import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Content from "./components/commons/pageContents/Content"
import Footer from "./components/commons/footer/Footer";
import React from 'react';
import { observer } from 'mobx-react';
import appStore from '../../frontend/src/components/atribute/AppStore';
import { BrowserRouter as Router } from "react-router-dom";

if (process.env.NODE_ENV !== 'production') {
  console.warn = function () { };
  console.error = function () { };
  console.log = function () { };
}


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      likedArr: JSON.parse(localStorage.getItem("liked_estate")) || [],
    };
  };
  componentDidMount() {
    appStore.setCountLikedClick(this.state.likedArr.length);
  }

  render() {
    return (
      <Router>
        <Content />
        <Footer />
      </Router>
    );
  }
}

export default observer(App);