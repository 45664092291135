// message for Login form
export const ERROR_NOT_INPUT_USERNAME = "Vui lòng nhập tên đăng nhập.";
export const ERROR_INPUT_USERNAME_NOT_VALID = "Chỉ được nhập vào ký tự.";
export const ERROR_INPUT_PASSWORD = "Vui lòng nhập mật khẩu.";
export const ERROR_INPUT_PASSWORD_LESSTHAN_8_CHARACTER = "Vui lòng nhập password lớn hơn 8 ký tự.";
export const ERROR_INPUT_PASSWORD_NOT_VALID = "Nhập tổ hợp chứa ký tự hoa, thường và số. Ví dụ: ABab1234" ;
export const ERROR_INPUT_PASSWORD_AND_REPASSWORD = "Vui lòng xem lại mật khẩu mới." ;
export const ERROR_INPUT_PASSWORD_NOT_THE_SAME = "Vui lòng nhập password giống nhau." ;
export const ERROR_INPUT_PASSWORD_OR_USER = "Username hoặc password không hợp lệ" ;

// message for Register form
export const ERROR_NOT_INPUT_NAME = "Vui lòng nhập tên.";
export const ERROR_NOT_INPUT_SUBJECT = "Vui lòng nhập chủ đề.";
export const ERROR_NOT_INPUT_CONTENT_DETAIL = "Vui lòng nhập nội dung chi tiết.";
export const ERROR_INPUT_NAME_NOT_VALID = "Chỉ được nhập vào ký tự.";
export const ERROR_INPUT_NAME_INVALID = "User name đã được đăng ký.";
export const ERROR_NOT_INPUT_EMAIL = "Vui lòng nhập email.";
export const ERROR_INPUT_EMAIL_NOT_VALID = "Vui lòng nhập vào địa chỉ email hợp lệ.";
export const ERROR_INPUT_EMAIL_HAS_USED = "Email này đã được đăng ký.";
export const ERROR_INPUT_CONFIRMPASSWORD = "Vui lòng nhập password.";
export const ERROR_INPUT_ENTER_CURENT_PASSWORD = "Vui lòng nhập password hiện tại.";
export const ERROR_INPUT_CONFIRM_PASSWORD_NOT_VALID = "Chỉ được nhập vào ký tự.";

// message for Publish form
export const ERROR_NOT_SELECT_RADIO_ESTATE = "Chọn loại bất động sản giao dịch.";
export const ERROR_NOT_SELECT_TYPE_ESTATE = "Chọn loại bất động sản.";
export const ERROR_NOT_SELECT_CITY_ESTATE = "Chọn tỉnh, thành nơi giao dịch.";
export const ERROR_NOT_SELECT_PROVINCE_ESTATE = "Chọn quận, huyện nơi giao dịch.";
export const ERROR_NOT_SELECT_DISTRICT_ESTATE = "Chọn phường, xã nơi giao dịch.";
export const ERROR_NOT_SELECT_STREET_ESTATE = "Nhập tên đường, phố nơi giao dịch.";
export const ERROR_NOT_INPUT_TITLE_ESTATE = "Mô tả tiêu đề bất động sản.";
export const ERROR_NOT_INPUT_ĐETAIL_ESTATE = "Mô tả chi tiết bất động sản.";
export const ERROR_NOT_INPUT_AREA_ESTATE = "Mô tả diện tích bất động sản.";
export const ERROR_NOT_SELECT_LEGAL_ESTATE = "Chọn giấy tờ pháp lý.";
export const ERROR_NOT_SELECT_WIDTH_ROAD = "Nhập chiều rộng mặt đường.";
export const ERROR_NOT_INPUT_PRICE_ESTATE = "Nhập mức giá.";
export const ERROR_NOT_INPUT_VALID_PRICE_ESTATE = "Nhập mức giá hợp lệ.";
export const ERROR_NOT_SELECT_PRICE_ESTATE = "Chọn mức giá.";
export const ERROR_NOT_INPUT_DIRECTION_ESTATE = "Chọn hướng nhà.";
export const ERROR_NOT_SELECT_UNIT_PRICE_ESTATE = "Chọn mức giá bất động sản.";
export const ERROR_NOT_SELECT_BEDROOM_ESTATE = "Chọn số phòng ngủ.";
export const ERROR_NOT_SELECT_LIVINGROOM_ESTATE = "Chọn số phòng khách.";
export const ERROR_NOT_SELECT_BATHANDTOLET_ESTATE = "Chọn số tắm, vệ sinh.";
export const ERROR_NOT_INPUT_CONTACT_NAME = "Nhập tên liên hệ.";
export const ERROR_NOT_INPUT_CONTACT_PHONE = "Nhập số điện thoại liên hệ.";
export const ERROR_NOT_INPUT_TRANSACTION_TYPE = "Chọn hình thức sở hữu.";