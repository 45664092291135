import React from "react";
import { Form} from "react-bootstrap";
import * as disLabel from "./../../constants/label";

class ActiveMailOK extends React.Component {

    render() {
        return (
            <div className="div-active-mail">
                <Form>
                    <span>{disLabel.ACTIVE_MAIL_SUCCESS_1}<br/></span>
                    <span>{disLabel.ACTIVE_MAIL_SUCCESS_2}</span>
                </Form>
            </div>
        );
    }
}

export default ActiveMailOK;