import React from "react";
import * as disLabel from "../../constants/label";
import { observer } from 'mobx-react';
import { Form } from "react-bootstrap";
import { FaChild, FaPhoneSquareAlt, FaRegHeart } from 'react-icons/fa';
import Mapbox from '../../atribute/Mapbox';
import ImageGallery from '../../atribute/SlideShow';
import appStore from '../../atribute/AppStore';
import convertToTextPrice from "../../utils/ConvertToText";


class ViewDetailEstate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      viewedDetail: JSON.parse(localStorage.getItem("viewed_detail_estate")) || [],
      likedArr: JSON.parse(localStorage.getItem("liked_estate")) || [],
      errors: {},
    };
  };

  playClickSound = () => {
    try {
      const audio = new Audio("/sounds/click-sound.mp3");
      audio.play();
    } catch (error) {
      console.error("Lỗi:", error);
    }
  }

  componentDidMount = () => {
    let savedEstate = JSON.parse(localStorage.getItem("liked_estate")) || [];
    let indexLiked = savedEstate.findIndex((likedItem) => likedItem.id === this.state.viewedDetail[0].id);
    if (indexLiked !== -1) {
      this.setState((prevState) => {
        if (indexLiked >= 0) {
          prevState.viewedDetail[0].isHeartClicked = true;
        }
        return { viewedDetail: prevState.viewedDetail };
      });
    }
  }

  handleHeartClick = (e, item) => {
    e.preventDefault();
    this.playClickSound();
    // Cập nhật trạng thái CSS sau khi bấm vào FaRegHeart
    // ------------------------------------Start---------------------------------------------
    // tạo bản sao item
    let itemViewedCopy = { ...item };
    if (itemViewedCopy.isHeartClicked === false) {
      itemViewedCopy.isHeartClicked = true;
    } else {
      itemViewedCopy.isHeartClicked = false;
    }

    let viewedEstateArr = JSON.parse(localStorage.getItem("viewed_recent_estate")) || [];
    let indexToUpdate = viewedEstateArr.findIndex((likedItem) => likedItem.id === itemViewedCopy.id);
    if (indexToUpdate !== -1) {
      viewedEstateArr[indexToUpdate] = itemViewedCopy;
    } else {
      viewedEstateArr.push(itemViewedCopy);
    }

    this.state.viewedDetail[0].isHeartClicked = itemViewedCopy.isHeartClicked;
    localStorage.setItem("viewed_detail_estate", JSON.stringify(this.state.viewedDetail));

    localStorage.setItem("viewed_recent_estate", JSON.stringify(viewedEstateArr));
    this.setState({ viewedRecentArr: viewedEstateArr });
    // -------------------------------------Finish-------------------------------------------


    // Add item liked to array liked_estate
    // ------------------------------------Start---------------------------------------------
    let isExistLikedEstate = localStorage.getItem("liked_estate");
    if (isExistLikedEstate) {
      try {
        let likedEstateArr = JSON.parse(isExistLikedEstate);
        if (likedEstateArr.length > 20) {
          localStorage.removeItem("liked_estate");
        }
      } catch (error) {
        console.log(error);
      }
    }
    let itemlikedCopy = { ...item };
    let likedEstateConvArr = JSON.parse(localStorage.getItem("liked_estate")) || [];
    let indexDelOrAdd = likedEstateConvArr.findIndex((item) => item.id === itemlikedCopy.id);
    if (indexDelOrAdd === -1) {
      itemlikedCopy.isHeartClicked = true;
      likedEstateConvArr.push(itemlikedCopy);
    } else {
      likedEstateConvArr.splice(indexDelOrAdd, 1)
    }
    localStorage.setItem("liked_estate", JSON.stringify(likedEstateConvArr));
    appStore.setCountLikedClick(likedEstateConvArr.length);
    // -------------------------------------Finish-------------------------------------------
  }

  render() {

    return (
      <div>
        <Form>
          {this.state.viewedDetail.map((item, index) => (
            <div className="block-result-detail" key={item.id}>
              <div className="title_view-detail">
                <div className="title_view_detail-text">{item.estates_Title}</div>
              </div>
              <div className="day_create_view_detail">
                <div >
                  <a className={`click-like-estates ${item.isHeartClicked === true ? "active" : "click-like-estates_inactive"}`}
                    href="/" onClick={(e) => this.handleHeartClick(e, item)}>
                    <FaRegHeart size={25} style={{ fill: item.isHeartClicked ? '#FF6800' : 'rgb(147 144 144)' }} />
                    <span>{disLabel.CLICK_LIKEST}</span>
                  </a>
                </div>
                {disLabel.PUBLISHED_DAY} {item.created_at}
              </div>
              <div className="slideshow-display">
                <ImageGallery />
              </div>
              <div className="block-result-detail_section-1">
                <div className="section_h2">
                  <div className="section_h2-header"><h5 className="section_h2-title">{disLabel.ESTATE_INFO}</h5></div>
                  <div className="section_h2-body">
                    <table className="detailtable">
                      <tbody>
                        <tr>
                          <th className="detailtable-title">{disLabel.ADDRESS}</th>
                          <td colspan="5" className="detailtable-body">{item.estates_Street + ", " + item.selected_District + ", " + item.selected_Province + ", " + item.selected_City}</td>
                        </tr>
                        <tr>
                          <th className="detailtable-title">{disLabel.PRICE}</th>
                          {item.estates_PriceForUnit === 'VND' ? (
                            <td colspan="5" className="detailtable-body table-td-price">{convertToTextPrice(item.estates_Price)}</td>
                          ) : (
                            <td colspan="5" className="detailtable-body table-td-price">{convertToTextPrice(item.estates_Price)}{disLabel.ESTATE_M2}</td>
                          )}
                        </tr>
                        <tr>
                          <th className="detailtable-title">{disLabel.LEGAL_DOCUMENT}</th>
                          <td colspan="5" className="detailtable-body">{item.estates_Legal_Doc}</td>
                        </tr>
                        <tr>
                          <th className="detailtable-title">{disLabel.AREA}</th>
                          <td className="detailtable-body">{item.estates_Area} m²</td>
                          <th className="detailtable-title">{disLabel.DIRECTION_HOUSE}</th>
                          <td className="detailtable-body">{item.estates_Direction_Home}</td>
                          <th className="detailtable-title">{disLabel.WIDTH_ROAD}</th>
                          <td className="detailtable-body">{item.width_Road} {disLabel.M}</td>
                        </tr>
                        {item.estates_Nums_BedRoom !== "0" || item.estates_Nums_GuestRoom !== "0" || item.estates_Nums_BedRoom !== "0" ? (
                          <tr>
                            <th className="detailtable-title">{disLabel.NUMBER_BEDROOMS}</th>
                            <td className="detailtable-body">{item.estates_Nums_BedRoom}</td>
                            <th className="detailtable-title">{disLabel.NUMBER_LIVINGROOMS}</th>
                            <td className="detailtable-body">{item.estates_Nums_GuestRoom}</td>
                            <th className="detailtable-title">{disLabel.NUMBER_BATHROOMS}</th>
                            <td className="detailtable-body">{item.estates_Nums_BedRoom}</td>
                          </tr>) : null}
                        <tr>
                          <th className="detailtable-title">{disLabel.DESCRIPT_ESTATE_DETAIL}</th>
                          <td colspan="5" className="detailtable-body" dangerouslySetInnerHTML={{ __html: item.estates_Detail }}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mapbox-container">
                  <div ><h5 className="map-sub-title">{disLabel.VIEW_MAP}</h5></div>
                  <Mapbox address={item.estates_Street + " " + item.selected_District + " " + item.selected_Province + " " + item.selected_City} />
                </div>
              </div>
              <div className="block-result-detail_section-2">
                <div className="section_h2">
                  <div className="section_h2-header"><h5 className="section_h2-title">{disLabel.CONTRACT_INFO}</h5></div>
                  <div className="section_h2-body">
                    <div className="inquiry_info">
                      <p className="inquiry_info-name">{disLabel.CONTACT_BELOW}【{item.transaction_Type}】{disLabel.CONTACT_INFO_BELOW}</p>
                      <span className="inquiry_info-number">
                        <FaChild size={30} color="blue" />
                        <div className="smartphone-number"><span className="inquiry_info-number_item">{item.contact_Name}</span></div>
                      </span>
                      <span className="inquiry_info-number">
                        <FaPhoneSquareAlt size={30} color="#f77c00" />
                        <div className="smartphone-number"><span className="inquiry_info-number_item">{item.contact_Phone}</span></div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Form>
      </div>
    );
  }
}

export default observer(ViewDetailEstate);