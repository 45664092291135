import React from "react";
import  '../../css/SlideShow.css'
require('dotenv').config();


const mediaImage = process.env.REACT_APP_BACKEND_SERVER;
const viewedDetail = JSON.parse(localStorage.getItem("viewed_detail_estate")) || [];
const ImageURL = [];

if (viewedDetail.length > 0 && viewedDetail[0].images_count !== undefined) {
  for (let i = 0; i < viewedDetail[0].images_count; i++) {
    const images_directory = viewedDetail[0].images_directory;
    const images_extension = viewedDetail[0].images_extension;
    const image = mediaImage + "media/" + images_directory + '/image_' + i + images_extension;

    if (image) {
      ImageURL.push(image);
    }
  }
}

// https://codepen.io/Esambino/pen/pQrwqy
class ImageGallery extends React.Component {
    constructor(props) {
      super(props);
      this.state = { currentIndex: 0, slideshow: false, fullscreen: false, imageCount: ImageURL.length };
      this.exitfullScreen = this.exitfullScreen.bind(this);
      this.slideTransition = this.slideTransition.bind(this);
      this.updateImage = this.updateImage.bind(this);
      this.imageVisibility = ImageURL.map((_, index) => index === 0);
      this.left = 0;
      
    }
    
    slideTransition = slider => {
      slider.style.left = this.left + 'px';
    };
  
    // 2s sau thi slide image tự động
    componentDidMount() {
      this.slideInterval = setInterval(() => {
        if (this.state.slideshow === true) {
          var slider = document.getElementById('slider-img-container');
          var index =
            this.state.currentIndex === this.state.imageCount - 1
              ? 0
              : this.state.currentIndex + 1;
    
          this.slideTransition(slider);
    
          this.setState({ currentIndex: index });
        }
      }, 2000);
    }

    // clear currentIndex tại hàm setInterval
    componentWillUnmount() {
      clearInterval(this.slideInterval);
    }    
    
    slideshowHandler = event => {
      this.setState(prevState => ({
        slideshow: prevState.slideshow === false ? true : false
      }));
    };
  
  exitfullScreen = event => {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    var i = document.getElementsByClassName('gallery-img-container ')[0];
    i.getElementsByClassName('gallery-img')[0].style.height = '450px';
    i.getElementsByClassName('gallery-img')[0].style.width = '100%';
    this.setState(prevState => ({
      fullscreen: prevState.fullscreen === false ? true : false
    }));
  };

    enterfullScreen = event => {
      var i = document.getElementsByClassName('gallery-img-container ')[0];
  
      if (i.requestFullscreen) {
        i.requestFullscreen();
      } else if (i.mozRequestFullScreen) {
        i.mozRequestFullScreen();
      } else if (i.webkitRequestFullscreen) {
        i.webkitRequestFullscreen();
      }
  
      i.getElementsByClassName('gallery-img')[0].style.height = '100%';
      i.getElementsByClassName('gallery-img')[0].style.width = '100%';
  
      this.setState(prevState => ({
        fullscreen: prevState.fullscreen === false ? true : false
      }));
    };
  
    dotHandler = event => {
      var imgIndex = event.target.id;
      var slider = document.getElementById('slider-img-container');
      slider.style.left = this.left + 'px';
      this.setState({ currentIndex: parseInt(imgIndex) });
    };
  
    updateImage = event => {
      var imgIndex = event.target.id;
      var slider = document.getElementById('slider-img-container');
      slider.style.left = this.left + 'px';
      this.setState({ currentIndex: parseInt(imgIndex) });
    };
  
    prevHandler = event => {
      var index = this.state.currentIndex === 0 ? (viewedDetail[0].images_count - 1) : this.state.currentIndex - 1;
      this.setState({ currentIndex: index });
    };
  
    nextHandler = event => {
      var index = this.state.currentIndex === (viewedDetail[0].images_count - 1) ? 0 : this.state.currentIndex + 1;
      this.setState({ currentIndex: index });
    };
  
    render() {
      var i = this.state.currentIndex;
      var dotNumbers = Array.from(Array(ImageURL.length).keys());
      const carouselDots = dotNumbers.map((n, index) => (
        <div
          className={[
            'carousel-dot',
            index === this.state.currentIndex ? 'active' : ''
          ].join(' ')}
          key={n}
          id={n}
          onClick={this.dotHandler}
        >
          &#9679;
        </div>
      ));
      const ImgItem = (
        <div className="gallery-img-container">
          <img className="gallery-img" src={ImageURL[i]} />
          <button type="button"  className="prev-carousel-button" onClick={this.prevHandler}>
            &#9664;
          </button>
          <div id="carousel-dot-container">{carouselDots}</div>
          <div type="button"  className="next-carousel-button" onClick={this.nextHandler}>
            &#9654;
          </div>
          {this.state.slideshow === false ? (
            <button type="button"  className="slideshow-button" onClick={this.slideshowHandler}>
              &#9654;
            </button>
          ) : (
            <button type="button"  className="slideshow-button" onClick={this.slideshowHandler}>
              &#9646;&#9646;
            </button>
          )}
          {this.state.fullscreen === false ? (
            <button type="button"  className="fullscreen-button" onClick={this.enterfullScreen}>
              &#9635;
            </button>
          ) : (
            <button type="button"  className="fullscreen-button" onClick={this.exitfullScreen}>
              &#9635;
            </button>
          )}
        </div>
      );
      var imgId = 0;
  
      const sliderImages = ImageURL.slice(0, this.state.imageCount).map((n, index) => (

        <img
          className={[
            'slider-img',
            index === this.state.currentIndex ? 'active' : ''
          ].join(' ')}
          src={n}
          key={n}
          id={imgId++}
          onClick={this.updateImage}
        />
      ));
  
      return (
        <div id="gallery-container" ref={this.myImage}>
          {ImgItem}
          <div id="slider-img-container" ref={this.mySlider}>
            {sliderImages}
          </div>
        </div>
      );
    }
  }
export default ImageGallery;
