import React from "react";
import Pagination from "react-bootstrap/Pagination";
import '../../css/Paginate.css';
import { Form } from "react-bootstrap";
import { FaEye, FaRegImage } from 'react-icons/fa';
import { format } from 'date-fns';
import http from "../axios/http-common";
import * as disLabel from "../constants/label";
import TextWithEllipsis from "../atribute/TextWithEllipsis";
import convertToTextPrice from "../utils/ConvertToText";
require('dotenv').config();


const endpoint = process.env.REACT_APP_BACKEND_SERVER;

class SearchResultPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      selectedEstates: [],
      mediaImage: endpoint + 'media/',
    };
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { searchResultArr, itemsPerPage } = this.props;
    const { currentPage } = this.state;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = searchResultArr.slice(startIndex, endIndex);

    const totalPages = Math.ceil(searchResultArr.length / itemsPerPage);

    const handleFirstClick = () => {
      this.handlePageChange(1);
    };

    const handlePrevClick = () => {
      if (currentPage > 1) {
        this.handlePageChange(currentPage - 1);
      }
    };

    const handleNextClick = () => {
      if (currentPage < totalPages) {
        this.handlePageChange(currentPage + 1);
      }
    };

    const handleLastClick = () => {
      this.handlePageChange(totalPages);
    };

    function ViewDetailPage(item) {

      let created_at_bf = new Date(item.created_at);
      let updated_at_bf = new Date(item.updated_at);
      let created_at = format(created_at_bf, 'dd/MM/yyyy');
      let updated_at = format(updated_at_bf, 'dd/MM/yyyy');
      let itemViewed = {
        id: item.id,
        estates_Type: item.estates_Type,
        estates_Name: item.estates_Name,
        estates_Title: item.estates_Title,
        estates_Detail: item.estates_Detail,
        estates_Legal_Doc: item.estates_Legal_Doc,
        width_Road: item.width_Road,
        estates_Price: item.estates_Price,
        estates_PriceForUnit: item.estates_PriceForUnit,
        estates_Direction_Home: item.estates_Direction_Home,
        estates_Area: item.estates_Area,
        selected_City: item.selected_City,
        selected_Province: item.selected_Province,
        selected_District: item.selected_District,
        estates_Street: item.estates_Street,
        estates_Nums_GuestRoom: item.estates_Nums_GuestRoom,
        estates_Nums_BedRoom: item.estates_Nums_BedRoom,
        estates_NumsBathAndToiletRoom: item.estates_NumsBathAndToiletRoom,
        contact_Name: item.contact_Name,
        contact_Phone: item.contact_Phone,
        transaction_Type: item.transaction_Type,
        isDisplay_Available: item.isDisplay_Available,
        images_directory: item.images_directory,
        images_extension: item.images_extension,
        images_count: item.images_count,
        created_at: created_at,
        updated_at: updated_at,
        isHeartClicked: false,
      }

      // ------------------------------------Start---------------------------------------------
      if (localStorage.getItem("viewed_detail_estate")) {
        localStorage.removeItem("viewed_detail_estate");
      }

      // Add item view detail to array viewed_detail_estate
      let viewDetailArr = [];
      viewDetailArr.push(itemViewed);
      localStorage.setItem("viewed_detail_estate", JSON.stringify(viewDetailArr));
      // -------------------------------------Finish-------------------------------------------


      // Add item view recent to array viewed_recent_estate
      // ------------------------------------Start---------------------------------------------
      const viewedRecentEstate = localStorage.getItem("viewed_recent_estate");
      if (viewedRecentEstate) {
        try {
          const viewRecentArr = JSON.parse(viewedRecentEstate);
          if (viewRecentArr.length > 20) {
            localStorage.removeItem("viewed_recent_estate");
          }
        } catch (error) {
          console.log(error);
        }
      }
      let viewRecentArr = JSON.parse(localStorage.getItem("viewed_recent_estate")) || [];
      // Kiểm tra nếu itemViewed không tồn tại trong mảng thì thêm mới
      if (!viewRecentArr.some(item => item.id === itemViewed.id)) {
        viewRecentArr.push(itemViewed);
        // Lưu mảng vào localStorage
        localStorage.setItem("viewed_recent_estate", JSON.stringify(viewRecentArr));
      }
      // -------------------------------------Finish-------------------------------------------


      // Function to increase view count for an item
      // ------------------------------------Start---------------------------------------------
      try {
        http.post(`/increase_view_count/${item.id}/`)
          .then((response) => {
            console.log(response.data.message);
          })
          .catch((error) => {
            console.error('Error increasing view count:', error);
          });
      }
      catch (error) {
        console.log(error);
      }
      // -------------------------------------Finish-------------------------------------------
    };

    // Tạo một mảng chứa các số trang để hiển thị phân trang
    // ------------------------------------Start---------------------------------------------
    const pageNumbers = [];

    // Điều này xác định số trang bạn muốn hiển thị (trong trường hợp này là 5 trang)
    const pagesToShow = 5;

    // Tính toán trang bắt đầu và trang kết thúc để hiển thị
    let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    let endPage = startPage + pagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    // Thêm "..." nếu có trang nào đó trước trang đầu tiên
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Thêm các trang vào mảng
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Thêm "..." nếu có trang nào đó sau trang cuối cùng
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }
    // -------------------------------------Finish-------------------------------------------

    return (
      <div>
        <Form>
          <div className="page_content">
            <div className="honepage-section_h2-header"><h5 className="title_display_default">{disLabel.ESTATE_RESULT_SEARCH}</h5></div>
            {currentData.map((item, index) => (
              <div className="block-result" key={item.id}>
                <div className="block-result-inner">
                  <div className="modulehead">
                    <span className="bds-type">{item.estates_Name}</span>
                    <a href="/xem-chi-tiet-bat-dong-san" target="_blank" className="title-bds" onClick={() => ViewDetailPage(item)}><TextWithEllipsis text={item.estates_Title} maxLength={73} /></a>
                  </div>
                  <div>
                    <div className="modulebody">
                      <div className="photo-spe-line">
                        <div className="bds-photo-lefthand">
                          <p className="photo">
                            <img
                              rel="preload"
                              src={`${this.state.mediaImage}${item.images_directory}/image_0${item.images_extension}`}
                              srcSet={`${this.state.mediaImage}${item.images_directory}/image_0${item.images_extension} 1x`}
                              sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw"
                              alt="" loading="lazy"
                            />
                            </p>
                          <FaRegImage className="display-images-number" size={20} color="rgb(223 218 213)" />
                          <p className="display-images-number-no">{item.images_count}</p>
                        </div>
                        <div className="bds-spec-righthand">
                          <div>
                            <table className="verticalTable">
                              <tbody>
                                <tr>
                                  <th>{disLabel.ADDRESS}</th>
                                  <td><TextWithEllipsis text={item.selected_District + ", " + item.selected_Province + ", " + item.selected_City} maxLength={64} /></td>
                                </tr>
                                <tr>
                                  <th>{disLabel.DIRECTION_HOUSE}</th>
                                  <td>{item.estates_Direction_Home}</td>
                                </tr>
                                <tr>
                                  <th>{disLabel.TRANSACTION_CONTACT}</th>
                                  <td>{item.transaction_Type}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <table className="unitSummary">
                        <thead>
                          <tr>
                            <th>{disLabel.ESTATE_PRICE}</th>
                            <th>{disLabel.ESTATE_AREA}</th>
                            <th>{disLabel.ESTATE_VIEW_COUNT}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {item.estates_PriceForUnit === 'VND' ? (
                              <td className="price-estate-converted">{convertToTextPrice(item.estates_Price)}</td>
                            ) : (
                              <td className="price-estate-converted">{convertToTextPrice(item.estates_Price)}{disLabel.ESTATE_M2}</td>
                            )}
                            <td>{item.estates_Area} {disLabel.ESTATE_M2_NOT_LINE}</td>
                            <td><div className="display_view_number"><FaEye className="FaLowVision_ViewNumber" size={20} color="rgb(147 144 144)" /> {item.view_count}</div></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>))
            }
            <Pagination>
              <Pagination.First onClick={handleFirstClick} />
              <Pagination.Prev onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber, index) => (
                <React.Fragment key={index}>
                  {pageNumber === "..." ? (
                    <Pagination.Ellipsis key={`ellipsis${index}`} disabled className="ellipsis-dot" />
                  ) : (
                    <Pagination.Item
                      key={`page${pageNumber}`}
                      active={pageNumber === currentPage}
                      onClick={() =>
                        pageNumber !== "..." && this.handlePageChange(pageNumber)
                      }
                    >
                      {pageNumber}
                    </Pagination.Item>
                  )}
                </React.Fragment>
              ))}
              <Pagination.Next onClick={handleNextClick} />
              <Pagination.Last onClick={handleLastClick} />
            </Pagination>
          </div>
        </Form>
      </div>
    );
  }
}

export default SearchResultPage;
