import React from 'react';
import * as disLabel from "../constants/label";


class ErrorModal extends React.Component {
  render() {
    const { isOpen, message, onClose } = this.props;

    return (
      <div className={`publish-modal ${isOpen ? 'is-active' : ''}`}>
        <div className="publish-modal-background" onClick={onClose}></div>
        <div className="publish-modal-content">
          <div className="publish-modal-header-error">
            <h5>{disLabel.ERROR_INFORM}</h5>
          </div>
          <div className="publish-modal-body">
            <p>{message}</p>
          </div>
          <div className="publish-modal-footer">
            <button id='close-model' onClick={onClose}>{disLabel.INFORM_BTN_CLOSE}</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorModal;