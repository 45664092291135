// Constant for Homepage
export const HOMEPAGE = "Trang chủ";
export const ESTATE_SELL = "Nhà đất bán";
export const ESTATE_FOR_RENT = "Nhà cho thuê";
export const ESTATE_FOR_INVEST = "Dự án đầu tư";
export const CONTRACT = "Liên hệ";
export const INSTRUST = "Hướng dẫn";
export const CAMPAGE_ITEMS = "Quảng cáo & Ưu đãi";


export const ESTATE_SELL_TAB_SEARCH = "Nhà đất bán";
export const ESTATE_RENT_TAB_SEARCH = "Nhà cho thuê";
export const ESTATE_INVEST_TAB_SEARCH = "Dự án đầu tư";
export const SELECT_ESTATE_NEED_TO_PUBLISH = "Chọn loại bất động sản giao dịch";

// Constant for Register
export const REGISTER_FORM = "Đăng ký";
export const USER_NAME = "Username";
export const EMAIL = "Email";
export const PASSWORD = "Password";
export const SAVE_PASSWORD = "Lưu Password";
// Constant for Login
export const LOGIN_FORM = "Đăng nhập";
export const LOGOUT_FORM = "Đăng xuất";
export const PUBLIST_BTN = "Đăng tin";
export const KEEP_LOGIN = "Duy trì đăng nhập";
// Constant for Register
export const PUBLISHER_FORM = "Đăng tin";
export const ESTATE_TYPE = "Loại bất động sản";

// Constant for Register
export const CURRENT_PASSWORD = "Mật khẩu hiện tại";
export const CHANGE_PASSWORD_SUCCESS = "Thay đổi mật khẩu thành công.";
export const SENT_CONTACT_SUCCESS = "Thông tin liên hệ đã được gởi đi.";
export const PROFILE_USER = "※ Chức năng này chỉ dùng cho user trả phí.";
export const NEW_PASSWORD = "Mật khẩu mới";

// Constant for publish
export const BASIS_INFO = "Thông tin cơ bản";
export const CONTACT_INFO = "Liên hệ";
export const PUBLISHER_INFO = "Thông tin mô tả";
export const DESCRIPTION_PUBLISH_1 = "Thông tin có dấu ";
export const DESCRIPTION_PUBLISH_2 = " là bắt buộc.";
export const DESCRIPT_CITY = "Tỉnh, thành phố";
export const DESCRIPT_PROVINCE = "Quận, huyện";
export const DESCRIPT_DISTRICT = "Phường, xã";
export const DESCRIPT_STREET = "Đường, phố";
export const DESCRIPT_WRITE_PUBLISH = "Vui lòng nhập tiếng việt có dấu.";
export const DESCRIPT_TITLE = "Tiêu đề";
export const DESCRIPT_ESTATE = "Mô tả tổng quát bất động sản";
export const DESCRIPT_ESTATE_DETAIL = "Mô tả";
export const ESTATE_INFO = "Thông tin bất động sản";
export const VIEW_MAP = "Xem trên bản đồ";
export const ESTATE_NEW_INFO = "Bất động sản mới nhất";
export const PUBLISH_GUIDLINE = "Hướng dẫn đăng bài";
export const RECENT_EST_GUIDLINE = "Hướng dẫn xem tin bất động sản gần nhất";
export const LIKED_EST_GUIDLINE = "Hướng dẫn xem tin bất động sản yêu thích";
export const CHANGE_PW_GUIDLINE = "Hướng dẫn đổi mật khẩu";
export const ACCOUNT_ACTIVE_GUIDLINE = "Hướng dẫn kích hoạt tài khoản";
export const ESTATE_RESULT_SEARCH = "Kết quả tìm kiếm";
export const NO_RESULT_SEARCH = "Không có kết quả tìm kiếm phù hợp.";
export const ESTATE_VIEW_RECENT = "Xem gần đây";
export const ESTATE_RENT = "Bất động sản đang cho thuê";
export const ESTATE_INVEST = "Dự án đầu tư";
export const CONTRACT_INFO = "Thông Tin Liên Hệ";
export const ESTATE_IMAGE_UPLOAD = "Đăng tải hình ảnh";
export const LIST_ESTATE_IMAGE_UPLOAD = "Danh sách file hình ảnh sẽ upload";
export const DRAG_AND_DROP_IMAGE_UPLOAD = "Kéo và thả file hình ảnh vào đây";
export const AREA= "Diện tích";
export const WIDTH_ROAD= "Mặt đường";
export const PRICE= "Mức giá";
export const M2= "m²";
export const M= "m";
export const BILIION= "Đồng";
export const UNIT= "Đơn vị";
export const LEGAL_DOCUMENT= "Giấy tờ pháp lý";
export const NUMBER_BEDROOMS= "Số phòng ngủ";
export const CONTACT_NAME= "Tên liên hệ";
export const CONTACT_NUMBERPHONE= "Số điện thoại";
export const NUMBER_LIVINGROOMS= "Số phòng khách";
export const NUMBER_BATHROOMS= "Số phòng tắm, vệ sinh";
export const TRANSACTION_TYPE= "Hình thức liên hệ";
export const TRANSACTION_CONTACT= "Giao dịch thông qua";
export const NUMBER_FLOOR= "Số tầng";
export const DIRECTION_HOUSE= "Hướng";
export const BALCONY_DIRECTION= "Hướng ban công";
export const WIDTH_STREET= "Chiều rộng đường";
export const FRONT_FACE= "Mặt tiền";
export const INTERIOR= "Nội thất";
export const ESTATE_PRICE= "Giá bất động sản";
export const ESTATE_AREA= "Diện tích";
export const ESTATE_VIEW_COUNT= "Lượt view";
export const ESTATE_M2= "/m²";
export const ESTATE_M2_NOT_LINE= "m²";
export const ESTATE_VIEWEST_RECENT = "Bất động sản xem gần nhất";
export const ESTATE_LIKEST = "Bất động sản yêu thích";
export const CLICK_LIKEST = " Yêu thích";
export const NO_EXIST_RECORD = "※ Không có record yêu thích nào tồn tại.";
export const CONTACT_BELOW = "Liên hệ";
export const CONTACT_INFO_BELOW = "bên dưới để tiến hành giao dịch";

// Constant for instructions
export const INSTRUCTION_INFOS = "Hướng dẫn sử dụng";
export const INSTRUCTION_PUBLISH = "Hướng dẫn đăng bài.";
export const INSTRUCTION_GET_PASSWORD_AGAIN = "Hướng dẫn đổi mật khẩu.";
export const INSTRUCTION_GET_ACTIVE_ACCOUNT = "Hướng dẫn kích hoạt tài khoản.";
export const INSTRUCTION__VIEW_EST = "Hướng dẫn xem tin bất động sản gần nhất.";
export const INSTRUCTION_VIEW_OR_DEL_LIKED_EST = "Hướng dẫn xem hoặc xóa tin bất động đã yêu thích.";
export const NOT_HAVENT_ACCOUNT = "Chưa có tài khoản?";
export const LET_SIGNUP_NOW = "Đăng ký ngay!";

// campage items
export const CAMPAIGN_INFOS = "Ưu đãi khi sử dụng";
export const CAMPAIGN_INFOS_1 = "Được kích hoạt tài khoản miễn phí.";
export const CAMPAIGN_INFOS_2 = "Được rao tối đa 5 tin trên một ngày, mỗi tin upload tối đa 5 hình.";
export const CAMPAIGN_INFOS_3 = "Thời hạn lưu trữ tin rao là 45 ngày.";
export const CAMPAIGN_INFOS_4 = "Tích hợp công cụ tìm kiếm mạnh mẽ.";
export const CAMPAIGN_INFOS_5 = "Sử dụng Mapbox ở chế độ đường phố và vệ tinh.";
export const CAMPAIGN_INFOS_6 = "Được hỗ trợ 24/7 khi gặp khó khăn.";

export const ADVERTIES_INFOS = "Góc quảng cáo";
export const ADVERTIES_INFOS_1 = "Cho thuê banner theo yêu cầu.";
export const ADVERTIES_INFOS_2 = "Nhận xây dựng và thiết kế Website bất động sản cho cá nhân và tổ chức(nhỏ) theo chuẩn SEO.";
export const ADVERTIES_INFOS_3 = "Bán các sản phẩm nội thất nhập từ Nhật Bản(liên hệ để biết thêm chi tiết).";
export const ADVERTIES_INFOS_4 = "Bán .";

// Constant for ViewDetailEstate
export const ADDRESS = "Địa Chỉ";
export const PUBLISHED_DAY = "Ngày đăng: ";
export const VIEW_DETAIL = "Xem chi tiết";

// Constant for Modal
export const ERROR_INFORM = "Thông báo lỗi";
export const SUCCESS_PUBLISH_INFORM = "Đăng Tin Thành Công";
export const INFORM_BTN_CLOSE= "Đóng";

// Button
export const DELETE_BTN = "Xóa";
export const CHANGE_PASSWORD_BTN = "Thay đổi";
export const SENT_BTN = "Gởi";
export const DELETE_CLOSE = "Đóng";
export const LOGIN_BTN = "Đăng nhập";
export const PUBLISH_BTN = "Đăng tin";
export const REGISTER_BTN = "Đăng ký";
export const SEARCH_BTN = "Tìm kiếm";


// Content Page
export const ACTIVE_MAIL_SUCCESS_1 = "Bạn đã kích hoạt email thành công.";
export const ACTIVE_MAIL_SUCCESS_2 = "Bây giờ bạn có thể login vào hệ thống với raozat.com.";
export const ACTIVE_MAIL_FAIL_1 = "Email đã không được kích hoạt thành công.";
export const ACTIVE_MAIL_FAIL_2 = "Vui lòng đăng ký lại bằng một tài khoản email khác.";
