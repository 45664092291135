import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


// Kiểm tra xem ứng dụng đang chạy trong môi trường sản phẩm
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);