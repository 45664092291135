import React from "react";
import * as disLabel from "../constants/label";
import { FaRegTrashAlt, FaBullhorn } from 'react-icons/fa';
import { LinkContainer } from "react-router-bootstrap";

class InstructionPage extends React.Component {


  render() {

    return (
      <div>
        <div className="container-instruction-parent">
          <div>
            <span className="infos-inform">{disLabel.INSTRUCTION_INFOS}</span>
            <div className="container-instruction-child">
              <FaBullhorn size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} />
              <LinkContainer to="/huong-dan-kich-hoat-tai-khoan">
                <a className="">{disLabel.INSTRUCTION_GET_ACTIVE_ACCOUNT}</a>
              </LinkContainer>
            </div>
            <div className="container-instruction-child">
              <FaBullhorn size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} />
              <LinkContainer to="/huong-dan-dang-bai">
                <a className="">{disLabel.INSTRUCTION_PUBLISH}</a>
              </LinkContainer>
            </div>
            <div className="container-instruction-child">
              <FaBullhorn size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} />
              <LinkContainer to="/huong-dan-doi-mat-khau">
                <a className="">{disLabel.INSTRUCTION_GET_PASSWORD_AGAIN}</a>
              </LinkContainer>
            </div>
            <div className="container-instruction-child">
              <FaBullhorn size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} />
              <LinkContainer to="/huong-dan-xem-tin-bds-gan-day">
                <a className="">{disLabel.INSTRUCTION__VIEW_EST}</a>
              </LinkContainer>
            </div>
            <div className="container-instruction-child">
              <FaBullhorn size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} />
              <LinkContainer to="/huong-dan-xem-tin-bds-yeu-thich">
              <a className="">{disLabel.INSTRUCTION_VIEW_OR_DEL_LIKED_EST}</a>
            </LinkContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InstructionPage;
