import axios from "axios";
import GetCookie from '../hooks/GetCookie';
require('dotenv').config();


const endpoint = process.env.REACT_APP_BACKEND_SERVER;

let pathApi = '';
// Kiểm tra xem ứng dụng đang chạy trong môi trường sản phẩm
if (process.env.NODE_ENV !== 'production') {
  pathApi = 'api'
}

const accessToken = GetCookie('access_token');
const headers = {
  "Content-type": "application/json",
  'Accept': 'application/json',
};

if (accessToken) {
  headers.Authorization = `Bearer ${accessToken.replace(/^"(.*)"$/, '$1')}`;
}

const instance = axios.create({
  baseURL: endpoint + pathApi,
  headers: headers
});

export default instance;