import React from "react";
import { Form } from "react-bootstrap";
import * as disLabel from "../../constants/label";

class RecentEsteGuideline extends React.Component {

    render() {
        return (
            <div className="div-guideline">
                <div><h5 className="title_display_default">{disLabel.RECENT_EST_GUIDLINE}</h5></div><br />
                <span>● Thông tin 30 bất động sản bạn xem gần đây nhất sẽ được lưu vào trang bên dưới.</span>
                <img src="/images/xemganday.png" alt="Active Account" style={{ width: '100%' }} />
            </div>
        );
    }
}

export default RecentEsteGuideline;