export const citys = [
    { value: "0", label: "Chọn tỉnh, thành phố" },
    { value: "1", label: "TP. Hồ Chí Minh" },
    { value: "2", label: "Hà Nội" },
    { value: "3", label: "Đà Nẵng" },
    { value: "4", label: "Hà Giang" },
    { value: "5", label: "Cao Bằng" },
    { value: "6", label: "Bắc Kạn" },
    { value: "7", label: "Tuyên Quang" },
    { value: "8", label: "Lào Cai" },
    { value: "9", label: "Điện Biên" },
    { value: "10", label: "Lai Châu" },
    { value: "11", label: "Sơn La" },
    { value: "12", label: "Yên Bái" },
    { value: "13", label: "Hòa Bình" },
    { value: "14", label: "Thái Nguyên" },
    { value: "15", label: "Lạng Sơn" },
    { value: "16", label: "Quảng Ninh" },
    { value: "17", label: "Bắc Giang" },
    { value: "18", label: "Phú Thọ" },
    { value: "19", label: "Vĩnh Phúc" },
    { value: "20", label: "Bắc Ninh" },
    { value: "21", label: "Hải Dương" },
    { value: "22", label: "Hải Phòng" },
    { value: "23", label: "Hưng Yên" },
    { value: "24", label: "Thái Bình" },
    { value: "25", label: "Hà Nam" },
    { value: "26", label: "Nam Định" },
    { value: "27", label: "Ninh Bình" },
    { value: "28", label: "Thanh Hóa" },
    { value: "29", label: "Nghệ An" },
    { value: "30", label: "Hà Tĩnh" },
    { value: "31", label: "Quảng Bình" },
    { value: "32", label: "Quảng Trị" },
    { value: "33", label: "Thừa Thiên Huế" },
    { value: "34", label: "Quảng Nam" },
    { value: "35", label: "Quảng Ngãi" },
    { value: "36", label: "Bình Định" },
    { value: "37", label: "Phú Yên" },
    { value: "38", label: "Khánh Hòa" },
    { value: "39", label: "Ninh Thuận" },
    { value: "40", label: "Bình Thuận" },
    { value: "41", label: "Kon Tum" },
    { value: "42", label: "Gia Lai" },
    { value: "43", label: "Đắk Lắk" },
    { value: "44", label: "Đắk Nông" },
    { value: "45", label: "Lâm Đồng" },
    { value: "46", label: "Bình Phước" },
    { value: "47", label: "Tây Ninh" },
    { value: "48", label: "Bình Dương" },
    { value: "49", label: "Đồng Nai" },
    { value: "50", label: "Bà Rịa - Vũng Tàu" },
    { value: "51", label: "Long An" },
    { value: "52", label: "Tiền Giang" },
    { value: "53", label: "Bến Tre" },
    { value: "54", label: "Trà Vinh" },
    { value: "55", label: "Vĩnh Long" },
    { value: "56", label: "Đồng Tháp" },
    { value: "57", label: "An Giang" },
    { value: "58", label: "Kiên Giang" },
    { value: "59", label: "Cần Thơ" },
    { value: "60", label: "Hậu Giang" },
    { value: "61", label: "Sóc Trăng" },
    { value: "62", label: "Bạc Liêu" },
    { value: "63", label: "Cà Mau" },
];