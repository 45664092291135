import React from "react";
import * as disLabel from "../../constants/label";
import Select from "react-select";
import appStore from '../../atribute/AppStore';
import { observer } from 'mobx-react';
import http from "../../axios/http-common";
import ReactLoading from "react-loading";
import { Article, atribute } from "../../atribute/Loading";
import * as area from "../../constants/area";
import * as Citys from "../../constants/city";
import { FaSearch} from 'react-icons/fa';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: '#fff',
    minHeight: '46px',
    height: '46px',
    width: '210px',
    fontSize: 'medium',
    '@media (max-width: 767px)': {
      width: '180px',
    },
    '&:hover': {
      border: '#fff',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'hidden',
  }),
  singleValue: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
};

class TabSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      estates: area.estateCateArr,
      citys: Citys.citys,
      costs: area.costArr,
      areaArr: area.areaArr,

      searchvalue: [],
      toggleState: 1,
      estateSelected1: false,
      estateSelected2: false,
      estateSelected3: false,
      estateSelectedTab1: false,
      estateSelectedTab2: false,
      estateSelectedTab3: false,
      fields_search1: { tabNo: '1', estateName: '', searchString: '', selectCity: '', selectPrice: '', area: { lowerArea: 0, upperArea: 0 }},
      fields_search2: { tabNo: '2', estateName: '', searchString: '', selectCity: '', selectPrice: '', area: { lowerArea: 0, upperArea: 0 }},
      fields_search3: { tabNo: '3', estateName: '', searchString: '', selectCity: '', selectPrice: '', area: { lowerArea: 0, upperArea: 0 }},
      isSearchLoading:false,
    };
  }

  OnchangeSelect = (selection, selectName) => {
    
    let fields;
    // Click vào chọn tab khi thực hiện search
    if (this.state.toggleState === 1) {
      fields = this.state.fields_search1;

      switch (selectName) {
        case "estates-select":
          fields["estateName"] = selection.label;
          break;
        case "string-search":
          fields["searchString"] = selection.target.value;
          break;
        case "city-select":
          fields["selectCity"] = selection.label;
          break;
        case "price-select":
          fields["selectPrice"] = this.convertToPriceFullNum(selection.label);
          break;
        case "area-select":
          let areaConverted = this.convertAreaToUpDown(selection.label);
          if(areaConverted === "upper50"){
            fields["area"]= {lowerArea: 1, upperArea: 50};
          } else if(areaConverted === "upper100"){
            fields["area"]= {lowerArea: 50, upperArea: 100};
          } else if(areaConverted === "upper150"){
            fields["area"]= {lowerArea: 100, upperArea: 150};
          } else if(areaConverted === "upper200"){
            fields["area"]= {lowerArea: 150, upperArea: 200};
          } else if(areaConverted === "upper250"){
            fields["area"]= {lowerArea: 200, upperArea: 250};
          } else if(areaConverted === "upper300"){
            fields["area"]= {lowerArea: 250, upperArea: 300};
          } else if(areaConverted === "upper350"){
            fields["area"]= {lowerArea: 300, upperArea: 350};
          } else if(areaConverted === "upper400"){
            fields["area"]= {lowerArea: 350, upperArea: 400};
          } else if(areaConverted === "upper450"){
            fields["area"]= {lowerArea: 400, upperArea: 450};
          } else if(areaConverted === "upper500"){
            fields["area"]= {lowerArea: 450, upperArea: 500};
          } else if(areaConverted === "upper10000"){
            fields["area"]= {lowerArea: 500, upperArea: 10000};
          } else {
            fields["area"]= {lowerArea: 0, upperArea: 0};
          }
          break;
        default:
          break;
      }

      this.setState({fields_search1: fields});
      this.setState({ estateSelectedTab1: true });

    } else if (this.state.toggleState === 2) {

      fields = this.state.fields_search2;
  
      switch (selectName) {
        case "estates-select":
          fields["estateName"] = selection.label;
          break;
        case "string-search":
          fields["searchString"] = selection.target.value;
          break;
        case "city-select":
          fields["selectCity"] = selection.label;
          break;
        case "price-select":
          fields["selectPrice"] = this.convertToPriceFullNum(selection.label);
          break;
        case "area-select":
          let areaConverted = this.convertAreaToUpDown(selection.label);
          if(areaConverted === "upper50"){
            fields["area"]= {lowerArea: 1, upperArea: 50};
          } else if(areaConverted === "upper100"){
            fields["area"]= {lowerArea: 50, upperArea: 100};
          } else if(areaConverted === "upper150"){
            fields["area"]= {lowerArea: 100, upperArea: 150};
          } else if(areaConverted === "upper200"){
            fields["area"]= {lowerArea: 150, upperArea: 200};
          } else if(areaConverted === "upper250"){
            fields["area"]= {lowerArea: 200, upperArea: 250};
          } else if(areaConverted === "upper300"){
            fields["area"]= {lowerArea: 250, upperArea: 300};
          } else if(areaConverted === "upper350"){
            fields["area"]= {lowerArea: 300, upperArea: 350};
          } else if(areaConverted === "upper400"){
            fields["area"]= {lowerArea: 350, upperArea: 400};
          } else if(areaConverted === "upper450"){
            fields["area"]= {lowerArea: 400, upperArea: 450};
          } else if(areaConverted === "upper500"){
            fields["area"]= {lowerArea: 450, upperArea: 500};
          } else if(areaConverted === "upper10000"){
            fields["area"]= {lowerArea: 500, upperArea: 10000};
          } else {
            fields["area"]= {lowerArea: 0, upperArea: 0};
          }
          break;
        default:
          break;
      }

      this.setState({ estateSelectedTab2: true });
    } else {
      fields = this.state.fields_search3;
  
      switch (selectName) {
        case "estates-select":
          fields["estateName"] = selection.label;
          break;
        case "string-search":
          fields["searchString"] = selection.target.value;
          break;
        case "city-select":
          fields["selectCity"] = selection.label;
          break;
        case "price-select":
          fields["selectPrice"] = this.convertToPriceFullNum(selection.label);
          break;
        case "area-select":
          let areaConverted = this.convertAreaToUpDown(selection.label);
          if(areaConverted === "upper50"){
            fields["area"]= {lowerArea: 1, upperArea: 50};
          } else if(areaConverted === "upper100"){
            fields["area"]= {lowerArea: 50, upperArea: 100};
          } else if(areaConverted === "upper150"){
            fields["area"]= {lowerArea: 100, upperArea: 150};
          } else if(areaConverted === "upper200"){
            fields["area"]= {lowerArea: 150, upperArea: 200};
          } else if(areaConverted === "upper250"){
            fields["area"]= {lowerArea: 200, upperArea: 250};
          } else if(areaConverted === "upper300"){
            fields["area"]= {lowerArea: 250, upperArea: 300};
          } else if(areaConverted === "upper350"){
            fields["area"]= {lowerArea: 300, upperArea: 350};
          } else if(areaConverted === "upper400"){
            fields["area"]= {lowerArea: 350, upperArea: 400};
          } else if(areaConverted === "upper450"){
            fields["area"]= {lowerArea: 400, upperArea: 450};
          } else if(areaConverted === "upper500"){
            fields["area"]= {lowerArea: 450, upperArea: 500};
          } else if(areaConverted === "upper10000"){
            fields["area"]= {lowerArea: 500, upperArea: 10000};
          } else {
            fields["area"]= {lowerArea: 0, upperArea: 0};
          }
          break;
        default:
          break;
      }

      this.setState({ estateSelectedTab3: true });
    }
  };

  convertToPriceFullNum = (price) => {
    if (price === 'dưới 1 tỷ') {
      return 1000000000
    } else if (price === 'dưới 2 tỷ') {
      return 2000000000
    } else if (price === 'dưới 3 tỷ') {
      return 3000000000
    } else if (price === 'dưới 4 tỷ') {
      return 4000000000
    } else if (price === 'dưới 5 tỷ') {
      return 5000000000
    } else if (price === 'dưới 6 tỷ') {
      return 6000000000
    } else if (price === 'dưới 7 tỷ') {
      return 7000000000
    } else if (price === 'dưới 8 tỷ') {
      return 8000000000
    } else if (price === 'dưới 9 tỷ') {
      return 9000000000
    } else if (price === 'dưới 10 tỷ') {
      return 10000000000
    } else {
      return ''
    }
  }

  convertAreaToUpDown = (area) => {
    if (area === '0-50 m²') {
      return "upper50";
    } else if (area === '50-100 m²') {
      return "upper100";
    } else if (area === '100-150 m²') {
      return "upper150";
    } else if (area === '150-200 m²') {
      return "upper200";
    } else if (area === '200-250 m²') {
      return "upper250";
    } else if (area === '250-300 m²') {
      return "upper300";
    } else if (area === '300-350 m²') {
      return "upper350";
    } else if (area === '350-400 m²') {
      return "upper400";
    } else if (area === '400-450 m²') {
      return "upper450";
    } else if (area === '450-500 m²') {
      return "upper500";
    } else　if (area === '500-10000 m²')  {
      return "upper10000";
    } else {
      return "Chọn diện tích";
    }
  }

  toggleTab = (index) => {
    this.setState({ toggleState: index });
  };

  searchHandler = () => {
    this.setState({ isSearchLoading: true});
    let searchDataReq;
    if (this.state.toggleState === 1) {
      searchDataReq =
      {
        "tabNo": this.state.fields_search1.tabNo,
        "estateName": this.state.fields_search1.estateName,
        "searchString": this.state.fields_search1.searchString,
        "selectCity": this.state.fields_search1.selectCity,
        "selectPrice": this.state.fields_search1.selectPrice,
        "lowerArea": this.state.fields_search1.area.lowerArea,
        "upperArea": this.state.fields_search1.area.upperArea,
      }
    } else if (this.state.toggleState === 2) {
      searchDataReq =
      {
        "tabNo": this.state.fields_search2.tabNo,
        "estateName": this.state.fields_search2.estateName,
        "searchString": this.state.fields_search2.searchString,
        "selectCity": this.state.fields_search2.selectCity,
        "selectPrice": this.state.fields_search2.selectPrice,
        "lowerArea": this.state.fields_search2.area.lowerArea,
        "upperArea": this.state.fields_search2.area.upperArea,
      }
    } else {
      searchDataReq =
      {
        "tabNo": this.state.fields_search3.tabNo,
        "estateName": this.state.fields_search3.estateName,
        "searchString": this.state.fields_search3.searchString,
        "selectCity": this.state.fields_search3.selectCity,
        "selectPrice": this.state.fields_search3.selectPrice,
        "lowerArea": this.state.fields_search3.area.lowerArea,
        "upperArea": this.state.fields_search3.area.upperArea,
      }
    }
    try {
      http.post(`/search/`, searchDataReq)
        .then(res => {
          if (res.status === 200) {
            // set false vào isSearchLoading để ẩn đi
            this.setState({ isSearchLoading: false });

            if (res.data.results.length === 0) {
              appStore.setHasSearchResults(false);
              appStore.setSearchResultList(res.data);
            } else {
              appStore.setHasSearchResults(true);
              appStore.setSearchResultList(res.data);
            }
          } else {
            console.log("Xuất hiện lỗi system trong quá trình Search.");
          }
        });
    } catch (error) { console.log(error) }
  };

  render() {

    return (
      <div className="tabs-search">
        {this.state.isSearchLoading === true ? (
          <div className="loading-spiner">
            {atribute.map(loading => (
              <Article key={loading.prop}>
                <ReactLoading type={loading.prop} color="#FF6800" />
              </Article>
            ))}
          </div>
        ) : (<div></div>)}
        <div className="bloc-tabs">
          <button
            className={this.state.toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => this.toggleTab(1)}
          >
            {disLabel.ESTATE_SELL_TAB_SEARCH}
          </button>
          <button
            className={this.state.toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => this.toggleTab(2)}
          >
            {disLabel.ESTATE_FOR_RENT}
          </button>
          <button
            className={this.state.toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => this.toggleTab(3)}
          >
            {disLabel.ESTATE_INVEST_TAB_SEARCH}
          </button>
        </div>
        <div className="content-tabs">
          <div className={this.state.toggleState === 1 ? "content active-content" : "content"} >
            <div className="container-search">
              <div className="search">
                <div className="line-selection-1">
                  <div className="estates-select">
                    <Select
                      name="estates-select"
                      className="custom-select-estates"
                      placeholder="Chọn nhà đất"
                      options={this.state.estates}
                      isSearchable={true}
                      maxMenuHeight="300px"
                      styles={customStyles}
                      onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select")}
                    />
                  </div>
                  <div className="search-area">
                    <input
                      type="text"
                      placeholder="Tìm theo từ khóa"
                      onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "string-search")} />
                    <button className="btn-search-homepage" onClick={this.searchHandler}>{disLabel.SEARCH_BTN}</button>
                    <span onClick={this.searchHandler}><FaSearch className="icon-search-homepage" id="FaSearch-icon" size={30} style={{ fill: '#FF6800' }} /></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-selection-2">
              <div className="form-group col-sm-4">
                <Select
                  id="estates-city-home"
                  name="estates-select"
                  placeholder="Chọn tỉnh, thành phố"
                  options={this.state.citys}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "city-select")}
                />
              </div>
              <div className="form-group col-sm-4">
                <Select
                  id="estates-price-home"
                  name="estates-select"
                  placeholder="Chọn mức giá"
                  options={this.state.costs}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "price-select")}
                />
              </div>
              <div className="form-group col-sm-4">
                <Select
                  id="estates-area-home"
                  name="estates-select"
                  placeholder="Chọn diện tích"
                  options={this.state.areaArr}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "area-select")}
                />
              </div>
            </div>
          </div>
          <div className={this.state.toggleState === 2 ? "content active-content" : "content"} >
          <div className="container-search">
              <div className="search">
                <div className="line-selection-1">
                  <div className="estates-select">
                    <Select
                      name="estates-select"
                      className="custom-select-estates"
                      placeholder="Chọn nhà đất"
                      options={this.state.estates}
                      isSearchable={true}
                      maxMenuHeight="300px"
                      styles={customStyles}
                      onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select")}
                    />
                  </div>
                  <div className="search-area">
                    <input
                      type="text"
                      placeholder="Tìm theo từ khóa"
                      onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "string-search")} />
                    <button className="btn-search-homepage" onClick={this.searchHandler}>{disLabel.SEARCH_BTN}</button>
                    <span onClick={this.searchHandler}><FaSearch className="icon-search-homepage" id="FaSearch-icon" size={30} style={{ fill: '#FF6800' }} /></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-selection-2">
              <div className="form-group col-sm-4">
                <Select
                  id="estates-city-home"
                  name="estates-select"
                  placeholder="Chọn tỉnh, thành phố"
                  options={this.state.citys}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "city-select")}
                />
              </div>
              <div className="form-group col-sm-4">
                <Select
                  id="estates-price-home"
                  name="estates-select"
                  placeholder="Chọn mức giá"
                  options={this.state.costs}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "price-select")}
                />
              </div>
              <div className="form-group col-sm-4">
                <Select
                  id="estates-area-home"
                  name="estates-select"
                  placeholder="Chọn diện tích"
                  options={this.state.areaArr}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "area-select")}
                />
              </div>
            </div>
          </div>
          <div className={this.state.toggleState === 3 ? "content active-content" : "content"} >
          <div className="container-search">
              <div className="search">
                <div className="line-selection-1">
                  <div className="estates-select">
                    <Select
                      name="estates-select"
                      className="custom-select-estates"
                      placeholder="Chọn nhà đất"
                      options={this.state.estates}
                      isSearchable={true}
                      maxMenuHeight="300px"
                      styles={customStyles}
                      onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select")}
                    />
                  </div>
                  <div className="search-area">
                    <input
                      type="text"
                      placeholder="Tìm theo từ khóa"
                      onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "string-search")} />
                    <button className="btn-search-homepage" onClick={this.searchHandler}>{disLabel.SEARCH_BTN}</button>
                    <span onClick={this.searchHandler}><FaSearch className="icon-search-homepage" id="FaSearch-icon" size={30} style={{ fill: '#FF6800' }} /></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="line-selection-2">
              <div className="form-group col-sm-4">
                <Select
                  id="estates-city-home"
                  name="estates-select"
                  placeholder="Chọn tỉnh, thành phố"
                  options={this.state.citys}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "city-select")}
                />
              </div>
              <div className="form-group col-sm-4">
                <Select
                  id="estates-price-home"
                  name="estates-select"
                  placeholder="Chọn mức giá"
                  options={this.state.costs}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "price-select")}
                />
              </div>
              <div className="form-group col-sm-4">
                <Select
                  id="estates-area-home"
                  name="estates-select"
                  placeholder="Chọn diện tích"
                  options={this.state.areaArr}
                  isSearchable={true}
                  maxMenuHeight="300px"
                  onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "area-select")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default observer(TabSearch);
