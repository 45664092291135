import React from "react";


class introducePage extends React.Component {


  render() {

    return (
      <div className="container-rule-parent">
        <span className="rule-use-title-lv1">Về Raozat.com</span>
        <div className="rule-use-content-lv2">
        <span >Raozat.com - nền tảng thương mại điện tử đáng tin cậy, mở cửa giao dịch miễn phí, tạo ra một trải nghiệm mua bán độc đáo, không giống bất kỳ hệ thống nào khác. Chúng tôi không chỉ là điểm đến để bạn giao dịch bất động sản mà còn là hành trình đặc sắc với những công nghệ tiên tiến.</span><br/>
        <span >Với nguồn dữ liệu phong phú trong danh mục bất động sản, Raozat.com xây dựng một cộng đồng mua bán trực tuyến mạnh mẽ và đáng tin cậy. Chúng tôi cam kết mang đến trải nghiệm mua bán an toàn, minh bạch và thuận lợi cho người dùng.</span><br/>
        <span >Sứ mệnh của chúng tôi là tập trung vào người dùng, từ việc cung cấp chế độ map đa dạng giúp thuận tiện tìm kiếm đến việc xem bản đồ trực tiếp ngay trên trang web. Sử dụng công nghệ thiết kế web hiện đại, chúng tôi hỗ trợ cộng đồng mua bán bất động sản trở nên dễ dàng và hiệu quả hơn bao giờ hết. Luôn lắng nghe và tiếp thu ý kiến từ khách hàng, Raozat.com cam kết không ngừng cải thiện để đáp ứng mọi nhu cầu của bạn. Raozat.com - nơi bạn có thể tin tưởng, trải nghiệm sự thuận lợi và chúng tôi không ngừng tiến bộ cùng bạn.</span>

        </div>
      </div>
    );
  }
}
export default introducePage;
