import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from "reactstrap";
import * as disLabel from "../constants/label";
import * as disErrMess from "../constants/errorMessage";
import http from "../axios/http-common";
import { observer } from 'mobx-react';
import appStore from '../../components/atribute/AppStore';
import ReCAPTCHA from "react-google-recaptcha";
import { FaEyeSlash, FaEye, FaUser, FaLock, FaMailBulk } from 'react-icons/fa';
require('dotenv').config();


const recaptcha_key = process.env.REACT_APP_SITE_KEY;


class SignUpModal extends React.Component {
  constructor(props) {
    super(props);
    this.recaptcha = null;

    this.state = {
      isRecaptchaVerified: false,
      recaptchaError: "",
      passwordVisible: false,
      rePasswordVisible: false,
      isModalFormNotRegisVisible: true,
      isModalFormCompRegisVisible: false,
      fields: { name: '', email: '', password: '', repassword: '' },
      viewedDetail: JSON.parse(localStorage.getItem("viewed_detail_estate")) || [],
      likedArr: JSON.parse(localStorage.getItem("liked_estate")) || [],
      errors: {},
    };
  }

  togglePasswordVisibility = (e, field) => {
    e.preventDefault();
    if (field === "password") {
      this.setState({ passwordVisible: !this.state.passwordVisible });
    };
    if (field === "repassword") {
      this.setState({ rePasswordVisible: !this.state.rePasswordVisible });
    };
  }

  // get value from element then set to state
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  // Validate form
  handleValidate(e) {
    // prevent load form when submit
    e.preventDefault();

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // Check name
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = disErrMess.ERROR_NOT_INPUT_NAME;
    } else if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^\S*$/)) {
        formIsValid = false;
        errors["name"] = disErrMess.ERROR_INPUT_NAME_NOT_VALID;
      }
    }

    // Check email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = disErrMess.ERROR_NOT_INPUT_EMAIL;
    } else if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = disErrMess.ERROR_INPUT_EMAIL_NOT_VALID;
      }
    }

    // Check Password
    if (!fields["password"] || !fields["repassword"]) {
      formIsValid = false;
      errors["repassword"] = disErrMess.ERROR_INPUT_PASSWORD;
    } else if (fields["password"].length < 8 || fields["repassword"].length < 8) {
      formIsValid = false;
      errors["repassword"] = disErrMess.ERROR_INPUT_PASSWORD_LESSTHAN_8_CHARACTER;
    } else if (fields["password"] !== fields["repassword"]) {
      formIsValid = false;
      errors["repassword"] = disErrMess.ERROR_INPUT_PASSWORD_NOT_THE_SAME;
    } else if (typeof fields["password"] !== "undefined" || typeof fields["repassword"] !== "undefined") {
      if (!fields["password"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}/)) {
        formIsValid = false;
        errors["repassword"] = disErrMess.ERROR_INPUT_PASSWORD_NOT_VALID;
      }
    }

    this.setState({ errors: errors });

    // submit form
    let registerData =
    {
      "username": this.state.fields.name,
      "password": this.state.fields.password,
      "email": this.state.fields.email,
    };

    if (formIsValid !== false) {

      if (!this.state.isRecaptchaVerified) {
        this.setState({
          recaptchaError: "Chọn tôi không phải robot để tiếp tục."
        });
        return;
      }

      http.post(`/register/`, registerData)
        .then(res => {
          if (res.status === 201) {
            this.handleReset();
            this.toggleModalBody();
          }
        })
        .catch(error => {
          try {
            // Catch lỗi sử dụng mail đã đăng ký từ trước.
            if (typeof error.response.data.email !== 'undefined') {
              errors["email"] = disErrMess.ERROR_INPUT_EMAIL_HAS_USED;
            }
            // Catch lỗi sử dụng username đã đăng ký từ trước.
            if (typeof error.response.data.username !== 'undefined') {
              errors["name"] = disErrMess.ERROR_INPUT_NAME_INVALID;

            }
            this.setState({ errors: errors })
          } catch (error) {
            console.log('Error:', error);
          }
        });
    }

    return formIsValid;
  };

  handleReset = () => {
    this.setState({
      fields: { name: '', email: '', password: '', repassword: '' },
      errors: {},
      recaptchaError: '',
    });
  };

  toggleModalBody = () => {
    this.setState(prevState => ({
      isModalFormNotRegisVisible: !prevState.isModalFormNotRegisVisible,
      isModalFormCompRegisVisible: !prevState.isModalFormCompRegisVisible,
    }));
  };

  handleRecaptchaChange = (value) => {
    if (value) {
      this.setState({
        isRecaptchaVerified: true,
        recaptchaError: ""
      });
    } else {
      this.setState({
        isRecaptchaVerified: false,
        recaptchaError: "Chọn tôi không phải robot để tiếp tục."
      });
    }
  };

  render() {
    const { toggle } = this.props;
    appStore.setCountLikedClick(this.state.likedArr.length);
    appStore.setCountLikedClick(this.state.viewedDetail.length);

    const lang = {
      hl: "en"
    };

    return (
      <div>
        <Modal isOpen={true} toggle={toggle}>
          <ModalHeader toggle={toggle}>{disLabel.REGISTER_FORM}</ModalHeader>
          {this.state.isModalFormNotRegisVisible && (
            <ModalBody>
              <Form>
                <FormGroup id="signup" className="modal-form">
                  <FaUser className="signup-icon" size={25} fill="#FF6800" />
                  <Input
                    className="signup-info"
                    type="text"
                    id="name"
                    name="name"
                    value={this.state.fields.name}
                    onChange={this.handleChange.bind(this, "name")}

                    placeholder="Nhập tên đăng nhập"
                  />
                  <span className="text-danger">{this.state.errors["name"]}</span>
                </FormGroup>
                <FormGroup id="signup" className="modal-form">
                  <FaLock className="signup-icon" size={25} fill="#FF6800" />
                  <Input
                    className="signup-info"
                    type={this.state.passwordVisible ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={this.state.fields.password}
                    onChange={this.handleChange.bind(this, "password")}
                    maxLength="32"
                    placeholder="Nhập password"
                  />
                  <a className="button-visible-password" onClick={(e) => this.togglePasswordVisibility(e, "password")}>
                    {this.state.passwordVisible ?
                      <FaEye className="show-password-entered" size={25} fill="#e46918" /> :
                      <FaEyeSlash className="show-password-entered" size={25} fill="#e46918" />
                    }
                  </a>
                  <FaLock className="signup-repassword-icon" size={25} fill="#FF6800" />
                  <Input
                    className="signup-info"
                    type={this.state.rePasswordVisible ? 'text' : 'password'}
                    id="signup-repassword"
                    name="signup-repassword"
                    value={this.state.fields.repassword}
                    onChange={this.handleChange.bind(this, "repassword")}
                    maxLength="32"
                    placeholder="Xác nhận lại password"
                  />
                  <a className="button-visible-password" onClick={(e) => this.togglePasswordVisibility(e, "repassword")}>
                    {this.state.rePasswordVisible ?
                      <FaEye className="show-repassword-entered" size={25} fill="#e46918" /> :
                      <FaEyeSlash className="show-repassword-entered" size={25} fill="#e46918" />
                    }
                  </a>
                  <span id="repassword" className="text-danger">{this.state.errors["repassword"]}</span>
                </FormGroup>
                <FormGroup id="signup" className="modal-form">
                  <FaMailBulk className="signup-email-icon" size={25} fill="#FF6800" />
                  <Input
                    className="signup-info"
                    type="text"
                    id="email"
                    name="email"
                    value={this.state.fields.email}
                    onChange={this.handleChange.bind(this, "email")}
                    maxLength="30"
                    placeholder="Nhập email"
                  />
                  <span id="email" className="text-danger">{this.state.errors["email"]}</span>
                </FormGroup>
              </Form>

              <div className="signup-recaptcha-container">
                <ReCAPTCHA
                  className="recaptcha-small"
                  ref={(ref) => this.recaptcha = ref}
                  sitekey={recaptcha_key}
                  onChange={this.handleRecaptchaChange}
                  lang={lang}
                />
              </div>
              <div>{this.state.recaptchaError && (
                <div className="im-not-robot">
                  {this.state.recaptchaError}
                </div>
              )}</div>
              <ModalFooter className="modal-footer">
                <Button
                  color="btn btn-secondary"
                  onClick={this.handleReset}
                >
                  {disLabel.DELETE_BTN}
                </Button>
                <Button
                  color="btn btn-info"
                  onClick={this.handleValidate.bind(this)}
                >
                  {disLabel.REGISTER_BTN}
                </Button>
              </ModalFooter>
            </ModalBody>)}
          {this.state.isModalFormCompRegisVisible && (
            <ModalBody>
              <span className="completed-register">Vui lòng truy cập vào email để kích hoạt account vừa đăng ký.</span>
            </ModalBody>
          )}
        </Modal>
      </div>
    );
  }
}
export default observer(SignUpModal);