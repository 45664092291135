import React from "react";
import { Form } from "react-bootstrap";
import * as disLabel from "../../constants/label";

class PublishGuideline extends React.Component {

    render() {
        return (
            <div className="div-guideline">
                <div><h5 className="title_display_default">{disLabel.CHANGE_PW_GUIDLINE}</h5></div><br/>
                <span>● Tại phần menu bên phải chọn vào thay đổi password.</span><br/>
                <span>● Nhập mật khẩu hiện tại và mật khẩu mới để đổi mật khẩu.</span><br/>
                <span>● Chọn tôi không phải là robot và click vào button thay đổi để hoàn thành.</span>
                <img src="/images/thaydoipass_1.png" alt="Active Account" style={{ width: '100%' }} />
            </div>
        );
    }
}

export default PublishGuideline;