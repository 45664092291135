import React,{ Component} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from "reactstrap";
import * as disLabel from "../constants/label";
import * as disErrMess from "../constants/errorMessage";
import http from "../axios/http-common";
import { observer } from 'mobx-react';
import appStore from '../../components/atribute/AppStore';
import SetCookies from '../hooks/SetCookie';
import RemoveCookies from '../hooks/RemoveCookie';
import ReCAPTCHA from "react-google-recaptcha";
import { FaEyeSlash, FaEye, FaUser, FaLock } from 'react-icons/fa';
require('dotenv').config();


const recaptcha_key = process.env.REACT_APP_SITE_KEY;

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.recaptcha = null;

    this.state = {
      isRecaptchaVerified: false,
      recaptchaError: "",
      signUpModal: false,
      passwordVisible: false,
      fields: { username: "", password: "" },
      viewedDetail: JSON.parse(localStorage.getItem("viewed_detail_estate")) || [],
      likedArr: JSON.parse(localStorage.getItem("liked_estate")) || [],
      errors: {},
    };
  }

  togglePasswordVisibility = (e, field) => {
    e.preventDefault();
    if (field === "PasswordVisible") {
      this.setState({ passwordVisible: !this.state.passwordVisible });
    };
  }

  // get value from element then set to state
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  };

  // close login form and open signup form
  openSignUpForm = (e) => {
    e.preventDefault();
    this.props.isOpenSignupForm(false);
  };

  // Validate form
  SubmitForm(e) {

    // prevent load form when submit
    e.preventDefault();

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // Check Username
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = disErrMess.ERROR_NOT_INPUT_USERNAME;
    } else if (typeof fields["username"] !== "undefined") {
      if (!fields["username"].match(/^\S*$/)) {
        formIsValid = false;
        errors["username"] = disErrMess.ERROR_INPUT_USERNAME_NOT_VALID;
      }
    }

    // Check Password
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = disErrMess.ERROR_INPUT_PASSWORD;
    } else if (fields["password"].length < 8) {
      formIsValid = false;
      errors["password"] =
        disErrMess.ERROR_INPUT_PASSWORD_LESSTHAN_8_CHARACTER;
    } else if (typeof fields["password"] !== "undefined") {
      if (!fields["password"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}/)) {
        formIsValid = false;
        errors["password"] = disErrMess.ERROR_INPUT_PASSWORD_NOT_VALID;
      }
    }

    this.setState({ errors: errors });


    // submit form
    let loginDataReq = 
      {
        "username": this.state.fields.username,
        "password": this.state.fields.password,
      }
    if (formIsValid !== false) {

      if (!this.state.isRecaptchaVerified) {
        this.setState({
          recaptchaError: "Chọn tôi không phải robot để tiếp tục."
        });
        return;
      }
      
      (async () => {
        try {
          const response = await http.post(`/token/`, loginDataReq);
          if (response.status === 200) {
            RemoveCookies('access_token');
            RemoveCookies('refresh_token');
            SetCookies('access_token',JSON.stringify(response.data.access));
            SetCookies('refresh_token',JSON.stringify(response.data.refresh));
            
            localStorage.setItem('username', response.data.username);
            window.location.reload();
          } else {
            console.log("Xuất hiện lỗi system trong quá trình đăng tin.");
          }
        } catch (error) {
          console.log(error);
          if (error.data !== "") {
            errors["password"] = disErrMess.ERROR_INPUT_PASSWORD_OR_USER;
            this.setState({ errors: errors });
          }
        }
      })();
    }

    return formIsValid;
  }

  handleReset = () => {
    this.setState({
      fields : { username: '', password: '' },
      errors : {},
      recaptchaError: '',
    });
  };

  handleRecaptchaChange = (value) => {
    if (value) {
      this.setState({
        isRecaptchaVerified: true,
        recaptchaError: ""
      });
    } else {
      this.setState({
        isRecaptchaVerified: false,
        recaptchaError: "Chọn tôi không phải robot để tiếp tục."
      });
    }
  };
  

  render() {
    const { toggle } = this.props;
    appStore.setCountLikedClick(this.state.likedArr.length);
    appStore.setCountLikedClick(this.state.viewedDetail.length);

    const lang = {
      hl: "en"
    };

    return (
      <div>
          <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>{disLabel.LOGIN_FORM}</ModalHeader>
            <ModalBody>
              <FormGroup className="modal-form">
                <FaUser className="login-icon" size={25} fill="#FF6800" />
                <Input
                  className="login-info"
                  type="text"
                  id="username"
                  name="username"
                  onChange={this.handleChange.bind(this, "username")}
                  value={this.state.fields.username}
                  maxLength="30"
                  placeholder="Nhập tên đăng nhập"
                />
                <span className="text-danger">
                  {this.state.errors["username"]}
                </span>
              </FormGroup>
              <FormGroup className="modal-form">
                <FaLock className="login-icon-password" size={25} fill="#FF6800" />
                <Input
                  className="login-info"
                  type={this.state.passwordVisible ? 'text' : 'password'}
                  id="login-password"
                  name="login-password"
                  onChange={this.handleChange.bind(this, "password")}
                  value={this.state.fields.password}
                  maxLength="32"
                  placeholder="Nhập mật khẩu"
                />
                <a className="button-visible-password" onClick={(e) => this.togglePasswordVisibility(e, "PasswordVisible")}>
                  {this.state.passwordVisible ?
                    <FaEye className="show-password-entered" size={25} fill="#e46918" /> :
                    <FaEyeSlash className="show-password-entered" size={25} fill="#e46918" />
                  }
                </a>
                <span className="text-danger">{this.state.errors["password"]}</span>
                <span className="register-link">{disLabel.NOT_HAVENT_ACCOUNT}<a className="register-link-tag-a" href="/" onClick={this.openSignUpForm}>{disLabel.LET_SIGNUP_NOW}</a></span>
              </FormGroup>
            <div className="recaptcha-container">
              <ReCAPTCHA
                className="recaptcha-small"
                ref={(ref) => this.recaptcha = ref}
                sitekey={recaptcha_key}
                onChange={this.handleRecaptchaChange}
                lang={lang}
              />
            </div>
            <div>{this.state.recaptchaError && (
              <div className="im-not-robot">
                {this.state.recaptchaError}
              </div>
            )}</div>
              <ModalFooter className="modal-footer">
              <Button color="btn btn-secondary" onClick={this.handleReset}>
                {disLabel.DELETE_BTN}
              </Button>
              <Button
                color="btn btn-info"
                onClick={this.SubmitForm.bind(this)}
              >
                {disLabel.LOGIN_BTN}
              </Button>
            </ModalFooter>
            </ModalBody>
          </Modal>
      </div>
    );
  }
}
export default observer(LoginModal);
