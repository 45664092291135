import React from "react";
import { Form } from "react-bootstrap";
import * as disLabel from "../../constants/label";

class LikedEsteGuideline extends React.Component {

    render() {
        return (
            <div className="div-guideline">
                <div><h5 className="title_display_default">{disLabel.LIKED_EST_GUIDLINE}</h5></div><br />
                <span>● Tại những bất động sản bạn đã "thả tim" sẽ được hiển thị tại trang dưới.</span><br />
                <span>● Và bạn hoàn toàn có thể xóa bất động sản yêu thích ra khỏi danh sách bằng cách bấm lại vào "thả tim".</span>
                <img src="/images/likedganday.png" alt="Active Account" style={{ width: '100%' }} />
            </div>
        );
    }
}

export default LikedEsteGuideline;