import React from "react";
import TabSearch from "./TabSearch";
import Publish from "./Publish";
import ViewDetailEstate from './ViewDetailEstate';
import * as disLabel from "../../constants/label";
import { LinkContainer } from "react-router-bootstrap";
import { Routes, Route } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import { observer } from 'mobx-react';
import appStore from "../../atribute/AppStore";
import SearchResultPage from '../../atribute/SearchResultPage';
import DisplayDefaultPage from '../../atribute/DisplayDefaultPage';
import EstatesInvestPage from '../../atribute/EstatesInvestPage';
import EstatesRentPage from '../../atribute/EstatesRentPage';
import ViewEstatesRecentPage from '../../atribute/ViewEstatesRecentPage';
import ViewEstatesLikedPage from '../../atribute/ViewEstatesLikedPage';
import InstructionPage from '../../atribute/InstructionPage';
import ContactPage from '../../atribute/ContactPage';
import MyPageTop from '../../atribute/MyPage';
import CampaignPage from '../../atribute/CampaignPage';
import RuleUsePage from '../../atribute/RuleUsePage';
import InfoSecurityPage from '../../atribute/InfoSecurityPage';
import IntroducePage from '../../atribute/introducePage';
import ActiveMailOKPage from './ActiveMailOK';
import ActiveMailNGPage from './ActiveMailNG';
import AccountActiveGuideline from './AccountActiveGuideline';
import ChangePWGuideline from './ChangePWGuideline';
import PublishGuideline from './PublishGuideline';
import RecentEsteGuideline from './RecentEsteGuideline';
import LikedEsteGuideline from './LikedEsteGuideline';

import { FaRegClock, FaRegHeart, FaUserCircle } from 'react-icons/fa';
import LoginModal from "../../modal/LoginModal";
import SignUpModal from "../../modal/SignUpModal";
import GetCookies from '../../hooks/GetCookie';
import NavbarCustomize from '../../atribute/Navbar';


class Content extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuth: !!GetCookies('access_token'),
      countViewedRecent: JSON.parse(localStorage.getItem("viewed_recent_estate")) || 0,
      countLikedRecent: JSON.parse(localStorage.getItem("liked_estate")) || 0,
      loginModal: false,
      signUpModal: false,
      activeItem: {
        userName: "",
        passWord: "",
        completed: false,
      },
      menuOpen: false,
    };
  }

  toggleLogin = () => {
    this.setState({ loginModal: !this.state.loginModal });
  };

  toggleSignUp = () => {
    this.setState({ signUpModal: !this.state.signUpModal });
  };

  // function đăng nhập
  createLoginModal = () => {
    this.setState({ loginModal: !this.state.loginModal });
  };

  // function đăng ký
  createSignUpModal = () => {
    this.setState({ signUpModal: !this.state.signUpModal });
  };

  createPublishEstate = () => {
    if (!this.state.isAuth) {
      this.createLoginModal();
    }
  };

  handleSubmitLogin = (item) => {
    this.toggleLogin();
  };

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen })
  };

  isClosedLoginForm = () => {
    this.toggleLogin();
    this.createSignUpModal();
  }

  render() {
    appStore.getSearchResultList();
    const CountLikedClick = appStore.getCountLikedClick();

    return (
      <div>
        <div className="container-header">
          <div className="">
            <div className="siteHeader-inner">
              <div className="logo">
                <a href="/"></a>

                <LinkContainer to="/">
                  <Nav.Link><img src={`${process.env.PUBLIC_URL}/images/raozat_logo.png`} alt="logo" /></Nav.Link>
                </LinkContainer>
              </div>
              <div className="nav-bar">
                <Navbar expand="lg" className={`custom-navbar ${this.state.menuOpen ? 'menu-open' : ''}`}>
                  <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={this.toggleMenu} />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <LinkContainer to="/">
                          <Nav.Link>Trang Chủ</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/nha-cho-thue">
                          <Nav.Link>Nhà Cho Thuê</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/du-an-dau-tu">
                          <Nav.Link>Dự Án Đầu Tư</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/huong-dan">
                          <Nav.Link>Hướng Dẫn</Nav.Link>
                        </LinkContainer>
                        {/* <LinkContainer to="/quang-cao-va-uu-dai">
                          <Nav.Link>Quảng cáo & ưu đãi</Nav.Link>
                        </LinkContainer> */}
                        <LinkContainer to="/lien-he">
                          <Nav.Link>Liên Hệ</Nav.Link>
                        </LinkContainer>
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </div>
              <NavbarCustomize />
            </div>


            <div className="container-right">
              <div className="div-header-content">
                <div className="view-estates-recent">
                  <LinkContainer to="/bat-dong-san-xem-gan-day">
                    <Nav.Link>
                      <span className="display-count-number-in-up_view" >
                        {this.state.countViewedRecent.length > 0 ? this.state.countViewedRecent.length : 0}
                      </span>
                      <FaRegClock id="faRegClock-icon" size={26} style={{ fill: '#FF6800' }} />
                    </Nav.Link>
                  </LinkContainer>
                </div>
                <div className="likes-estates-recent">
                  <LinkContainer to="/bat-dong-san-yeu-thich">
                    <Nav.Link>
                      <span className="display-count-number-in-up_like">
                        {CountLikedClick > 0 ? CountLikedClick : 0}</span>
                      <FaRegHeart id="faRegHeart-icon" size={26} style={{ fill: '#FF6800' }} />
                    </Nav.Link>
                  </LinkContainer>
                </div>
              </div>
              <div className="header">
                {this.state.isAuth === false ? (
                  <div className="logout_login-btn">
                    <Nav.Link onClick={this.createLoginModal}>
                      <span className="div-right-login">{disLabel.LOGIN_FORM}</span>
                    </Nav.Link>
                  </div>
                )
                  : (
                    ""
                  )}
              </div>
              {this.state.isAuth === true ? (
                <div className="container-div-right-publish">
                  <LinkContainer to="/dang-tin">
                    <Nav.Link>
                      <span className="div-right-publish">{disLabel.PUBLIST_BTN}</span>
                    </Nav.Link>
                  </LinkContainer>
                </div>
              ) : (
                <div className="container-div-right-publish">
                  <Nav.Link onClick={this.createPublishEstate}>
                    <span className="div-right-publish">{disLabel.PUBLIST_BTN}</span>
                  </Nav.Link>
                </div>
              )}
              {this.state.isAuth === true ? (
                <div className="user-circle-icon">
                  <LinkContainer to="/trang-cua-toi">
                    <Nav.Link><FaUserCircle size={30} style={{ fill: '#FF6800' }} /></Nav.Link>
                  </LinkContainer>
                </div>) : (<></>)}
            </div>
          </div>
        </div>
        {this.state.signUpModal ? (
          <SignUpModal toggle={this.toggleSignUp} />
        ) : null}
        {this.state.loginModal ? (
          <LoginModal isOpenSignupForm={this.isClosedLoginForm} toggle={this.toggleLogin} />
        ) : null}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/trang-chu" element={<Homepage />} />
          <Route path="/nha-cho-thue" element={<EstateForRent />} />
          <Route path="/du-an-dau-tu" element={<EstateForInvest />} />
          <Route path="/lien-he" element={<Contract />} />
          <Route path="/huong-dan" element={<Instruction />} />
          <Route path="/quang-cao-va-uu-dai" element={<Campaign />} />
          <Route path="/dang-tin" element={<PublishEstate />} />
          <Route path="/trang-cua-toi" element={<MyPage />} />
          <Route path="/xem-chi-tiet-bat-dong-san" element={<ViewDetailEstate />} />
          <Route path="/bat-dong-san-xem-gan-day" element={<ViewEstatesRecent />} />
          <Route path="/bat-dong-san-yeu-thich" element={<ViewEstatesLiked />} />
          <Route path="/quy-che-hoat-dong" element={<RuleUse />} />
          <Route path="/bao-mat-thong-tin" element={<InfoSecurity />} />
          <Route path="/gioi-thieu" element={<Introduce />} />
          <Route path="/kich-hoat-mail-thanh-cong" element={<ActiveMailOK />} />
          <Route path="/kich-hoat-mail-that-bai" element={<ActiveMailNG />} />
          <Route path="/huong-dan-kich-hoat-tai-khoan" element={<ActiveAccountGuidelineFnc />} />
          <Route path="/huong-dan-dang-bai" element={<PublishGuidelineFnc />} />
          <Route path="/huong-dan-doi-mat-khau" element={<ChangePWGuidelineFnc />} />
          <Route path="/huong-dan-xem-tin-bds-gan-day" element={<RecentEstetaGuidelineFnc />}/>
          <Route path="/huong-dan-xem-tin-bds-yeu-thich" element={<LikeEstetaGuidelineFnc />}/>
        </Routes>
      </div>
    );
  }
}

export default observer(Content);

function Homepage() {

  let searchResultList = appStore.getSearchResultList();
  let hasSearchResults = appStore.getHasSearchResults();
  return (
    <div className="page_container_search">
      <TabSearch />
      <div>
        {hasSearchResults ? (
          <div><SearchResultPage searchResultArr={searchResultList.results} itemsPerPage={30} /></div>
        ) : (
          <div>
            {searchResultList && searchResultList.results !== undefined ? (
              <div className="no-result-search">
                <div className="honepage-section_h2-header"><h5 className="title_display_default">{disLabel.ESTATE_RESULT_SEARCH}</h5></div>
                <span className="">{disLabel.NO_RESULT_SEARCH}</span>
              </div>
            ) : (
              <DisplayDefaultPage itemsPerPage={30} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function Instruction() {
  return (
    <div className="page-container-common">
      <InstructionPage />
    </div>
  );
}


function Campaign() {
  return (
    <div className="page-container-common">
      <CampaignPage />
    </div>
  );
}

function RuleUse() {
  return (
    <div className="page-container-common">
      <RuleUsePage />
    </div>
  );
}

function InfoSecurity() {
  return (
    <div className="page-container-common">
      <InfoSecurityPage />
    </div>
  );
}

function Introduce() {
  return (
    <div className="page-container-common">
      <IntroducePage />
    </div>
  );
}

function PublishEstate() {
  return (
    <div className="page-container-publish">
      <div className="page_content">
        <Publish />
      </div>
    </div>
  );
}

function EstateForRent() {
  return (
    <div className="page-container-for-rent-invest">
      <div><EstatesRentPage itemsPerPage={30} /></div>
    </div>
  );
}

function ViewEstatesRecent() {
  return (
    <div className="page-container-common">
      <div><ViewEstatesRecentPage /></div>
    </div>
  );
}

function ViewEstatesLiked() {
  return (
    <div className="page-container-common">
      <div><ViewEstatesLikedPage /></div>
    </div>
  );
}

function EstateForInvest() {

  return (
    <div className="page-container-for-rent-invest">
      <div><EstatesInvestPage itemsPerPage={30} /></div>
    </div>
  );
}

function Contract() {
  return (
    <div className="page-container-common">
      <ContactPage />
    </div>
  );
}

function MyPage() {
  return (
    <div className="page-container-common">
      <MyPageTop />
    </div>
  );
}

function ActiveMailOK() {
  return (
    <div className="page-container-common">
      <ActiveMailOKPage />
    </div>
  );
}

function ActiveMailNG() {
  return (
    <div className="page-container-common">
      <ActiveMailNGPage />
    </div>
  );
}

function ActiveAccountGuidelineFnc() {
  return (
    <div className="page-container-common">
      <AccountActiveGuideline />
    </div>
  );
}

function PublishGuidelineFnc() {
  return (
    <div className="page-container-common">
      <PublishGuideline />
    </div>
  );
}

function ChangePWGuidelineFnc() {
  return (
    <div className="page-container-common">
      <ChangePWGuideline />
    </div>
  );
}

function RecentEstetaGuidelineFnc() {
  return (
    <div className="page-container-common">
      <RecentEsteGuideline />
    </div>
  );
}

function LikeEstetaGuidelineFnc() {
  return (
    <div className="page-container-common">
      <LikedEsteGuideline />
    </div>
  );
}

