import React from "react";
import { Button, Input } from "reactstrap";
import * as disLabel from "../../constants/label";
import * as area from "../../constants/area";
import * as Citys from "../../constants/city";
import * as Provinces from "../../constants/provinces";
import * as Districts from "../../constants/districts";
import * as disErrMess from "../../constants/errorMessage";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Upload from '../../atribute/Upload';
import TextEditor from '../../atribute/TextEditor';
import http from "../../axios/http-common";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorModal from '../../modal/ErrorModal';
import SuccessModal from '../../modal/SuccessModal';
require('dotenv').config();


const recaptcha_key = process.env.REACT_APP_SITE_KEY;

class Publish extends React.Component {

  constructor(props) {
    super(props);

    this.recaptcha = null;

    this.state = {

      // Radio button
      provinceList: area.defaultOptions,
      districtList: area.defaultOptions,

      // Sử dụng khi đã chọn thành phố và tỉnh thành rồi nhưng muôn change thì select phường xã được trả về null
      changeCityFlag: false,
      changeProvinceFlag: false,

      // Sử dụng khi đã chọn thành phố và tỉnh thành được chọn
      selectedCity: null,
      selectedProvince: null,
      selectedDistrict: null,

      // Đọc dữ liệu từ file data
      estates: area.estateCateArr,
      citys: Citys.citys,

      legalDocument: area.legalDocument,
      unitVND: area.unitVND,
      directionHome: area.direction,
      bedRooms: area.bedRooms,
      livingRooms: area.livingRooms,
      toiletRooms: area.toiletRooms,
      transactionType: area.transactionType,
      raidoEstatesType: { estatesType_1: false, estatesType_2: false, estatesType_3: false },

      // Đọc dữ liệu từ màn hình
      estatesNameRq: "",
      estatesCityRq: "",
      estatesProvinceRq: "",
      estatesDistrictRq: "",
      estatesStreetRq: "",

      estatesTitleRq: "",
      estatesDetailRq: "",

      estatesAreaRq: "",
      estatesLegalDocRq: "",
      widthRoadRq: "",
      estatesPriceRq: "",
      priceAfterConvert: "",
      unitPriceToText: "",
      convertedPriceValue:"",
      estatesPriceForUnitRq: "",
      estatesDirectionRq: "",
      estatesNumsBedRoomRq: 0,
      estatesNumsGuestRoomRq: 0,
      estatesNumsBathAndToiletRoomRq: 0,

      contactNameRq: "",
      contactPhoneRq: "",
      transactionTypeRq: "",

      errors: {},
      errorModalOpen: false,
      errorMessage: '',
      successModalOpen: false,
      successMessage: '',
      resetPressed: false,
      uploadedFiles: [],

      isRecaptchaVerified: false,
      recaptchaError: "",
    };
  }

  // Nhận file image từ class Upload
  handleFilesChange = (files) => {
    this.setState({ uploadedFiles: files});
  };

  // chọn pulldown select city
  ChangeSelectCitys = (selectedOption) => {

    this.setState({ changeCityFlag: true });
    this.setState({ changeProvinceFlag: false });
    this.setState({ selectedProvince: null });
    this.setState({ selectedCity: selectedOption });

    switch (selectedOption.value) {
      case '1':
        this.setState({ selectedCity: "TP. Hồ Chí Minh" });
        this.setState({ provinceList: Provinces.hochiminh_province });
        break
      case '2':
        this.setState({ selectedCity: "Hà Nội" });
        this.setState({ provinceList: Provinces.hanoi_province });
        break
      case '3':
        this.setState({ selectedCity: "Đà Nẵng" });
        this.setState({ provinceList: Provinces.danang_province });
        break
      case '4':
        this.setState({ selectedCity: "Hà Giang" });
        this.setState({ provinceList: Provinces.hagiang_province });
        break
      case '5':
        this.setState({ selectedCity: "Cao Bằng" });
        this.setState({ provinceList: Provinces.caobang_province });
        break
      case '6':
        this.setState({ selectedCity: "Bắc Kạn" });
        this.setState({ provinceList: Provinces.baccan_province });
        break
      case '7':
        this.setState({ selectedCity: "Tuyên Quang" });
        this.setState({ provinceList: Provinces.tuyenquang_province });
        break
      case '8':
        this.setState({ selectedCity: "Lào Cai" });
        this.setState({ provinceList: Provinces.laocai_province });
        break
      case '9':
        this.setState({ selectedCity: "Điện Biên" });
        this.setState({ provinceList: Provinces.dienbien_province });
        break
      case '10':
        this.setState({ selectedCity: "Lai Châu" });
        this.setState({ provinceList: Provinces.laichau_province });
        break
      case '11':
        this.setState({ selectedCity: "Sơn La" });
        this.setState({ provinceList: Provinces.sonla_province });
        break
      case '12':
        this.setState({ selectedCity: "Yên Bái" });
        this.setState({ provinceList: Provinces.yenbai_province });
        break
      case '13':
        this.setState({ selectedCity: "Hòa Bình" });
        this.setState({ provinceList: Provinces.hoabinh_province });
        break
      case '14':
        this.setState({ selectedCity: "Thái Nguyên" });
        this.setState({ provinceList: Provinces.thainguyen_province });
        break
      case '15':
        this.setState({ selectedCity: "Lạng Sơn" });
        this.setState({ provinceList: Provinces.langson_province });
        break
      case '16':
        this.setState({ selectedCity: "Quảng Ninh" });
        this.setState({ provinceList: Provinces.quangninh_province });
        break
      case '17':
        this.setState({ selectedCity: "Bắc Giang" });
        this.setState({ provinceList: Provinces.bacgiang_province });
        break
      case '18':
        this.setState({ selectedCity: "Phú Thọ" });
        this.setState({ provinceList: Provinces.phutho_province });
        break
      case '19':
        this.setState({ selectedCity: "Vĩnh Phúc" });
        this.setState({ provinceList: Provinces.vinhphuc_province });
        break
      case '20':
        this.setState({ selectedCity: "Bắc Ninh" });
        this.setState({ provinceList: Provinces.bacninh_province });
        break
      case '21':
        this.setState({ selectedCity: "Hải Dương" });
        this.setState({ provinceList: Provinces.haiduong_province });
        break
      case '22':
        this.setState({ selectedCity: "Hải Phòng" });
        this.setState({ provinceList: Provinces.haiphong_city });
        break
      case '23':
        this.setState({ selectedCity: "Hưng Yên" });
        this.setState({ provinceList: Provinces.hungyen_province });
        break
      case '24':
        this.setState({ selectedCity: "Thái Bình" });
        this.setState({ provinceList: Provinces.thaibinh_province });
        break
      case '25':
        this.setState({ selectedCity: "Hà Nam" });
        this.setState({ provinceList: Provinces.hanam_province });
        break
      case '26':
        this.setState({ selectedCity: "Nam Định" });
        this.setState({ provinceList: Provinces.namdinh_province });
        break
      case '27':
        this.setState({ selectedCity: "Ninh Bình" });
        this.setState({ provinceList: Provinces.ninhbinh_province });
        break
      case '28':
        this.setState({ selectedCity: "Thanh Hóa" });
        this.setState({ provinceList: Provinces.thanhhoa_province });
        break
      case '29':
        this.setState({ selectedCity: "Nghệ An" });
        this.setState({ provinceList: Provinces.nghean_province });
        break
      case '30':
        this.setState({ selectedCity: "Hà Tĩnh" });
        this.setState({ provinceList: Provinces.hatinh_province });
        break
      case '31':
        this.setState({ selectedCity: "Quảng Bình" });
        this.setState({ provinceList: Provinces.quangbinh_province });
        break
      case '32':
        this.setState({ selectedCity: "Quảng Trị" });
        this.setState({ provinceList: Provinces.quangtri_province });
        break
      case '33':
        this.setState({ selectedCity: "Thừa Thiên Huế" });
        this.setState({ provinceList: Provinces.thua_thien_hue_province });
        break
      case '34':
        this.setState({ selectedCity: "Quảng Nam" });
        this.setState({ provinceList: Provinces.quangnam_province });
        break
      case '35':
        this.setState({ selectedCity: "Quảng Ngãi" });
        this.setState({ provinceList: Provinces.quangngai_province });
        break
      case '36':
        this.setState({ selectedCity: "Bình Định" });
        this.setState({ provinceList: Provinces.binhdinh_province });
        break
      case '37':
        this.setState({ selectedCity: "Phú Yên" });
        this.setState({ provinceList: Provinces.phuyen_province });
        break
      case '38':
        this.setState({ selectedCity: "Khánh Hòa" });
        this.setState({ provinceList: Provinces.khanhhoa_province });
        break
      case '39':
        this.setState({ selectedCity: "Ninh Thuận" });
        this.setState({ provinceList: Provinces.ninhthuan_province });
        break
      case '40':
        this.setState({ selectedCity: "Bình Thuận" });
        this.setState({ provinceList: Provinces.binhthuan_province });
        break
      case '41':
        this.setState({ selectedCity: "Kon Tum" });
        this.setState({ provinceList: Provinces.kontum_province });
        break
      case '42':
        this.setState({ selectedCity: "Gia Lai" });
        this.setState({ provinceList: Provinces.gialai_province });
        break
      case '43':
        this.setState({ selectedCity: "Đắk Lắk" });
        this.setState({ provinceList: Provinces.daklak_province });
        break
      case '44':
        this.setState({ selectedCity: "Đắk Nông" });
        this.setState({ provinceList: Provinces.daknong_province });
        break
      case '45':
        this.setState({ selectedCity: "Lâm Đồng" });
        this.setState({ provinceList: Provinces.lamdong_province });
        break
      case '46':
        this.setState({ selectedCity: "Bình Phước" });
        this.setState({ provinceList: Provinces.binhphuoc_province });
        break
      case '47':
        this.setState({ selectedCity: "Tây Ninh" });
        this.setState({ provinceList: Provinces.tayninh_province });
        break
      case '48':
        this.setState({ selectedCity: "Bình Dương" });
        this.setState({ provinceList: Provinces.binhduong_province });
        break
      case '49':
        this.setState({ selectedCity: "Đồng Nai" });
        this.setState({ provinceList: Provinces.dongnai_province });
        break
      case '50':
        this.setState({ selectedCity: "Bà Rịa - Vũng Tàu" });
        this.setState({ provinceList: Provinces.bariavungtau_province });
        break
      case '51':
        this.setState({ selectedCity: "Long An" });
        this.setState({ provinceList: Provinces.longan_province });
        break
      case '52':
        this.setState({ selectedCity: "Tiền Giang" });
        this.setState({ provinceList: Provinces.tiengiang_province });
        break
      case '53':
        this.setState({ selectedCity: "Bến Tre" });
        this.setState({ provinceList: Provinces.bentre_province });
        break
      case '54':
        this.setState({ selectedCity: "Trà Vinh" });
        this.setState({ provinceList: Provinces.travinh_province });
        break
      case '55':
        this.setState({ selectedCity: "Vĩnh Long" });
        this.setState({ provinceList: Provinces.vinhlong_province });
        break
      case '56':
        this.setState({ selectedCity: "Đồng Tháp" });
        this.setState({ provinceList: Provinces.dongthap_province });
        break
      case '57':
        this.setState({ selectedCity: "An Giang" });
        this.setState({ provinceList: Provinces.angiang_province });
        break
      case '58':
        this.setState({ selectedCity: "Kiên Giang" });
        this.setState({ provinceList: Provinces.kiengiang_province });
        break
      case '59':
        this.setState({ selectedCity: "Cần Thơ" });
        this.setState({ provinceList: Provinces.cantho_province });
        break
      case '60':
        this.setState({ selectedCity: "Hậu Giang" });
        this.setState({ provinceList: Provinces.haugiang_province });
        break
      case '61':
        this.setState({ selectedCity: "Sóc Trăng" });
        this.setState({ provinceList: Provinces.soctrang_province });
        break
      case '62':
        this.setState({ selectedCity: "Bạc Liêu" });
        this.setState({ provinceList: Provinces.baclieu_province });
        break
      case '63':
        this.setState({ selectedCity: "Cà Mau" });
        this.setState({ provinceList: Provinces.camau_province });
        break
      default:
        break
    }

  };

  // chọn pulldown select province
  ChangeSelectProvince = (selectedOption) => {
    this.setState({ selectedProvince: selectedOption })
    this.setState({ changeProvinceFlag: true });
    this.setState({ selectedDistrict: null });

    switch (this.state.selectedCity) {
      case 'TP. Hồ Chí Minh':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.hochiminh_quan1 });
            break
          case '2':
            this.setState({ districtList: Districts.hochiminh_quan12 });
            break
          case '3':
            this.setState({ districtList: Districts.hochiminh_quanthuduc });
            break
          case '4':
            this.setState({ districtList: Districts.hochiminh_quan9 });
            break
          case '5':
            this.setState({ districtList: Districts.hochiminh_quangovap });
            break
          case '6':
            this.setState({ districtList: Districts.hochiminh_quanbinhthanh });
            break
          case '7':
            this.setState({ districtList: Districts.hochiminh_quantanbinh });
            break
          case '8':
            this.setState({ districtList: Districts.hochiminh_quantanphu });
            break
          case '9':
            this.setState({ districtList: Districts.hochiminh_quanphunhuan });
            break
          case '10':
            this.setState({ districtList: Districts.hochiminh_quan2 });
            break
          case '11':
            this.setState({ districtList: Districts.hochiminh_quan3 });
            break
          case '12':
            this.setState({ districtList: Districts.hochiminh_quan10 });
            break
          case '13':
            this.setState({ districtList: Districts.hochiminh_quan11 });
            break
          case '14':
            this.setState({ districtList: Districts.hochiminh_quan4 });
            break
          case '15':
            this.setState({ districtList: Districts.hochiminh_quan5 });
            break
          case '16':
            this.setState({ districtList: Districts.hochiminh_quan6 });
            break
          case '17':
            this.setState({ districtList: Districts.hochiminh_quan8 });
            break
          case '18':
            this.setState({ districtList: Districts.hochiminh_quanbinhtan });
            break
          case '19':
            this.setState({ districtList: Districts.hochiminh_quan7 });
            break
          case '20':
            this.setState({ districtList: Districts.hochiminh_huyencuchi });
            break
          case '21':
            this.setState({ districtList: Districts.hochiminh_huyenhocmon });
            break
          case '22':
            this.setState({ districtList: Districts.hochiminh_huyenbinhchanh });
            break
          case '23':
            this.setState({ districtList: Districts.hochiminh_huyennhabe });
            break
          case '24':
            this.setState({ districtList: Districts.hochiminh_huyencangio });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hà Nội':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.hanoi_quanbadinh });
            break
          case '2':
            this.setState({ districtList: Districts.hanoi_quanhoangkiem });
            break
          case '3':
            this.setState({ districtList: Districts.hanoi_quantayho });
            break
          case '4':
            this.setState({ districtList: Districts.hanoi_quanlongbien });
            break
          case '5':
            this.setState({ districtList: Districts.hanoi_quancaugiay });
            break
          case '6':
            this.setState({ districtList: Districts.hanoi_quandongda });
            break
          case '7':
            this.setState({ districtList: Districts.hanoi_quanhaibatrung });
            break
          case '8':
            this.setState({ districtList: Districts.hanoi_quanhoangmai });
            break
          case '9':
            this.setState({ districtList: Districts.hanoi_quanthanhxuan });
            break
          case '10':
            this.setState({ districtList: Districts.hanoi_huyensocson });
            break
          case '11':
            this.setState({ districtList: Districts.hanoi_huyendonganh });
            break
          case '12':
            this.setState({ districtList: Districts.hanoi_huyengialam });
            break
          case '13':
            this.setState({ districtList: Districts.hanoi_quannamtuliem });
            break
          case '14':
            this.setState({ districtList: Districts.hanoi_huyenthanhtri });
            break
          case '15':
            this.setState({ districtList: Districts.hanoi_quanbactuliem });
            break
          case '16':
            this.setState({ districtList: Districts.hanoi_huyenmelinh });
            break
          case '17':
            this.setState({ districtList: Districts.hanoi_quanhadong });
            break
          case '18':
            this.setState({ districtList: Districts.hanoi_xasontay });
            break
          case '19':
            this.setState({ districtList: Districts.hanoi_huyenbavi });
            break
          case '20':
            this.setState({ districtList: Districts.hanoi_huyenphuctho });
            break
          case '21':
            this.setState({ districtList: Districts.hanoi_huyendanphuong });
            break
          case '22':
            this.setState({ districtList: Districts.hanoi_huyenhoaiduc });
            break
          case '23':
            this.setState({ districtList: Districts.hanoi_huyenquocoai });
            break
          case '24':
            this.setState({ districtList: Districts.hanoi_huyenthachthat });
            break
          case '25':
            this.setState({ districtList: Districts.hanoi_huyenchuongmy });
            break
          case '26':
            this.setState({ districtList: Districts.hanoi_huyenthanhoai });
            break
          case '27':
            this.setState({ districtList: Districts.hanoi_huyenthuongtin });
            break
          case '28':
            this.setState({ districtList: Districts.hanoi_huyenphuxuyen });
            break
          case '29':
            this.setState({ districtList: Districts.hanoi_huyenunghoa });
            break
          case '30':
            this.setState({ districtList: Districts.hanoi_huyenmyduc });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Đà Nẵng':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.danang_quanlienchieu });
            break
          case '2':
            this.setState({ districtList: Districts.danang_quanthanhkhe });
            break
          case '3':
            this.setState({ districtList: Districts.danang_quanhaichau });
            break
          case '4':
            this.setState({ districtList: Districts.danang_quansontra });
            break
          case '5':
            this.setState({ districtList: Districts.danang_quannguhanhson });
            break
          case '6':
            this.setState({ districtList: Districts.danang_quancamle });
            break
          case '7':
            this.setState({ districtList: Districts.danang_huyenhoavang });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hà Giang':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.hagiang_thanhphohagiang });
            break
          case '2':
            this.setState({ districtList: Districts.hagiang_huyendongvan });
            break
          case '3':
            this.setState({ districtList: Districts.hagiang_huyenmeovac });
            break
          case '4':
            this.setState({ districtList: Districts.hagiang_huyenyenminh });
            break
          case '5':
            this.setState({ districtList: Districts.hagiang_huyenquanba });
            break
          case '6':
            this.setState({ districtList: Districts.hagiang_huyenvixuyen });
            break
          case '7':
            this.setState({ districtList: Districts.hagiang_huyenbacme });
            break
          case '8':
            this.setState({ districtList: Districts.hagiang_huyenhoangsuphi });
            break
          case '9':
            this.setState({ districtList: Districts.hagiang_huyenxinman });
            break
          case '10':
            this.setState({ districtList: Districts.hagiang_huyenbacquang });
            break
          case '11':
            this.setState({ districtList: Districts.hagiang_huyenquangbinh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Cao Bằng':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.caobang_thanhphocaobang });
            break
          case '2':
            this.setState({ districtList: Districts.caobang_huyenbaolam });
            break
          case '3':
            this.setState({ districtList: Districts.caobang_huyenbaolac });
            break
          case '4':
            this.setState({ districtList: Districts.caobang_huyenthongnong });
            break
          case '5':
            this.setState({ districtList: Districts.caobang_huyenhaquang });
            break
          case '6':
            this.setState({ districtList: Districts.caobang_huyentralinh });
            break
          case '7':
            this.setState({ districtList: Districts.caobang_huyentrungkhanh });
            break
          case '8':
            this.setState({ districtList: Districts.caobang_huyenhalang });
            break
          case '9':
            this.setState({ districtList: Districts.caobang_huyenquanguyen });
            break
          case '10':
            this.setState({ districtList: Districts.caobang_huyenphuchoa });
            break
          case '11':
            this.setState({ districtList: Districts.caobang_huyenhoaan });
            break
          case '12':
            this.setState({ districtList: Districts.caobang_huyennguyenbinh });
            break
          case '13':
            this.setState({ districtList: Districts.caobang_huyenthachan });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bắc Kạn':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.backan_thanhphobackan });
            break
          case '2':
            this.setState({ districtList: Districts.backan_huyenpacnam });
            break
          case '3':
            this.setState({ districtList: Districts.backan_huyenbabe });
            break
          case '4':
            this.setState({ districtList: Districts.backan_huyennganson });
            break
          case '5':
            this.setState({ districtList: Districts.backan_huyenbachthong });
            break
          case '6':
            this.setState({ districtList: Districts.backan_huyenchodon });
            break
          case '7':
            this.setState({ districtList: Districts.backan_huyenchomoi });
            break
          case '8':
            this.setState({ districtList: Districts.backan_huyennari });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Tuyên Quang':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.tuyenquang_thanhphotuyenquang });
            break
          case '2':
            this.setState({ districtList: Districts.tuyenquang_huyenyenson });
            break
          case '3':
            this.setState({ districtList: Districts.tuyenquang_huyensonduong });
            break
          case '4':
            this.setState({ districtList: Districts.tuyenquang_huyenlambinh });
            break
          case '5':
            this.setState({ districtList: Districts.tuyenquang_huyennahang });
            break
          case '6':
            this.setState({ districtList: Districts.tuyenquang_huyenchiemhoa });
            break
          case '7':
            this.setState({ districtList: Districts.tuyenquang_huyenhamyen });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Lào Cai':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.laocai_thanhpholaocai });
            break
          case '2':
            this.setState({ districtList: Districts.laocai_huyenbatxat });
            break
          case '3':
            this.setState({ districtList: Districts.laocai_huyenmuongkhuong });
            break
          case '4':
            this.setState({ districtList: Districts.laocai_huyensimacai });
            break
          case '5':
            this.setState({ districtList: Districts.laocai_huyenbacha });
            break
          case '6':
            this.setState({ districtList: Districts.laocai_huyenbaothang });
            break
          case '7':
            this.setState({ districtList: Districts.laocai_huyenbaoyen });
            break
          case '8':
            this.setState({ districtList: Districts.laocai_huyensapa });
            break
          case '9':
            this.setState({ districtList: Districts.laocai_huyenvanban });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Điện Biên':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.dienbien_thanhphodienbienphu });
            break
          case '2':
            this.setState({ districtList: Districts.dienbien_xamuonglay });
            break
          case '3':
            this.setState({ districtList: Districts.dienbien_huyenmuongnhe });
            break
          case '4':
            this.setState({ districtList: Districts.dienbien_huyenmuongcha });
            break
          case '5':
            this.setState({ districtList: Districts.dienbien_huyentuachua });
            break
          case '6':
            this.setState({ districtList: Districts.dienbien_huyentuangiao });
            break
          case '7':
            this.setState({ districtList: Districts.dienbien_huyendienbien });
            break
          case '8':
            this.setState({ districtList: Districts.dienbien_huyendienbiendong });
            break
          case '9':
            this.setState({ districtList: Districts.dienbien_huyenmuonganh });
            break
          case '10':
            this.setState({ districtList: Districts.dienbien_huyennampo });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Lai Châu':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.laichau_thanhpholaichau });
            break
          case '2':
            this.setState({ districtList: Districts.laichau_huyentamduong });
            break
          case '3':
            this.setState({ districtList: Districts.laichau_huyenmuongte });
            break
          case '4':
            this.setState({ districtList: Districts.laichau_huyensinho });
            break
          case '5':
            this.setState({ districtList: Districts.laichau_huyenphongtho });
            break
          case '6':
            this.setState({ districtList: Districts.laichau_huyenthanuyen });
            break
          case '7':
            this.setState({ districtList: Districts.laichau_huyentanuyen });
            break
          case '8':
            this.setState({ districtList: Districts.laichau_huyennamnhun });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Sơn La':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.sonla_thanhphosonla });
            break
          case '2':
            this.setState({ districtList: Districts.sonla_huyenquynhnhai });
            break
          case '3':
            this.setState({ districtList: Districts.sonla_huyenthauchau });
            break
          case '4':
            this.setState({ districtList: Districts.sonla_huyenmuongla });
            break
          case '5':
            this.setState({ districtList: Districts.sonla_huyenbacyen });
            break
          case '6':
            this.setState({ districtList: Districts.sonla_huyenphuyen });
            break
          case '7':
            this.setState({ districtList: Districts.sonla_huyenmocchau });
            break
          case '8':
            this.setState({ districtList: Districts.sonla_huyenyenchau });
            break
          case '9':
            this.setState({ districtList: Districts.sonla_huyenmaison });
            break
          case '10':
            this.setState({ districtList: Districts.sonla_huyensongma });
            break
          case '11':
            this.setState({ districtList: Districts.sonla_huyensopcop });
            break
          case '12':
            this.setState({ districtList: Districts.sonla_huyenvanho });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Yên Bái':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.yenbai_thanhphoyenbai });
            break
          case '2':
            this.setState({ districtList: Districts.yenbai_xanghialo });
            break
          case '3':
            this.setState({ districtList: Districts.yenbai_huyenlucyen });
            break
          case '4':
            this.setState({ districtList: Districts.yenbai_huyenvanyen });
            break
          case '5':
            this.setState({ districtList: Districts.yenbai_huyenmucangchai });
            break
          case '6':
            this.setState({ districtList: Districts.yenbai_huyentranyen });
            break
          case '7':
            this.setState({ districtList: Districts.yenbai_huyentramtau });
            break
          case '8':
            this.setState({ districtList: Districts.yenbai_huyenvanchan });
            break
          case '9':
            this.setState({ districtList: Districts.yenbai_huyenyenbinh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hòa Bình':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.hoabinh_thanhphohoabinh });
            break
          case '2':
            this.setState({ districtList: Districts.hoabinh_huyendabac });
            break
          case '3':
            this.setState({ districtList: Districts.hoabinh_huyenkyson });
            break
          case '4':
            this.setState({ districtList: Districts.hoabinh_huyenluongson });
            break
          case '5':
            this.setState({ districtList: Districts.hoabinh_huyenkimboi });
            break
          case '6':
            this.setState({ districtList: Districts.hoabinh_huyencaophong });
            break
          case '7':
            this.setState({ districtList: Districts.hoabinh_huyentanlac });
            break
          case '8':
            this.setState({ districtList: Districts.hoabinh_huyenmaichau });
            break
          case '9':
            this.setState({ districtList: Districts.hoabinh_huyenlacson });
            break
          case '10':
            this.setState({ districtList: Districts.hoabinh_huyenyenthuy });
            break
          case '11':
            this.setState({ districtList: Districts.hoabinh_huyenlacthuy });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Thái Nguyên':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.thainguyen_thanhphothainguyen });
            break
          case '2':
            this.setState({ districtList: Districts.thainguyen_thanhphosongcong });
            break
          case '3':
            this.setState({ districtList: Districts.thainguyen_huyendinhhoa });
            break
          case '4':
            this.setState({ districtList: Districts.thainguyen_huyenphuluong });
            break
          case '5':
            this.setState({ districtList: Districts.thainguyen_huyendonghy });
            break
          case '6':
            this.setState({ districtList: Districts.thainguyen_huyenvonhai });
            break
          case '7':
            this.setState({ districtList: Districts.thainguyen_huyendaitu });
            break
          case '8':
            this.setState({ districtList: Districts.thainguyen_xaphoyen });
            break
          case '9':
            this.setState({ districtList: Districts.thainguyen_huyenphubinh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Lạng Sơn':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.langson_thanhpholangson });
            break
          case '2':
            this.setState({ districtList: Districts.langson_huyentrangdinh });
            break
          case '3':
            this.setState({ districtList: Districts.langson_huyenbinhgia });
            break
          case '4':
            this.setState({ districtList: Districts.langson_huyenvanlang });
            break
          case '5':
            this.setState({ districtList: Districts.langson_huyencaoloc });
            break
          case '6':
            this.setState({ districtList: Districts.langson_huyenvanquan });
            break
          case '7':
            this.setState({ districtList: Districts.langson_huyenbacson });
            break
          case '8':
            this.setState({ districtList: Districts.langson_huyenhuulung });
            break
          case '9':
            this.setState({ districtList: Districts.langson_huyenchilang });
            break
          case '10':
            this.setState({ districtList: Districts.langson_huyenlocbinh });
            break
          case '11':
            this.setState({ districtList: Districts.langson_huyendinhlap });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Quảng Ninh':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.quangninh_thanhphohalong });
            break
          case '2':
            this.setState({ districtList: Districts.quangninh_thanhphomongcai });
            break
          case '3':
            this.setState({ districtList: Districts.quangninh_thanhphocampha });
            break
          case '4':
            this.setState({ districtList: Districts.quangninh_thanhphouongbi });
            break
          case '5':
            this.setState({ districtList: Districts.quangninh_huyenbinhlieu });
            break
          case '6':
            this.setState({ districtList: Districts.quangninh_huyentienyen });
            break
          case '7':
            this.setState({ districtList: Districts.quangninh_huyendamha });
            break
          case '8':
            this.setState({ districtList: Districts.quangninh_huyenhaiha });
            break
          case '9':
            this.setState({ districtList: Districts.quangninh_huyenbache });
            break
          case '10':
            this.setState({ districtList: Districts.quangninh_huyenvandon });
            break
          case '11':
            this.setState({ districtList: Districts.quangninh_huyenhoanhbo });
            break
          case '12':
            this.setState({ districtList: Districts.quangninh_xadongtrieu });
            break
          case '13':
            this.setState({ districtList: Districts.quangninh_xaquangyen });
            break
          case '14':
            this.setState({ districtList: Districts.quangninh_huyencoto });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bắc Giang':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.bacgiang_thanhphobacgiang });
            break
          case '2':
            this.setState({ districtList: Districts.bacgiang_huyenyenthe });
            break
          case '3':
            this.setState({ districtList: Districts.bacgiang_huyentanyen });
            break
          case '4':
            this.setState({ districtList: Districts.bacgiang_huyenlanggiang });
            break
          case '5':
            this.setState({ districtList: Districts.bacgiang_huyenlucnam });
            break
          case '6':
            this.setState({ districtList: Districts.bacgiang_huyenlucngan });
            break
          case '7':
            this.setState({ districtList: Districts.bacgiang_huyensondong });
            break
          case '8':
            this.setState({ districtList: Districts.bacgiang_huyenyendung });
            break
          case '9':
            this.setState({ districtList: Districts.bacgiang_huyenvietyen });
            break
          case '10':
            this.setState({ districtList: Districts.bacgiang_huyenhiephoa });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Phú Thọ':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.phutho_thanhphoviettri });
            break
          case '2':
            this.setState({ districtList: Districts.phutho_xaphutho });
            break
          case '3':
            this.setState({ districtList: Districts.phutho_huyendoanhung });
            break
          case '4':
            this.setState({ districtList: Districts.phutho_huyenhahoa });
            break
          case '5':
            this.setState({ districtList: Districts.phutho_huyenthanhba });
            break
          case '6':
            this.setState({ districtList: Districts.phutho_huyenphuninh });
            break
          case '7':
            this.setState({ districtList: Districts.phutho_huyenyenlap });
            break
          case '8':
            this.setState({ districtList: Districts.phutho_huyencamkhe });
            break
          case '9':
            this.setState({ districtList: Districts.phutho_huyentamnong });
            break
          case '10':
            this.setState({ districtList: Districts.phutho_huyenlamthao });
            break
          case '11':
            this.setState({ districtList: Districts.phutho_huyenthanhson });
            break
          case '12':
            this.setState({ districtList: Districts.phutho_huyenthanhthuy });
            break
          case '13':
            this.setState({ districtList: Districts.phutho_huyentanson });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Vĩnh Phúc':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.vinhphuc_thanhphovinhyen });
            break
          case '2':
            this.setState({ districtList: Districts.vinhphuc_xaphucyen });
            break
          case '3':
            this.setState({ districtList: Districts.vinhphuc_huyenlapthach });
            break
          case '4':
            this.setState({ districtList: Districts.vinhphuc_huyentamduong });
            break
          case '5':
            this.setState({ districtList: Districts.vinhphuc_huyentamdao });
            break
          case '6':
            this.setState({ districtList: Districts.vinhphuc_huyenbinhxuyen });
            break
          case '7':
            this.setState({ districtList: Districts.vinhphuc_huyenyenlac });
            break
          case '8':
            this.setState({ districtList: Districts.vinhphuc_huyenvinhtuong });
            break
          case '9':
            this.setState({ districtList: Districts.vinhphuc_huyensonglo });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bắc Ninh':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.bacninh_thanhphobacninh });
            break
          case '2':
            this.setState({ districtList: Districts.bacninh_huyenyenphong });
            break
          case '3':
            this.setState({ districtList: Districts.bacninh_huyenquevo });
            break
          case '4':
            this.setState({ districtList: Districts.bacninh_huyentiendu });
            break
          case '5':
            this.setState({ districtList: Districts.bacninh_xatuson });
            break
          case '6':
            this.setState({ districtList: Districts.bacninh_huyenthuanthanh });
            break
          case '7':
            this.setState({ districtList: Districts.bacninh_huyengiabinh });
            break
          case '8':
            this.setState({ districtList: Districts.bacninh_huyenluongtai });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hải Dương':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.haiduong_thanhphohaihuong });
            break
          case '2':
            this.setState({ districtList: Districts.haiduong_xachilinh });
            break
          case '3':
            this.setState({ districtList: Districts.haiduong_huyennamsach });
            break
          case '4':
            this.setState({ districtList: Districts.haiduong_huyenkinhmon });
            break
          case '5':
            this.setState({ districtList: Districts.haiduong_huyenkimthanh });
            break
          case '6':
            this.setState({ districtList: Districts.haiduong_huyenthanhha });
            break
          case '7':
            this.setState({ districtList: Districts.haiduong_huyencamgiang });
            break
          case '8':
            this.setState({ districtList: Districts.haiduong_huyenbinhgiang });
            break
          case '9':
            this.setState({ districtList: Districts.haiduong_huyengialoc });
            break
          case '10':
            this.setState({ districtList: Districts.haiduong_huyentuky });
            break
          case '11':
            this.setState({ districtList: Districts.haiduong_huyenninhgiang });
            break
          case '12':
            this.setState({ districtList: Districts.haiduong_huyenthanhmien });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hải Phòng':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.haiphong_quanhongbang });
            break
          case '2':
            this.setState({ districtList: Districts.haiphong_quanngoquyen });
            break
          case '3':
            this.setState({ districtList: Districts.haiphong_quanlechan });
            break
          case '4':
            this.setState({ districtList: Districts.haiphong_quanhaian });
            break
          case '5':
            this.setState({ districtList: Districts.haiphong_quankienan });
            break
          case '6':
            this.setState({ districtList: Districts.haiphong_huyendoson });
            break
          case '7':
            this.setState({ districtList: Districts.haiphong_huyenduongkinh });
            break
          case '8':
            this.setState({ districtList: Districts.haiphong_huyenthuynguyen });
            break
          case '9':
            this.setState({ districtList: Districts.haiphong_huyenanduong });
            break
          case '10':
            this.setState({ districtList: Districts.haiphong_huyenanlao });
            break
          case '11':
            this.setState({ districtList: Districts.haiphong_huyenkienthuy });
            break
          case '12':
            this.setState({ districtList: Districts.haiphong_huyentienlang });
            break
          case '13':
            this.setState({ districtList: Districts.haiphong_huyenvinhbao });
            break
          case '14':
            this.setState({ districtList: Districts.haiphong_huyencathai });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hưng Yên':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.hungyen_thanhphohungyen });
            break
          case '2':
            this.setState({ districtList: Districts.hungyen_huyenvanlam });
            break
          case '3':
            this.setState({ districtList: Districts.hungyen_huyenvangiang });
            break
          case '4':
            this.setState({ districtList: Districts.hungyen_huyenyenmy });
            break
          case '5':
            this.setState({ districtList: Districts.hungyen_huyenmyhao });
            break
          case '6':
            this.setState({ districtList: Districts.hungyen_huyenanthi });
            break
          case '7':
            this.setState({ districtList: Districts.hungyen_huyenkhoaichau });
            break
          case '8':
            this.setState({ districtList: Districts.hungyen_huyenkimdong });
            break
          case '9':
            this.setState({ districtList: Districts.hungyen_huyentienlu });
            break
          case '10':
            this.setState({ districtList: Districts.hungyen_huyenphucu });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Thái Bình':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.thaibinh_thanhphothaibinh });
            break
          case '2':
            this.setState({ districtList: Districts.thaibinh_huyenquynhphu });
            break
          case '3':
            this.setState({ districtList: Districts.thaibinh_huyenhungha });
            break
          case '4':
            this.setState({ districtList: Districts.thaibinh_huyendonghung });
            break
          case '5':
            this.setState({ districtList: Districts.thaibinh_huyenthaithuy });
            break
          case '6':
            this.setState({ districtList: Districts.thaibinh_huyentienhai });
            break
          case '7':
            this.setState({ districtList: Districts.thaibinh_huyenkienxuong });
            break
          case '8':
            this.setState({ districtList: Districts.thaibinh_huyenvuthu });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hà Nam':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.hanam_thanhphophuly });
            break
          case '2':
            this.setState({ districtList: Districts.hanam_huyenduytien });
            break
          case '3':
            this.setState({ districtList: Districts.hanam_huyenkimbang });
            break
          case '4':
            this.setState({ districtList: Districts.hanam_huyenthanhlien });
            break
          case '5':
            this.setState({ districtList: Districts.hanam_huyenbinhluc });
            break
          case '6':
            this.setState({ districtList: Districts.hanam_huyenlynhan });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Nam Định':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.namdinh_thanhphonamdinh });
            break
          case '2':
            this.setState({ districtList: Districts.namdinh_huyenmyloc });
            break
          case '3':
            this.setState({ districtList: Districts.namdinh_huyenvuban });
            break
          case '4':
            this.setState({ districtList: Districts.namdinh_huyenyyen });
            break
          case '5':
            this.setState({ districtList: Districts.namdinh_huyennghiahung });
            break
          case '6':
            this.setState({ districtList: Districts.namdinh_huyennamtruc });
            break
          case '7':
            this.setState({ districtList: Districts.namdinh_huyentrucninh });
            break
          case '8':
            this.setState({ districtList: Districts.namdinh_huyenxuantruong });
            break
          case '9':
            this.setState({ districtList: Districts.namdinh_huyengiaothuy });
            break
          case '10':
            this.setState({ districtList: Districts.namdinh_huyenhaihau });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Ninh Bình':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.ninhbinh_thanhphoninhbinh });
            break
          case '2':
            this.setState({ districtList: Districts.ninhbinh_thanhphotamdiep });
            break
          case '3':
            this.setState({ districtList: Districts.ninhbinh_huyennhoquan });
            break
          case '4':
            this.setState({ districtList: Districts.ninhbinh_huyengiavien });
            break
          case '5':
            this.setState({ districtList: Districts.ninhbinh_huyenhoalu });
            break
          case '6':
            this.setState({ districtList: Districts.ninhbinh_huyenyenkhanh });
            break
          case '7':
            this.setState({ districtList: Districts.ninhbinh_huyenkimson });
            break
          case '8':
            this.setState({ districtList: Districts.ninhbinh_huyenyenmo });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Thanh Hóa':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.thanhhoa_thanhphothanhhoa });
            break
          case '2':
            this.setState({ districtList: Districts.thanhhoa_xabimson });
            break
          case '3':
            this.setState({ districtList: Districts.thanhhoa_thanhphosamson });
            break
          case '4':
            this.setState({ districtList: Districts.thanhhoa_huyenmuonglat });
            break
          case '5':
            this.setState({ districtList: Districts.thanhhoa_huyenquanhoa });
            break
          case '6':
            this.setState({ districtList: Districts.thanhhoa_huyenbathuoc });
            break
          case '7':
            this.setState({ districtList: Districts.thanhhoa_huyenquanson });
            break
          case '8':
            this.setState({ districtList: Districts.thanhhoa_huyenlangchanh });
            break
          case '9':
            this.setState({ districtList: Districts.thanhhoa_huyenngoclac });
            break
          case '10':
            this.setState({ districtList: Districts.thanhhoa_huyencamthuy });
            break
          case '11':
            this.setState({ districtList: Districts.thanhhoa_huyenthachthanh });
            break
          case '12':
            this.setState({ districtList: Districts.thanhhoa_huyenhatrung });
            break
          case '13':
            this.setState({ districtList: Districts.thanhhoa_huyenvinhloc });
            break
          case '14':
            this.setState({ districtList: Districts.thanhhoa_huyenyendinh });
            break
          case '15':
            this.setState({ districtList: Districts.thanhhoa_huyenthoxuan });
            break
          case '16':
            this.setState({ districtList: Districts.thanhhoa_huyenthuongxuan });
            break
          case '17':
            this.setState({ districtList: Districts.thanhhoa_huyentrieuson });
            break
          case '18':
            this.setState({ districtList: Districts.thanhhoa_huyenthieuhoa });
            break
          case '19':
            this.setState({ districtList: Districts.thanhhoa_huyenhoanghoa });
            break
          case '20':
            this.setState({ districtList: Districts.thanhhoa_huyenhauloc });
            break
          case '21':
            this.setState({ districtList: Districts.thanhhoa_huyenngason });
            break
          case '22':
            this.setState({ districtList: Districts.thanhhoa_huyennhuxuan });
            break
          case '23':
            this.setState({ districtList: Districts.thanhhoa_huyennhuthanh });
            break
          case '24':
            this.setState({ districtList: Districts.thanhhoa_huyennongcong });
            break
          case '25':
            this.setState({ districtList: Districts.thanhhoa_huyendongson });
            break
          case '26':
            this.setState({ districtList: Districts.thanhhoa_huyenquangxuong });
            break
          case '27':
            this.setState({ districtList: Districts.thanhhoa_huyentinhgia });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Nghệ An':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.nghean_thanhphovinh });
            break
          case '2':
            this.setState({ districtList: Districts.nghean_xacualo });
            break
          case '3':
            this.setState({ districtList: Districts.nghean_xathaihoa });
            break
          case '4':
            this.setState({ districtList: Districts.nghean_huyenquephong });
            break
          case '5':
            this.setState({ districtList: Districts.nghean_huyenquychau });
            break
          case '6':
            this.setState({ districtList: Districts.nghean_huyenkyson });
            break
          case '7':
            this.setState({ districtList: Districts.nghean_huyentuongduong });
            break
          case '8':
            this.setState({ districtList: Districts.nghean_huyennghiadan });
            break
          case '9':
            this.setState({ districtList: Districts.nghean_huyenquyhop });
            break
          case '10':
            this.setState({ districtList: Districts.nghean_huyenquynhluu });
            break
          case '11':
            this.setState({ districtList: Districts.nghean_huyenconcuong });
            break
          case '12':
            this.setState({ districtList: Districts.nghean_huyentanky });
            break
          case '13':
            this.setState({ districtList: Districts.nghean_huyenanhson });
            break
          case '14':
            this.setState({ districtList: Districts.nghean_huyendienchau });
            break
          case '15':
            this.setState({ districtList: Districts.nghean_huyenyenthanh });
            break
          case '16':
            this.setState({ districtList: Districts.nghean_huyendoluong });
            break
          case '17':
            this.setState({ districtList: Districts.nghean_huyenthanhchuong });
            break
          case '18':
            this.setState({ districtList: Districts.nghean_huyennghiloc });
            break
          case '19':
            this.setState({ districtList: Districts.nghean_huyennamdan });
            break
          case '20':
            this.setState({ districtList: Districts.nghean_huyenhungnguyen });
            break
          case '21':
            this.setState({ districtList: Districts.nghean_xahoangmai });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hà Tĩnh':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.hatinh_thanhphohatinh });
            break
          case '2':
            this.setState({ districtList: Districts.hatinh_xahonglinh });
            break
          case '3':
            this.setState({ districtList: Districts.hatinh_huyenhuongson });
            break
          case '4':
            this.setState({ districtList: Districts.hatinh_huyenductho });
            break
          case '5':
            this.setState({ districtList: Districts.hatinh_huyenvuquang });
            break
          case '6':
            this.setState({ districtList: Districts.hatinh_huyennghixuan });
            break
          case '7':
            this.setState({ districtList: Districts.hatinh_huyencanloc });
            break
          case '8':
            this.setState({ districtList: Districts.hatinh_huyenhuongkhe });
            break
          case '9':
            this.setState({ districtList: Districts.hatinh_huyenthachha });
            break
          case '10':
            this.setState({ districtList: Districts.hatinh_huyencamxuyen });
            break
          case '11':
            this.setState({ districtList: Districts.hatinh_huyenkyanh });
            break
          case '12':
            this.setState({ districtList: Districts.hatinh_huyenlocha });
            break
          case '13':
            this.setState({ districtList: Districts.hatinh_xakyanh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Quảng Bình':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.quangbinh_thanhphodonghoi });
            break
          case '2':
            this.setState({ districtList: Districts.quangbinh_huyenminhhoa });
            break
          case '3':
            this.setState({ districtList: Districts.quangbinh_huyentuyenhoa });
            break
          case '4':
            this.setState({ districtList: Districts.quangbinh_huyenquangtrach });
            break
          case '5':
            this.setState({ districtList: Districts.quangbinh_huyenbotrach });
            break
          case '6':
            this.setState({ districtList: Districts.quangbinh_huyenquangninh });
            break
          case '7':
            this.setState({ districtList: Districts.quangbinh_huyenlethuy });
            break
          case '8':
            this.setState({ districtList: Districts.quangbinh_xabadon });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Quảng Trị':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.quangtri_thanhphodongha });
            break
          case '2':
            this.setState({ districtList: Districts.quangtri_xaquangtri });
            break
          case '3':
            this.setState({ districtList: Districts.quangtri_huyenvinhlinh });
            break
          case '4':
            this.setState({ districtList: Districts.quangtri_huyenhuonghoa });
            break
          case '5':
            this.setState({ districtList: Districts.quangtri_huyengiolinh });
            break
          case '6':
            this.setState({ districtList: Districts.quangtri_huyendakrong });
            break
          case '7':
            this.setState({ districtList: Districts.quangtri_huyencamlo });
            break
          case '8':
            this.setState({ districtList: Districts.quangtri_huyentrieuphong });
            break
          case '9':
            this.setState({ districtList: Districts.quangtri_huyenhailang });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Thừa Thiên Huế':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.thuathienhue_thanhphohue });
            break
          case '2':
            this.setState({ districtList: Districts.thuathienhue_huyenphongdien });
            break
          case '3':
            this.setState({ districtList: Districts.thuathienhue_huyenquangdien });
            break
          case '4':
            this.setState({ districtList: Districts.thuathienhue_huyenphuvang });
            break
          case '5':
            this.setState({ districtList: Districts.thuathienhue_xahuongthuy });
            break
          case '6':
            this.setState({ districtList: Districts.thuathienhue_xahuongtra });
            break
          case '7':
            this.setState({ districtList: Districts.thuathienhue_huyenaluoi });
            break
          case '8':
            this.setState({ districtList: Districts.thuathienhue_huyenphuloc });
            break
          case '9':
            this.setState({ districtList: Districts.thuathienhue_huyennamdong });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Quảng Nam':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.quangnam_thanhphotamky });
            break
          case '2':
            this.setState({ districtList: Districts.quangnam_thanhphohoian });
            break
          case '3':
            this.setState({ districtList: Districts.quangnam_huyentaygiang });
            break
          case '4':
            this.setState({ districtList: Districts.quangnam_huyendonggiang });
            break
          case '5':
            this.setState({ districtList: Districts.quangnam_huyendailoc });
            break
          case '6':
            this.setState({ districtList: Districts.quangnam_xadienban });
            break
          case '7':
            this.setState({ districtList: Districts.quangnam_huyenduyxuyen });
            break
          case '8':
            this.setState({ districtList: Districts.quangnam_huyenqueson });
            break
          case '9':
            this.setState({ districtList: Districts.quangnam_huyennamgiang });
            break
          case '10':
            this.setState({ districtList: Districts.quangnam_huyenphuocson });
            break
          case '11':
            this.setState({ districtList: Districts.quangnam_huyenhiepduc });
            break
          case '12':
            this.setState({ districtList: Districts.quangnam_huyenthangbinh });
            break
          case '13':
            this.setState({ districtList: Districts.quangnam_huyentienphuoc });
            break
          case '14':
            this.setState({ districtList: Districts.quangnam_huyenbactramy });
            break
          case '15':
            this.setState({ districtList: Districts.quangnam_huyennamtramy });
            break
          case '16':
            this.setState({ districtList: Districts.quangnam_huyennuithanh });
            break
          case '17':
            this.setState({ districtList: Districts.quangnam_huyenphuninh });
            break
          case '18':
            this.setState({ districtList: Districts.quangnam_huyennongson });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Quảng Ngãi':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.quangngai_thanhphoquangngai });
            break
          case '2':
            this.setState({ districtList: Districts.quangngai_huyenbinhson });
            break
          case '3':
            this.setState({ districtList: Districts.quangngai_huyentrabong });
            break
          case '4':
            this.setState({ districtList: Districts.quangngai_huyentaytra });
            break
          case '5':
            this.setState({ districtList: Districts.quangngai_huyensontinh });
            break
          case '6':
            this.setState({ districtList: Districts.quangngai_huyentunghia });
            break
          case '7':
            this.setState({ districtList: Districts.quangngai_huyensonha });
            break
          case '8':
            this.setState({ districtList: Districts.quangngai_huyensontay });
            break
          case '9':
            this.setState({ districtList: Districts.quangngai_huyenminhlong });
            break
          case '10':
            this.setState({ districtList: Districts.quangngai_huyennghiahanh });
            break
          case '11':
            this.setState({ districtList: Districts.quangngai_huyenmoduc });
            break
          case '12':
            this.setState({ districtList: Districts.quangngai_huyenducpho });
            break
          case '13':
            this.setState({ districtList: Districts.quangngai_huyenbato });
            break
          case '14':
            this.setState({ districtList: Districts.quangngai_huyenlyson });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bình Định':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.binhdinh_thanhphoquinhon });
            break
          case '2':
            this.setState({ districtList: Districts.binhdinh_huyenanlao });
            break
          case '3':
            this.setState({ districtList: Districts.binhdinh_huyenhoainhon });
            break
          case '4':
            this.setState({ districtList: Districts.binhdinh_huyenhoaian });
            break
          case '5':
            this.setState({ districtList: Districts.binhdinh_huyenphumy });
            break
          case '6':
            this.setState({ districtList: Districts.binhdinh_huyenvinhthanh });
            break
          case '7':
            this.setState({ districtList: Districts.binhdinh_huyentayson });
            break
          case '8':
            this.setState({ districtList: Districts.binhdinh_huyenphucat });
            break
          case '9':
            this.setState({ districtList: Districts.binhdinh_xaannhon });
            break
          case '10':
            this.setState({ districtList: Districts.binhdinh_huyentuyphuoc });
            break
          case '11':
            this.setState({ districtList: Districts.binhdinh_huyenvancanh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Phú Yên':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.phuyen_thanhphotuyhoa });
            break
          case '2':
            this.setState({ districtList: Districts.phuyen_xasongcau });
            break
          case '3':
            this.setState({ districtList: Districts.phuyen_huyendongxuan });
            break
          case '4':
            this.setState({ districtList: Districts.phuyen_huyentuyan });
            break
          case '5':
            this.setState({ districtList: Districts.phuyen_huyensonhoa });
            break
          case '6':
            this.setState({ districtList: Districts.phuyen_huyensonghinh });
            break
          case '7':
            this.setState({ districtList: Districts.phuyen_huyentayhoa });
            break
          case '8':
            this.setState({ districtList: Districts.phuyen_huyenphuhoa });
            break
          case '9':
            this.setState({ districtList: Districts.phuyen_huyendonghoa });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Khánh Hòa':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.khanhhoa_thanhphonhatrang });
            break
          case '2':
            this.setState({ districtList: Districts.khanhhoa_thanhphocamranh });
            break
          case '3':
            this.setState({ districtList: Districts.khanhhoa_huyencanlam });
            break
          case '4':
            this.setState({ districtList: Districts.khanhhoa_huyenvanninh });
            break
          case '5':
            this.setState({ districtList: Districts.khanhhoa_xaninhhoa });
            break
          case '6':
            this.setState({ districtList: Districts.khanhhoa_huyenkhanhvinh });
            break
          case '7':
            this.setState({ districtList: Districts.khanhhoa_huyendienkhanh });
            break
          case '8':
            this.setState({ districtList: Districts.khanhhoa_huyenkhanhson });
            break
          case '9':
            this.setState({ districtList: Districts.khanhhoa_huyentruongsa });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Ninh Thuận':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.ninhthuan_thanhphophanrang });
            break
          case '2':
            this.setState({ districtList: Districts.ninhthuan_huyenbacai });
            break
          case '3':
            this.setState({ districtList: Districts.ninhthuan_huyenninhson });
            break
          case '4':
            this.setState({ districtList: Districts.ninhthuan_huyenninhhai });
            break
          case '5':
            this.setState({ districtList: Districts.ninhthuan_huyenninhphuoc });
            break
          case '6':
            this.setState({ districtList: Districts.ninhthuan_huyenthuanbac });
            break
          case '7':
            this.setState({ districtList: Districts.ninhthuan_huyenthuannam });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bình Thuận':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.binhthuan_thanhphophanthiet });
            break
          case '2':
            this.setState({ districtList: Districts.binhthuan_xalagi });
            break
          case '3':
            this.setState({ districtList: Districts.binhthuan_huyentuyphong });
            break
          case '4':
            this.setState({ districtList: Districts.binhthuan_huyenbacbinh });
            break
          case '5':
            this.setState({ districtList: Districts.binhthuan_huyenhamthuanbac });
            break
          case '6':
            this.setState({ districtList: Districts.binhthuan_huyenhamthuannam });
            break
          case '7':
            this.setState({ districtList: Districts.binhthuan_huyentanhlinh });
            break
          case '8':
            this.setState({ districtList: Districts.binhthuan_huyenduclinh });
            break
          case '9':
            this.setState({ districtList: Districts.binhthuan_huyenhamtan });
            break
          case '10':
            this.setState({ districtList: Districts.binhthuan_huyenphuqui });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Kon Tum':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.kontum_konTum });
            break
          case '2':
            this.setState({ districtList: Districts.kontum_dakGlei });
            break
          case '3':
            this.setState({ districtList: Districts.kontum_dakHa });
            break
          case '4':
            this.setState({ districtList: Districts.kontum_dakTo });
            break
          case '5':
            this.setState({ districtList: Districts.kontum_iaHDrai });
            break
          case '6':
            this.setState({ districtList: Districts.kontum_konPlông });
            break
          case '7':
            this.setState({ districtList: Districts.kontum_konRay });
            break
          case '8':
            this.setState({ districtList: Districts.kontum_ngocHoi });
            break
          case '9':
            this.setState({ districtList: Districts.kontum_saThay });
            break
          case '10':
            this.setState({ districtList: Districts.kontum_tuMoRong });
            break
          default:
            break
        }
        break
      case 'Gia Lai':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.gialai_pleiKu });
            break
          case '2':
            this.setState({ districtList: Districts.gialai_anKhe });
            break
          case '3':
            this.setState({ districtList: Districts.gialai_ayunPa });
            break
          case '4':
            this.setState({ districtList: Districts.gialai_chuPah });
            break
          case '5':
            this.setState({ districtList: Districts.gialai_chuProng });
            break
          case '6':
            this.setState({ districtList: Districts.gialai_chuPuh });
            break
          case '7':
            this.setState({ districtList: Districts.gialai_chuSe });
            break
          case '8':
            this.setState({ districtList: Districts.gialai_dakDoa });
            break
          case '9':
            this.setState({ districtList: Districts.gialai_dakPo });
            break
          case '10':
            this.setState({ districtList: Districts.gialai_ducCo });
            break
          case '11':
            this.setState({ districtList: Districts.gialai_iaGrai });
            break
          case '12':
            this.setState({ districtList: Districts.gialai_iaPa });
            break
          case '13':
            this.setState({ districtList: Districts.gialai_kBang });
            break
          case '14':
            this.setState({ districtList: Districts.gialai_kongChro });
            break
          case '15':
            this.setState({ districtList: Districts.gialai_krongPa });
            break
          case '16':
            this.setState({ districtList: Districts.gialai_mangYang });
            break
          case '17':
            this.setState({ districtList: Districts.gialai_phuThien });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Đắk Lắk':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.daklak_buonMaThuot });
            break
          case '2':
            this.setState({ districtList: Districts.daklak_buonHo });
            break
          case '3':
            this.setState({ districtList: Districts.daklak_buonDon });
            break
          case '4':
            this.setState({ districtList: Districts.daklak_cuKuin });
            break
          case '5':
            this.setState({ districtList: Districts.daklak_cuMgar });
            break
          case '6':
            this.setState({ districtList: Districts.daklak_eaHleo });
            break
          case '7':
            this.setState({ districtList: Districts.daklak_eaKar });
            break
          case '8':
            this.setState({ districtList: Districts.daklak_eaSub });
            break
          case '9':
            this.setState({ districtList: Districts.daklak_krongAna });
            break
          case '10':
            this.setState({ districtList: Districts.daklak_krongBong });
            break
          case '11':
            this.setState({ districtList: Districts.daklak_krongBuk });
            break
          case '12':
            this.setState({ districtList: Districts.daklak_krongNang });
            break
          case '13':
            this.setState({ districtList: Districts.daklak_krongPack });
            break
          case '14':
            this.setState({ districtList: Districts.daklak_lak });
            break
          case '15':
            this.setState({ districtList: Districts.daklak_madrak });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Đắk Nông':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.daknong_giaNghia });
            break
          case '2':
            this.setState({ districtList: Districts.daknong_cuJut });
            break
          case '3':
            this.setState({ districtList: Districts.daknong_dakGlong });
            break
          case '4':
            this.setState({ districtList: Districts.daknong_dakMil });
            break
          case '5':
            this.setState({ districtList: Districts.daknong_dakRlap });
            break
          case '6':
            this.setState({ districtList: Districts.daknong_dakSong });
            break
          case '7':
            this.setState({ districtList: Districts.daknong_krongNo });
            break
          case '8':
            this.setState({ districtList: Districts.daknong_tuyDuc });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Lâm Đồng':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.lamdong_baoLoc });

            break
          case '2':
            this.setState({ districtList: Districts.lamdong_daLat });
            break
          case '3':
            this.setState({ districtList: Districts.lamdong_baoLam });
            break
          case '4':
            this.setState({ districtList: Districts.lamdong_catTien });
            break
          case '5':
            this.setState({ districtList: Districts.lamdong_diLinh });
            break
          case '6':
            this.setState({ districtList: Districts.lamdong_daHuoai });
            break
          case '7':
            this.setState({ districtList: Districts.lamdong_daTeh });
            break
          case '8':
            this.setState({ districtList: Districts.lamdong_damRong });
            break
          case '9':
            this.setState({ districtList: Districts.lamdong_donDuong });
            break
          case '10':
            this.setState({ districtList: Districts.lamdong_ducTrong });
            break
          case '11':
            this.setState({ districtList: Districts.lamdong_lacDuong });
            break
          case '12':
            this.setState({ districtList: Districts.lamdong_lamHa });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bình Phước':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.binhphuoc_xaphuoclong });
            break
          case '2':
            this.setState({ districtList: Districts.binhphuoc_xadongxoai });
            break
          case '3':
            this.setState({ districtList: Districts.binhphuoc_xabinhlong });
            break
          case '4':
            this.setState({ districtList: Districts.binhphuoc_huyenbugiamap });
            break
          case '5':
            this.setState({ districtList: Districts.binhphuoc_huyenlocninh });
            break
          case '6':
            this.setState({ districtList: Districts.binhphuoc_huyenbudop });
            break
          case '7':
            this.setState({ districtList: Districts.binhphuoc_huyenhonquan });
            break
          case '8':
            this.setState({ districtList: Districts.binhphuoc_huyendongphu });
            break
          case '9':
            this.setState({ districtList: Districts.binhphuoc_huyenbudang });
            break
          case '10':
            this.setState({ districtList: Districts.binhphuoc_huyenchonthanh });
            break
          case '11':
            this.setState({ districtList: Districts.binhphuoc_huyenphurieng });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Tây Ninh':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.tayninh_thanhphotayninh });
            break
          case '2':
            this.setState({ districtList: Districts.tayninh_huyentanbien });
            break
          case '3':
            this.setState({ districtList: Districts.tayninh_huyentanchau });
            break
          case '4':
            this.setState({ districtList: Districts.tayninh_huyenduongminhchau });
            break
          case '5':
            this.setState({ districtList: Districts.tayninh_huyenchauthanh });
            break
          case '6':
            this.setState({ districtList: Districts.tayninh_huyenhoathanh });
            break
          case '7':
            this.setState({ districtList: Districts.tayninh_huyengodau });
            break
          case '8':
            this.setState({ districtList: Districts.tayninh_huyenbencau });
            break
          case '9':
            this.setState({ districtList: Districts.tayninh_huyentrangbang });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bình Dương':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.binhduong_thanhphothudaumot });
            break
          case '2':
            this.setState({ districtList: Districts.binhduong_huyenbaubang });
            break
          case '3':
            this.setState({ districtList: Districts.binhduong_huyendaitieng });
            break
          case '4':
            this.setState({ districtList: Districts.binhduong_xabencat });
            break
          case '5':
            this.setState({ districtList: Districts.binhduong_huyenphugiao });
            break
          case '6':
            this.setState({ districtList: Districts.binhduong_xatanuyen });
            break
          case '7':
            this.setState({ districtList: Districts.binhduong_xadian });
            break
          case '8':
            this.setState({ districtList: Districts.binhduong_xathuanan });
            break
          case '9':
            this.setState({ districtList: Districts.binhduong_huyenbactanuyen });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Đồng Nai':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.dongnai_thanhphobienhoa });
            break
          case '2':
            this.setState({ districtList: Districts.dongnai_xalongkhanh });
            break
          case '3':
            this.setState({ districtList: Districts.dongnai_huyentanphu });
            break
          case '4':
            this.setState({ districtList: Districts.dongnai_huyenvinhcuu });
            break
          case '5':
            this.setState({ districtList: Districts.dongnai_huyendinhquan });
            break
          case '6':
            this.setState({ districtList: Districts.dongnai_huyentrangbom });
            break
          case '7':
            this.setState({ districtList: Districts.dongnai_huyenthongnhat });
            break
          case '8':
            this.setState({ districtList: Districts.dongnai_huyencammy });
            break
          case '9':
            this.setState({ districtList: Districts.dongnai_huyenlongthanh });
            break
          case '10':
            this.setState({ districtList: Districts.dongnai_huyenxuanloc });
            break
          case '11':
            this.setState({ districtList: Districts.dongnai_huyennhontrach });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bà Rịa - Vũng Tàu':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.bariavungtau_thanhphovungtau });
            break
          case '2':
            this.setState({ districtList: Districts.bariavungtau_thanhphobaria });
            break
          case '3':
            this.setState({ districtList: Districts.bariavungtau_huyenchauduc });
            break
          case '4':
            this.setState({ districtList: Districts.bariavungtau_huyenxuyenmoc });
            break
          case '5':
            this.setState({ districtList: Districts.bariavungtau_huyenlongdien });
            break
          case '6':
            this.setState({ districtList: Districts.bariavungtau_huyendatdo });
            break
          case '7':
            this.setState({ districtList: Districts.bariavungtau_huyentanthanh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Long An':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.longan_thanhphotanan });
            break
          case '2':
            this.setState({ districtList: Districts.longan_xakientuong });
            break
          case '3':
            this.setState({ districtList: Districts.longan_huyentanhung });
            break
          case '4':
            this.setState({ districtList: Districts.longan_huyenvinhhung });
            break
          case '5':
            this.setState({ districtList: Districts.longan_huyenmochoa });
            break
          case '6':
            this.setState({ districtList: Districts.longan_huyentanthanh });
            break
          case '7':
            this.setState({ districtList: Districts.longan_huyenthanhhoa });
            break
          case '8':
            this.setState({ districtList: Districts.longan_huyenduchue });
            break
          case '9':
            this.setState({ districtList: Districts.longan_huyenduchoa });
            break
          case '10':
            this.setState({ districtList: Districts.longan_huyenbenluc });
            break
          case '11':
            this.setState({ districtList: Districts.longan_huyenthuthua });
            break
          case '12':
            this.setState({ districtList: Districts.longan_huyentantru });
            break
          case '13':
            this.setState({ districtList: Districts.longan_huyencanduoc });
            break
          case '14':
            this.setState({ districtList: Districts.longan_huyencangiuoc });
            break
          case '15':
            this.setState({ districtList: Districts.longan_huyenchauthanh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Tiền Giang':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.tiengiang_thanhphomytho });
            break
          case '2':
            this.setState({ districtList: Districts.tiengiang_xagocong });
            break
          case '3':
            this.setState({ districtList: Districts.tiengiang_xacailay });
            break
          case '4':
            this.setState({ districtList: Districts.tiengiang_huyentanphuoc });
            break
          case '5':
            this.setState({ districtList: Districts.tiengiang_huyencaibe });
            break
          case '6':
            this.setState({ districtList: Districts.tiengiang_huyencailay });
            break
          case '7':
            this.setState({ districtList: Districts.tiengiang_huyenchauthanh });
            break
          case '8':
            this.setState({ districtList: Districts.tiengiang_huyenchogao });
            break
          case '9':
            this.setState({ districtList: Districts.tiengiang_huyengocongtay });
            break
          case '10':
            this.setState({ districtList: Districts.tiengiang_huyengocongdong });
            break
          case '11':
            this.setState({ districtList: Districts.tiengiang_huyentanphudong });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bến Tre':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.bentre_thanhphobentre });
            break
          case '2':
            this.setState({ districtList: Districts.bentre_huyenchauthanh });
            break
          case '3':
            this.setState({ districtList: Districts.bentre_huyencholach });
            break
          case '4':
            this.setState({ districtList: Districts.bentre_huyenmocaynam });
            break
          case '5':
            this.setState({ districtList: Districts.bentre_huyengiongtrom });
            break
          case '6':
            this.setState({ districtList: Districts.bentre_huyenbinhdai });
            break
          case '7':
            this.setState({ districtList: Districts.bentre_huyenbatri });
            break
          case '8':
            this.setState({ districtList: Districts.bentre_huyenthanhphu });
            break
          case '9':
            this.setState({ districtList: Districts.bentre_huyenmocaybac });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Trà Vinh':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.travinh_thanhphotravinh });
            break
          case '2':
            this.setState({ districtList: Districts.travinh_huyencanglong });
            break
          case '3':
            this.setState({ districtList: Districts.travinh_huyencauke });
            break
          case '4':
            this.setState({ districtList: Districts.travinh_huyentieucan });
            break
          case '5':
            this.setState({ districtList: Districts.travinh_huyenchauthanh });
            break
          case '6':
            this.setState({ districtList: Districts.travinh_huyenaungang });
            break
          case '7':
            this.setState({ districtList: Districts.travinh_huyentracu });
            break
          case '8':
            this.setState({ districtList: Districts.travinh_huyenduyenhai });
            break
          case '9':
            this.setState({ districtList: Districts.travinh_xaduyenhai });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Vĩnh Long':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.vinhlong_thanhphovinhlong });
            break
          case '2':
            this.setState({ districtList: Districts.vinhlong_huyenlongho });
            break
          case '3':
            this.setState({ districtList: Districts.vinhlong_huyenmangthit });
            break
          case '4':
            this.setState({ districtList: Districts.vinhlong_huyenvungliem });
            break
          case '5':
            this.setState({ districtList: Districts.vinhlong_huyentambinh });
            break
          case '6':
            this.setState({ districtList: Districts.vinhlong_xabinhminh });
            break
          case '7':
            this.setState({ districtList: Districts.vinhlong_huyentraon });
            break
          case '8':
            this.setState({ districtList: Districts.vinhlong_huyenbinhtan });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Đồng Tháp':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.dongthap_thanhphocaolanh });
            break
          case '2':
            this.setState({ districtList: Districts.dongthap_thanhphosadec });
            break
          case '3':
            this.setState({ districtList: Districts.dongthap_xahongngu });
            break
          case '4':
            this.setState({ districtList: Districts.dongthap_huyentanhong });
            break
          case '5':
            this.setState({ districtList: Districts.dongthap_huyenhongngu });
            break
          case '6':
            this.setState({ districtList: Districts.dongthap_huyentamnong });
            break
          case '7':
            this.setState({ districtList: Districts.dongthap_huyenthapmuoi });
            break
          case '8':
            this.setState({ districtList: Districts.dongthap_huyencaolanh });
            break
          case '9':
            this.setState({ districtList: Districts.dongthap_huyenthanhbinh });
            break
          case '10':
            this.setState({ districtList: Districts.dongthap_huyenlapvo });
            break
          case '11':
            this.setState({ districtList: Districts.dongthap_huyenlaivung });
            break
          case '12':
            this.setState({ districtList: Districts.dongthap_huyenchauthanh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'An Giang':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.angiang_thanhpholongxuyen });
            break
          case '2':
            this.setState({ districtList: Districts.angiang_thanhphochaudoc });
            break
          case '3':
            this.setState({ districtList: Districts.angiang_huyenanphu });
            break
          case '4':
            this.setState({ districtList: Districts.angiang_xatanchau });
            break
          case '5':
            this.setState({ districtList: Districts.angiang_huyenphutan });
            break
          case '6':
            this.setState({ districtList: Districts.angiang_huyenchauphu });
            break
          case '7':
            this.setState({ districtList: Districts.angiang_huyentinhbien });
            break
          case '8':
            this.setState({ districtList: Districts.angiang_huyentriton });
            break
          case '9':
            this.setState({ districtList: Districts.angiang_huyenchauthanh });
            break
          case '10':
            this.setState({ districtList: Districts.angiang_huyenchomoi });
            break
          case '11':
            this.setState({ districtList: Districts.angiang_huyenthoaison });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Kiên Giang':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.kiengiang_thanhphorachgia });
            break
          case '2':
            this.setState({ districtList: Districts.kiengiang_xahatien });
            break
          case '3':
            this.setState({ districtList: Districts.kiengiang_huyenkienluong });
            break
          case '4':
            this.setState({ districtList: Districts.kiengiang_huyenhondat });
            break
          case '5':
            this.setState({ districtList: Districts.kiengiang_huyentanhiep });
            break
          case '6':
            this.setState({ districtList: Districts.kiengiang_huyenchauthanh });
            break
          case '7':
            this.setState({ districtList: Districts.kiengiang_huyengiongrieng });
            break
          case '8':
            this.setState({ districtList: Districts.kiengiang_huyengoquao });
            break
          case '9':
            this.setState({ districtList: Districts.kiengiang_huyenanbien });
            break
          case '10':
            this.setState({ districtList: Districts.kiengiang_huyenanminh });
            break
          case '11':
            this.setState({ districtList: Districts.kiengiang_huyenvinhthuan });
            break
          case '12':
            this.setState({ districtList: Districts.kiengiang_huyenphuquoc });
            break
          case '13':
            this.setState({ districtList: Districts.kiengiang_huyenkienhai });
            break
          case '14':
            this.setState({ districtList: Districts.kiengiang_huyenuminhthuong });
            break
          case '15':
            this.setState({ districtList: Districts.kiengiang_quangiangthanh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Cần Thơ':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.cantho_quaninhkieu });
            break
          case '2':
            this.setState({ districtList: Districts.cantho_quanomon });
            break
          case '3':
            this.setState({ districtList: Districts.cantho_quanbinhthuy });
            break
          case '4':
            this.setState({ districtList: Districts.cantho_quancairang });
            break
          case '5':
            this.setState({ districtList: Districts.cantho_quanthotnot });
            break
          case '6':
            this.setState({ districtList: Districts.cantho_huyenvinhthanh });
            break
          case '7':
            this.setState({ districtList: Districts.cantho_huyencodo });
            break
          case '8':
            this.setState({ districtList: Districts.cantho_huyenphongdien });
            break
          case '9':
            this.setState({ districtList: Districts.cantho_xathoilai });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Hậu Giang':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.haugiang_thanhphovithanh });
            break
          case '2':
            this.setState({ districtList: Districts.haugiang_xangabay });
            break
          case '3':
            this.setState({ districtList: Districts.haugiang_huyenchauthanhA });
            break
          case '4':
            this.setState({ districtList: Districts.haugiang_huyenchauthanh });
            break
          case '5':
            this.setState({ districtList: Districts.haugiang_huyenphunghiep });
            break
          case '6':
            this.setState({ districtList: Districts.haugiang_huyenvithuy });
            break
          case '7':
            this.setState({ districtList: Districts.haugiang_huyenlongmy });
            break
          case '8':
            this.setState({ districtList: Districts.haugiang_xalongmy });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Sóc Trăng':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.soctrang_thanhphosoctrang });
            break
          case '2':
            this.setState({ districtList: Districts.soctrang_huyenchauthanh });
            break
          case '3':
            this.setState({ districtList: Districts.soctrang_huyenkesach });
            break
          case '4':
            this.setState({ districtList: Districts.soctrang_huyenmytu });
            break
          case '5':
            this.setState({ districtList: Districts.soctrang_huyenculaodung });
            break
          case '6':
            this.setState({ districtList: Districts.soctrang_huyenlongphu });
            break
          case '7':
            this.setState({ districtList: Districts.soctrang_huyenmyxuyen });
            break
          case '8':
            this.setState({ districtList: Districts.soctrang_xanganam });
            break
          case '9':
            this.setState({ districtList: Districts.soctrang_huyenthanhtri });
            break
          case '10':
            this.setState({ districtList: Districts.soctrang_xavinhchau });
            break
          case '11':
            this.setState({ districtList: Districts.soctrang_huyentrande });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Bạc Liêu':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.baclieu_thanhphobaclieu });
            break
          case '2':
            this.setState({ districtList: Districts.baclieu_huyenhongdan });
            break
          case '3':
            this.setState({ districtList: Districts.baclieu_huyenphuoclong });
            break
          case '4':
            this.setState({ districtList: Districts.baclieu_huyenvinhloi });
            break
          case '5':
            this.setState({ districtList: Districts.baclieu_xagiarai });
            break
          case '6':
            this.setState({ districtList: Districts.baclieu_huyendonghai });
            break
          case '7':
            this.setState({ districtList: Districts.baclieu_huyenhoabinh });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
      case 'Cà Mau':
        switch (selectedOption.value) {
          case '1':
            this.setState({ districtList: Districts.camau_thanhphocamau });
            break
          case '2':
            this.setState({ districtList: Districts.camau_huyenuminh });
            break
          case '3':
            this.setState({ districtList: Districts.camau_huyenthoibinh });
            break
          case '4':
            this.setState({ districtList: Districts.camau_huyentranvanthoi });
            break
          case '5':
            this.setState({ districtList: Districts.camau_huyencainuoc });
            break
          case '6':
            this.setState({ districtList: Districts.camau_huyendamdoi });
            break
          case '7':
            this.setState({ districtList: Districts.camau_huyennamcan });
            break
          case '8':
            this.setState({ districtList: Districts.camau_huyenphutan });
            break
          case '9':
            this.setState({ districtList: Districts.camau_huyenngochuyen });
            break
          default:
            this.setState({ districtList: null });
            break
        }
        break
        default:
          
      }
  };

  // chọn pulldown select District
  ChangeSelectDistrict = (selectedOption) => {
    this.setState({ selectedDistrict: selectedOption })
  }

  // Chọn loại bất động sản giao dịch 
  OnChangeRadio = (selectedOption) => {
    if (selectedOption.target.name === 'estatesType_1') {
      this.setState({
        estatesType: 1,
        raidoEstatesType: { estatesType_1: true, estatesType_2: false, estatesType_3: false },
      });
    } else if (selectedOption.target.name === 'estatesType_2') {
      this.setState({
        estatesType: 2,
        raidoEstatesType: { estatesType_1: false, estatesType_2: true, estatesType_3: false },
      });
    }else {
      this.setState({
        estatesType: 3,
        raidoEstatesType: { estatesType_1: false, estatesType_2: false, estatesType_3: true },
      });
    }
  }

  // Lấy giá trị được chọn từ select trên màn hình
  OnchangeSelect = (selectedOption, selectName) => {
    switch (selectName) {
      case "estates-select":
        this.setState({ estatesNameRq: selectedOption.label })
        break;
      case "estates-select_street":
        this.setState({ estatesStreetRq: selectedOption.label })
        break;
      case "estates-select_legal-doc":
        this.setState({ estatesLegalDocRq: selectedOption.label })
        break;
      case "estates-width-road":
        this.setState({ widthRoadRq: selectedOption.label })
        break;
      case "estates-select_price-for-unit":
        this.setState({ estatesPriceForUnitRq: selectedOption.label })
        break;
      case "estates-select_direction":
        this.setState({ estatesDirectionRq: selectedOption.label })
        break;
      case "estates-select_bed-rooms":
        this.setState({ estatesNumsBedRoomRq: selectedOption.label })
        break;
      case "estates-select_guest-rooms":
        this.setState({ estatesNumsGuestRoomRq: selectedOption.label })
        break;
      case "estates-select_bathandtoilet-rooms":
        this.setState({ estatesNumsBathAndToiletRoomRq: selectedOption.label })
        break;
      case "transaction-type":
      this.setState({ transactionTypeRq: selectedOption.label })
        break;
      default:
        break;
    }
  }

  CapitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  };

  // Nhập tiêu đề bất động sản
  HandleTextareaChange(event, textAreaName) {
    switch (textAreaName) {
      case "estates-title-input":
        this.setState({ estatesTitleRq: this.CapitalizeFirstLetter(event.target.value) })
        break;
      case "estates-detail-input":
        this.setState({ estatesDetailRq: event.target.value })
        break;
      case "estates-area-input":
        this.setState({ estatesAreaRq: event.target.value })
        break;
      case "estates-price-input":
        this.convertToMillionOrBillion(event.target.value)
        break;
      case "estates-street-input":
        this.setState({ estatesStreetRq: event.target.value })
        break;
      case "contactname":
        this.setState({ contactNameRq: event.target.value })
        break;
      case "contactphone":
        this.setState({ contactPhoneRq: event.target.value })
        break;
      case "estates-width-road":
        this.setState({ widthRoadRq: event.target.value })
      break;
      default:
        break;
    }
  }

  convertToMillionOrBillion = (value) => {
    let updatedValue;
    let unitToText;
    let updatePriceToText;
    let cleanedInput = '';

    if(this.state.errors["estatesPriceRq"] !== '') {
      this.setState({errors: {estatesPriceRq : ''}})
    }

    if (value.includes(',')) {
      cleanedInput = value.replace(/,/g, '');
    }

    if (cleanedInput !== '') {
      if (cleanedInput.length > 13) {
        updatedValue = cleanedInput.slice(0, 1) + "," +  cleanedInput.slice(1, 4) + "," + cleanedInput.slice(4, 7) + "," + cleanedInput.slice(7, 10) + "," + cleanedInput.slice(10, 13);
        unitToText = 'Tỷ';
        updatePriceToText = cleanedInput.slice(0, 2) + "," + cleanedInput.slice(2,4) + " " + unitToText;        
      }
      else if (cleanedInput.length >= 13) {
        updatedValue = cleanedInput.slice(0, 1) + "," +  cleanedInput.slice(1, 4) + "," + cleanedInput.slice(4, 7) + "," + cleanedInput.slice(7, 10) + "," + cleanedInput.slice(10, 13);
        unitToText = 'Tỷ';
        updatePriceToText = cleanedInput.slice(0, 3) + "," + cleanedInput.slice(3,4) + " " + unitToText;
      }
      else if (cleanedInput.length >= 12) {
        unitToText = 'Tỷ';
        updatedValue = cleanedInput.slice(0, 3) + "," + cleanedInput.slice(3, 6) + "," + cleanedInput.slice(6, 9) + "," + cleanedInput.slice(9, 12);
        updatePriceToText = cleanedInput.slice(0, 3) + "," + cleanedInput.slice(3,5) + " " + unitToText;
      }
      else if (cleanedInput.length >= 11) {
        updatedValue = cleanedInput.slice(0, 2) + "," + cleanedInput.slice(2, 5) + "," + cleanedInput.slice(5, 8) + "," + cleanedInput.slice(8, 11)
        unitToText = 'Tỷ';
        updatePriceToText = cleanedInput.slice(0, 2) + "," + cleanedInput.slice(2,4) + " " + unitToText;        
      }
      else if (cleanedInput.length >= 10) {
        updatedValue = cleanedInput.slice(0, 1) + "," + cleanedInput.slice(1, 4) + "," + cleanedInput.slice(4, 7) + "," + cleanedInput.slice(7, 10);
        unitToText = 'Tỷ';
        updatePriceToText = cleanedInput.slice(0, 1) + "," + cleanedInput.slice(1,3) + " " + unitToText;
      }
      else if (cleanedInput.length >= 9) {
        updatedValue = cleanedInput.slice(0, 3) + "," + cleanedInput.slice(3, 6) + "," + cleanedInput.slice(6, 9);
        unitToText = 'Triệu';
        updatePriceToText = cleanedInput.slice(0, 3) + "," + cleanedInput.slice(3,5) + " " + unitToText;
      }
      else if (cleanedInput.length >= 8) {
        updatedValue = cleanedInput.slice(0, 2) + "," + cleanedInput.slice(2, 5) + "," + cleanedInput.slice(5, 8);
        unitToText = 'Triệu';
        updatePriceToText = cleanedInput.slice(0, 2) + "," + cleanedInput.slice(2,4) + " " + unitToText;
      }
      else if (cleanedInput.length >= 7) {
        updatedValue = cleanedInput.slice(0, 1) + "," + cleanedInput.slice(1, 4) + "," + cleanedInput.slice(4, 7);
        unitToText = 'Triệu';
        updatePriceToText = cleanedInput.slice(0, 1) + "," + cleanedInput.slice(1,3) + " " + unitToText;
      }
      else if (cleanedInput.length >= 6) {
        updatedValue = cleanedInput.slice(0, 3) + "," + cleanedInput.slice(3, 6);
        unitToText = 'Ngàn';
        updatePriceToText = cleanedInput.slice(0, 3) + "," + cleanedInput.slice(3,5) + " " + unitToText;
      }
      else if (cleanedInput.length >= 5) {
        updatedValue = cleanedInput.slice(0, 2) + "," + cleanedInput.slice(2);
        unitToText = 'Ngàn';
        updatePriceToText = cleanedInput.slice(0, 2) + "," + cleanedInput.slice(2,4) + " " + unitToText;
      }
    } else if (value.length >= 4) {
      updatedValue = value.slice(0, 1) + "," + value.slice(1);
      unitToText = 'Ngàn';
      updatePriceToText = value.slice(0, 1) + "," + value.slice(1, 3) + " " + unitToText;
    }

    this.setState({ estatesPriceRq: updatedValue, unitPriceToText: updatePriceToText, priceAfterConvert: cleanedInput });
  };

  handleEditorChange = (value) => {
    this.setState({ estatesDetailRq: value });
  }

    // Hàm mở modal error
    openErrorModal = (message) => {
      this.setState({
        errorMessage: message,
        errorModalOpen: true
      });
    };

    // Hàm mở modal success
    openSuccessModal = (message) => {
      this.setState({
        successMessage: message,
        successModalOpen: true
      });
    };

    // Hàm đóng modal error
    closeErrorModal = () => {
      this.handleReset();
    };

    // Hàm đóng modal success
    closeSuccessModal = () => {
      this.handleReset();
    };
    
  // submit form
  SubmitForm = (e) => {
    if(this.state.resetPressed === true) {
      return
    }
    e.preventDefault();

    let errors = {};
    let formIsValid = true;

    // Check bất động sản giao dịch
    if (typeof this.state.estatesType === 'undefined') {
      formIsValid = false;
      errors["estatesType"] = disErrMess.ERROR_NOT_SELECT_RADIO_ESTATE;
    }
    // Check Loại bất động sản*
    if (this.state.estatesNameRq === "" || this.state.estatesNameRq === "undefined" || this.state.estatesNameRq === null) {
      formIsValid = false;
      errors["estatesNameRq"] = disErrMess.ERROR_NOT_SELECT_TYPE_ESTATE;
    }

    // Check Tỉnh, thành phố
    if (this.state.selectedCity === "" || this.state.selectedCity === "undefined" || this.state.selectedCity === null) {
      formIsValid = false;
      errors["selectedCity"] = disErrMess.ERROR_NOT_SELECT_CITY_ESTATE;
    }

    // Check Quận, huyện
    if (this.state.selectedProvince === "" || this.state.selectedProvince === "undefined" || this.state.selectedProvince === null) {
      formIsValid = false;
      errors["selectedProvinceRq"] = disErrMess.ERROR_NOT_SELECT_PROVINCE_ESTATE;
    }

    // Phường, xã
    if (this.state.selectedDistrict === "" || this.state.selectedDistrict === "undefined" || this.state.selectedDistrict === null) {
      formIsValid = false;
      errors["selectedDistrictRq"] = disErrMess.ERROR_NOT_SELECT_DISTRICT_ESTATE;
    }

    // Check Đường, phố
    if (this.state.estatesStreetRq === "" || this.state.estatesStreetRq === "undefined" || this.state.estatesStreetRq === null) {
      formIsValid = false;
      errors["estatesStreetRq"] = disErrMess.ERROR_NOT_SELECT_STREET_ESTATE;
    }

    // Check Tiêu đề
    if (this.state.estatesTitleRq === "" || this.state.estatesTitleRq === "undefined" || this.state.estatesTitleRq === null) {
      formIsValid = false;
      errors["estatesTitleRq"] = disErrMess.ERROR_NOT_INPUT_TITLE_ESTATE;
    }

    // Check Mô tả tổng quát bất động sản
    if (this.state.estatesDetailRq === "" || this.state.estatesDetailRq === "undefined" || this.state.estatesDetailRq === null) {
      formIsValid = false;
      errors["estatesDetailRq"] = disErrMess.ERROR_NOT_INPUT_ĐETAIL_ESTATE;
    }

    // Check Diện tích
    if (this.state.estatesAreaRq === "" || this.state.estatesAreaRq === "undefined" || this.state.estatesAreaRq === null) {
      formIsValid = false;
      errors["estatesAreaRq"] = disErrMess.ERROR_NOT_INPUT_AREA_ESTATE;
    }

    // Check Giấy tờ pháp lý
    if (this.state.estatesLegalDocRq === "" || this.state.estatesLegalDocRq === "undefined" || this.state.estatesLegalDocRq === null) {
      formIsValid = false;
      errors["estatesLegalDocRq"] = disErrMess.ERROR_NOT_SELECT_LEGAL_ESTATE;
    }

    // Check mặt tiền
    if (this.state.widthRoadRq === "" || this.state.widthRoadRq === "undefined" || this.state.widthRoadRq === null) {
      formIsValid = false;
      errors["widthRoadRq"] = disErrMess.ERROR_NOT_SELECT_WIDTH_ROAD;
    }

    // Check Mức giá
    if (this.state.estatesPriceRq === "" || this.state.estatesPriceRq === "undefined" || this.state.estatesPriceRq === null) {
      formIsValid = false;
      errors["estatesPriceRq"] = disErrMess.ERROR_NOT_INPUT_PRICE_ESTATE;
    }

    // Chọn Mức giá
    if (this.state.estatesPriceForUnitRq === "" || this.state.estatesPriceForUnitRq === "undefined" || this.state.estatesPriceForUnitRq === null) {
      formIsValid = false;
      errors["estatesPriceForUnitRq"] = disErrMess.ERROR_NOT_SELECT_PRICE_ESTATE;
    }

    // Chọn Đơn vị
    if (this.state.estatesDirectionRq === "" || this.state.estatesDirectionRq === "undefined" || this.state.estatesDirectionRq === null) {
      formIsValid = false;
      errors["estatesDirectionRq"] = disErrMess.ERROR_NOT_INPUT_DIRECTION_ESTATE;
    }

    // Check tên liên lạc
    if (this.state.contactNameRq === "" || this.state.contactNameRq === "undefined" || this.state.contactNameRq === null) {
      formIsValid = false;
      errors["contactNameRq"] = disErrMess.ERROR_NOT_INPUT_CONTACT_NAME;
    }

    // Check số điện thoại liên lạc
    if (this.state.contactPhoneRq === "" || this.state.contactPhoneRq === "undefined" || this.state.contactPhoneRq === null) {
      formIsValid = false;
      errors["contactPhoneRq"] = disErrMess.ERROR_NOT_INPUT_CONTACT_PHONE;
    }

    if (this.state.contactPhoneRq.length > 11) {
        formIsValid = false;
        errors["contactPhoneRq"] = "Số điện thoại không hợp lệ.";
    }

    // Check Hình thức sở hữu
    if (this.state.transactionTypeRq === "" || this.state.transactionTypeRq === "undefined" || this.state.transactionTypeRq === null) {
      formIsValid = false;
      errors["transactionTypeRq"] = disErrMess.ERROR_NOT_INPUT_TRANSACTION_TYPE;
    }

    // Bo dau "," va check gia tien hop le?
    let cleanedInput = 0;
    if (this.state.estatesPriceRq.includes(',')) {
      cleanedInput = this.state.estatesPriceRq.replace(/,/g, '');
    } else {
      cleanedInput = this.state.estatesPriceRq;
    }
    if (cleanedInput.length < 7) {
      formIsValid = false;
      this.setState({unitPriceToText : ''})
      errors["estatesPriceRq"] = disErrMess.ERROR_NOT_INPUT_VALID_PRICE_ESTATE;
    }

    this.setState({ errors: errors });

    if (formIsValid !== false) {
      const publishDataReq = new FormData();
      // Thêm các trường dữ liệu vào FormData
      publishDataReq.append('username', localStorage.getItem('username'));
      publishDataReq.append('estates_Type', this.state.estatesType);
      publishDataReq.append('estates_Name', this.state.estatesNameRq);
      publishDataReq.append('selected_City', this.state.selectedCity);
      publishDataReq.append('selected_Province', this.state.selectedProvince.label);
      publishDataReq.append('selected_District', this.state.selectedDistrict.label);
      publishDataReq.append('estates_Street', this.state.estatesStreetRq);
      publishDataReq.append('estates_Title', this.state.estatesTitleRq);
      publishDataReq.append('estates_Detail', this.state.estatesDetailRq);
      publishDataReq.append('estates_Area', this.state.estatesAreaRq);
      publishDataReq.append('estates_Legal_Doc', this.state.estatesLegalDocRq);
      publishDataReq.append('width_Road', this.state.widthRoadRq);
      publishDataReq.append('estates_Price', cleanedInput);
      publishDataReq.append('estates_PriceForUnit', this.state.estatesPriceForUnitRq);
      publishDataReq.append('estates_Direction_Home', this.state.estatesDirectionRq);
      publishDataReq.append('estates_Nums_BedRoom', this.state.estatesNumsBedRoomRq);
      publishDataReq.append('estates_Nums_GuestRoom', this.state.estatesNumsGuestRoomRq);
      publishDataReq.append('estates_NumsBathAndToiletRoom', this.state.estatesNumsBathAndToiletRoomRq);
      publishDataReq.append('contact_Name', this.state.contactNameRq);
      publishDataReq.append('contact_Phone', this.state.contactPhoneRq);
      publishDataReq.append('transaction_Type', this.state.transactionTypeRq);
    
      const uploadedFiles = this.state.uploadedFiles;
      if (uploadedFiles.length === 0) {
        alert(`Bạn phải upload image khi đăng tin.`);
        return
      } else {
        for (let i = 0; i < uploadedFiles.length; i++) {
          const file = uploadedFiles[i];
          publishDataReq.append('images', file, file.name);
        }
      }

      if (!this.state.isRecaptchaVerified) {
        this.setState({
          recaptchaError: "Chọn tôi không phải robot để tiếp tục."
        });
        return;
      }

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      
      http.post(`/publish/`, publishDataReq, { headers })
        .then(res => {
          if (res.status === 201) {
            this.openSuccessModal("Bạn đã đăng tin thành công.")
          } else if (res.status === 200) {
            if (res.data !== '') {
              this.openErrorModal("Bạn đã đăng quá 5 tin/ngày. Vui lòng thử lại vào ngày mai.");
            }
          } else {
            console.log("Xuất hiện lỗi system trong quá trình đăng tin.");
          }
        })
        .catch(error => {
          console.log("Có lỗi xảy ra trong quá trình xử lý: ", error);
        });
    }
  };

  handleReset = () => {
    this.setState({resetPressed : true,  recaptchaError: ""});
    window.location.reload();
  }
  
  handleRecaptchaChange = (value) => {
    if (value) {
      this.setState({
        isRecaptchaVerified: true,
        recaptchaError: ""
      });
    } else {
      this.setState({
        isRecaptchaVerified: false,
        recaptchaError: "Chọn tôi không phải robot để tiếp tục."
      });
    }
  };

  render() {

    const lang = {
      hl: "en"
    };

    return (
      <div className="publist-content">
      <Form onSubmit={this.SubmitForm} encType="multipart/form-data">
        <div className="section_h2-header_block1"><h5 className="title_display_default">{disLabel.BASIS_INFO}</h5></div>
        <span className="left-align_label-left">{disLabel.DESCRIPTION_PUBLISH_1}</span><span className="red-note">(*)</span><span className="left-align_label-right">{disLabel.DESCRIPTION_PUBLISH_2}</span>
        
        <div className="citys-column">
          <div className="form-group col-sm-12">
            <div className="select-theme">
              <p>{disLabel.SELECT_ESTATE_NEED_TO_PUBLISH}</p>
              <div className="radio-align-center">
                <RadioButton
                  name="estatesType_1"
                  id="radio_type_1"
                  text={disLabel.ESTATE_SELL_TAB_SEARCH}
                  onChange={this.OnChangeRadio}
                  checked={this.state.raidoEstatesType.estatesType_1}
                />
                <RadioButton
                  name="estatesType_2"
                  id="radio_type_2"
                  text={disLabel.ESTATE_RENT_TAB_SEARCH}
                  onChange={this.OnChangeRadio}
                  checked={this.state.raidoEstatesType.estatesType_2}
                />
                <RadioButton
                  name="estatesType_3"
                  id="radio_type_3"
                  text={disLabel.ESTATE_INVEST_TAB_SEARCH}
                  onChange={this.OnChangeRadio}
                  checked={this.state.raidoEstatesType.estatesType_3}
                />
              </div>
            </div>
            <span className="text-danger">{this.state.errors["estatesType"]}</span>
          </div>
        </div>
        
        <div className="citys-column">
          <div className="form-group col-sm-4">
              <span>{disLabel.ESTATE_TYPE}<span className="red-note">*</span></span>
              <Select
                name="estates-select"
                placeholder="Chọn loại nhà đất"
                options={this.state.estates}
                isSearchable={true}
                maxMenuHeight="300px"
                onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select")}
              />
              <span className="text-danger">{this.state.errors["estatesNameRq"]}</span>
            </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.DESCRIPT_CITY}<span className="red-note">*</span></span>
            <Select
              onChange={this.ChangeSelectCitys}
              options={this.state.citys}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn tỉnh, thành phố"
            />
            <span className="text-danger">{this.state.errors["selectedCity"]}</span>
          </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.DESCRIPT_PROVINCE}<span className="red-note">*</span></span>
            <Select
              value={this.state.changeCityFlag === true ? this.state.selectedProvince: area.defaultOptions}
              onChange={this.ChangeSelectProvince}
              options={this.state.changeCityFlag === true ? this.state.provinceList: area.defaultOptions }
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn quận, huyện"
            />
            <span className="text-danger">{this.state.errors["selectedProvinceRq"]}</span>
          </div>
        </div>
        
        <div className="citys-column">
          <div className="form-group col-sm-4">
            <span>{disLabel.DESCRIPT_DISTRICT}<span className="red-note">*</span></span>
            <Select
              value={ this.state.changeCityFlag === true && this.state.changeProvinceFlag === false ? area.defaultOptions : this.state.selectedDistrict}
              onChange={this.ChangeSelectDistrict}
              options={ this.state.changeCityFlag === true && this.state.changeProvinceFlag === false ? area.defaultOptions : this.state.districtList }
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn phường, xã"
            />
            <span className="text-danger">{this.state.errors["selectedDistrictRq"]}</span>
          </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.DESCRIPT_STREET}</span>
            <Input
              value={this.state.estatesStreetRq}
              name="estates-street-input"
              type="text"
              id="street"
              maxLength="70"
              placeholder="Nhập đường, phố"
              onChange={(InputedTextarea) => this.HandleTextareaChange(InputedTextarea, "estates-street-input")}
            />
            <span className="text-danger">{this.state.errors["estatesStreetRq"]}</span>
          </div>
        </div>
        
        <div className="section_h2-header_block2"><h5 className="title_display_default">{disLabel.PUBLISHER_INFO}</h5></div>
        <div className="citys-column">
          <div className="form-group col-sm-12">
            <span>{disLabel.DESCRIPT_TITLE}<span className="red-note">*</span></span>
            <textarea 
              value={this.state.estatesTitleRq}
              className="form-control" 
              rows="1" maxLength="100" 
              placeholder="Nhập tiêu đề cho bất động sản" 
              onChange={(InputedTextarea) => this.HandleTextareaChange(InputedTextarea, "estates-title-input")}
              >
            </textarea>
            <span className="text-danger">{this.state.errors["estatesTitleRq"]}</span>
          </div>
        </div>
        
          <div className="citys-column">
            <div className="form-group col-sm-12">
              <span>{disLabel.DESCRIPT_ESTATE}<span className="red-note">*</span></span>
              <TextEditor onEditorChange={this.handleEditorChange}/>
              <span className="text-danger">{this.state.errors["estatesDetailRq"]}</span>
            </div>
          </div>
        
        <div className="section_h2-header_block2"><h5 className="title_display_default">{disLabel.ESTATE_INFO}</h5></div>

        <div className="citys-column">
          <div className="form-group col-sm-4">
            <span>{disLabel.AREA}<span className="red-note">*</span></span>
            <span className="area-m2">{disLabel.M2}</span>
            <Input
              value={this.state.estatesAreaRq} 
              type="text"
              id="area"
              maxLength="4"
              placeholder="Nhập diện tích"
              onChange={(InputedTextarea) => this.HandleTextareaChange(InputedTextarea, "estates-area-input")}
            />
            <span className="text-danger">{this.state.errors["estatesAreaRq"]}</span>
          </div>
          
          <div className="form-group col-sm-4">
            <span>{disLabel.LEGAL_DOCUMENT}<span className="red-note">*</span></span>
            <Select
              name="estates-select_legal-doc"
              options={this.state.legalDocument}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn giấy tờ pháp lý"
              onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select_legal-doc")}
            />
            <span className="text-danger">{this.state.errors["estatesLegalDocRq"]}</span>
          </div>

          <div className="form-group col-sm-4">
            <span>{disLabel.WIDTH_ROAD}<span className="red-note">*</span></span>
            <span className="area-m2">{disLabel.M}</span>
            <Input
              value={this.state.widthRoadRq} 
              type="text"
              id="widthroad"
              maxLength="2"
              placeholder="Nhập chiều rộng mặt đường"
              onChange={(InputedTextarea) => this.HandleTextareaChange(InputedTextarea, "estates-width-road")}
            />
            <span className="text-danger">{this.state.errors["widthRoadRq"]}</span>
          </div>
        </div>
        <div className="citys-column">
          <div className="form-group col-sm-4">
            <span>{disLabel.PRICE}<span className="red-note">*</span></span>
            <span className="unit-billion">{disLabel.BILIION}</span>
            <Input
              value={this.state.estatesPriceRq}
              type="text"
              id="price"
              maxLength="15"
              placeholder="Nhập mức giá"
              onChange={(InputedTextarea) => this.HandleTextareaChange(InputedTextarea, "estates-price-input")}
            />
            <span className="unit-billion-totext">{this.state.unitPriceToText}</span>
            <span className="text-danger">{this.state.errors["estatesPriceRq"]}</span>
          </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.UNIT}<span className="red-note">*</span></span>
            <Select
              name="estates-select_price-for-unit"
              options={this.state.unitVND}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn mức giá"
              onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select_price-for-unit")}
            />
            <span className="text-danger">{this.state.errors["estatesPriceForUnitRq"]}</span>
          </div>

          <div className="form-group col-sm-4">
            <span>{disLabel.DIRECTION_HOUSE}<span className="red-note">*</span></span>
            <Select
              name="estates-select_direction"
              options={this.state.directionHome}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn hướng nhà"
              onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select_direction")}
            />
            <span className="text-danger">{this.state.errors["estatesDirectionRq"]}</span>
          </div>
        </div>
       
        <div className="citys-column">
          <div className="form-group col-sm-4">
            <span>{disLabel.NUMBER_BEDROOMS}</span>
            <Select
              name="estates-select_bed-rooms"
              options={this.state.bedRooms}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn số phòng ngủ"
              onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select_bed-rooms")}
            />
          </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.NUMBER_LIVINGROOMS}</span>
            <Select
              name="estates-select_guest-rooms"
              options={this.state.livingRooms}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn số phòng khách"
              onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select_guest-rooms")}
            />
          </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.NUMBER_BATHROOMS}</span>
            <Select
              name="estates-select_bathandtoilet-rooms"
              options={this.state.toiletRooms}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn số phòng vệ sinh"
              onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "estates-select_bathandtoilet-rooms")}
            />
          </div>
        </div>
        <div className="section_h2-header_block2"><h5 className="title_display_default">{disLabel.CONTRACT_INFO}</h5></div>
        <div className="citys-column">
          <div className="form-group col-sm-4">
            <span>{disLabel.CONTACT_NAME}<span className="red-note">*</span></span>
            <Input
              value={this.state.contactNameRq} 
              type="text"
              id="contactname"
              maxLength="20"
              placeholder="Nhập tên liên hệ"
              onChange={(InputedTextarea) => this.HandleTextareaChange(InputedTextarea, "contactname")}
            />
            <span className="text-danger">{this.state.errors["contactNameRq"]}</span>
          </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.CONTACT_NUMBERPHONE}<span className="red-note">*</span></span>
            <Input
              value={this.state.contactPhoneRq} 
              type="number"
              id="contactphone"
              maxLength="12"
              placeholder="Nhập số điện thoại"
              onChange={(InputedTextarea) => this.HandleTextareaChange(InputedTextarea, "contactphone")}
            />
            <span className="text-danger">{this.state.errors["contactPhoneRq"]}</span>
          </div>
          <div className="form-group col-sm-4">
            <span>{disLabel.TRANSACTION_TYPE}<span className="red-note">*</span></span>
            <Select
              name="transaction-type"
              options={this.state.transactionType}
              isSearchable={true}
              maxMenuHeight="300px"
              placeholder="Chọn hình thức giao dịch"
              onChange={(selectedOption) => this.OnchangeSelect(selectedOption, "transaction-type")}
            />
            <span className="text-danger">{this.state.errors["transactionTypeRq"]}</span>
          </div>
        </div>
        
        <div className="section_h2-header_block2"><h5 className="title_display_default">{disLabel.ESTATE_IMAGE_UPLOAD}</h5></div>
        <div>
          <Upload onFilesChange={this.handleFilesChange}/>
        </div>
        <div className="recaptcha-container">
          <ReCAPTCHA
            className="recaptcha-small"
            ref={(ref) => this.recaptcha = ref}
            sitekey={recaptcha_key}
            onChange={this.handleRecaptchaChange}
            lang={lang}
          />
        </div>
          <div>{this.state.recaptchaError && (
            <div className="im-not-robot">
              {this.state.recaptchaError}
            </div>
          )}</div>
        <div className="left-align">
          <Button color="btn btn-secondary" onClick={this.handleReset}>{disLabel.DELETE_BTN}</Button>
          <Button color="btn btn-info">{disLabel.PUBLISH_BTN}</Button>
        </div>
        <ErrorModal isOpen={this.state.errorModalOpen} message={this.state.errorMessage} onClose={this.closeErrorModal} />
        <SuccessModal isOpen={this.state.successModalOpen} message={this.state.successMessage} onClose={this.closeSuccessModal} />
      </Form>
    </div>
    )

  }
}
const RadioButton = ({ name, id, value, onChange, checked, text }) => {
  return (
    <label htmlFor={id} className="radio-label">
      <input
        className="radio-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" />
      {text}
    </label>
  )
}

export default Publish;