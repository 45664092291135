// TP. Hồ Chí Minh
export const hochiminh_province = [
    { value: "1", label: "Quận 1" },
    { value: "2", label: "Quận 12" },
    { value: "3", label: "Quận Thủ Đức" },
    { value: "4", label: "Quận 9" },
    { value: "5", label: "Quận Gò Vấp" },
    { value: "6", label: "Quận Bình Thạnh" },
    { value: "7", label: "Quận Tân Bình" },
    { value: "8", label: "Quận Tân Phú" },
    { value: "9", label: "Quận Phú Nhuận" },
    { value: "10", label: "Quận 2" },
    { value: "11", label: "Quận 3" },
    { value: "12", label: "Quận 10" },
    { value: "13", label: "Quận 11" },
    { value: "14", label: "Quận 4" },
    { value: "15", label: "Quận 5" },
    { value: "16", label: "Quận 6" },
    { value: "17", label: "Quận 8" },
    { value: "18", label: "Quận Bình Tân" },
    { value: "19", label: "Quận 7" },
    { value: "20", label: "Huyện Củ Chi" },
    { value: "21", label: "Huyện Hóc Môn" },
    { value: "22", label: "Huyện Bình Chánh" },
    { value: "23", label: "Huyện Nhà Bè" },
    { value: "24", label: "Huyện Cần Giờ" },
];

// Hà Nội
export const hanoi_province = [
    { value: "1", label: "Quận Ba Đình" },
    { value: "2", label: "Quận Hoàn Kiếm" },
    { value: "3", label: "Quận Tây Hồ" },
    { value: "4", label: "Quận Long Biên" },
    { value: "5", label: "Quận Cầu Giấy" },
    { value: "6", label: "Quận Đống Đa" },
    { value: "7", label: "Quận Hai Bà Trưng" },
    { value: "8", label: "Quận Hoàng Mai" },
    { value: "9", label: "Quận Thanh Xuân" },
    { value: "10", label: "Huyện Sóc Sơn" },
    { value: "11", label: "Huyện Đông Anh" },
    { value: "12", label: "Huyện Gia Lâm" },
    { value: "13", label: "Quận Nam Từ Liêm" },
    { value: "14", label: "Huyện Thanh Trì" },
    { value: "15", label: "Quận Bắc Từ Liêm" },
    { value: "16", label: "Huyện Mê Linh" },
    { value: "17", label: "Quận Hà Đông" },
    { value: "18", label: "Thị xã Sơn Tây" },
    { value: "19", label: "Huyện Ba Vì" },
    { value: "20", label: "Huyện Phúc Thọ" },
    { value: "21", label: "Huyện Đan Phượng" },
    { value: "22", label: "Huyện Hoài Đức" },
    { value: "23", label: "Huyện Quốc Oai" },
    { value: "24", label: "Huyện Thạch Thất" },
    { value: "25", label: "Huyện Chương Mỹ" },
    { value: "26", label: "Huyện Thanh Oai" },
    { value: "27", label: "Huyện Thường Tín" },
    { value: "28", label: "Huyện Phú Xuyên" },
    { value: "29", label: "Huyện Ứng Hòa" },
    { value: "30", label: "Huyện Mỹ Đức" },
];

// Đà Nẵng
export const danang_province = [
    { value: "1", label: "Quận Liên Chiểu" },
    { value: "2", label: "Quận Thanh Khê" },
    { value: "3", label: "Quận Hải Châu" },
    { value: "4", label: "Quận Sơn Trà" },
    { value: "5", label: "Quận Ngũ Hành Sơn" },
    { value: "6", label: "Quận Cẩm Lệ" },
    { value: "7", label: "Huyện Hòa Vang" },
];

// Hà Giang
export const hagiang_province = [
    { value: "1", label: "Thành phố Hà Giang" },
    { value: "2", label: "Huyện Đồng Văn" },
    { value: "3", label: "Huyện Mèo Vạc" },
    { value: "4", label: "Huyện Yên Minh" },
    { value: "5", label: "Huyện Quản Bạ" },
    { value: "6", label: "Huyện Vị Xuyên" },
    { value: "7", label: "Huyện Bắc Mê" },
    { value: "8", label: "Huyện Hoàng Su Phì" },
    { value: "9", label: "Huyện Xín Mần" },
    { value: "10", label: "Huyện Bắc Quang" },
    { value: "11", label: "Huyện Quang Bình" },
];

// Cao Bằng
export const caobang_province = [
    { value: "1", label: "Thành phố Cao Bằng" },
    { value: "2", label: "Huyện Bảo Lâm" },
    { value: "3", label: "Huyện Bảo Lạc" },
    { value: "4", label: "Huyện Thông Nông" },
    { value: "5", label: "Huyện Hà Quảng" },
    { value: "6", label: "Huyện Trà Lĩnh" },
    { value: "7", label: "Huyện Trùng Khánh" },
    { value: "8", label: "Huyện Hạ Lang" },
    { value: "9", label: "Huyện Quảng Uyên" },
    { value: "10", label: "Huyện Phục Hoà" },
    { value: "11", label: "Huyện Hoà An" },
    { value: "12", label: "Huyện Nguyên Bình" },
    { value: "13", label: "Huyện Thạch An" },
];

// Bắc Kạn
export const baccan_province = [
    { value: "1", label: "Thành Phố Bắc Kạn" },
    { value: "2", label: "Huyện Pác Nặm" },
    { value: "3", label: "Huyện Ba Bể" },
    { value: "4", label: "Huyện Ngân Sơn" },
    { value: "5", label: "Huyện Bạch Thông" },
    { value: "6", label: "Huyện Chợ Đồn" },
    { value: "7", label: "Huyện Chợ Mới" },
    { value: "8", label: "Huyện Na Rì" },
];

// Tuyên Quang
export const tuyenquang_province = [
    { value: "1", label: "Thành Phố Tuyên Quang" },
    { value: "2", label: "Huyện Yên Sơn" },
    { value: "3", label: "Huyện Sơn Dương" },
    { value: "4", label: "Huyện Lâm Bình" },
    { value: "5", label: "Huyện Na Hang" },
    { value: "6", label: "Huyện Chiêm Hóa" },
    { value: "7", label: "Huyện Hàm Yên" },
];

// Lào Cai
export const laocai_province = [
    { value: "1", label: "Thành phố Lào Cai" },
    { value: "2", label: "Huyện Bát Xát" },
    { value: "3", label: "Huyện Mường Khương" },
    { value: "4", label: "Huyện Si Ma Cai" },
    { value: "5", label: "Huyện Bắc Hà" },
    { value: "6", label: "Huyện Bảo Thắng" },
    { value: "7", label: "Huyện Bảo Yên" },
    { value: "8", label: "Huyện Sa Pa" },
    { value: "9", label: "Huyện Văn Bàn" },
];

// Điện Biên
export const dienbien_province = [
    { value: "1", label: "Thành phố Điện Biên Phủ" },
    { value: "2", label: "Thị Xã Mường Lay" },
    { value: "3", label: "Huyện Mường Nhé" },
    { value: "4", label: "Huyện Mường Chà" },
    { value: "5", label: "Huyện Tủa Chùa" },
    { value: "6", label: "Huyện Tuần Giáo" },
    { value: "7", label: "Huyện Điện Biên" },
    { value: "8", label: "Huyện Điện Biên Đông" },
    { value: "9", label: "Huyện Mường Ảng" },
    { value: "10", label: "Huyện Nậm Pồ" },
];

// Lai Châu
export const laichau_province = [
    { value: "1", label: "Thành phố Lai Châu" },
    { value: "2", label: "Huyện Tam Đường" },
    { value: "3", label: "Huyện Mường Tè" },
    { value: "4", label: "Huyện Sìn Hồ" },
    { value: "5", label: "Huyện Phong Thổ" },
    { value: "6", label: "Huyện Than Uyên" },
    { value: "7", label: "Huyện Tân Uyên" },
    { value: "8", label: "Huyện Nậm Nhùn" },
];

// Sơn La
export const sonla_province = [
    { value: "1", label: "Thành phố Sơn La" },
    { value: "2", label: "Huyện Quỳnh Nhai" },
    { value: "3", label: "Huyện Thuận Châu" },
    { value: "4", label: "Huyện Mường La" },
    { value: "5", label: "Huyện Bắc Yên" },
    { value: "6", label: "Huyện Phù Yên" },
    { value: "7", label: "Huyện Mộc Châu" },
    { value: "8", label: "Huyện Yên Châu" },
    { value: "9", label: "Huyện Mai Sơn" },
    { value: "10", label: "Huyện Sông Mã" },
    { value: "11", label: "Huyện Sốp Cộp" },
    { value: "12", label: "Huyện Vân Hồ" },
];

// yên bái
export const yenbai_province = [
    { value: "1", label: "Thành phố Yên Bái" },
    { value: "2", label: "Thị xã Nghĩa Lộ" },
    { value: "3", label: "Huyện Lục Yên" },
    { value: "4", label: "Huyện Văn Yên" },
    { value: "5", label: "Huyện Mù Căng Chải" },
    { value: "6", label: "Huyện Trấn Yên" },
    { value: "7", label: "Huyện Trạm Tấu" },
    { value: "8", label: "Huyện Văn Chấn" },
    { value: "9", label: "Huyện Yên Bình" },
];

// Hòa Bình
export const hoabinh_province = [
    { value: "1", label: "Thành phố Hòa Bình" },
    { value: "2", label: "Huyện Đà Bắc" },
    { value: "3", label: "Huyện Kỳ Sơn" },
    { value: "4", label: "Huyện Lương Sơn" },
    { value: "5", label: "Huyện Kim Bôi" },
    { value: "6", label: "Huyện Cao Phong" },
    { value: "7", label: "Huyện Tân Lạc" },
    { value: "8", label: "Huyện Mai Châu" },
    { value: "9", label: "Huyện Lạc Sơn" },
    { value: "10", label: "Huyện Yên Thủy" },
    { value: "11", label: "Huyện Lạc Thủy" },
];

// Thái Nguyên
export const thainguyen_province = [
    { value: "1", label: "Thành phố Thái Nguyên" },
    { value: "2", label: "Thành phố Sông Công" },
    { value: "3", label: "Huyện Định Hóa" },
    { value: "4", label: "Huyện Phú Lương" },
    { value: "5", label: "Huyện Đồng Hỷ" },
    { value: "6", label: "Huyện Võ Nhai" },
    { value: "7", label: "Huyện Đại Từ" },
    { value: "8", label: "Thị xã Phổ Yên" },
    { value: "9", label: "Huyện Phú Bình" },
];

// Lạng Sơn
export const langson_province = [
    { value: "1", label: "Thành phố Lạng Sơn" },
    { value: "2", label: "Huyện Tràng Định" },
    { value: "3", label: "Huyện Bình Gia" },
    { value: "4", label: "Huyện Văn Lãng" },
    { value: "5", label: "Huyện Cao Lộc" },
    { value: "6", label: "Huyện Văn Quan" },
    { value: "7", label: "Huyện Bắc Sơn" },
    { value: "8", label: "Huyện Hữu Lũng" },
    { value: "9", label: "Huyện Chi Lăng" },
    { value: "10", label: "Huyện Lộc Bình" },
    { value: "11", label: "Huyện Đình Lập" },
];

// Quảng Ninh
export const quangninh_province = [
    { value: "1", label: "Thành phố Hạ Long" },
    { value: "2", label: "Thành phố Móng Cái" },
    { value: "3", label: "Thành phố Cẩm Phả" },
    { value: "4", label: "Thành phố Uông Bí" },
    { value: "5", label: "Huyện Bình Liêu" },
    { value: "6", label: "Huyện Tiên Yên" },
    { value: "7", label: "Huyện Đầm Hà" },
    { value: "8", label: "Huyện Hải Hà" },
    { value: "9", label: "Huyện Ba Chẽ" },
    { value: "10", label: "Huyện Vân Đồn" },
    { value: "11", label: "Huyện Hoành Bồ" },
    { value: "12", label: "Thị xã Đông Triều" },
    { value: "13", label: "Thị xã Quảng Yên" },
    { value: "14", label: "Huyện Cô Tô" },
];

// Bắc Giang
export const bacgiang_province = [
    { value: "1", label: "Thành phố Bắc Giang" },
    { value: "2", label: "Huyện Yên Thế" },
    { value: "3", label: "Huyện Tân Yên" },
    { value: "4", label: "Huyện Lạng Giang" },
    { value: "5", label: "Huyện Lục Nam" },
    { value: "6", label: "Huyện Lục Ngạn" },
    { value: "7", label: "Huyện Sơn Động" },
    { value: "8", label: "Huyện Yên Dũng" },
    { value: "9", label: "Huyện Việt Yên" },
    { value: "10", label: "Huyện Hiệp Hòa" },
];

// Phú Thọ
export const phutho_province = [
    { value: "1", label: "Thành phố Việt Trì" },
    { value: "2", label: "Thị xã Phú Thọ" },
    { value: "3", label: "Huyện Đoan Hùng" },
    { value: "4", label: "Huyện Hạ Hoà" },
    { value: "5", label: "Huyện Thanh Ba" },
    { value: "6", label: "Huyện Phù Ninh" },
    { value: "7", label: "Huyện Yên Lập" },
    { value: "8", label: "Huyện Cẩm Khê" },
    { value: "9", label: "Huyện Tam Nông" },
    { value: "10", label: "Huyện Lâm Thao" },
    { value: "11", label: "Huyện Thanh Sơn" },
    { value: "12", label: "Huyện Thanh Thuỷ" },
    { value: "13", label: "Huyện Tân Sơn" },
];

// Vĩnh Phúc
export const vinhphuc_province = [
    { value: "1", label: "Thành phố Vĩnh Yên" },
    { value: "2", label: "Thị xã Phúc Yên" },
    { value: "3", label: "Huyện Lập Thạch" },
    { value: "4", label: "Huyện Tam Dương" },
    { value: "5", label: "Huyện Tam Đảo" },
    { value: "6", label: "Huyện Bình Xuyên" },
    { value: "7", label: "Huyện Yên Lạc" },
    { value: "8", label: "Huyện Vĩnh Tường" },
    { value: "9", label: "Huyện Sông Lô" },
];

// Bắc Ninh
export const bacninh_province = [
    { value: "1", label: "Thành phố Bắc Ninh" },
    { value: "2", label: "Huyện Yên Phong" },
    { value: "3", label: "Huyện Quế Võ" },
    { value: "4", label: "Huyện Tiên Du" },
    { value: "5", label: "Thị xã Từ Sơn" },
    { value: "6", label: "Huyện Thuận Thành" },
    { value: "7", label: "Huyện Gia Bình" },
    { value: "8", label: "Huyện Lương Tài" },
];

// Hải Dương
export const haiduong_province = [
    { value: "1", label: "Thành phố Hải Dương" },
    { value: "2", label: "Thị xã Chí Linh" },
    { value: "3", label: "Huyện Nam Sách" },
    { value: "4", label: "Huyện Kinh Môn" },
    { value: "5", label: "Huyện Kim Thành" },
    { value: "6", label: "Huyện Thanh Hà" },
    { value: "7", label: "Huyện Cẩm Giàng" },
    { value: "8", label: "Huyện Bình Giang" },
    { value: "9", label: "Huyện Gia Lộc" },
    { value: "10", label: "Huyện Tứ Kỳ" },
    { value: "11", label: "Huyện Ninh Giang" },
    { value: "12", label: "Huyện Thanh Miện" },
];

// Hải Phòng
export const haiphong_city = [
    { value: "1", label: "Quận Hồng Bàng" },
    { value: "2", label: "Quận Ngô Quyền" },
    { value: "3", label: "Quận Lê Chân" },
    { value: "4", label: "Quận Hải An" },
    { value: "5", label: "Quận Kiến An" },
    { value: "6", label: "Quận Đồ Sơn" },
    { value: "7", label: "Quận Dương Kinh" },
    { value: "8", label: "Huyện Thuỷ Nguyên" },
    { value: "9", label: "Huyện An Dương" },
    { value: "10", label: "Huyện An Lão" },
    { value: "11", label: "Huyện Kiến Thuỵ" },
    { value: "12", label: "Huyện Tiên Lãng" },
    { value: "13", label: "Huyện Vĩnh Bảo" },
    { value: "14", label: "Huyện Cát Hải" },
];

// Hưng Yên
export const hungyen_province = [
    { value: "1", label: "Thành phố Hưng Yên" },
    { value: "2", label: "Huyện Văn Lâm" },
    { value: "3", label: "Huyện Văn Giang" },
    { value: "4", label: "Huyện Yên Mỹ" },
    { value: "5", label: "Huyện Mỹ Hào" },
    { value: "6", label: "Huyện Ân Thi" },
    { value: "7", label: "Huyện Khoái Châu" },
    { value: "8", label: "Huyện Kim Động" },
    { value: "9", label: "Huyện Tiên Lữ" },
    { value: "10", label: "Huyện Phù Cừ" },
];
// Thái Bình
export const thaibinh_province = [
    { value: "1", label: "Thành phố Thái Bình" },
    { value: "2", label: "Huyện Quỳnh Phụ" },
    { value: "3", label: "Huyện Hưng Hà" },
    { value: "4", label: "Huyện Đông Hưng" },
    { value: "5", label: "Huyện Thái Thụy" },
    { value: "6", label: "Huyện Tiền Hải" },
    { value: "7", label: "Huyện Kiến Xương" },
    { value: "8", label: "Huyện Vũ Thư" },
];

// Hà Nam
export const hanam_province = [
    { value: "1", label: "Thành phố Phủ Lý" },
    { value: "2", label: "Huyện Duy Tiên" },
    { value: "3", label: "Huyện Kim Bảng" },
    { value: "4", label: "Huyện Thanh Liêm" },
    { value: "5", label: "Huyện Bình Lục" },
    { value: "6", label: "Huyện Lý Nhân" },
];

// Nam Định
export const namdinh_province = [
    { value: "1", label: "Thành phố Nam Định" },
    { value: "2", label: "Huyện Mỹ Lộc" },
    { value: "3", label: "Huyện Vụ Bản" },
    { value: "4", label: "Huyện Ý Yên" },
    { value: "5", label: "Huyện Nghĩa Hưng" },
    { value: "6", label: "Huyện Nam Trực" },
    { value: "7", label: "Huyện Trực Ninh" },
    { value: "8", label: "Huyện Xuân Trường" },
    { value: "9", label: "Huyện Giao Thủy" },
    { value: "10", label: "Huyện Hải Hậu" },
];

// Ninh Bình
export const ninhbinh_province = [
    { value: "1", label: "Thành phố Ninh Bình" },
    { value: "2", label: "Thành phố Tam Điệp" },
    { value: "3", label: "Huyện Nho Quan" },
    { value: "4", label: "Huyện Gia Viễn" },
    { value: "5", label: "Huyện Hoa Lư" },
    { value: "6", label: "Huyện Yên Khánh" },
    { value: "7", label: "Huyện Kim Sơn" },
    { value: "8", label: "Huyện Yên Mô" },
];

// Thanh Hóa
export const thanhhoa_province = [
    { value: "1", label: "Thành phố Thanh Hóa" },
    { value: "2", label: "Thị xã Bỉm Sơn" },
    { value: "3", label: "Thị xã Sầm Sơn" },
    { value: "4", label: "Huyện Mường Lát" },
    { value: "5", label: "Huyện Quan Hóa" },
    { value: "6", label: "Huyện Bá Thước" },
    { value: "7", label: "Huyện Quan Sơn" },
    { value: "8", label: "Huyện Lang Chánh" },
    { value: "9", label: "Huyện Ngọc Lặc" },
    { value: "10", label: "Huyện Cẩm Thủy" },
    { value: "11", label: "Huyện Thạch Thành" },
    { value: "12", label: "Huyện Hà Trung" },
    { value: "13", label: "Huyện Vĩnh Lộc" },
    { value: "14", label: "Huyện Yên Định" },
    { value: "15", label: "Huyện Thọ Xuân" },
    { value: "16", label: "Huyện Thường Xuân" },
    { value: "17", label: "Huyện Triệu Sơn" },
    { value: "18", label: "Huyện Thiệu Hóa" },
    { value: "19", label: "Huyện Hoằng Hóa" },
    { value: "20", label: "Huyện Hậu Lộc" },
    { value: "21", label: "Huyện Nga Sơn" },
    { value: "22", label: "Huyện Như Xuân" },
    { value: "23", label: "Huyện Như Thanh" },
    { value: "24", label: "Huyện Nông Cống" },
    { value: "25", label: "Huyện Đông Sơn" },
    { value: "26", label: "Huyện Quảng Xương" },
    { value: "27", label: "Huyện Tĩnh Gia" },
];

// Nghệ An
export const nghean_province = [
    { value: "1", label: "Thành phố Vinh" },
    { value: "2", label: "Thị xã Cửa Lò" },
    { value: "3", label: "Thị xã Thái Hoà" },
    { value: "4", label: "Huyện Quế Phong" },
    { value: "5", label: "Huyện Quỳ Châu" },
    { value: "6", label: "Huyện Kỳ Sơn" },
    { value: "7", label: "Huyện Tương Dương" },
    { value: "8", label: "Huyện Nghĩa Đàn" },
    { value: "9", label: "Huyện Quỳ Hợp" },
    { value: "10", label: "Huyện Quỳnh Lưu" },
    { value: "11", label: "Huyện Con Cuông" },
    { value: "12", label: "Huyện Tân Kỳ" },
    { value: "13", label: "Huyện Anh Sơn" },
    { value: "14", label: "Huyện Diễn Châu" },
    { value: "15", label: "Huyện Yên Thành" },
    { value: "16", label: "Huyện Đô Lương" },
    { value: "17", label: "Huyện Thanh Chương" },
    { value: "18", label: "Huyện Nghi Lộc" },
    { value: "19", label: "Huyện Nam Đàn" },
    { value: "20", label: "Huyện Hưng Nguyên" },
    { value: "21", label: "Thị xã Hoàng Mai" },
];

// Hà Tĩnh
export const hatinh_province = [
    { value: "1", label: "Thành phố Hà Tĩnh" },
    { value: "2", label: "Thị xã Hồng Lĩnh" },
    { value: "3", label: "Huyện Hương Sơn" },
    { value: "4", label: "Huyện Đức Thọ" },
    { value: "5", label: "Huyện Vũ Quang" },
    { value: "6", label: "Huyện Nghi Xuân" },
    { value: "7", label: "Huyện Can Lộc" },
    { value: "8", label: "Huyện Hương Khê" },
    { value: "9", label: "Huyện Thạch Hà" },
    { value: "10", label: "Huyện Cẩm Xuyên" },
    { value: "11", label: "Huyện Kỳ Anh" },
    { value: "12", label: "Huyện Lộc Hà" },
    { value: "13", label: "Thị xã Kỳ Anh" },
];

// Quảng Bình
export const quangbinh_province = [
    { value: "1", label: "Thành Phố Đồng Hới" },
    { value: "2", label: "Huyện Minh Hóa" },
    { value: "3", label: "Huyện Tuyên Hóa" },
    { value: "4", label: "Huyện Quảng Trạch" },
    { value: "5", label: "Huyện Bố Trạch" },
    { value: "6", label: "Huyện Quảng Ninh" },
    { value: "7", label: "Huyện Lệ Thủy" },
    { value: "8", label: "Thị xã Ba Đồn" },
];

// Quảng Trị
export const quangtri_province = [
    { value: "1", label: "Thành phố Đông Hà" },
    { value: "2", label: "Thị xã Quảng Trị" },
    { value: "3", label: "Huyện Vĩnh Linh" },
    { value: "4", label: "Huyện Hướng Hóa" },
    { value: "5", label: "Huyện Gio Linh" },
    { value: "6", label: "Huyện Đa Krông" },
    { value: "7", label: "Huyện Cam Lộ" },
    { value: "8", label: "Huyện Triệu Phong" },
    { value: "9", label: "Huyện Hải Lăng" },
];

// Thừa Thiên Huế
export const thua_thien_hue_province = [
    { value: "1", label: "Thành phố Huế" },
    { value: "2", label: "Huyện Phong Điền" },
    { value: "3", label: "Huyện Quảng Điền" },
    { value: "4", label: "Huyện Phú Vang" },
    { value: "5", label: "Thị xã Hương Thủy" },
    { value: "6", label: "Thị xã Hương Trà" },
    { value: "7", label: "Huyện A Lưới" },
    { value: "8", label: "Huyện Phú Lộc" },
    { value: "9", label: "Huyện Nam Đông" },
];

// Quảng Nam
export const quangnam_province = [
    { value: "1", label: "Thành phố Tam Kỳ" },
    { value: "2", label: "Thành phố Hội An" },
    { value: "3", label: "Huyện Tây Giang" },
    { value: "4", label: "Huyện Đông Giang" },
    { value: "5", label: "Huyện Đại Lộc" },
    { value: "6", label: "Thị xã Điện Bàn" },
    { value: "7", label: "Huyện Duy Xuyên" },
    { value: "8", label: "Huyện Quế Sơn" },
    { value: "9", label: "Huyện Nam Giang" },
    { value: "10", label: "Huyện Phước Sơn" },
    { value: "11", label: "Huyện Hiệp Đức" },
    { value: "12", label: "Huyện Thăng Bình" },
    { value: "13", label: "Huyện Tiên Phước" },
    { value: "14", label: "Huyện Bắc Trà My" },
    { value: "15", label: "Huyện Nam Trà My" },
    { value: "16", label: "Huyện Núi Thành" },
    { value: "17", label: "Huyện Phú Ninh" },
    { value: "18", label: "Huyện Nông Sơn" },
];

// Quảng Ngãi
export const quangngai_province = [
    { value: "1", label: "Thành phố Quảng Ngãi" },
    { value: "2", label: "Huyện Bình Sơn" },
    { value: "3", label: "Huyện Trà Bồng" },
    { value: "4", label: "Huyện Tây Trà" },
    { value: "5", label: "Huyện Sơn Tịnh" },
    { value: "6", label: "Huyện Tư Nghĩa" },
    { value: "7", label: "Huyện Sơn Hà" },
    { value: "8", label: "Huyện Sơn Tây" },
    { value: "9", label: "Huyện Minh Long" },
    { value: "10", label: "Huyện Nghĩa Hành" },
    { value: "11", label: "Huyện Mộ Đức" },
    { value: "12", label: "Huyện Đức Phổ" },
    { value: "13", label: "Huyện Ba Tơ" },
    { value: "14", label: "Huyện Lý Sơn" },
];

// Bình Định
export const binhdinh_province = [
    { value: "1", label: "Thành phố Qui Nhơn" },
    { value: "2", label: "Huyện An Lão" },
    { value: "3", label: "Huyện Hoài Nhơn" },
    { value: "4", label: "Huyện Hoài Ân" },
    { value: "5", label: "Huyện Phù Mỹ" },
    { value: "6", label: "Huyện Vĩnh Thạnh" },
    { value: "7", label: "Huyện Tây Sơn" },
    { value: "8", label: "Huyện Phù Cát" },
    { value: "9", label: "Thị xã An Nhơn" },
    { value: "10", label: "Huyện Tuy Phước" },
    { value: "11", label: "Huyện Vân Canh" },
];

// Phú Yên
export const phuyen_province = [
    { value: "1", label: "Thành phố Tuy Hoà" },
    { value: "2", label: "Thị xã Sông Cầu" },
    { value: "3", label: "Huyện Đồng Xuân" },
    { value: "4", label: "Huyện Tuy An" },
    { value: "5", label: "Huyện Sơn Hòa" },
    { value: "6", label: "Huyện Sông Hinh" },
    { value: "7", label: "Huyện Tây Hoà" },
    { value: "8", label: "Huyện Phú Hoà" },
    { value: "9", label: "Huyện Đông Hòa" },
];

// Khánh Hòa
export const khanhhoa_province = [
    { value: "1", label: "Thành phố Nha Trang" },
    { value: "2", label: "Thành phố Cam Ranh" },
    { value: "3", label: "Huyện Cam Lâm" },
    { value: "4", label: "Huyện Vạn Ninh" },
    { value: "5", label: "Thị xã Ninh Hòa" },
    { value: "6", label: "Huyện Khánh Vĩnh" },
    { value: "7", label: "Huyện Diên Khánh" },
    { value: "8", label: "Huyện Khánh Sơn" },
    { value: "9", label: "Huyện Trường Sa" },
];

// Ninh Thuận
export const ninhthuan_province = [
    { value: "1", label: "Thành phố Phan Rang-Tháp Chàm" },
    { value: "2", label: "Huyện Bác Ái" },
    { value: "3", label: "Huyện Ninh Sơn" },
    { value: "4", label: "Huyện Ninh Hải" },
    { value: "5", label: "Huyện Ninh Phước" },
    { value: "6", label: "Huyện Thuận Bắc" },
    { value: "7", label: "Huyện Thuận Nam" },
];

// Bình Thuận
export const binhthuan_province = [
    { value: "1", label: "Thành phố Phan Thiết" },
    { value: "2", label: "Thị xã La Gi" },
    { value: "3", label: "Huyện Tuy Phong" },
    { value: "4", label: "Huyện Bắc Bình" },
    { value: "5", label: "Huyện Hàm Thuận Bắc" },
    { value: "6", label: "Huyện Hàm Thuận Nam" },
    { value: "7", label: "Huyện Tánh Linh" },
    { value: "8", label: "Huyện Đức Linh" },
    { value: "9", label: "Huyện Hàm Tân" },
    { value: "10", label: "Huyện Phú Quí" },
];


// Bình Phước
export const binhphuoc_province = [
    { value: "1", label: "Thị xã Phước Long" },
    { value: "2", label: "Thị xã Đồng Xoài" },
    { value: "3", label: "Thị xã Bình Long" },
    { value: "4", label: "Huyện Bù Gia Mập" },
    { value: "5", label: "Huyện Lộc Ninh" },
    { value: "6", label: "Huyện Bù Đốp" },
    { value: "7", label: "Huyện Hớn Quản" },
    { value: "8", label: "Huyện Đồng Phú" },
    { value: "9", label: "Huyện Bù Đăng" },
    { value: "10", label: "Huyện Chơn Thành" },
    { value: "11", label: "Huyện Phú Riềng" },
];


// Tây Ninh
export const tayninh_province = [
    { value: "1", label: "Thành phố Tây Ninh" },
    { value: "2", label: "Huyện Tân Biên" },
    { value: "3", label: "Huyện Tân Châu" },
    { value: "4", label: "Huyện Dương Minh Châu" },
    { value: "5", label: "Huyện Châu Thành" },
    { value: "6", label: "Huyện Hòa Thành" },
    { value: "7", label: "Huyện Gò Dầu" },
    { value: "8", label: "Huyện Bến Cầu" },
    { value: "9", label: "Huyện Trảng Bàng" },
];


// Bình Dương
export const binhduong_province = [
    { value: "1", label: "Thành phố Thủ Dầu Một" },
    { value: "2", label: "Huyện Bàu Bàng" },
    { value: "3", label: "Huyện Dầu Tiếng" },
    { value: "4", label: "Thị xã Bến Cát" },
    { value: "5", label: "Huyện Phú Giáo" },
    { value: "6", label: "Thị xã Tân Uyên" },
    { value: "7", label: "Thị xã Dĩ An" },
    { value: "8", label: "Thị xã Thuận An" },
    { value: "9", label: "Huyện Bắc Tân Uyên" },
];


// Đồng Nai
export const dongnai_province = [
    { value: "1", label: "Thành phố Biên Hòa" },
    { value: "2", label: "Thị xã Long Khánh" },
    { value: "3", label: "Huyện Tân Phú" },
    { value: "4", label: "Huyện Vĩnh Cửu" },
    { value: "5", label: "Huyện Định Quán" },
    { value: "6", label: "Huyện Trảng Bom" },
    { value: "7", label: "Huyện Thống Nhất" },
    { value: "8", label: "Huyện Cẩm Mỹ" },
    { value: "9", label: "Huyện Long Thành" },
    { value: "10", label: "Huyện Xuân Lộc" },
    { value: "11", label: "Huyện Nhơn Trạch" },
];


// Bà Rịa - Vũng Tàu
export const bariavungtau_province = [
    { value: "1", label: "Thành phố Vũng Tàu" },
    { value: "2", label: "Thành phố Bà Rịa" },
    { value: "3", label: "Huyện Châu Đức" },
    { value: "4", label: "Huyện Xuyên Mộc" },
    { value: "5", label: "Huyện Long Điền" },
    { value: "6", label: "Huyện Đất Đỏ" },
    { value: "7", label: "Huyện Tân Thành" },
];

// Long An
export const longan_province = [
    { value: "1", label: "Thành phố Tân An" },
    { value: "2", label: "Thị xã Kiến Tường" },
    { value: "3", label: "Huyện Tân Hưng" },
    { value: "4", label: "Huyện Vĩnh Hưng" },
    { value: "5", label: "Huyện Mộc Hóa" },
    { value: "6", label: "Huyện Tân Thạnh" },
    { value: "7", label: "Huyện Thạnh Hóa" },
    { value: "8", label: "Huyện Đức Huệ" },
    { value: "9", label: "Huyện Đức Hòa" },
    { value: "10", label: "Huyện Bến Lức" },
    { value: "11", label: "Huyện Thủ Thừa" },
    { value: "12", label: "Huyện Tân Trụ" },
    { value: "13", label: "Huyện Cần Đước" },
    { value: "14", label: "Huyện Cần Giuộc" },
    { value: "15", label: "Huyện Châu Thành" },
];


// Tiền Giang
export const tiengiang_province = [
    { value: "1", label: "Thành phố Mỹ Tho" },
    { value: "2", label: "Thị xã Gò Công" },
    { value: "3", label: "Thị xã Cai Lậy" },
    { value: "4", label: "Huyện Tân Phước" },
    { value: "5", label: "Huyện Cái Bè" },
    { value: "6", label: "Huyện Cai Lậy" },
    { value: "7", label: "Huyện Châu Thành" },
    { value: "8", label: "Huyện Chợ Gạo" },
    { value: "9", label: "Huyện Gò Công Tây" },
    { value: "10", label: "Huyện Gò Công Đông" },
    { value: "11", label: "Huyện Tân Phú Đông" },
];


// Bến Tre
export const bentre_province = [
    { value: "1", label: "Thành phố Bến Tre" },
    { value: "2", label: "Huyện Châu Thành" },
    { value: "3", label: "Huyện Chợ Lách" },
    { value: "4", label: "Huyện Mỏ Cày Nam" },
    { value: "5", label: "Huyện Giồng Trôm" },
    { value: "6", label: "Huyện Bình Đại" },
    { value: "7", label: "Huyện Ba Tri" },
    { value: "8", label: "Huyện Thạnh Phú" },
    { value: "9", label: "Huyện Mỏ Cày Bắc" },
];

// Trà Vinh
export const travinh_province = [
    { value: "1", label: "Thành phố Trà Vinh" },
    { value: "2", label: "Huyện Càng Long" },
    { value: "3", label: "Huyện Cầu Kè" },
    { value: "4", label: "Huyện Tiểu Cần" },
    { value: "5", label: "Huyện Châu Thành" },
    { value: "6", label: "Huyện Cầu Ngang" },
    { value: "7", label: "Huyện Trà Cú" },
    { value: "8", label: "Huyện Duyên Hải" },
    { value: "9", label: "Thị xã Duyên Hải" },
];


// Vĩnh Long
export const vinhlong_province = [
    { value: "1", label: "Thành phố Vĩnh Long" },
    { value: "2", label: "Huyện Long Hồ" },
    { value: "3", label: "Huyện Mang Thít" },
    { value: "4", label: "Huyện Vũng Liêm" },
    { value: "5", label: "Huyện Tam Bình" },
    { value: "6", label: "Thị xã Bình Minh" },
    { value: "7", label: "Huyện Trà Ôn" },
    { value: "8", label: "Huyện Bình Tân" },
];


// Đồng Tháp
export const dongthap_province = [
    { value: "1", label: "Thành phố Cao Lãnh" },
    { value: "2", label: "Thành phố Sa Đéc" },
    { value: "3", label: "Thị xã Hồng Ngự" },
    { value: "4", label: "Huyện Tân Hồng" },
    { value: "5", label: "Huyện Hồng Ngự" },
    { value: "6", label: "Huyện Tam Nông" },
    { value: "7", label: "Huyện Tháp Mười" },
    { value: "8", label: "Huyện Cao Lãnh" },
    { value: "9", label: "Huyện Thanh Bình" },
    { value: "10", label: "Huyện Lấp Vò" },
    { value: "11", label: "Huyện Lai Vung" },
    { value: "12", label: "Huyện Châu Thành" },
];


// An Giang
export const angiang_province = [
    { value: "1", label: "Thành phố Long Xuyên" },
    { value: "2", label: "Thành phố Châu Đốc" },
    { value: "3", label: "Huyện An Phú" },
    { value: "4", label: "Thị xã Tân Châu" },
    { value: "5", label: "Huyện Phú Tân" },
    { value: "6", label: "Huyện Châu Phú" },
    { value: "7", label: "Huyện Tịnh Biên" },
    { value: "8", label: "Huyện Tri Tôn" },
    { value: "9", label: "Huyện Châu Thành" },
    { value: "10", label: "Huyện Chợ Mới" },
    { value: "11", label: "Huyện Thoại Sơn" },
];


// Kiên Giang
export const kiengiang_province = [
    { value: "1", label: "Thành phố Rạch Giá" },
    { value: "2", label: "Thị xã Hà Tiên" },
    { value: "3", label: "Huyện Kiên Lương" },
    { value: "4", label: "Huyện Hòn Đất" },
    { value: "5", label: "Huyện Tân Hiệp" },
    { value: "6", label: "Huyện Châu Thành" },
    { value: "7", label: "Huyện Giồng Riềng" },
    { value: "8", label: "Huyện Gò Quao" },
    { value: "9", label: "Huyện An Biên" },
    { value: "10", label: "Huyện An Minh" },
    { value: "11", label: "Huyện Vĩnh Thuận" },
    { value: "12", label: "Huyện Phú Quốc" },
    { value: "13", label: "Huyện Kiên Hải" },
    { value: "14", label: "Huyện U Minh Thượng" },
    { value: "15", label: "Huyện Giang Thành" },
];


// Cần Thơ
export const cantho_province = [
    { value: "1", label: "Quận Ninh Kiều" },
    { value: "2", label: "Quận Ô Môn" },
    { value: "3", label: "Quận Bình Thuỷ" },
    { value: "4", label: "Quận Cái Răng" },
    { value: "5", label: "Quận Thốt Nốt" },
    { value: "6", label: "Huyện Vĩnh Thạnh" },
    { value: "7", label: "Huyện Cờ Đỏ" },
    { value: "8", label: "Huyện Phong Điền" },
    { value: "9", label: "Huyện Thới Lai" },
];


// Hậu Giang
export const haugiang_province = [
    { value: "1", label: "Thành phố Vị Thanh" },
    { value: "2", label: "Thị xã Ngã Bảy" },
    { value: "3", label: "Huyện Châu Thành A" },
    { value: "4", label: "Huyện Châu Thành" },
    { value: "5", label: "Huyện Phụng Hiệp" },
    { value: "6", label: "Huyện Vị Thuỷ" },
    { value: "7", label: "Huyện Long Mỹ" },
    { value: "8", label: "Thị xã Long Mỹ" },
];

// Sóc Trăng
export const soctrang_province = [
    { value: "1", label: "Thành phố Sóc Trăng" },
    { value: "2", label: "Huyện Châu Thành" },
    { value: "3", label: "Huyện Kế Sách" },
    { value: "4", label: "Huyện Mỹ Tú" },
    { value: "5", label: "Huyện Cù Lao Dung" },
    { value: "6", label: "Huyện Long Phú" },
    { value: "7", label: "Huyện Mỹ Xuyên" },
    { value: "8", label: "Thị xã Ngã Năm" },
    { value: "9", label: "Huyện Thạnh Trị" },
    { value: "10", label: "Thị xã Vĩnh Châu" },
    { value: "11", label: "Huyện Trần Đề" },
];


// Bạc Liêu
export const baclieu_province = [
    { value: "1", label: "Thành phố Bạc Liêu" },
    { value: "2", label: "Huyện Hồng Dân" },
    { value: "3", label: "Huyện Phước Long" },
    { value: "4", label: "Huyện Vĩnh Lợi" },
    { value: "5", label: "Thị xã Giá Rai" },
    { value: "6", label: "Huyện Đông Hải" },
    { value: "7", label: "Huyện Hoà Bình" },
];


// Cà Mau
export const camau_province = [
    { value: "1", label: "Thành phố Cà Mau" },
    { value: "2", label: "Huyện U Minh" },
    { value: "3", label: "Huyện Thới Bình" },
    { value: "4", label: "Huyện Trần Văn Thời" },
    { value: "5", label: "Huyện Cái Nước" },
    { value: "6", label: "Huyện Đầm Dơi" },
    { value: "7", label: "Huyện Năm Căn" },
    { value: "8", label: "Huyện Phú Tân" },
    { value: "9", label: "Huyện Ngọc Hiển" },
];


// Tỉnh thành Đắk Lak
export const daklak_province = [
    { value: "1", label: "Thành phố Buôn Ma Thuột" },
    { value: "2", label: "Thị xã Buôn Hồ" },
    { value: "3", label: "Huyện Buôn Đôn" },
    { value: "4", label: "Huyện Cư Kuin" },
    { value: "5", label: "Huyện Cư M’gar" },
    { value: "6", label: "Huyện Ea H’leo" },
    { value: "7", label: "Huyện Ea Kar" },
    { value: "8", label: "Huyện Ea Súp" },
    { value: "9", label: "Huyện Krông Ana" },
    { value: "10", label: "Huyện Krông Bông" },
    { value: "11", label: "Huyện Krông Búk" },
    { value: "12", label: "Huyện Krông Năng" },
    { value: "13", label: "Huyện Krông Pắk" },
    { value: "14", label: "Huyện Lắk" },
    { value: "15", label: "Huyện M’Đrắk" },
];

// Tỉnh thành Đắk Nông
export const daknong_province = [
    { value: "1", label: "Thành phố Gia Nghĩa" },
    { value: "2", label: "Huyện Cư Jút" },
    { value: "3", label: "Huyện Đắk Glong" },
    { value: "4", label: "Huyện Đắk Mil" },
    { value: "5", label: "Huyện Đắk R’lấp" },
    { value: "6", label: "Huyện Đắk Song" },
    { value: "7", label: "Huyện Krông Nô" },
    { value: "8", label: "Huyện Tuy Đức" },
];

// Tỉnh thành Lâm Đồng
export const lamdong_province = [
    { value: "1", label: "Thành phố Bảo Lộc" },
    { value: "2", label: "Thành phố Đà Lạt" },
    { value: "3", label: "Huyện Bảo Lâm" },
    { value: "4", label: "Huyện Cát Tiên" },
    { value: "5", label: "Huyện Di Linh" },
    { value: "6", label: "Huyện Đạ Huoai" },
    { value: "7", label: "Huyện Đạ Tẻh" },
    { value: "8", label: "Huyện Đam Rông" },
    { value: "9", label: "Huyện Đơn Dương" },
    { value: "10", label: "Huyện Đức Trọng" },
    { value: "11", label: "Huyện Lạc Dương" },
    { value: "12", label: "Huyện Lâm Hà" },
];


// Tỉnh thành Gia lai
export const gialai_province = [
    { value: "1", label: "Thành phố Pleiku" },
    { value: "2", label: "Thị xã An Khê" },
    { value: "3", label: "Thị xã Ayun Pa" },
    { value: "4", label: "Huyện Chư Păh" },
    { value: "5", label: "Huyện Chư Prông" },
    { value: "6", label: "Huyện Chư Pưh" },
    { value: "7", label: "Huyện Chư Sê" },
    { value: "8", label: "Huyện Đắk Đoa" },
    { value: "9", label: "Huyện Đak Pơ" },
    { value: "10", label: "Huyện Đức Cơ" },
    { value: "11", label: "Huyện Ia Grai" },
    { value: "12", label: "Huyện Ia Pa" },
    { value: "13", label: "Huyện K’Bang" },
    { value: "14", label: "Huyện Kông Chro" },
    { value: "15", label: "Huyện Krông Pa" },
    { value: "16", label: "Huyện Mang Yang" },
    { value: "17", label: "Huyện Phú Thiện" },
];

// Tỉnh thành Kontum
export const kontum_province = [
    { value: "1", label: "Thành phố Kon Tum" },
    { value: "2", label: "Huyện Đắk Glei" },
    { value: "3", label: "Huyện Đắk Hà" },
    { value: "4", label: "Huyện Đắk Tô" },
    { value: "5", label: "Huyện Ia H’Drai" },
    { value: "6", label: "Huyện Kon Plông" },
    { value: "7", label: "Huyện Kon Rẫy" },
    { value: "8", label: "Huyện Ngọc Hồi" },
    { value: "9", label: "Huyện Sa Thầy" },
    { value: "10", label: "Huyện Tu Mơ Rông" },
];