import React from "react";
import '../../css/Paginate.css';
import * as disLabel from "../constants/label";
import '../../css/ViewAndLike.css';
import { FaRegClock, FaRegHeart } from 'react-icons/fa';
import appStore from '../atribute/AppStore';
import { observer } from 'mobx-react';
import convertToTextPrice from "../utils/ConvertToText";
require('dotenv').config();


const endpoint = process.env.REACT_APP_BACKEND_SERVER;

class ViewEstatesRecentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewedRecentArr: JSON.parse(localStorage.getItem("viewed_recent_estate")) || [],
      likedEstate: JSON.parse(localStorage.getItem("liked_estate")) || [],
      mediaImage: endpoint + 'media/',
    };
  }

  componentDidMount = () => {
    const { viewedRecentArr, likedEstate } = this.state;

    // Tìm xem có item nào trong viewedRecentArr mà cần cập nhật
    const indexToUpdate = viewedRecentArr.findIndex((viewedItem) =>
      likedEstate.some((likedItem) => likedItem.id === viewedItem.id)
    );

    if (indexToUpdate !== -1) {
      // Tạo một bản sao mới của viewedRecentArr để tránh sửa đổi trực tiếp trạng thái
      const updatedViewedRecentArr = [...viewedRecentArr];

      // Cập nhật trạng thái isHeartClicked cho item tại indexToUpdate
      updatedViewedRecentArr[indexToUpdate].isHeartClicked = true;

      // Cập nhật trạng thái mới vào trạng thái của bạn
      this.setState({
        viewedRecentArr: updatedViewedRecentArr,
      });
    }
  }

  playClickSound = () => {
    try {
      const audio = new Audio("/sounds/click-sound.mp3");
      audio.play();
    } catch (error) {
      console.error("Lỗi:", error);
    }
  }

  handleHeartClick = (e,item) => {
    e.preventDefault();
    
    this.playClickSound();
    // Cập nhật trạng thái CSS sau khi bấm vào FaRegHeart
    // ------------------------------------Start---------------------------------------------
    // tạo bản sao item
    let itemViewedCopy = {...item};
    if (itemViewedCopy.isHeartClicked === false) {
      itemViewedCopy.isHeartClicked = true;
    } else {
      itemViewedCopy.isHeartClicked = false;
    }

    let viewedEstateArr = JSON.parse(localStorage.getItem("viewed_recent_estate")) || [];
    let indexToUpdate = viewedEstateArr.findIndex((likedItem) => likedItem.id === itemViewedCopy.id);
    if (indexToUpdate !== -1) {
      viewedEstateArr[indexToUpdate] = itemViewedCopy;
    } else {
      viewedEstateArr.push(itemViewedCopy);
    }

    localStorage.setItem("viewed_recent_estate", JSON.stringify(viewedEstateArr));
    this.setState({ viewedRecentArr: viewedEstateArr });
    // -------------------------------------Finish-------------------------------------------
    

    // Add item liked to array liked_estate
    // ------------------------------------Start---------------------------------------------
    let isExistLikedEstate = localStorage.getItem("liked_estate");
    if (isExistLikedEstate) {
        try {
            let likedEstateArr = JSON.parse(isExistLikedEstate);
            if (likedEstateArr.length > 20) {
                localStorage.removeItem("liked_estate");
            }
        } catch (error) {
            console.log(error);
        }
    }
    let itemlikedCopy = {...item};
    let likedEstateConvArr = JSON.parse(localStorage.getItem("liked_estate")) || [];
    let indexDelOrAdd = likedEstateConvArr.findIndex((item) => item.id === itemlikedCopy.id);
    if(indexDelOrAdd === -1) {
      if (itemlikedCopy.isHeartClicked === false) {
        itemlikedCopy.isHeartClicked = true;
        likedEstateConvArr.push(itemlikedCopy);
     }
    } else {
      likedEstateConvArr.splice(indexDelOrAdd, 1)
    }
    localStorage.setItem("liked_estate", JSON.stringify(likedEstateConvArr));
    appStore.setCountLikedClick(likedEstateConvArr.length);
    // -------------------------------------Finish-------------------------------------------
  }

  viewDetailPage(item) {
   
      let itemLiked = {
        id: item.id,
        estates_Type: item.estates_Type,
        estates_Name: item.estates_Name,
        estates_Title: item.estates_Title,
        estates_Detail: item.estates_Detail,
        estates_Legal_Doc: item.estates_Legal_Doc,
        width_Road: item.width_Road,
        estates_Price: item.estates_Price,
        estates_PriceForUnit: item.estates_PriceForUnit,
        estates_Direction_Home: item.estates_Direction_Home,
        estates_Area: item.estates_Area,
        selected_City: item.selected_City,
        selected_Province: item.selected_Province,
        selected_District: item.selected_District,
        estates_Street: item.estates_Street,
        estates_Nums_GuestRoom: item.estates_Nums_GuestRoom,
        estates_Nums_BedRoom: item.estates_Nums_BedRoom,
        estates_NumsBathAndToiletRoom: item.estates_NumsBathAndToiletRoom,
        isDisplay_Available: item.isDisplay_Available,
        isHeartClicked: item.isHeartClicked,
        contact_Name: item.contact_Name,
        contact_Phone: item.contact_Phone,
        transaction_Type: item.transaction_Type,
        images_directory: item.images_directory,
        images_extension: item.images_extension,
        images_count: item.images_count,
        created_at: item.created_at,
        updated_at: item.updated_at,
      }

    if (localStorage.getItem("viewed_detail_estate")) {
      localStorage.removeItem("viewed_detail_estate");
    }

    // Add item view detail to array viewed_detail_estate
    let viewDetailArr = [];
    viewDetailArr.push(itemLiked);
    localStorage.setItem("viewed_detail_estate", JSON.stringify(viewDetailArr));
  };

  render() {

    return (
      <div>
        <FaRegClock size={30} className="fa-heard-title" style={{ fill:'#FF6800'}} /><span className="title-viewest-liked">{disLabel.ESTATE_VIEWEST_RECENT}</span>
        {this.state.viewedRecentArr.length === 0 ? 
        <div className="no-record-like-exist">{disLabel.NO_EXIST_RECORD}</div> :
        this.state.viewedRecentArr.map((item, index) => (
          <div>
            <div className="container-view-recent-child">
              <a className="link-to-view-recent-page" href="/xem-chi-tiet-bat-dong-san" onClick={() => this.viewDetailPage(item)}>
                <div className="view-recent-parent">

                  <div className="view-recent-child-left">
                    <div className="view-recent-photo-lefthand">
                      <p className="view-recent-photo"><img src={`${this.state.mediaImage}${item.images_directory}/image_0${item.images_extension}`} alt="" /></p>
                    </div>
                  </div>
                  <div className="view-recent-child-right">
                    <div className="view-recent-child-right_column1">
                      {item.estates_PriceForUnit === 'VND' ? (
                        <span className="view-recent-child-right_cost">{convertToTextPrice(item.estates_Price)}</span>
                      ) : (
                        <span className="view-recent-child-right_cost">{convertToTextPrice(item.estates_Price)}{disLabel.ESTATE_M2}</span>
                      )}
                      <span className="view-recent-child-right_address">{item.selected_Province}, {item.selected_City}</span>
                    </div>
                    <div className="view-recent-child-right_column2">
                      <span className="view-recent-child-right_construct">{disLabel.DIRECTION_HOUSE} {item.estates_Direction_Home}</span>
                    </div>
                    <div className="view-recent-child-right_column3">
                      <span className="view-recent-child-right_area">{disLabel.AREA} {item.estates_Area}m²</span>
                    </div>
                  </div>
                  <div className="click-like-estates_view-recent">
                    <FaRegHeart size={26} style={{ fill: item.isHeartClicked ? '#FF6800' : 'rgb(147 144 144)' }} onClick={(e) => this.handleHeartClick(e, item)} />
                  </div>
                </div>
              </a>
            </div>
            <div className="div-between"></div>
          </div>))
          }
      </div>
    );
  }
}
export default observer(ViewEstatesRecentPage);
