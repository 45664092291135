// store.js
import { makeAutoObservable } from 'mobx';

class AppStore {
  
  SearchResultList = [];
  CountLikedClick = 0;
  HasSearchResults = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSearchResultList(value) {
    this.SearchResultList = value;
  }

  setCountLikedClick(value) {
    this.CountLikedClick = value;
  }

  setHasSearchResults(value) {
    this.HasSearchResults = value;
  }

  getSearchResultList() {
    return this.SearchResultList;
  }

  getCountLikedClick() {
    return this.CountLikedClick;
  }

  getHasSearchResults() {
    return this.HasSearchResults;
  }
}

const appStore = new AppStore();
export default appStore;
