import React from "react";
import { Form } from "react-bootstrap";
import * as disLabel from "../../constants/label";

class AccountActiveGuideline extends React.Component {

    render() {
        return (
            <div className="div-guideline">
                <div><h5 className="title_display_default">{disLabel.ACCOUNT_ACTIVE_GUIDLINE}</h5></div><br/>
                <span>Để đăng tin bán bất động sản thì trước tiên phải tạo account, và lưu ý là Email đăng ký phải hợp lệ để có thể kích hoạt account.<br/></span>
                <img src="/images/active_account_1.png" alt="Active Account" style={{ width: '100%' }} /><br/><br/>
                <span>Sau khi đăng ký account thì hệ thống sẽ gởi mail kích hoạt như dưới, bạn phải kích hoạt trong vòng 24h kể từ thời điểm đăng ký.<br/></span>
                <img src="/images/active_account_2.png" alt="Active Account" style={{ width: '100%' }} /><br/><br/>
                <span>Sau khi kích hoạt thành công thì sẽ duy chuyển tới màn hình như bên dưới, và bạn có thể login vào hệ thống.</span>
                <img src="/images/active_account_3.png" alt="Active Account" style={{ width: '100%' }} />
            </div>
        );
    }
}

export default AccountActiveGuideline;