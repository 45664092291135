import React from "react";


class InfoSecurityPage extends React.Component {


  render() {

    return (
      <div className="container-rule-parent">
        <span className="rule-use-title-lv1">Bảo mật thông tin</span>
        <div className="rule-use-title-lv2">
          <span >Chính Sách Bảo Mật Thông Tin Người Dùng - Raozat.com</span>
          <div className="rule-use-content-lv2">
            <span>Chào mừng Quý khách đến với Raozat.com - Sàn thương mại điện tử hàng đầu! Chúng tôi cam kết bảo vệ thông tin cá nhân của Quý khách, đảm bảo tuân thủ theo các quy định của Nghị định 52/2013/NĐ-CP.</span>
          </div>
          <span >1. Trách Nhiệm Bảo Vệ Thông Tin Cá Nhân Người Dùng (Điều 68):</span>
          <div className="rule-use-content-lv2">
            <span>Trong quá trình hoạt động kinh doanh Sàn thương mại điện tử, Raozat.com không chỉ tuân thủ mọi quy định tại Nghị định 52/2013/NĐ-CP mà còn chú trọng đặc biệt đến các quy định pháp luật liên quan về bảo vệ thông tin cá nhân.</span>
          </div>
          <span >2. Chính Sách Bảo Vệ Thông Tin Cá Nhân Người Dùng (Điều 69):</span>
          <div className="rule-use-content-lv2">
            <span>▪ Mục Đích và Phạm Vi Thu Thập Thông Tin:</span><br/>
            <span>Raozat.com thu thập và sử dụng thông tin cá nhân để xây dựng sự tin tưởng trong cộng đồng khách hàng và đối tác.</span><br/>
            <span>Thông tin cá nhân bao gồm: mật khẩu, password, email, số điện thoại, Họ và tên, Địa chỉ.</span><br/>
            <span>▪ Phạm Vi Sử Dụng Thông Tin:</span><br/>
            <span>Thông tin cá nhân được áp dụng để quản lý tài khoản và tạo điều kiện cho giao dịch mua bán.</span><br/>
            <span>Thông tin được lưu trữ trọn đời và chỉ chia sẻ với ban quản trị và trong những trường hợp hỗ trợ từ cơ quan chức năng, thống kê thị trường, hay mục đích xã hội khác.</span><br/>
            <span>▪ Phương Tiện và Công Cụ Điều Chỉnh Dữ Liệu Cá Nhân:</span><br/>
            <span>Người dùng được cấp quyền hoàn toàn để chỉnh sửa thông tin cá nhân qua tài khoản đã đăng ký và xác thực.</span><br/>
          </div>
          <span >3. Cam Kết Bảo Mật Thông Tin Khách Hàng:</span>
          <div className="rule-use-content-lv2">
            <span>Quy định về bảo vệ thông tin khách hàng được Raozat.com công bố rõ ràng tại phần chân trang. Sự đồng ý của người dùng được yêu cầu trước khi thu thập thông tin, thông qua cơ chế xác nhận qua email.</span>
          </div>
          <span >4. Xin Phép Người Tiêu Dùng Khi Thu Thập Thông Tin (Điều 70):</span>
          <div className="rule-use-content-lv2">
            <span>Raozat.com chỉ thu thập thông tin sau khi có sự đồng ý rõ ràng từ người dùng, trừ những trường hợp đã công bố công khai, hoặc để thực hiện các hợp đồng mua bán và dịch vụ khác trên mạng.</span>
          </div>
          <span >5. Sử Dụng Thông Tin Cá Nhân (Điều 71):</span>
          <div className="rule-use-content-lv2">
            <span>Raozat.com cam kết sử dụng thông tin cá nhân đúng với mục đích và phạm vi đã được thông báo, tuân thủ đầy đủ quy định tại Nghị định 52/2013/NĐ-CP.</span>
          </div>
          <span >6. Chính Sách Bảo Đảm An Toàn Thông Tin Cá Nhân (Điều 72):</span>
          <div className="rule-use-content-lv2">
            <span>Ban quản trị Raozat.com cam kết bảo vệ tuyệt đối thông tin cá nhân, thực hiện các biện pháp an toàn để ngăn chặn truy cập trái phép, đảm bảo uy tín và hiệu quả kinh doanh.</span>
          </div>
          <span >7. Kiểm Tra, Cập Nhật và Điều Chỉnh Thông Tin Cá Nhân (Điều 73):</span>
          <div className="rule-use-content-lv2">
            <span>Người dùng có quyền yêu cầu kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân. Raozat.com cam kết đáp ứng nhanh chóng và cung cấp công cụ thuận lợi để người dùng tự kiểm soát thông tin cá nhân của mình.</span><br/>
            <span>Chúng tôi không ngừng nỗ lực để giữ vững và nâng cao chất lượng bảo mật thông tin, mang lại trải nghiệm an toàn và tin cậy nhất cho cộng đồng người dùng Raozat.com.</span>
          </div>
        </div>
      </div>
    );
  }
}
export default InfoSecurityPage;
