export const hochiminh_quan1 = [
  { value: "1", label: "Phường Tân Định" },
  { value: "2", label: "Phường Đa Kao" },
  { value: "3", label: "Phường Bến Nghé" },
  { value: "4", label: "Phường Bến Thành" },
  { value: "5", label: "Phường Nguyễn Thái Bình" },
  { value: "6", label: "Phường Phạm Ngũ Lão" },
  { value: "7", label: "Phường Cầu Ông Lãnh" },
  { value: "8", label: "Phường Cô Giang" },
  { value: "9", label: "Phường Nguyễn Cư Trinh" },
  { value: "10", label: "Phường Cầu Kho" },
];
export const hochiminh_quan12 = [
  { value: "1", label: "Phường Thạnh Xuân" },
  { value: "2", label: "Phường Thạnh Lộc" },
  { value: "3", label: "Phường Hiệp Thành" },
  { value: "4", label: "Phường Thới An" },
  { value: "5", label: "Phường Tân Chánh Hiệp" },
  { value: "6", label: "Phường An Phú Đông" },
  { value: "7", label: "Phường Tân Thới Hiệp" },
  { value: "8", label: "Phường Trung Mỹ Tây" },
  { value: "9", label: "Phường Tân Hưng Thuận" },
  { value: "10", label: "Phường Đông Hưng Thuận" },
  { value: "11", label: "Phường Tân Thới Nhất" },
];
export const hochiminh_quanthuduc = [
  { value: "1", label: "Phường Linh Xuân" },
  { value: "2", label: "Phường Bình Chiểu" },
  { value: "3", label: "Phường Linh Trung" },
  { value: "4", label: "Phường Tam Bình" },
  { value: "5", label: "Phường Tam Phú" },
  { value: "6", label: "Phường Hiệp Bình Phước" },
  { value: "7", label: "Phường Hiệp Bình Chánh" },
  { value: "8", label: "Phường Linh Chiểu" },
  { value: "9", label: "Phường Linh Tây" },
  { value: "10", label: "Phường Linh Đông" },
  { value: "11", label: "Phường Bình Thọ" },
  { value: "12", label: "Phường Trường Thọ" },
];
export const hochiminh_quan9 = [
  { value: "1", label: "Phường Long Bình" },
  { value: "2", label: "Phường Long Thạnh Mỹ" },
  { value: "3", label: "Phường Tân Phú" },
  { value: "4", label: "Phường Hiệp Phú" },
  { value: "5", label: "Phường Tăng Nhơn Phú A" },
  { value: "6", label: "Phường Tăng Nhơn Phú B" },
  { value: "7", label: "Phường Phước Long B" },
  { value: "8", label: "Phường Phước Long A" },
  { value: "9", label: "Phường Trường Thạnh" },
  { value: "10", label: "Phường Long Phước" },
  { value: "11", label: "Phường Long Trường" },
  { value: "12", label: "Phường Phước Bình" },
  { value: "13", label: "Phường Phú Hữu" },
];
export const hochiminh_quangovap = [
  { value: "1", label: "Phường 15" },
  { value: "2", label: "Phường 13" },
  { value: "3", label: "Phường 17" },
  { value: "4", label: "Phường 6" },
  { value: "5", label: "Phường 16" },
  { value: "6", label: "Phường 12" },
  { value: "7", label: "Phường 14" },
  { value: "8", label: "Phường 10" },
  { value: "9", label: "Phường 05" },
  { value: "10", label: "Phường 07" },
  { value: "11", label: "Phường 04" },
  { value: "12", label: "Phường 01" },
  { value: "13", label: "Phường 9" },
  { value: "14", label: "Phường 8" },
  { value: "15", label: "Phường 11" },
  { value: "16", label: "Phường 03" },
];
export const hochiminh_quanbinhthanh = [
  { value: "1", label: "Phường 13" },
  { value: "2", label: "Phường 11" },
  { value: "3", label: "Phường 27" },
  { value: "4", label: "Phường 26" },
  { value: "5", label: "Phường 12" },
  { value: "6", label: "Phường 25" },
  { value: "7", label: "Phường 05" },
  { value: "8", label: "Phường 07" },
  { value: "9", label: "Phường 24" },
  { value: "10", label: "Phường 06" },
  { value: "11", label: "Phường 14" },
  { value: "12", label: "Phường 15" },
  { value: "13", label: "Phường 02" },
  { value: "14", label: "Phường 01" },
  { value: "15", label: "Phường 03" },
  { value: "16", label: "Phường 17" },
  { value: "17", label: "Phường 21" },
  { value: "18", label: "Phường 22" },
  { value: "19", label: "Phường 19" },
  { value: "20", label: "Phường 28" },
];
export const hochiminh_quantanbinh = [
  { value: "1", label: "Phường 02" },
  { value: "2", label: "Phường 04" },
  { value: "3", label: "Phường 12" },
  { value: "4", label: "Phường 13" },
  { value: "5", label: "Phường 01" },
  { value: "6", label: "Phường 03" },
  { value: "7", label: "Phường 11" },
  { value: "8", label: "Phường 07" },
  { value: "9", label: "Phường 05" },
  { value: "10", label: "Phường 10" },
  { value: "11", label: "Phường 06" },
  { value: "12", label: "Phường 08" },
  { value: "13", label: "Phường 09" },
  { value: "14", label: "Phường 14" },
  { value: "15", label: "Phường 15" },
];
export const hochiminh_quantanphu = [
  { value: "1", label: "Phường Tân Sơn Nhì" },
  { value: "2", label: "Phường Tây Thạnh" },
  { value: "3", label: "Phường Sơn Kỳ" },
  { value: "4", label: "Phường Tân Quý" },
  { value: "5", label: "Phường Tân Thành" },
  { value: "6", label: "Phường Phú Thọ Hòa" },
  { value: "7", label: "Phường Phú Thạnh" },
  { value: "8", label: "Phường Phú Trung" },
  { value: "9", label: "Phường Hòa Thạnh" },
  { value: "10", label: "Phường Hiệp Tân" },
  { value: "11", label: "Phường Tân Thới Hòa" },
];
export const hochiminh_quanphunhuan = [
  { value: "1", label: "Phường 04" },
  { value: "2", label: "Phường 05" },
  { value: "3", label: "Phường 09" },
  { value: "4", label: "Phường 07" },
  { value: "5", label: "Phường 03" },
  { value: "6", label: "Phường 01" },
  { value: "7", label: "Phường 02" },
  { value: "8", label: "Phường 08" },
  { value: "9", label: "Phường 15" },
  { value: "10", label: "Phường 10" },
  { value: "11", label: "Phường 11" },
  { value: "12", label: "Phường 17" },
  { value: "13", label: "Phường 14" },
  { value: "14", label: "Phường 12" },
  { value: "15", label: "Phường 13" },
];
export const hochiminh_quan2 = [
  { value: "1", label: "Phường Thảo Điền" },
  { value: "2", label: "Phường An Phú" },
  { value: "3", label: "Phường Bình An" },
  { value: "4", label: "Phường Bình Trưng Đông" },
  { value: "5", label: "Phường Bình Trưng Tây" },
  { value: "6", label: "Phường Bình Khánh" },
  { value: "7", label: "Phường An Khánh" },
  { value: "8", label: "Phường Cát Lái" },
  { value: "9", label: "Phường Thạnh Mỹ Lợi" },
  { value: "10", label: "Phường An Lợi Đông" },
  { value: "11", label: "Phường Thủ Thiêm" },
];
export const hochiminh_quan3 = [
  { value: "1", label: "Phường 08" },
  { value: "2", label: "Phường 07" },
  { value: "3", label: "Phường 14" },
  { value: "4", label: "Phường 12" },
  { value: "5", label: "Phường 11" },
  { value: "6", label: "Phường 13" },
  { value: "7", label: "Phường 06" },
  { value: "8", label: "Phường 09" },
  { value: "9", label: "Phường 10" },
  { value: "10", label: "Phường 04" },
  { value: "11", label: "Phường 05" },
  { value: "12", label: "Phường 03" },
  { value: "13", label: "Phường 02" },
  { value: "14", label: "Phường 01" },
];
export const hochiminh_quan10 = [
  { value: "1", label: "Phường 15" },
  { value: "2", label: "Phường 13" },
  { value: "3", label: "Phường 14" },
  { value: "4", label: "Phường 12" },
  { value: "5", label: "Phường 11" },
  { value: "6", label: "Phường 10" },
  { value: "7", label: "Phường 09" },
  { value: "8", label: "Phường 01" },
  { value: "9", label: "Phường 08" },
  { value: "10", label: "Phường 02" },
  { value: "11", label: "Phường 04" },
  { value: "12", label: "Phường 07" },
  { value: "13", label: "Phường 05" },
  { value: "14", label: "Phường 06" },
  { value: "15", label: "Phường 03" },
];
export const hochiminh_quan11 = [
  { value: "1", label: "Phường 15" },
  { value: "2", label: "Phường 05" },
  { value: "3", label: "Phường 14" },
  { value: "4", label: "Phường 11" },
  { value: "5", label: "Phường 03" },
  { value: "6", label: "Phường 10" },
  { value: "7", label: "Phường 13" },
  { value: "8", label: "Phường 08" },
  { value: "9", label: "Phường 09" },
  { value: "10", label: "Phường 12" },
  { value: "11", label: "Phường 07" },
  { value: "12", label: "Phường 06" },
  { value: "13", label: "Phường 04" },
  { value: "14", label: "Phường 01" },
  { value: "15", label: "Phường 02" },
  { value: "16", label: "Phường 16" },
];
export const hochiminh_quan4 = [
  { value: "1", label: "Phường 12" },
  { value: "2", label: "Phường 13" },
  { value: "3", label: "Phường 09" },
  { value: "4", label: "Phường 06" },
  { value: "5", label: "Phường 08" },
  { value: "6", label: "Phường 10" },
  { value: "7", label: "Phường 05" },
  { value: "8", label: "Phường 18" },
  { value: "9", label: "Phường 14" },
  { value: "10", label: "Phường 04" },
  { value: "11", label: "Phường 03" },
  { value: "12", label: "Phường 16" },
  { value: "13", label: "Phường 02" },
  { value: "14", label: "Phường 15" },
  { value: "15", label: "Phường 01" },
];
export const hochiminh_quan5 = [
  { value: "1", label: "Phường 04" },
  { value: "2", label: "Phường 09" },
  { value: "3", label: "Phường 03" },
  { value: "4", label: "Phường 12" },
  { value: "5", label: "Phường 02" },
  { value: "6", label: "Phường 08" },
  { value: "7", label: "Phường 15" },
  { value: "8", label: "Phường 07" },
  { value: "9", label: "Phường 01" },
  { value: "10", label: "Phường 11" },
  { value: "11", label: "Phường 14" },
  { value: "12", label: "Phường 05" },
  { value: "13", label: "Phường 06" },
  { value: "14", label: "Phường 10" },
  { value: "15", label: "Phường 13" },
];
export const hochiminh_quan6 = [
  { value: "1", label: "Phường 14" },
  { value: "2", label: "Phường 13" },
  { value: "3", label: "Phường 09" },
  { value: "4", label: "Phường 06" },
  { value: "5", label: "Phường 12" },
  { value: "6", label: "Phường 05" },
  { value: "7", label: "Phường 11" },
  { value: "8", label: "Phường 02" },
  { value: "9", label: "Phường 01" },
  { value: "10", label: "Phường 04" },
  { value: "11", label: "Phường 08" },
  { value: "12", label: "Phường 03" },
  { value: "13", label: "Phường 07" },
  { value: "14", label: "Phường 10" },
];
export const hochiminh_quan8 = [
  { value: "1", label: "Phường 08" },
  { value: "2", label: "Phường 02" },
  { value: "3", label: "Phường 01" },
  { value: "4", label: "Phường 03" },
  { value: "5", label: "Phường 11" },
  { value: "6", label: "Phường 09" },
  { value: "7", label: "Phường 10" },
  { value: "8", label: "Phường 04" },
  { value: "9", label: "Phường 13" },
  { value: "10", label: "Phường 12" },
  { value: "11", label: "Phường 05" },
  { value: "12", label: "Phường 14" },
  { value: "13", label: "Phường 06" },
  { value: "14", label: "Phường 15" },
  { value: "15", label: "Phường 16" },
  { value: "16", label: "Phường 07" },
];
export const hochiminh_quanbinhtan = [
  { value: "1", label: "Phường Bình Hưng Hòa" },
  { value: "2", label: "Phường Bình Hưng Hòa A" },
  { value: "3", label: "Phường Bình Hưng Hòa B" },
  { value: "4", label: "Phường Bình Trị Đông" },
  { value: "5", label: "Phường Bình Trị Đông A" },
  { value: "6", label: "Phường Bình Trị Đông B" },
  { value: "7", label: "Phường Tân Tạo" },
  { value: "8", label: "Phường Tân Tạo A" },
  { value: "9", label: "Phường An Lạc" },
  { value: "10", label: "Phường An Lạc A" },
];
export const hochiminh_quan7 = [
  { value: "1", label: "Phường Tân Thuận Đông" },
  { value: "2", label: "Phường Tân Thuận Tây" },
  { value: "3", label: "Phường Tân Kiểng" },
  { value: "4", label: "Phường Tân Hưng" },
  { value: "5", label: "Phường Bình Thuận" },
  { value: "6", label: "Phường Tân Quy" },
  { value: "7", label: "Phường Phú Thuận" },
  { value: "8", label: "Phường Tân Phú" },
  { value: "9", label: "Phường Tân Phong" },
  { value: "10", label: "Phường Phú Mỹ" },
];
export const hochiminh_huyencuchi = [
  { value: "1", label: "Thị trấn Củ Chi" },
  { value: "2", label: "Xã Phú Mỹ Hưng" },
  { value: "3", label: "Xã An Phú" },
  { value: "4", label: "Xã Trung Lập Thượng" },
  { value: "5", label: "Xã An Nhơn Tây" },
  { value: "6", label: "Xã Nhuận Đức" },
  { value: "7", label: "Xã Phạm Văn Cội" },
  { value: "8", label: "Xã Phú Hòa Đông" },
  { value: "9", label: "Xã Trung Lập Hạ" },
  { value: "10", label: "Xã Trung An" },
  { value: "11", label: "Xã Phước Thạnh" },
  { value: "12", label: "Xã Phước Hiệp" },
  { value: "13", label: "Xã Tân An Hội" },
  { value: "14", label: "Xã Phước Vĩnh An" },
  { value: "15", label: "Xã Thái Mỹ" },
  { value: "16", label: "Xã Tân Thạnh Tây" },
  { value: "17", label: "Xã Hòa Phú" },
  { value: "18", label: "Xã Tân Thạnh Đông" },
  { value: "19", label: "Xã Bình Mỹ" },
  { value: "20", label: "Xã Tân Phú Trung" },
  { value: "21", label: "Xã Tân Thông Hội" },
];
export const hochiminh_huyenhocmon = [
  { value: "1", label: "Thị trấn Hóc Môn" },
  { value: "2", label: "Xã Tân Hiệp" },
  { value: "3", label: "Xã Nhị Bình" },
  { value: "4", label: "Xã Đông Thạnh" },
  { value: "5", label: "Xã Tân Thới Nhì" },
  { value: "6", label: "Xã Thới Tam Thôn" },
  { value: "7", label: "Xã Xuân Thới Sơn" },
  { value: "8", label: "Xã Tân Xuân" },
  { value: "9", label: "Xã Xuân Thới Đông" },
  { value: "10", label: "Xã Trung Chánh" },
  { value: "11", label: "Xã Xuân Thới Thượng" },
  { value: "12", label: "Xã Bà Điểm" },
];
export const hochiminh_huyenbinhchanh = [
  { value: "1", label: "Thị trấn Tân Túc" },
  { value: "2", label: "Xã Phạm Văn Hai" },
  { value: "3", label: "Xã Vĩnh Lộc A" },
  { value: "4", label: "Xã Vĩnh Lộc B" },
  { value: "5", label: "Xã Bình Lợi" },
  { value: "6", label: "Xã Lê Minh Xuân" },
  { value: "7", label: "Xã Tân Nhựt" },
  { value: "8", label: "Xã Tân Kiên" },
  { value: "9", label: "Xã Bình Hưng" },
  { value: "10", label: "Xã Phong Phú" },
  { value: "11", label: "Xã An Phú Tây" },
  { value: "12", label: "Xã Hưng Long" },
  { value: "13", label: "Xã Đa Phước" },
  { value: "14", label: "Xã Tân Quý Tây" },
  { value: "15", label: "Xã Bình Chánh" },
  { value: "16", label: "Xã Quy Đức" },
];
export const hochiminh_huyennhabe = [
  { value: "1", label: "Thị trấn Nhà Bè" },
  { value: "2", label: "Xã Phước Kiển" },
  { value: "3", label: "Xã Phước Lộc" },
  { value: "4", label: "Xã Nhơn Đức" },
  { value: "5", label: "Xã Phú Xuân" },
  { value: "6", label: "Xã Long Thới" },
  { value: "7", label: "Xã Hiệp Phước" },
];
export const hochiminh_huyencangio = [
  { value: "1", label: "Thị trấn Cần Thạnh" },
  { value: "2", label: "Xã Bình Khánh" },
  { value: "3", label: "Xã Tam Thôn Hiệp" },
  { value: "4", label: "Xã An Thới Đông" },
  { value: "5", label: "Xã Thạnh An" },
  { value: "6", label: "Xã Long Hòa" },
  { value: "7", label: "Xã Lý Nhơn" },
];
export const hanoi_quanbadinh = [
  { value: "1", label: "Phường Phúc Xá" },
  { value: "2", label: "Phường Trúc Bạch" },
  { value: "3", label: "Phường Vĩnh Phúc" },
  { value: "4", label: "Phường Cống Vị" },
  { value: "5", label: "Phường Liễu Giai" },
  { value: "6", label: "Phường Nguyễn Trung Trực" },
  { value: "7", label: "Phường Quán Thánh" },
  { value: "8", label: "Phường Ngọc Hà" },
  { value: "9", label: "Phường Điện Biên" },
  { value: "10", label: "Phường Đội Cấn" },
  { value: "11", label: "Phường Ngọc Khánh" },
  { value: "12", label: "Phường Kim Mã" },
  { value: "13", label: "Phường Giảng Võ" },
  { value: "14", label: "Phường Thành Công" },
];
export const hanoi_quanhoangkiem = [
  { value: "1", label: "Phường Phúc Tân" },
  { value: "2", label: "Phường Đồng Xuân" },
  { value: "3", label: "Phường Hàng Mã" },
  { value: "4", label: "Phường Hàng Buồm" },
  { value: "5", label: "Phường Hàng Đào" },
  { value: "6", label: "Phường Hàng Bồ" },
  { value: "7", label: "Phường Cửa Đông" },
  { value: "8", label: "Phường Lý Thái Tổ" },
  { value: "9", label: "Phường Hàng Bạc" },
  { value: "10", label: "Phường Hàng Gai" },
  { value: "11", label: "Phường Chương Dương Độ" },
  { value: "12", label: "Phường Hàng Trống" },
  { value: "13", label: "Phường Cửa Nam" },
  { value: "14", label: "Phường Hàng Bông" },
  { value: "15", label: "Phường Tràng Tiền" },
  { value: "16", label: "Phường Trần Hưng Đạo" },
  { value: "17", label: "Phường Phan Chu Trinh" },
  { value: "18", label: "Phường Hàng Bài" },
];
export const hanoi_quantayho = [
  { value: "1", label: "Phường Phú Thượng" },
  { value: "2", label: "Phường Nhật Tân" },
  { value: "3", label: "Phường Tứ Liên" },
  { value: "4", label: "Phường Quảng An" },
  { value: "5", label: "Phường Xuân La" },
  { value: "6", label: "Phường Yên Phụ" },
  { value: "7", label: "Phường Bưởi" },
  { value: "8", label: "Phường Thụy Khuê" },
];
export const hanoi_quanlongbien = [
  { value: "1", label: "Phường Thượng Thanh" },
  { value: "2", label: "Phường Ngọc Thụy" },
  { value: "3", label: "Phường Giang Biên" },
  { value: "4", label: "Phường Đức Giang" },
  { value: "5", label: "Phường Việt Hưng" },
  { value: "6", label: "Phường Gia Thụy" },
  { value: "7", label: "Phường Ngọc Lâm" },
  { value: "8", label: "Phường Phúc Lợi" },
  { value: "9", label: "Phường Bồ Đề" },
  { value: "10", label: "Phường Sài Đồng" },
  { value: "11", label: "Phường Long Biên" },
  { value: "12", label: "Phường Thạch Bàn" },
  { value: "13", label: "Phường Phúc Đồng" },
  { value: "14", label: "Phường Cự Khối" },
];
export const hanoi_quancaugiay = [
  { value: "1", label: "Phường Nghĩa Đô" },
  { value: "2", label: "Phường Nghĩa Tân" },
  { value: "3", label: "Phường Mai Dịch" },
  { value: "4", label: "Phường Dịch Vọng" },
  { value: "5", label: "Phường Dịch Vọng Hậu" },
  { value: "6", label: "Phường Quan Hoa" },
  { value: "7", label: "Phường Yên Hòa" },
  { value: "8", label: "Phường Trung Hòa" },
];
export const hanoi_quandongda = [
  { value: "1", label: "Phường Cát Linh" },
  { value: "2", label: "Phường Văn Miếu" },
  { value: "3", label: "Phường Quốc Tử Giám" },
  { value: "4", label: "Phường Láng Thượng" },
  { value: "5", label: "Phường Ô Chợ Dừa" },
  { value: "6", label: "Phường Văn Chương" },
  { value: "7", label: "Phường Hàng Bột" },
  { value: "8", label: "Phường Láng Hạ" },
  { value: "9", label: "Phường Khâm Thiên" },
  { value: "10", label: "Phường Thổ Quan" },
  { value: "11", label: "Phường Nam Đồng" },
  { value: "12", label: "Phường Trung Phụng" },
  { value: "13", label: "Phường Quang Trung" },
  { value: "14", label: "Phường Trung Liệt" },
  { value: "15", label: "Phường Phương Liên" },
  { value: "16", label: "Phường Thịnh Quang" },
  { value: "17", label: "Phường Trung Tự" },
  { value: "18", label: "Phường Kim Liên" },
  { value: "19", label: "Phường Phương Mai" },
  { value: "20", label: "Phường Ngã Tư Sở" },
  { value: "21", label: "Phường Khương Thượng" },
];
export const hanoi_quanhaibatrung = [
  { value: "1", label: "Phường Nguyễn Du" },
  { value: "2", label: "Phường Bạch Đằng" },
  { value: "3", label: "Phường Phạm Đình Hổ" },
  { value: "4", label: "Phường Bùi Thị Xuân" },
  { value: "5", label: "Phường Ngô Thì Nhậm" },
  { value: "6", label: "Phường Lê Đại Hành" },
  { value: "7", label: "Phường Đồng Nhân" },
  { value: "8", label: "Phường Phố Huế" },
  { value: "9", label: "Phường Đống Mác" },
  { value: "10", label: "Phường Thanh Lương" },
  { value: "11", label: "Phường Thanh Nhàn" },
  { value: "12", label: "Phường Cầu Dền" },
  { value: "13", label: "Phường Bách Khoa" },
  { value: "14", label: "Phường Đồng Tâm" },
  { value: "15", label: "Phường Vĩnh Tuy" },
  { value: "16", label: "Phường Bạch Mai" },
  { value: "17", label: "Phường Quỳnh Mai" },
  { value: "18", label: "Phường Quỳnh Lôi" },
  { value: "19", label: "Phường Minh Khai" },
  { value: "20", label: "Phường Trương Định" },
];
export const hanoi_quanhoangmai = [
  { value: "1", label: "Phường Thanh Trì" },
  { value: "2", label: "Phường Vĩnh Hưng" },
  { value: "3", label: "Phường Định Công" },
  { value: "4", label: "Phường Mai Động" },
  { value: "5", label: "Phường Tương Mai" },
  { value: "6", label: "Phường Đại Kim" },
  { value: "7", label: "Phường Tân Mai" },
  { value: "8", label: "Phường Hoàng Văn Thụ" },
  { value: "9", label: "Phường Giáp Bát" },
  { value: "10", label: "Phường Lĩnh Nam" },
  { value: "11", label: "Phường Thịnh Liệt" },
  { value: "12", label: "Phường Trần Phú" },
  { value: "13", label: "Phường Hoàng Liệt" },
  { value: "14", label: "Phường Yên Sở" },
];
export const hanoi_quanthanhxuan = [
  { value: "1", label: "Phường Nhân Chính" },
  { value: "2", label: "Phường Thượng Đình" },
  { value: "3", label: "Phường Khương Trung" },
  { value: "4", label: "Phường Khương Mai" },
  { value: "5", label: "Phường Thanh Xuân Trung" },
  { value: "6", label: "Phường Phương Liệt" },
  { value: "7", label: "Phường Hạ Đình" },
  { value: "8", label: "Phường Khương Đình" },
  { value: "9", label: "Phường Thanh Xuân Bắc" },
  { value: "10", label: "Phường Thanh Xuân Nam" },
  { value: "11", label: "Phường Kim Giang" },
];
export const hanoi_huyensocson = [
  { value: "1", label: "Thị trấn Sóc Sơn" },
  { value: "2", label: "Xã Bắc Sơn" },
  { value: "3", label: "Xã Minh Trí" },
  { value: "4", label: "Xã Hồng Kỳ" },
  { value: "5", label: "Xã Nam Sơn" },
  { value: "6", label: "Xã Trung Giã" },
  { value: "7", label: "Xã Tân Hưng" },
  { value: "8", label: "Xã Minh Phú" },
  { value: "9", label: "Xã Phù Linh" },
  { value: "10", label: "Xã Bắc Phú" },
  { value: "11", label: "Xã Tân Minh" },
  { value: "12", label: "Xã Quang Tiến" },
  { value: "13", label: "Xã Hiền Ninh" },
  { value: "14", label: "Xã Tân Dân" },
  { value: "15", label: "Xã Tiên Dược" },
  { value: "16", label: "Xã Việt Long" },
  { value: "17", label: "Xã Xuân Giang" },
  { value: "18", label: "Xã Mai Đình" },
  { value: "19", label: "Xã Đức Hòa" },
  { value: "20", label: "Xã Thanh Xuân" },
  { value: "21", label: "Xã Đông Xuân" },
  { value: "22", label: "Xã Kim Lũ" },
  { value: "23", label: "Xã Phú Cường" },
  { value: "24", label: "Xã Phú Minh" },
  { value: "25", label: "Xã Phù Lỗ" },
  { value: "26", label: "Xã Xuân Thu" },
];
export const hanoi_huyendonganh = [
  { value: "1", label: "Thị trấn Đông Anh" },
  { value: "2", label: "Xã Xuân Nộn" },
  { value: "3", label: "Xã Thuy Lâm" },
  { value: "4", label: "Xã Bắc Hồng" },
  { value: "5", label: "Xã Nguyên Khê" },
  { value: "6", label: "Xã Nam Hồng" },
  { value: "7", label: "Xã Tiên Dương" },
  { value: "8", label: "Xã Vân Hà" },
  { value: "9", label: "Xã Uy Nỗ" },
  { value: "10", label: "Xã Vân Nội" },
  { value: "11", label: "Xã Liên Hà" },
  { value: "12", label: "Xã Việt Hùng" },
  { value: "13", label: "Xã Kim Nỗ" },
  { value: "14", label: "Xã Kim Chung" },
  { value: "15", label: "Xã Dục Tú" },
  { value: "16", label: "Xã Đại Mạch" },
  { value: "17", label: "Xã Vĩnh Ngọc" },
  { value: "18", label: "Xã Cổ Loa" },
  { value: "19", label: "Xã Hải Bối" },
  { value: "20", label: "Xã Xuân Canh" },
  { value: "21", label: "Xã Võng La" },
  { value: "22", label: "Xã Tầm Xá" },
  { value: "23", label: "Xã Mai Lâm" },
  { value: "24", label: "Xã Đông Hội" },
];
export const hanoi_huyengialam = [
  { value: "1", label: "Thị trấn Yên Viên" },
  { value: "2", label: "Xã Yên Thường" },
  { value: "3", label: "Xã Yên Viên" },
  { value: "4", label: "Xã Ninh Hiệp" },
  { value: "5", label: "Xã Đình Xuyên" },
  { value: "6", label: "Xã Dương Hà" },
  { value: "7", label: "Xã Phù Đổng" },
  { value: "8", label: "Xã Trung Mầu" },
  { value: "9", label: "Xã Lệ Chi" },
  { value: "10", label: "Xã Cổ Bi" },
  { value: "11", label: "Xã Đặng Xá" },
  { value: "12", label: "Xã Phú Thị" },
  { value: "13", label: "Xã Kim Sơn" },
  { value: "14", label: "Thị trấn Trâu Quỳ" },
  { value: "15", label: "Xã Dương Quang" },
  { value: "16", label: "Xã Dương Xá" },
  { value: "17", label: "Xã Đông Dư" },
  { value: "18", label: "Xã Đa Tốn" },
  { value: "19", label: "Xã Kiêu Ky" },
  { value: "20", label: "Xã Bát Tràng" },
  { value: "21", label: "Xã Kim Lan" },
  { value: "22", label: "Xã Văn Đức" },
];
export const hanoi_quannamtuliem = [
  { value: "1", label: "Phường Cầu Diễn" },
  { value: "2", label: "Phường Xuân Phương" },
  { value: "3", label: "Phường Phương Canh" },
  { value: "4", label: "Phường Mỹ Đình 1" },
  { value: "5", label: "Phường Mỹ Đình 2" },
  { value: "6", label: "Phường Tây Mỗ" },
  { value: "7", label: "Phường Mễ Trì" },
  { value: "8", label: "Phường Phú Đô" },
  { value: "9", label: "Phường Đại Mỗ" },
  { value: "10", label: "Phường Trung Văn" },
];
export const hanoi_huyenthanhtri = [
  { value: "1", label: "Thị trấn Văn Điển" },
  { value: "2", label: "Xã Tân Triều" },
  { value: "3", label: "Xã Thanh Liệt" },
  { value: "4", label: "Xã Tả Thanh Oai" },
  { value: "5", label: "Xã Hữu Hòa" },
  { value: "6", label: "Xã Tam Hiệp" },
  { value: "7", label: "Xã Tứ Hiệp" },
  { value: "8", label: "Xã Yên Mỹ" },
  { value: "9", label: "Xã Vĩnh Quỳnh" },
  { value: "10", label: "Xã Ngũ Hiệp" },
  { value: "11", label: "Xã Duyên Hà" },
  { value: "12", label: "Xã Ngọc Hồi" },
  { value: "13", label: "Xã Vạn Phúc" },
  { value: "14", label: "Xã Đại áng" },
  { value: "15", label: "Xã Liên Ninh" },
  { value: "16", label: "Xã Đông Mỹ" },
];
export const hanoi_quanbactuliem = [
  { value: "1", label: "Phường Thượng Cát" },
  { value: "2", label: "Phường Liên Mạc" },
  { value: "3", label: "Phường Đông Ngạc" },
  { value: "4", label: "Phường Đức Thắng" },
  { value: "5", label: "Phường Thụy Phương" },
  { value: "6", label: "Phường Tây Tựu" },
  { value: "7", label: "Phường Xuân Đỉnh" },
  { value: "8", label: "Phường Xuân Tảo" },
  { value: "9", label: "Phường Minh Khai" },
  { value: "10", label: "Phường Cổ Nhuế 1" },
  { value: "11", label: "Phường Cổ Nhuế 2" },
  { value: "12", label: "Phường Phú Diễn" },
  { value: "13", label: "Phường Phúc Diễn" },
];
export const hanoi_huyenmelinh = [
  { value: "1", label: "Thị trấn Chi Đông" },
  { value: "2", label: "Xã Đại Thịnh" },
  { value: "3", label: "Xã Kim Hoa" },
  { value: "4", label: "Xã Thạch Đà" },
  { value: "5", label: "Xã Tiến Thắng" },
  { value: "6", label: "Xã Tự Lập" },
  { value: "7", label: "Thị trấn Quang Minh" },
  { value: "8", label: "Xã Thanh Lâm" },
  { value: "9", label: "Xã Tam Đồng" },
  { value: "10", label: "Xã Liên Mạc" },
  { value: "11", label: "Xã Vạn Yên" },
  { value: "12", label: "Xã Chu Phan" },
  { value: "13", label: "Xã Tiến Thịnh" },
  { value: "14", label: "Xã Mê Linh" },
  { value: "15", label: "Xã Văn Khê" },
  { value: "16", label: "Xã Hoàng Kim" },
  { value: "17", label: "Xã Tiền Phong" },
  { value: "18", label: "Xã Tráng Việt" },
];
export const hanoi_quanhadong = [
  { value: "1", label: "Phường Nguyễn Trãi" },
  { value: "2", label: "Phường Mộ Lao" },
  { value: "3", label: "Phường Văn Quán" },
  { value: "4", label: "Phường Vạn Phúc" },
  { value: "5", label: "Phường Yết Kiêu" },
  { value: "6", label: "Phường Quang Trung" },
  { value: "7", label: "Phường La Khê" },
  { value: "8", label: "Phường Phú La" },
  { value: "9", label: "Phường Phúc La" },
  { value: "10", label: "Phường Hà Cầu" },
  { value: "11", label: "Phường Yên Nghĩa" },
  { value: "12", label: "Phường Kiến Hưng" },
  { value: "13", label: "Phường Phú Lãm" },
  { value: "14", label: "Phường Phú Lương" },
  { value: "15", label: "Phường Dương Nội" },
  { value: "16", label: "Phường Đồng Mai" },
  { value: "17", label: "Phường Biên Giang" },
];
export const hanoi_xasontay = [
  { value: "1", label: "Phường Lê Lợi" },
  { value: "2", label: "Phường Phú Thịnh" },
  { value: "3", label: "Phường Ngô Quyền" },
  { value: "4", label: "Phường Quang Trung" },
  { value: "5", label: "Phường Sơn Lộc" },
  { value: "6", label: "Phường Xuân Khanh" },
  { value: "7", label: "Xã Đường Lâm" },
  { value: "8", label: "Phường Viên Sơn" },
  { value: "9", label: "Xã Xuân Sơn" },
  { value: "10", label: "Phường Trung Hưng" },
  { value: "11", label: "Xã Thanh Mỹ" },
  { value: "12", label: "Phường Trung Sơn Trầm" },
  { value: "13", label: "Xã Kim Sơn" },
  { value: "14", label: "Xã Sơn Đông" },
  { value: "15", label: "Xã Cổ Đông" },
];
export const hanoi_huyenbavi = [
  { value: "1", label: "Thị trấn Tây Đằng" },
  { value: "2", label: "Xã Phú Cường" },
  { value: "3", label: "Xã Cổ Đô" },
  { value: "4", label: "Xã Tản Hồng" },
  { value: "5", label: "Xã Vạn Thắng" },
  { value: "6", label: "Xã Châu Sơn" },
  { value: "7", label: "Xã Phong Vân" },
  { value: "8", label: "Xã Phú Đông" },
  { value: "9", label: "Xã Phú Phương" },
  { value: "10", label: "Xã Phú Châu" },
  { value: "11", label: "Xã Thái Hòa" },
  { value: "12", label: "Xã Đồng Thái" },
  { value: "13", label: "Xã Phú Sơn" },
  { value: "14", label: "Xã Minh Châu" },
  { value: "15", label: "Xã Vật Lại" },
  { value: "16", label: "Xã Chu Minh" },
  { value: "17", label: "Xã Tòng Bạt" },
  { value: "18", label: "Xã Cẩm Lĩnh" },
  { value: "19", label: "Xã Sơn Đà" },
  { value: "20", label: "Xã Đông Quang" },
  { value: "21", label: "Xã Tiên Phong" },
  { value: "22", label: "Xã Thụy An" },
  { value: "23", label: "Xã Cam Thượng" },
  { value: "24", label: "Xã Thuần Mỹ" },
  { value: "25", label: "Xã Tản Lĩnh" },
  { value: "26", label: "Xã Ba Trại" },
  { value: "27", label: "Xã Minh Quang" },
  { value: "28", label: "Xã Ba Vì" },
  { value: "29", label: "Xã Vân Hòa" },
  { value: "30", label: "Xã Yên Bài" },
  { value: "31", label: "Xã Khánh Thượng" },
];
export const hanoi_huyenphuctho = [
  { value: "1", label: "Thị trấn Phúc Thọ" },
  { value: "2", label: "Xã Vân Hà" },
  { value: "3", label: "Xã Vân Phúc" },
  { value: "4", label: "Xã Vân Nam" },
  { value: "5", label: "Xã Xuân Phú" },
  { value: "6", label: "Xã Phương Độ" },
  { value: "7", label: "Xã Sen Chiểu" },
  { value: "8", label: "Xã Cẩm Đình" },
  { value: "9", label: "Xã Võng Xuyên" },
  { value: "10", label: "Xã Thọ Lộc" },
  { value: "11", label: "Xã Long Xuyên" },
  { value: "12", label: "Xã Thượng Cốc" },
  { value: "13", label: "Xã Hát Môn" },
  { value: "14", label: "Xã Tích Giang" },
  { value: "15", label: "Xã Thanh Đa" },
  { value: "16", label: "Xã Trạch Mỹ Lộc" },
  { value: "17", label: "Xã Phúc Hòa" },
  { value: "18", label: "Xã Ngọc Tảo" },
  { value: "19", label: "Xã Phụng Thượng" },
  { value: "20", label: "Xã Tam Thuấn" },
  { value: "21", label: "Xã Tam Hiệp" },
  { value: "22", label: "Xã Hiệp Thuận" },
  { value: "23", label: "Xã Liên Hiệp" },
];
export const hanoi_huyendanphuong = [
  { value: "1", label: "Thị trấn Phùng" },
  { value: "2", label: "Xã Trung Châu" },
  { value: "3", label: "Xã Thọ An" },
  { value: "4", label: "Xã Thọ Xuân" },
  { value: "5", label: "Xã Hồng Hà" },
  { value: "6", label: "Xã Liên Hồng" },
  { value: "7", label: "Xã Liên Hà" },
  { value: "8", label: "Xã Hạ Mỗ" },
  { value: "9", label: "Xã Liên Trung" },
  { value: "10", label: "Xã Phương Đình" },
  { value: "11", label: "Xã Thượng Mỗ" },
  { value: "12", label: "Xã Tân Hội" },
  { value: "13", label: "Xã Tân Lập" },
  { value: "14", label: "Xã Đan Phượng" },
  { value: "15", label: "Xã Đồng Tháp" },
  { value: "16", label: "Xã Song Phượng" },
];
export const hanoi_huyenhoaiduc = [
  { value: "1", label: "Thị trấn Trạm Trôi" },
  { value: "2", label: "Xã Đức Thượng" },
  { value: "3", label: "Xã Minh Khai" },
  { value: "4", label: "Xã Dương Liễu" },
  { value: "5", label: "Xã Di Trạch" },
  { value: "6", label: "Xã Đức Giang" },
  { value: "7", label: "Xã Cát Quế" },
  { value: "8", label: "Xã Kim Chung" },
  { value: "9", label: "Xã Yên Sở" },
  { value: "10", label: "Xã Sơn Đồng" },
  { value: "11", label: "Xã Vân Canh" },
  { value: "12", label: "Xã Đắc Sở" },
  { value: "13", label: "Xã Lại Yên" },
  { value: "14", label: "Xã Tiền Yên" },
  { value: "15", label: "Xã Song Phương" },
  { value: "16", label: "Xã An Khánh" },
  { value: "17", label: "Xã An Thượng" },
  { value: "18", label: "Xã Vân Côn" },
  { value: "19", label: "Xã La Phù" },
  { value: "20", label: "Xã Đông La" },
];
export const hanoi_huyenquocoai = [
  { value: "1", label: "Xã Đông Xuân" },
  { value: "2", label: "Thị trấn Quốc Oai" },
  { value: "3", label: "Xã Sài Sơn" },
  { value: "4", label: "Xã Phượng Cách" },
  { value: "5", label: "Xã Yên Sơn" },
  { value: "6", label: "Xã Ngọc Liệp" },
  { value: "7", label: "Xã Ngọc Mỹ" },
  { value: "8", label: "Xã Liệp Tuyết" },
  { value: "9", label: "Xã Thạch Thán" },
  { value: "10", label: "Xã Đồng Quang" },
  { value: "11", label: "Xã Phú Cát" },
  { value: "12", label: "Xã Tuyết Nghĩa" },
  { value: "13", label: "Xã Nghĩa Hương" },
  { value: "14", label: "Xã Cộng Hòa" },
  { value: "15", label: "Xã Tân Phú" },
  { value: "16", label: "Xã Đại Thành" },
  { value: "17", label: "Xã Phú Mãn" },
  { value: "18", label: "Xã Cấn Hữu" },
  { value: "19", label: "Xã Tân Hòa" },
  { value: "20", label: "Xã Hòa Thạch" },
  { value: "21", label: "Xã Đông Yên" },
];
export const hanoi_huyenthachthat = [
  { value: "1", label: "Xã Yên Trung" },
  { value: "2", label: "Xã Yên Bình" },
  { value: "3", label: "Xã Tiến Xuân" },
  { value: "4", label: "Thị trấn Liên Quan" },
  { value: "5", label: "Xã Đại Đồng" },
  { value: "6", label: "Xã Cẩm Yên" },
  { value: "7", label: "Xã Lại Thượng" },
  { value: "8", label: "Xã Phú Kim" },
  { value: "9", label: "Xã Hương Ngải" },
  { value: "10", label: "Xã Canh Nậu" },
  { value: "11", label: "Xã Kim Quan" },
  { value: "12", label: "Xã Dị Nậu" },
  { value: "13", label: "Xã Bình Yên" },
  { value: "14", label: "Xã Chàng Sơn" },
  { value: "15", label: "Xã Thạch Hòa" },
  { value: "16", label: "Xã Cần Kiệm" },
  { value: "17", label: "Xã Hữu Bằng" },
  { value: "18", label: "Xã Phùng Xá" },
  { value: "19", label: "Xã Tân Xã" },
  { value: "20", label: "Xã Thạch Xá" },
  { value: "21", label: "Xã Bình Phú" },
  { value: "22", label: "Xã Hạ Bằng" },
  { value: "23", label: "Xã Đồng Trúc" },
];
export const hanoi_huyenchuongmy = [
  { value: "1", label: "Thị trấn Chúc Sơn" },
  { value: "2", label: "Thị trấn Xuân Mai" },
  { value: "3", label: "Xã Phụng Châu" },
  { value: "4", label: "Xã Tiên Phương" },
  { value: "5", label: "Xã Đông Sơn" },
  { value: "6", label: "Xã Đông Phương Yên" },
  { value: "7", label: "Xã Phú Nghĩa" },
  { value: "8", label: "Xã Trường Yên" },
  { value: "9", label: "Xã Ngọc Hòa" },
  { value: "10", label: "Xã Thủy Xuân Tiên" },
  { value: "11", label: "Xã Thanh Bình" },
  { value: "12", label: "Xã Trung Hòa" },
  { value: "13", label: "Xã Đại Yên" },
  { value: "14", label: "Xã Thụy Hương" },
  { value: "15", label: "Xã Tốt Động" },
  { value: "16", label: "Xã Lam Điền" },
  { value: "17", label: "Xã Tân Tiến" },
  { value: "18", label: "Xã Nam Phương Tiến" },
  { value: "19", label: "Xã Hợp Đồng" },
  { value: "20", label: "Xã Hoàng Văn Thụ" },
  { value: "21", label: "Xã Hoàng Diệu" },
  { value: "22", label: "Xã Hữu Văn" },
  { value: "23", label: "Xã Quảng Bị" },
  { value: "24", label: "Xã Mỹ Lương" },
  { value: "25", label: "Xã Thượng Vực" },
  { value: "26", label: "Xã Hồng Phong" },
  { value: "27", label: "Xã Đồng Phú" },
  { value: "28", label: "Xã Trần Phú" },
  { value: "29", label: "Xã Văn Võ" },
  { value: "30", label: "Xã Đồng Lạc" },
  { value: "31", label: "Xã Hòa Chính" },
  { value: "32", label: "Xã Phú Nam An" },
];
export const hanoi_huyenthanhoai = [
  { value: "1", label: "Thị trấn Kim Bài" },
  { value: "2", label: "Xã Cự Khê" },
  { value: "3", label: "Xã Bích Hòa" },
  { value: "4", label: "Xã Mỹ Hưng" },
  { value: "5", label: "Xã Cao Viên" },
  { value: "6", label: "Xã Bình Minh" },
  { value: "7", label: "Xã Tam Hưng" },
  { value: "8", label: "Xã Thanh Cao" },
  { value: "9", label: "Xã Thanh Thùy" },
  { value: "10", label: "Xã Thanh Mai" },
  { value: "11", label: "Xã Thanh Văn" },
  { value: "12", label: "Xã Đỗ Động" },
  { value: "13", label: "Xã Kim An" },
  { value: "14", label: "Xã Kim Thư" },
  { value: "15", label: "Xã Phương Trung" },
  { value: "16", label: "Xã Tân Ước" },
  { value: "17", label: "Xã Dân Hòa" },
  { value: "18", label: "Xã Liên Châu" },
  { value: "19", label: "Xã Cao Dương" },
  { value: "20", label: "Xã Xuân Dương" },
  { value: "21", label: "Xã Hồng Dương" },
];
export const hanoi_huyenthuongtin = [
  { value: "1", label: "Thị trấn Thường Tín" },
  { value: "2", label: "Xã Ninh Sở" },
  { value: "3", label: "Xã Nhị Khê" },
  { value: "4", label: "Xã Duyên Thái" },
  { value: "5", label: "Xã Khánh Hà" },
  { value: "6", label: "Xã Hòa Bình" },
  { value: "7", label: "Xã Văn Bình" },
  { value: "8", label: "Xã Hiền Giang" },
  { value: "9", label: "Xã Hồng Vân" },
  { value: "10", label: "Xã Vân Tảo" },
  { value: "11", label: "Xã Liên Phương" },
  { value: "12", label: "Xã Văn Phú" },
  { value: "13", label: "Xã Tự Nhiên" },
  { value: "14", label: "Xã Tiền Phong" },
  { value: "15", label: "Xã Hà Hồi" },
  { value: "16", label: "Xã Thư Phú" },
  { value: "17", label: "Xã Nguyễn Trãi" },
  { value: "18", label: "Xã Quất Động" },
  { value: "19", label: "Xã Chương Dương" },
  { value: "20", label: "Xã Tân Minh" },
  { value: "21", label: "Xã Lê Lợi" },
  { value: "22", label: "Xã Thắng Lợi" },
  { value: "23", label: "Xã Dũng Tiến" },
  { value: "24", label: "Xã Thống Nhất" },
  { value: "25", label: "Xã Nghiêm Xuyên" },
  { value: "26", label: "Xã Tô Hiệu" },
  { value: "27", label: "Xã Văn Tự" },
  { value: "28", label: "Xã Vạn Điểm" },
  { value: "29", label: "Xã Minh Cường" },
];
export const hanoi_huyenphuxuyen = [
  { value: "1", label: "Thị trấn Phú Minh" },
  { value: "2", label: "Thị trấn Phú Xuyên" },
  { value: "3", label: "Xã Hồng Minh" },
  { value: "4", label: "Xã Phượng Dực" },
  { value: "5", label: "Xã Văn Nhân" },
  { value: "6", label: "Xã Thụy Phú" },
  { value: "7", label: "Xã Tri Trung" },
  { value: "8", label: "Xã Đại Thắng" },
  { value: "9", label: "Xã Phú Túc" },
  { value: "10", label: "Xã Văn Hoàng" },
  { value: "11", label: "Xã Hồng Thái" },
  { value: "12", label: "Xã Hoàng Long" },
  { value: "13", label: "Xã Quang Trung" },
  { value: "14", label: "Xã Nam Phong" },
  { value: "15", label: "Xã Nam Triều" },
  { value: "16", label: "Xã Tân Dân" },
  { value: "17", label: "Xã Sơn Hà" },
  { value: "18", label: "Xã Chuyên Mỹ" },
  { value: "19", label: "Xã Khai Thái" },
  { value: "20", label: "Xã Phúc Tiến" },
  { value: "21", label: "Xã Vân Từ" },
  { value: "22", label: "Xã Tri Thủy" },
  { value: "23", label: "Xã Đại Xuyên" },
  { value: "24", label: "Xã Phú Yên" },
  { value: "25", label: "Xã Bạch Hạ" },
  { value: "26", label: "Xã Quang Lãng" },
  { value: "27", label: "Xã Châu Can" },
  { value: "28", label: "Xã Minh Tân" },
];
export const hanoi_huyenunghoa = [
  { value: "1", label: "Thị trấn Vân Đình" },
  { value: "2", label: "Xã Viên An" },
  { value: "3", label: "Xã Viên Nội" },
  { value: "4", label: "Xã Hoa Sơn" },
  { value: "5", label: "Xã Quảng Phú Cầu" },
  { value: "6", label: "Xã Trường Thịnh" },
  { value: "7", label: "Xã Cao Thành" },
  { value: "8", label: "Xã Liên Bạt" },
  { value: "9", label: "Xã Sơn Công" },
  { value: "10", label: "Xã Đồng Tiến" },
  { value: "11", label: "Xã Phương Tú" },
  { value: "12", label: "Xã Trung Tú" },
  { value: "13", label: "Xã Đồng Tân" },
  { value: "14", label: "Xã Tảo Dương Văn" },
  { value: "15", label: "Xã Vạn Thái" },
  { value: "16", label: "Xã Minh Đức" },
  { value: "17", label: "Xã Hòa Lâm" },
  { value: "18", label: "Xã Hòa Xá" },
  { value: "19", label: "Xã Trầm Lộng" },
  { value: "20", label: "Xã Kim Đường" },
  { value: "21", label: "Xã Hòa Nam" },
  { value: "22", label: "Xã Hòa Phú" },
  { value: "23", label: "Xã Đội Bình" },
  { value: "24", label: "Xã Đại Hùng" },
  { value: "25", label: "Xã Đông Lỗ" },
  { value: "26", label: "Xã Phù Lưu" },
  { value: "27", label: "Xã Đại Cường" },
  { value: "28", label: "Xã Lưu Hoàng" },
  { value: "29", label: "Xã Hồng Quang" },
];
export const hanoi_huyenmyduc = [
  { value: "1", label: "Thị trấn Đại Nghĩa" },
  { value: "2", label: "Xã Đồng Tâm" },
  { value: "3", label: "Xã Thượng Lâm" },
  { value: "4", label: "Xã Tuy Lai" },
  { value: "5", label: "Xã Phúc Lâm" },
  { value: "6", label: "Xã Mỹ Thành" },
  { value: "7", label: "Xã Bột Xuyên" },
  { value: "8", label: "Xã An Mỹ" },
  { value: "9", label: "Xã Hồng Sơn" },
  { value: "10", label: "Xã Lê Thanh" },
  { value: "11", label: "Xã Xuy Xá" },
  { value: "12", label: "Xã Phùng Xá" },
  { value: "13", label: "Xã Phù Lưu Tế" },
  { value: "14", label: "Xã Đại Hưng" },
  { value: "15", label: "Xã Vạn Kim" },
  { value: "16", label: "Xã Đốc Tín" },
  { value: "17", label: "Xã Hương Sơn" },
  { value: "18", label: "Xã Hùng Tiến" },
  { value: "19", label: "Xã An Tiến" },
  { value: "20", label: "Xã Hợp Tiến" },
  { value: "21", label: "Xã Hợp Thanh" },
  { value: "22", label: "Xã An Phú" },
];
export const danang_quanlienchieu = [
  { value: "1", label: "Phường Hòa Hiệp Bắc" },
  { value: "2", label: "Phường Hòa Hiệp Nam" },
  { value: "3", label: "Phường Hòa Khánh Bắc" },
  { value: "4", label: "Phường Hòa Khánh Nam" },
  { value: "5", label: "Phường Hòa Minh" },
];
export const danang_quanthanhkhe = [
  { value: "1", label: "Phường Tam Thuận" },
  { value: "2", label: "Phường Thanh Khê Tây" },
  { value: "3", label: "Phường Thanh Khê Đông" },
  { value: "4", label: "Phường Xuân Hà" },
  { value: "5", label: "Phường Tân Chính" },
  { value: "6", label: "Phường Chính Gián" },
  { value: "7", label: "Phường Vĩnh Trung" },
  { value: "8", label: "Phường Thạc Gián" },
  { value: "9", label: "Phường An Khê" },
  { value: "10", label: "Phường Hòa Khê" },
];
export const danang_quanhaichau = [
  { value: "1", label: "Phường Thanh Bình" },
  { value: "2", label: "Phường Thuận Phước" },
  { value: "3", label: "Phường Thạch Thang" },
  { value: "4", label: "Phường Hải Châu I" },
  { value: "5", label: "Phường Hải Châu II" },
  { value: "6", label: "Phường Phước Ninh" },
  { value: "7", label: "Phường Hòa Thuận Tây" },
  { value: "8", label: "Phường Hòa Thuận Đông" },
  { value: "9", label: "Phường Nam Dương" },
  { value: "10", label: "Phường Bình Hiên" },
  { value: "11", label: "Phường Bình Thuận" },
  { value: "12", label: "Phường Hòa Cường Bắc" },
  { value: "13", label: "Phường Hòa Cường Nam" },
];
export const danang_quansontra = [
  { value: "1", label: "Phường Thọ Quang" },
  { value: "2", label: "Phường Nại Hiên Đông" },
  { value: "3", label: "Phường Mân Thái" },
  { value: "4", label: "Phường An Hải Bắc" },
  { value: "5", label: "Phường Phước Mỹ" },
  { value: "6", label: "Phường An Hải Tây" },
  { value: "7", label: "Phường An Hải Đông" },
];
export const danang_quannguhanhson = [
  { value: "1", label: "Phường Mỹ An" },
  { value: "2", label: "Phường Khuê Mỹ" },
  { value: "3", label: "Phường Hòa Quý" },
  { value: "4", label: "Phường Hòa Hải" },
];
export const danang_quancamle = [
  { value: "1", label: "Phường Khuê Trung" },
  { value: "2", label: "Phường Hòa Phát" },
  { value: "3", label: "Phường Hòa An" },
  { value: "4", label: "Phường Hòa Thọ Tây" },
  { value: "5", label: "Phường Hòa Thọ Đông" },
  { value: "6", label: "Phường Hòa Xuân" },
];
export const danang_huyenhoavang = [
  { value: "1", label: "Xã Hòa Bắc" },
  { value: "2", label: "Xã Hòa Liên" },
  { value: "3", label: "Xã Hòa Ninh" },
  { value: "4", label: "Xã Hòa Sơn" },
  { value: "5", label: "Xã Hòa Nhơn" },
  { value: "6", label: "Xã Hòa Phú" },
  { value: "7", label: "Xã Hòa Phong" },
  { value: "8", label: "Xã Hòa Châu" },
  { value: "9", label: "Xã Hòa Tiến" },
  { value: "10", label: "Xã Hòa Phước" },
  { value: "11", label: "Xã Hòa Khương" },
];

export const hagiang_thanhphohagiang = [
  { value: "1", label: "Phường Quang Trung" },
  { value: "2", label: "Phường Trần Phú" },
  { value: "3", label: "Phường Ngọc Hà" },
  { value: "4", label: "Phường Nguyễn Trãi" },
  { value: "5", label: "Phường Minh Khai" },
  { value: "6", label: "Xã Ngọc Đường" },
  { value: "7", label: "Xã Phương Độ" },
  { value: "8", label: "Xã Phương Thiện" },
];
export const hagiang_huyendongvan = [
  { value: "1", label: "Thị trấn Phó Bảng" },
  { value: "2", label: "Xã Lũng Cú" },
  { value: "3", label: "Xã Má Lé" },
  { value: "4", label: "Thị trấn Đồng Văn" },
  { value: "5", label: "Xã Lũng Táo" },
  { value: "6", label: "Xã Phố Là" },
  { value: "7", label: "Xã Thài Phìn Tủng" },
  { value: "8", label: "Xã Sủng Là" },
  { value: "9", label: "Xã Xà Phìn" },
  { value: "10", label: "Xã Tả Phìn" },
  { value: "11", label: "Xã Tả Lủng" },
  { value: "12", label: "Xã Phố Cáo" },
  { value: "13", label: "Xã Sính Lủng" },
  { value: "14", label: "Xã Sảng Tủng" },
  { value: "15", label: "Xã Lũng Thầu" },
  { value: "16", label: "Xã Hố Quáng Phìn" },
  { value: "17", label: "Xã Vần Chải" },
  { value: "18", label: "Xã Lũng Phìn" },
  { value: "19", label: "Xã Sủng Trái" },
];
export const hagiang_huyenmeovac = [
  { value: "1", label: "Thị trấn Mèo Vạc" },
  { value: "2", label: "Xã Thượng Phùng" },
  { value: "3", label: "Xã Pải Lủng" },
  { value: "4", label: "Xã Xín Cái" },
  { value: "5", label: "Xã Pả Vi" },
  { value: "6", label: "Xã Giàng Chu Phìn" },
  { value: "7", label: "Xã Sủng Trà" },
  { value: "8", label: "Xã Sủng Máng" },
  { value: "9", label: "Xã Sơn Vĩ" },
  { value: "10", label: "Xã Tả Lủng" },
  { value: "11", label: "Xã Cán Chu Phìn" },
  { value: "12", label: "Xã Lũng Pù" },
  { value: "13", label: "Xã Lũng Chinh" },
  { value: "14", label: "Xã Tát Ngà" },
  { value: "15", label: "Xã Nậm Ban" },
  { value: "16", label: "Xã Khâu Vai" },
  { value: "17", label: "Xã Niêm Tòng" },
  { value: "18", label: "Xã Niêm Sơn" },
];
export const hagiang_huyenyenminh = [
  { value: "1", label: "Thị trấn Yên Minh" },
  { value: "2", label: "Xã Thắng Mố" },
  { value: "3", label: "Xã Phú Lũng" },
  { value: "4", label: "Xã Sủng Tráng" },
  { value: "5", label: "Xã Bạch Đích" },
  { value: "6", label: "Xã Na Khê" },
  { value: "7", label: "Xã Sủng Thài" },
  { value: "8", label: "Xã Hữu Vinh" },
  { value: "9", label: "Xã Lao Và Chải" },
  { value: "10", label: "Xã Mậu Duệ" },
  { value: "11", label: "Xã Đông Minh" },
  { value: "12", label: "Xã Mậu Long" },
  { value: "13", label: "Xã Ngam La" },
  { value: "14", label: "Xã Ngọc Long" },
  { value: "15", label: "Xã Đường Thượng" },
  { value: "16", label: "Xã Lũng Hồ" },
  { value: "17", label: "Xã Du Tiến" },
  { value: "18", label: "Xã Du Già" },
];
export const hagiang_huyenquanba = [
  { value: "1", label: "Thị trấn Tam Sơn" },
  { value: "2", label: "Xã Bát Đại Sơn" },
  { value: "3", label: "Xã Nghĩa Thuận" },
  { value: "4", label: "Xã Cán Tỷ" },
  { value: "5", label: "Xã Cao Mã Pờ" },
  { value: "6", label: "Xã Thanh Vân" },
  { value: "7", label: "Xã Tùng Vài" },
  { value: "8", label: "Xã Đông Hà" },
  { value: "9", label: "Xã Quản Bạ" },
  { value: "10", label: "Xã Lùng Tám" },
  { value: "11", label: "Xã Quyết Tiến" },
  { value: "12", label: "Xã Tả Ván" },
  { value: "13", label: "Xã Thái An" },
];
export const hagiang_huyenvixuyen = [
  { value: "1", label: "Xã Kim Thạch" },
  { value: "2", label: "Xã Phú Linh" },
  { value: "3", label: "Xã Kim Linh" },
  { value: "4", label: "Thị trấn Vị Xuyên" },
  { value: "5", label: "Thị trấn Nông Trường Việt Lâm" },
  { value: "6", label: "Xã Minh Tân" },
  { value: "7", label: "Xã Thuận Hòa" },
  { value: "8", label: "Xã Tùng Bá" },
  { value: "9", label: "Xã Thanh Thủy" },
  { value: "10", label: "Xã Thanh Đức" },
  { value: "11", label: "Xã Phong Quang" },
  { value: "12", label: "Xã Xín Chải" },
  { value: "13", label: "Xã Phương Tiến" },
  { value: "14", label: "Xã Lao Chải" },
  { value: "15", label: "Xã Cao Bồ" },
  { value: "16", label: "Xã Đạo Đức" },
  { value: "17", label: "Xã Thượng Sơn" },
  { value: "18", label: "Xã Linh Hồ" },
  { value: "19", label: "Xã Quảng Ngần" },
  { value: "20", label: "Xã Việt Lâm" },
  { value: "21", label: "Xã Ngọc Linh" },
  { value: "22", label: "Xã Ngọc Minh" },
  { value: "23", label: "Xã Bạch Ngọc" },
  { value: "24", label: "Xã Trung Thành" },
];
export const hagiang_huyenbacme = [
  { value: "1", label: "Xã Minh Sơn" },
  { value: "2", label: "Xã Giáp Trung" },
  { value: "3", label: "Xã Yên Định" },
  { value: "4", label: "Thị trấn Yên Phú" },
  { value: "5", label: "Xã Minh Ngọc" },
  { value: "6", label: "Xã Yên Phong" },
  { value: "7", label: "Xã Lạc Nông" },
  { value: "8", label: "Xã Phú Nam" },
  { value: "9", label: "Xã Yên Cường" },
  { value: "10", label: "Xã Thượng Tân" },
  { value: "11", label: "Xã Đường Âm" },
  { value: "12", label: "Xã Đường Hồng" },
  { value: "13", label: "Xã Phiêng Luông" },
];
export const hagiang_huyenhoangsuphi = [
  { value: "1", label: "Thị trấn Vinh Quang" },
  { value: "2", label: "Xã Bản Máy" },
  { value: "3", label: "Xã Thàng Tín" },
  { value: "4", label: "Xã Thèn Chu Phìn" },
  { value: "5", label: "Xã Pố Lồ" },
  { value: "6", label: "Xã Bản Phùng" },
  { value: "7", label: "Xã Túng Sán" },
  { value: "8", label: "Xã Chiến Phố" },
  { value: "9", label: "Xã Đản Ván" },
  { value: "10", label: "Xã Tụ Nhân" },
  { value: "11", label: "Xã Tân Tiến" },
  { value: "12", label: "Xã Nàng Đôn" },
  { value: "13", label: "Xã Pờ Ly Ngài" },
  { value: "14", label: "Xã Sán Xả Hồ" },
  { value: "15", label: "Xã Bản Luốc" },
  { value: "16", label: "Xã Ngàm Đăng Vài" },
  { value: "17", label: "Xã Bản Nhùng" },
  { value: "18", label: "Xã Tả Sử Choóng" },
  { value: "19", label: "Xã Nậm Dịch" },
  { value: "20", label: "Xã Bản Péo" },
  { value: "21", label: "Xã Hồ Thầu" },
  { value: "22", label: "Xã Nam Sơn" },
  { value: "23", label: "Xã Nậm Ty" },
  { value: "24", label: "Xã Thông Nguyên" },
  { value: "25", label: "Xã Nậm Khòa" },
];
export const hagiang_huyenxinman = [
  { value: "1", label: "Thị trấn Cốc Pài" },
  { value: "2", label: "Xã Nàn Xỉn" },
  { value: "3", label: "Xã Bản Díu" },
  { value: "4", label: "Xã Chí Cà" },
  { value: "5", label: "Xã Xín Mần" },
  { value: "6", label: "Xã Trung Thịnh" },
  { value: "7", label: "Xã Thèn Phàng" },
  { value: "8", label: "Xã Ngán Chiên" },
  { value: "9", label: "Xã Pà Vầy Sủ" },
  { value: "10", label: "Xã Cốc Rế" },
  { value: "11", label: "Xã Thu Tà" },
  { value: "12", label: "Xã Nàn Ma" },
  { value: "13", label: "Xã Tả Nhìu" },
  { value: "14", label: "Xã Bản Ngò" },
  { value: "15", label: "Xã Chế Là" },
  { value: "16", label: "Xã Nấm Dẩn" },
  { value: "17", label: "Xã Quảng Nguyên" },
  { value: "18", label: "Xã Nà Chì" },
  { value: "19", label: "Xã Khuôn Lùng" },
];
export const hagiang_huyenbacquang = [
  { value: "1", label: "Thị trấn Việt Quang" },
  { value: "2", label: "Thị trấn Vĩnh Tuy" },
  { value: "3", label: "Xã Tân Lập" },
  { value: "4", label: "Xã Tân Thành" },
  { value: "5", label: "Xã Đồng Tiến" },
  { value: "6", label: "Xã Đồng Tâm" },
  { value: "7", label: "Xã Tân Quang" },
  { value: "8", label: "Xã Thượng Bình" },
  { value: "9", label: "Xã Hữu Sản" },
  { value: "10", label: "Xã Kim Ngọc" },
  { value: "11", label: "Xã Việt Vinh" },
  { value: "12", label: "Xã Bằng Hành" },
  { value: "13", label: "Xã Quang Minh" },
  { value: "14", label: "Xã Liên Hiệp" },
  { value: "15", label: "Xã Vô Điếm" },
  { value: "16", label: "Xã Việt Hồng" },
  { value: "17", label: "Xã Hùng An" },
  { value: "18", label: "Xã Đức Xuân" },
  { value: "19", label: "Xã Tiên Kiều" },
  { value: "20", label: "Xã Vĩnh Hảo" },
  { value: "21", label: "Xã Vĩnh Phúc" },
  { value: "22", label: "Xã Đồng Yên" },
  { value: "23", label: "Xã Đông Thành" },
];
export const hagiang_huyenquangbinh = [
  { value: "1", label: "Xã Xuân Minh" },
  { value: "2", label: "Xã Tiên Nguyên" },
  { value: "3", label: "Xã Tân Nam" },
  { value: "4", label: "Xã Bản Rịa" },
  { value: "5", label: "Xã Yên Thành" },
  { value: "6", label: "Thị trấn Yên Bình" },
  { value: "7", label: "Xã Tân Trịnh" },
  { value: "8", label: "Xã Tân Bắc" },
  { value: "9", label: "Xã Bằng Lang" },
  { value: "10", label: "Xã Yên Hà" },
  { value: "11", label: "Xã Hương Sơn" },
  { value: "12", label: "Xã Xuân Giang" },
  { value: "13", label: "Xã Nà Khương" },
  { value: "14", label: "Xã Tiên Yên" },
  { value: "15", label: "Xã Vĩ Thượng" },
];

export const caobang_thanhphocaobang = [
  { value: "1", label: "Phường Sông Hiến" },
  { value: "2", label: "Phường Sông Bằng" },
  { value: "3", label: "Phường Hợp Giang" },
  { value: "4", label: "Phường Tân Giang" },
  { value: "5", label: "Phường Ngọc Xuân" },
  { value: "6", label: "Phường Đề Thám" },
  { value: "7", label: "Phường Hòa Chung" },
  { value: "8", label: "Phường Duyệt Trung" },
  { value: "9", label: "Xã Vĩnh Quang" },
  { value: "10", label: "Xã Hưng Đạo" },
  { value: "11", label: "Xã Chu Trinh" },
];
export const caobang_huyenbaolam = [
  { value: "1", label: "Thị trấn Pác Miầu" },
  { value: "2", label: "Xã Đức Hạnh" },
  { value: "3", label: "Xã Lý Bôn" },
  { value: "4", label: "Xã Nam Cao" },
  { value: "5", label: "Xã Nam Quang" },
  { value: "6", label: "Xã Vĩnh Quang" },
  { value: "7", label: "Xã Quảng Lâm" },
  { value: "8", label: "Xã Thạch Lâm" },
  { value: "9", label: "Xã Tân Việt" },
  { value: "10", label: "Xã Vĩnh Phong" },
  { value: "11", label: "Xã Mông Ân" },
  { value: "12", label: "Xã Thái Học" },
  { value: "13", label: "Xã Thái Sơn" },
  { value: "14", label: "Xã Yên Thổ" },
];
export const caobang_huyenbaolac = [
  { value: "1", label: "Thị trấn Bảo Lạc" },
  { value: "2", label: "Xã Cốc Pàng" },
  { value: "3", label: "Xã Thượng Hà" },
  { value: "4", label: "Xã Cô Ba" },
  { value: "5", label: "Xã Bảo Toàn" },
  { value: "6", label: "Xã Khánh Xuân" },
  { value: "7", label: "Xã Xuân Trường" },
  { value: "8", label: "Xã Hồng Trị" },
  { value: "9", label: "Xã Kim Cúc" },
  { value: "10", label: "Xã Phan Thanh" },
  { value: "11", label: "Xã Hồng An" },
  { value: "12", label: "Xã Hưng Đạo" },
  { value: "13", label: "Xã Hưng Thịnh" },
  { value: "14", label: "Xã Huy Giáp" },
  { value: "15", label: "Xã Đình Phùng" },
  { value: "16", label: "Xã Sơn Lập" },
  { value: "17", label: "Xã Sơn Lộ" },
];
export const caobang_huyenthongnong = [
  { value: "1", label: "Thị trấn Thông Nông" },
  { value: "2", label: "Xã Cần Yên" },
  { value: "3", label: "Xã Cần Nông" },
  { value: "4", label: "Xã Vị Quang" },
  { value: "5", label: "Xã Lương Thông" },
  { value: "6", label: "Xã Đa Thông" },
  { value: "7", label: "Xã Ngọc Động" },
  { value: "8", label: "Xã Yên Sơn" },
  { value: "9", label: "Xã Lương Can" },
  { value: "10", label: "Xã Thanh Long" },
  { value: "11", label: "Xã Bình Lãng" },
];
export const caobang_huyenhaquang = [
  { value: "1", label: "Thị trấn Xuân Hòa" },
  { value: "2", label: "Xã Lũng Nặm" },
  { value: "3", label: "Xã Kéo Yên" },
  { value: "4", label: "Xã Trường Hà" },
  { value: "5", label: "Xã Vân An" },
  { value: "6", label: "Xã Cải Viên" },
  { value: "7", label: "Xã Nà Sác" },
  { value: "8", label: "Xã Nội Thôn" },
  { value: "9", label: "Xã Tổng Cọt" },
  { value: "10", label: "Xã Sóc Hà" },
  { value: "11", label: "Xã Thượng Thôn" },
  { value: "12", label: "Xã Vần Dính" },
  { value: "13", label: "Xã Hồng Sĩ" },
  { value: "14", label: "Xã Sĩ Hai" },
  { value: "15", label: "Xã Quý Quân" },
  { value: "16", label: "Xã Mã Ba" },
  { value: "17", label: "Xã Phù Ngọc" },
  { value: "18", label: "Xã Đào Ngạn" },
  { value: "19", label: "Xã Hạ Thôn" },
];
export const caobang_huyentralinh = [
  { value: "1", label: "Thị trấn Hùng Quốc" },
  { value: "2", label: "Xã Cô Mười" },
  { value: "3", label: "Xã Tri Phương" },
  { value: "4", label: "Xã Quang Hán" },
  { value: "5", label: "Xã Quang Vinh" },
  { value: "6", label: "Xã Xuân Nội" },
  { value: "7", label: "Xã Quang Trung" },
  { value: "8", label: "Xã Lưu Ngọc" },
  { value: "9", label: "Xã Cao Chương" },
  { value: "10", label: "Xã Quốc Toản" },
];
export const caobang_huyentrungkhanh = [
  { value: "1", label: "Thị trấn Trùng Khánh" },
  { value: "2", label: "Xã Ngọc Khê" },
  { value: "3", label: "Xã Ngọc Côn" },
  { value: "4", label: "Xã Phong Nậm" },
  { value: "5", label: "Xã Ngọc Chung" },
  { value: "6", label: "Xã Đình Phong" },
  { value: "7", label: "Xã Lăng Yên" },
  { value: "8", label: "Xã Đàm Thuỷ" },
  { value: "9", label: "Xã Khâm Thành" },
  { value: "10", label: "Xã Chí Viễn" },
  { value: "11", label: "Xã Lăng Hiếu" },
  { value: "12", label: "Xã Phong Châu" },
  { value: "13", label: "Xã Đình Minh" },
  { value: "14", label: "Xã Cảnh Tiên" },
  { value: "15", label: "Xã Trung Phúc" },
  { value: "16", label: "Xã Cao Thăng" },
  { value: "17", label: "Xã Đức Hồng" },
  { value: "18", label: "Xã Thông Hoè" },
  { value: "19", label: "Xã Thân Giáp" },
  { value: "20", label: "Xã Đoài Côn" },
];
export const caobang_huyenhalang = [
  { value: "1", label: "Xã Minh Long" },
  { value: "2", label: "Xã Lý Quốc" },
  { value: "3", label: "Xã Thắng Lợi" },
  { value: "4", label: "Xã Đồng Loan" },
  { value: "5", label: "Xã Đức Quang" },
  { value: "6", label: "Xã Kim Loan" },
  { value: "7", label: "Xã Quang Long" },
  { value: "8", label: "Xã An Lạc" },
  { value: "9", label: "Thị trấn Thanh Nhật" },
  { value: "10", label: "Xã Vinh Quý" },
  { value: "11", label: "Xã Việt Chu" },
  { value: "12", label: "Xã Cô Ngân" },
  { value: "13", label: "Xã Thái Đức" },
  { value: "14", label: "Xã Thị Hoa" },
];
export const caobang_huyenquanguyen = [
  { value: "1", label: "Thị trấn Quảng Uyên" },
  { value: "2", label: "Xã Phi Hải" },
  { value: "3", label: "Xã Quảng Hưng" },
  { value: "4", label: "Xã Bình Lăng" },
  { value: "5", label: "Xã Quốc Dân" },
  { value: "6", label: "Xã Quốc Phong" },
  { value: "7", label: "Xã Độc Lập" },
  { value: "8", label: "Xã Cai Bộ" },
  { value: "9", label: "Xã Đoài Khôn" },
  { value: "10", label: "Xã Phúc Sen" },
  { value: "11", label: "Xã Chí Thảo" },
  { value: "12", label: "Xã Tự Do" },
  { value: "13", label: "Xã Hồng Định" },
  { value: "14", label: "Xã Hồng Quang" },
  { value: "15", label: "Xã Ngọc Động" },
  { value: "16", label: "Xã Hoàng Hải" },
  { value: "17", label: "Xã Hạnh Phúc" },
];
export const caobang_huyenphuchoa = [
  { value: "1", label: "Thị trấn Tà Lùng" },
  { value: "2", label: "Xã Triệu ẩu" },
  { value: "3", label: "Xã Hồng Đại" },
  { value: "4", label: "Xã Cách Linh" },
  { value: "5", label: "Xã Đại Sơn" },
  { value: "6", label: "Xã Lương Thiện" },
  { value: "7", label: "Xã Tiên Thành" },
  { value: "8", label: "Thị trấn Hòa Thuận" },
  { value: "9", label: "Xã Mỹ Hưng" },
];
export const caobang_huyenhoaan = [
  { value: "1", label: "Thị trấn Nước Hai" },
  { value: "2", label: "Xã Dân Chủ" },
  { value: "3", label: "Xã Nam Tuấn" },
  { value: "4", label: "Xã Đức Xuân" },
  { value: "5", label: "Xã Đại Tiến" },
  { value: "6", label: "Xã Đức Long" },
  { value: "7", label: "Xã Ngũ Lão" },
  { value: "8", label: "Xã Trương Lương" },
  { value: "9", label: "Xã Bình Long" },
  { value: "10", label: "Xã Nguyễn Huệ" },
  { value: "11", label: "Xã Công Trừng" },
  { value: "12", label: "Xã Hồng Việt" },
  { value: "13", label: "Xã Bế Triều" },
  { value: "14", label: "Xã Hoàng Tung" },
  { value: "15", label: "Xã Trương Vương" },
  { value: "16", label: "Xã Quang Trung" },
  { value: "17", label: "Xã Bạch Đằng" },
  { value: "18", label: "Xã Bình Dương" },
  { value: "19", label: "Xã Lê Chung" },
  { value: "20", label: "Xã Hà Trì" },
  { value: "21", label: "Xã Hồng Nam" },
];
export const caobang_huyennguyenbinh = [
  { value: "1", label: "Thị trấn Nguyên Bình" },
  { value: "2", label: "Thị trấn Tĩnh Túc" },
  { value: "3", label: "Xã Yên Lạc" },
  { value: "4", label: "Xã Triệu Nguyên" },
  { value: "5", label: "Xã Ca Thành" },
  { value: "6", label: "Xã Thái Học" },
  { value: "7", label: "Xã Vũ Nông" },
  { value: "8", label: "Xã Minh Tâm" },
  { value: "9", label: "Xã Thể Dục" },
  { value: "10", label: "Xã Bắc Hợp" },
  { value: "11", label: "Xã Mai Long" },
  { value: "12", label: "Xã Lang Môn" },
  { value: "13", label: "Xã Minh Thanh" },
  { value: "14", label: "Xã Hoa Thám" },
  { value: "15", label: "Xã Phan Thanh" },
  { value: "16", label: "Xã Quang Thành" },
  { value: "17", label: "Xã Tam Kim" },
  { value: "18", label: "Xã Thành Công" },
  { value: "19", label: "Xã Thịnh Vượng" },
  { value: "20", label: "Xã Hưng Đạo" },
];
export const caobang_huyenthachan = [
  { value: "1", label: "Thị trấn Đông Khê" },
  { value: "2", label: "Xã Canh Tân" },
  { value: "3", label: "Xã Kim Đồng" },
  { value: "4", label: "Xã Minh Khai" },
  { value: "5", label: "Xã Thị Ngân" },
  { value: "6", label: "Xã Đức Thông" },
  { value: "7", label: "Xã Thái Cường" },
  { value: "8", label: "Xã Vân Trình" },
  { value: "9", label: "Xã Thụy Hùng" },
  { value: "10", label: "Xã Quang Trọng" },
  { value: "11", label: "Xã Trọng Con" },
  { value: "12", label: "Xã Lê Lai" },
  { value: "13", label: "Xã Đức Long" },
  { value: "14", label: "Xã Danh Sỹ" },
  { value: "15", label: "Xã Lê Lợi" },
  { value: "16", label: "Xã Đức Xuân" },
];
export const backan_thanhphobackan = [
  { value: "1", label: "Phường Nguyễn Thị Minh Khai" },
  { value: "2", label: "Phường Sông Cầu" },
  { value: "3", label: "Phường Đức Xuân" },
  { value: "4", label: "Phường Phùng Chí Kiên" },
  { value: "5", label: "Phường Huyền Tụng" },
  { value: "6", label: "Xã Dương Quang" },
  { value: "7", label: "Xã Nông Thượng" },
  { value: "8", label: "Phường Xuất Hóa" },
];
export const backan_huyenpacnam = [
  { value: "1", label: "Xã Bằng Thành" },
  { value: "2", label: "Xã Nhạn Môn" },
  { value: "3", label: "Xã Bộc Bố" },
  { value: "4", label: "Xã Công Bằng" },
  { value: "5", label: "Xã Giáo Hiệu" },
  { value: "6", label: "Xã Xuân La" },
  { value: "7", label: "Xã An Thắng" },
  { value: "8", label: "Xã Cổ Linh" },
  { value: "9", label: "Xã Nghiên Loan" },
  { value: "10", label: "Xã Cao Tân" },
];
export const backan_huyenbabe = [
  { value: "1", label: "Thị trấn Chợ Rã" },
  { value: "2", label: "Xã Bành Trạch" },
  { value: "3", label: "Xã Phúc Lộc" },
  { value: "4", label: "Xã Hà Hiệu" },
  { value: "5", label: "Xã Cao Thượng" },
  { value: "6", label: "Xã Cao Trĩ" },
  { value: "7", label: "Xã Khang Ninh" },
  { value: "8", label: "Xã Nam Mẫu" },
  { value: "9", label: "Xã Thượng Giáo" },
  { value: "10", label: "Xã Địa Linh" },
  { value: "11", label: "Xã Yến Dương" },
  { value: "12", label: "Xã Chu Hương" },
  { value: "13", label: "Xã Quảng Khê" },
  { value: "14", label: "Xã Mỹ Phương" },
  { value: "15", label: "Xã Hoàng Trĩ" },
  { value: "16", label: "Xã Đồng Phúc" },
];
export const backan_huyennganson = [
  { value: "1", label: "Thị trấn Nà Phặc" },
  { value: "2", label: "Xã Thượng Ân" },
  { value: "3", label: "Xã Bằng Vân" },
  { value: "4", label: "Xã Cốc Đán" },
  { value: "5", label: "Xã Trung Hòa" },
  { value: "6", label: "Xã Đức Vân" },
  { value: "7", label: "Xã Vân Tùng" },
  { value: "8", label: "Xã Thượng Quan" },
  { value: "9", label: "Xã Lãng Ngâm" },
  { value: "10", label: "Xã Thuần Mang" },
  { value: "11", label: "Xã Hương Nê" },
];
export const backan_huyenbachthong = [
  { value: "1", label: "Thị trấn Phủ Thông" },
  { value: "2", label: "Xã Phương Linh" },
  { value: "3", label: "Xã Vi Hương" },
  { value: "4", label: "Xã Sĩ Bình" },
  { value: "5", label: "Xã Vũ Muộn" },
  { value: "6", label: "Xã Đôn Phong" },
  { value: "7", label: "Xã Tú Trĩ" },
  { value: "8", label: "Xã Lục Bình" },
  { value: "9", label: "Xã Tân Tiến" },
  { value: "10", label: "Xã Quân Bình" },
  { value: "11", label: "Xã Nguyên Phúc" },
  { value: "12", label: "Xã Cao Sơn" },
  { value: "13", label: "Xã Hà Vị" },
  { value: "14", label: "Xã Cẩm Giàng" },
  { value: "15", label: "Xã Mỹ Thanh" },
  { value: "16", label: "Xã Dương Phong" },
  { value: "17", label: "Xã Quang Thuận" },
];
export const backan_huyenchodon = [
  { value: "1", label: "Thị trấn Bằng Lũng" },
  { value: "2", label: "Xã Xuân Lạc" },
  { value: "3", label: "Xã Nam Cường" },
  { value: "4", label: "Xã Đồng Lạc" },
  { value: "5", label: "Xã Tân Lập" },
  { value: "6", label: "Xã Bản Thi" },
  { value: "7", label: "Xã Quảng Bạch" },
  { value: "8", label: "Xã Bằng Phúc" },
  { value: "9", label: "Xã Yên Thịnh" },
  { value: "10", label: "Xã Yên Thượng" },
  { value: "11", label: "Xã Phương Viên" },
  { value: "12", label: "Xã Ngọc Phái" },
  { value: "13", label: "Xã Rã Bản" },
  { value: "14", label: "Xã Đông Viên" },
  { value: "15", label: "Xã Lương Bằng" },
  { value: "16", label: "Xã Bằng Lãng" },
  { value: "17", label: "Xã Đại Sảo" },
  { value: "18", label: "Xã Nghĩa Tá" },
  { value: "19", label: "Xã Phong Huân" },
  { value: "20", label: "Xã Yên Mỹ" },
  { value: "21", label: "Xã Bình Trung" },
  { value: "22", label: "Xã Yên Nhuận" },
];
export const backan_huyenchomoi = [
  { value: "1", label: "Thị trấn Chợ Mới" },
  { value: "2", label: "Xã Tân Sơn" },
  { value: "3", label: "Xã Thanh Vận" },
  { value: "4", label: "Xã Mai Lạp" },
  { value: "5", label: "Xã Hòa Mục" },
  { value: "6", label: "Xã Thanh Mai" },
  { value: "7", label: "Xã Cao Kỳ" },
  { value: "8", label: "Xã Nông Hạ" },
  { value: "9", label: "Xã Yên Cư" },
  { value: "10", label: "Xã Nông Thịnh" },
  { value: "11", label: "Xã Yên Hân" },
  { value: "12", label: "Xã Thanh Bình" },
  { value: "13", label: "Xã Như Cố" },
  { value: "14", label: "Xã Bình Văn" },
  { value: "15", label: "Xã Yên Đĩnh" },
  { value: "16", label: "Xã Quảng Chu" },
];
export const backan_huyennari = [
  { value: "1", label: "Thị trấn Yến Lạc" },
  { value: "2", label: "Xã Vũ Loan" },
  { value: "3", label: "Xã Lạng San" },
  { value: "4", label: "Xã Lương Thượng" },
  { value: "5", label: "Xã Kim Hỷ" },
  { value: "6", label: "Xã Văn Học" },
  { value: "7", label: "Xã Cường Lợi" },
  { value: "8", label: "Xã Lương Hạ" },
  { value: "9", label: "Xã Kim Lư" },
  { value: "10", label: "Xã Lương Thành" },
  { value: "11", label: "Xã Ân Tình" },
  { value: "12", label: "Xã Lam Sơn" },
  { value: "13", label: "Xã Văn Minh" },
  { value: "14", label: "Xã Côn Minh" },
  { value: "15", label: "Xã Cư Lễ" },
  { value: "16", label: "Xã Hữu Thác" },
  { value: "17", label: "Xã Hảo Nghĩa" },
  { value: "18", label: "Xã Quang Phong" },
  { value: "19", label: "Xã Dương Sơn" },
  { value: "20", label: "Xã Xuân Dương" },
  { value: "21", label: "Xã Đổng Xá" },
  { value: "22", label: "Xã Liêm Thuỷ" },
];
export const tuyenquang_thanhphotuyenquang = [
  { value: "1", label: "Phường Phan Thiết" },
  { value: "2", label: "Phường Minh Xuân" },
  { value: "3", label: "Phường Tân Quang" },
  { value: "4", label: "Xã Tràng Đà" },
  { value: "5", label: "Phường Nông Tiến" },
  { value: "6", label: "Phường Ỷ La" },
  { value: "7", label: "Phường Tân Hà" },
  { value: "8", label: "Phường Hưng Thành" },
  { value: "9", label: "Xã An Khang" },
  { value: "10", label: "Xã An Tường" },
  { value: "11", label: "Xã Lưỡng Vượng" },
  { value: "12", label: "Xã Thái Long" },
  { value: "13", label: "Xã Đội Cấn" },
];
export const tuyenquang_huyenlambinh = [
  { value: "1", label: "Xã Phúc Yên" },
  { value: "2", label: "Xã Xuân Lập" },
  { value: "3", label: "Xã Khuôn Hà" },
  { value: "4", label: "Xã Lăng Can" },
  { value: "5", label: "Xã Thượng Lâm" },
  { value: "6", label: "Xã Bình An" },
  { value: "7", label: "Xã Hồng Quang" },
  { value: "8", label: "Xã Thổ Bình" },
];
export const tuyenquang_huyennahang = [
  { value: "1", label: "Thị trấn Nà Hang" },
  { value: "2", label: "Xã Sinh Long" },
  { value: "3", label: "Xã Thượng Giáp" },
  { value: "4", label: "Xã Thượng Nông" },
  { value: "5", label: "Xã Côn Lôn" },
  { value: "6", label: "Xã Yên Hoa" },
  { value: "7", label: "Xã Hồng Thái" },
  { value: "8", label: "Xã Đà Vị" },
  { value: "9", label: "Xã Khau Tinh" },
  { value: "10", label: "Xã Sơn Phú" },
  { value: "11", label: "Xã Năng Khả" },
  { value: "12", label: "Xã Thanh Tương" },
];
export const tuyenquang_huyenchiemhoa = [
  { value: "1", label: "Thị trấn Vĩnh Lộc" },
  { value: "2", label: "Xã Phúc Sơn" },
  { value: "3", label: "Xã Minh Quang" },
  { value: "4", label: "Xã Trung Hà" },
  { value: "5", label: "Xã Tân Mỹ" },
  { value: "6", label: "Xã Hà Lang" },
  { value: "7", label: "Xã Hùng Mỹ" },
  { value: "8", label: "Xã Yên Lập" },
  { value: "9", label: "Xã Tân An" },
  { value: "10", label: "Xã Bình Phú" },
  { value: "11", label: "Xã Xuân Quang" },
  { value: "12", label: "Xã Ngọc Hội" },
  { value: "13", label: "Xã Phú Bình" },
  { value: "14", label: "Xã Hòa Phú" },
  { value: "15", label: "Xã Phúc Thịnh" },
  { value: "16", label: "Xã Kiên Đài" },
  { value: "17", label: "Xã Tân Thịnh" },
  { value: "18", label: "Xã Trung Hòa" },
  { value: "19", label: "Xã Kim Bình" },
  { value: "20", label: "Xã Hòa An" },
  { value: "21", label: "Xã Vinh Quang" },
  { value: "22", label: "Xã Tri Phú" },
  { value: "23", label: "Xã Nhân Lý" },
  { value: "24", label: "Xã Yên Nguyên" },
  { value: "25", label: "Xã Linh Phú" },
  { value: "26", label: "Xã Bình Nhân" },
];
export const tuyenquang_huyenhamyen = [
  { value: "1", label: "Thị trấn Tân Yên" },
  { value: "2", label: "Xã Yên Thuận" },
  { value: "3", label: "Xã Bạch Xa" },
  { value: "4", label: "Xã Minh Khương" },
  { value: "5", label: "Xã Yên Lâm" },
  { value: "6", label: "Xã Minh Dân" },
  { value: "7", label: "Xã Phù Lưu" },
  { value: "8", label: "Xã Minh Hương" },
  { value: "9", label: "Xã Yên Phú" },
  { value: "10", label: "Xã Tân Thành" },
  { value: "11", label: "Xã Bình Xa" },
  { value: "12", label: "Xã Thái Sơn" },
  { value: "13", label: "Xã Nhân Mục" },
  { value: "14", label: "Xã Thành Long" },
  { value: "15", label: "Xã Bằng Cốc" },
  { value: "16", label: "Xã Thái Hòa" },
  { value: "17", label: "Xã Đức Ninh" },
  { value: "18", label: "Xã Hùng Đức" },
];
export const tuyenquang_huyenyenson = [
  { value: "1", label: "Thị trấn Tân Bình" },
  { value: "2", label: "Xã Quí Quân" },
  { value: "3", label: "Xã Lực Hành" },
  { value: "4", label: "Xã Kiến Thiết" },
  { value: "5", label: "Xã Trung Minh" },
  { value: "6", label: "Xã Chiêu Yên" },
  { value: "7", label: "Xã Trung Trực" },
  { value: "8", label: "Xã Xuân Vân" },
  { value: "9", label: "Xã Phúc Ninh" },
  { value: "10", label: "Xã Hùng Lợi" },
  { value: "11", label: "Xã Trung Sơn" },
  { value: "12", label: "Xã Tân Tiến" },
  { value: "13", label: "Xã Tứ Quận" },
  { value: "14", label: "Xã Đạo Viện" },
  { value: "15", label: "Xã Tân Long" },
  { value: "16", label: "Xã Thắng Quân" },
  { value: "17", label: "Xã Kim Quan" },
  { value: "18", label: "Xã Lang Quán" },
  { value: "19", label: "Xã Phú Thịnh" },
  { value: "20", label: "Xã Công Đa" },
  { value: "21", label: "Xã Trung Môn" },
  { value: "22", label: "Xã Chân Sơn" },
  { value: "23", label: "Xã Thái Bình" },
  { value: "24", label: "Xã Kim Phú" },
  { value: "25", label: "Xã Tiến Bộ" },
  { value: "26", label: "Xã Mỹ Bằng" },
  { value: "27", label: "Xã Phú Lâm" },
  { value: "28", label: "Xã Hoàng Khai" },
  { value: "29", label: "Xã Nhữ Hán" },
  { value: "30", label: "Xã Nhữ Khê" },
  { value: "31", label: "Xã Đội Bình" },
];
export const tuyenquang_huyensonduong = [
  { value: "1", label: "Thị trấn Sơn Dương" },
  { value: "2", label: "Xã Trung Yên" },
  { value: "3", label: "Xã Minh Thanh" },
  { value: "4", label: "Xã Tân Trào" },
  { value: "5", label: "Xã Vĩnh Lợi" },
  { value: "6", label: "Xã Thượng Ấm" },
  { value: "7", label: "Xã Bình Yên" },
  { value: "8", label: "Xã Lương Thiện" },
  { value: "9", label: "Xã Tú Thịnh" },
  { value: "10", label: "Xã Cấp Tiến" },
  { value: "11", label: "Xã Hợp Thành" },
  { value: "12", label: "Xã Phúc Ứng" },
  { value: "13", label: "Xã Đông Thọ" },
  { value: "14", label: "Xã Kháng Nhật" },
  { value: "15", label: "Xã Hợp Hòa" },
  { value: "16", label: "Xã Thanh Phát" },
  { value: "17", label: "Xã Quyết Thắng" },
  { value: "18", label: "Xã Đồng Quý" },
  { value: "19", label: "Xã Tuân Lộ" },
  { value: "20", label: "Xã Vân Sơn" },
  { value: "21", label: "Xã Văn Phú" },
  { value: "22", label: "Xã Chi Thiết" },
  { value: "23", label: "Xã Đông Lợi" },
  { value: "24", label: "Xã Thiện Kế" },
  { value: "25", label: "Xã Hồng Lạc" },
  { value: "26", label: "Xã Phú Lương" },
  { value: "27", label: "Xã Ninh Lai" },
  { value: "28", label: "Xã Đại Phú" },
  { value: "29", label: "Xã Sơn Nam" },
  { value: "30", label: "Xã Hào Phú" },
  { value: "31", label: "Xã Tam Đa" },
  { value: "32", label: "Xã Sầm Dương" },
  { value: "33", label: "Xã Lâm Xuyên" },
];
export const laocai_thanhpholaocai = [
  { value: "1", label: "Phường Duyên Hải" },
  { value: "2", label: "Phường Lào Cai" },
  { value: "3", label: "Phường Phố Mới" },
  { value: "4", label: "Phường Cốc Lếu" },
  { value: "5", label: "Phường Kim Tân" },
  { value: "6", label: "Phường Bắc Lệnh" },
  { value: "7", label: "Phường Pom Hán" },
  { value: "8", label: "Phường Xuân Tăng" },
  { value: "9", label: "Phường Bình Minh" },
  { value: "10", label: "Phường Thống Nhất" },
  { value: "11", label: "Xã Đồng Tuyển" },
  { value: "12", label: "Xã Vạn Hòa" },
  { value: "13", label: "Phường Bắc Cường" },
  { value: "14", label: "Phường Nam Cường" },
  { value: "15", label: "Xã Cam Đường" },
  { value: "16", label: "Xã Tả Phời" },
  { value: "17", label: "Xã Hợp Thành" },
];
export const laocai_huyenbatxat = [
  { value: "1", label: "Thị trấn Bát Xát" },
  { value: "2", label: "Xã A Mú Sung" },
  { value: "3", label: "Xã Nậm Chạc" },
  { value: "4", label: "Xã A Lù" },
  { value: "5", label: "Xã Trịnh Tường" },
  { value: "6", label: "Xã Ngải Thầu" },
  { value: "7", label: "Xã Y Tý" },
  { value: "8", label: "Xã Cốc Mỳ" },
  { value: "9", label: "Xã Dền Sáng" },
  { value: "10", label: "Xã Bản Vược" },
  { value: "11", label: "Xã Sàng Ma Sáo" },
  { value: "12", label: "Xã Bản Qua" },
  { value: "13", label: "Xã Mường Vi" },
  { value: "14", label: "Xã Dền Thàng" },
  { value: "15", label: "Xã Bản Xèo" },
  { value: "16", label: "Xã Mường Hum" },
  { value: "17", label: "Xã Trung Lèng Hồ" },
  { value: "18", label: "Xã Quang Kim" },
  { value: "19", label: "Xã Pa Cheo" },
  { value: "20", label: "Xã Nậm Pung" },
  { value: "21", label: "Xã Phìn Ngan" },
  { value: "22", label: "Xã Cốc San" },
  { value: "23", label: "Xã Tòng Sành" },
];
export const laocai_huyenmuongkhuong = [
  { value: "1", label: "Xã Pha Long" },
  { value: "2", label: "Xã Tả Ngải Chồ" },
  { value: "3", label: "Xã Tung Chung Phố" },
  { value: "4", label: "Thị trấn Mường Khương" },
  { value: "5", label: "Xã Dìn Chin" },
  { value: "6", label: "Xã Tả Gia Khâu" },
  { value: "7", label: "Xã Nậm Chảy" },
  { value: "8", label: "Xã Nấm Lư" },
  { value: "9", label: "Xã Lùng Khấu Nhin" },
  { value: "10", label: "Xã Thanh Bình" },
  { value: "11", label: "Xã Cao Sơn" },
  { value: "12", label: "Xã Lùng Vai" },
  { value: "13", label: "Xã Bản Lầu" },
  { value: "14", label: "Xã La Pan Tẩn" },
  { value: "15", label: "Xã Tả Thàng" },
  { value: "16", label: "Xã Bản Sen" },
];
export const laocai_huyensimacai = [
  { value: "1", label: "Xã Nàn Sán" },
  { value: "2", label: "Xã Thào Chư Phìn" },
  { value: "3", label: "Xã Bản Mế" },
  { value: "4", label: "Xã Si Ma Cai" },
  { value: "5", label: "Xã Sán Chải" },
  { value: "6", label: "Xã Mản Thẩn" },
  { value: "7", label: "Xã Lùng Sui" },
  { value: "8", label: "Xã Cán Cấu" },
  { value: "9", label: "Xã Sín Chéng" },
  { value: "10", label: "Xã Cán Hồ" },
  { value: "11", label: "Xã Quan Thần Sán" },
  { value: "12", label: "Xã Lử Thẩn" },
  { value: "13", label: "Xã Nàn Xín" },
];
export const laocai_huyenbacha = [
  { value: "1", label: "Thị trấn Bắc Hà" },
  { value: "2", label: "Xã Lùng Cải" },
  { value: "3", label: "Xã Bản Già" },
  { value: "4", label: "Xã Lùng Phình" },
  { value: "5", label: "Xã Tả Van Chư" },
  { value: "6", label: "Xã Tả Củ Tỷ" },
  { value: "7", label: "Xã Thải Giàng Phố" },
  { value: "8", label: "Xã Lầu Thí Ngài" },
  { value: "9", label: "Xã Hoàng Thu Phố" },
  { value: "10", label: "Xã Bản Phố" },
  { value: "11", label: "Xã Bản Liền" },
  { value: "12", label: "Xã Tà Chải" },
  { value: "13", label: "Xã Na Hối" },
  { value: "14", label: "Xã Cốc Ly" },
  { value: "15", label: "Xã Nậm Mòn" },
  { value: "16", label: "Xã Nậm Đét" },
  { value: "17", label: "Xã Nậm Khánh" },
  { value: "18", label: "Xã Bảo Nhai" },
  { value: "19", label: "Xã Nậm Lúc" },
  { value: "20", label: "Xã Cốc Lầu" },
  { value: "21", label: "Xã Bản Cái" },
];
export const laocai_huyenbaothang = [
  { value: "1", label: "Thị trấn N.T Phong Hải" },
  { value: "2", label: "Thị trấn Phố Lu" },
  { value: "3", label: "Thị trấn Tằng Loỏng" },
  { value: "4", label: "Xã Bản Phiệt" },
  { value: "5", label: "Xã Bản Cầm" },
  { value: "6", label: "Xã Thái Niên" },
  { value: "7", label: "Xã Phong Niên" },
  { value: "8", label: "Xã Gia Phú" },
  { value: "9", label: "Xã Xuân Quang" },
  { value: "10", label: "Xã Sơn Hải" },
  { value: "11", label: "Xã Xuân Giao" },
  { value: "12", label: "Xã Trì Quang" },
  { value: "13", label: "Xã Sơn Hà" },
  { value: "14", label: "Xã Phố Lu" },
  { value: "15", label: "Xã Phú Nhuận" },
];
export const laocai_huyenbaoyen = [
  { value: "1", label: "Thị trấn Phố Ràng" },
  { value: "2", label: "Xã Tân Tiến" },
  { value: "3", label: "Xã Nghĩa Đô" },
  { value: "4", label: "Xã Vĩnh Yên" },
  { value: "5", label: "Xã Điện Quan" },
  { value: "6", label: "Xã Xuân Hòa" },
  { value: "7", label: "Xã Tân Dương" },
  { value: "8", label: "Xã Thượng Hà" },
  { value: "9", label: "Xã Kim Sơn" },
  { value: "10", label: "Xã Cam Cọn" },
  { value: "11", label: "Xã Minh Tân" },
  { value: "12", label: "Xã Xuân Thượng" },
  { value: "13", label: "Xã Việt Tiến" },
  { value: "14", label: "Xã Yên Sơn" },
  { value: "15", label: "Xã Bảo Hà" },
  { value: "16", label: "Xã Lương Sơn" },
  { value: "17", label: "Xã Long Phúc" },
  { value: "18", label: "Xã Long Khánh" },
];
export const laocai_huyensapa = [
  { value: "1", label: "Thị trấn Sa Pa" },
  { value: "2", label: "Xã Bản Khoang" },
  { value: "3", label: "Xã Tả Giàng Phình" },
  { value: "4", label: "Xã Trung Chải" },
  { value: "5", label: "Xã Tả Phìn" },
  { value: "6", label: "Xã Sa Pả" },
  { value: "7", label: "Xã San Sả Hồ" },
  { value: "8", label: "Xã Bản Phùng" },
  { value: "9", label: "Xã Hầu Thào" },
  { value: "10", label: "Xã Lao Chải" },
  { value: "11", label: "Xã Thanh Kim" },
  { value: "12", label: "Xã Suối Thầu" },
  { value: "13", label: "Xã Sử Pán" },
  { value: "14", label: "Xã Tả Van" },
  { value: "15", label: "Xã Thanh Phú" },
  { value: "16", label: "Xã Bản Hồ" },
  { value: "17", label: "Xã Nậm Sài" },
  { value: "18", label: "Xã Nậm Cang" },
];
export const laocai_huyenvanban = [
  { value: "1", label: "Thị trấn Khánh Yên" },
  { value: "2", label: "Xã Văn Sơn" },
  { value: "3", label: "Xã Võ Lao" },
  { value: "4", label: "Xã Sơn Thuỷ" },
  { value: "5", label: "Xã Nậm Mả" },
  { value: "6", label: "Xã Tân Thượng" },
  { value: "7", label: "Xã Nậm Rạng" },
  { value: "8", label: "Xã Nậm Chầy" },
  { value: "9", label: "Xã Tân An" },
  { value: "10", label: "Xã Khánh Yên Thượng" },
  { value: "11", label: "Xã Nậm Xé" },
  { value: "12", label: "Xã Dần Thàng" },
  { value: "13", label: "Xã Chiềng Ken" },
  { value: "14", label: "Xã Làng Giàng" },
  { value: "15", label: "Xã Hòa Mạc" },
  { value: "16", label: "Xã Khánh Yên Trung" },
  { value: "17", label: "Xã Khánh Yên Hạ" },
  { value: "18", label: "Xã Dương Quỳ" },
  { value: "19", label: "Xã Nậm Tha" },
  { value: "20", label: "Xã Minh Lương" },
  { value: "21", label: "Xã Thẩm Dương" },
  { value: "22", label: "Xã Liêm Phú" },
  { value: "23", label: "Xã Nậm Xây" },
];
export const dienbien_thanhphodienbienphu = [
  { value: "1", label: "Phường Noong Bua" },
  { value: "2", label: "Phường Him Lam" },
  { value: "3", label: "Phường Thanh Bình" },
  { value: "4", label: "Phường Tân Thanh" },
  { value: "5", label: "Phường Mường Thanh" },
  { value: "6", label: "Phường Nam Thanh" },
  { value: "7", label: "Phường Thanh Trường" },
  { value: "8", label: "Xã Tà Lèng" },
  { value: "9", label: "Xã Thanh Minh" },
];
export const dienbien_xamuonglay = [
  { value: "1", label: "Phường Sông Đà" },
  { value: "2", label: "Phường Na Lay" },
  { value: "3", label: "Xã Lay Nưa" },
];
export const dienbien_huyenmuongnhe = [
  { value: "1", label: "Xã Sín Thầu" },
  { value: "2", label: "Xã Sen Thượng" },
  { value: "3", label: "Xã Chung Chải" },
  { value: "4", label: "Xã Leng Su Sìn" },
  { value: "5", label: "Xã Pá Mỳ" },
  { value: "6", label: "Xã Mường Nhé" },
  { value: "7", label: "Xã Nậm Vì" },
  { value: "8", label: "Xã Nậm Kè" },
  { value: "9", label: "Xã Mường Toong" },
  { value: "10", label: "Xã Quảng Lâm" },
  { value: "11", label: "Xã Huổi Lếnh" },
];
export const dienbien_huyenmuongcha = [
  { value: "1", label: "Thị Trấn Mường Chà" },
  { value: "2", label: "Xã Xá Tổng" },
  { value: "3", label: "Xã Mường Tùng" },
  { value: "4", label: "Xã Hừa Ngài" },
  { value: "5", label: "Xã Huổi Mí" },
  { value: "6", label: "Xã Pa Ham" },
  { value: "7", label: "Xã Nậm Nèn" },
  { value: "8", label: "Xã Huổi Lèng" },
  { value: "9", label: "Xã Sa Lông" },
  { value: "10", label: "Xã Ma Thì Hồ" },
  { value: "11", label: "Xã Na Sang" },
  { value: "12", label: "Xã Mường Mươn" },
];
export const dienbien_huyentuachua = [
  { value: "1", label: "Thị trấn Tủa Chùa" },
  { value: "2", label: "Xã Huổi Só" },
  { value: "3", label: "Xã Xín Chải" },
  { value: "4", label: "Xã Tả Sìn Thàng" },
  { value: "5", label: "Xã Lao Xả Phình" },
  { value: "6", label: "Xã Tả Phìn" },
  { value: "7", label: "Xã Tủa Thàng" },
  { value: "8", label: "Xã Trung Thu" },
  { value: "9", label: "Xã Sính Phình" },
  { value: "10", label: "Xã Sáng Nhè" },
  { value: "11", label: "Xã Mường Đun" },
  { value: "12", label: "Xã Mường Báng" },
];
export const dienbien_huyentuangiao = [
  { value: "1", label: "Thị trấn Tuần Giáo" },
  { value: "2", label: "Xã Phình Sáng" },
  { value: "3", label: "Xã Rạng Đông" },
  { value: "4", label: "Xã Mùn Chung" },
  { value: "5", label: "Xã Nà Tòng" },
  { value: "6", label: "Xã Ta Ma" },
  { value: "7", label: "Xã Mường Mùn" },
  { value: "8", label: "Xã Pú Xi" },
  { value: "9", label: "Xã Pú Nhung" },
  { value: "10", label: "Xã Quài Nưa" },
  { value: "11", label: "Xã Mường Thín" },
  { value: "12", label: "Xã Tỏa Tình" },
  { value: "13", label: "Xã Nà Sáy" },
  { value: "14", label: "Xã Mường Khong" },
  { value: "15", label: "Xã Quài Cang" },
  { value: "16", label: "Xã Quài Tở" },
  { value: "17", label: "Xã Chiềng Sinh" },
  { value: "18", label: "Xã Chiềng Đông" },
  { value: "19", label: "Xã Tênh Phông" },
];
export const dienbien_huyendienbien = [
  { value: "1", label: "Xã Nà Tấu" },
  { value: "2", label: "Xã Nà Nhạn" },
  { value: "3", label: "Xã Mường Pồn" },
  { value: "4", label: "Xã Thanh Nưa" },
  { value: "5", label: "Xã Hua Thanh" },
  { value: "6", label: "Xã Mường Phăng" },
  { value: "7", label: "Xã Pá Khoang" },
  { value: "8", label: "Xã Thanh Luông" },
  { value: "9", label: "Xã Thanh Hưng" },
  { value: "10", label: "Xã Thanh Xương" },
  { value: "11", label: "Xã Thanh Chăn" },
  { value: "12", label: "Xã Pa Thơm" },
  { value: "13", label: "Xã Thanh An" },
  { value: "14", label: "Xã Thanh Yên" },
  { value: "15", label: "Xã Noong Luống" },
  { value: "16", label: "Xã Noọng Hẹt" },
  { value: "17", label: "Xã Sam Mứn" },
  { value: "18", label: "Xã Pom Lót" },
  { value: "19", label: "Xã Núa Ngam" },
  { value: "20", label: "Xã Hẹ Muông" },
  { value: "21", label: "Xã Na Ư" },
  { value: "22", label: "Xã Mường Nhà" },
  { value: "23", label: "Xã Na Tông" },
  { value: "24", label: "Xã Mường Lói" },
  { value: "25", label: "Xã Phu Luông" },
];
export const dienbien_huyendienbiendong = [
  { value: "1", label: "Thị trấn Điện Biên Đông" },
  { value: "2", label: "Xã Na Son" },
  { value: "3", label: "Xã Phì Nhừ" },
  { value: "4", label: "Xã Chiềng Sơ" },
  { value: "5", label: "Xã Mường Luân" },
  { value: "6", label: "Xã Pú Nhi" },
  { value: "7", label: "Xã Nong U" },
  { value: "8", label: "Xã Xa Dung" },
  { value: "9", label: "Xã Keo Lôm" },
  { value: "10", label: "Xã Luân Giới" },
  { value: "11", label: "Xã Phình Giàng" },
  { value: "12", label: "Xã Pú Hồng" },
  { value: "13", label: "Xã Tìa Dình" },
  { value: "14", label: "Xã Háng Lìa" },
];
export const dienbien_huyenmuonganh = [
  { value: "1", label: "Thị trấn Mường Ảng" },
  { value: "2", label: "Xã Mường Đăng" },
  { value: "3", label: "Xã Ngối Cáy" },
  { value: "4", label: "Xã Ẩng Tở" },
  { value: "5", label: "Xã Búng Lao" },
  { value: "6", label: "Xã Xuân Lao" },
  { value: "7", label: "Xã Ẩng Nưa" },
  { value: "8", label: "Xã Ẩng Cang" },
  { value: "9", label: "Xã Nặm Lịch" },
  { value: "10", label: "Xã Mường Lạn" },
];
export const dienbien_huyennampo = [
  { value: "1", label: "Xã Nậm Tin" },
  { value: "2", label: "Xã Pa Tần" },
  { value: "3", label: "Xã Chà Cang" },
  { value: "4", label: "Xã Na Cô Sa" },
  { value: "5", label: "Xã Nà Khoa" },
  { value: "6", label: "Xã Nà Hỳ" },
  { value: "7", label: "Xã Nà Bủng" },
  { value: "8", label: "Xã Nậm Nhừ" },
  { value: "9", label: "Xã Nậm Chua" },
  { value: "10", label: "Xã Nậm Khăn" },
  { value: "11", label: "Xã Chà Tở" },
  { value: "12", label: "Xã Vàng Đán" },
  { value: "13", label: "Xã Chà Nưa" },
  { value: "14", label: "Xã Phìn Hồ" },
  { value: "15", label: "Xã Si Pa Phìn" },
];
export const laichau_thanhpholaichau = [
  { value: "1", label: "Phường Quyết Thắng" },
  { value: "2", label: "Phường Tân Phong" },
  { value: "3", label: "Phường Quyết Tiến" },
  { value: "4", label: "Phường Đoàn Kết" },
  { value: "5", label: "Xã Nậm Loỏng" },
  { value: "6", label: "Phường Đông Phong" },
  { value: "7", label: "Xã San Thàng" },
];
export const laichau_huyentamduong = [
  { value: "1", label: "Thị trấn Tam Đường" },
  { value: "2", label: "Xã Thèn Sin" },
  { value: "3", label: "Xã Sùng Phài" },
  { value: "4", label: "Xã Tả Lèng" },
  { value: "5", label: "Xã Giang Ma" },
  { value: "6", label: "Xã Hồ Thầu" },
  { value: "7", label: "Xã Bình Lư" },
  { value: "8", label: "Xã Sơn Bình" },
  { value: "9", label: "Xã Nùng Nàng" },
  { value: "10", label: "Xã Bản Giang" },
  { value: "11", label: "Xã Bản Hon" },
  { value: "12", label: "Xã Bản Bo" },
  { value: "13", label: "Xã Nà Tăm" },
  { value: "14", label: "Xã Khun Há" },
];
export const laichau_huyenmuongte = [
  { value: "1", label: "Thị trấn Mường Tè" },
  { value: "2", label: "Xã Thu Lũm" },
  { value: "3", label: "Xã Ka Lăng" },
  { value: "4", label: "Xã Tá Bạ" },
  { value: "5", label: "Xã Pa ủ" },
  { value: "6", label: "Xã Mường Tè" },
  { value: "7", label: "Xã Pa Vệ Sử" },
  { value: "8", label: "Xã Mù Cả" },
  { value: "9", label: "Xã Bun Tở" },
  { value: "10", label: "Xã Nậm Khao" },
  { value: "11", label: "Xã Tà Tổng" },
  { value: "12", label: "Xã Bun Nưa" },
  { value: "13", label: "Xã Vàng San" },
  { value: "14", label: "Xã Kan Hồ" },
];
export const laichau_huyensinho = [
  { value: "1", label: "Thị trấn Sìn Hồ" },
  { value: "2", label: "Xã Chăn Nưa" },
  { value: "3", label: "Xã Pa Tần" },
  { value: "4", label: "Xã Phìn Hồ" },
  { value: "5", label: "Xã Hồng Thu" },
  { value: "6", label: "Xã Phăng Sô Lin" },
  { value: "7", label: "Xã Ma Quai" },
  { value: "8", label: "Xã Lùng Thàng" },
  { value: "9", label: "Xã Tả Phìn" },
  { value: "10", label: "Xã Sà Dề Phìn" },
  { value: "11", label: "Xã Nậm Tăm" },
  { value: "12", label: "Xã Tả Ngảo" },
  { value: "13", label: "Xã Pu Sam Cáp" },
  { value: "14", label: "Xã Nậm Cha" },
  { value: "15", label: "Xã Pa Khoá" },
  { value: "16", label: "Xã Làng Mô" },
  { value: "17", label: "Xã Noong Hẻo" },
  { value: "18", label: "Xã Nậm Mạ" },
  { value: "19", label: "Xã Căn Co" },
  { value: "20", label: "Xã Tủa Sín Chải" },
  { value: "21", label: "Xã Nậm Cuổi" },
  { value: "22", label: "Xã Nậm Hăn" },
];
export const laichau_huyenphongtho = [
  { value: "1", label: "Xã Lả Nhì Thàng" },
  { value: "2", label: "Xã Huổi Luông" },
  { value: "3", label: "Thị trấn Phong Thổ" },
  { value: "4", label: "Xã Sì Lờ Lầu" },
  { value: "5", label: "Xã Mồ Sì San" },
  { value: "6", label: "Xã Ma Li Chải" },
  { value: "7", label: "Xã Pa Vây Sử" },
  { value: "8", label: "Xã Vàng Ma Chải" },
  { value: "9", label: "Xã Tông Qua Lìn" },
  { value: "10", label: "Xã Mù Sang" },
  { value: "11", label: "Xã Dào San" },
  { value: "12", label: "Xã Ma Ly Pho" },
  { value: "13", label: "Xã Bản Lang" },
  { value: "14", label: "Xã Hoang Thèn" },
  { value: "15", label: "Xã Khổng Lào" },
  { value: "16", label: "Xã Nậm Xe" },
  { value: "17", label: "Xã Mường So" },
  { value: "18", label: "Xã Sin Suối Hồ" },
];
export const laichau_huyenthanuyen = [
  { value: "1", label: "Thị trấn Than Uyên" },
  { value: "2", label: "Xã Phúc Than" },
  { value: "3", label: "Xã Mường Than" },
  { value: "4", label: "Xã Mường Mít" },
  { value: "5", label: "Xã Pha Mu" },
  { value: "6", label: "Xã Mường Cang" },
  { value: "7", label: "Xã Hua Nà" },
  { value: "8", label: "Xã Tà Hừa" },
  { value: "9", label: "Xã Mường Kim" },
  { value: "10", label: "Xã Tà Mung" },
  { value: "11", label: "Xã Tà Gia" },
  { value: "12", label: "Xã Khoen On" },
];
export const laichau_huyentanuyen = [
  { value: "1", label: "Thị trấn Tân Uyên" },
  { value: "2", label: "Xã Mường Khoa" },
  { value: "3", label: "Xã Phúc Khoa" },
  { value: "4", label: "Xã Thân Thuộc" },
  { value: "5", label: "Xã Trung Đồng" },
  { value: "6", label: "Xã Hố Mít" },
  { value: "7", label: "Xã Nậm Cần" },
  { value: "8", label: "Xã Nậm Sỏ" },
  { value: "9", label: "Xã Pắc Ta" },
  { value: "10", label: "Xã Tà Mít" },
];
export const laichau_huyennamnhun = [
  { value: "1", label: "Thị trấn Nậm Nhùn" },
  { value: "2", label: "Xã Hua Bun" },
  { value: "3", label: "Xã Mường Mô" },
  { value: "4", label: "Xã Nậm Chà" },
  { value: "5", label: "Xã Nậm Manh" },
  { value: "6", label: "Xã Nậm Hàng" },
  { value: "7", label: "Xã Lê Lợi" },
  { value: "8", label: "Xã Pú Đao" },
  { value: "9", label: "Xã Nậm Pì" },
  { value: "10", label: "Xã Nậm Ban" },
  { value: "11", label: "Xã Trung Chải" },
];

export const sonla_thanhphosonla = [
  { value: "1", label: "Phường Chiềng Lề" },
  { value: "2", label: "Phường Tô Hiệu" },
  { value: "3", label: "Phường Quyết Thắng" },
  { value: "4", label: "Phường Quyết Tâm" },
  { value: "5", label: "Xã Chiềng Cọ" },
  { value: "6", label: "Xã Chiềng Đen" },
  { value: "7", label: "Xã Chiềng Xôm" },
  { value: "8", label: "Phường Chiềng An" },
  { value: "9", label: "Phường Chiềng Cơi" },
  { value: "10", label: "Xã Chiềng Ngần" },
  { value: "11", label: "Xã Hua La" },
  { value: "12", label: "Phường Chiềng Sinh" },
];
export const sonla_huyenquynhnhai = [
  { value: "1", label: "Xã Mường Chiên" },
  { value: "2", label: "Xã Cà Nàng" },
  { value: "3", label: "Xã Chiềng Khay" },
  { value: "4", label: "Xã Mường Giôn" },
  { value: "5", label: "Xã Pá Ma Pha Khinh" },
  { value: "6", label: "Xã Chiềng On" },
  { value: "7", label: "Xã Mường Giàng" },
  { value: "8", label: "Xã Chiềng Bằng" },
  { value: "9", label: "Xã Mường Sại" },
  { value: "10", label: "Xã Nậm ét" },
  { value: "11", label: "Xã Chiềng Khoang" },
];
export const sonla_huyenthauchau = [
  { value: "1", label: "Thị trấn Thuận Châu" },
  { value: "2", label: "Xã Phỏng Lái" },
  { value: "3", label: "Xã Mường é" },
  { value: "4", label: "Xã Chiềng Pha" },
  { value: "5", label: "Xã Chiềng La" },
  { value: "6", label: "Xã Chiềng Ngàm" },
  { value: "7", label: "Xã Liệp Tè" },
  { value: "8", label: "Xã é Tòng" },
  { value: "9", label: "Xã Phỏng Lập" },
  { value: "10", label: "Xã Phổng Lăng" },
  { value: "11", label: "Xã Chiềng Ly" },
  { value: "12", label: "Xã Nong Lay" },
  { value: "13", label: "Xã Mường Khiêng" },
  { value: "14", label: "Xã Mường Bám" },
  { value: "15", label: "Xã Long Hẹ" },
  { value: "16", label: "Xã Chiềng Bôm" },
  { value: "17", label: "Xã Thôn Mòn" },
  { value: "18", label: "Xã Tòng Lệnh" },
  { value: "19", label: "Xã Tòng Cọ" },
  { value: "20", label: "Xã Bó Mười" },
  { value: "21", label: "Xã Co Mạ" },
  { value: "22", label: "Xã Púng Tra" },
  { value: "23", label: "Xã Chiềng Pấc" },
  { value: "24", label: "Xã Nậm Lầu" },
  { value: "25", label: "Xã Bon Phặng" },
  { value: "26", label: "Xã Co Tòng" },
  { value: "27", label: "Xã Muội Nọi" },
  { value: "28", label: "Xã Pá Lông" },
  { value: "29", label: "Xã Bản Lầm" },
];
export const sonla_huyenmuongla = [
  { value: "1", label: "Thị trấn Ít Ong" },
  { value: "2", label: "Xã Nậm Giôn" },
  { value: "3", label: "Xã Chiềng Lao" },
  { value: "4", label: "Xã Hua Trai" },
  { value: "5", label: "Xã Ngọc Chiến" },
  { value: "6", label: "Xã Mường Trai" },
  { value: "7", label: "Xã Nậm Păm" },
  { value: "8", label: "Xã Chiềng Muôn" },
  { value: "9", label: "Xã Chiềng Ân" },
  { value: "10", label: "Xã Pi Toong" },
  { value: "11", label: "Xã Chiềng Công" },
  { value: "12", label: "Xã Tạ Bú" },
  { value: "13", label: "Xã Chiềng San" },
  { value: "14", label: "Xã Mường Bú" },
  { value: "15", label: "Xã Chiềng Hoa" },
  { value: "16", label: "Xã Mường Chùm" },
];
export const sonla_huyenbacyen = [
  { value: "1", label: "Thị trấn Bắc Yên" },
  { value: "2", label: "Xã Phiêng Ban" },
  { value: "3", label: "Xã Hang Chú" },
  { value: "4", label: "Xã Xín Vàng" },
  { value: "5", label: "Xã Tà Xùa" },
  { value: "6", label: "Xã Háng Đồng" },
  { value: "7", label: "Xã Bắc Ngà" },
  { value: "8", label: "Xã Làng Chếu" },
  { value: "9", label: "Xã Chim Vàn" },
  { value: "10", label: "Xã Mường Khoa" },
  { value: "11", label: "Xã Song Pe" },
  { value: "12", label: "Xã Hồng Ngài" },
  { value: "13", label: "Xã Tạ Khoa" },
  { value: "14", label: "Xã Hua Nhàn" },
  { value: "15", label: "Xã Phiêng Kôn" },
  { value: "16", label: "Xã Chiềng Sại" },
];
export const sonla_huyenphuyen = [
  { value: "1", label: "Thị trấn Phù Yên" },
  { value: "2", label: "Xã Suối Tọ" },
  { value: "3", label: "Xã Mường Thải" },
  { value: "4", label: "Xã Mường Cơi" },
  { value: "5", label: "Xã Quang Huy" },
  { value: "6", label: "Xã Huy Bắc" },
  { value: "7", label: "Xã Huy Thượng" },
  { value: "8", label: "Xã Tân Lang" },
  { value: "9", label: "Xã Gia Phù" },
  { value: "10", label: "Xã Tường Phù" },
  { value: "11", label: "Xã Huy Hạ" },
  { value: "12", label: "Xã Huy Tân" },
  { value: "13", label: "Xã Mường Lang" },
  { value: "14", label: "Xã Suối Bau" },
  { value: "15", label: "Xã Huy Tường" },
  { value: "16", label: "Xã Mường Do" },
  { value: "17", label: "Xã Sập Xa" },
  { value: "18", label: "Xã Tường Thượng" },
  { value: "19", label: "Xã Tường Tiến" },
  { value: "20", label: "Xã Tường Phong" },
  { value: "21", label: "Xã Tường Hạ" },
  { value: "22", label: "Xã Kim Bon" },
  { value: "23", label: "Xã Mường Bang" },
  { value: "24", label: "Xã Đá Đỏ" },
  { value: "25", label: "Xã Tân Phong" },
  { value: "26", label: "Xã Nam Phong" },
  { value: "27", label: "Xã Bắc Phong" },
];
export const sonla_huyenmocchau = [
  { value: "1", label: "Thị trấn Mộc Châu" },
  { value: "2", label: "Thị trấn NT Mộc Châu" },
  { value: "3", label: "Xã Chiềng Sơn" },
  { value: "4", label: "Xã Tân Hợp" },
  { value: "5", label: "Xã Qui Hướng" },
  { value: "6", label: "Xã Tân Lập" },
  { value: "7", label: "Xã Nà Mường" },
  { value: "8", label: "Xã Tà Lai" },
  { value: "9", label: "Xã Chiềng Hắc" },
  { value: "10", label: "Xã Hua Păng" },
  { value: "11", label: "Xã Chiềng Khừa" },
  { value: "12", label: "Xã Mường Sang" },
  { value: "13", label: "Xã Đông Sang" },
  { value: "14", label: "Xã Phiêng Luông" },
  { value: "15", label: "Xã Lóng Sập" },
];
export const sonla_huyenyenchau = [
  { value: "1", label: "Thị trấn Yên Châu" },
  { value: "2", label: "Xã Chiềng Đông" },
  { value: "3", label: "Xã Sập Vạt" },
  { value: "4", label: "Xã Chiềng Sàng" },
  { value: "5", label: "Xã Chiềng Pằn" },
  { value: "6", label: "Xã Viêng Lán" },
  { value: "7", label: "Xã Chiềng Hặc" },
  { value: "8", label: "Xã Mường Lựm" },
  { value: "9", label: "Xã Chiềng On" },
  { value: "10", label: "Xã Yên Sơn" },
  { value: "11", label: "Xã Chiềng Khoi" },
  { value: "12", label: "Xã Tú Nang" },
  { value: "13", label: "Xã Lóng Phiêng" },
  { value: "14", label: "Xã Phiêng Khoài" },
  { value: "15", label: "Xã Chiềng Tương" },
];
export const sonla_huyenmaison = [
  { value: "1", label: "Thị trấn Hát Lót" },
  { value: "2", label: "Xã Chiềng Sung" },
  { value: "3", label: "Xã Mường Bằng" },
  { value: "4", label: "Xã Chiềng Chăn" },
  { value: "5", label: "Xã Mương Tranh" },
  { value: "6", label: "Xã Chiềng Ban" },
  { value: "7", label: "Xã Chiềng Mung" },
  { value: "8", label: "Xã Mường Bon" },
  { value: "9", label: "Xã Chiềng Chung" },
  { value: "10", label: "Xã Chiềng Mai" },
  { value: "11", label: "Xã Hát Lót" },
  { value: "12", label: "Xã Nà Pó" },
  { value: "13", label: "Xã Cò Nòi" },
  { value: "14", label: "Xã Chiềng Nơi" },
  { value: "15", label: "Xã Phiêng Cằm" },
  { value: "16", label: "Xã Chiềng Dong" },
  { value: "17", label: "Xã Chiềng Kheo" },
  { value: "18", label: "Xã Chiềng Ve" },
  { value: "19", label: "Xã Chiềng Lương" },
  { value: "20", label: "Xã Phiêng Pằn" },
  { value: "21", label: "Xã Nà Ot" },
  { value: "22", label: "Xã Tà Hộc" },
];
export const sonla_huyensongma = [
  { value: "1", label: "Thị trấn Sông Mã" },
  { value: "2", label: "Xã Bó Sinh" },
  { value: "3", label: "Xã Pú Pẩu" },
  { value: "4", label: "Xã Chiềng Phung" },
  { value: "5", label: "Xã Chiềng En" },
  { value: "6", label: "Xã Mường Lầm" },
  { value: "7", label: "Xã Nậm Ty" },
  { value: "8", label: "Xã Đứa Mòn" },
  { value: "9", label: "Xã Yên Hưng" },
  { value: "10", label: "Xã Chiềng Sơ" },
  { value: "11", label: "Xã Nà Ngựu" },
  { value: "12", label: "Xã Nậm Mằn" },
  { value: "13", label: "Xã Chiềng Khoong" },
  { value: "14", label: "Xã Chiềng Cang" },
  { value: "15", label: "Xã Huổi Một" },
  { value: "16", label: "Xã Mường Sai" },
  { value: "17", label: "Xã Mường Cai" },
  { value: "18", label: "Xã Mường Hung" },
  { value: "19", label: "Xã Chiềng Khương" },
];
export const sonla_huyensopcop = [
  { value: "1", label: "Xã Sam Kha" },
  { value: "2", label: "Xã Púng Bánh" },
  { value: "3", label: "Xã Xốp Cộp" },
  { value: "4", label: "Xã Dồm Cang" },
  { value: "5", label: "Xã Nậm Lạnh" },
  { value: "6", label: "Xã Mường Lèo" },
  { value: "7", label: "Xã Mường Và" },
  { value: "8", label: "Xã Mường Lạn" },
];
export const sonla_huyenvanho = [
  { value: "1", label: "Xã Suối Bàng" },
  { value: "2", label: "Xã Song Khủa" },
  { value: "3", label: "Xã Liên Hòa" },
  { value: "4", label: "Xã Tô Múa" },
  { value: "5", label: "Xã Mường Tè" },
  { value: "6", label: "Xã Chiềng Khoa" },
  { value: "7", label: "Xã Mường Men" },
  { value: "8", label: "Xã Quang Minh" },
  { value: "9", label: "Xã Vân Hồ" },
  { value: "10", label: "Xã Lóng Luông" },
  { value: "11", label: "Xã Chiềng Yên" },
  { value: "12", label: "Xã Chiềng Xuân" },
  { value: "13", label: "Xã Xuân Nha" },
  { value: "14", label: "Xã Tân Xuân" },
];
export const yenbai_thanhphoyenbai = [
  { value: "1", label: "Phường Yên Thịnh" },
  { value: "2", label: "Phường Yên Ninh" },
  { value: "3", label: "Phường Minh Tân" },
  { value: "4", label: "Phường Nguyễn Thái Học" },
  { value: "5", label: "Phường Đồng Tâm" },
  { value: "6", label: "Phường Nguyễn Phúc" },
  { value: "7", label: "Phường Hồng Hà" },
  { value: "8", label: "Xã Minh Bảo" },
  { value: "9", label: "Phường Nam Cường" },
  { value: "10", label: "Xã Tuy Lộc" },
  { value: "11", label: "Xã Tân Thịnh" },
  { value: "12", label: "Xã Âu Lâu" },
  { value: "13", label: "Xã Giới Phiên" },
  { value: "14", label: "Phường Hợp Minh" },
  { value: "15", label: "Xã Văn Tiến" },
  { value: "16", label: "Xã Phúc Lộc" },
  { value: "17", label: "Xã Văn Phú" },
];
export const yenbai_xanghialo = [
  { value: "1", label: "Phường Pú Trạng" },
  { value: "2", label: "Phường Trung Tâm" },
  { value: "3", label: "Phường Tân An" },
  { value: "4", label: "Phường Cầu Thia" },
  { value: "5", label: "Xã Nghĩa Lợi" },
  { value: "6", label: "Xã Nghĩa Phúc" },
  { value: "7", label: "Xã Nghĩa An" },
];
export const yenbai_huyenlucyen = [
  { value: "1", label: "Thị trấn Yên Thế" },
  { value: "2", label: "Xã Tân Phượng" },
  { value: "3", label: "Xã Lâm Thượng" },
  { value: "4", label: "Xã Khánh Thiện" },
  { value: "5", label: "Xã Minh Chuẩn" },
  { value: "6", label: "Xã Mai Sơn" },
  { value: "7", label: "Xã Khai Trung" },
  { value: "8", label: "Xã Mường Lai" },
  { value: "9", label: "Xã An Lạc" },
  { value: "10", label: "Xã Minh Xuân" },
  { value: "11", label: "Xã Tô Mậu" },
  { value: "12", label: "Xã Tân Lĩnh" },
  { value: "13", label: "Xã Yên Thắng" },
  { value: "14", label: "Xã Khánh Hòa" },
  { value: "15", label: "Xã Vĩnh Lạc" },
  { value: "16", label: "Xã Liễu Đô" },
  { value: "17", label: "Xã Động Quan" },
  { value: "18", label: "Xã Tân Lập" },
  { value: "19", label: "Xã Minh Tiến" },
  { value: "20", label: "Xã Trúc Lâu" },
  { value: "21", label: "Xã Phúc Lợi" },
  { value: "22", label: "Xã Phan Thanh" },
  { value: "23", label: "Xã An Phú" },
  { value: "24", label: "Xã Trung Tâm" },
];
export const yenbai_huyenvanyen = [
  { value: "1", label: "Thị trấn Mậu A" },
  { value: "2", label: "Xã Lang Thíp" },
  { value: "3", label: "Xã Lâm Giang" },
  { value: "4", label: "Xã Châu Quế Thượng" },
  { value: "5", label: "Xã Châu Quế Hạ" },
  { value: "6", label: "Xã An Bình" },
  { value: "7", label: "Xã Quang Minh" },
  { value: "8", label: "Xã Đông An" },
  { value: "9", label: "Xã Đông Cuông" },
  { value: "10", label: "Xã Phong Dụ Hạ" },
  { value: "11", label: "Xã Mậu Đông" },
  { value: "12", label: "Xã Ngòi A" },
  { value: "13", label: "Xã Xuân Tầm" },
  { value: "14", label: "Xã Tân Hợp" },
  { value: "15", label: "Xã An Thịnh" },
  { value: "16", label: "Xã Yên Thái" },
  { value: "17", label: "Xã Phong Dụ Thượng" },
  { value: "18", label: "Xã Yên Hợp" },
  { value: "19", label: "Xã Đại Sơn" },
  { value: "20", label: "Xã Yên Hưng" },
  { value: "21", label: "Xã Đại Phác" },
  { value: "22", label: "Xã Yên Phú" },
  { value: "23", label: "Xã Xuân Ái" },
  { value: "24", label: "Xã Hoàng Thắng" },
  { value: "25", label: "Xã Viễn Sơn" },
  { value: "26", label: "Xã Mỏ Vàng" },
  { value: "27", label: "Xã Nà Hẩu" },
];
export const yenbai_huyenmucangchai = [
  { value: "1", label: "Thị trấn Mù Căng Chải" },
  { value: "2", label: "Xã Hồ Bốn" },
  { value: "3", label: "Xã Nậm Có" },
  { value: "4", label: "Xã Khao Mang" },
  { value: "5", label: "Xã Mồ Dề" },
  { value: "6", label: "Xã Chế Cu Nha" },
  { value: "7", label: "Xã Lao Chải" },
  { value: "8", label: "Xã Kim Nọi" },
  { value: "9", label: "Xã Cao Phạ" },
  { value: "10", label: "Xã La Pán Tẩn" },
  { value: "11", label: "Xã Dế Su Phình" },
  { value: "12", label: "Xã Chế Tạo" },
  { value: "13", label: "Xã Púng Luông" },
  { value: "14", label: "Xã Nậm Khắt" },
];
export const yenbai_huyentranyen = [
  { value: "1", label: "Thị trấn Cổ Phúc" },
  { value: "2", label: "Xã Tân Đồng" },
  { value: "3", label: "Xã Báo Đáp" },
  { value: "4", label: "Xã Đào Thịnh" },
  { value: "5", label: "Xã Việt Thành" },
  { value: "6", label: "Xã Hòa Cuông" },
  { value: "7", label: "Xã Minh Quán" },
  { value: "8", label: "Xã Quy Mông" },
  { value: "9", label: "Xã Cường Thịnh" },
  { value: "10", label: "Xã Kiên Thành" },
  { value: "11", label: "Xã Nga Quán" },
  { value: "12", label: "Xã Y Can" },
  { value: "13", label: "Xã Minh Tiến" },
  { value: "14", label: "Xã Lương Thịnh" },
  { value: "15", label: "Xã Bảo Hưng" },
  { value: "16", label: "Xã Việt Cường" },
  { value: "17", label: "Xã Minh Quân" },
  { value: "18", label: "Xã Hồng Ca" },
  { value: "19", label: "Xã Hưng Thịnh" },
  { value: "20", label: "Xã Hưng Khánh" },
  { value: "21", label: "Xã Việt Hồng" },
  { value: "22", label: "Xã Vân Hội" },
];
export const yenbai_huyentramtau = [
  { value: "1", label: "Thị trấn Trạm Tấu" },
  { value: "2", label: "Xã Túc Đán" },
  { value: "3", label: "Xã Pá Lau" },
  { value: "4", label: "Xã Xà Hồ" },
  { value: "5", label: "Xã Phình Hồ" },
  { value: "6", label: "Xã Trạm Tấu" },
  { value: "7", label: "Xã Tà Si Láng" },
  { value: "8", label: "Xã Pá Hu" },
  { value: "9", label: "Xã Làng Nhì" },
  { value: "10", label: "Xã Bản Công" },
  { value: "11", label: "Xã Bản Mù" },
  { value: "12", label: "Xã Hát Lìu" },
];
export const yenbai_huyenvanchan = [
  { value: "1", label: "Thị trấn NT Liên Sơn" },
  { value: "2", label: "Thị trấn NT Nghĩa Lộ" },
  { value: "3", label: "Thị trấn NT Trần Phú" },
  { value: "4", label: "Xã Tú Lệ" },
  { value: "5", label: "Xã Nậm Búng" },
  { value: "6", label: "Xã Gia Hội" },
  { value: "7", label: "Xã Sùng Đô" },
  { value: "8", label: "Xã Nậm Mười" },
  { value: "9", label: "Xã An Lương" },
  { value: "10", label: "Xã Nậm Lành" },
  { value: "11", label: "Xã Sơn Lương" },
  { value: "12", label: "Xã Suối Quyền" },
  { value: "13", label: "Xã Suối Giàng" },
  { value: "14", label: "Xã Sơn A" },
  { value: "15", label: "Xã Phù Nham" },
  { value: "16", label: "Xã Nghĩa Sơn" },
  { value: "17", label: "Xã Suối Bu" },
  { value: "18", label: "Xã Sơn Thịnh" },
  { value: "19", label: "Xã Thanh Lương" },
  { value: "20", label: "Xã Hạnh Sơn" },
  { value: "21", label: "Xã Phúc Sơn" },
  { value: "22", label: "Xã Thạch Lương" },
  { value: "23", label: "Xã Đại Lịch" },
  { value: "24", label: "Xã Đồng Khê" },
  { value: "25", label: "Xã Cát Thịnh" },
  { value: "26", label: "Xã Tân Thịnh" },
  { value: "27", label: "Xã Chấn Thịnh" },
  { value: "28", label: "Xã Bình Thuận" },
  { value: "29", label: "Xã Thượng Bằng La" },
  { value: "30", label: "Xã Minh An" },
  { value: "31", label: "Xã Nghĩa Tâm" },
];
export const yenbai_huyenyenbinh = [
  { value: "1", label: "Xã Văn Lãng" },
  { value: "2", label: "Thị trấn Yên Bình" },
  { value: "3", label: "Thị trấn Thác Bà" },
  { value: "4", label: "Xã Xuân Long" },
  { value: "5", label: "Xã Tích Cốc" },
  { value: "6", label: "Xã Cảm Nhân" },
  { value: "7", label: "Xã Ngọc Chấn" },
  { value: "8", label: "Xã Tân Nguyên" },
  { value: "9", label: "Xã Phúc Ninh" },
  { value: "10", label: "Xã Bảo Ái" },
  { value: "11", label: "Xã Mỹ Gia" },
  { value: "12", label: "Xã Xuân Lai" },
  { value: "13", label: "Xã Mông Sơn" },
  { value: "14", label: "Xã Cảm Ân" },
  { value: "15", label: "Xã Yên Thành" },
  { value: "16", label: "Xã Tân Hương" },
  { value: "17", label: "Xã Phúc An" },
  { value: "18", label: "Xã Bạch Hà" },
  { value: "19", label: "Xã Vũ Linh" },
  { value: "20", label: "Xã Đại Đồng" },
  { value: "21", label: "Xã Vĩnh Kiên" },
  { value: "22", label: "Xã Yên Bình" },
  { value: "23", label: "Xã Thịnh Hưng" },
  { value: "24", label: "Xã Hán Đà" },
  { value: "25", label: "Xã Phú Thịnh" },
  { value: "26", label: "Xã Đại Minh" },
];
export const hoabinh_thanhphohoabinh = [
  { value: "1", label: "Phường Thái Bình" },
  { value: "2", label: "Phường Tân Hòa" },
  { value: "3", label: "Phường Thịnh Lang" },
  { value: "4", label: "Phường Hữu Nghị" },
  { value: "5", label: "Phường Tân Thịnh" },
  { value: "6", label: "Phường Đồng Tiến" },
  { value: "7", label: "Phường Phương Lâm" },
  { value: "8", label: "Phường Chăm Mát" },
  { value: "9", label: "Xã Yên Mông" },
  { value: "10", label: "Xã Sủ Ngòi" },
  { value: "11", label: "Xã Dân Chủ" },
  { value: "12", label: "Xã Thái Thịnh" },
  { value: "13", label: "Xã Hòa Bình" },
  { value: "14", label: "Xã Thống Nhất" },
  { value: "15", label: "Xã Trung Minh" },
];
export const hoabinh_huyendabac = [
  { value: "1", label: "Thị trấn Đà Bắc" },
  { value: "2", label: "Xã Đồng Nghê" },
  { value: "3", label: "Xã Suối Nánh" },
  { value: "4", label: "Xã Giáp Đắt" },
  { value: "5", label: "Xã Mường Tuổng" },
  { value: "6", label: "Xã Mường Chiềng" },
  { value: "7", label: "Xã Tân Pheo" },
  { value: "8", label: "Xã Đồng Chum" },
  { value: "9", label: "Xã Tân Minh" },
  { value: "10", label: "Xã Đoàn Kết" },
  { value: "11", label: "Xã Đồng Ruộng" },
  { value: "12", label: "Xã Hào Lý" },
  { value: "13", label: "Xã Tu Lý" },
  { value: "14", label: "Xã Trung Thành" },
  { value: "15", label: "Xã Yên Hòa" },
  { value: "16", label: "Xã Cao Sơn" },
  { value: "17", label: "Xã Toàn Sơn" },
  { value: "18", label: "Xã Hiền Lương" },
  { value: "19", label: "Xã Tiền Phong" },
  { value: "20", label: "Xã Vầy Nưa" },
];
export const hoabinh_huyenkyson = [
  { value: "1", label: "Thị trấn Kỳ Sơn" },
  { value: "2", label: "Xã Hợp Thịnh" },
  { value: "3", label: "Xã Phú Minh" },
  { value: "4", label: "Xã Hợp Thành" },
  { value: "5", label: "Xã Phúc Tiến" },
  { value: "6", label: "Xã Dân Hòa" },
  { value: "7", label: "Xã Mông Hóa" },
  { value: "8", label: "Xã Dân Hạ" },
  { value: "9", label: "Xã Độc Lập" },
  { value: "10", label: "Xã Yên Quang" },
];
export const hoabinh_huyenluongson = [
  { value: "1", label: "Thị trấn Lương Sơn" },
  { value: "2", label: "Xã Lâm Sơn" },
  { value: "3", label: "Xã Hòa Sơn" },
  { value: "4", label: "Xã Trường Sơn" },
  { value: "5", label: "Xã Tân Vinh" },
  { value: "6", label: "Xã Nhuận Trạch" },
  { value: "7", label: "Xã Cao Răm" },
  { value: "8", label: "Xã Cư Yên" },
  { value: "9", label: "Xã Hợp Hòa" },
  { value: "10", label: "Xã Liên Sơn" },
  { value: "11", label: "Xã Thành Lập" },
  { value: "12", label: "Xã Tiến Sơn" },
  { value: "13", label: "Xã Trung Sơn" },
  { value: "14", label: "Xã Tân Thành" },
  { value: "15", label: "Xã Cao Dương" },
  { value: "16", label: "Xã Hợp Châu" },
  { value: "17", label: "Xã Long Sơn" },
  { value: "18", label: "Xã Cao Thắng" },
  { value: "19", label: "Xã Thanh Lương" },
  { value: "20", label: "Xã Hợp Thanh" },
];
export const hoabinh_huyenkimboi = [
  { value: "1", label: "Thị trấn Bo" },
  { value: "2", label: "Xã Đú Sáng" },
  { value: "3", label: "Xã Bắc Sơn" },
  { value: "4", label: "Xã Bình Sơn" },
  { value: "5", label: "Xã Hùng Tiến" },
  { value: "6", label: "Xã Tú Sơn" },
  { value: "7", label: "Xã Nật Sơn" },
  { value: "8", label: "Xã Vĩnh Tiến" },
  { value: "9", label: "Xã Sơn Thủy" },
  { value: "10", label: "Xã Đông Bắc" },
  { value: "11", label: "Xã Thượng Bì" },
  { value: "12", label: "Xã Lập Chiệng" },
  { value: "13", label: "Xã Vĩnh Đồng" },
  { value: "14", label: "Xã Hạ Bì" },
  { value: "15", label: "Xã Trung Bì" },
  { value: "16", label: "Xã Kim Sơn" },
  { value: "17", label: "Xã Hợp Đồng" },
  { value: "18", label: "Xã Thượng Tiến" },
  { value: "19", label: "Xã Kim Tiến" },
  { value: "20", label: "Xã Kim Bình" },
  { value: "21", label: "Xã Hợp Kim" },
  { value: "22", label: "Xã Kim Bôi" },
  { value: "23", label: "Xã Nam Thượng" },
  { value: "24", label: "Xã Kim Truy" },
  { value: "25", label: "Xã Cuối Hạ" },
  { value: "26", label: "Xã Sào Báy" },
  { value: "27", label: "Xã Mi Hòa" },
  { value: "28", label: "Xã Nuông Dăm" },
];
export const hoabinh_huyencaophong = [
  { value: "1", label: "Thị trấn Cao Phong" },
  { value: "2", label: "Xã Bình Thanh" },
  { value: "3", label: "Xã Thung Nai" },
  { value: "4", label: "Xã Bắc Phong" },
  { value: "5", label: "Xã Thu Phong" },
  { value: "6", label: "Xã Đông Phong" },
  { value: "7", label: "Xã Xuân Phong" },
  { value: "8", label: "Xã Tây Phong" },
  { value: "9", label: "Xã Tân Phong" },
  { value: "10", label: "Xã Dũng Phong" },
  { value: "11", label: "Xã Nam Phong" },
  { value: "12", label: "Xã Yên Lập" },
  { value: "13", label: "Xã Yên Thượng" },
];
export const hoabinh_huyentanlac = [
  { value: "1", label: "Thị trấn Mường Khến" },
  { value: "2", label: "Xã Ngòi Hoa" },
  { value: "3", label: "Xã Trung Hòa" },
  { value: "4", label: "Xã Phú Vinh" },
  { value: "5", label: "Xã Phú Cường" },
  { value: "6", label: "Xã Mỹ Hòa" },
  { value: "7", label: "Xã Quy Hậu" },
  { value: "8", label: "Xã Phong Phú" },
  { value: "9", label: "Xã Quyết Chiến" },
  { value: "10", label: "Xã Mãn Đức" },
  { value: "11", label: "Xã Địch Giáo" },
  { value: "12", label: "Xã Tuân Lộ" },
  { value: "13", label: "Xã Tử Nê" },
  { value: "14", label: "Xã Thanh Hối" },
  { value: "15", label: "Xã Ngọc Mỹ" },
  { value: "16", label: "Xã Đông Lai" },
  { value: "17", label: "Xã Lũng Vân" },
  { value: "18", label: "Xã Bắc Sơn" },
  { value: "19", label: "Xã Quy Mỹ" },
  { value: "20", label: "Xã Do Nhân" },
  { value: "21", label: "Xã Nam Sơn" },
  { value: "22", label: "Xã Lỗ Sơn" },
  { value: "23", label: "Xã Ngổ Luông" },
  { value: "24", label: "Xã Gia Mô" },
];
export const hoabinh_huyenmaichau = [
  { value: "1", label: "Xã Tân Dân" },
  { value: "2", label: "Thị trấn Mai Châu" },
  { value: "3", label: "Xã Tân Mai" },
  { value: "4", label: "Xã Phúc Sạn" },
  { value: "5", label: "Xã Pà Cò" },
  { value: "6", label: "Xã Hang Kia" },
  { value: "7", label: "Xã Ba Khan" },
  { value: "8", label: "Xã Tân Sơn" },
  { value: "9", label: "Xã Đồng Bảng" },
  { value: "10", label: "Xã Cun Pheo" },
  { value: "11", label: "Xã Bao La" },
  { value: "12", label: "Xã Piềng Vế" },
  { value: "13", label: "Xã Tòng Đậu" },
  { value: "14", label: "Xã Nà Mèo" },
  { value: "15", label: "Xã Thung Khe" },
  { value: "16", label: "Xã Nà Phòn" },
  { value: "17", label: "Xã Săm Khóe" },
  { value: "18", label: "Xã Chiềng Châu" },
  { value: "19", label: "Xã Mai Hạ" },
  { value: "20", label: "Xã Nong Luông" },
  { value: "21", label: "Xã Mai Hịch" },
  { value: "22", label: "Xã Pù Pin" },
  { value: "23", label: "Xã Vạn Mai" },
];
export const hoabinh_huyenlacson = [
  { value: "1", label: "Thị trấn Vụ Bản" },
  { value: "2", label: "Xã Quý Hòa" },
  { value: "3", label: "Xã Miền Đồi" },
  { value: "4", label: "Xã Mỹ Thành" },
  { value: "5", label: "Xã Tuân Đạo" },
  { value: "6", label: "Xã Văn Nghĩa" },
  { value: "7", label: "Xã Văn Sơn" },
  { value: "8", label: "Xã Tân Lập" },
  { value: "9", label: "Xã Nhân Nghĩa" },
  { value: "10", label: "Xã Thượng Cốc" },
  { value: "11", label: "Xã Phú Lương" },
  { value: "12", label: "Xã Phúc Tuy" },
  { value: "13", label: "Xã Xuất Hóa" },
  { value: "14", label: "Xã Yên Phú" },
  { value: "15", label: "Xã Bình Hẻm" },
  { value: "16", label: "Xã Chí Thiện" },
  { value: "17", label: "Xã Bình Cảng" },
  { value: "18", label: "Xã Bình Chân" },
  { value: "19", label: "Xã Định Cư" },
  { value: "20", label: "Xã Chí Đạo" },
  { value: "21", label: "Xã Liên Vũ" },
  { value: "22", label: "Xã Ngọc Sơn" },
  { value: "23", label: "Xã Hương Nhượng" },
  { value: "24", label: "Xã Vũ Lâm" },
  { value: "25", label: "Xã Tự Do" },
  { value: "26", label: "Xã Yên Nghiệp" },
  { value: "27", label: "Xã Tân Mỹ" },
  { value: "28", label: "Xã Ân Nghĩa" },
  { value: "29", label: "Xã Ngọc Lâu" },
];
export const hoabinh_huyenyenthuy = [
  { value: "1", label: "Thị trấn Hàng Trạm" },
  { value: "2", label: "Xã Lạc Sỹ" },
  { value: "3", label: "Xã Lạc Hưng" },
  { value: "4", label: "Xã Lạc Lương" },
  { value: "5", label: "Xã Bảo Hiệu" },
  { value: "6", label: "Xã Đa Phúc" },
  { value: "7", label: "Xã Hữu Lợi" },
  { value: "8", label: "Xã Lạc Thịnh" },
  { value: "9", label: "Xã Yên Lạc" },
  { value: "10", label: "Xã Đoàn Kết" },
  { value: "11", label: "Xã Phú Lai" },
  { value: "12", label: "Xã Yên Trị" },
  { value: "13", label: "Xã Ngọc Lương" },
];
export const hoabinh_huyenlacthuy = [
  { value: "1", label: "Thị trấn Thanh Hà" },
  { value: "2", label: "Xã Thanh Nông" },
  { value: "3", label: "Thị trấn Chi Nê" },
  { value: "4", label: "Xã Phú Lão" },
  { value: "5", label: "Xã Phú Thành" },
  { value: "6", label: "Xã Cố Nghĩa" },
  { value: "7", label: "Xã Hưng Thi" },
  { value: "8", label: "Xã Lạc Long" },
  { value: "9", label: "Xã Liên Hòa" },
  { value: "10", label: "Xã Khoan Dụ" },
  { value: "11", label: "Xã Đồng Môn" },
  { value: "12", label: "Xã Đồng Tâm" },
  { value: "13", label: "Xã Yên Bồng" },
  { value: "14", label: "Xã An Lạc" },
  { value: "15", label: "Xã An Bình" },
];
export const thainguyen_thanhphothainguyen = [
  { value: "1", label: "Phường Quán Triều" },
  { value: "2", label: "Phường Quang Vinh" },
  { value: "3", label: "Phường Túc Duyên" },
  { value: "4", label: "Phường Hoàng Văn Thụ" },
  { value: "5", label: "Phường Trưng Vương" },
  { value: "6", label: "Phường Quang Trung" },
  { value: "7", label: "Phường Phan Đình Phùng" },
  { value: "8", label: "Phường Tân Thịnh" },
  { value: "9", label: "Phường Thịnh Đán" },
  { value: "10", label: "Phường Đồng Quang" },
  { value: "11", label: "Phường Gia Sàng" },
  { value: "12", label: "Phường Tân Lập" },
  { value: "13", label: "Phường Cam Giá" },
  { value: "14", label: "Phường Phú Xá" },
  { value: "15", label: "Phường Hương Sơn" },
  { value: "16", label: "Phường Trung Thành" },
  { value: "17", label: "Phường Tân Thành" },
  { value: "18", label: "Phường Tân Long" },
  { value: "19", label: "Xã Phúc Hà" },
  { value: "20", label: "Xã Phúc Xuân" },
  { value: "21", label: "Xã Quyết Thắng" },
  { value: "22", label: "Xã Phúc Trìu" },
  { value: "23", label: "Xã Thịnh Đức" },
  { value: "24", label: "Phường Tích Lương" },
  { value: "25", label: "Xã Tân Cương" },
  { value: "26", label: "Xã Sơn Cẩm" },
  { value: "27", label: "Phường Chùa Hang" },
  { value: "28", label: "Xã Cao Ngạn" },
  { value: "29", label: "Xã Linh Sơn" },
  { value: "30", label: "Phường Đồng Bẩm" },
  { value: "31", label: "Xã Huống Thượng" },
  { value: "32", label: "Xã Đồng Liên" },
];
export const thainguyen_thanhphosongcong = [
  { value: "1", label: "Phường Lương Sơn" },
  { value: "2", label: "Phường Lương Châu" },
  { value: "3", label: "Phường Mỏ Chè" },
  { value: "4", label: "Phường Cải Đan" },
  { value: "5", label: "Phường Thắng Lợi" },
  { value: "6", label: "Phường Phố Cò" },
  { value: "7", label: "Xã Vinh Sơn" },
  { value: "8", label: "Xã Tân Quang" },
  { value: "9", label: "Phường Bách Quang" },
  { value: "10", label: "Xã Bình Sơn" },
  { value: "11", label: "Xã Bá Xuyên" },
];
export const thainguyen_huyendinhhoa = [
  { value: "1", label: "Thị trấn Chợ Chu" },
  { value: "2", label: "Xã Linh Thông" },
  { value: "3", label: "Xã Lam Vỹ" },
  { value: "4", label: "Xã Quy Kỳ" },
  { value: "5", label: "Xã Tân Thịnh" },
  { value: "6", label: "Xã Kim Phượng" },
  { value: "7", label: "Xã Bảo Linh" },
  { value: "8", label: "Xã Kim Sơn" },
  { value: "9", label: "Xã Phúc Chu" },
  { value: "10", label: "Xã Tân Dương" },
  { value: "11", label: "Xã Phượng Tiến" },
  { value: "12", label: "Xã Bảo Cường" },
  { value: "13", label: "Xã Đồng Thịnh" },
  { value: "14", label: "Xã Định Biên" },
  { value: "15", label: "Xã Thanh Định" },
  { value: "16", label: "Xã Trung Hội" },
  { value: "17", label: "Xã Trung Lương" },
  { value: "18", label: "Xã Bình Yên" },
  { value: "19", label: "Xã Điềm Mặc" },
  { value: "20", label: "Xã Phú Tiến" },
  { value: "21", label: "Xã Bộc Nhiêu" },
  { value: "22", label: "Xã Sơn Phú" },
  { value: "23", label: "Xã Phú Đình" },
  { value: "24", label: "Xã Bình Thành" },
];
export const thainguyen_huyenphuluong = [
  { value: "1", label: "Thị trấn Giang Tiên" },
  { value: "2", label: "Thị trấn Đu" },
  { value: "3", label: "Xã Yên Ninh" },
  { value: "4", label: "Xã Yên Trạch" },
  { value: "5", label: "Xã Yên Đổ" },
  { value: "6", label: "Xã Yên Lạc" },
  { value: "7", label: "Xã Ôn Lương" },
  { value: "8", label: "Xã Động Đạt" },
  { value: "9", label: "Xã Phủ Lý" },
  { value: "10", label: "Xã Phú Đô" },
  { value: "11", label: "Xã Hợp Thành" },
  { value: "12", label: "Xã Tức Tranh" },
  { value: "13", label: "Xã Phấn Mễ" },
  { value: "14", label: "Xã Vô Tranh" },
  { value: "15", label: "Xã Cổ Lũng" },
];
export const thainguyen_huyendonghy = [
  { value: "1", label: "Thị trấn Sông Cầu" },
  { value: "2", label: "Thị trấn Trại Cau" },
  { value: "3", label: "Xã Văn Lăng" },
  { value: "4", label: "Xã Tân Long" },
  { value: "5", label: "Xã Hòa Bình" },
  { value: "6", label: "Xã Quang Sơn" },
  { value: "7", label: "Xã Minh Lập" },
  { value: "8", label: "Xã Văn Hán" },
  { value: "9", label: "Xã Hóa Trung" },
  { value: "10", label: "Xã Khe Mo" },
  { value: "11", label: "Xã Cây Thị" },
  { value: "12", label: "Xã Hóa Thượng" },
  { value: "13", label: "Xã Hợp Tiến" },
  { value: "14", label: "Xã Tân Lợi" },
  { value: "15", label: "Xã Nam Hòa" },
];
export const thainguyen_huyenvonhai = [
  { value: "1", label: "Thị trấn Đình Cả" },
  { value: "2", label: "Xã Sảng Mộc" },
  { value: "3", label: "Xã Nghinh Tường" },
  { value: "4", label: "Xã Thần Xa" },
  { value: "5", label: "Xã Vũ Chấn" },
  { value: "6", label: "Xã Thượng Nung" },
  { value: "7", label: "Xã Phú Thượng" },
  { value: "8", label: "Xã Cúc Đường" },
  { value: "9", label: "Xã La Hiên" },
  { value: "10", label: "Xã Lâu Thượng" },
  { value: "11", label: "Xã Tràng Xá" },
  { value: "12", label: "Xã Phương Giao" },
  { value: "13", label: "Xã Liên Minh" },
  { value: "14", label: "Xã Dân Tiến" },
  { value: "15", label: "Xã Bình Long" },
];
export const thainguyen_huyendaitu = [
  { value: "1", label: "Thị trấn Hùng Sơn" },
  { value: "2", label: "Thị trấn Quân Chu" },
  { value: "3", label: "Xã Phúc Lương" },
  { value: "4", label: "Xã Minh Tiến" },
  { value: "5", label: "Xã Yên Lãng" },
  { value: "6", label: "Xã Đức Lương" },
  { value: "7", label: "Xã Phú Cường" },
  { value: "8", label: "Xã Na Mao" },
  { value: "9", label: "Xã Phú Lạc" },
  { value: "10", label: "Xã Tân Linh" },
  { value: "11", label: "Xã Phú Thịnh" },
  { value: "12", label: "Xã Phục Linh" },
  { value: "13", label: "Xã Phú Xuyên" },
  { value: "14", label: "Xã Bản Ngoại" },
  { value: "15", label: "Xã Tiên Hội" },
  { value: "16", label: "Xã Cù Vân" },
  { value: "17", label: "Xã Hà Thượng" },
  { value: "18", label: "Xã La Bằng" },
  { value: "19", label: "Xã Hoàng Nông" },
  { value: "20", label: "Xã Khôi Kỳ" },
  { value: "21", label: "Xã An Khánh" },
  { value: "22", label: "Xã Tân Thái" },
  { value: "23", label: "Xã Bình Thuận" },
  { value: "24", label: "Xã Lục Ba" },
  { value: "25", label: "Xã Mỹ Yên" },
  { value: "26", label: "Xã Vạn Thọ" },
  { value: "27", label: "Xã Văn Yên" },
  { value: "28", label: "Xã Ký Phú" },
  { value: "29", label: "Xã Cát Nê" },
  { value: "30", label: "Xã Quân Chu" },
];
export const thainguyen_xaphoyen = [
  { value: "1", label: "Phường Bãi Bông" },
  { value: "2", label: "Phường Bắc Sơn" },
  { value: "3", label: "Phường Ba Hàng" },
  { value: "4", label: "Xã Phúc Tân" },
  { value: "5", label: "Xã Phúc Thuận" },
  { value: "6", label: "Xã Hồng Tiến" },
  { value: "7", label: "Xã Minh Đức" },
  { value: "8", label: "Xã Đắc Sơn" },
  { value: "9", label: "Phường Đồng Tiến" },
  { value: "10", label: "Xã Thành Công" },
  { value: "11", label: "Xã Tiên Phong" },
  { value: "12", label: "Xã Vạn Phái" },
  { value: "13", label: "Xã Nam Tiến" },
  { value: "14", label: "Xã Tân Hương" },
  { value: "15", label: "Xã Đông Cao" },
  { value: "16", label: "Xã Trung Thành" },
  { value: "17", label: "Xã Tân Phú" },
  { value: "18", label: "Xã Thuận Thành" },
];
export const thainguyen_huyenphubinh = [
  { value: "1", label: "Thị trấn Hương Sơn" },
  { value: "2", label: "Xã Bàn Đạt" },
  { value: "3", label: "Xã Tân Khánh" },
  { value: "4", label: "Xã Tân Kim" },
  { value: "5", label: "Xã Tân Thành" },
  { value: "6", label: "Xã Đào Xá" },
  { value: "7", label: "Xã Bảo Lý" },
  { value: "8", label: "Xã Thượng Đình" },
  { value: "9", label: "Xã Tân Hòa" },
  { value: "10", label: "Xã Nhã Lộng" },
  { value: "11", label: "Xã Điềm Thụy" },
  { value: "12", label: "Xã Xuân Phương" },
  { value: "13", label: "Xã Tân Đức" },
  { value: "14", label: "Xã Úc Kỳ" },
  { value: "15", label: "Xã Lương Phú" },
  { value: "16", label: "Xã Nga My" },
  { value: "17", label: "Xã Kha Sơn" },
  { value: "18", label: "Xã Thanh Ninh" },
  { value: "19", label: "Xã Dương Thành" },
  { value: "20", label: "Xã Hà Châu" },
];
export const langson_thanhpholangson = [
  { value: "1", label: "Phường Hoàng Văn Thụ" },
  { value: "2", label: "Phường Tam Thanh" },
  { value: "3", label: "Phường Vĩnh Trại" },
  { value: "4", label: "Phường Đông Kinh" },
  { value: "5", label: "Phường Chi Lăng" },
  { value: "6", label: "Xã Hoàng Đồng" },
  { value: "7", label: "Xã Quảng Lạc" },
  { value: "8", label: "Xã Mai Pha" },
];
export const langson_huyentrangdinh = [
  { value: "1", label: "Thị trấn Thất Khê" },
  { value: "2", label: "Xã Khánh Long" },
  { value: "3", label: "Xã Đoàn Kết" },
  { value: "4", label: "Xã Quốc Khánh" },
  { value: "5", label: "Xã Vĩnh Tiến" },
  { value: "6", label: "Xã Cao Minh" },
  { value: "7", label: "Xã Chí Minh" },
  { value: "8", label: "Xã Tri Phương" },
  { value: "9", label: "Xã Tân Tiến" },
  { value: "10", label: "Xã Tân Yên" },
  { value: "11", label: "Xã Đội Cấn" },
  { value: "12", label: "Xã Tân Minh" },
  { value: "13", label: "Xã Kim Đồng" },
  { value: "14", label: "Xã Chi Lăng" },
  { value: "15", label: "Xã Trung Thành" },
  { value: "16", label: "Xã Đại Đồng" },
  { value: "17", label: "Xã Đào Viên" },
  { value: "18", label: "Xã Đề Thám" },
  { value: "19", label: "Xã Kháng Chiến" },
  { value: "20", label: "Xã Bắc Ái" },
  { value: "21", label: "Xã Hùng Sơn" },
  { value: "22", label: "Xã Quốc Việt" },
  { value: "23", label: "Xã Hùng Việt" },
];
export const langson_huyenbinhgia = [
  { value: "1", label: "Thị trấn Bình Gia" },
  { value: "2", label: "Xã Hưng Đạo" },
  { value: "3", label: "Xã Vĩnh Yên" },
  { value: "4", label: "Xã Hoa Thám" },
  { value: "5", label: "Xã Quý Hòa" },
  { value: "6", label: "Xã Hồng Phong" },
  { value: "7", label: "Xã Yên Lỗ" },
  { value: "8", label: "Xã Thiện Hòa" },
  { value: "9", label: "Xã Quang Trung" },
  { value: "10", label: "Xã Thiện Thuật" },
  { value: "11", label: "Xã Minh Khai" },
  { value: "12", label: "Xã Thiện Long" },
  { value: "13", label: "Xã Hoàng Văn Thụ" },
  { value: "14", label: "Xã Hòa Bình" },
  { value: "15", label: "Xã Mông Ân" },
  { value: "16", label: "Xã Tân Hòa" },
  { value: "17", label: "Xã Tô Hiệu" },
  { value: "18", label: "Xã Hồng Thái" },
  { value: "19", label: "Xã Bình La" },
  { value: "20", label: "Xã Tân Văn" },
];
export const langson_huyenvanlang = [
  { value: "1", label: "Thị trấn Na Sầm" },
  { value: "2", label: "Xã Trùng Khánh" },
  { value: "3", label: "Xã Tân Việt" },
  { value: "4", label: "Xã Bắc La" },
  { value: "5", label: "Xã Thụy Hùng" },
  { value: "6", label: "Xã Trùng Quán" },
  { value: "7", label: "Xã Tân Tác" },
  { value: "8", label: "Xã An Hùng" },
  { value: "9", label: "Xã Thanh Long" },
  { value: "10", label: "Xã Hội Hoan" },
  { value: "11", label: "Xã Tân Lang" },
  { value: "12", label: "Xã Hoàng Việt" },
  { value: "13", label: "Xã Gia Miễn" },
  { value: "14", label: "Xã Thành Hòa" },
  { value: "15", label: "Xã Tân Thanh" },
  { value: "16", label: "Xã Nam La" },
  { value: "17", label: "Xã Tân Mỹ" },
  { value: "18", label: "Xã Hồng Thái" },
  { value: "19", label: "Xã Hoàng Văn Thụ" },
  { value: "20", label: "Xã Nhạc Kỳ" },
];
export const langson_huyencaoloc = [
  { value: "1", label: "Thị trấn Đồng Đăng" },
  { value: "2", label: "Thị trấn Cao Lộc" },
  { value: "3", label: "Xã Bảo Lâm" },
  { value: "4", label: "Xã Thanh Lòa" },
  { value: "5", label: "Xã Cao Lâu" },
  { value: "6", label: "Xã Thạch Đạn" },
  { value: "7", label: "Xã Xuất Lễ" },
  { value: "8", label: "Xã Hồng Phong" },
  { value: "9", label: "Xã Thụy Hùng" },
  { value: "10", label: "Xã Lộc Yên" },
  { value: "11", label: "Xã Phú Xá" },
  { value: "12", label: "Xã Bình Trung" },
  { value: "13", label: "Xã Hải Yến" },
  { value: "14", label: "Xã Hòa Cư" },
  { value: "15", label: "Xã Hợp Thành" },
  { value: "16", label: "Xã Song Giáp" },
  { value: "17", label: "Xã Công Sơn" },
  { value: "18", label: "Xã Gia Cát" },
  { value: "19", label: "Xã Mẫu Sơn" },
  { value: "20", label: "Xã Xuân Long" },
  { value: "21", label: "Xã Tân Liên" },
  { value: "22", label: "Xã Yên Trạch" },
  { value: "23", label: "Xã Tân Thành" },
];
export const langson_huyenvanquan = [
  { value: "1", label: "Thị trấn Văn Quan" },
  { value: "2", label: "Xã Trấn Ninh" },
  { value: "3", label: "Xã Phú Mỹ" },
  { value: "4", label: "Xã Việt Yên" },
  { value: "5", label: "Xã Song Giang" },
  { value: "6", label: "Xã Vân Mộng" },
  { value: "7", label: "Xã Vĩnh Lại" },
  { value: "8", label: "Xã Hòa Bình" },
  { value: "9", label: "Xã Tú Xuyên" },
  { value: "10", label: "Xã Văn An" },
  { value: "11", label: "Xã Đại An" },
  { value: "12", label: "Xã Khánh Khê" },
  { value: "13", label: "Xã Chu Túc" },
  { value: "14", label: "Xã Lương Năng" },
  { value: "15", label: "Xã Đồng Giáp" },
  { value: "16", label: "Xã Xuân Mai" },
  { value: "17", label: "Xã Tràng Các" },
  { value: "18", label: "Xã Tràng Sơn" },
  { value: "19", label: "Xã Tân Đoàn" },
  { value: "20", label: "Xã Bình Phúc" },
  { value: "21", label: "Xã Tri Lễ" },
  { value: "22", label: "Xã Tràng Phái" },
  { value: "23", label: "Xã Yên Phúc" },
  { value: "24", label: "Xã Hữu Lễ" },
];
export const langson_huyenbacson = [
  { value: "1", label: "Thị trấn Bắc Sơn" },
  { value: "2", label: "Xã Long Đống" },
  { value: "3", label: "Xã Vạn Thủy" },
  { value: "4", label: "Xã Quỳnh Sơn" },
  { value: "5", label: "Xã Đồng ý" },
  { value: "6", label: "Xã Tân Tri" },
  { value: "7", label: "Xã Bắc Sơn" },
  { value: "8", label: "Xã Hữu Vĩnh" },
  { value: "9", label: "Xã Hưng Vũ" },
  { value: "10", label: "Xã Tân Lập" },
  { value: "11", label: "Xã Vũ Sơn" },
  { value: "12", label: "Xã Chiêu Vũ" },
  { value: "13", label: "Xã Tân Hương" },
  { value: "14", label: "Xã Chiến Thắng" },
  { value: "15", label: "Xã Vũ Lăng" },
  { value: "16", label: "Xã Trấn Yên" },
  { value: "17", label: "Xã Vũ Lễ" },
  { value: "18", label: "Xã Nhất Hòa" },
  { value: "19", label: "Xã Tân Thành" },
  { value: "20", label: "Xã Nhất Tiến" },
];
export const langson_huyenhuulung = [
  { value: "1", label: "Thị trấn Hữu Lũng" },
  { value: "2", label: "Xã Hữu Liên" },
  { value: "3", label: "Xã Yên Bình" },
  { value: "4", label: "Xã Quyết Thắng" },
  { value: "5", label: "Xã Hòa Bình" },
  { value: "6", label: "Xã Yên Thịnh" },
  { value: "7", label: "Xã Yên Sơn" },
  { value: "8", label: "Xã Thiện Ky" },
  { value: "9", label: "Xã Tân Lập" },
  { value: "10", label: "Xã Yên Vượng" },
  { value: "11", label: "Xã Minh Tiến" },
  { value: "12", label: "Xã Nhật Tiến" },
  { value: "13", label: "Xã Thanh Sơn" },
  { value: "14", label: "Xã Đồng Tân" },
  { value: "15", label: "Xã Cai Kinh" },
  { value: "16", label: "Xã Hòa Lạc" },
  { value: "17", label: "Xã Vân Nham" },
  { value: "18", label: "Xã Đồng Tiến" },
  { value: "19", label: "Xã Đô Lương" },
  { value: "20", label: "Xã Tân Thành" },
  { value: "21", label: "Xã Hòa Sơn" },
  { value: "22", label: "Xã Minh Sơn" },
  { value: "23", label: "Xã Hồ Sơn" },
  { value: "24", label: "Xã Sơn Hà" },
  { value: "25", label: "Xã Minh Hòa" },
  { value: "26", label: "Xã Hòa Thắng" },
];
export const langson_huyenchilang = [
  { value: "1", label: "Thị trấn Đồng Mỏ" },
  { value: "2", label: "Thị trấn Chi Lăng" },
  { value: "3", label: "Xã Vân An" },
  { value: "4", label: "Xã Vân Thủy" },
  { value: "5", label: "Xã Gia Lộc" },
  { value: "6", label: "Xã Bắc Thủy" },
  { value: "7", label: "Xã Chiến Thắng" },
  { value: "8", label: "Xã Mai Sao" },
  { value: "9", label: "Xã Bằng Hữu" },
  { value: "10", label: "Xã Thượng Cường" },
  { value: "11", label: "Xã Bằng Mạc" },
  { value: "12", label: "Xã Nhân Lý" },
  { value: "13", label: "Xã Lâm Sơn" },
  { value: "14", label: "Xã Liên Sơn" },
  { value: "15", label: "Xã Vạn Linh" },
  { value: "16", label: "Xã Hòa Bình" },
  { value: "17", label: "Xã Quang Lang" },
  { value: "18", label: "Xã Hữu Kiên" },
  { value: "19", label: "Xã Quan Sơn" },
  { value: "20", label: "Xã Y Tịch" },
  { value: "21", label: "Xã Chi Lăng" },
];
export const langson_huyenlocbinh = [
  { value: "1", label: "Thị trấn Na Dương" },
  { value: "2", label: "Thị trấn Lộc Bình" },
  { value: "3", label: "Xã Mẫu Sơn" },
  { value: "4", label: "Xã Bằng Khánh" },
  { value: "5", label: "Xã Xuân Lễ" },
  { value: "6", label: "Xã Yên Khoái" },
  { value: "7", label: "Xã Xuân Mãn" },
  { value: "8", label: "Xã Tú Mịch" },
  { value: "9", label: "Xã Hữu Khánh" },
  { value: "10", label: "Xã Đồng Bục" },
  { value: "11", label: "Xã Vân Mộng" },
  { value: "12", label: "Xã Tam Gia" },
  { value: "13", label: "Xã Tú Đoạn" },
  { value: "14", label: "Xã Khuất Xá" },
  { value: "15", label: "Xã Như Khuê" },
  { value: "16", label: "Xã Lục Thôn" },
  { value: "17", label: "Xã Tĩnh Bắc" },
  { value: "18", label: "Xã Xuân Tình" },
  { value: "19", label: "Xã Hiệp Hạ" },
  { value: "20", label: "Xã Nhượng Bạn" },
  { value: "21", label: "Xã Quan Bản" },
  { value: "22", label: "Xã Sàn Viên" },
  { value: "23", label: "Xã Đông Quan" },
  { value: "24", label: "Xã Minh Phát" },
  { value: "25", label: "Xã Hữu Lân" },
  { value: "26", label: "Xã Lợi Bác" },
  { value: "27", label: "Xã Nam Quan" },
  { value: "28", label: "Xã Xuân Dương" },
  { value: "29", label: "Xã Ái Quốc" },
];
export const langson_huyendinhlap = [
  { value: "1", label: "Thị trấn Đình Lập" },
  { value: "2", label: "Thị trấn NT Thái Bình" },
  { value: "3", label: "Xã Bắc Xa" },
  { value: "4", label: "Xã Bính Xá" },
  { value: "5", label: "Xã Kiên Mộc" },
  { value: "6", label: "Xã Đình Lập" },
  { value: "7", label: "Xã Thái Bình" },
  { value: "8", label: "Xã Cường Lợi" },
  { value: "9", label: "Xã Châu Sơn" },
  { value: "10", label: "Xã Lâm Ca" },
  { value: "11", label: "Xã Đồng Thắng" },
  { value: "12", label: "Xã Bắc Lãng" },
];
export const quangninh_thanhphohalong = [
  { value: "1", label: "Phường Hà Khánh" },
  { value: "2", label: "Phường Hà Phong" },
  { value: "3", label: "Phường Hà Khẩu" },
  { value: "4", label: "Phường Cao Xanh" },
  { value: "5", label: "Phường Giếng Đáy" },
  { value: "6", label: "Phường Hà Tu" },
  { value: "7", label: "Phường Hà Trung" },
  { value: "8", label: "Phường Hà Lầm" },
  { value: "9", label: "Phường Bãi Cháy" },
  { value: "10", label: "Phường Cao Thắng" },
  { value: "11", label: "Phường Hùng Thắng" },
  { value: "12", label: "Phường Yết Kiêu" },
  { value: "13", label: "Phường Trần Hưng Đạo" },
  { value: "14", label: "Phường Hồng Hải" },
  { value: "15", label: "Phường Hồng Gai" },
  { value: "16", label: "Phường Bạch Đằng" },
  { value: "17", label: "Phường Hồng Hà" },
  { value: "18", label: "Phường Tuần Châu" },
  { value: "19", label: "Phường Việt Hưng" },
  { value: "20", label: "Phường Đại Yên" },
];
export const quangninh_thanhphomongcai = [
  { value: "1", label: "Phường Ka Long" },
  { value: "2", label: "Phường Trần Phú" },
  { value: "3", label: "Phường Ninh Dương" },
  { value: "4", label: "Phường Hòa Lạc" },
  { value: "5", label: "Phường Trà Cổ" },
  { value: "6", label: "Xã Hải Sơn" },
  { value: "7", label: "Xã Bắc Sơn" },
  { value: "8", label: "Xã Hải Đông" },
  { value: "9", label: "Xã Hải Tiến" },
  { value: "10", label: "Phường Hải Yên" },
  { value: "11", label: "Xã Quảng Nghĩa" },
  { value: "12", label: "Phường Hải Hòa" },
  { value: "13", label: "Xã Hải Xuân" },
  { value: "14", label: "Xã Vạn Ninh" },
  { value: "15", label: "Phường Bình Ngọc" },
  { value: "16", label: "Xã Vĩnh Trung" },
  { value: "17", label: "Xã Vĩnh Thực" },
];
export const quangninh_thanhphocampha = [
  { value: "1", label: "Phường Mông Dương" },
  { value: "2", label: "Phường Cửa Ông" },
  { value: "3", label: "Phường Cẩm Sơn" },
  { value: "4", label: "Phường Cẩm Đông" },
  { value: "5", label: "Phường Cẩm Phú" },
  { value: "6", label: "Phường Cẩm Tây" },
  { value: "7", label: "Phường Quang Hanh" },
  { value: "8", label: "Phường Cẩm Thịnh" },
  { value: "9", label: "Phường Cẩm Thủy" },
  { value: "10", label: "Phường Cẩm Thạch" },
  { value: "11", label: "Phường Cẩm Thành" },
  { value: "12", label: "Phường Cẩm Trung" },
  { value: "13", label: "Phường Cẩm Bình" },
  { value: "14", label: "Xã Cộng Hòa" },
  { value: "15", label: "Xã Cẩm Hải" },
  { value: "16", label: "Xã Dương Huy" },
];
export const quangninh_thanhphouongbi = [
  { value: "1", label: "Phường Vàng Danh" },
  { value: "2", label: "Phường Thanh Sơn" },
  { value: "3", label: "Phường Bắc Sơn" },
  { value: "4", label: "Phường Quang Trung" },
  { value: "5", label: "Phường Trưng Vương" },
  { value: "6", label: "Phường Nam Khê" },
  { value: "7", label: "Phường Yên Thanh" },
  { value: "8", label: "Xã Thượng Yên Công" },
  { value: "9", label: "Phường Phương Đông" },
  { value: "10", label: "Phường Phương Nam" },
  { value: "11", label: "Xã Điền Công" },
];
export const quangninh_huyenbinhlieu = [
  { value: "1", label: "Thị trấn Bình Liêu" },
  { value: "2", label: "Xã Hoành Mô" },
  { value: "3", label: "Xã Đồng Tâm" },
  { value: "4", label: "Xã Đồng Văn" },
  { value: "5", label: "Xã Tình Húc" },
  { value: "6", label: "Xã Vô Ngại" },
  { value: "7", label: "Xã Lục Hồn" },
  { value: "8", label: "Xã Húc Động" },
];
export const quangninh_huyentienyen = [
  { value: "1", label: "Thị trấn Tiên Yên" },
  { value: "2", label: "Xã Hà Lâu" },
  { value: "3", label: "Xã Đại Dực" },
  { value: "4", label: "Xã Đại Thành" },
  { value: "5", label: "Xã Phong Dụ" },
  { value: "6", label: "Xã Điền Xá" },
  { value: "7", label: "Xã Đông Ngũ" },
  { value: "8", label: "Xã Yên Than" },
  { value: "9", label: "Xã Đông Hải" },
  { value: "10", label: "Xã Hải Lạng" },
  { value: "11", label: "Xã Tiên Lãng" },
  { value: "12", label: "Xã Đồng Rui" },
];
export const quangninh_huyendamha = [
  { value: "1", label: "Thị trấn Đầm Hà" },
  { value: "2", label: "Xã Quảng Lâm" },
  { value: "3", label: "Xã Quảng An" },
  { value: "4", label: "Xã Tân Bình" },
  { value: "5", label: "Xã Quảng Lợi" },
  { value: "6", label: "Xã Dực Yên" },
  { value: "7", label: "Xã Quảng Tân" },
  { value: "8", label: "Xã Đầm Hà" },
  { value: "9", label: "Xã Tân Lập" },
  { value: "10", label: "Xã Đại Bình" },
];
export const quangninh_huyenhaiha = [
  { value: "1", label: "Thị trấn Quảng Hà" },
  { value: "2", label: "Xã Quảng Đức" },
  { value: "3", label: "Xã Quảng Sơn" },
  { value: "4", label: "Xã Quảng Thành" },
  { value: "5", label: "Xã Quảng Thắng" },
  { value: "6", label: "Xã Quảng Thịnh" },
  { value: "7", label: "Xã Quảng Minh" },
  { value: "8", label: "Xã Quảng Chính" },
  { value: "9", label: "Xã Quảng Long" },
  { value: "10", label: "Xã Đường Hoa" },
  { value: "11", label: "Xã Quảng Phong" },
  { value: "12", label: "Xã Quảng Trung" },
  { value: "13", label: "Xã Phú Hải" },
  { value: "14", label: "Xã Quảng Điền" },
  { value: "15", label: "Xã Tiến Tới" },
  { value: "16", label: "Xã Cái Chiên" },
];
export const quangninh_huyenbache = [
  { value: "1", label: "Thị trấn Ba Chẽ" },
  { value: "2", label: "Xã Thanh Sơn" },
  { value: "3", label: "Xã Thanh Lâm" },
  { value: "4", label: "Xã Đạp Thanh" },
  { value: "5", label: "Xã Nam Sơn" },
  { value: "6", label: "Xã Lương Mông" },
  { value: "7", label: "Xã Đồn Đạc" },
  { value: "8", label: "Xã Minh Cầm" },
];
export const quangninh_huyenvandon = [
  { value: "1", label: "Thị trấn Cái Rồng" },
  { value: "2", label: "Xã Đài Xuyên" },
  { value: "3", label: "Xã Bình Dân" },
  { value: "4", label: "Xã Vạn Yên" },
  { value: "5", label: "Xã Minh Châu" },
  { value: "6", label: "Xã Đoàn Kết" },
  { value: "7", label: "Xã Hạ Long" },
  { value: "8", label: "Xã Đông Xá" },
  { value: "9", label: "Xã Bản Sen" },
  { value: "10", label: "Xã Thắng Lợi" },
  { value: "11", label: "Xã Quan Lạn" },
  { value: "12", label: "Xã Ngọc Vừng" },
];
export const quangninh_huyenhoanhbo = [
  { value: "1", label: "Thị trấn Trới" },
  { value: "2", label: "Xã Kỳ Thượng" },
  { value: "3", label: "Xã Đồng Sơn" },
  { value: "4", label: "Xã Tân Dân" },
  { value: "5", label: "Xã Đồng Lâm" },
  { value: "6", label: "Xã Hòa Bình" },
  { value: "7", label: "Xã Vũ Oai" },
  { value: "8", label: "Xã Dân Chủ" },
  { value: "9", label: "Xã Quảng La" },
  { value: "10", label: "Xã Bằng Cả" },
  { value: "11", label: "Xã Thống Nhất" },
  { value: "12", label: "Xã Sơn Dương" },
  { value: "13", label: "Xã Lê Lợi" },
];
export const quangninh_xadongtrieu = [
  { value: "1", label: "Phường Mạo Khê" },
  { value: "2", label: "Phường Đông Triều" },
  { value: "3", label: "Xã An Sinh" },
  { value: "4", label: "Xã Tràng Lương" },
  { value: "5", label: "Xã Bình Khê" },
  { value: "6", label: "Xã Việt Dân" },
  { value: "7", label: "Xã Tân Việt" },
  { value: "8", label: "Xã Bình Dương" },
  { value: "9", label: "Phường Đức Chính" },
  { value: "10", label: "Xã Tràng An" },
  { value: "11", label: "Xã Nguyễn Huệ" },
  { value: "12", label: "Xã Thủy An" },
  { value: "13", label: "Phường Xuân Sơn" },
  { value: "14", label: "Xã Hồng Thái Tây" },
  { value: "15", label: "Xã Hồng Thái Đông" },
  { value: "16", label: "Xã Hoàng Quế" },
  { value: "17", label: "Xã Yên Thọ" },
  { value: "18", label: "Xã Hồng Phong" },
  { value: "19", label: "Phường Kim Sơn" },
  { value: "20", label: "Phường Hưng Đạo" },
  { value: "21", label: "Xã Yên Đức" },
];
export const quangninh_xaquangyen = [
  { value: "1", label: "Phường Quảng Yên" },
  { value: "2", label: "Phường Đông Mai" },
  { value: "3", label: "Phường Minh Thành" },
  { value: "4", label: "Xã Sông Khoai" },
  { value: "5", label: "Xã Hiệp Hòa" },
  { value: "6", label: "Phường Cộng Hòa" },
  { value: "7", label: "Xã Tiền An" },
  { value: "8", label: "Xã Hoàng Tân" },
  { value: "9", label: "Phường Tân An" },
  { value: "10", label: "Phường Yên Giang" },
  { value: "11", label: "Phường Nam Hòa" },
  { value: "12", label: "Phường Hà An" },
  { value: "13", label: "Xã Cẩm La" },
  { value: "14", label: "Phường Phong Hải" },
  { value: "15", label: "Phường Yên Hải" },
  { value: "16", label: "Xã Liên Hòa" },
  { value: "17", label: "Phường Phong Cốc" },
  { value: "18", label: "Xã Liên Vị" },
  { value: "19", label: "Xã Tiền Phong" },
];
export const quangninh_huyencoto = [
  { value: "1", label: "Thị trấn Cô Tô" },
  { value: "2", label: "Xã Đồng Tiến" },
  { value: "3", label: "Xã Thanh Lân" },
];

export const bacgiang_thanhphobacgiang = [
  { value: "1", label: "Phường Thọ Xương" },
  { value: "2", label: "Phường Trần Nguyên Hãn" },
  { value: "3", label: "Phường Ngô Quyền" },
  { value: "4", label: "Phường Hoàng Văn Thụ" },
  { value: "5", label: "Phường Trần Phú" },
  { value: "6", label: "Phường Mỹ Độ" },
  { value: "7", label: "Phường Lê Lợi" },
  { value: "8", label: "Xã Song Mai" },
  { value: "9", label: "Phường Xương Giang" },
  { value: "10", label: "Phường Đa Mai" },
  { value: "11", label: "Phường Dĩnh Kế" },
  { value: "12", label: "Xã Dĩnh Trì" },
  { value: "13", label: "Xã Tân Mỹ" },
  { value: "14", label: "Xã Đồng Sơn" },
  { value: "15", label: "Xã Tân Tiến" },
  { value: "16", label: "Xã Song Khê" },
];
export const bacgiang_huyenyenthe = [
  { value: "1", label: "Thị trấn Cầu Gồ" },
  { value: "2", label: "Thị trấn Bố Hạ" },
  { value: "3", label: "Xã Đồng Tiến" },
  { value: "4", label: "Xã Canh Nậu" },
  { value: "5", label: "Xã Xuân Lương" },
  { value: "6", label: "Xã Tam Tiến" },
  { value: "7", label: "Xã Đồng Vương" },
  { value: "8", label: "Xã Đồng Hưu" },
  { value: "9", label: "Xã Đồng Tâm" },
  { value: "10", label: "Xã Tam Hiệp" },
  { value: "11", label: "Xã Tiến Thắng" },
  { value: "12", label: "Xã Hồng Kỳ" },
  { value: "13", label: "Xã Đồng Lạc" },
  { value: "14", label: "Xã Đông Sơn" },
  { value: "15", label: "Xã Tân Hiệp" },
  { value: "16", label: "Xã Hương Vĩ" },
  { value: "17", label: "Xã Đồng Kỳ" },
  { value: "18", label: "Xã An Thượng" },
  { value: "19", label: "Xã Phồn Xương" },
  { value: "20", label: "Xã Tân Sỏi" },
  { value: "21", label: "Xã Bố Hạ" },
];
export const bacgiang_huyentanyen = [
  { value: "1", label: "Thị trấn Cao Thượng" },
  { value: "2", label: "Thị trấn Nhã Nam" },
  { value: "3", label: "Xã Lan Giới" },
  { value: "4", label: "Xã Nhã Nam" },
  { value: "5", label: "Xã Tân Trung" },
  { value: "6", label: "Xã Đại Hóa" },
  { value: "7", label: "Xã Quang Tiến" },
  { value: "8", label: "Xã Phúc Sơn" },
  { value: "9", label: "Xã An Dương" },
  { value: "10", label: "Xã Phúc Hòa" },
  { value: "11", label: "Xã Liên Sơn" },
  { value: "12", label: "Xã Hợp Đức" },
  { value: "13", label: "Xã Lam Cốt" },
  { value: "14", label: "Xã Cao Xá" },
  { value: "15", label: "Xã Cao Thượng" },
  { value: "16", label: "Xã Việt Ngọc" },
  { value: "17", label: "Xã Song Vân" },
  { value: "18", label: "Xã Ngọc Châu" },
  { value: "19", label: "Xã Ngọc Vân" },
  { value: "20", label: "Xã Việt Lập" },
  { value: "21", label: "Xã Liên Chung" },
  { value: "22", label: "Xã Ngọc Thiện" },
  { value: "23", label: "Xã Ngọc Lý" },
  { value: "24", label: "Xã Quế Nham" },
];
export const bacgiang_huyenlanggiang = [
  { value: "1", label: "Thị trấn Kép" },
  { value: "2", label: "Thị trấn Vôi" },
  { value: "3", label: "Xã Nghĩa Hòa" },
  { value: "4", label: "Xã Nghĩa Hưng" },
  { value: "5", label: "Xã Quang Thịnh" },
  { value: "6", label: "Xã Hương Sơn" },
  { value: "7", label: "Xã Đào Mỹ" },
  { value: "8", label: "Xã Tiên Lục" },
  { value: "9", label: "Xã An Hà" },
  { value: "10", label: "Xã Tân Thịnh" },
  { value: "11", label: "Xã Mỹ Hà" },
  { value: "12", label: "Xã Hương Lạc" },
  { value: "13", label: "Xã Dương Đức" },
  { value: "14", label: "Xã Tân Thanh" },
  { value: "15", label: "Xã Yên Mỹ" },
  { value: "16", label: "Xã Tân Hưng" },
  { value: "17", label: "Xã Mỹ Thái" },
  { value: "18", label: "Xã Phi Mô" },
  { value: "19", label: "Xã Xương Lâm" },
  { value: "20", label: "Xã Xuân Hương" },
  { value: "21", label: "Xã Tân Dĩnh" },
  { value: "22", label: "Xã Đại Lâm" },
  { value: "23", label: "Xã Thái Đào" },
];
export const bacgiang_huyenlucnam = [
  { value: "1", label: "Thị trấn Đồi Ngô" },
  { value: "2", label: "Thị trấn Lục Nam" },
  { value: "3", label: "Xã Đông Hưng" },
  { value: "4", label: "Xã Đông Phú" },
  { value: "5", label: "Xã Tam Dị" },
  { value: "6", label: "Xã Bảo Sơn" },
  { value: "7", label: "Xã Bảo Đài" },
  { value: "8", label: "Xã Thanh Lâm" },
  { value: "9", label: "Xã Tiên Nha" },
  { value: "10", label: "Xã Trường Giang" },
  { value: "11", label: "Xã Tiên Hưng" },
  { value: "12", label: "Xã Phương Sơn" },
  { value: "13", label: "Xã Chu Điện" },
  { value: "14", label: "Xã Cương Sơn" },
  { value: "15", label: "Xã Nghĩa Phương" },
  { value: "16", label: "Xã Vô Tranh" },
  { value: "17", label: "Xã Bình Sơn" },
  { value: "18", label: "Xã Lan Mẫu" },
  { value: "19", label: "Xã Yên Sơn" },
  { value: "20", label: "Xã Khám Lạng" },
  { value: "21", label: "Xã Huyền Sơn" },
  { value: "22", label: "Xã Trường Sơn" },
  { value: "23", label: "Xã Lục Sơn" },
  { value: "24", label: "Xã Bắc Lũng" },
  { value: "25", label: "Xã Vũ Xá" },
  { value: "26", label: "Xã Cẩm Lý" },
  { value: "27", label: "Xã Đan Hội" },
];
export const bacgiang_huyenlucngan = [
  { value: "1", label: "Thị trấn Chũ" },
  { value: "2", label: "Xã Cấm Sơn" },
  { value: "3", label: "Xã Tân Sơn" },
  { value: "4", label: "Xã Phong Minh" },
  { value: "5", label: "Xã Phong Vân" },
  { value: "6", label: "Xã Xa Lý" },
  { value: "7", label: "Xã Hộ Đáp" },
  { value: "8", label: "Xã Sơn Hải" },
  { value: "9", label: "Xã Thanh Hải" },
  { value: "10", label: "Xã Kiên Lao" },
  { value: "11", label: "Xã Biên Sơn" },
  { value: "12", label: "Xã Kiên Thành" },
  { value: "13", label: "Xã Hồng Giang" },
  { value: "14", label: "Xã Kim Sơn" },
  { value: "15", label: "Xã Tân Hoa" },
  { value: "16", label: "Xã Giáp Sơn" },
  { value: "17", label: "Xã Biển Động" },
  { value: "18", label: "Xã Quý Sơn" },
  { value: "19", label: "Xã Trù Hựu" },
  { value: "20", label: "Xã Phì Điền" },
  { value: "21", label: "Xã Nghĩa Hồ" },
  { value: "22", label: "Xã Tân Quang" },
  { value: "23", label: "Xã Đồng Cốc" },
  { value: "24", label: "Xã Tân Lập" },
  { value: "25", label: "Xã Phú Nhuận" },
  { value: "26", label: "Xã Mỹ An" },
  { value: "27", label: "Xã Nam Dương" },
  { value: "28", label: "Xã Tân Mộc" },
  { value: "29", label: "Xã Đèo Gia" },
  { value: "30", label: "Xã Phượng Sơn" },
];
export const bacgiang_huyensondong = [
  { value: "1", label: "Thị trấn An Châu" },
  { value: "2", label: "Thị trấn Thanh Sơn" },
  { value: "3", label: "Xã Thạch Sơn" },
  { value: "4", label: "Xã Vân Sơn" },
  { value: "5", label: "Xã Hữu Sản" },
  { value: "6", label: "Xã Quế Sơn" },
  { value: "7", label: "Xã Phúc Thắng" },
  { value: "8", label: "Xã Chiên Sơn" },
  { value: "9", label: "Xã Giáo Liêm" },
  { value: "10", label: "Xã Vĩnh Khương" },
  { value: "11", label: "Xã Cẩm Đàn" },
  { value: "12", label: "Xã An Lạc" },
  { value: "13", label: "Xã An Lập" },
  { value: "14", label: "Xã Yên Định" },
  { value: "15", label: "Xã Lệ Viễn" },
  { value: "16", label: "Xã An Châu" },
  { value: "17", label: "Xã An Bá" },
  { value: "18", label: "Xã Tuấn Đạo" },
  { value: "19", label: "Xã Dương Hưu" },
  { value: "20", label: "Xã Bồng Am" },
  { value: "21", label: "Xã Long Sơn" },
  { value: "22", label: "Xã Tuấn Mậu" },
  { value: "23", label: "Xã Thanh Luận" },
];
export const bacgiang_huyenyendung = [
  { value: "1", label: "Thị trấn Neo" },
  { value: "2", label: "Thị trấn Tân Dân" },
  { value: "3", label: "Xã Lão Hộ" },
  { value: "4", label: "Xã Hương Gián" },
  { value: "5", label: "Xã Tân An" },
  { value: "6", label: "Xã Quỳnh Sơn" },
  { value: "7", label: "Xã Nội Hoàng" },
  { value: "8", label: "Xã Tiền Phong" },
  { value: "9", label: "Xã Xuân Phú" },
  { value: "10", label: "Xã Tân Liễu" },
  { value: "11", label: "Xã Trí Yên" },
  { value: "12", label: "Xã Lãng Sơn" },
  { value: "13", label: "Xã Yên Lư" },
  { value: "14", label: "Xã Tiến Dũng" },
  { value: "15", label: "Xã Nham Sơn" },
  { value: "16", label: "Xã Đức Giang" },
  { value: "17", label: "Xã Cảnh Thụy" },
  { value: "18", label: "Xã Tư Mại" },
  { value: "19", label: "Xã Thắng Cương" },
  { value: "20", label: "Xã Đồng Việt" },
  { value: "21", label: "Xã Đồng Phúc" },
];
export const bacgiang_huyenvietyen = [
  { value: "1", label: "Thị trấn Bích Động" },
  { value: "2", label: "Thị trấn Nếnh" },
  { value: "3", label: "Xã Thượng Lan" },
  { value: "4", label: "Xã Việt Tiến" },
  { value: "5", label: "Xã Nghĩa Trung" },
  { value: "6", label: "Xã Minh Đức" },
  { value: "7", label: "Xã Hương Mai" },
  { value: "8", label: "Xã Tự Lạn" },
  { value: "9", label: "Xã Bích Sơn" },
  { value: "10", label: "Xã Trung Sơn" },
  { value: "11", label: "Xã Hồng Thái" },
  { value: "12", label: "Xã Tiên Sơn" },
  { value: "13", label: "Xã Tăng Tiến" },
  { value: "14", label: "Xã Quảng Minh" },
  { value: "15", label: "Xã Hoàng Ninh" },
  { value: "16", label: "Xã Ninh Sơn" },
  { value: "17", label: "Xã Vân Trung" },
  { value: "18", label: "Xã Vân Hà" },
  { value: "19", label: "Xã Quang Châu" },
];
export const bacgiang_huyenhiephoa = [
  { value: "1", label: "Thị trấn Thắng" },
  { value: "2", label: "Xã Đồng Tân" },
  { value: "3", label: "Xã Thanh Vân" },
  { value: "4", label: "Xã Hoàng Lương" },
  { value: "5", label: "Xã Hoàng Vân" },
  { value: "6", label: "Xã Hoàng Thanh" },
  { value: "7", label: "Xã Hoàng An" },
  { value: "8", label: "Xã Ngọc Sơn" },
  { value: "9", label: "Xã Thái Sơn" },
  { value: "10", label: "Xã Hòa Sơn" },
  { value: "11", label: "Xã Đức Thắng" },
  { value: "12", label: "Xã Quang Minh" },
  { value: "13", label: "Xã Lương Phong" },
  { value: "14", label: "Xã Hùng Sơn" },
  { value: "15", label: "Xã Đại Thành" },
  { value: "16", label: "Xã Thường Thắng" },
  { value: "17", label: "Xã Hợp Thịnh" },
  { value: "18", label: "Xã Danh Thắng" },
  { value: "19", label: "Xã Mai Trung" },
  { value: "20", label: "Xã Đoan Bái" },
  { value: "21", label: "Xã Bắc Lý" },
  { value: "22", label: "Xã Xuân Cẩm" },
  { value: "23", label: "Xã Hương Lâm" },
  { value: "24", label: "Xã Đông Lỗ" },
  { value: "25", label: "Xã Châu Minh" },
  { value: "26", label: "Xã Mai Đình" },
];
export const phutho_thanhphoviettri = [
  { value: "1", label: "Phường Dữu Lâu" },
  { value: "2", label: "Phường Vân Cơ" },
  { value: "3", label: "Phường Nông Trang" },
  { value: "4", label: "Phường Tân Dân" },
  { value: "5", label: "Phường Gia Cẩm" },
  { value: "6", label: "Phường Tiên Cát" },
  { value: "7", label: "Phường Thọ Sơn" },
  { value: "8", label: "Phường Thanh Miếu" },
  { value: "9", label: "Phường Bạch Hạc" },
  { value: "10", label: "Phường Bến Gót" },
  { value: "11", label: "Phường Vân Phú" },
  { value: "12", label: "Xã Phượng Lâu" },
  { value: "13", label: "Xã Thụy Vân" },
  { value: "14", label: "Phường Minh Phương" },
  { value: "15", label: "Xã Trưng Vương" },
  { value: "16", label: "Phường Minh Nông" },
  { value: "17", label: "Xã Sông Lô" },
  { value: "18", label: "Xã Kim Đức" },
  { value: "19", label: "Xã Hùng Lô" },
  { value: "20", label: "Xã Hy Cương" },
  { value: "21", label: "Xã Chu Hóa" },
  { value: "22", label: "Xã Thanh Đình" },
  { value: "23", label: "Xã Tân Đức" },
];
export const phutho_xaphutho = [
  { value: "1", label: "Phường Trường Thịnh" },
  { value: "2", label: "Phường Hùng Vương" },
  { value: "3", label: "Phường Phong Châu" },
  { value: "4", label: "Phường Âu Cơ" },
  { value: "5", label: "Xã Hà Lộc" },
  { value: "6", label: "Xã Phú Hộ" },
  { value: "7", label: "Xã Văn Lung" },
  { value: "8", label: "Xã Thanh Minh" },
  { value: "9", label: "Xã Hà Thạch" },
  { value: "10", label: "Phường Thanh Vinh" },
];
export const phutho_huyendoanhung = [
  { value: "1", label: "Thị trấn Đoan Hùng" },
  { value: "2", label: "Xã Đông Khê" },
  { value: "3", label: "Xã Nghinh Xuyên" },
  { value: "4", label: "Xã Hùng Quan" },
  { value: "5", label: "Xã Bằng Luân" },
  { value: "6", label: "Xã Vân Du" },
  { value: "7", label: "Xã Phương Trung" },
  { value: "8", label: "Xã Quế Lâm" },
  { value: "9", label: "Xã Minh Lương" },
  { value: "10", label: "Xã Bằng Doãn" },
  { value: "11", label: "Xã Chí Đám" },
  { value: "12", label: "Xã Phong Phú" },
  { value: "13", label: "Xã Phúc Lai" },
  { value: "14", label: "Xã Ngọc Quan" },
  { value: "15", label: "Xã Hữu Đô" },
  { value: "16", label: "Xã Đại Nghĩa" },
  { value: "17", label: "Xã Sóc Đăng" },
  { value: "18", label: "Xã Phú Thứ" },
  { value: "19", label: "Xã Tây Cốc" },
  { value: "20", label: "Xã Yên Kiện" },
  { value: "21", label: "Xã Hùng Long" },
  { value: "22", label: "Xã Vụ Quang" },
  { value: "23", label: "Xã Vân Đồn" },
  { value: "24", label: "Xã Tiêu Sơn" },
  { value: "25", label: "Xã Minh Tiến" },
  { value: "26", label: "Xã Minh Phú" },
  { value: "27", label: "Xã Chân Mộng" },
  { value: "28", label: "Xã Ca Đình" },
];
export const phutho_huyenhahoa = [
  { value: "1", label: "Thị trấn Hạ Hòa" },
  { value: "2", label: "Xã Đại Phạm" },
  { value: "3", label: "Xã Hậu Bổng" },
  { value: "4", label: "Xã Đan Hà" },
  { value: "5", label: "Xã Hà Lương" },
  { value: "6", label: "Xã Lệnh Khanh" },
  { value: "7", label: "Xã Phụ Khánh" },
  { value: "8", label: "Xã Liên Phương" },
  { value: "9", label: "Xã Đan Thượng" },
  { value: "10", label: "Xã Hiền Lương" },
  { value: "11", label: "Xã Động Lâm" },
  { value: "12", label: "Xã Lâm Lợi" },
  { value: "13", label: "Xã Phương Viên" },
  { value: "14", label: "Xã Gia Điền" },
  { value: "15", label: "Xã Ấm Hạ" },
  { value: "16", label: "Xã Quân Khê" },
  { value: "17", label: "Xã Y Sơn" },
  { value: "18", label: "Xã Hương Xạ" },
  { value: "19", label: "Xã Cáo Điền" },
  { value: "20", label: "Xã Xuân Áng" },
  { value: "21", label: "Xã Yên Kỳ" },
  { value: "22", label: "Xã Chuế Lưu" },
  { value: "23", label: "Xã Minh Hạc" },
  { value: "24", label: "Xã Lang Sơn" },
  { value: "25", label: "Xã Bằng Giã" },
  { value: "26", label: "Xã Yên Luật" },
  { value: "27", label: "Xã Vô Tranh" },
  { value: "28", label: "Xã Văn Lang" },
  { value: "29", label: "Xã Chính Công" },
  { value: "30", label: "Xã Minh Côi" },
  { value: "31", label: "Xã Vĩnh Chân" },
  { value: "32", label: "Xã Mai Tùng" },
  { value: "33", label: "Xã Vụ Cầu" },
];
export const phutho_huyenthanhba = [
  { value: "1", label: "Thị trấn Thanh Ba" },
  { value: "2", label: "Xã Thanh Vân" },
  { value: "3", label: "Xã Vân Lĩnh" },
  { value: "4", label: "Xã Đông Lĩnh" },
  { value: "5", label: "Xã Đại An" },
  { value: "6", label: "Xã Hanh Cù" },
  { value: "7", label: "Xã Thái Ninh" },
  { value: "8", label: "Xã Đồng Xuân" },
  { value: "9", label: "Xã Năng Yên" },
  { value: "10", label: "Xã Yển Khê" },
  { value: "11", label: "Xã Ninh Dân" },
  { value: "12", label: "Xã Quảng Nạp" },
  { value: "13", label: "Xã Vũ Yển" },
  { value: "14", label: "Xã Yên Nội" },
  { value: "15", label: "Xã Phương Lĩnh" },
  { value: "16", label: "Xã Võ Lao" },
  { value: "17", label: "Xã Khải Xuân" },
  { value: "18", label: "Xã Mạn Lạn" },
  { value: "19", label: "Xã Thanh Xá" },
  { value: "20", label: "Xã Chí Tiên" },
  { value: "21", label: "Xã Đông Thành" },
  { value: "22", label: "Xã Hoàng Cương" },
  { value: "23", label: "Xã Sơn Cương" },
  { value: "24", label: "Xã Thanh Hà" },
  { value: "25", label: "Xã Đỗ Sơn" },
  { value: "26", label: "Xã Đỗ Xuyên" },
  { value: "27", label: "Xã Lương Lỗ" },
];
export const phutho_huyenphuninh = [
  { value: "1", label: "Thị trấn Phong Châu" },
  { value: "2", label: "Xã Phú Mỹ" },
  { value: "3", label: "Xã Lệ Mỹ" },
  { value: "4", label: "Xã Liên Hoa" },
  { value: "5", label: "Xã Trạm Thản" },
  { value: "6", label: "Xã Trị Quận" },
  { value: "7", label: "Xã Trung Giáp" },
  { value: "8", label: "Xã Tiên Phú" },
  { value: "9", label: "Xã Hạ Giáp" },
  { value: "10", label: "Xã Bảo Thanh" },
  { value: "11", label: "Xã Phú Lộc" },
  { value: "12", label: "Xã Gia Thanh" },
  { value: "13", label: "Xã Tiên Du" },
  { value: "14", label: "Xã Phú Nham" },
  { value: "15", label: "Xã Bình Bộ" },
  { value: "16", label: "Xã An Đạo" },
  { value: "17", label: "Xã Tử Đà" },
  { value: "18", label: "Xã Phù Ninh" },
  { value: "19", label: "Xã Vĩnh Phú" },
];
export const phutho_huyenyenlap = [
  { value: "1", label: "Thị trấn Yên Lập" },
  { value: "2", label: "Xã Mỹ Lung" },
  { value: "3", label: "Xã Mỹ Lương" },
  { value: "4", label: "Xã Lương Sơn" },
  { value: "5", label: "Xã Xuân An" },
  { value: "6", label: "Xã Xuân Viên" },
  { value: "7", label: "Xã Xuân Thủy" },
  { value: "8", label: "Xã Trung Sơn" },
  { value: "9", label: "Xã Hưng Long" },
  { value: "10", label: "Xã Nga Hoàng" },
  { value: "11", label: "Xã Đồng Lạc" },
  { value: "12", label: "Xã Thượng Long" },
  { value: "13", label: "Xã Đồng Thịnh" },
  { value: "14", label: "Xã Phúc Khánh" },
  { value: "15", label: "Xã Minh Hòa" },
  { value: "16", label: "Xã Ngọc Lập" },
  { value: "17", label: "Xã Ngọc Đồng" },
];
export const phutho_huyencamkhe = [
  { value: "1", label: "Thị trấn Sông Thao" },
  { value: "2", label: "Xã Tiên Lương" },
  { value: "3", label: "Xã Tuy Lộc" },
  { value: "4", label: "Xã Ngô Xá" },
  { value: "5", label: "Xã Phương Xá" },
  { value: "6", label: "Xã Phượng Vĩ" },
  { value: "7", label: "Xã Đồng Cam" },
  { value: "8", label: "Xã Thụy Liễu" },
  { value: "9", label: "Xã Phùng Xá" },
  { value: "10", label: "Xã Sơn Nga" },
  { value: "11", label: "Xã Sai Nga" },
  { value: "12", label: "Xã Tùng Khê" },
  { value: "13", label: "Xã Tam Sơn" },
  { value: "14", label: "Xã Văn Bán" },
  { value: "15", label: "Xã Cấp Dẫn" },
  { value: "16", label: "Xã Thanh Nga" },
  { value: "17", label: "Xã Xương Thịnh" },
  { value: "18", label: "Xã Phú Khê" },
  { value: "19", label: "Xã Sơn Tình" },
  { value: "20", label: "Xã Yên Tập" },
  { value: "21", label: "Xã Hương Lung" },
  { value: "22", label: "Xã Tạ Xá" },
  { value: "23", label: "Xã Phú Lạc" },
  { value: "24", label: "Xã Tình Cương" },
  { value: "25", label: "Xã Chương Xá" },
  { value: "26", label: "Xã Hiền Đa" },
  { value: "27", label: "Xã Văn Khúc" },
  { value: "28", label: "Xã Yên Dưỡng" },
  { value: "29", label: "Xã Cát Trù" },
  { value: "30", label: "Xã Điêu Lương" },
  { value: "31", label: "Xã Đồng Lương" },
];
export const phutho_huyentamnong = [
  { value: "1", label: "Thị trấn Hưng Hoá" },
  { value: "2", label: "Xã Vực Trường" },
  { value: "3", label: "Xã Hiền Quan" },
  { value: "4", label: "Xã Hương Nha" },
  { value: "5", label: "Xã Thanh Uyên" },
  { value: "6", label: "Xã Xuân Quang" },
  { value: "7", label: "Xã Tứ Mỹ" },
  { value: "8", label: "Xã Văn Lương" },
  { value: "9", label: "Xã Hùng Đô" },
  { value: "10", label: "Xã Phương Thịnh" },
  { value: "11", label: "Xã Tam Cường" },
  { value: "12", label: "Xã Cổ Tiết" },
  { value: "13", label: "Xã Quang Húc" },
  { value: "14", label: "Xã Hương Nộn" },
  { value: "15", label: "Xã Tề Lễ" },
  { value: "16", label: "Xã Thọ Văn" },
  { value: "17", label: "Xã Dị Nậu" },
  { value: "18", label: "Xã Hồng Đà" },
  { value: "19", label: "Xã Dậu Dương" },
  { value: "20", label: "Xã Thượng Nông" },
];
export const phutho_huyenlamthao = [
  { value: "1", label: "Thị trấn Lâm Thao" },
  { value: "2", label: "Xã Tiên Kiên" },
  { value: "3", label: "Thị trấn Hùng Sơn" },
  { value: "4", label: "Xã Xuân Lũng" },
  { value: "5", label: "Xã Xuân Huy" },
  { value: "6", label: "Xã Thạch Sơn" },
  { value: "7", label: "Xã Sơn Vi" },
  { value: "8", label: "Xã Hợp Hải" },
  { value: "9", label: "Xã Sơn Dương" },
  { value: "10", label: "Xã Cao Xá" },
  { value: "11", label: "Xã Kinh Kệ" },
  { value: "12", label: "Xã Vĩnh Lại" },
  { value: "13", label: "Xã Tứ Xã" },
  { value: "14", label: "Xã Bản Nguyên" },
];
export const phutho_huyenthanhson = [
  { value: "1", label: "Thị trấn Thanh Sơn" },
  { value: "2", label: "Xã Sơn Hùng" },
  { value: "3", label: "Xã Địch Quả" },
  { value: "4", label: "Xã Giáp Lai" },
  { value: "5", label: "Xã Thục Luyện" },
  { value: "6", label: "Xã Võ Miếu" },
  { value: "7", label: "Xã Thạch Khoán" },
  { value: "8", label: "Xã Cự Thắng" },
  { value: "9", label: "Xã Tất Thắng" },
  { value: "10", label: "Xã Văn Miếu" },
  { value: "11", label: "Xã Cự Đồng" },
  { value: "12", label: "Xã Thắng Sơn" },
  { value: "13", label: "Xã Tân Minh" },
  { value: "14", label: "Xã Hương Cần" },
  { value: "15", label: "Xã Khả Cửu" },
  { value: "16", label: "Xã Đông Cửu" },
  { value: "17", label: "Xã Tân Lập" },
  { value: "18", label: "Xã Yên Lãng" },
  { value: "19", label: "Xã Yên Lương" },
  { value: "20", label: "Xã Thượng Cửu" },
  { value: "21", label: "Xã Lương Nha" },
  { value: "22", label: "Xã Yên Sơn" },
  { value: "23", label: "Xã Tinh Nhuệ" },
];
export const phutho_huyenthanhthuy = [
  { value: "1", label: "Xã Đào Xá" },
  { value: "2", label: "Xã Thạch Đồng" },
  { value: "3", label: "Xã Xuân Lộc" },
  { value: "4", label: "Xã Tân Phương" },
  { value: "5", label: "Thị trấn Thanh Thủy" },
  { value: "6", label: "Xã Sơn Thủy" },
  { value: "7", label: "Xã Bảo Yên" },
  { value: "8", label: "Xã Đoan Hạ" },
  { value: "9", label: "Xã Đồng Luận" },
  { value: "10", label: "Xã Hoàng Xá" },
  { value: "11", label: "Xã Trung Thịnh" },
  { value: "12", label: "Xã Trung Nghĩa" },
  { value: "13", label: "Xã Phượng Mao" },
  { value: "14", label: "Xã Yến Mao" },
  { value: "15", label: "Xã Tu Vũ" },
];
export const phutho_huyentanson = [
  { value: "1", label: "Xã Thu Cúc" },
  { value: "2", label: "Xã Thạch Kiệt" },
  { value: "3", label: "Xã Thu Ngạc" },
  { value: "4", label: "Xã Kiệt Sơn" },
  { value: "5", label: "Xã Đồng Sơn" },
  { value: "6", label: "Xã Lai Đồng" },
  { value: "7", label: "Xã Tân Phú" },
  { value: "8", label: "Xã Mỹ Thuận" },
  { value: "9", label: "Xã Tân Sơn" },
  { value: "10", label: "Xã Xuân Đài" },
  { value: "11", label: "Xã Minh Đài" },
  { value: "12", label: "Xã Văn Luông" },
  { value: "13", label: "Xã Xuân Sơn" },
  { value: "14", label: "Xã Long Cốc" },
  { value: "15", label: "Xã Kim Thượng" },
  { value: "16", label: "Xã Tam Thanh" },
  { value: "17", label: "Xã Vinh Tiền" },
];
export const vinhphuc_thanhphovinhyen = [
  { value: "1", label: "Phường Tích Sơn" },
  { value: "2", label: "Phường Liên Bảo" },
  { value: "3", label: "Phường Hội Hợp" },
  { value: "4", label: "Phường Đống Đa" },
  { value: "5", label: "Phường Ngô Quyền" },
  { value: "6", label: "Phường Đồng Tâm" },
  { value: "7", label: "Xã Định Trung" },
  { value: "8", label: "Phường Khai Quang" },
  { value: "9", label: "Xã Thanh Trù" },
];
export const vinhphuc_xaphucyen = [
  { value: "1", label: "Phường Trưng Trắc" },
  { value: "2", label: "Phường Hùng Vương" },
  { value: "3", label: "Phường Trưng Nhị" },
  { value: "4", label: "Phường Phúc Thắng" },
  { value: "5", label: "Phường Xuân Hòa" },
  { value: "6", label: "Phường Đồng Xuân" },
  { value: "7", label: "Xã Ngọc Thanh" },
  { value: "8", label: "Xã Cao Minh" },
  { value: "9", label: "Xã Nam Viêm" },
  { value: "10", label: "Xã Tiền Châu" },
];
export const vinhphuc_huyenlapthach = [
  { value: "1", label: "Thị trấn Lập Thạch" },
  { value: "2", label: "Xã Quang Sơn" },
  { value: "3", label: "Xã Ngọc Mỹ" },
  { value: "4", label: "Xã Hợp Lý" },
  { value: "5", label: "Xã Bắc Bình" },
  { value: "6", label: "Xã Thái Hòa" },
  { value: "7", label: "Thị trấn Hoa Sơn" },
  { value: "8", label: "Xã Liễn Sơn" },
  { value: "9", label: "Xã Xuân Hòa" },
  { value: "10", label: "Xã Vân Trục" },
  { value: "11", label: "Xã Liên Hòa" },
  { value: "12", label: "Xã Tử Du" },
  { value: "13", label: "Xã Bàn Giản" },
  { value: "14", label: "Xã Xuân Lôi" },
  { value: "15", label: "Xã Đồng Ích" },
  { value: "16", label: "Xã Tiên Lữ" },
  { value: "17", label: "Xã Văn Quán" },
  { value: "18", label: "Xã Đình Chu" },
  { value: "19", label: "Xã Triệu Đề" },
  { value: "20", label: "Xã Sơn Đông" },
];
export const vinhphuc_huyentamduong = [
  { value: "1", label: "Thị trấn Hợp Hòa" },
  { value: "2", label: "Xã Hoàng Hoa" },
  { value: "3", label: "Xã Đồng Tĩnh" },
  { value: "4", label: "Xã Kim Long" },
  { value: "5", label: "Xã Hướng Đạo" },
  { value: "6", label: "Xã Đạo Tú" },
  { value: "7", label: "Xã An Hòa" },
  { value: "8", label: "Xã Thanh Vân" },
  { value: "9", label: "Xã Duy Phiên" },
  { value: "10", label: "Xã Hoàng Đan" },
  { value: "11", label: "Xã Hoàng Lâu" },
  { value: "12", label: "Xã Vân Hội" },
  { value: "13", label: "Xã Hợp Thịnh" },
];
export const vinhphuc_huyentamdao = [
  { value: "1", label: "Thị trấn Tam Đảo" },
  { value: "2", label: "Xã Hợp Châu" },
  { value: "3", label: "Xã Đạo Trù" },
  { value: "4", label: "Xã Yên Dương" },
  { value: "5", label: "Xã Bồ Lý" },
  { value: "6", label: "Xã Đại Đình" },
  { value: "7", label: "Xã Tam Quan" },
  { value: "8", label: "Xã Hồ Sơn" },
  { value: "9", label: "Xã Minh Quang" },
];
export const vinhphuc_huyenbinhxuyen = [
  { value: "1", label: "Thị trấn Hương Canh" },
  { value: "2", label: "Thị trấn Gia Khánh" },
  { value: "3", label: "Xã Trung Mỹ" },
  { value: "4", label: "Xã Bá Hiến" },
  { value: "5", label: "Xã Thiện Kế" },
  { value: "6", label: "Xã Hương Sơn" },
  { value: "7", label: "Xã Tam Hợp" },
  { value: "8", label: "Xã Quất Lưu" },
  { value: "9", label: "Xã Sơn Lôi" },
  { value: "10", label: "Xã Đạo Đức" },
  { value: "11", label: "Xã Tân Phong" },
  { value: "12", label: "Thị trấn Thanh Lãng" },
  { value: "13", label: "Xã Phú Xuân" },
];
export const vinhphuc_huyenyenlac = [
  { value: "1", label: "Thị trấn Yên Lạc" },
  { value: "2", label: "Xã Đồng Cương" },
  { value: "3", label: "Xã Đồng Văn" },
  { value: "4", label: "Xã Bình Định" },
  { value: "5", label: "Xã Trung Nguyên" },
  { value: "6", label: "Xã Tề Lỗ" },
  { value: "7", label: "Xã Tam Hồng" },
  { value: "8", label: "Xã Yên Đồng" },
  { value: "9", label: "Xã Văn Tiến" },
  { value: "10", label: "Xã Nguyệt Đức" },
  { value: "11", label: "Xã Yên Phương" },
  { value: "12", label: "Xã Hồng Phương" },
  { value: "13", label: "Xã Trung Kiên" },
  { value: "14", label: "Xã Liên Châu" },
  { value: "15", label: "Xã Đại Tự" },
  { value: "16", label: "Xã Hồng Châu" },
  { value: "17", label: "Xã Trung Hà" },
];
export const vinhphuc_huyenvinhtuong = [
  { value: "1", label: "Thị trấn Vĩnh Tường" },
  { value: "2", label: "Xã Kim Xá" },
  { value: "3", label: "Xã Yên Bình" },
  { value: "4", label: "Xã Chấn Hưng" },
  { value: "5", label: "Xã Nghĩa Hưng" },
  { value: "6", label: "Xã Yên Lập" },
  { value: "7", label: "Xã Việt Xuân" },
  { value: "8", label: "Xã Bồ Sao" },
  { value: "9", label: "Xã Đại Đồng" },
  { value: "10", label: "Xã Tân Tiến" },
  { value: "11", label: "Xã Lũng Hòa" },
  { value: "12", label: "Xã Cao Đại" },
  { value: "13", label: "Thị Trấn Thổ Tang" },
  { value: "14", label: "Xã Vĩnh Sơn" },
  { value: "15", label: "Xã Bình Dương" },
  { value: "16", label: "Xã Tân Cương" },
  { value: "17", label: "Xã Phú Thịnh" },
  { value: "18", label: "Xã Thượng Trưng" },
  { value: "19", label: "Xã Vũ Di" },
  { value: "20", label: "Xã Lý Nhân" },
  { value: "21", label: "Xã Tuân Chính" },
  { value: "22", label: "Xã Vân Xuân" },
  { value: "23", label: "Xã Tam Phúc" },
  { value: "24", label: "Thị trấn Tứ Trưng" },
  { value: "25", label: "Xã Ngũ Kiên" },
  { value: "26", label: "Xã An Tường" },
  { value: "27", label: "Xã Vĩnh Thịnh" },
  { value: "28", label: "Xã Phú Đa" },
  { value: "29", label: "Xã Vĩnh Ninh" },
];
export const vinhphuc_huyensonglo = [
  { value: "1", label: "Xã Lãng Công" },
  { value: "2", label: "Xã Quang Yên" },
  { value: "3", label: "Xã Bạch Lưu" },
  { value: "4", label: "Xã Hải Lựu" },
  { value: "5", label: "Xã Đồng Quế" },
  { value: "6", label: "Xã Nhân Đạo" },
  { value: "7", label: "Xã Đôn Nhân" },
  { value: "8", label: "Xã Phương Khoan" },
  { value: "9", label: "Xã Tân Lập" },
  { value: "10", label: "Xã Nhạo Sơn" },
  { value: "11", label: "Thị trấn Tam Sơn" },
  { value: "12", label: "Xã Như Thụy" },
  { value: "13", label: "Xã Yên Thạch" },
  { value: "14", label: "Xã Đồng Thịnh" },
  { value: "15", label: "Xã Tứ Yên" },
  { value: "16", label: "Xã Đức Bác" },
  { value: "17", label: "Xã Cao Phong" },
];
export const bacninh_thanhphobacninh = [
  { value: "1", label: "Phường Vũ Ninh" },
  { value: "2", label: "Phường Đáp Cầu" },
  { value: "3", label: "Phường Thị Cầu" },
  { value: "4", label: "Phường Kinh Bắc" },
  { value: "5", label: "Phường Vệ An" },
  { value: "6", label: "Phường Tiền An" },
  { value: "7", label: "Phường Đại Phúc" },
  { value: "8", label: "Phường Ninh Xá" },
  { value: "9", label: "Phường Suối Hoa" },
  { value: "10", label: "Phường Võ Cường" },
  { value: "11", label: "Xã Hòa Long" },
  { value: "12", label: "Phường Vạn An" },
  { value: "13", label: "Phường Khúc Xuyên" },
  { value: "14", label: "Phường Phong Khê" },
  { value: "15", label: "Xã Kim Chân" },
  { value: "16", label: "Phường Vân Dương" },
  { value: "17", label: "Xã Nam Sơn" },
  { value: "18", label: "Phường Khắc Niệm" },
  { value: "19", label: "Phường Hạp Lĩnh" },
];
export const bacninh_huyenyenphong = [
  { value: "1", label: "Thị trấn Chờ" },
  { value: "2", label: "Xã Dũng Liệt" },
  { value: "3", label: "Xã Tam Đa" },
  { value: "4", label: "Xã Tam Giang" },
  { value: "5", label: "Xã Yên Trung" },
  { value: "6", label: "Xã Thụy Hòa" },
  { value: "7", label: "Xã Hòa Tiến" },
  { value: "8", label: "Xã Đông Tiến" },
  { value: "9", label: "Xã Yên Phụ" },
  { value: "10", label: "Xã Trung Nghĩa" },
  { value: "11", label: "Xã Đông Phong" },
  { value: "12", label: "Xã Long Châu" },
  { value: "13", label: "Xã Văn Môn" },
  { value: "14", label: "Xã Đông Thọ" },
];
export const bacninh_huyenquevo = [
  { value: "1", label: "Thị trấn Phố Mới" },
  { value: "2", label: "Xã Việt Thống" },
  { value: "3", label: "Xã Đại Xuân" },
  { value: "4", label: "Xã Nhân Hòa" },
  { value: "5", label: "Xã Bằng An" },
  { value: "6", label: "Xã Phương Liễu" },
  { value: "7", label: "Xã Quế Tân" },
  { value: "8", label: "Xã Phù Lương" },
  { value: "9", label: "Xã Phù Lãng" },
  { value: "10", label: "Xã Phượng Mao" },
  { value: "11", label: "Xã Việt Hùng" },
  { value: "12", label: "Xã Ngọc Xá" },
  { value: "13", label: "Xã Châu Phong" },
  { value: "14", label: "Xã Bồng Lai" },
  { value: "15", label: "Xã Cách Bi" },
  { value: "16", label: "Xã Đào Viên" },
  { value: "17", label: "Xã Yên Giả" },
  { value: "18", label: "Xã Mộ Đạo" },
  { value: "19", label: "Xã Đức Long" },
  { value: "20", label: "Xã Chi Lăng" },
  { value: "21", label: "Xã Hán Quảng" },
];
export const bacninh_huyentiendu = [
  { value: "1", label: "Thị trấn Lim" },
  { value: "2", label: "Xã Phú Lâm" },
  { value: "3", label: "Xã Nội Duệ" },
  { value: "4", label: "Xã Liên Bão" },
  { value: "5", label: "Xã Hiên Vân" },
  { value: "6", label: "Xã Hoàn Sơn" },
  { value: "7", label: "Xã Lạc Vệ" },
  { value: "8", label: "Xã Việt Đoàn" },
  { value: "9", label: "Xã Phật Tích" },
  { value: "10", label: "Xã Tân Chi" },
  { value: "11", label: "Xã Đại Đồng" },
  { value: "12", label: "Xã Tri Phương" },
  { value: "13", label: "Xã Minh Đạo" },
  { value: "14", label: "Xã Cảnh Hưng" },
];
export const bacninh_xatuson = [
  { value: "1", label: "Phường Đông Ngàn" },
  { value: "2", label: "Xã Tam Sơn" },
  { value: "3", label: "Xã Hương Mạc" },
  { value: "4", label: "Xã Tương Giang" },
  { value: "5", label: "Xã Phù Khê" },
  { value: "6", label: "Phường Đồng Ky" },
  { value: "7", label: "Phường Trang Hạ" },
  { value: "8", label: "Phường Đồng Nguyên" },
  { value: "9", label: "Phường Châu Khê" },
  { value: "10", label: "Phường Tân Hồng" },
  { value: "11", label: "Phường Đình Bảng" },
  { value: "12", label: "Xã Phù Chẩn" },
];
export const bacninh_huyenthuanthanh = [
  { value: "1", label: "Thị trấn Hồ" },
  { value: "2", label: "Xã Hoài Thượng" },
  { value: "3", label: "Xã Đại Đồng Thành" },
  { value: "4", label: "Xã Mão Điền" },
  { value: "5", label: "Xã Song Hồ" },
  { value: "6", label: "Xã Đình Tổ" },
  { value: "7", label: "Xã An Bình" },
  { value: "8", label: "Xã Trí Quả" },
  { value: "9", label: "Xã Gia Đông" },
  { value: "10", label: "Xã Thanh Khương" },
  { value: "11", label: "Xã Trạm Lộ" },
  { value: "12", label: "Xã Xuân Lâm" },
  { value: "13", label: "Xã Hà Mãn" },
  { value: "14", label: "Xã Ngũ Thái" },
  { value: "15", label: "Xã Nguyệt Đức" },
  { value: "16", label: "Xã Ninh Xá" },
  { value: "17", label: "Xã Nghĩa Đạo" },
  { value: "18", label: "Xã Song Liễu" },
];
export const bacninh_huyengiabinh = [
  { value: "1", label: "Thị trấn Gia Bình" },
  { value: "2", label: "Xã Vạn Ninh" },
  { value: "3", label: "Xã Thái Bảo" },
  { value: "4", label: "Xã Giang Sơn" },
  { value: "5", label: "Xã Cao Đức" },
  { value: "6", label: "Xã Đại Lai" },
  { value: "7", label: "Xã Song Giang" },
  { value: "8", label: "Xã Bình Dương" },
  { value: "9", label: "Xã Lãng Ngâm" },
  { value: "10", label: "Xã Nhân Thắng" },
  { value: "11", label: "Xã Xuân Lai" },
  { value: "12", label: "Xã Đông Cứu" },
  { value: "13", label: "Xã Đại Bái" },
  { value: "14", label: "Xã Quỳnh Phú" },
];
export const bacninh_huyenluongtai = [
  { value: "1", label: "Thị trấn Thứa" },
  { value: "2", label: "Xã An Thịnh" },
  { value: "3", label: "Xã Trung Kênh" },
  { value: "4", label: "Xã Phú Hòa" },
  { value: "5", label: "Xã Mỹ Hương" },
  { value: "6", label: "Xã Tân Lãng" },
  { value: "7", label: "Xã Quảng Phú" },
  { value: "8", label: "Xã Trừng Xá" },
  { value: "9", label: "Xã Lai Hạ" },
  { value: "10", label: "Xã Trung Chính" },
  { value: "11", label: "Xã Minh Tân" },
  { value: "12", label: "Xã Bình Định" },
  { value: "13", label: "Xã Phú Lương" },
  { value: "14", label: "Xã Lâm Thao" },
];
export const haiduong_thanhphohaihuong = [
  { value: "1", label: "Phường Cẩm Thượng" },
  { value: "2", label: "Phường Bình Hàn" },
  { value: "3", label: "Phường Ngọc Châu" },
  { value: "4", label: "Phường Nhị Châu" },
  { value: "5", label: "Phường Quang Trung" },
  { value: "6", label: "Phường Nguyễn Trãi" },
  { value: "7", label: "Phường Phạm Ngũ Lão" },
  { value: "8", label: "Phường Trần Hưng Đạo" },
  { value: "9", label: "Phường Trần Phú" },
  { value: "10", label: "Phường Thanh Bình" },
  { value: "11", label: "Phường Tân Bình" },
  { value: "12", label: "Phường Lê Thanh Nghị" },
  { value: "13", label: "Phường Hải Tân" },
  { value: "14", label: "Phường Tứ Minh" },
  { value: "15", label: "Phường Việt Hòa" },
  { value: "16", label: "Phường Ái Quốc" },
  { value: "17", label: "Xã An Châu" },
  { value: "18", label: "Xã Thượng Đạt" },
  { value: "19", label: "Xã Nam Đồng" },
  { value: "20", label: "Phường Thạch Khôi" },
  { value: "21", label: "Xã Tân Hưng" },
];
export const haiduong_xachilinh = [
  { value: "1", label: "Phường Phả Lại" },
  { value: "2", label: "Phường Sao Đỏ" },
  { value: "3", label: "Phường Bến Tắm" },
  { value: "4", label: "Xã Hoàng Hoa Thám" },
  { value: "5", label: "Xã Bắc An" },
  { value: "6", label: "Xã Hưng Đạo" },
  { value: "7", label: "Xã Lê Lợi" },
  { value: "8", label: "Xã Hoàng Tiến" },
  { value: "9", label: "Phường Cộng Hòa" },
  { value: "10", label: "Phường Hoàng Tân" },
  { value: "11", label: "Xã Cổ Thành" },
  { value: "12", label: "Phường Văn An" },
  { value: "13", label: "Phường Chí Minh" },
  { value: "14", label: "Xã Văn Đức" },
  { value: "15", label: "Phường Thái Học" },
  { value: "16", label: "Xã Nhân Huệ" },
  { value: "17", label: "Xã An Lạc" },
  { value: "18", label: "Xã Kênh Giang" },
  { value: "19", label: "Xã Đồng Lạc" },
  { value: "20", label: "Xã Tân Dân" },
];
export const haiduong_huyennamsach = [
  { value: "1", label: "Thị trấn Nam Sách" },
  { value: "2", label: "Xã Nam Hưng" },
  { value: "3", label: "Xã Nam Tân" },
  { value: "4", label: "Xã Hợp Tiến" },
  { value: "5", label: "Xã Hiệp Cát" },
  { value: "6", label: "Xã Thanh Quang" },
  { value: "7", label: "Xã Quốc Tuấn" },
  { value: "8", label: "Xã Nam Chính" },
  { value: "9", label: "Xã An Bình" },
  { value: "10", label: "Xã Nam Trung" },
  { value: "11", label: "Xã An Sơn" },
  { value: "12", label: "Xã Cộng Hòa" },
  { value: "13", label: "Xã Thái Tân" },
  { value: "14", label: "Xã An Lâm" },
  { value: "15", label: "Xã Phú Điền" },
  { value: "16", label: "Xã Nam Hồng" },
  { value: "17", label: "Xã Hồng Phong" },
  { value: "18", label: "Xã Đồng Lạc" },
  { value: "19", label: "Xã Minh Tân" },
];
export const haiduong_huyenkinhmon = [
  { value: "1", label: "Thị trấn Kinh Môn" },
  { value: "2", label: "Xã Bạch Đằng" },
  { value: "3", label: "Xã Thất Hùng" },
  { value: "4", label: "Xã Lê Ninh" },
  { value: "5", label: "Xã Hoành Sơn" },
  { value: "6", label: "Xã Phúc Thành B" },
  { value: "7", label: "Xã Thái Sơn" },
  { value: "8", label: "Xã Duy Tân" },
  { value: "9", label: "Xã Tân Dân" },
  { value: "10", label: "Thị trấn Minh Tân" },
  { value: "11", label: "Xã Quang Trung" },
  { value: "12", label: "Xã Hiệp Hòa" },
  { value: "13", label: "Xã Phạm Mệnh" },
  { value: "14", label: "Thị trấn Phú Thứ" },
  { value: "15", label: "Xã Thăng Long" },
  { value: "16", label: "Xã Lạc Long" },
  { value: "17", label: "Xã An Sinh" },
  { value: "18", label: "Xã Hiệp Sơn" },
  { value: "19", label: "Xã Thượng Quận" },
  { value: "20", label: "Xã An Phụ" },
  { value: "21", label: "Xã Hiệp An" },
  { value: "22", label: "Xã Long Xuyên" },
  { value: "23", label: "Xã Thái Thịnh" },
  { value: "24", label: "Xã Hiến Thành" },
  { value: "25", label: "Xã Minh Hòa" },
];
export const haiduong_huyenkimthanh = [
  { value: "1", label: "Thị trấn Phú Thái" },
  { value: "2", label: "Xã Lai Vu" },
  { value: "3", label: "Xã Cộng Hòa" },
  { value: "4", label: "Xã Thượng Vũ" },
  { value: "5", label: "Xã Cổ Dũng" },
  { value: "6", label: "Xã Việt Hưng" },
  { value: "7", label: "Xã Tuấn Hưng" },
  { value: "8", label: "Xã Kim Xuyên" },
  { value: "9", label: "Xã Phúc Thành A" },
  { value: "10", label: "Xã Ngũ Phúc" },
  { value: "11", label: "Xã Kim Anh" },
  { value: "12", label: "Xã Kim Lương" },
  { value: "13", label: "Xã Kim Tân" },
  { value: "14", label: "Xã Kim Khê" },
  { value: "15", label: "Xã Kim Đính" },
  { value: "16", label: "Xã Cẩm La" },
  { value: "17", label: "Xã Bình Dân" },
  { value: "18", label: "Xã Tam Kỳ" },
  { value: "19", label: "Xã Đồng Gia" },
  { value: "20", label: "Xã Liên Hòa" },
  { value: "21", label: "Xã Đại Đức" },
];
export const haiduong_huyenthanhha = [
  { value: "1", label: "Thị trấn Thanh Hà" },
  { value: "2", label: "Xã Hồng Lạc" },
  { value: "3", label: "Xã Việt Hồng" },
  { value: "4", label: "Xã Quyết Thắng" },
  { value: "5", label: "Xã Tân Việt" },
  { value: "6", label: "Xã Cẩm Chế" },
  { value: "7", label: "Xã Thanh An" },
  { value: "8", label: "Xã Thanh Lang" },
  { value: "9", label: "Xã Tiền Tiến" },
  { value: "10", label: "Xã Tân An" },
  { value: "11", label: "Xã Liên Mạc" },
  { value: "12", label: "Xã Thanh Hải" },
  { value: "13", label: "Xã Thanh Khê" },
  { value: "14", label: "Xã Thanh Xá" },
  { value: "15", label: "Xã Thanh Xuân" },
  { value: "16", label: "Xã An Lương" },
  { value: "17", label: "Xã Thanh Thủy" },
  { value: "18", label: "Xã Phượng Hoàng" },
  { value: "19", label: "Xã Thanh Sơn" },
  { value: "20", label: "Xã Hợp Đức" },
  { value: "21", label: "Xã Trường Thành" },
  { value: "22", label: "Xã Thanh Bính" },
  { value: "23", label: "Xã Thanh Hồng" },
  { value: "24", label: "Xã Thanh Cường" },
  { value: "25", label: "Xã Vĩnh Lập" },
];
export const haiduong_huyencamgiang = [
  { value: "1", label: "Thị trấn Cẩm Giàng" },
  { value: "2", label: "Thị trấn Lai Cách" },
  { value: "3", label: "Xã Cẩm Hưng" },
  { value: "4", label: "Xã Cẩm Hoàng" },
  { value: "5", label: "Xã Cẩm Văn" },
  { value: "6", label: "Xã Ngọc Liên" },
  { value: "7", label: "Xã Thạch Lỗi" },
  { value: "8", label: "Xã Cẩm Vũ" },
  { value: "9", label: "Xã Đức Chính" },
  { value: "10", label: "Xã Cẩm Sơn" },
  { value: "11", label: "Xã Cẩm Định" },
  { value: "12", label: "Xã Kim Giang" },
  { value: "13", label: "Xã Lương Điền" },
  { value: "14", label: "Xã Cao An" },
  { value: "15", label: "Xã Tân Trường" },
  { value: "16", label: "Xã Cẩm Phúc" },
  { value: "17", label: "Xã Cẩm Điền" },
  { value: "18", label: "Xã Cẩm Đông" },
  { value: "19", label: "Xã Cẩm Đoài" },
];
export const haiduong_huyenbinhgiang = [
  { value: "1", label: "Thị trấn Kẻ Sặt" },
  { value: "2", label: "Xã Hưng Thịnh" },
  { value: "3", label: "Xã Vĩnh Tuy" },
  { value: "4", label: "Xã Hùng Thắng" },
  { value: "5", label: "Xã Tráng Liệt" },
  { value: "6", label: "Xã Vĩnh Hồng" },
  { value: "7", label: "Xã Long Xuyên" },
  { value: "8", label: "Xã Tân Việt" },
  { value: "9", label: "Xã Thúc Kháng" },
  { value: "10", label: "Xã Tân Hồng" },
  { value: "11", label: "Xã Bình Minh" },
  { value: "12", label: "Xã Hồng Khê" },
  { value: "13", label: "Xã Thái Học" },
  { value: "14", label: "Xã Cổ Bi" },
  { value: "15", label: "Xã Nhân Quyền" },
  { value: "16", label: "Xã Thái Dương" },
  { value: "17", label: "Xã Thái Hòa" },
  { value: "18", label: "Xã Bình Xuyên" },
];
export const haiduong_huyengialoc = [
  { value: "1", label: "Thị trấn Gia Lộc" },
  { value: "2", label: "Xã Liên Hồng" },
  { value: "3", label: "Xã Thống Nhất" },
  { value: "4", label: "Xã Trùng Khánh" },
  { value: "5", label: "Xã Gia Xuyên" },
  { value: "6", label: "Xã Yết Kiêu" },
  { value: "7", label: "Xã Gia Hòa" },
  { value: "8", label: "Xã Phương Hưng" },
  { value: "9", label: "Xã Gia Tân" },
  { value: "10", label: "Xã Tân Tiến" },
  { value: "11", label: "Xã Gia Khánh" },
  { value: "12", label: "Xã Gia Lương" },
  { value: "13", label: "Xã Lê Lợi" },
  { value: "14", label: "Xã Toàn Thắng" },
  { value: "15", label: "Xã Hoàng Diệu" },
  { value: "16", label: "Xã Hồng Hưng" },
  { value: "17", label: "Xã Phạm Trấn" },
  { value: "18", label: "Xã Đoàn Thượng" },
  { value: "19", label: "Xã Thống Kênh" },
  { value: "20", label: "Xã Quang Minh" },
  { value: "21", label: "Xã Đồng Quang" },
  { value: "22", label: "Xã Nhật Tân" },
  { value: "23", label: "Xã Đức Xương" },
];
export const haiduong_huyentuky = [
  { value: "1", label: "Thị trấn Tứ Kỳ" },
  { value: "2", label: "Xã Ngọc Sơn" },
  { value: "3", label: "Xã Kỳ Sơn" },
  { value: "4", label: "Xã Đại Đồng" },
  { value: "5", label: "Xã Hưng Đạo" },
  { value: "6", label: "Xã Ngọc Kỳ" },
  { value: "7", label: "Xã Bình Lăng" },
  { value: "8", label: "Xã Tứ Xuyên" },
  { value: "9", label: "Xã Tái Sơn" },
  { value: "10", label: "Xã Quang Phục" },
  { value: "11", label: "Xã Đông Kỳ" },
  { value: "12", label: "Xã Tây Kỳ" },
  { value: "13", label: "Xã Dân Chủ" },
  { value: "14", label: "Xã Tân Kỳ" },
  { value: "15", label: "Xã Quang Khải" },
  { value: "16", label: "Xã Đại Hợp" },
  { value: "17", label: "Xã Quảng Nghiệp" },
  { value: "18", label: "Xã An Thanh" },
  { value: "19", label: "Xã Minh Đức" },
  { value: "20", label: "Xã Văn Tố" },
  { value: "21", label: "Xã Quang Trung" },
  { value: "22", label: "Xã Phượng Kỳ" },
  { value: "23", label: "Xã Cộng Lạc" },
  { value: "24", label: "Xã Tiên Động" },
  { value: "25", label: "Xã Nguyên Giáp" },
  { value: "26", label: "Xã Hà Kỳ" },
  { value: "27", label: "Xã Hà Thanh" },
];
export const haiduong_huyenninhgiang = [
  { value: "1", label: "Thị trấn Ninh Giang" },
  { value: "2", label: "Xã Quyết Thắng" },
  { value: "3", label: "Xã Ứng Hoè" },
  { value: "4", label: "Xã Nghĩa An" },
  { value: "5", label: "Xã Hồng Đức" },
  { value: "6", label: "Xã Ninh Hòa" },
  { value: "7", label: "Xã An Đức" },
  { value: "8", label: "Xã Vạn Phúc" },
  { value: "9", label: "Xã Tân Hương" },
  { value: "10", label: "Xã Ninh Thành" },
  { value: "11", label: "Xã Vĩnh Hòa" },
  { value: "12", label: "Xã Đông Xuyên" },
  { value: "13", label: "Xã Hoàng Hanh" },
  { value: "14", label: "Xã Quang Hưng" },
  { value: "15", label: "Xã Tân Phong" },
  { value: "16", label: "Xã Ninh Hải" },
  { value: "17", label: "Xã Đồng Tâm" },
  { value: "18", label: "Xã Tân Quang" },
  { value: "19", label: "Xã Kiến Quốc" },
  { value: "20", label: "Xã Hồng Thái" },
  { value: "21", label: "Xã Hồng Dụ" },
  { value: "22", label: "Xã Văn Hội" },
  { value: "23", label: "Xã Hưng Thái" },
  { value: "24", label: "Xã Hồng Phong" },
  { value: "25", label: "Xã Hiệp Lực" },
  { value: "26", label: "Xã Hồng Phúc" },
  { value: "27", label: "Xã Hưng Long" },
  { value: "28", label: "Xã Văn Giang" },
];
export const haiduong_huyenthanhmien = [
  { value: "1", label: "Thị trấn Thanh Miện" },
  { value: "2", label: "Xã Thanh Tùng" },
  { value: "3", label: "Xã Phạm Kha" },
  { value: "4", label: "Xã Ngô Quyền" },
  { value: "5", label: "Xã Đoàn Tùng" },
  { value: "6", label: "Xã Hồng Quang" },
  { value: "7", label: "Xã Tân Trào" },
  { value: "8", label: "Xã Lam Sơn" },
  { value: "9", label: "Xã Đoàn Kết" },
  { value: "10", label: "Xã Lê Hồng" },
  { value: "11", label: "Xã Tứ Cường" },
  { value: "12", label: "Xã Hùng Sơn" },
  { value: "13", label: "Xã Ngũ Hùng" },
  { value: "14", label: "Xã Cao Thắng" },
  { value: "15", label: "Xã Chi Lăng Bắc" },
  { value: "16", label: "Xã Chi Lăng Nam" },
  { value: "17", label: "Xã Thanh Giang" },
  { value: "18", label: "Xã Diên Hồng" },
  { value: "19", label: "Xã Tiền Phong" },
];

export const haiphong_quanhongbang = [
  { value: "1", label: "Phường Quán Toan" },
  { value: "2", label: "Phường Hùng Vương" },
  { value: "3", label: "Phường Sở Dầu" },
  { value: "4", label: "Phường Thượng Lý" },
  { value: "5", label: "Phường Hạ Lý" },
  { value: "6", label: "Phường Minh Khai" },
  { value: "7", label: "Phường Trại Chuối" },
  { value: "8", label: "Phường Quang Trung" },
  { value: "9", label: "Phường Hoàng Văn Thụ" },
  { value: "10", label: "Phường Phan Bội Châu" },
  { value: "11", label: "Phường Phạm Hồng Thái" },
];
export const haiphong_quanngoquyen = [
  { value: "1", label: "Phường Máy Chai" },
  { value: "2", label: "Phường Máy Tơ" },
  { value: "3", label: "Phường Vạn Mỹ" },
  { value: "4", label: "Phường Cầu Tre" },
  { value: "5", label: "Phường Lạc Viên" },
  { value: "6", label: "Phường Lương Khánh Thiện" },
  { value: "7", label: "Phường Gia Viên" },
  { value: "8", label: "Phường Đông Khê" },
  { value: "9", label: "Phường Cầu Đất" },
  { value: "10", label: "Phường Lê Lợi" },
  { value: "11", label: "Phường Đằng Giang" },
  { value: "12", label: "Phường Lạch Tray" },
  { value: "13", label: "Phường Đổng Quốc Bình" },
];
export const haiphong_quanlechan = [
  { value: "1", label: "Phường Cát Dài" },
  { value: "2", label: "Phường An Biên" },
  { value: "3", label: "Phường Lam Sơn" },
  { value: "4", label: "Phường An Dương" },
  { value: "5", label: "Phường Trần Nguyên Hãn" },
  { value: "6", label: "Phường Hồ Nam" },
  { value: "7", label: "Phường Trại Cau" },
  { value: "8", label: "Phường Dư Hàng" },
  { value: "9", label: "Phường Hàng Kênh" },
  { value: "10", label: "Phường Đông Hải" },
  { value: "11", label: "Phường Niệm Nghĩa" },
  { value: "12", label: "Phường Nghĩa Xá" },
  { value: "13", label: "Phường Dư Hàng Kênh" },
  { value: "14", label: "Phường Kênh Dương" },
  { value: "15", label: "Phường Vĩnh Niệm" },
];
export const haiphong_quanhaian = [
  { value: "1", label: "Phường Đông Hải 1" },
  { value: "2", label: "Phường Đông Hải 2" },
  { value: "3", label: "Phường Đằng Lâm" },
  { value: "4", label: "Phường Thành Tô" },
  { value: "5", label: "Phường Đằng Hải" },
  { value: "6", label: "Phường Nam Hải" },
  { value: "7", label: "Phường Cát Bi" },
  { value: "8", label: "Phường Tràng Cát" },
];
export const haiphong_quankienan = [
  { value: "1", label: "Phường Quán Trữ" },
  { value: "2", label: "Phường Lãm Hà" },
  { value: "3", label: "Phường Đồng Hòa" },
  { value: "4", label: "Phường Bắc Sơn" },
  { value: "5", label: "Phường Nam Sơn" },
  { value: "6", label: "Phường Ngọc Sơn" },
  { value: "7", label: "Phường Trần Thành Ngọ" },
  { value: "8", label: "Phường Văn Đẩu" },
  { value: "9", label: "Phường Phù Liễn" },
  { value: "10", label: "Phường Tràng Minh" },
];
export const haiphong_huyendoson = [
  { value: "1", label: "Phường Ngọc Xuyên" },
  { value: "2", label: "Phường Ngọc Hải" },
  { value: "3", label: "Phường Vạn Hương" },
  { value: "4", label: "Phường Vạn Sơn" },
  { value: "5", label: "Phường Minh Đức" },
  { value: "6", label: "Phường Bàng La" },
  { value: "7", label: "Phường Hợp Đức" },
];
export const haiphong_huyenduongkinh = [
  { value: "1", label: "Phường Đa Phúc" },
  { value: "2", label: "Phường Hưng Đạo" },
  { value: "3", label: "Phường Anh Dũng" },
  { value: "4", label: "Phường Hải Thành" },
  { value: "5", label: "Phường Hòa Nghĩa" },
  { value: "6", label: "Phường Tân Thành" },
];
export const haiphong_huyenthuynguyen = [
  { value: "1", label: "Thị trấn Núi Đèo" },
  { value: "2", label: "Thị trấn Minh Đức" },
  { value: "3", label: "Xã Lại Xuân" },
  { value: "4", label: "Xã An Sơn" },
  { value: "5", label: "Xã Kỳ Sơn" },
  { value: "6", label: "Xã Liên Khê" },
  { value: "7", label: "Xã Lưu Kiếm" },
  { value: "8", label: "Xã Lưu Kỳ" },
  { value: "9", label: "Xã Gia Minh" },
  { value: "10", label: "Xã Gia Đức" },
  { value: "11", label: "Xã Minh Tân" },
  { value: "12", label: "Xã Phù Ninh" },
  { value: "13", label: "Xã Quảng Thanh" },
  { value: "14", label: "Xã Chính Mỹ" },
  { value: "15", label: "Xã Kênh Giang" },
  { value: "16", label: "Xã Hợp Thành" },
  { value: "17", label: "Xã Cao Nhân" },
  { value: "18", label: "Xã Mỹ Đồng" },
  { value: "19", label: "Xã Đông Sơn" },
  { value: "20", label: "Xã Hòa Bình" },
  { value: "21", label: "Xã Trung Hà" },
  { value: "22", label: "Xã An Lư" },
  { value: "23", label: "Xã Thuỷ Triều" },
  { value: "24", label: "Xã Ngũ Lão" },
  { value: "25", label: "Xã Phục Lễ" },
  { value: "26", label: "Xã Tam Hưng" },
  { value: "27", label: "Xã Phả Lễ" },
  { value: "28", label: "Xã Lập Lễ" },
  { value: "29", label: "Xã Kiền Bái" },
  { value: "30", label: "Xã Thiên Hương" },
  { value: "31", label: "Xã Thuỷ Sơn" },
  { value: "32", label: "Xã Thuỷ Đường" },
  { value: "33", label: "Xã Hoàng Động" },
  { value: "34", label: "Xã Lâm Động" },
  { value: "35", label: "Xã Hoa Động" },
  { value: "36", label: "Xã Tân Dương" },
  { value: "37", label: "Xã Dương Quan" },
];
export const haiphong_huyenanduong = [
  { value: "1", label: "Thị trấn An Dương" },
  { value: "2", label: "Xã Lê Thiện" },
  { value: "3", label: "Xã Đại Bản" },
  { value: "4", label: "Xã An Hòa" },
  { value: "5", label: "Xã Hồng Phong" },
  { value: "6", label: "Xã Tân Tiến" },
  { value: "7", label: "Xã An Hưng" },
  { value: "8", label: "Xã An Hồng" },
  { value: "9", label: "Xã Bắc Sơn" },
  { value: "10", label: "Xã Nam Sơn" },
  { value: "11", label: "Xã Lê Lợi" },
  { value: "12", label: "Xã Đặng Cương" },
  { value: "13", label: "Xã Đồng Thái" },
  { value: "14", label: "Xã Quốc Tuấn" },
  { value: "15", label: "Xã An Đồng" },
  { value: "16", label: "Xã Hồng Thái" },
];
export const haiphong_huyenanlao = [
  { value: "1", label: "Thị trấn An Lão" },
  { value: "2", label: "Xã Bát Trang" },
  { value: "3", label: "Xã Trường Thọ" },
  { value: "4", label: "Xã Trường Thành" },
  { value: "5", label: "Xã An Tiến" },
  { value: "6", label: "Xã Quang Hưng" },
  { value: "7", label: "Xã Quang Trung" },
  { value: "8", label: "Xã Quốc Tuấn" },
  { value: "9", label: "Xã An Thắng" },
  { value: "10", label: "Thị trấn Trường Sơn" },
  { value: "11", label: "Xã Tân Dân" },
  { value: "12", label: "Xã Thái Sơn" },
  { value: "13", label: "Xã Tân Viên" },
  { value: "14", label: "Xã Mỹ Đức" },
  { value: "15", label: "Xã Chiến Thắng" },
  { value: "16", label: "Xã An Thọ" },
  { value: "17", label: "Xã An Thái" },
];
export const haiphong_huyenkienthuy = [
  { value: "1", label: "Thị trấn Núi Đối" },
  { value: "2", label: "Xã Đông Phương" },
  { value: "3", label: "Xã Thuận Thiên" },
  { value: "4", label: "Xã Hữu Bằng" },
  { value: "5", label: "Xã Đại Đồng" },
  { value: "6", label: "Xã Ngũ Phúc" },
  { value: "7", label: "Xã Kiến Quốc" },
  { value: "8", label: "Xã Du Lễ" },
  { value: "9", label: "Xã Thuy Hương" },
  { value: "10", label: "Xã Thanh Sơn" },
  { value: "11", label: "Xã Minh Tân" },
  { value: "12", label: "Xã Đại Hà" },
  { value: "13", label: "Xã Ngũ Đoan" },
  { value: "14", label: "Xã Tân Phong" },
  { value: "15", label: "Xã Tân Trào" },
  { value: "16", label: "Xã Đoàn Xá" },
  { value: "17", label: "Xã Tú Sơn" },
  { value: "18", label: "Xã Đại Hợp" },
];
export const haiphong_huyentienlang = [
  { value: "1", label: "Thị trấn Tiên Lãng" },
  { value: "2", label: "Xã Đại Thắng" },
  { value: "3", label: "Xã Tiên Cường" },
  { value: "4", label: "Xã Tự Cường" },
  { value: "5", label: "Xã Tiên Tiến" },
  { value: "6", label: "Xã Quyết Tiến" },
  { value: "7", label: "Xã Khởi Nghĩa" },
  { value: "8", label: "Xã Tiên Thanh" },
  { value: "9", label: "Xã Cấp Tiến" },
  { value: "10", label: "Xã Kiến Thiết" },
  { value: "11", label: "Xã Đoàn Lập" },
  { value: "12", label: "Xã Bạch Đằng" },
  { value: "13", label: "Xã Quang Phục" },
  { value: "14", label: "Xã Toàn Thắng" },
  { value: "15", label: "Xã Tiên Thắng" },
  { value: "16", label: "Xã Tiên Minh" },
  { value: "17", label: "Xã Bắc Hưng" },
  { value: "18", label: "Xã Nam Hưng" },
  { value: "19", label: "Xã Hùng Thắng" },
  { value: "20", label: "Xã Tây Hưng" },
  { value: "21", label: "Xã Đông Hưng" },
  { value: "22", label: "Xã Tiên Hưng" },
  { value: "23", label: "Xã Vinh Quang" },
];
export const haiphong_huyenvinhbao = [
  { value: "1", label: "Thị trấn Vĩnh Bảo" },
  { value: "2", label: "Xã Dũng Tiến" },
  { value: "3", label: "Xã Giang Biên" },
  { value: "4", label: "Xã Thắng Thuỷ" },
  { value: "5", label: "Xã Trung Lập" },
  { value: "6", label: "Xã Việt Tiến" },
  { value: "7", label: "Xã Vĩnh An" },
  { value: "8", label: "Xã Vĩnh Long" },
  { value: "9", label: "Xã Hiệp Hòa" },
  { value: "10", label: "Xã Hùng Tiến" },
  { value: "11", label: "Xã An Hòa" },
  { value: "12", label: "Xã Tân Hưng" },
  { value: "13", label: "Xã Tân Liên" },
  { value: "14", label: "Xã Nhân Hòa" },
  { value: "15", label: "Xã Tam Đa" },
  { value: "16", label: "Xã Hưng Nhân" },
  { value: "17", label: "Xã Vinh Quang" },
  { value: "18", label: "Xã Đồng Minh" },
  { value: "19", label: "Xã Thanh Lương" },
  { value: "20", label: "Xã Liên Am" },
  { value: "21", label: "Xã Lý Học" },
  { value: "22", label: "Xã Tam Cường" },
  { value: "23", label: "Xã Hòa Bình" },
  { value: "24", label: "Xã Tiền Phong" },
  { value: "25", label: "Xã Vĩnh Phong" },
  { value: "26", label: "Xã Cộng Hiền" },
  { value: "27", label: "Xã Cao Minh" },
  { value: "28", label: "Xã Cổ Am" },
  { value: "29", label: "Xã Vĩnh Tiến" },
  { value: "30", label: "Xã Trấn Dương" },
];
export const haiphong_huyencathai = [
  { value: "1", label: "Thị trấn Cát Bà" },
  { value: "2", label: "Thị trấn Cát Hải" },
  { value: "3", label: "Xã Nghĩa Lộ" },
  { value: "4", label: "Xã Đồng Bài" },
  { value: "5", label: "Xã Hoàng Châu" },
  { value: "6", label: "Xã Văn Phong" },
  { value: "7", label: "Xã Phù Long" },
  { value: "8", label: "Xã Gia Luận" },
  { value: "9", label: "Xã Hiền Hào" },
  { value: "10", label: "Xã Trân Châu" },
  { value: "11", label: "Xã Việt Hải" },
  { value: "12", label: "Xã Xuân Đám" },
];
export const hungyen_thanhphohungyen = [
  { value: "1", label: "Phường Lam Sơn" },
  { value: "2", label: "Phường Hiến Nam" },
  { value: "3", label: "Phường An Tảo" },
  { value: "4", label: "Phường Lê Lợi" },
  { value: "5", label: "Phường Minh Khai" },
  { value: "6", label: "Phường Quang Trung" },
  { value: "7", label: "Phường Hồng Châu" },
  { value: "8", label: "Xã Trung Nghĩa" },
  { value: "9", label: "Xã Liên Phương" },
  { value: "10", label: "Xã Hồng Nam" },
  { value: "11", label: "Xã Quảng Châu" },
  { value: "12", label: "Xã Bảo Khê" },
  { value: "13", label: "Xã Phú Cường" },
  { value: "14", label: "Xã Hùng Cường" },
  { value: "15", label: "Xã Phương Chiểu" },
  { value: "16", label: "Xã Tân Hưng" },
  { value: "17", label: "Xã Hoàng Hanh" },
];
export const hungyen_huyenvanlam = [
  { value: "1", label: "Thị trấn Như Quỳnh" },
  { value: "2", label: "Xã Lạc Đạo" },
  { value: "3", label: "Xã Chỉ Đạo" },
  { value: "4", label: "Xã Đại Đồng" },
  { value: "5", label: "Xã Việt Hưng" },
  { value: "6", label: "Xã Tân Quang" },
  { value: "7", label: "Xã Đình Dù" },
  { value: "8", label: "Xã Minh Hải" },
  { value: "9", label: "Xã Lương Tài" },
  { value: "10", label: "Xã Trưng Trắc" },
  { value: "11", label: "Xã Lạc Hồng" },
];
export const hungyen_huyenvangiang = [
  { value: "1", label: "Thị trấn Văn Giang" },
  { value: "2", label: "Xã Xuân Quan" },
  { value: "3", label: "Xã Cửu Cao" },
  { value: "4", label: "Xã Phụng Công" },
  { value: "5", label: "Xã Nghĩa Trụ" },
  { value: "6", label: "Xã Long Hưng" },
  { value: "7", label: "Xã Vĩnh Khúc" },
  { value: "8", label: "Xã Liên Nghĩa" },
  { value: "9", label: "Xã Tân Tiến" },
  { value: "10", label: "Xã Thắng Lợi" },
  { value: "11", label: "Xã Mễ Sở" },
];
export const hungyen_huyenyenmy = [
  { value: "1", label: "Thị trấn Yên Mỹ" },
  { value: "2", label: "Xã Giai Phạm" },
  { value: "3", label: "Xã Nghĩa Hiệp" },
  { value: "4", label: "Xã Đồng Than" },
  { value: "5", label: "Xã Ngọc Long" },
  { value: "6", label: "Xã Liêu Xá" },
  { value: "7", label: "Xã Hoàn Long" },
  { value: "8", label: "Xã Tân Lập" },
  { value: "9", label: "Xã Thanh Long" },
  { value: "10", label: "Xã Yên Phú" },
  { value: "11", label: "Xã Việt Cường" },
  { value: "12", label: "Xã Trung Hòa" },
  { value: "13", label: "Xã Yên Hòa" },
  { value: "14", label: "Xã Minh Châu" },
  { value: "15", label: "Xã Trung Hưng" },
  { value: "16", label: "Xã Lý Thường Kiệt" },
  { value: "17", label: "Xã Tân Việt" },
];
export const hungyen_huyenmyhao = [
  { value: "1", label: "Thị trấn Bần Yên Nhân" },
  { value: "2", label: "Xã Phan Đình Phùng" },
  { value: "3", label: "Xã Cẩm Xá" },
  { value: "4", label: "Xã Dương Quang" },
  { value: "5", label: "Xã Hòa Phong" },
  { value: "6", label: "Xã Nhân Hòa" },
  { value: "7", label: "Xã Dị Sử" },
  { value: "8", label: "Xã Bạch Sam" },
  { value: "9", label: "Xã Minh Đức" },
  { value: "10", label: "Xã Phùng Chí Kiên" },
  { value: "11", label: "Xã Xuân Dục" },
  { value: "12", label: "Xã Ngọc Lâm" },
  { value: "13", label: "Xã Hưng Long" },
];
export const hungyen_huyenanthi = [
  { value: "1", label: "Thị trấn Ân Thi" },
  { value: "2", label: "Xã Phù Ủng" },
  { value: "3", label: "Xã Bắc Sơn" },
  { value: "4", label: "Xã Bãi Sậy" },
  { value: "5", label: "Xã Đào Dương" },
  { value: "6", label: "Xã Tân Phúc" },
  { value: "7", label: "Xã Vân Du" },
  { value: "8", label: "Xã Quang Vinh" },
  { value: "9", label: "Xã Xuân Trúc" },
  { value: "10", label: "Xã Hoàng Hoa Thám" },
  { value: "11", label: "Xã Quảng Lãng" },
  { value: "12", label: "Xã Văn Nhuệ" },
  { value: "13", label: "Xã Đặng Lễ" },
  { value: "14", label: "Xã Cẩm Ninh" },
  { value: "15", label: "Xã Nguyễn Trãi" },
  { value: "16", label: "Xã Đa Lộc" },
  { value: "17", label: "Xã Hồ Tùng Mậu" },
  { value: "18", label: "Xã Tiền Phong" },
  { value: "19", label: "Xã Hồng Vân" },
  { value: "20", label: "Xã Hồng Quang" },
  { value: "21", label: "Xã Hạ Lễ" },
];
export const hungyen_huyenkhoaichau = [
  { value: "1", label: "Thị trấn Khoái Châu" },
  { value: "2", label: "Xã Đông Tảo" },
  { value: "3", label: "Xã Bình Minh" },
  { value: "4", label: "Xã Dạ Trạch" },
  { value: "5", label: "Xã Hàm Tử" },
  { value: "6", label: "Xã Ông Đình" },
  { value: "7", label: "Xã Tân Dân" },
  { value: "8", label: "Xã Tứ Dân" },
  { value: "9", label: "Xã An Vĩ" },
  { value: "10", label: "Xã Đông Kết" },
  { value: "11", label: "Xã Bình Kiều" },
  { value: "12", label: "Xã Dân Tiến" },
  { value: "13", label: "Xã Đồng Tiến" },
  { value: "14", label: "Xã Hồng Tiến" },
  { value: "15", label: "Xã Tân Châu" },
  { value: "16", label: "Xã Liên Khê" },
  { value: "17", label: "Xã Phùng Hưng" },
  { value: "18", label: "Xã Việt Hòa" },
  { value: "19", label: "Xã Đông Ninh" },
  { value: "20", label: "Xã Đại Tập" },
  { value: "21", label: "Xã Chí Tân" },
  { value: "22", label: "Xã Đại Hưng" },
  { value: "23", label: "Xã Thuần Hưng" },
  { value: "24", label: "Xã Thành Công" },
  { value: "25", label: "Xã Nhuế Dương" },
];
export const hungyen_huyenkimdong = [
  { value: "1", label: "Thị trấn Lương Bằng" },
  { value: "2", label: "Xã Nghĩa Dân" },
  { value: "3", label: "Xã Toàn Thắng" },
  { value: "4", label: "Xã Vĩnh Xá" },
  { value: "5", label: "Xã Phạm Ngũ Lão" },
  { value: "6", label: "Xã Thọ Vinh" },
  { value: "7", label: "Xã Đồng Thanh" },
  { value: "8", label: "Xã Song Mai" },
  { value: "9", label: "Xã Chính Nghĩa" },
  { value: "10", label: "Xã Nhân La" },
  { value: "11", label: "Xã Phú Thịnh" },
  { value: "12", label: "Xã Mai Động" },
  { value: "13", label: "Xã Đức Hợp" },
  { value: "14", label: "Xã Hùng An" },
  { value: "15", label: "Xã Ngọc Thanh" },
  { value: "16", label: "Xã Vũ Xá" },
  { value: "17", label: "Xã Hiệp Cường" },
];
export const hungyen_huyentienlu = [
  { value: "1", label: "Thị trấn Vương" },
  { value: "2", label: "Xã Hưng Đạo" },
  { value: "3", label: "Xã Ngô Quyền" },
  { value: "4", label: "Xã Nhật Tân" },
  { value: "5", label: "Xã Dị Chế" },
  { value: "6", label: "Xã Lệ Xá" },
  { value: "7", label: "Xã An Viên" },
  { value: "8", label: "Xã Đức Thắng" },
  { value: "9", label: "Xã Trung Dũng" },
  { value: "10", label: "Xã Hải Triều" },
  { value: "11", label: "Xã Thủ Sỹ" },
  { value: "12", label: "Xã Thiện Phiến" },
  { value: "13", label: "Xã Thụy Lôi" },
  { value: "14", label: "Xã Cương Chính" },
  { value: "15", label: "Xã Minh Phương" },
];
export const hungyen_huyenphucu = [
  { value: "1", label: "Thị trấn Trần Cao" },
  { value: "2", label: "Xã Minh Tân" },
  { value: "3", label: "Xã Phan Sào Nam" },
  { value: "4", label: "Xã Quang Hưng" },
  { value: "5", label: "Xã Minh Hoàng" },
  { value: "6", label: "Xã Đoàn Đào" },
  { value: "7", label: "Xã Tống Phan" },
  { value: "8", label: "Xã Đình Cao" },
  { value: "9", label: "Xã Nhật Quang" },
  { value: "10", label: "Xã Tiền Tiến" },
  { value: "11", label: "Xã Tam Đa" },
  { value: "12", label: "Xã Minh Tiến" },
  { value: "13", label: "Xã Nguyên Hòa" },
  { value: "14", label: "Xã Tống Trân" },
];
export const thaibinh_thanhphothaibinh = [
  { value: "1", label: "Phường Lê Hồng Phong" },
  { value: "2", label: "Phường Bồ Xuyên" },
  { value: "3", label: "Phường Đề Thám" },
  { value: "4", label: "Phường Kỳ Bá" },
  { value: "5", label: "Phường Quang Trung" },
  { value: "6", label: "Phường Phú Khánh" },
  { value: "7", label: "Phường Tiền Phong" },
  { value: "8", label: "Phường Trần Hưng Đạo" },
  { value: "9", label: "Phường Trần Lãm" },
  { value: "10", label: "Xã Đông Hòa" },
  { value: "11", label: "Phường Hoàng Diệu" },
  { value: "12", label: "Xã Phú Xuân" },
  { value: "13", label: "Xã Vũ Phúc" },
  { value: "14", label: "Xã Vũ Chính" },
  { value: "15", label: "Xã Đông Mỹ" },
  { value: "16", label: "Xã Đông Thọ" },
  { value: "17", label: "Xã Vũ Đông" },
  { value: "18", label: "Xã Vũ Lạc" },
  { value: "19", label: "Xã Tân Bình" },
];
export const thaibinh_huyenquynhphu = [
  { value: "1", label: "Thị trấn Quỳnh Côi" },
  { value: "2", label: "Xã An Khê" },
  { value: "3", label: "Xã An Đồng" },
  { value: "4", label: "Xã Quỳnh Hoa" },
  { value: "5", label: "Xã Quỳnh Lâm" },
  { value: "6", label: "Xã Quỳnh Thọ" },
  { value: "7", label: "Xã An Hiệp" },
  { value: "8", label: "Xã Quỳnh Hoàng" },
  { value: "9", label: "Xã Quỳnh Giao" },
  { value: "10", label: "Xã An Thái" },
  { value: "11", label: "Xã An Cầu" },
  { value: "12", label: "Xã Quỳnh Hồng" },
  { value: "13", label: "Xã Quỳnh Khê" },
  { value: "14", label: "Xã Quỳnh Minh" },
  { value: "15", label: "Xã An Ninh" },
  { value: "16", label: "Xã Quỳnh Ngọc" },
  { value: "17", label: "Xã Quỳnh Hải" },
  { value: "18", label: "Thị trấn An Bài" },
  { value: "19", label: "Xã An Ấp" },
  { value: "20", label: "Xã Quỳnh Hội" },
  { value: "21", label: "Xã Quỳnh Sơn" },
  { value: "22", label: "Xã Quỳnh Mỹ" },
  { value: "23", label: "Xã An Quí" },
  { value: "24", label: "Xã An Thanh" },
  { value: "25", label: "Xã Quỳnh Châu" },
  { value: "26", label: "Xã An Vũ" },
  { value: "27", label: "Xã An Lễ" },
  { value: "28", label: "Xã Quỳnh Hưng" },
  { value: "29", label: "Xã Quỳnh Bảo" },
  { value: "30", label: "Xã An Mỹ" },
  { value: "31", label: "Xã Quỳnh Nguyên" },
  { value: "32", label: "Xã An Vinh" },
  { value: "33", label: "Xã Quỳnh Xá" },
  { value: "34", label: "Xã An Dục" },
  { value: "35", label: "Xã Đông Hải" },
  { value: "36", label: "Xã Quỳnh Trang" },
  { value: "37", label: "Xã An Tràng" },
  { value: "38", label: "Xã Đồng Tiến" },
];
export const thaibinh_huyenhungha = [
  { value: "1", label: "Thị trấn Hưng Hà" },
  { value: "2", label: "Xã Điệp Nông" },
  { value: "3", label: "Xã Tân Lễ" },
  { value: "4", label: "Xã Cộng Hòa" },
  { value: "5", label: "Xã Dân Chủ" },
  { value: "6", label: "Xã Canh Tân" },
  { value: "7", label: "Xã Hòa Tiến" },
  { value: "8", label: "Xã Hùng Dũng" },
  { value: "9", label: "Xã Tân Tiến" },
  { value: "10", label: "Thị trấn Hưng Nhân" },
  { value: "11", label: "Xã Đoan Hùng" },
  { value: "12", label: "Xã Duyên Hải" },
  { value: "13", label: "Xã Tân Hòa" },
  { value: "14", label: "Xã Văn Cẩm" },
  { value: "15", label: "Xã Bắc Sơn" },
  { value: "16", label: "Xã Đông Đô" },
  { value: "17", label: "Xã Phúc Khánh" },
  { value: "18", label: "Xã Liên Hiệp" },
  { value: "19", label: "Xã Tây Đô" },
  { value: "20", label: "Xã Thống Nhất" },
  { value: "21", label: "Xã Tiến Đức" },
  { value: "22", label: "Xã Thái Hưng" },
  { value: "23", label: "Xã Thái Phương" },
  { value: "24", label: "Xã Hòa Bình" },
  { value: "25", label: "Xã Chi Lăng" },
  { value: "26", label: "Xã Minh Khai" },
  { value: "27", label: "Xã Hồng An" },
  { value: "28", label: "Xã Kim Chung" },
  { value: "29", label: "Xã Hồng Lĩnh" },
  { value: "30", label: "Xã Minh Tân" },
  { value: "31", label: "Xã Văn Lang" },
  { value: "32", label: "Xã Độc Lập" },
  { value: "33", label: "Xã Chí Hòa" },
  { value: "34", label: "Xã Minh Hòa" },
  { value: "35", label: "Xã Hồng Minh" },
];
export const thaibinh_huyendonghung = [
  { value: "1", label: "Thị trấn Đông Hưng" },
  { value: "2", label: "Xã Đô Lương" },
  { value: "3", label: "Xã Đông Phương" },
  { value: "4", label: "Xã Liên Giang" },
  { value: "5", label: "Xã An Châu" },
  { value: "6", label: "Xã Đông Sơn" },
  { value: "7", label: "Xã Đông Cường" },
  { value: "8", label: "Xã Phú Lương" },
  { value: "9", label: "Xã Mê Linh" },
  { value: "10", label: "Xã Lô Giang" },
  { value: "11", label: "Xã Đông La" },
  { value: "12", label: "Xã Minh Tân" },
  { value: "13", label: "Xã Đông Xá" },
  { value: "14", label: "Xã Chương Dương" },
  { value: "15", label: "Xã Nguyên Xá" },
  { value: "16", label: "Xã Phong Châu" },
  { value: "17", label: "Xã Hợp Tiến" },
  { value: "18", label: "Xã Hồng Việt" },
  { value: "19", label: "Xã Đông Hà" },
  { value: "20", label: "Xã Đông Giang" },
  { value: "21", label: "Xã Đông Kinh" },
  { value: "22", label: "Xã Đông Hợp" },
  { value: "23", label: "Xã Thăng Long" },
  { value: "24", label: "Xã Đông Các" },
  { value: "25", label: "Xã Phú Châu" },
  { value: "26", label: "Xã Hoa Lư" },
  { value: "27", label: "Xã Minh Châu" },
  { value: "28", label: "Xã Đông Tân" },
  { value: "29", label: "Xã Đông Vinh" },
  { value: "30", label: "Xã Đông Động" },
  { value: "31", label: "Xã Hồng Châu" },
  { value: "32", label: "Xã Bạch Đằng" },
  { value: "33", label: "Xã Trọng Quan" },
  { value: "34", label: "Xã Hoa Nam" },
  { value: "35", label: "Xã Hồng Giang" },
  { value: "36", label: "Xã Đông Phong" },
  { value: "37", label: "Xã Đông Quang" },
  { value: "38", label: "Xã Đông Xuân" },
  { value: "39", label: "Xã Đông Á" },
  { value: "40", label: "Xã Đông Lĩnh" },
  { value: "41", label: "Xã Đông Hoàng" },
  { value: "42", label: "Xã Đông Dương" },
  { value: "43", label: "Xã Đông Huy" },
  { value: "44", label: "Xã Đồng Phú" },
];
export const thaibinh_huyenthaithuy = [
  { value: "1", label: "Thị trấn Diêm Điền" },
  { value: "2", label: "Xã Thụy Tân" },
  { value: "3", label: "Xã Thụy Trường" },
  { value: "4", label: "Xã Hồng Quỳnh" },
  { value: "5", label: "Xã Thụy Dũng" },
  { value: "6", label: "Xã Thụy Hồng" },
  { value: "7", label: "Xã Thụy Quỳnh" },
  { value: "8", label: "Xã Thụy An" },
  { value: "9", label: "Xã Thụy Ninh" },
  { value: "10", label: "Xã Thụy Hưng" },
  { value: "11", label: "Xã Thụy Việt" },
  { value: "12", label: "Xã Thụy Văn" },
  { value: "13", label: "Xã Thụy Xuân" },
  { value: "14", label: "Xã Thụy Dương" },
  { value: "15", label: "Xã Thụy Trình" },
  { value: "16", label: "Xã Thụy Bình" },
  { value: "17", label: "Xã Thụy Chính" },
  { value: "18", label: "Xã Thụy Dân" },
  { value: "19", label: "Xã Thụy Hải" },
  { value: "20", label: "Xã Thụy Phúc" },
  { value: "21", label: "Xã Thụy Lương" },
  { value: "22", label: "Xã Thụy Liên" },
  { value: "23", label: "Xã Thụy Duyên" },
  { value: "24", label: "Xã Thụy Hà" },
  { value: "25", label: "Xã Thụy Thanh" },
  { value: "26", label: "Xã Thụy Sơn" },
  { value: "27", label: "Xã Thụy Phong" },
  { value: "28", label: "Xã Thái Thượng" },
  { value: "29", label: "Xã Thái Nguyên" },
  { value: "30", label: "Xã Thái Thủy" },
  { value: "31", label: "Xã Thái Dương" },
  { value: "32", label: "Xã Thái Giang" },
  { value: "33", label: "Xã Thái Hòa" },
  { value: "34", label: "Xã Thái Sơn" },
  { value: "35", label: "Xã Thái Hồng" },
  { value: "36", label: "Xã Thái An" },
  { value: "37", label: "Xã Thái Phúc" },
  { value: "38", label: "Xã Thái Hưng" },
  { value: "39", label: "Xã Thái Đô" },
  { value: "40", label: "Xã Thái Xuyên" },
  { value: "41", label: "Xã Thái Hà" },
  { value: "42", label: "Xã Mỹ Lộc" },
  { value: "43", label: "Xã Thái Tân" },
  { value: "44", label: "Xã Thái Thuần" },
  { value: "45", label: "Xã Thái Học" },
  { value: "46", label: "Xã Thái Thịnh" },
  { value: "47", label: "Xã Thái Thành" },
  { value: "48", label: "Xã Thái Thọ" },
];
export const thaibinh_huyentienhai = [
  { value: "1", label: "Thị trấn Tiền Hải" },
  { value: "2", label: "Xã Đông Hải" },
  { value: "3", label: "Xã Đông Trà" },
  { value: "4", label: "Xã Đông Long" },
  { value: "5", label: "Xã Đông Quí" },
  { value: "6", label: "Xã Vũ Lăng" },
  { value: "7", label: "Xã Đông Xuyên" },
  { value: "8", label: "Xã Tây Lương" },
  { value: "9", label: "Xã Tây Ninh" },
  { value: "10", label: "Xã Đông Trung" },
  { value: "11", label: "Xã Đông Hoàng" },
  { value: "12", label: "Xã Đông Minh" },
  { value: "13", label: "Xã Tây An" },
  { value: "14", label: "Xã Đông Phong" },
  { value: "15", label: "Xã An Ninh" },
  { value: "16", label: "Xã Tây Sơn" },
  { value: "17", label: "Xã Đông Cơ" },
  { value: "18", label: "Xã Tây Giang" },
  { value: "19", label: "Xã Đông Lâm" },
  { value: "20", label: "Xã Phương Công" },
  { value: "21", label: "Xã Tây Phong" },
  { value: "22", label: "Xã Tây Tiến" },
  { value: "23", label: "Xã Nam Cường" },
  { value: "24", label: "Xã Vân Trường" },
  { value: "25", label: "Xã Nam Thắng" },
  { value: "26", label: "Xã Nam Chính" },
  { value: "27", label: "Xã Bắc Hải" },
  { value: "28", label: "Xã Nam Thịnh" },
  { value: "29", label: "Xã Nam Hà" },
  { value: "30", label: "Xã Nam Thanh" },
  { value: "31", label: "Xã Nam Trung" },
  { value: "32", label: "Xã Nam Hồng" },
  { value: "33", label: "Xã Nam Hưng" },
  { value: "34", label: "Xã Nam Hải" },
  { value: "35", label: "Xã Nam Phú" },
];
export const thaibinh_huyenkienxuong = [
  { value: "1", label: "Thị trấn Thanh Nê" },
  { value: "2", label: "Xã Trà Giang" },
  { value: "3", label: "Xã Quốc Tuấn" },
  { value: "4", label: "Xã An Bình" },
  { value: "5", label: "Xã Vũ Tây" },
  { value: "6", label: "Xã Hồng Thái" },
  { value: "7", label: "Xã Bình Nguyên" },
  { value: "8", label: "Xã Vũ Sơn" },
  { value: "9", label: "Xã Lê Lợi" },
  { value: "10", label: "Xã Quyết Tiến" },
  { value: "11", label: "Xã Vũ Lễ" },
  { value: "12", label: "Xã Thanh Tân" },
  { value: "13", label: "Xã Thượng Hiền" },
  { value: "14", label: "Xã Nam Cao" },
  { value: "15", label: "Xã Đình Phùng" },
  { value: "16", label: "Xã Vũ Ninh" },
  { value: "17", label: "Xã Vũ An" },
  { value: "18", label: "Xã Quang Lịch" },
  { value: "19", label: "Xã Hòa Bình" },
  { value: "20", label: "Xã Bình Minh" },
  { value: "21", label: "Xã Vũ Quí" },
  { value: "22", label: "Xã Quang Bình" },
  { value: "23", label: "Xã An Bồi" },
  { value: "24", label: "Xã Vũ Trung" },
  { value: "25", label: "Xã Vũ Thắng" },
  { value: "26", label: "Xã Vũ Công" },
  { value: "27", label: "Xã Vũ Hòa" },
  { value: "28", label: "Xã Quang Minh" },
  { value: "29", label: "Xã Quang Trung" },
  { value: "30", label: "Xã Minh Hưng" },
  { value: "31", label: "Xã Quang Hưng" },
  { value: "32", label: "Xã Vũ Bình" },
  { value: "33", label: "Xã Minh Tân" },
  { value: "34", label: "Xã Nam Bình" },
  { value: "35", label: "Xã Bình Thanh" },
  { value: "36", label: "Xã Bình Định" },
  { value: "37", label: "Xã Hồng Tiến" },
];
export const thaibinh_huyenvuthu = [
  { value: "1", label: "Thị trấn Vũ Thư" },
  { value: "2", label: "Xã Hồng Lý" },
  { value: "3", label: "Xã Đồng Thanh" },
  { value: "4", label: "Xã Xuân Hòa" },
  { value: "5", label: "Xã Hiệp Hòa" },
  { value: "6", label: "Xã Phúc Thành" },
  { value: "7", label: "Xã Tân Phong" },
  { value: "8", label: "Xã Song Lãng" },
  { value: "9", label: "Xã Tân Hòa" },
  { value: "10", label: "Xã Việt Hùng" },
  { value: "11", label: "Xã Minh Lãng" },
  { value: "12", label: "Xã Minh Khai" },
  { value: "13", label: "Xã Dũng Nghĩa" },
  { value: "14", label: "Xã Minh Quang" },
  { value: "15", label: "Xã Tam Quang" },
  { value: "16", label: "Xã Tân Lập" },
  { value: "17", label: "Xã Bách Thuận" },
  { value: "18", label: "Xã Tự Tân" },
  { value: "19", label: "Xã Song An" },
  { value: "20", label: "Xã Trung An" },
  { value: "21", label: "Xã Vũ Hội" },
  { value: "22", label: "Xã Hòa Bình" },
  { value: "23", label: "Xã Nguyên Xá" },
  { value: "24", label: "Xã Việt Thuận" },
  { value: "25", label: "Xã Vũ Vinh" },
  { value: "26", label: "Xã Vũ Đoài" },
  { value: "27", label: "Xã Vũ Tiến" },
  { value: "28", label: "Xã Vũ Vân" },
  { value: "29", label: "Xã Duy Nhất" },
  { value: "30", label: "Xã Hồng Phong" },
];
export const hanam_thanhphophuly = [
  { value: "1", label: "Phường Quang Trung" },
  { value: "2", label: "Phường Lương Khánh Thiện" },
  { value: "3", label: "Phường Lê Hồng Phong" },
  { value: "4", label: "Phường Minh Khai" },
  { value: "5", label: "Phường Hai Bà Trưng" },
  { value: "6", label: "Phường Trần Hưng Đạo" },
  { value: "7", label: "Phường Lam Hà" },
  { value: "8", label: "Xã Phù Vân" },
  { value: "9", label: "Phường Liêm Chính" },
  { value: "10", label: "Xã Liêm Chung" },
  { value: "11", label: "Phường Thanh Châu" },
  { value: "12", label: "Phường Châu Sơn" },
  { value: "13", label: "Xã Tiên Tân" },
  { value: "14", label: "Xã Tiên Hiệp" },
  { value: "15", label: "Xã Tiên Hải" },
  { value: "16", label: "Xã Kim Bình" },
  { value: "17", label: "Xã Liêm Tuyền" },
  { value: "18", label: "Xã Liêm Tiết" },
  { value: "19", label: "Phường Thanh Tuyền" },
  { value: "20", label: "Xã Đinh Xá" },
  { value: "21", label: "Xã Trịnh Xá" },
];
export const hanam_huyenduytien = [
  { value: "1", label: "Thị trấn Đồng Văn" },
  { value: "2", label: "Thị trấn Hòa Mạc" },
  { value: "3", label: "Xã Mộc Bắc" },
  { value: "4", label: "Xã Châu Giang" },
  { value: "5", label: "Xã Bạch Thượng" },
  { value: "6", label: "Xã Duy Minh" },
  { value: "7", label: "Xã Mộc Nam" },
  { value: "8", label: "Xã Duy Hải" },
  { value: "9", label: "Xã Chuyên Ngoại" },
  { value: "10", label: "Xã Yên Bắc" },
  { value: "11", label: "Xã Trác Văn" },
  { value: "12", label: "Xã Tiên Nội" },
  { value: "13", label: "Xã Hoàng Đông" },
  { value: "14", label: "Xã Yên Nam" },
  { value: "15", label: "Xã Tiên Ngoại" },
  { value: "16", label: "Xã Đọi Sơn" },
  { value: "17", label: "Xã Châu Sơn" },
  { value: "18", label: "Xã Tiền Phong" },
];
export const hanam_huyenkimbang = [
  { value: "1", label: "Thị trấn Quế" },
  { value: "2", label: "Xã Nguyễn Úy" },
  { value: "3", label: "Xã Đại Cương" },
  { value: "4", label: "Xã Lê Hồ" },
  { value: "5", label: "Xã Tượng Lĩnh" },
  { value: "6", label: "Xã Nhật Tựu" },
  { value: "7", label: "Xã Nhật Tân" },
  { value: "8", label: "Xã Đồng Hóa" },
  { value: "9", label: "Xã Hoàng Tây" },
  { value: "10", label: "Xã Tân Sơn" },
  { value: "11", label: "Xã Thụy Lôi" },
  { value: "12", label: "Xã Văn Xá" },
  { value: "13", label: "Xã Khả Phong" },
  { value: "14", label: "Xã Ngọc Sơn" },
  { value: "15", label: "Thị trấn Ba Sao" },
  { value: "16", label: "Xã Liên Sơn" },
  { value: "17", label: "Xã Thi Sơn" },
  { value: "18", label: "Xã Thanh Sơn" },
];
export const hanam_huyenthanhlien = [
  { value: "1", label: "Thị trấn Kiện Khê" },
  { value: "2", label: "Xã Liêm Phong" },
  { value: "3", label: "Xã Thanh Hà" },
  { value: "4", label: "Xã Liêm Cần" },
  { value: "5", label: "Xã Thanh Bình" },
  { value: "6", label: "Xã Liêm Thuận" },
  { value: "7", label: "Xã Thanh Thủy" },
  { value: "8", label: "Xã Thanh Phong" },
  { value: "9", label: "Xã Thanh Lưu" },
  { value: "10", label: "Xã Thanh Tân" },
  { value: "11", label: "Xã Liêm Túc" },
  { value: "12", label: "Xã Liêm Sơn" },
  { value: "13", label: "Xã Thanh Hương" },
  { value: "14", label: "Xã Thanh Nghị" },
  { value: "15", label: "Xã Thanh Tâm" },
  { value: "16", label: "Xã Thanh Nguyên" },
  { value: "17", label: "Xã Thanh Hải" },
];
export const hanam_huyenbinhluc = [
  { value: "1", label: "Thị trấn Bình Mỹ" },
  { value: "2", label: "Xã Bình Nghĩa" },
  { value: "3", label: "Xã Tràng An" },
  { value: "4", label: "Xã Đồng Du" },
  { value: "5", label: "Xã Ngọc Lũ" },
  { value: "6", label: "Xã Hưng Công" },
  { value: "7", label: "Xã Đồn Xá" },
  { value: "8", label: "Xã An Ninh" },
  { value: "9", label: "Xã Bồ Đề" },
  { value: "10", label: "Xã Bối Cầu" },
  { value: "11", label: "Xã An Mỹ" },
  { value: "12", label: "Xã An Nội" },
  { value: "13", label: "Xã Vũ Bản" },
  { value: "14", label: "Xã Trung Lương" },
  { value: "15", label: "Xã Mỹ Thọ" },
  { value: "16", label: "Xã An Đổ" },
  { value: "17", label: "Xã La Sơn" },
  { value: "18", label: "Xã Tiêu Động" },
  { value: "19", label: "Xã An Lão" },
];
export const hanam_huyenlynhan = [
  { value: "1", label: "Thị trấn Vĩnh Trụ" },
  { value: "2", label: "Xã Hợp Lý" },
  { value: "3", label: "Xã Nguyên Lý" },
  { value: "4", label: "Xã Chính Lý" },
  { value: "5", label: "Xã Chân Lý" },
  { value: "6", label: "Xã Đạo Lý" },
  { value: "7", label: "Xã Công Lý" },
  { value: "8", label: "Xã Văn Lý" },
  { value: "9", label: "Xã Bắc Lý" },
  { value: "10", label: "Xã Đức Lý" },
  { value: "11", label: "Xã Nhân Đạo" },
  { value: "12", label: "Xã Đồng Lý" },
  { value: "13", label: "Xã Nhân Thịnh" },
  { value: "14", label: "Xã Nhân Hưng" },
  { value: "15", label: "Xã Nhân Khang" },
  { value: "16", label: "Xã Nhân Mỹ" },
  { value: "17", label: "Xã Nhân Nghĩa" },
  { value: "18", label: "Xã Nhân Chính" },
  { value: "19", label: "Xã Nhân Bình" },
  { value: "20", label: "Xã Phú Phúc" },
  { value: "21", label: "Xã Xuân Khê" },
  { value: "22", label: "Xã Tiến Thắng" },
  { value: "23", label: "Xã Hòa Hậu" },
];
export const namdinh_thanhphonamdinh = [
  { value: "1", label: "Phường Hạ Long" },
  { value: "2", label: "Phường Trần Tế Xương" },
  { value: "3", label: "Phường Vị Hoàng" },
  { value: "4", label: "Phường Vị Xuyên" },
  { value: "5", label: "Phường Quang Trung" },
  { value: "6", label: "Phường Cửa Bắc" },
  { value: "7", label: "Phường Nguyễn Du" },
  { value: "8", label: "Phường Bà Triệu" },
  { value: "9", label: "Phường Trường Thi" },
  { value: "10", label: "Phường Phan Đình Phùng" },
  { value: "11", label: "Phường Ngô Quyền" },
  { value: "12", label: "Phường Trần Hưng Đạo" },
  { value: "13", label: "Phường Trần Đăng Ninh" },
  { value: "14", label: "Phường Năng Tĩnh" },
  { value: "15", label: "Phường Văn Miếu" },
  { value: "16", label: "Phường Trần Quang Khải" },
  { value: "17", label: "Phường Thống Nhất" },
  { value: "18", label: "Phường Lộc Hạ" },
  { value: "19", label: "Phường Lộc Vượng" },
  { value: "20", label: "Phường Cửa Nam" },
  { value: "21", label: "Xã Lộc Hòa" },
  { value: "22", label: "Xã Nam Phong" },
  { value: "23", label: "Xã Mỹ Xá" },
  { value: "24", label: "Xã Lộc An" },
  { value: "25", label: "Xã Nam Vân" },
];
export const namdinh_huyenmyloc = [
  { value: "1", label: "Thị trấn Mỹ Lộc" },
  { value: "2", label: "Xã Mỹ Hà" },
  { value: "3", label: "Xã Mỹ Tiến" },
  { value: "4", label: "Xã Mỹ Thắng" },
  { value: "5", label: "Xã Mỹ Trung" },
  { value: "6", label: "Xã Mỹ Tân" },
  { value: "7", label: "Xã Mỹ Phúc" },
  { value: "8", label: "Xã Mỹ Hưng" },
  { value: "9", label: "Xã Mỹ Thuận" },
  { value: "10", label: "Xã Mỹ Thịnh" },
  { value: "11", label: "Xã Mỹ Thành" },
];
export const namdinh_huyenvuban = [
  { value: "1", label: "Thị trấn Gôi" },
  { value: "2", label: "Xã Minh Thuận" },
  { value: "3", label: "Xã Hiển Khánh" },
  { value: "4", label: "Xã Tân Khánh" },
  { value: "5", label: "Xã Hợp Hưng" },
  { value: "6", label: "Xã Đại An" },
  { value: "7", label: "Xã Tân Thành" },
  { value: "8", label: "Xã Cộng Hòa" },
  { value: "9", label: "Xã Trung Thành" },
  { value: "10", label: "Xã Quang Trung" },
  { value: "11", label: "Xã Minh Tân" },
  { value: "12", label: "Xã Liên Bảo" },
  { value: "13", label: "Xã Thành Lợi" },
  { value: "14", label: "Xã Kim Thái" },
  { value: "15", label: "Xã Liên Minh" },
  { value: "16", label: "Xã Đại Thắng" },
  { value: "17", label: "Xã Tam Thanh" },
  { value: "18", label: "Xã Vĩnh Hào" },
];
export const namdinh_huyenyyen = [
  { value: "1", label: "Thị trấn Lâm" },
  { value: "2", label: "Xã Yên Trung" },
  { value: "3", label: "Xã Yên Thành" },
  { value: "4", label: "Xã Yên Tân" },
  { value: "5", label: "Xã Yên Lợi" },
  { value: "6", label: "Xã Yên Thọ" },
  { value: "7", label: "Xã Yên Nghĩa" },
  { value: "8", label: "Xã Yên Minh" },
  { value: "9", label: "Xã Yên Phương" },
  { value: "10", label: "Xã Yên Chính" },
  { value: "11", label: "Xã Yên Bình" },
  { value: "12", label: "Xã Yên Phú" },
  { value: "13", label: "Xã Yên Mỹ" },
  { value: "14", label: "Xã Yên Dương" },
  { value: "15", label: "Xã Yên Xá" },
  { value: "16", label: "Xã Yên Hưng" },
  { value: "17", label: "Xã Yên Khánh" },
  { value: "18", label: "Xã Yên Phong" },
  { value: "19", label: "Xã Yên Ninh" },
  { value: "20", label: "Xã Yên Lương" },
  { value: "21", label: "Xã Yên Hồng" },
  { value: "22", label: "Xã Yên Quang" },
  { value: "23", label: "Xã Yên Tiến" },
  { value: "24", label: "Xã Yên Thắng" },
  { value: "25", label: "Xã Yên Phúc" },
  { value: "26", label: "Xã Yên Cường" },
  { value: "27", label: "Xã Yên Lộc" },
  { value: "28", label: "Xã Yên Bằng" },
  { value: "29", label: "Xã Yên Đồng" },
  { value: "30", label: "Xã Yên Khang" },
  { value: "31", label: "Xã Yên Nhân" },
  { value: "32", label: "Xã Yên Trị" },
];
export const namdinh_huyennghiahung = [
  { value: "1", label: "Thị trấn Liễu Đề" },
  { value: "2", label: "Thị trấn Rạng Đông" },
  { value: "3", label: "Xã Nghĩa Đồng" },
  { value: "4", label: "Xã Nghĩa Thịnh" },
  { value: "5", label: "Xã Nghĩa Minh" },
  { value: "6", label: "Xã Nghĩa Thái" },
  { value: "7", label: "Xã Hoàng Nam" },
  { value: "8", label: "Xã Nghĩa Châu" },
  { value: "9", label: "Xã Nghĩa Trung" },
  { value: "10", label: "Xã Nghĩa Sơn" },
  { value: "11", label: "Xã Nghĩa Lạc" },
  { value: "12", label: "Xã Nghĩa Hồng" },
  { value: "13", label: "Xã Nghĩa Phong" },
  { value: "14", label: "Xã Nghĩa Phú" },
  { value: "15", label: "Xã Nghĩa Bình" },
  { value: "16", label: "Thị trấn Quỹ Nhất" },
  { value: "17", label: "Xã Nghĩa Tân" },
  { value: "18", label: "Xã Nghĩa Hùng" },
  { value: "19", label: "Xã Nghĩa Lâm" },
  { value: "20", label: "Xã Nghĩa Thành" },
  { value: "21", label: "Xã Nghĩa Thắng" },
  { value: "22", label: "Xã Nghĩa Lợi" },
  { value: "23", label: "Xã Nghĩa Hải" },
  { value: "24", label: "Xã Nghĩa Phúc" },
  { value: "25", label: "Xã Nam Điền" },
];
export const namdinh_huyennamtruc = [
  { value: "1", label: "Thị trấn Nam Giang" },
  { value: "2", label: "Xã Nam Mỹ" },
  { value: "3", label: "Xã Điền Xá" },
  { value: "4", label: "Xã Nghĩa An" },
  { value: "5", label: "Xã Nam Thắng" },
  { value: "6", label: "Xã Nam Toàn" },
  { value: "7", label: "Xã Hồng Quang" },
  { value: "8", label: "Xã Tân Thịnh" },
  { value: "9", label: "Xã Nam Cường" },
  { value: "10", label: "Xã Nam Hồng" },
  { value: "11", label: "Xã Nam Hùng" },
  { value: "12", label: "Xã Nam Hoa" },
  { value: "13", label: "Xã Nam Dương" },
  { value: "14", label: "Xã Nam Thanh" },
  { value: "15", label: "Xã Nam Lợi" },
  { value: "16", label: "Xã Bình Minh" },
  { value: "17", label: "Xã Đồng Sơn" },
  { value: "18", label: "Xã Nam Tiến" },
  { value: "19", label: "Xã Nam Hải" },
  { value: "20", label: "Xã Nam Thái" },
];
export const namdinh_huyentrucninh = [
  { value: "1", label: "Thị trấn Cổ Lễ" },
  { value: "2", label: "Xã Phương Định" },
  { value: "3", label: "Xã Trực Chính" },
  { value: "4", label: "Xã Trung Đông" },
  { value: "5", label: "Xã Liêm Hải" },
  { value: "6", label: "Xã Trực Tuấn" },
  { value: "7", label: "Xã Việt Hùng" },
  { value: "8", label: "Xã Trực Đạo" },
  { value: "9", label: "Xã Trực Hưng" },
  { value: "10", label: "Xã Trực Nội" },
  { value: "11", label: "Thị trấn Cát Thành" },
  { value: "12", label: "Xã Trực Thanh" },
  { value: "13", label: "Xã Trực Khang" },
  { value: "14", label: "Xã Trực Thuận" },
  { value: "15", label: "Xã Trực Mỹ" },
  { value: "16", label: "Xã Trực Đại" },
  { value: "17", label: "Xã Trực Cường" },
  { value: "18", label: "Xã Trực Phú" },
  { value: "19", label: "Xã Trực Thái" },
  { value: "20", label: "Xã Trực Hùng" },
  { value: "21", label: "Xã Trực Thắng" },
];
export const namdinh_huyenxuantruong = [
  { value: "1", label: "Thị trấn Xuân Trường" },
  { value: "2", label: "Xã Xuân Châu" },
  { value: "3", label: "Xã Xuân Hồng" },
  { value: "4", label: "Xã Xuân Thành" },
  { value: "5", label: "Xã Xuân Thượng" },
  { value: "6", label: "Xã Xuân Phong" },
  { value: "7", label: "Xã Xuân Đài" },
  { value: "8", label: "Xã Xuân Tân" },
  { value: "9", label: "Xã Xuân Thủy" },
  { value: "10", label: "Xã Xuân Ngọc" },
  { value: "11", label: "Xã Xuân Bắc" },
  { value: "12", label: "Xã Xuân Phương" },
  { value: "13", label: "Xã Thọ Nghiệp" },
  { value: "14", label: "Xã Xuân Phú" },
  { value: "15", label: "Xã Xuân Trung" },
  { value: "16", label: "Xã Xuân Vinh" },
  { value: "17", label: "Xã Xuân Kiên" },
  { value: "18", label: "Xã Xuân Tiến" },
  { value: "19", label: "Xã Xuân Ninh" },
  { value: "20", label: "Xã Xuân Hòa" },
];
export const namdinh_huyengiaothuy = [
  { value: "1", label: "Thị trấn Ngô Đồng" },
  { value: "2", label: "Thị trấn Quất Lâm" },
  { value: "3", label: "Xã Giao Hương" },
  { value: "4", label: "Xã Hồng Thuận" },
  { value: "5", label: "Xã Giao Thiện" },
  { value: "6", label: "Xã Giao Thanh" },
  { value: "7", label: "Xã Hoành Sơn" },
  { value: "8", label: "Xã Bình Hòa" },
  { value: "9", label: "Xã Giao Tiến" },
  { value: "10", label: "Xã Giao Hà" },
  { value: "11", label: "Xã Giao Nhân" },
  { value: "12", label: "Xã Giao An" },
  { value: "13", label: "Xã Giao Lạc" },
  { value: "14", label: "Xã Giao Châu" },
  { value: "15", label: "Xã Giao Tân" },
  { value: "16", label: "Xã Giao Yến" },
  { value: "17", label: "Xã Giao Xuân" },
  { value: "18", label: "Xã Giao Thịnh" },
  { value: "19", label: "Xã Giao Hải" },
  { value: "20", label: "Xã Bạch Long" },
  { value: "21", label: "Xã Giao Long" },
  { value: "22", label: "Xã Giao Phong" },
];
export const namdinh_huyenhaihau = [
  { value: "1", label: "Thị trấn Yên Định" },
  { value: "2", label: "Thị trấn Cồn" },
  { value: "3", label: "Thị trấn Thịnh Long" },
  { value: "4", label: "Xã Hải Nam" },
  { value: "5", label: "Xã Hải Trung" },
  { value: "6", label: "Xã Hải Vân" },
  { value: "7", label: "Xã Hải Minh" },
  { value: "8", label: "Xã Hải Anh" },
  { value: "9", label: "Xã Hải Hưng" },
  { value: "10", label: "Xã Hải Bắc" },
  { value: "11", label: "Xã Hải Phúc" },
  { value: "12", label: "Xã Hải Thanh" },
  { value: "13", label: "Xã Hải Hà" },
  { value: "14", label: "Xã Hải Long" },
  { value: "15", label: "Xã Hải Phương" },
  { value: "16", label: "Xã Hải Đường" },
  { value: "17", label: "Xã Hải Lộc" },
  { value: "18", label: "Xã Hải Quang" },
  { value: "19", label: "Xã Hải Đông" },
  { value: "20", label: "Xã Hải Sơn" },
  { value: "21", label: "Xã Hải Tân" },
  { value: "22", label: "Xã Hải Toàn" },
  { value: "23", label: "Xã Hải Phong" },
  { value: "24", label: "Xã Hải An" },
  { value: "25", label: "Xã Hải Tây" },
  { value: "26", label: "Xã Hải Lý" },
  { value: "27", label: "Xã Hải Phú" },
  { value: "28", label: "Xã Hải Giang" },
  { value: "29", label: "Xã Hải Cường" },
  { value: "30", label: "Xã Hải Ninh" },
  { value: "31", label: "Xã Hải Chính" },
  { value: "32", label: "Xã Hải Xuân" },
  { value: "33", label: "Xã Hải Châu" },
  { value: "34", label: "Xã Hải Triều" },
  { value: "35", label: "Xã Hải Hòa" },
];
export const ninhbinh_thanhphoninhbinh = [
  { value: "1", label: "Phường Đông Thành" },
  { value: "2", label: "Phường Tân Thành" },
  { value: "3", label: "Phường Thanh Bình" },
  { value: "4", label: "Phường Vân Giang" },
  { value: "5", label: "Phường Bích Đào" },
  { value: "6", label: "Phường Phúc Thành" },
  { value: "7", label: "Phường Nam Bình" },
  { value: "8", label: "Phường Nam Thành" },
  { value: "9", label: "Phường Ninh Khánh" },
  { value: "10", label: "Xã Ninh Nhất" },
  { value: "11", label: "Xã Ninh Tiến" },
  { value: "12", label: "Xã Ninh Phúc" },
  { value: "13", label: "Phường Ninh Sơn" },
  { value: "14", label: "Phường Ninh Phong" },
];
export const ninhbinh_thanhphotamdiep = [
  { value: "1", label: "Phường Bắc Sơn" },
  { value: "2", label: "Phường Trung Sơn" },
  { value: "3", label: "Phường Nam Sơn" },
  { value: "4", label: "Phường Tây Sơn" },
  { value: "5", label: "Xã Yên Sơn" },
  { value: "6", label: "Phường Yên Bình" },
  { value: "7", label: "Phường Tân Bình" },
  { value: "8", label: "Xã Quang Sơn" },
  { value: "9", label: "Xã Đông Sơn" },
];
export const ninhbinh_huyennhoquan = [
  { value: "1", label: "Thị trấn Nho Quan" },
  { value: "2", label: "Xã Xích Thổ" },
  { value: "3", label: "Xã Gia Lâm" },
  { value: "4", label: "Xã Gia Sơn" },
  { value: "5", label: "Xã Thạch Bình" },
  { value: "6", label: "Xã Gia Thủy" },
  { value: "7", label: "Xã Gia Tường" },
  { value: "8", label: "Xã Cúc Phương" },
  { value: "9", label: "Xã Phú Sơn" },
  { value: "10", label: "Xã Đức Long" },
  { value: "11", label: "Xã Lạc Vân" },
  { value: "12", label: "Xã Đồng Phong" },
  { value: "13", label: "Xã Yên Quang" },
  { value: "14", label: "Xã Lạng Phong" },
  { value: "15", label: "Xã Thượng Hòa" },
  { value: "16", label: "Xã Văn Phong" },
  { value: "17", label: "Xã Văn Phương" },
  { value: "18", label: "Xã Thanh Lạc" },
  { value: "19", label: "Xã Sơn Lai" },
  { value: "20", label: "Xã Sơn Thành" },
  { value: "21", label: "Xã Văn Phú" },
  { value: "22", label: "Xã Phú Lộc" },
  { value: "23", label: "Xã Kỳ Phú" },
  { value: "24", label: "Xã Quỳnh Lưu" },
  { value: "25", label: "Xã Sơn Hà" },
  { value: "26", label: "Xã Phú Long" },
  { value: "27", label: "Xã Quảng Lạc" },
];
export const ninhbinh_huyengiavien = [
  { value: "1", label: "Thị trấn Me" },
  { value: "2", label: "Xã Gia Hòa" },
  { value: "3", label: "Xã Gia Hưng" },
  { value: "4", label: "Xã Liên Sơn" },
  { value: "5", label: "Xã Gia Thanh" },
  { value: "6", label: "Xã Gia Vân" },
  { value: "7", label: "Xã Gia Phú" },
  { value: "8", label: "Xã Gia Xuân" },
  { value: "9", label: "Xã Gia Lập" },
  { value: "10", label: "Xã Gia Vượng" },
  { value: "11", label: "Xã Gia Trấn" },
  { value: "12", label: "Xã Gia Thịnh" },
  { value: "13", label: "Xã Gia Phương" },
  { value: "14", label: "Xã Gia Tân" },
  { value: "15", label: "Xã Gia Thắng" },
  { value: "16", label: "Xã Gia Trung" },
  { value: "17", label: "Xã Gia Minh" },
  { value: "18", label: "Xã Gia Lạc" },
  { value: "19", label: "Xã Gia Tiến" },
  { value: "20", label: "Xã Gia Sinh" },
  { value: "21", label: "Xã Gia Phong" },
];
export const ninhbinh_huyenhoalu = [
  { value: "1", label: "Thị trấn Thiên Tôn" },
  { value: "2", label: "Xã Ninh Giang" },
  { value: "3", label: "Xã Trường Yên" },
  { value: "4", label: "Xã Ninh Khang" },
  { value: "5", label: "Xã Ninh Mỹ" },
  { value: "6", label: "Xã Ninh Hòa" },
  { value: "7", label: "Xã Ninh Xuân" },
  { value: "8", label: "Xã Ninh Hải" },
  { value: "9", label: "Xã Ninh Thắng" },
  { value: "10", label: "Xã Ninh Vân" },
  { value: "11", label: "Xã Ninh An" },
];
export const ninhbinh_huyenyenkhanh = [
  { value: "1", label: "Thị trấn Yên Ninh" },
  { value: "2", label: "Xã Khánh Tiên" },
  { value: "3", label: "Xã Khánh Phú" },
  { value: "4", label: "Xã Khánh Hòa" },
  { value: "5", label: "Xã Khánh Lợi" },
  { value: "6", label: "Xã Khánh An" },
  { value: "7", label: "Xã Khánh Cường" },
  { value: "8", label: "Xã Khánh Cư" },
  { value: "9", label: "Xã Khánh Thiện" },
  { value: "10", label: "Xã Khánh Hải" },
  { value: "11", label: "Xã Khánh Trung" },
  { value: "12", label: "Xã Khánh Mậu" },
  { value: "13", label: "Xã Khánh Vân" },
  { value: "14", label: "Xã Khánh Hội" },
  { value: "15", label: "Xã Khánh Công" },
  { value: "16", label: "Xã Khánh Thành" },
  { value: "17", label: "Xã Khánh Nhạc" },
  { value: "18", label: "Xã Khánh Thủy" },
  { value: "19", label: "Xã Khánh Hồng" },
];
export const ninhbinh_huyenkimson = [
  { value: "1", label: "Thị trấn Phát Diệm" },
  { value: "2", label: "Thị trấn Bình Minh" },
  { value: "3", label: "Xã Xuân Thiện" },
  { value: "4", label: "Xã Hồi Ninh" },
  { value: "5", label: "Xã Chính Tâm" },
  { value: "6", label: "Xã Kim Định" },
  { value: "7", label: "Xã Ân Hòa" },
  { value: "8", label: "Xã Hùng Tiến" },
  { value: "9", label: "Xã Yên Mật" },
  { value: "10", label: "Xã Quang Thiện" },
  { value: "11", label: "Xã Như Hòa" },
  { value: "12", label: "Xã Chất Bình" },
  { value: "13", label: "Xã Đồng Hướng" },
  { value: "14", label: "Xã Kim Chính" },
  { value: "15", label: "Xã Thượng Kiệm" },
  { value: "16", label: "Xã Lưu Phương" },
  { value: "17", label: "Xã Tân Thành" },
  { value: "18", label: "Xã Yên Lộc" },
  { value: "19", label: "Xã Lai Thành" },
  { value: "20", label: "Xã Định Hóa" },
  { value: "21", label: "Xã Văn Hải" },
  { value: "22", label: "Xã Kim Tân" },
  { value: "23", label: "Xã Kim Mỹ" },
  { value: "24", label: "Xã Cồn Thoi" },
  { value: "25", label: "Xã Kim Hải" },
  { value: "26", label: "Xã Kim Trung" },
  { value: "27", label: "Xã Kim Đông" },
];
export const ninhbinh_huyenyenmo = [
  { value: "1", label: "Thị trấn Yên Thịnh" },
  { value: "2", label: "Xã Khánh Thượng" },
  { value: "3", label: "Xã Khánh Dương" },
  { value: "4", label: "Xã Mai Sơn" },
  { value: "5", label: "Xã Khánh Thịnh" },
  { value: "6", label: "Xã Yên Phong" },
  { value: "7", label: "Xã Yên Hòa" },
  { value: "8", label: "Xã Yên Thắng" },
  { value: "9", label: "Xã Yên Từ" },
  { value: "10", label: "Xã Yên Hưng" },
  { value: "11", label: "Xã Yên Thành" },
  { value: "12", label: "Xã Yên Nhân" },
  { value: "13", label: "Xã Yên Mỹ" },
  { value: "14", label: "Xã Yên Mạc" },
  { value: "15", label: "Xã Yên Đồng" },
  { value: "16", label: "Xã Yên Thái" },
  { value: "17", label: "Xã Yên Lâm" },
];

export const thanhhoa_thanhphothanhhoa = [
  { value: "1", label: "Phường Hàm Rồng" },
  { value: "2", label: "Phường Đông Thọ" },
  { value: "3", label: "Phường Nam Ngạn" },
  { value: "4", label: "Phường Trường Thi" },
  { value: "5", label: "Phường Điện Biên" },
  { value: "6", label: "Phường Phú Sơn" },
  { value: "7", label: "Phường Lam Sơn" },
  { value: "8", label: "Phường Ba Đình" },
  { value: "9", label: "Phường Ngọc Trạo" },
  { value: "10", label: "Phường Đông Vệ" },
  { value: "11", label: "Phường Đông Sơn" },
  { value: "12", label: "Phường Tân Sơn" },
  { value: "13", label: "Phường Đông Cương" },
  { value: "14", label: "Phường Đông Hương" },
  { value: "15", label: "Phường Đông Hải" },
  { value: "16", label: "Phường Quảng Hưng" },
  { value: "17", label: "Phường Quảng Thắng" },
  { value: "18", label: "Phường Quảng Thành" },
  { value: "19", label: "Xã Thiệu Vân" },
  { value: "20", label: "Xã Thiệu Khánh" },
  { value: "21", label: "Xã Thiệu Dương" },
  { value: "22", label: "Phường Tào Xuyên" },
  { value: "23", label: "Xã Hoằng Lý" },
  { value: "24", label: "Xã Hoằng Long" },
  { value: "25", label: "Xã Hoằng Quang" },
  { value: "26", label: "Xã Hoằng Đại" },
  { value: "27", label: "Xã Hoằng Anh" },
  { value: "28", label: "Phường An Hoạch" },
  { value: "29", label: "Xã Đông Lĩnh" },
  { value: "30", label: "Xã Đông Vinh" },
  { value: "31", label: "Xã Đông Tân" },
  { value: "32", label: "Xã Đông Hưng" },
  { value: "33", label: "Xã Quảng Thịnh" },
  { value: "34", label: "Xã Quảng Đông" },
  { value: "35", label: "Xã Quảng Cát" },
  { value: "36", label: "Xã Quảng Phú" },
  { value: "37", label: "Xã Quảng Tâm" },
];
export const thanhhoa_xabimson = [
  { value: "1", label: "Phường Bắc Sơn" },
  { value: "2", label: "Phường Ba Đình" },
  { value: "3", label: "Phường Lam Sơn" },
  { value: "4", label: "Phường Ngọc Trạo" },
  { value: "5", label: "Phường Đông Sơn" },
  { value: "6", label: "Phường Phú Sơn" },
  { value: "7", label: "Xã Quang Trung" },
  { value: "8", label: "Xã Hà Lan" },
];
export const thanhhoa_thanhphosamson = [
  { value: "1", label: "Phường Trung Sơn" },
  { value: "2", label: "Phường Bắc Sơn" },
  { value: "3", label: "Phường Trường Sơn" },
  { value: "4", label: "Phường Quảng Cư" },
  { value: "5", label: "Phường Quảng Tiến" },
  { value: "6", label: "Xã Quảng Minh" },
  { value: "7", label: "Xã Quảng Hùng" },
  { value: "8", label: "Phường Quảng Thọ" },
  { value: "9", label: "Phường Quảng Châu" },
  { value: "10", label: "Phường Quảng Vinh" },
  { value: "11", label: "Xã Quảng Đại" },
];
export const thanhhoa_huyenmuonglat = [
  { value: "1", label: "Thị trấn Mường Lát" },
  { value: "2", label: "Xã Tam Chung" },
  { value: "3", label: "Xã Tén Tằn" },
  { value: "4", label: "Xã Mường Lý" },
  { value: "5", label: "Xã Trung Lý" },
  { value: "6", label: "Xã Quang Chiểu" },
  { value: "7", label: "Xã Pù Nhi" },
  { value: "8", label: "Xã Nhi Sơn" },
  { value: "9", label: "Xã Mường Chanh" },
];
export const thanhhoa_huyenquanhoa = [
  { value: "1", label: "Thị trấn Quan Hóa" },
  { value: "2", label: "Xã Thành Sơn" },
  { value: "3", label: "Xã Trung Sơn" },
  { value: "4", label: "Xã Phú Thanh" },
  { value: "5", label: "Xã Trung Thành" },
  { value: "6", label: "Xã Phú Lệ" },
  { value: "7", label: "Xã Phú Sơn" },
  { value: "8", label: "Xã Phú Xuân" },
  { value: "9", label: "Xã Thanh Xuân" },
  { value: "10", label: "Xã Hiền Chung" },
  { value: "11", label: "Xã Hiền Kiệt" },
  { value: "12", label: "Xã Nam Tiến" },
  { value: "13", label: "Xã Hồi Xuân" },
  { value: "14", label: "Xã Thiên Phủ" },
  { value: "15", label: "Xã Phú Nghiêm" },
  { value: "16", label: "Xã Nam Xuân" },
  { value: "17", label: "Xã Nam Động" },
  { value: "18", label: "Xã Xuân Phú" },
];
export const thanhhoa_huyenbathuoc = [
  { value: "1", label: "Thị trấn Cành Nàng" },
  { value: "2", label: "Xã Điền Thượng" },
  { value: "3", label: "Xã Điền Hạ" },
  { value: "4", label: "Xã Điền Quang" },
  { value: "5", label: "Xã Điền Trung" },
  { value: "6", label: "Xã Thành Sơn" },
  { value: "7", label: "Xã Lương Ngoại" },
  { value: "8", label: "Xã Ái Thượng" },
  { value: "9", label: "Xã Lương Nội" },
  { value: "10", label: "Xã Điền Lư" },
  { value: "11", label: "Xã Lương Trung" },
  { value: "12", label: "Xã Lũng Niêm" },
  { value: "13", label: "Xã Lũng Cao" },
  { value: "14", label: "Xã Hạ Trung" },
  { value: "15", label: "Xã Cổ Lũng" },
  { value: "16", label: "Xã Thành Lâm" },
  { value: "17", label: "Xã Ban Công" },
  { value: "18", label: "Xã Kỳ Tân" },
  { value: "19", label: "Xã Văn Nho" },
  { value: "20", label: "Xã Thiết Ống" },
  { value: "21", label: "Xã Lâm Sa" },
  { value: "22", label: "Xã Thiết Kế" },
  { value: "23", label: "Xã Tân Lập" },
];
export const thanhhoa_huyenquanson = [
  { value: "1", label: "Thị trấn Quan Sơn" },
  { value: "2", label: "Xã Trung Xuân" },
  { value: "3", label: "Xã Trung Thượng" },
  { value: "4", label: "Xã Trung Tiến" },
  { value: "5", label: "Xã Trung Hạ" },
  { value: "6", label: "Xã Sơn Hà" },
  { value: "7", label: "Xã Tam Thanh" },
  { value: "8", label: "Xã Sơn Thủy" },
  { value: "9", label: "Xã Na Mèo" },
  { value: "10", label: "Xã Sơn Lư" },
  { value: "11", label: "Xã Tam Lư" },
  { value: "12", label: "Xã Sơn Điện" },
  { value: "13", label: "Xã Mường Mìn" },
];
export const thanhhoa_huyenlangchanh = [
  { value: "1", label: "Thị trấn Lang Chánh" },
  { value: "2", label: "Xã Yên Khương" },
  { value: "3", label: "Xã Yên Thắng" },
  { value: "4", label: "Xã Trí Nang" },
  { value: "5", label: "Xã Giao An" },
  { value: "6", label: "Xã Giao Thiện" },
  { value: "7", label: "Xã Tân Phúc" },
  { value: "8", label: "Xã Tam Văn" },
  { value: "9", label: "Xã Lâm Phú" },
  { value: "10", label: "Xã Quang Hiến" },
  { value: "11", label: "Xã Đồng Lương" },
];
export const thanhhoa_huyenngoclac = [
  { value: "1", label: "Thị Trấn Ngọc Lặc" },
  { value: "2", label: "Xã Lam Sơn" },
  { value: "3", label: "Xã Mỹ Tân" },
  { value: "4", label: "Xã Thúy Sơn" },
  { value: "5", label: "Xã Thạch Lập" },
  { value: "6", label: "Xã Vân Âm" },
  { value: "7", label: "Xã Cao Ngọc" },
  { value: "8", label: "Xã Ngọc Khê" },
  { value: "9", label: "Xã Quang Trung" },
  { value: "10", label: "Xã Đồng Thịnh" },
  { value: "11", label: "Xã Ngọc Liên" },
  { value: "12", label: "Xã Ngọc Sơn" },
  { value: "13", label: "Xã Lộc Thịnh" },
  { value: "14", label: "Xã Cao Thịnh" },
  { value: "15", label: "Xã Ngọc Trung" },
  { value: "16", label: "Xã Phùng Giáo" },
  { value: "17", label: "Xã Phùng Minh" },
  { value: "18", label: "Xã Phúc Thịnh" },
  { value: "19", label: "Xã Nguyệt Ấn" },
  { value: "20", label: "Xã Kiên Thọ" },
  { value: "21", label: "Xã Minh Tiến" },
  { value: "22", label: "Xã Minh Sơn" },
];
export const thanhhoa_huyencamthuy = [
  { value: "1", label: "Thị trấn Cẩm Thủy" },
  { value: "2", label: "Xã Phúc Do" },
  { value: "3", label: "Xã Cẩm Thành" },
  { value: "4", label: "Xã Cẩm Quý" },
  { value: "5", label: "Xã Cẩm Lương" },
  { value: "6", label: "Xã Cẩm Thạch" },
  { value: "7", label: "Xã Cẩm Liên" },
  { value: "8", label: "Xã Cẩm Giang" },
  { value: "9", label: "Xã Cẩm Bình" },
  { value: "10", label: "Xã Cẩm Tú" },
  { value: "11", label: "Xã Cẩm Sơn" },
  { value: "12", label: "Xã Cẩm Châu" },
  { value: "13", label: "Xã Cẩm Tâm" },
  { value: "14", label: "Xã Cẩm Phong" },
  { value: "15", label: "Xã Cẩm Ngọc" },
  { value: "16", label: "Xã Cẩm Long" },
  { value: "17", label: "Xã Cẩm Yên" },
  { value: "18", label: "Xã Cẩm Tân" },
  { value: "19", label: "Xã Cẩm Phú" },
  { value: "20", label: "Xã Cẩm Vân" },
];
export const thanhhoa_huyenthachthanh = [
  { value: "1", label: "Thị trấn Kim Tân" },
  { value: "2", label: "Thị trấn Vân Du" },
  { value: "3", label: "Xã Thạch Tân" },
  { value: "4", label: "Xã Thạch Lâm" },
  { value: "5", label: "Xã Thạch Quảng" },
  { value: "6", label: "Xã Thạch Tượng" },
  { value: "7", label: "Xã Thạch Cẩm" },
  { value: "8", label: "Xã Thạch Sơn" },
  { value: "9", label: "Xã Thạch Bình" },
  { value: "10", label: "Xã Thạch Định" },
  { value: "11", label: "Xã Thạch Đồng" },
  { value: "12", label: "Xã Thạch Long" },
  { value: "13", label: "Xã Thành Mỹ" },
  { value: "14", label: "Xã Thành Yên" },
  { value: "15", label: "Xã Thành Vinh" },
  { value: "16", label: "Xã Thành Minh" },
  { value: "17", label: "Xã Thành Công" },
  { value: "18", label: "Xã Thành Tân" },
  { value: "19", label: "Xã Thành Trực" },
  { value: "20", label: "Xã Thành Vân" },
  { value: "21", label: "Xã Thành Tâm" },
  { value: "22", label: "Xã Thành An" },
  { value: "23", label: "Xã Thành Thọ" },
  { value: "24", label: "Xã Thành Tiến" },
  { value: "25", label: "Xã Thành Long" },
  { value: "26", label: "Xã Thành Kim" },
  { value: "27", label: "Xã Thành Hưng" },
  { value: "28", label: "Xã Ngọc Trạo" },
];
export const thanhhoa_huyenhatrung = [
  { value: "1", label: "Thị trấn Hà Trung" },
  { value: "2", label: "Xã Hà Long" },
  { value: "3", label: "Xã Hà Vinh" },
  { value: "4", label: "Xã Hà Bắc" },
  { value: "5", label: "Xã Hà Vân" },
  { value: "6", label: "Xã Hà Yên" },
  { value: "7", label: "Xã Hà Thanh" },
  { value: "8", label: "Xã Hà Giang" },
  { value: "9", label: "Xã Hà Dương" },
  { value: "10", label: "Xã Hà Phú" },
  { value: "11", label: "Xã Hà Phong" },
  { value: "12", label: "Xã Hà Ngọc" },
  { value: "13", label: "Xã Hà Ninh" },
  { value: "14", label: "Xã Hà Lâm" },
  { value: "15", label: "Xã Hà Sơn" },
  { value: "16", label: "Xã Hà Lĩnh" },
  { value: "17", label: "Xã Hà Đông" },
  { value: "18", label: "Xã Hà Tân" },
  { value: "19", label: "Xã Hà Tiến" },
  { value: "20", label: "Xã Hà Bình" },
  { value: "21", label: "Xã Hà Lai" },
  { value: "22", label: "Xã Hà Châu" },
  { value: "23", label: "Xã Hà Toại" },
  { value: "24", label: "Xã Hà Thái" },
  { value: "25", label: "Xã Hà Hải" },
];
export const thanhhoa_huyenvinhloc = [
  { value: "1", label: "Thị trấn Vĩnh Lộc" },
  { value: "2", label: "Xã Vĩnh Thành" },
  { value: "3", label: "Xã Vĩnh Quang" },
  { value: "4", label: "Xã Vĩnh Yên" },
  { value: "5", label: "Xã Vĩnh Tiến" },
  { value: "6", label: "Xã Vĩnh Long" },
  { value: "7", label: "Xã Vĩnh Phúc" },
  { value: "8", label: "Xã Vĩnh Hưng" },
  { value: "9", label: "Xã Vĩnh Minh" },
  { value: "10", label: "Xã Vĩnh Khang" },
  { value: "11", label: "Xã Vĩnh Hòa" },
  { value: "12", label: "Xã Vĩnh Hùng" },
  { value: "13", label: "Xã Vĩnh Tân" },
  { value: "14", label: "Xã Vĩnh Ninh" },
  { value: "15", label: "Xã Vĩnh Thịnh" },
  { value: "16", label: "Xã Vĩnh An" },
];
export const thanhhoa_huyenyendinh = [
  { value: "1", label: "Thị trấn Quán Lào" },
  { value: "2", label: "Thị trấn Thống Nhất" },
  { value: "3", label: "Xã Yên Phú" },
  { value: "4", label: "Xã Yên Lâm" },
  { value: "5", label: "Xã Yên Tâm" },
  { value: "6", label: "Xã Yên Giang" },
  { value: "7", label: "Xã Quí Lộc" },
  { value: "8", label: "Xã Yên Thọ" },
  { value: "9", label: "Xã Yên Trung" },
  { value: "10", label: "Xã Yên Trường" },
  { value: "11", label: "Xã Yên Bái" },
  { value: "12", label: "Xã Yên Phong" },
  { value: "13", label: "Xã Yên Thái" },
  { value: "14", label: "Xã Yên Hùng" },
  { value: "15", label: "Xã Yên Thịnh" },
  { value: "16", label: "Xã Yên Ninh" },
  { value: "17", label: "Xã Yên Lạc" },
  { value: "18", label: "Xã Định Tăng" },
  { value: "19", label: "Xã Định Hòa" },
  { value: "20", label: "Xã Định Thành" },
  { value: "21", label: "Xã Định Công" },
  { value: "22", label: "Xã Định Tân" },
  { value: "23", label: "Xã Định Tiến" },
  { value: "24", label: "Xã Định Long" },
  { value: "25", label: "Xã Định Liên" },
  { value: "26", label: "Xã Định Tường" },
  { value: "27", label: "Xã Định Hưng" },
  { value: "28", label: "Xã Định Hải" },
  { value: "29", label: "Xã Định Bình" },
];
export const thanhhoa_huyenthoxuan = [
  { value: "1", label: "Thị trấn Thọ Xuân" },
  { value: "2", label: "Thị trấn Lam Sơn" },
  { value: "3", label: "Thị trấn Sao Vàng" },
  { value: "4", label: "Xã Xuân Khánh" },
  { value: "5", label: "Xã Thọ Nguyên" },
  { value: "6", label: "Xã Xuân Thành" },
  { value: "7", label: "Xã Hạnh Phúc" },
  { value: "8", label: "Xã Bắc Lương" },
  { value: "9", label: "Xã Nam Giang" },
  { value: "10", label: "Xã Xuân Phong" },
  { value: "11", label: "Xã Thọ Lộc" },
  { value: "12", label: "Xã Xuân Trường" },
  { value: "13", label: "Xã Xuân Hòa" },
  { value: "14", label: "Xã Thọ Hải" },
  { value: "15", label: "Xã Tây Hồ" },
  { value: "16", label: "Xã Xuân Giang" },
  { value: "17", label: "Xã Xuân Quang" },
  { value: "18", label: "Xã Xuân Sơn" },
  { value: "19", label: "Xã Xuân Hưng" },
  { value: "20", label: "Xã Thọ Diên" },
  { value: "21", label: "Xã Thọ Lâm" },
  { value: "22", label: "Xã Thọ Xương" },
  { value: "23", label: "Xã Xuân Bái" },
  { value: "24", label: "Xã Xuân Phú" },
  { value: "25", label: "Xã Xuân Thắng" },
  { value: "26", label: "Xã Xuân Lam" },
  { value: "27", label: "Xã Xuân Thiên" },
  { value: "28", label: "Xã Thọ Minh" },
  { value: "29", label: "Xã Xuân Châu" },
  { value: "30", label: "Xã Thọ Lập" },
  { value: "31", label: "Xã Quảng Phú" },
  { value: "32", label: "Xã Xuân Tín" },
  { value: "33", label: "Xã Phú Yên" },
  { value: "34", label: "Xã Xuân Yên" },
  { value: "35", label: "Xã Xuân Lai" },
  { value: "36", label: "Xã Xuân Lập" },
  { value: "37", label: "Xã Thọ Thắng" },
  { value: "38", label: "Xã Xuân Minh" },
  { value: "39", label: "Xã Xuân Tân" },
  { value: "40", label: "Xã Xuân Vinh" },
  { value: "41", label: "Xã Thọ Trường" },
];
export const thanhhoa_huyenthuongxuan = [
  { value: "1", label: "Thị trấn Thường Xuân" },
  { value: "2", label: "Xã Bát Mọt" },
  { value: "3", label: "Xã Yên Nhân" },
  { value: "4", label: "Xã Xuân Lẹ" },
  { value: "5", label: "Xã Vạn Xuân" },
  { value: "6", label: "Xã Lương Sơn" },
  { value: "7", label: "Xã Xuân Cao" },
  { value: "8", label: "Xã Luận Thành" },
  { value: "9", label: "Xã Luận Khê" },
  { value: "10", label: "Xã Xuân Thắng" },
  { value: "11", label: "Xã Xuân Lộc" },
  { value: "12", label: "Xã Xuân Cẩm" },
  { value: "13", label: "Xã Xuân Dương" },
  { value: "14", label: "Xã Thọ Thanh" },
  { value: "15", label: "Xã Ngọc Phụng" },
  { value: "16", label: "Xã Xuân Chinh" },
  { value: "17", label: "Xã Tân Thành" },
];
export const thanhhoa_huyentrieuson = [
  { value: "1", label: "Thị trấn Triệu Sơn" },
  { value: "2", label: "Xã Thọ Sơn" },
  { value: "3", label: "Xã Thọ Bình" },
  { value: "4", label: "Xã Thọ Tiến" },
  { value: "5", label: "Xã Hợp Lý" },
  { value: "6", label: "Xã Hợp Tiến" },
  { value: "7", label: "Xã Hợp Thành" },
  { value: "8", label: "Xã Triệu Thành" },
  { value: "9", label: "Xã Hợp Thắng" },
  { value: "10", label: "Xã Minh Sơn" },
  { value: "11", label: "Xã Minh Dân" },
  { value: "12", label: "Xã Minh Châu" },
  { value: "13", label: "Xã Dân Lực" },
  { value: "14", label: "Xã Dân Lý" },
  { value: "15", label: "Xã Dân Quyền" },
  { value: "16", label: "Xã An Nông" },
  { value: "17", label: "Xã Văn Sơn" },
  { value: "18", label: "Xã Thái Hòa" },
  { value: "19", label: "Xã Tân Ninh" },
  { value: "20", label: "Xã Đồng Lợi" },
  { value: "21", label: "Xã Đồng Tiến" },
  { value: "22", label: "Xã Đồng Thắng" },
  { value: "23", label: "Xã Tiến Nông" },
  { value: "24", label: "Xã Khuyến Nông" },
  { value: "25", label: "Xã Xuân Thịnh" },
  { value: "26", label: "Xã Xuân Lộc" },
  { value: "27", label: "Xã Thọ Dân" },
  { value: "28", label: "Xã Xuân Thọ" },
  { value: "29", label: "Xã Thọ Tân" },
  { value: "30", label: "Xã Thọ Ngọc" },
  { value: "31", label: "Xã Thọ Cường" },
  { value: "32", label: "Xã Thọ Phú" },
  { value: "33", label: "Xã Thọ Vực" },
  { value: "34", label: "Xã Thọ Thế" },
  { value: "35", label: "Xã Nông Trường" },
  { value: "36", label: "Xã Bình Sơn" },
];
export const thanhhoa_huyenthieuhoa = [
  { value: "1", label: "Thị trấn Vạn Hà" },
  { value: "2", label: "Xã Thiệu Ngọc" },
  { value: "3", label: "Xã Thiệu Vũ" },
  { value: "4", label: "Xã Thiệu Phúc" },
  { value: "5", label: "Xã Thiệu Tiến" },
  { value: "6", label: "Xã Thiệu Công" },
  { value: "7", label: "Xã Thiệu Phú" },
  { value: "8", label: "Xã Thiệu Long" },
  { value: "9", label: "Xã Thiệu Giang" },
  { value: "10", label: "Xã Thiệu Duy" },
  { value: "11", label: "Xã Thiệu Nguyên" },
  { value: "12", label: "Xã Thiệu Hợp" },
  { value: "13", label: "Xã Thiệu Thịnh" },
  { value: "14", label: "Xã Thiệu Quang" },
  { value: "15", label: "Xã Thiệu Thành" },
  { value: "16", label: "Xã Thiệu Toán" },
  { value: "17", label: "Xã Thiệu Chính" },
  { value: "18", label: "Xã Thiệu Hòa" },
  { value: "19", label: "Xã Thiệu Minh" },
  { value: "20", label: "Xã Thiệu Tâm" },
  { value: "21", label: "Xã Thiệu Viên" },
  { value: "22", label: "Xã Thiệu Lý" },
  { value: "23", label: "Xã Thiệu Vận" },
  { value: "24", label: "Xã Thiệu Trung" },
  { value: "25", label: "Xã Thiệu Đô" },
  { value: "26", label: "Xã Thiệu Châu" },
  { value: "27", label: "Xã Thiệu Giao" },
  { value: "28", label: "Xã Thiệu Tân" },
];
export const thanhhoa_huyenhoanghoa = [
  { value: "1", label: "Thị trấn Bút Sơn" },
  { value: "2", label: "Xã Hoằng Giang" },
  { value: "3", label: "Xã Hoằng Xuân" },
  { value: "4", label: "Xã Hoằng Khánh" },
  { value: "5", label: "Xã Hoằng Phượng" },
  { value: "6", label: "Xã Hoằng Phú" },
  { value: "7", label: "Xã Hoằng Quỳ" },
  { value: "8", label: "Xã Hoằng Kim" },
  { value: "9", label: "Xã Hoằng Trung" },
  { value: "10", label: "Xã Hoằng Trinh" },
  { value: "11", label: "Xã Hoằng Sơn" },
  { value: "12", label: "Xã Hoằng Lương" },
  { value: "13", label: "Xã Hoằng Xuyên" },
  { value: "14", label: "Xã Hoằng Cát" },
  { value: "15", label: "Xã Hoằng Khê" },
  { value: "16", label: "Xã Hoằng Quý" },
  { value: "17", label: "Xã Hoằng Hợp" },
  { value: "18", label: "Xã Hoằng Minh" },
  { value: "19", label: "Xã Hoằng Phúc" },
  { value: "20", label: "Xã Hoằng Đức" },
  { value: "21", label: "Xã Hoằng Hà" },
  { value: "22", label: "Xã Hoằng Đạt" },
  { value: "23", label: "Xã Hoằng Vinh" },
  { value: "24", label: "Xã Hoằng Đạo" },
  { value: "25", label: "Xã Hoằng Thắng" },
  { value: "26", label: "Xã Hoằng Đồng" },
  { value: "27", label: "Xã Hoằng Thái" },
  { value: "28", label: "Xã Hoằng Thịnh" },
  { value: "29", label: "Xã Hoằng Thành" },
  { value: "30", label: "Xã Hoằng Lộc" },
  { value: "31", label: "Xã Hoằng Trạch" },
  { value: "32", label: "Xã Hoằng Phong" },
  { value: "33", label: "Xã Hoằng Lưu" },
  { value: "34", label: "Xã Hoằng Châu" },
  { value: "35", label: "Xã Hoằng Tân" },
  { value: "36", label: "Xã Hoằng Yến" },
  { value: "37", label: "Xã Hoằng Tiến" },
  { value: "38", label: "Xã Hoằng Hải" },
  { value: "39", label: "Xã Hoằng Ngọc" },
  { value: "40", label: "Xã Hoằng Đông" },
  { value: "41", label: "Xã Hoằng Thanh" },
  { value: "42", label: "Xã Hoằng Phụ" },
  { value: "43", label: "Xã Hoằng Trường" },
];
export const thanhhoa_huyenhauloc = [
  { value: "1", label: "Thị trấn Hậu Lộc" },
  { value: "2", label: "Xã Đồng Lộc" },
  { value: "3", label: "Xã Đại Lộc" },
  { value: "4", label: "Xã Triệu Lộc" },
  { value: "5", label: "Xã Châu Lộc" },
  { value: "6", label: "Xã Tiến Lộc" },
  { value: "7", label: "Xã Lộc Sơn" },
  { value: "8", label: "Xã Cầu Lộc" },
  { value: "9", label: "Xã Thành Lộc" },
  { value: "10", label: "Xã Tuy Lộc" },
  { value: "11", label: "Xã Phong Lộc" },
  { value: "12", label: "Xã Mỹ Lộc" },
  { value: "13", label: "Xã Văn Lộc" },
  { value: "14", label: "Xã Thuần Lộc" },
  { value: "15", label: "Xã Lộc Tân" },
  { value: "16", label: "Xã Xuân Lộc" },
  { value: "17", label: "Xã Thịnh Lộc" },
  { value: "18", label: "Xã Hoa Lộc" },
  { value: "19", label: "Xã Liên Lộc" },
  { value: "20", label: "Xã Quang Lộc" },
  { value: "21", label: "Xã Phú Lộc" },
  { value: "22", label: "Xã Hòa Lộc" },
  { value: "23", label: "Xã Minh Lộc" },
  { value: "24", label: "Xã Hưng Lộc" },
  { value: "25", label: "Xã Hải Lộc" },
  { value: "26", label: "Xã Đa Lộc" },
  { value: "27", label: "Xã Ngư Lộc" },
];
export const thanhhoa_huyenngason = [
  { value: "1", label: "Thị trấn Nga Sơn" },
  { value: "2", label: "Xã Ba Đình" },
  { value: "3", label: "Xã Nga Vịnh" },
  { value: "4", label: "Xã Nga Văn" },
  { value: "5", label: "Xã Nga Thiện" },
  { value: "6", label: "Xã Nga Tiến" },
  { value: "7", label: "Xã Nga Lĩnh" },
  { value: "8", label: "Xã Nga Nhân" },
  { value: "9", label: "Xã Nga Trung" },
  { value: "10", label: "Xã Nga Bạch" },
  { value: "11", label: "Xã Nga Thanh" },
  { value: "12", label: "Xã Nga Hưng" },
  { value: "13", label: "Xã Nga Mỹ" },
  { value: "14", label: "Xã Nga Yên" },
  { value: "15", label: "Xã Nga Giáp" },
  { value: "16", label: "Xã Nga Hải" },
  { value: "17", label: "Xã Nga Thành" },
  { value: "18", label: "Xã Nga An" },
  { value: "19", label: "Xã Nga Phú" },
  { value: "20", label: "Xã Nga Điền" },
  { value: "21", label: "Xã Nga Tân" },
  { value: "22", label: "Xã Nga Thủy" },
  { value: "23", label: "Xã Nga Liên" },
  { value: "24", label: "Xã Nga Thái" },
  { value: "25", label: "Xã Nga Thạch" },
  { value: "26", label: "Xã Nga Thắng" },
  { value: "27", label: "Xã Nga Trường" },
];
export const thanhhoa_huyennhuxuan = [
  { value: "1", label: "Thị trấn Yên Cát" },
  { value: "2", label: "Xã Bãi Trành" },
  { value: "3", label: "Xã Xuân Hòa" },
  { value: "4", label: "Xã Xuân Bình" },
  { value: "5", label: "Xã Hóa Quỳ" },
  { value: "6", label: "Xã Xuân Quỳ" },
  { value: "7", label: "Xã Yên Lễ" },
  { value: "8", label: "Xã Cát Vân" },
  { value: "9", label: "Xã Cát Tân" },
  { value: "10", label: "Xã Tân Bình" },
  { value: "11", label: "Xã Bình Lương" },
  { value: "12", label: "Xã Thanh Quân" },
  { value: "13", label: "Xã Thanh Xuân" },
  { value: "14", label: "Xã Thanh Hòa" },
  { value: "15", label: "Xã Thanh Phong" },
  { value: "16", label: "Xã Thanh Lâm" },
  { value: "17", label: "Xã Thanh Sơn" },
  { value: "18", label: "Xã Thượng Ninh" },
];
export const thanhhoa_huyennhuthanh = [
  { value: "1", label: "Thị trấn Bến Sung" },
  { value: "2", label: "Xã Cán Khê" },
  { value: "3", label: "Xã Xuân Du" },
  { value: "4", label: "Xã Xuân Thọ" },
  { value: "5", label: "Xã Phượng Nghi" },
  { value: "6", label: "Xã Mậu Lâm" },
  { value: "7", label: "Xã Xuân Khang" },
  { value: "8", label: "Xã Phú Nhuận" },
  { value: "9", label: "Xã Hải Long" },
  { value: "10", label: "Xã Hải Vân" },
  { value: "11", label: "Xã Xuân Thái" },
  { value: "12", label: "Xã Xuân Phúc" },
  { value: "13", label: "Xã Yên Thọ" },
  { value: "14", label: "Xã Yên Lạc" },
  { value: "15", label: "Xã Phúc Đường" },
  { value: "16", label: "Xã Thanh Tân" },
  { value: "17", label: "Xã Thanh Kỳ" },
];
export const thanhhoa_huyennongcong = [
  { value: "1", label: "Thị trấn Nông Cống" },
  { value: "2", label: "Xã Tân Phúc" },
  { value: "3", label: "Xã Tân Thọ" },
  { value: "4", label: "Xã Hoàng Sơn" },
  { value: "5", label: "Xã Tân Khang" },
  { value: "6", label: "Xã Hoàng Giang" },
  { value: "7", label: "Xã Trung Chính" },
  { value: "8", label: "Xã Trung Ý" },
  { value: "9", label: "Xã Trung Thành" },
  { value: "10", label: "Xã Tế Tân" },
  { value: "11", label: "Xã Tế Thắng" },
  { value: "12", label: "Xã Tế Lợi" },
  { value: "13", label: "Xã Tế Nông" },
  { value: "14", label: "Xã Minh Nghĩa" },
  { value: "15", label: "Xã Minh Khôi" },
  { value: "16", label: "Xã Vạn Hòa" },
  { value: "17", label: "Xã Trường Trung" },
  { value: "18", label: "Xã Vạn Thắng" },
  { value: "19", label: "Xã Trường Giang" },
  { value: "20", label: "Xã Vạn Thiện" },
  { value: "21", label: "Xã Thăng Long" },
  { value: "22", label: "Xã Trường Minh" },
  { value: "23", label: "Xã Trường Sơn" },
  { value: "24", label: "Xã Thăng Bình" },
  { value: "25", label: "Xã Công Liêm" },
  { value: "26", label: "Xã Tượng Văn" },
  { value: "27", label: "Xã Thăng Thọ" },
  { value: "28", label: "Xã Tượng Lĩnh" },
  { value: "29", label: "Xã Tượng Sơn" },
  { value: "30", label: "Xã Công Chính" },
  { value: "31", label: "Xã Công Bình" },
  { value: "32", label: "Xã Yên Mỹ" },
];
export const thanhhoa_huyendongson = [
  { value: "1", label: "Thị trấn Rừng Thông" },
  { value: "2", label: "Xã Đông Hoàng" },
  { value: "3", label: "Xã Đông Ninh" },
  { value: "4", label: "Xã Đông Khê" },
  { value: "5", label: "Xã Đông Hòa" },
  { value: "6", label: "Xã Đông Yên" },
  { value: "7", label: "Xã Đông Minh" },
  { value: "8", label: "Xã Đông Thanh" },
  { value: "9", label: "Xã Đông Tiến" },
  { value: "10", label: "Xã Đông Anh" },
  { value: "11", label: "Xã Đông Thịnh" },
  { value: "12", label: "Xã Đông Văn" },
  { value: "13", label: "Xã Đông Phú" },
  { value: "14", label: "Xã Đông Nam" },
  { value: "15", label: "Xã Đông Quang" },
];
export const thanhhoa_huyenquangxuong = [
  { value: "1", label: "Thị trấn Quảng Xương" },
  { value: "2", label: "Xã Quảng Tân" },
  { value: "3", label: "Xã Quảng Trạch" },
  { value: "4", label: "Xã Quảng Phong" },
  { value: "5", label: "Xã Quảng Đức" },
  { value: "6", label: "Xã Quảng Định" },
  { value: "7", label: "Xã Quảng Nhân" },
  { value: "8", label: "Xã Quảng Ninh" },
  { value: "9", label: "Xã Quảng Bình" },
  { value: "10", label: "Xã Quảng Hợp" },
  { value: "11", label: "Xã Quảng Văn" },
  { value: "12", label: "Xã Quảng Long" },
  { value: "13", label: "Xã Quảng Yên" },
  { value: "14", label: "Xã Quảng Hòa" },
  { value: "15", label: "Xã Quảng Lĩnh" },
  { value: "16", label: "Xã Quảng Khê" },
  { value: "17", label: "Xã Quảng Trung" },
  { value: "18", label: "Xã Quảng Chính" },
  { value: "19", label: "Xã Quảng Ngọc" },
  { value: "20", label: "Xã Quảng Trường" },
  { value: "21", label: "Xã Quảng Phúc" },
  { value: "22", label: "Xã Quảng Vọng" },
  { value: "23", label: "Xã Quảng Giao" },
  { value: "24", label: "Xã Quảng Hải" },
  { value: "25", label: "Xã Quảng Lưu" },
  { value: "26", label: "Xã Quảng Lộc" },
  { value: "27", label: "Xã Quảng Lợi" },
  { value: "28", label: "Xã Quảng Nham" },
  { value: "29", label: "Xã Quảng Thạch" },
  { value: "30", label: "Xã Quảng Thái" },
];
export const thanhhoa_huyentinhgia = [
  { value: "1", label: "Thị trấn Tĩnh Gia" },
  { value: "2", label: "Xã Hải Châu" },
  { value: "3", label: "Xã Thanh Thủy" },
  { value: "4", label: "Xã Thanh Sơn" },
  { value: "5", label: "Xã Triêu Dương" },
  { value: "6", label: "Xã Hải Ninh" },
  { value: "7", label: "Xã Anh Sơn" },
  { value: "8", label: "Xã Ngọc Lĩnh" },
  { value: "9", label: "Xã Hải An" },
  { value: "10", label: "Xã Hùng Sơn" },
  { value: "11", label: "Xã Các Sơn" },
  { value: "12", label: "Xã Tân Dân" },
  { value: "13", label: "Xã Hải Lĩnh" },
  { value: "14", label: "Xã Định Hải" },
  { value: "15", label: "Xã Phú Sơn" },
  { value: "16", label: "Xã Ninh Hải" },
  { value: "17", label: "Xã Nguyên Bình" },
  { value: "18", label: "Xã Hải Nhân" },
  { value: "19", label: "Xã Hải Hòa" },
  { value: "20", label: "Xã Bình Minh" },
  { value: "21", label: "Xã Hải Thanh" },
  { value: "22", label: "Xã Phú Lâm" },
  { value: "23", label: "Xã Xuân Lâm" },
  { value: "24", label: "Xã Trúc Lâm" },
  { value: "25", label: "Xã Hải Bình" },
  { value: "26", label: "Xã Tân Trường" },
  { value: "27", label: "Xã Tùng Lâm" },
  { value: "28", label: "Xã Tĩnh Hải" },
  { value: "29", label: "Xã Mai Lâm" },
  { value: "30", label: "Xã Trường Lâm" },
  { value: "31", label: "Xã Hải Yến" },
  { value: "32", label: "Xã Hải Thượng" },
  { value: "33", label: "Xã Nghi Sơn" },
  { value: "34", label: "Xã Hải Hà" },
];
export const nghean_thanhphovinh = [
  { value: "1", label: "Phường Đông Vĩnh" },
  { value: "2", label: "Phường Hà Huy Tập" },
  { value: "3", label: "Phường Lê Lợi" },
  { value: "4", label: "Phường Quán Bàu" },
  { value: "5", label: "Phường Hưng Bình" },
  { value: "6", label: "Phường Hưng Phúc" },
  { value: "7", label: "Phường Hưng Dũng" },
  { value: "8", label: "Phường Cửa Nam" },
  { value: "9", label: "Phường Quang Trung" },
  { value: "10", label: "Phường Đội Cung" },
  { value: "11", label: "Phường Lê Mao" },
  { value: "12", label: "Phường Trường Thi" },
  { value: "13", label: "Phường Bến Thủy" },
  { value: "14", label: "Phường Hồng Sơn" },
  { value: "15", label: "Phường Trung Đô" },
  { value: "16", label: "Xã Nghi Phú" },
  { value: "17", label: "Xã Hưng Đông" },
  { value: "18", label: "Xã Hưng Lộc" },
  { value: "19", label: "Xã Hưng Hòa" },
  { value: "20", label: "Phường Vinh Tân" },
  { value: "21", label: "Xã Nghi Liên" },
  { value: "22", label: "Xã Nghi Ân" },
  { value: "23", label: "Xã Nghi Kim" },
  { value: "24", label: "Xã Nghi Đức" },
  { value: "25", label: "Xã Hưng Chính" },
];
export const nghean_xacualo = [
  { value: "1", label: "Phường Nghi Thuỷ" },
  { value: "2", label: "Phường Nghi Tân" },
  { value: "3", label: "Phường Thu Thuỷ" },
  { value: "4", label: "Phường Nghi Hòa" },
  { value: "5", label: "Phường Nghi Hải" },
  { value: "6", label: "Phường Nghi Hương" },
  { value: "7", label: "Phường Nghi Thu" },
];
export const nghean_xathaihoa = [
  { value: "1", label: "Phường Hòa Hiếu" },
  { value: "2", label: "Phường Quang Phong" },
  { value: "3", label: "Phường Quang Tiến" },
  { value: "4", label: "Xã Nghĩa Hòa" },
  { value: "5", label: "Phường Long Sơn" },
  { value: "6", label: "Xã Nghĩa Tiến" },
  { value: "7", label: "Xã Nghĩa Mỹ" },
  { value: "8", label: "Xã Tây Hiếu" },
  { value: "9", label: "Xã Nghĩa Thuận" },
  { value: "10", label: "Xã Đông Hiếu" },
];
export const nghean_huyenquephong = [
  { value: "1", label: "Thị trấn Kim Sơn" },
  { value: "2", label: "Xã Thông Thụ" },
  { value: "3", label: "Xã Đồng Văn" },
  { value: "4", label: "Xã Hạnh Dịch" },
  { value: "5", label: "Xã Tiền Phong" },
  { value: "6", label: "Xã Nậm Giải" },
  { value: "7", label: "Xã Tri Lễ" },
  { value: "8", label: "Xã Châu Kim" },
  { value: "9", label: "Xã Mường Nọc" },
  { value: "10", label: "Xã Quế Sơn" },
  { value: "11", label: "Xã Châu Thôn" },
  { value: "12", label: "Xã Nậm Nhoóng" },
  { value: "13", label: "Xã Quang Phong" },
  { value: "14", label: "Xã Căm Muộn" },
];
export const nghean_huyenquychau = [
  { value: "1", label: "Thị trấn Tân Lạc" },
  { value: "2", label: "Xã Châu Bính" },
  { value: "3", label: "Xã Châu Thuận" },
  { value: "4", label: "Xã Châu Hội" },
  { value: "5", label: "Xã Châu Nga" },
  { value: "6", label: "Xã Châu Tiến" },
  { value: "7", label: "Xã Châu Hạnh" },
  { value: "8", label: "Xã Châu Thắng" },
  { value: "9", label: "Xã Châu Phong" },
  { value: "10", label: "Xã Châu Bình" },
  { value: "11", label: "Xã Châu Hoàn" },
  { value: "12", label: "Xã Diên Lãm" },
];
export const nghean_huyenkyson = [
  { value: "1", label: "Thị trấn Mường Xén" },
  { value: "2", label: "Xã Mỹ Lý" },
  { value: "3", label: "Xã Bắc Lý" },
  { value: "4", label: "Xã Keng Đu" },
  { value: "5", label: "Xã Đoọc Mạy" },
  { value: "6", label: "Xã Huồi Tụ" },
  { value: "7", label: "Xã Mường Lống" },
  { value: "8", label: "Xã Na Loi" },
  { value: "9", label: "Xã Nậm Cắn" },
  { value: "10", label: "Xã Bảo Nam" },
  { value: "11", label: "Xã Phà Đánh" },
  { value: "12", label: "Xã Bảo Thắng" },
  { value: "13", label: "Xã Hữu Lập" },
  { value: "14", label: "Xã Tà Cạ" },
  { value: "15", label: "Xã Chiêu Lưu" },
  { value: "16", label: "Xã Mường Típ" },
  { value: "17", label: "Xã Hữu Kiệm" },
  { value: "18", label: "Xã Tây Sơn" },
  { value: "19", label: "Xã Mường Ải" },
  { value: "20", label: "Xã Na Ngoi" },
  { value: "21", label: "Xã Nậm Càn" },
];
export const nghean_huyentuongduong = [
  { value: "1", label: "Thị trấn Hòa Bình" },
  { value: "2", label: "Xã Mai Sơn" },
  { value: "3", label: "Xã Nhôn Mai" },
  { value: "4", label: "Xã Hữu Khuông" },
  { value: "5", label: "Xã Yên Tĩnh" },
  { value: "6", label: "Xã Nga My" },
  { value: "7", label: "Xã Xiêng My" },
  { value: "8", label: "Xã Lưỡng Minh" },
  { value: "9", label: "Xã Yên Hòa" },
  { value: "10", label: "Xã Yên Na" },
  { value: "11", label: "Xã Lưu Kiền" },
  { value: "12", label: "Xã Thạch Giám" },
  { value: "13", label: "Xã Xá Lượng" },
  { value: "14", label: "Xã Tam Thái" },
  { value: "15", label: "Xã Tam Đình" },
  { value: "16", label: "Xã Yên Thắng" },
  { value: "17", label: "Xã Tam Quang" },
  { value: "18", label: "Xã Tam Hợp" },
];
export const nghean_huyennghiadan = [
  { value: "1", label: "Thị trấn Nghĩa Đàn" },
  { value: "2", label: "Xã Nghĩa Mai" },
  { value: "3", label: "Xã Nghĩa Yên" },
  { value: "4", label: "Xã Nghĩa Lạc" },
  { value: "5", label: "Xã Nghĩa Lâm" },
  { value: "6", label: "Xã Nghĩa Sơn" },
  { value: "7", label: "Xã Nghĩa Lợi" },
  { value: "8", label: "Xã Nghĩa Bình" },
  { value: "9", label: "Xã Nghĩa Thọ" },
  { value: "10", label: "Xã Nghĩa Minh" },
  { value: "11", label: "Xã Nghĩa Phú" },
  { value: "12", label: "Xã Nghĩa Hưng" },
  { value: "13", label: "Xã Nghĩa Hồng" },
  { value: "14", label: "Xã Nghĩa Thịnh" },
  { value: "15", label: "Xã Nghĩa Trung" },
  { value: "16", label: "Xã Nghĩa Hội" },
  { value: "17", label: "Xã Nghĩa Tân" },
  { value: "18", label: "Xã Nghĩa Thắng" },
  { value: "19", label: "Xã Nghĩa Hiếu" },
  { value: "20", label: "Xã Nghĩa Liên" },
  { value: "21", label: "Xã Nghĩa Đức" },
  { value: "22", label: "Xã Nghĩa An" },
  { value: "23", label: "Xã Nghĩa Long" },
  { value: "24", label: "Xã Nghĩa Lộc" },
  { value: "25", label: "Xã Nghĩa Khánh" },
];
export const nghean_huyenquyhop = [
  { value: "1", label: "Thị trấn Quỳ Hợp" },
  { value: "2", label: "Xã Yên Hợp" },
  { value: "3", label: "Xã Châu Tiến" },
  { value: "4", label: "Xã Châu Hồng" },
  { value: "5", label: "Xã Đồng Hợp" },
  { value: "6", label: "Xã Châu Thành" },
  { value: "7", label: "Xã Liên Hợp" },
  { value: "8", label: "Xã Châu Lộc" },
  { value: "9", label: "Xã Tam Hợp" },
  { value: "10", label: "Xã Châu Cường" },
  { value: "11", label: "Xã Châu Quang" },
  { value: "12", label: "Xã Thọ Hợp" },
  { value: "13", label: "Xã Minh Hợp" },
  { value: "14", label: "Xã Nghĩa Xuân" },
  { value: "15", label: "Xã Châu Thái" },
  { value: "16", label: "Xã Châu Đình" },
  { value: "17", label: "Xã Văn Lợi" },
  { value: "18", label: "Xã Nam Sơn" },
  { value: "19", label: "Xã Châu Lý" },
  { value: "20", label: "Xã Hạ Sơn" },
  { value: "21", label: "Xã Bắc Sơn" },
];
export const nghean_huyenquynhluu = [
  { value: "1", label: "Thị trấn Cầu Giát" },
  { value: "2", label: "Xã Quỳnh Thắng" },
  { value: "3", label: "Xã Quỳnh Tân" },
  { value: "4", label: "Xã Quỳnh Châu" },
  { value: "5", label: "Xã Tân Sơn" },
  { value: "6", label: "Xã Quỳnh Văn" },
  { value: "7", label: "Xã Ngọc Sơn" },
  { value: "8", label: "Xã Quỳnh Tam" },
  { value: "9", label: "Xã Quỳnh Hoa" },
  { value: "10", label: "Xã Quỳnh Thạch" },
  { value: "11", label: "Xã Quỳnh Bảng" },
  { value: "12", label: "Xã Quỳnh Mỹ" },
  { value: "13", label: "Xã Quỳnh Thanh" },
  { value: "14", label: "Xã Quỳnh Hậu" },
  { value: "15", label: "Xã Quỳnh Lâm" },
  { value: "16", label: "Xã Quỳnh Đôi" },
  { value: "17", label: "Xã Quỳnh Lương" },
  { value: "18", label: "Xã Quỳnh Hồng" },
  { value: "19", label: "Xã Quỳnh Yên" },
  { value: "20", label: "Xã Quỳnh Bá" },
  { value: "21", label: "Xã Quỳnh Minh" },
  { value: "22", label: "Xã Quỳnh Diện" },
  { value: "23", label: "Xã Quỳnh Hưng" },
  { value: "24", label: "Xã Quỳnh Giang" },
  { value: "25", label: "Xã Quỳnh Ngọc" },
  { value: "26", label: "Xã Quỳnh Nghĩa" },
  { value: "27", label: "Xã An Hòa" },
  { value: "28", label: "Xã Tiến Thủy" },
  { value: "29", label: "Xã Sơn Hải" },
  { value: "30", label: "Xã Quỳnh Thọ" },
  { value: "31", label: "Xã Quỳnh Thuận" },
  { value: "32", label: "Xã Quỳnh Long" },
  { value: "33", label: "Xã Tân Thắng" },
];
export const nghean_huyenconcuong = [
  { value: "1", label: "Thị trấn Con Cuông" },
  { value: "2", label: "Xã Bình Chuẩn" },
  { value: "3", label: "Xã Lạng Khê" },
  { value: "4", label: "Xã Cam Lâm" },
  { value: "5", label: "Xã Thạch Ngàn" },
  { value: "6", label: "Xã Đôn Phục" },
  { value: "7", label: "Xã Mậu Đức" },
  { value: "8", label: "Xã Châu Khê" },
  { value: "9", label: "Xã Chi Khê" },
  { value: "10", label: "Xã Bồng Khê" },
  { value: "11", label: "Xã Yên Khê" },
  { value: "12", label: "Xã Lục Dạ" },
  { value: "13", label: "Xã Môn Sơn" },
];
export const nghean_huyentanky = [
  { value: "1", label: "Thị trấn Tân Kỳ" },
  { value: "2", label: "Xã Tân Hợp" },
  { value: "3", label: "Xã Tân Phú" },
  { value: "4", label: "Xã Tân Xuân" },
  { value: "5", label: "Xã Giai Xuân" },
  { value: "6", label: "Xã Nghĩa Bình" },
  { value: "7", label: "Xã Nghĩa Đồng" },
  { value: "8", label: "Xã Đồng Văn" },
  { value: "9", label: "Xã Nghĩa Thái" },
  { value: "10", label: "Xã Nghĩa Hợp" },
  { value: "11", label: "Xã Nghĩa Hoàn" },
  { value: "12", label: "Xã Nghĩa Phúc" },
  { value: "13", label: "Xã Tiên Kỳ" },
  { value: "14", label: "Xã Tân An" },
  { value: "15", label: "Xã Nghĩa Dũng" },
  { value: "16", label: "Xã Tân Long" },
  { value: "17", label: "Xã Kỳ Sơn" },
  { value: "18", label: "Xã Hương Sơn" },
  { value: "19", label: "Xã Kỳ Tân" },
  { value: "20", label: "Xã Phú Sơn" },
  { value: "21", label: "Xã Tân Hương" },
  { value: "22", label: "Xã Nghĩa Hành" },
];
export const nghean_huyenanhson = [
  { value: "1", label: "Thị trấn Anh Sơn" },
  { value: "2", label: "Xã Thọ Sơn" },
  { value: "3", label: "Xã Thành Sơn" },
  { value: "4", label: "Xã Bình Sơn" },
  { value: "5", label: "Xã Tam Sơn" },
  { value: "6", label: "Xã Đỉnh Sơn" },
  { value: "7", label: "Xã Hùng Sơn" },
  { value: "8", label: "Xã Cẩm Sơn" },
  { value: "9", label: "Xã Đức Sơn" },
  { value: "10", label: "Xã Tường Sơn" },
  { value: "11", label: "Xã Hoa Sơn" },
  { value: "12", label: "Xã Tào Sơn" },
  { value: "13", label: "Xã Vĩnh Sơn" },
  { value: "14", label: "Xã Lạng Sơn" },
  { value: "15", label: "Xã Hội Sơn" },
  { value: "16", label: "Xã Thạch Sơn" },
  { value: "17", label: "Xã Phúc Sơn" },
  { value: "18", label: "Xã Long Sơn" },
  { value: "19", label: "Xã Khai Sơn" },
  { value: "20", label: "Xã Lĩnh Sơn" },
  { value: "21", label: "Xã Cao Sơn" },
];
export const nghean_huyendienchau = [
  { value: "1", label: "Thị trấn Diễn Châu" },
  { value: "2", label: "Xã Diễn Lâm" },
  { value: "3", label: "Xã Diễn Đoài" },
  { value: "4", label: "Xã Diễn Trường" },
  { value: "5", label: "Xã Diễn Yên" },
  { value: "6", label: "Xã Diễn Hoàng" },
  { value: "7", label: "Xã Diễn Hùng" },
  { value: "8", label: "Xã Diễn Mỹ" },
  { value: "9", label: "Xã Diễn Hồng" },
  { value: "10", label: "Xã Diễn Phong" },
  { value: "11", label: "Xã Diễn Hải" },
  { value: "12", label: "Xã Diễn Tháp" },
  { value: "13", label: "Xã Diễn Liên" },
  { value: "14", label: "Xã Diễn Vạn" },
  { value: "15", label: "Xã Diễn Kim" },
  { value: "16", label: "Xã Diễn Kỷ" },
  { value: "17", label: "Xã Diễn Xuân" },
  { value: "18", label: "Xã Diễn Thái" },
  { value: "19", label: "Xã Diễn Đồng" },
  { value: "20", label: "Xã Diễn Bích" },
  { value: "21", label: "Xã Diễn Hạnh" },
  { value: "22", label: "Xã Diễn Ngọc" },
  { value: "23", label: "Xã Diễn Quảng" },
  { value: "24", label: "Xã Diễn Nguyên" },
  { value: "25", label: "Xã Diễn Hoa" },
  { value: "26", label: "Xã Diễn Thành" },
  { value: "27", label: "Xã Diễn Phúc" },
  { value: "28", label: "Xã Diễn Minh" },
  { value: "29", label: "Xã Diễn Bình" },
  { value: "30", label: "Xã Diễn Cát" },
  { value: "31", label: "Xã Diễn Thịnh" },
  { value: "32", label: "Xã Diễn Tân" },
  { value: "33", label: "Xã Diễn Thắng" },
  { value: "34", label: "Xã Diễn Thọ" },
  { value: "35", label: "Xã Diễn Lợi" },
  { value: "36", label: "Xã Diễn Lộc" },
  { value: "37", label: "Xã Diễn Trung" },
  { value: "38", label: "Xã Diễn An" },
  { value: "39", label: "Xã Diễn Phú" },
];
export const nghean_huyenyenthanh = [
  { value: "1", label: "Thị trấn Yên Thành" },
  { value: "2", label: "Xã Mã Thành" },
  { value: "3", label: "Xã Tiến Thành" },
  { value: "4", label: "Xã Lăng Thành" },
  { value: "5", label: "Xã Tân Thành" },
  { value: "6", label: "Xã Đức Thành" },
  { value: "7", label: "Xã Kim Thành" },
  { value: "8", label: "Xã Hậu Thành" },
  { value: "9", label: "Xã Hùng Thành" },
  { value: "10", label: "Xã Đô Thành" },
  { value: "11", label: "Xã Thọ Thành" },
  { value: "12", label: "Xã Quang Thành" },
  { value: "13", label: "Xã Tây Thành" },
  { value: "14", label: "Xã Phúc Thành" },
  { value: "15", label: "Xã Hồng Thành" },
  { value: "16", label: "Xã Đồng Thành" },
  { value: "17", label: "Xã Phú Thành" },
  { value: "18", label: "Xã Hoa Thành" },
  { value: "19", label: "Xã Tăng Thành" },
  { value: "20", label: "Xã Văn Thành" },
  { value: "21", label: "Xã Thịnh Thành" },
  { value: "22", label: "Xã Hợp Thành" },
  { value: "23", label: "Xã Xuân Thành" },
  { value: "24", label: "Xã Bắc Thành" },
  { value: "25", label: "Xã Nhân Thành" },
  { value: "26", label: "Xã Trung Thành" },
  { value: "27", label: "Xã Long Thành" },
  { value: "28", label: "Xã Minh Thành" },
  { value: "29", label: "Xã Nam Thành" },
  { value: "30", label: "Xã Vĩnh Thành" },
  { value: "31", label: "Xã Lý Thành" },
  { value: "32", label: "Xã Khánh Thành" },
  { value: "33", label: "Xã Viên Thành" },
  { value: "34", label: "Xã Đại Thành" },
  { value: "35", label: "Xã Liên Thành" },
  { value: "36", label: "Xã Bảo Thành" },
  { value: "37", label: "Xã Mỹ Thành" },
  { value: "38", label: "Xã Công Thành" },
  { value: "39", label: "Xã Sơn Thành" },
];
export const nghean_huyendoluong = [
  { value: "1", label: "Thị trấn Đô Lương" },
  { value: "2", label: "Xã Giang Sơn Đông" },
  { value: "3", label: "Xã Giang Sơn Tây" },
  { value: "4", label: "Xã Lam Sơn" },
  { value: "5", label: "Xã Bồi Sơn" },
  { value: "6", label: "Xã Hồng Sơn" },
  { value: "7", label: "Xã Bài Sơn" },
  { value: "8", label: "Xã Ngọc Sơn" },
  { value: "9", label: "Xã Bắc Sơn" },
  { value: "10", label: "Xã Tràng Sơn" },
  { value: "11", label: "Xã Thượng Sơn" },
  { value: "12", label: "Xã Hòa Sơn" },
  { value: "13", label: "Xã Đặng Sơn" },
  { value: "14", label: "Xã Đông Sơn" },
  { value: "15", label: "Xã Nam Sơn" },
  { value: "16", label: "Xã Lưu Sơn" },
  { value: "17", label: "Xã Yên Sơn" },
  { value: "18", label: "Xã Văn Sơn" },
  { value: "19", label: "Xã Đà Sơn" },
  { value: "20", label: "Xã Lạc Sơn" },
  { value: "21", label: "Xã Tân Sơn" },
  { value: "22", label: "Xã Thái Sơn" },
  { value: "23", label: "Xã Quang Sơn" },
  { value: "24", label: "Xã Thịnh Sơn" },
  { value: "25", label: "Xã Trung Sơn" },
  { value: "26", label: "Xã Xuân Sơn" },
  { value: "27", label: "Xã Minh Sơn" },
  { value: "28", label: "Xã Thuận Sơn" },
  { value: "29", label: "Xã Nhân Sơn" },
  { value: "30", label: "Xã Hiến Sơn" },
  { value: "31", label: "Xã Mỹ Sơn" },
  { value: "32", label: "Xã Trù Sơn" },
  { value: "33", label: "Xã Đại Sơn" },
];
export const nghean_huyenthanhchuong = [
  { value: "1", label: "Thị trấn Thanh Chương" },
  { value: "2", label: "Xã Cát Văn" },
  { value: "3", label: "Xã Thanh Nho" },
  { value: "4", label: "Xã Hạnh Lâm" },
  { value: "5", label: "Xã Thanh Sơn" },
  { value: "6", label: "Xã Thanh Hòa" },
  { value: "7", label: "Xã Phong Thịnh" },
  { value: "8", label: "Xã Thanh Phong" },
  { value: "9", label: "Xã Thanh Mỹ" },
  { value: "10", label: "Xã Thanh Tiên" },
  { value: "11", label: "Xã Thanh Hưng" },
  { value: "12", label: "Xã Thanh Liên" },
  { value: "13", label: "Xã Thanh Tường" },
  { value: "14", label: "Xã Thanh Văn" },
  { value: "15", label: "Xã Thanh Đồng" },
  { value: "16", label: "Xã Thanh Ngọc" },
  { value: "17", label: "Xã Thanh Hương" },
  { value: "18", label: "Xã Ngọc Lâm" },
  { value: "19", label: "Xã Thanh Lĩnh" },
  { value: "20", label: "Xã Đồng Văn" },
  { value: "21", label: "Xã Ngọc Sơn" },
  { value: "22", label: "Xã Thanh Thịnh" },
  { value: "23", label: "Xã Thanh An" },
  { value: "24", label: "Xã Thanh Chi" },
  { value: "25", label: "Xã Xuân Tường" },
  { value: "26", label: "Xã Thanh Dương" },
  { value: "27", label: "Xã Thanh Lương" },
  { value: "28", label: "Xã Thanh Khê" },
  { value: "29", label: "Xã Võ Liệt" },
  { value: "30", label: "Xã Thanh Long" },
  { value: "31", label: "Xã Thanh Thủy" },
  { value: "32", label: "Xã Thanh Khai" },
  { value: "33", label: "Xã Thanh Yên" },
  { value: "34", label: "Xã Thanh Hà" },
  { value: "35", label: "Xã Thanh Giang" },
  { value: "36", label: "Xã Thanh Tùng" },
  { value: "37", label: "Xã Thanh Lâm" },
  { value: "38", label: "Xã Thanh Mai" },
  { value: "39", label: "Xã Thanh Xuân" },
  { value: "40", label: "Xã Thanh Đức" },
];
export const nghean_huyennghiloc = [
  { value: "1", label: "Thị trấn Quán Hành" },
  { value: "2", label: "Xã Nghi Văn" },
  { value: "3", label: "Xã Nghi Yên" },
  { value: "4", label: "Xã Nghi Tiến" },
  { value: "5", label: "Xã Nghi Hưng" },
  { value: "6", label: "Xã Nghi Đồng" },
  { value: "7", label: "Xã Nghi Thiết" },
  { value: "8", label: "Xã Nghi Lâm" },
  { value: "9", label: "Xã Nghi Quang" },
  { value: "10", label: "Xã Nghi Kiều" },
  { value: "11", label: "Xã Nghi Mỹ" },
  { value: "12", label: "Xã Nghi Phương" },
  { value: "13", label: "Xã Nghi Thuận" },
  { value: "14", label: "Xã Nghi Long" },
  { value: "15", label: "Xã Nghi Xá" },
  { value: "16", label: "Xã Nghi Hợp" },
  { value: "17", label: "Xã Nghi Hoa" },
  { value: "18", label: "Xã Nghi Khánh" },
  { value: "19", label: "Xã Nghi Thịnh" },
  { value: "20", label: "Xã Nghi Công Bắc" },
  { value: "21", label: "Xã Nghi Công Nam" },
  { value: "22", label: "Xã Nghi Thạch" },
  { value: "23", label: "Xã Nghi Trung" },
  { value: "24", label: "Xã Nghi Trường" },
  { value: "25", label: "Xã Nghi Diên" },
  { value: "26", label: "Xã Nghi Phong" },
  { value: "27", label: "Xã Nghi Xuân" },
  { value: "28", label: "Xã Nghi Vạn" },
  { value: "29", label: "Xã Phúc Thọ" },
  { value: "30", label: "Xã Nghi Thái" },
];
export const nghean_huyennamdan = [
  { value: "1", label: "Thị trấn Nam Đàn" },
  { value: "2", label: "Xã Nam Hưng" },
  { value: "3", label: "Xã Nam Nghĩa" },
  { value: "4", label: "Xã Nam Thanh" },
  { value: "5", label: "Xã Nam Anh" },
  { value: "6", label: "Xã Nam Xuân" },
  { value: "7", label: "Xã Nam Thái" },
  { value: "8", label: "Xã Vân Diên" },
  { value: "9", label: "Xã Nam Lĩnh" },
  { value: "10", label: "Xã Nam Giang" },
  { value: "11", label: "Xã Xuân Hòa" },
  { value: "12", label: "Xã Hùng Tiến" },
  { value: "13", label: "Xã Nam Thượng" },
  { value: "14", label: "Xã Nam Tân" },
  { value: "15", label: "Xã Kim Liên" },
  { value: "16", label: "Xã Nam Lộc" },
  { value: "17", label: "Xã Hồng Long" },
  { value: "18", label: "Xã Xuân Lâm" },
  { value: "19", label: "Xã Nam Cát" },
  { value: "20", label: "Xã Khánh Sơn" },
  { value: "21", label: "Xã Nam Phúc" },
  { value: "22", label: "Xã Nam Cường" },
  { value: "23", label: "Xã Nam Trung" },
  { value: "24", label: "Xã Nam Kim" },
];
export const nghean_huyenhungnguyen = [
  { value: "1", label: "Thị trấn Hưng Nguyên" },
  { value: "2", label: "Xã Hưng Trung" },
  { value: "3", label: "Xã Hưng Yên" },
  { value: "4", label: "Xã Hưng Yên Bắc" },
  { value: "5", label: "Xã Hưng Tây" },
  { value: "6", label: "Xã Hưng Đạo" },
  { value: "7", label: "Xã Hưng Mỹ" },
  { value: "8", label: "Xã Hưng Thịnh" },
  { value: "9", label: "Xã Hưng Lĩnh" },
  { value: "10", label: "Xã Hưng Thông" },
  { value: "11", label: "Xã Hưng Tân" },
  { value: "12", label: "Xã Hưng Lợi" },
  { value: "13", label: "Xã Hưng Thắng" },
  { value: "14", label: "Xã Hưng Phúc" },
  { value: "15", label: "Xã Hưng Long" },
  { value: "16", label: "Xã Hưng Tiến" },
  { value: "17", label: "Xã Hưng Xá" },
  { value: "18", label: "Xã Hưng Châu" },
  { value: "19", label: "Xã Hưng Xuân" },
  { value: "20", label: "Xã Hưng Nhân" },
  { value: "21", label: "Xã Hưng Phú" },
  { value: "22", label: "Xã Hưng Khánh" },
  { value: "23", label: "Xã Hưng Lam" },
];
export const nghean_xahoangmai = [
  { value: "1", label: "Xã Quỳnh Vinh" },
  { value: "2", label: "Xã Quỳnh Lộc" },
  { value: "3", label: "Phường Quỳnh Thiện" },
  { value: "4", label: "Xã Quỳnh Lập" },
  { value: "5", label: "Xã Quỳnh Trang" },
  { value: "6", label: "Phường Mai Hùng" },
  { value: "7", label: "Phường Quỳnh Dị" },
  { value: "8", label: "Phường Quỳnh Xuân" },
  { value: "9", label: "Phường Quỳnh Phương" },
  { value: "10", label: "Xã Quỳnh Liên" },
];

export const hatinh_thanhphohatinh = [
  { value: "1", label: "Phường Trần Phú" },
  { value: "2", label: "Phường Nam Hà" },
  { value: "3", label: "Phường Bắc Hà" },
  { value: "4", label: "Phường Nguyễn Du" },
  { value: "5", label: "Phường Tân Giang" },
  { value: "6", label: "Phường Đại Nài" },
  { value: "7", label: "Phường Hà Huy Tập" },
  { value: "8", label: "Xã Thạch Trung" },
  { value: "9", label: "Phường Thạch Quý" },
  { value: "10", label: "Phường Thạch Linh" },
  { value: "11", label: "Phường Văn Yên" },
  { value: "12", label: "Xã Thạch Hạ" },
  { value: "13", label: "Xã Thạch Môn" },
  { value: "14", label: "Xã Thạch Đồng" },
  { value: "15", label: "Xã Thạch Hưng" },
  { value: "16", label: "Xã Thạch Bình" },
];
export const hatinh_xahonglinh = [
  { value: "1", label: "Phường Bắc Hồng" },
  { value: "2", label: "Phường Nam Hồng" },
  { value: "3", label: "Phường Trung Lương" },
  { value: "4", label: "Phường Đức Thuận" },
  { value: "5", label: "Phường Đậu Liêu" },
  { value: "6", label: "Xã Thuận Lộc" },
];
export const hatinh_huyenhuongson = [
  { value: "1", label: "Thị trấn Phố Châu" },
  { value: "2", label: "Thị trấn Tây Sơn" },
  { value: "3", label: "Xã Sơn Hồng" },
  { value: "4", label: "Xã Sơn Tiến" },
  { value: "5", label: "Xã Sơn Lâm" },
  { value: "6", label: "Xã Sơn Lễ" },
  { value: "7", label: "Xã Sơn Thịnh" },
  { value: "8", label: "Xã Sơn An" },
  { value: "9", label: "Xã Sơn Giang" },
  { value: "10", label: "Xã Sơn Lĩnh" },
  { value: "11", label: "Xã Sơn Hòa" },
  { value: "12", label: "Xã Sơn Tân" },
  { value: "13", label: "Xã Sơn Mỹ" },
  { value: "14", label: "Xã Sơn Tây" },
  { value: "15", label: "Xã Sơn Ninh" },
  { value: "16", label: "Xã Sơn Châu" },
  { value: "17", label: "Xã Sơn Hà" },
  { value: "18", label: "Xã Sơn Quang" },
  { value: "19", label: "Xã Sơn Trung" },
  { value: "20", label: "Xã Sơn Bằng" },
  { value: "21", label: "Xã Sơn Bình" },
  { value: "22", label: "Xã Sơn Kim 1" },
  { value: "23", label: "Xã Sơn Kim 2" },
  { value: "24", label: "Xã Sơn Trà" },
  { value: "25", label: "Xã Sơn Long" },
  { value: "26", label: "Xã Sơn Diệm" },
  { value: "27", label: "Xã Sơn Thủy" },
  { value: "28", label: "Xã Sơn Hàm" },
  { value: "29", label: "Xã Sơn Phú" },
  { value: "30", label: "Xã Sơn Phúc" },
  { value: "31", label: "Xã Sơn Trường" },
  { value: "32", label: "Xã Sơn Mai" },
];
export const hatinh_huyenductho = [
  { value: "1", label: "Thị trấn Đức Thọ" },
  { value: "2", label: "Xã Đức Quang" },
  { value: "3", label: "Xã Đức Vĩnh" },
  { value: "4", label: "Xã Đức Châu" },
  { value: "5", label: "Xã Đức Tùng" },
  { value: "6", label: "Xã Trường Sơn" },
  { value: "7", label: "Xã Liên Minh" },
  { value: "8", label: "Xã Đức La" },
  { value: "9", label: "Xã Yên Hồ" },
  { value: "10", label: "Xã Đức Nhân" },
  { value: "11", label: "Xã Tùng Ảnh" },
  { value: "12", label: "Xã Bùi Xá" },
  { value: "13", label: "Xã Đức Thịnh" },
  { value: "14", label: "Xã Đức Yên" },
  { value: "15", label: "Xã Đức Thủy" },
  { value: "16", label: "Xã Thái Yên" },
  { value: "17", label: "Xã Trung Lễ" },
  { value: "18", label: "Xã Đức Hòa" },
  { value: "19", label: "Xã Đức Long" },
  { value: "20", label: "Xã Đức Lâm" },
  { value: "21", label: "Xã Đức Thanh" },
  { value: "22", label: "Xã Đức Dũng" },
  { value: "23", label: "Xã Đức Lập" },
  { value: "24", label: "Xã Đức An" },
  { value: "25", label: "Xã Đức Lạc" },
  { value: "26", label: "Xã Đức Đồng" },
  { value: "27", label: "Xã Đức Lạng" },
  { value: "28", label: "Xã Tân Hương" },
];
export const hatinh_huyenvuquang = [
  { value: "1", label: "Thị trấn Vũ Quang" },
  { value: "2", label: "Xã Ân Phú" },
  { value: "3", label: "Xã Đức Giang" },
  { value: "4", label: "Xã Đức Lĩnh" },
  { value: "5", label: "Xã Sơn Thọ" },
  { value: "6", label: "Xã Đức Hương" },
  { value: "7", label: "Xã Đức Bồng" },
  { value: "8", label: "Xã Đức Liên" },
  { value: "9", label: "Xã Hương Điền" },
  { value: "10", label: "Xã Hương Minh" },
  { value: "11", label: "Xã Hương Thọ" },
  { value: "12", label: "Xã Hương Quang" },
];
export const hatinh_huyennghixuan = [
  { value: "1", label: "Thị trấn Nghi Xuân" },
  { value: "2", label: "Thị trấn Xuân An" },
  { value: "3", label: "Xã Xuân Hội" },
  { value: "4", label: "Xã Xuân Trường" },
  { value: "5", label: "Xã Xuân Đan" },
  { value: "6", label: "Xã Xuân Phổ" },
  { value: "7", label: "Xã Xuân Hải" },
  { value: "8", label: "Xã Xuân Giang" },
  { value: "9", label: "Xã Tiên Điền" },
  { value: "10", label: "Xã Xuân Yên" },
  { value: "11", label: "Xã Xuân Mỹ" },
  { value: "12", label: "Xã Xuân Thành" },
  { value: "13", label: "Xã Xuân Viên" },
  { value: "14", label: "Xã Xuân Hồng" },
  { value: "15", label: "Xã Cỗ Đạm" },
  { value: "16", label: "Xã Xuân Liên" },
  { value: "17", label: "Xã Xuân Lĩnh" },
  { value: "18", label: "Xã Xuân Lam" },
  { value: "19", label: "Xã Cương Gián" },
];
export const hatinh_huyencanloc = [
  { value: "1", label: "Thị trấn Nghèn" },
  { value: "2", label: "Xã Thiên Lộc" },
  { value: "3", label: "Xã Thuần Thiện" },
  { value: "4", label: "Xã Kim Lộc" },
  { value: "5", label: "Xã Vượng Lộc" },
  { value: "6", label: "Xã Thanh Lộc" },
  { value: "7", label: "Xã Song Lộc" },
  { value: "8", label: "Xã Thường Nga" },
  { value: "9", label: "Xã Trường Lộc" },
  { value: "10", label: "Xã Tùng Lộc" },
  { value: "11", label: "Xã Yên Lộc" },
  { value: "12", label: "Xã Phú Lộc" },
  { value: "13", label: "Xã Khánh Lộc" },
  { value: "14", label: "Xã Gia Hanh" },
  { value: "15", label: "Xã Vĩnh Lộc" },
  { value: "16", label: "Xã Tiến Lộc" },
  { value: "17", label: "Xã Trung Lộc" },
  { value: "18", label: "Xã Xuân Lộc" },
  { value: "19", label: "Xã Thượng Lộc" },
  { value: "20", label: "Xã Quang Lộc" },
  { value: "21", label: "Xã Đồng Lộc" },
  { value: "22", label: "Xã Mỹ Lộc" },
  { value: "23", label: "Xã Sơn Lộc" },
];
export const hatinh_huyenhuongkhe = [
  { value: "1", label: "Thị trấn Hương Khê" },
  { value: "2", label: "Xã Phương Mỹ" },
  { value: "3", label: "Xã Hà Linh" },
  { value: "4", label: "Xã Hương Thủy" },
  { value: "5", label: "Xã Hòa Hải" },
  { value: "6", label: "Xã Phương Điền" },
  { value: "7", label: "Xã Phúc Đồng" },
  { value: "8", label: "Xã Hương Giang" },
  { value: "9", label: "Xã Lộc Yên" },
  { value: "10", label: "Xã Hương Bình" },
  { value: "11", label: "Xã Hương Long" },
  { value: "12", label: "Xã Phú Gia" },
  { value: "13", label: "Xã Gia Phố" },
  { value: "14", label: "Xã Phú Phong" },
  { value: "15", label: "Xã Hương Đô" },
  { value: "16", label: "Xã Hương Vĩnh" },
  { value: "17", label: "Xã Hương Xuân" },
  { value: "18", label: "Xã Phúc Trạch" },
  { value: "19", label: "Xã Hương Trà" },
  { value: "20", label: "Xã Hương Trạch" },
  { value: "21", label: "Xã Hương Lâm" },
  { value: "22", label: "Xã Hương Liên" },
];
export const hatinh_huyenthachha = [
  { value: "1", label: "Thị trấn Thạch Hà" },
  { value: "2", label: "Xã Ngọc Sơn" },
  { value: "3", label: "Xã Thạch Hải" },
  { value: "4", label: "Xã Thạch Bàn" },
  { value: "5", label: "Xã Thạch Kênh" },
  { value: "6", label: "Xã Thạch Sơn" },
  { value: "7", label: "Xã Thạch Liên" },
  { value: "8", label: "Xã Thạch Đỉnh" },
  { value: "9", label: "Xã Phù Việt" },
  { value: "10", label: "Xã Thạch Khê" },
  { value: "11", label: "Xã Thạch Long" },
  { value: "12", label: "Xã Việt Xuyên" },
  { value: "13", label: "Xã Thạch Tiến" },
  { value: "14", label: "Xã Thạch Thanh" },
  { value: "15", label: "Xã Thạch Trị" },
  { value: "16", label: "Xã Thạch Lạc" },
  { value: "17", label: "Xã Thạch Ngọc" },
  { value: "18", label: "Xã Tượng Sơn" },
  { value: "19", label: "Xã Thạch Văn" },
  { value: "20", label: "Xã Thạch Vĩnh" },
  { value: "21", label: "Xã Thạch Thắng" },
  { value: "22", label: "Xã Thạch Lưu" },
  { value: "23", label: "Xã Thạch Đài" },
  { value: "24", label: "Xã Bắc Sơn" },
  { value: "25", label: "Xã Thạch Hội" },
  { value: "26", label: "Xã Thạch Tân" },
  { value: "27", label: "Xã Thạch Lâm" },
  { value: "28", label: "Xã Thạch Xuân" },
  { value: "29", label: "Xã Thạch Hương" },
  { value: "30", label: "Xã Nam Hương" },
  { value: "31", label: "Xã Thạch Điền" },
];
export const hatinh_huyencamxuyen = [
  { value: "1", label: "Thị trấn Cẩm Xuyên" },
  { value: "2", label: "Thị trấn Thiên Cầm" },
  { value: "3", label: "Xã Cẩm Hòa" },
  { value: "4", label: "Xã Cẩm Dương" },
  { value: "5", label: "Xã Cẩm Bình" },
  { value: "6", label: "Xã Cẩm Yên" },
  { value: "7", label: "Xã Cẩm Vĩnh" },
  { value: "8", label: "Xã Cẩm Thành" },
  { value: "9", label: "Xã Cẩm Quang" },
  { value: "10", label: "Xã Cẩm Nam" },
  { value: "11", label: "Xã Cẩm Huy" },
  { value: "12", label: "Xã Cẩm Thạch" },
  { value: "13", label: "Xã Cẩm Nhượng" },
  { value: "14", label: "Xã Cẩm Thăng" },
  { value: "15", label: "Xã Cẩm Duệ" },
  { value: "16", label: "Xã Cẩm Phúc" },
  { value: "17", label: "Xã Cẩm Lĩnh" },
  { value: "18", label: "Xã Cẩm Quan" },
  { value: "19", label: "Xã Cẩm Hà" },
  { value: "20", label: "Xã Cẩm Lộc" },
  { value: "21", label: "Xã Cẩm Hưng" },
  { value: "22", label: "Xã Cẩm Thịnh" },
  { value: "23", label: "Xã Cẩm Mỹ" },
  { value: "24", label: "Xã Cẩm Trung" },
  { value: "25", label: "Xã Cẩm Sơn" },
  { value: "26", label: "Xã Cẩm Lạc" },
  { value: "27", label: "Xã Cẩm Minh" },
];
export const hatinh_huyenkyanh = [
  { value: "1", label: "Xã Kỳ Xuân" },
  { value: "2", label: "Xã Kỳ Bắc" },
  { value: "3", label: "Xã Kỳ Phú" },
  { value: "4", label: "Xã Kỳ Phong" },
  { value: "5", label: "Xã Kỳ Tiến" },
  { value: "6", label: "Xã Kỳ Giang" },
  { value: "7", label: "Xã Kỳ Đồng" },
  { value: "8", label: "Xã Kỳ Khang" },
  { value: "9", label: "Xã Kỳ Văn" },
  { value: "10", label: "Xã Kỳ Trung" },
  { value: "11", label: "Xã Kỳ Thọ" },
  { value: "12", label: "Xã Kỳ Tây" },
  { value: "13", label: "Xã Kỳ Thượng" },
  { value: "14", label: "Xã Kỳ Hải" },
  { value: "15", label: "Xã Kỳ Thư" },
  { value: "16", label: "Xã Kỳ Châu" },
  { value: "17", label: "Xã Kỳ Tân" },
  { value: "18", label: "Xã Kỳ Hợp" },
  { value: "19", label: "Xã Kỳ Lâm" },
  { value: "20", label: "Xã Kỳ Sơn" },
  { value: "21", label: "Xã Kỳ Lạc" },
];
export const hatinh_huyenlocha = [
  { value: "1", label: "Xã Tân Lộc" },
  { value: "2", label: "Xã Hồng Lộc" },
  { value: "3", label: "Xã Thịnh Lộc" },
  { value: "4", label: "Xã An Lộc" },
  { value: "5", label: "Xã Bình Lộc" },
  { value: "6", label: "Xã Ích Hậu" },
  { value: "7", label: "Xã Phù Lưu" },
  { value: "8", label: "Xã Thạch Bằng" },
  { value: "9", label: "Xã Thạch Mỹ" },
  { value: "10", label: "Xã Thạch Kim" },
  { value: "11", label: "Xã Thạch Châu" },
  { value: "12", label: "Xã Hộ Độ" },
  { value: "13", label: "Xã Mai Phụ" },
];
export const hatinh_xakyanh = [
  { value: "1", label: "Phường Sông Trí" },
  { value: "2", label: "Xã Kỳ Ninh" },
  { value: "3", label: "Xã Kỳ Lợi" },
  { value: "4", label: "Xã Kỳ Hà" },
  { value: "5", label: "Xã Kỳ Hưng" },
  { value: "6", label: "Phường Kỳ Trinh" },
  { value: "7", label: "Phường Kỳ Thịnh" },
  { value: "8", label: "Xã Kỳ Hoa" },
  { value: "9", label: "Phường Kỳ Phương" },
  { value: "10", label: "Phường Kỳ Long" },
  { value: "11", label: "Phường Kỳ Liên" },
  { value: "12", label: "Xã Kỳ Nam" },
];
export const quangbinh_thanhphodonghoi = [
  { value: "1", label: "Phường Hải Thành" },
  { value: "2", label: "Phường Đồng Phú" },
  { value: "3", label: "Phường Bắc Lý" },
  { value: "4", label: "Phường Đồng Mỹ" },
  { value: "5", label: "Phường Nam Lý" },
  { value: "6", label: "Phường Hải Đình" },
  { value: "7", label: "Phường Đồng Sơn" },
  { value: "8", label: "Phường Phú Hải" },
  { value: "9", label: "Phường Bắc Nghĩa" },
  { value: "10", label: "Phường Đức Ninh Đông" },
  { value: "11", label: "Xã Quang Phú" },
  { value: "12", label: "Xã Lộc Ninh" },
  { value: "13", label: "Xã Bảo Ninh" },
  { value: "14", label: "Xã Nghĩa Ninh" },
  { value: "15", label: "Xã Thuận Đức" },
  { value: "16", label: "Xã Đức Ninh" },
];
export const quangbinh_huyenminhhoa = [
  { value: "1", label: "Thị trấn Quy Đạt" },
  { value: "2", label: "Xã Dân Hóa" },
  { value: "3", label: "Xã Trọng Hóa" },
  { value: "4", label: "Xã Hóa Phúc" },
  { value: "5", label: "Xã Hồng Hóa" },
  { value: "6", label: "Xã Hóa Thanh" },
  { value: "7", label: "Xã Hóa Tiến" },
  { value: "8", label: "Xã Hóa Hợp" },
  { value: "9", label: "Xã Xuân Hóa" },
  { value: "10", label: "Xã Yên Hóa" },
  { value: "11", label: "Xã Minh Hóa" },
  { value: "12", label: "Xã Tân Hóa" },
  { value: "13", label: "Xã Hóa Sơn" },
  { value: "14", label: "Xã Quy Hóa" },
  { value: "15", label: "Xã Trung Hóa" },
  { value: "16", label: "Xã Thượng Hóa" },
];
export const quangbinh_huyentuyenhoa = [
  { value: "1", label: "Thị trấn Đồng Lê" },
  { value: "2", label: "Xã Hương Hóa" },
  { value: "3", label: "Xã Kim Hóa" },
  { value: "4", label: "Xã Thanh Hóa" },
  { value: "5", label: "Xã Thanh Thạch" },
  { value: "6", label: "Xã Thuận Hóa" },
  { value: "7", label: "Xã Lâm Hóa" },
  { value: "8", label: "Xã Lê Hóa" },
  { value: "9", label: "Xã Sơn Hóa" },
  { value: "10", label: "Xã Đồng Hóa" },
  { value: "11", label: "Xã Ngư Hóa" },
  { value: "12", label: "Xã Nam Hóa" },
  { value: "13", label: "Xã Thạch Hóa" },
  { value: "14", label: "Xã Đức Hóa" },
  { value: "15", label: "Xã Phong Hóa" },
  { value: "16", label: "Xã Mai Hóa" },
  { value: "17", label: "Xã Tiến Hóa" },
  { value: "18", label: "Xã Châu Hóa" },
  { value: "19", label: "Xã Cao Quảng" },
  { value: "20", label: "Xã Văn Hóa" },
];
export const quangbinh_huyenquangtrach = [
  { value: "1", label: "Xã Quảng Hợp" },
  { value: "2", label: "Xã Quảng Kim" },
  { value: "3", label: "Xã Quảng Đông" },
  { value: "4", label: "Xã Quảng Phú" },
  { value: "5", label: "Xã Quảng Châu" },
  { value: "6", label: "Xã Quảng Thạch" },
  { value: "7", label: "Xã Quảng Lưu" },
  { value: "8", label: "Xã Quảng Tùng" },
  { value: "9", label: "Xã Cảnh Dương" },
  { value: "10", label: "Xã Quảng Tiến" },
  { value: "11", label: "Xã Quảng Hưng" },
  { value: "12", label: "Xã Quảng Xuân" },
  { value: "13", label: "Xã Cảnh Hóa" },
  { value: "14", label: "Xã Quảng Liên" },
  { value: "15", label: "Xã Quảng Trường" },
  { value: "16", label: "Xã Quảng Phương" },
  { value: "17", label: "Xã Phù Hóa" },
  { value: "18", label: "Xã Quảng Thanh" },
];
export const quangbinh_huyenbotrach = [
  { value: "1", label: "Thị trấn Hoàn Lão" },
  { value: "2", label: "Thị trấn NT Việt Trung" },
  { value: "3", label: "Xã Xuân Trạch" },
  { value: "4", label: "Xã Mỹ Trạch" },
  { value: "5", label: "Xã Hạ Trạch" },
  { value: "6", label: "Xã Bắc Trạch" },
  { value: "7", label: "Xã Lâm Trạch" },
  { value: "8", label: "Xã Thanh Trạch" },
  { value: "9", label: "Xã Liên Trạch" },
  { value: "10", label: "Xã Phúc Trạch" },
  { value: "11", label: "Xã Cự Nẫm" },
  { value: "12", label: "Xã Hải Trạch" },
  { value: "13", label: "Xã Thượng Trạch" },
  { value: "14", label: "Xã Sơn Lộc" },
  { value: "15", label: "Xã Phú Trạch" },
  { value: "16", label: "Xã Hưng Trạch" },
  { value: "17", label: "Xã Đồng Trạch" },
  { value: "18", label: "Xã Đức Trạch" },
  { value: "19", label: "Xã Sơn Trạch" },
  { value: "20", label: "Xã Vạn Trạch" },
  { value: "21", label: "Xã Hoàn Trạch" },
  { value: "22", label: "Xã Phú Định" },
  { value: "23", label: "Xã Trung Trạch" },
  { value: "24", label: "Xã Tây Trạch" },
  { value: "25", label: "Xã Hòa Trạch" },
  { value: "26", label: "Xã Đại Trạch" },
  { value: "27", label: "Xã Nhân Trạch" },
  { value: "28", label: "Xã Tân Trạch" },
  { value: "29", label: "Xã Nam Trạch" },
  { value: "30", label: "Xã Lý Trạch" },
];
export const quangbinh_huyenquangninh = [
  { value: "1", label: "Thị trấn Quán Hàu" },
  { value: "2", label: "Xã Trường Sơn" },
  { value: "3", label: "Xã Lương Ninh" },
  { value: "4", label: "Xã Vĩnh Ninh" },
  { value: "5", label: "Xã Võ Ninh" },
  { value: "6", label: "Xã Hải Ninh" },
  { value: "7", label: "Xã Hàm Ninh" },
  { value: "8", label: "Xã Duy Ninh" },
  { value: "9", label: "Xã Gia Ninh" },
  { value: "10", label: "Xã Trường Xuân" },
  { value: "11", label: "Xã Hiền Ninh" },
  { value: "12", label: "Xã Tân Ninh" },
  { value: "13", label: "Xã Xuân Ninh" },
  { value: "14", label: "Xã An Ninh" },
  { value: "15", label: "Xã Vạn Ninh" },
];
export const quangbinh_huyenlethuy = [
  { value: "1", label: "Thị trấn NT Lệ Ninh" },
  { value: "2", label: "Thị trấn Kiến Giang" },
  { value: "3", label: "Xã Hồng Thủy" },
  { value: "4", label: "Xã Ngư Thủy Bắc" },
  { value: "5", label: "Xã Hoa Thủy" },
  { value: "6", label: "Xã Thanh Thủy" },
  { value: "7", label: "Xã An Thủy" },
  { value: "8", label: "Xã Phong Thủy" },
  { value: "9", label: "Xã Cam Thủy" },
  { value: "10", label: "Xã Ngân Thủy" },
  { value: "11", label: "Xã Sơn Thủy" },
  { value: "12", label: "Xã Lộc Thủy" },
  { value: "13", label: "Xã Ngư Thủy Trung" },
  { value: "14", label: "Xã Liên Thủy" },
  { value: "15", label: "Xã Hưng Thủy" },
  { value: "16", label: "Xã Dương Thủy" },
  { value: "17", label: "Xã Tân Thủy" },
  { value: "18", label: "Xã Phú Thủy" },
  { value: "19", label: "Xã Xuân Thủy" },
  { value: "20", label: "Xã Mỹ Thủy" },
  { value: "21", label: "Xã Ngư Thủy Nam" },
  { value: "22", label: "Xã Mai Thủy" },
  { value: "23", label: "Xã Sen Thủy" },
  { value: "24", label: "Xã Thái Thủy" },
  { value: "25", label: "Xã Kim Thủy" },
  { value: "26", label: "Xã Trường Thủy" },
  { value: "27", label: "Xã Văn Thủy" },
  { value: "28", label: "Xã Lâm Thủy" },
];
export const quangbinh_xabadon = [
  { value: "1", label: "Phường Ba Đồn" },
  { value: "2", label: "Phường Quảng Long" },
  { value: "3", label: "Phường Quảng Thọ" },
  { value: "4", label: "Xã Quảng Tiên" },
  { value: "5", label: "Xã Quảng Trung" },
  { value: "6", label: "Phường Quảng Phong" },
  { value: "7", label: "Phường Quảng Thuận" },
  { value: "8", label: "Xã Quảng Tân" },
  { value: "9", label: "Xã Quảng Hải" },
  { value: "10", label: "Xã Quảng Sơn" },
  { value: "11", label: "Xã Quảng Lộc" },
  { value: "12", label: "Xã Quảng Thủy" },
  { value: "13", label: "Xã Quảng Văn" },
  { value: "14", label: "Phường Quảng Phúc" },
  { value: "15", label: "Xã Quảng Hòa" },
  { value: "16", label: "Xã Quảng Minh" },
];
export const quangtri_thanhphodongha = [
  { value: "1", label: "Phường Đông Giang" },
  { value: "2", label: "Phường 1" },
  { value: "3", label: "Phường Đông Lễ" },
  { value: "4", label: "Phường Đông Thanh" },
  { value: "5", label: "Phường 2" },
  { value: "6", label: "Phường 4" },
  { value: "7", label: "Phường 5" },
  { value: "8", label: "Phường Đông Lương" },
  { value: "9", label: "Phường 3" },
];
export const quangtri_xaquangtri = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường An Đôn" },
  { value: "3", label: "Phường 2" },
  { value: "4", label: "Phường 3" },
  { value: "5", label: "Xã Hải Lệ" },
];
export const quangtri_huyenvinhlinh = [
  { value: "1", label: "Thị trấn Hồ Xá" },
  { value: "2", label: "Thị trấn Bến Quan" },
  { value: "3", label: "Xã Vĩnh Thái" },
  { value: "4", label: "Xã Vĩnh Tú" },
  { value: "5", label: "Xã Vĩnh Chấp" },
  { value: "6", label: "Xã Vĩnh Trung" },
  { value: "7", label: "Xã Vĩnh Kim" },
  { value: "8", label: "Xã Vĩnh Thạch" },
  { value: "9", label: "Xã Vĩnh Long" },
  { value: "10", label: "Xã Vĩnh Nam" },
  { value: "11", label: "Xã Vĩnh Khê" },
  { value: "12", label: "Xã Vĩnh Hòa" },
  { value: "13", label: "Xã Vĩnh Hiền" },
  { value: "14", label: "Xã Vĩnh Thủy" },
  { value: "15", label: "Xã Vĩnh Lâm" },
  { value: "16", label: "Xã Vĩnh Thành" },
  { value: "17", label: "Xã Vĩnh Tân" },
  { value: "18", label: "Thị trấn Cửa Tùng" },
  { value: "19", label: "Xã Vĩnh Hà" },
  { value: "20", label: "Xã Vĩnh Sơn" },
  { value: "21", label: "Xã Vĩnh Giang" },
  { value: "22", label: "Xã Vĩnh Ô" },
];
export const quangtri_huyenhuonghoa = [
  { value: "1", label: "Thị trấn Khe Sanh" },
  { value: "2", label: "Thị trấn Lao Bảo" },
  { value: "3", label: "Xã Hướng Lập" },
  { value: "4", label: "Xã Hướng Việt" },
  { value: "5", label: "Xã Hướng Phùng" },
  { value: "6", label: "Xã Hướng Sơn" },
  { value: "7", label: "Xã Hướng Linh" },
  { value: "8", label: "Xã Tân Hợp" },
  { value: "9", label: "Xã Hướng Tân" },
  { value: "10", label: "Xã Tân Thành" },
  { value: "11", label: "Xã Tân Long" },
  { value: "12", label: "Xã Tân Lập" },
  { value: "13", label: "Xã Tân Liên" },
  { value: "14", label: "Xã Húc" },
  { value: "15", label: "Xã Thuận" },
  { value: "16", label: "Xã Hướng Lộc" },
  { value: "17", label: "Xã Ba Tầng" },
  { value: "18", label: "Xã Thanh" },
  { value: "19", label: "Xã A Dơi" },
  { value: "20", label: "Xã A Xing" },
  { value: "21", label: "Xã A Túc" },
  { value: "22", label: "Xã Xy" },
];
export const quangtri_huyengiolinh = [
  { value: "1", label: "Thị trấn Gio Linh" },
  { value: "2", label: "Thị trấn Cửa Việt" },
  { value: "3", label: "Xã Trung Giang" },
  { value: "4", label: "Xã Trung Hải" },
  { value: "5", label: "Xã Trung Sơn" },
  { value: "6", label: "Xã Gio Phong" },
  { value: "7", label: "Xã Gio Mỹ" },
  { value: "8", label: "Xã Vĩnh Trường" },
  { value: "9", label: "Xã Gio Bình" },
  { value: "10", label: "Xã Gio Hải" },
  { value: "11", label: "Xã Gio An" },
  { value: "12", label: "Xã Gio Châu" },
  { value: "13", label: "Xã Gio Thành" },
  { value: "14", label: "Xã Gio Việt" },
  { value: "15", label: "Xã Linh Thượng" },
  { value: "16", label: "Xã Gio Sơn" },
  { value: "17", label: "Xã Gio Hòa" },
  { value: "18", label: "Xã Gio Mai" },
  { value: "19", label: "Xã Hải Thái" },
  { value: "20", label: "Xã Linh Hải" },
  { value: "21", label: "Xã Gio Quang" },
];
export const quangtri_huyendakrong = [
  { value: "1", label: "Thị trấn Krông Klang" },
  { value: "2", label: "Xã Mò Ó" },
  { value: "3", label: "Xã Hướng Hiệp" },
  { value: "4", label: "Xã Đa Krông" },
  { value: "5", label: "Xã Triệu Nguyên" },
  { value: "6", label: "Xã Ba Lòng" },
  { value: "7", label: "Xã Hải Phúc" },
  { value: "8", label: "Xã Ba Nang" },
  { value: "9", label: "Xã Tà Long" },
  { value: "10", label: "Xã Húc Nghì" },
  { value: "11", label: "Xã A Vao" },
  { value: "12", label: "Xã Tà Rụt" },
  { value: "13", label: "Xã A Bung" },
  { value: "14", label: "Xã A Ngo" },
];
export const quangtri_huyencamlo = [
  { value: "1", label: "Thị trấn Cam Lộ" },
  { value: "2", label: "Xã Cam Tuyền" },
  { value: "3", label: "Xã Cam An" },
  { value: "4", label: "Xã Cam Thủy" },
  { value: "5", label: "Xã Cam Thanh" },
  { value: "6", label: "Xã Cam Thành" },
  { value: "7", label: "Xã Cam Hiếu" },
  { value: "8", label: "Xã Cam Chính" },
  { value: "9", label: "Xã Cam Nghĩa" },
];
export const quangtri_huyentrieuphong = [
  { value: "1", label: "Thị Trấn Ái Tử" },
  { value: "2", label: "Xã Triệu An" },
  { value: "3", label: "Xã Triệu Vân" },
  { value: "4", label: "Xã Triệu Phước" },
  { value: "5", label: "Xã Triệu Độ" },
  { value: "6", label: "Xã Triệu Trạch" },
  { value: "7", label: "Xã Triệu Thuận" },
  { value: "8", label: "Xã Triệu Đại" },
  { value: "9", label: "Xã Triệu Hòa" },
  { value: "10", label: "Xã Triệu Lăng" },
  { value: "11", label: "Xã Triệu Sơn" },
  { value: "12", label: "Xã Triệu Long" },
  { value: "13", label: "Xã Triệu Tài" },
  { value: "14", label: "Xã Triệu Đông" },
  { value: "15", label: "Xã Triệu Trung" },
  { value: "16", label: "Xã Triệu Ái" },
  { value: "17", label: "Xã Triệu Thượng" },
  { value: "18", label: "Xã Triệu Giang" },
  { value: "19", label: "Xã Triệu Thành" },
];
export const quangtri_huyenhailang = [
  { value: "1", label: "Thị trấn Hải Lăng" },
  { value: "2", label: "Xã Hải An" },
  { value: "3", label: "Xã Hải Ba" },
  { value: "4", label: "Xã Hải Xuân" },
  { value: "5", label: "Xã Hải Quy" },
  { value: "6", label: "Xã Hải Quế" },
  { value: "7", label: "Xã Hải Vĩnh" },
  { value: "8", label: "Xã Hải Phú" },
  { value: "9", label: "Xã Hải Thượng" },
  { value: "10", label: "Xã Hải Dương" },
  { value: "11", label: "Xã Hải Thiện" },
  { value: "12", label: "Xã Hải Lâm" },
  { value: "13", label: "Xã Hải Thành" },
  { value: "14", label: "Xã Hải Hòa" },
  { value: "15", label: "Xã Hải Tân" },
  { value: "16", label: "Xã Hải Trường" },
  { value: "17", label: "Xã Hải Thọ" },
  { value: "18", label: "Xã Hải Sơn" },
  { value: "19", label: "Xã Hải Chánh" },
  { value: "20", label: "Xã Hải Khê" },
];
export const thuathienhue_thanhphohue = [
  { value: "1", label: "Phường Phú Thuận" },
  { value: "2", label: "Phường Phú Bình" },
  { value: "3", label: "Phường Tây Lộc" },
  { value: "4", label: "Phường Thuận Lộc" },
  { value: "5", label: "Phường Phú Hiệp" },
  { value: "6", label: "Phường Phú Hậu" },
  { value: "7", label: "Phường Thuận Hòa" },
  { value: "8", label: "Phường Thuận Thành" },
  { value: "9", label: "Phường Phú Hòa" },
  { value: "10", label: "Phường Phú Cát" },
  { value: "11", label: "Phường Kim Long" },
  { value: "12", label: "Phường Vĩ Dạ" },
  { value: "13", label: "Phường Phường Đúc" },
  { value: "14", label: "Phường Vĩnh Ninh" },
  { value: "15", label: "Phường Phú Hội" },
  { value: "16", label: "Phường Phú Nhuận" },
  { value: "17", label: "Phường Xuân Phú" },
  { value: "18", label: "Phường Trường An" },
  { value: "19", label: "Phường Phước Vĩnh" },
  { value: "20", label: "Phường An Cựu" },
  { value: "21", label: "Phường An Hòa" },
  { value: "22", label: "Phường Hương Sơ" },
  { value: "23", label: "Phường Thuỷ Biều" },
  { value: "24", label: "Phường Hương Long" },
  { value: "25", label: "Phường Thuỷ Xuân" },
  { value: "26", label: "Phường An Đông" },
  { value: "27", label: "Phường An Tây" },
];
export const thuathienhue_huyenphongdien = [
  { value: "1", label: "Thị trấn Phong Điền" },
  { value: "2", label: "Xã Điền Hương" },
  { value: "3", label: "Xã Điền Môn" },
  { value: "4", label: "Xã Điền Lộc" },
  { value: "5", label: "Xã Phong Bình" },
  { value: "6", label: "Xã Điền Hòa" },
  { value: "7", label: "Xã Phong Chương" },
  { value: "8", label: "Xã Phong Hải" },
  { value: "9", label: "Xã Điền Hải" },
  { value: "10", label: "Xã Phong Hòa" },
  { value: "11", label: "Xã Phong Thu" },
  { value: "12", label: "Xã Phong Hiền" },
  { value: "13", label: "Xã Phong Mỹ" },
  { value: "14", label: "Xã Phong An" },
  { value: "15", label: "Xã Phong Xuân" },
  { value: "16", label: "Xã Phong Sơn" },
];
export const thuathienhue_huyenquangdien = [
  { value: "1", label: "Thị trấn Sịa" },
  { value: "2", label: "Xã Quảng Thái" },
  { value: "3", label: "Xã Quảng Ngạn" },
  { value: "4", label: "Xã Quảng Lợi" },
  { value: "5", label: "Xã Quảng Công" },
  { value: "6", label: "Xã Quảng Phước" },
  { value: "7", label: "Xã Quảng Vinh" },
  { value: "8", label: "Xã Quảng An" },
  { value: "9", label: "Xã Quảng Thành" },
  { value: "10", label: "Xã Quảng Thọ" },
  { value: "11", label: "Xã Quảng Phú" },
];
export const thuathienhue_huyenphuvang = [
  { value: "1", label: "Thị trấn Thuận An" },
  { value: "2", label: "Xã Phú Thuận" },
  { value: "3", label: "Xã Phú Dương" },
  { value: "4", label: "Xã Phú Mậu" },
  { value: "5", label: "Xã Phú An" },
  { value: "6", label: "Xã Phú Hải" },
  { value: "7", label: "Xã Phú Xuân" },
  { value: "8", label: "Xã Phú Diên" },
  { value: "9", label: "Xã Phú Thanh" },
  { value: "10", label: "Xã Phú Mỹ" },
  { value: "11", label: "Xã Phú Thượng" },
  { value: "12", label: "Xã Phú Hồ" },
  { value: "13", label: "Xã Vinh Xuân" },
  { value: "14", label: "Xã Phú Lương" },
  { value: "15", label: "Thị trấn Phú Đa" },
  { value: "16", label: "Xã Vinh Thanh" },
  { value: "17", label: "Xã Vinh An" },
  { value: "18", label: "Xã Vinh Phú" },
  { value: "19", label: "Xã Vinh Thái" },
  { value: "20", label: "Xã Vinh Hà" },
];
export const thuathienhue_xahuongthuy = [
  { value: "1", label: "Phường Phú Bài" },
  { value: "2", label: "Xã Thủy Vân" },
  { value: "3", label: "Xã Thủy Thanh" },
  { value: "4", label: "Phường Thủy Dương" },
  { value: "5", label: "Phường Thủy Phương" },
  { value: "6", label: "Phường Thủy Châu" },
  { value: "7", label: "Phường Thủy Lương" },
  { value: "8", label: "Xã Thủy Bằng" },
  { value: "9", label: "Xã Thủy Tân" },
  { value: "10", label: "Xã Thủy Phù" },
  { value: "11", label: "Xã Phú Sơn" },
  { value: "12", label: "Xã Dương Hòa" },
];
export const thuathienhue_xahuongtra = [
  { value: "1", label: "Phường Tứ Hạ" },
  { value: "2", label: "Xã Hải Dương" },
  { value: "3", label: "Xã Hương Phong" },
  { value: "4", label: "Xã Hương Toàn" },
  { value: "5", label: "Phường Hương Vân" },
  { value: "6", label: "Phường Hương Văn" },
  { value: "7", label: "Xã Hương Vinh" },
  { value: "8", label: "Phường Hương Xuân" },
  { value: "9", label: "Phường Hương Chữ" },
  { value: "10", label: "Phường Hương An" },
  { value: "11", label: "Xã Hương Bình" },
  { value: "12", label: "Phường Hương Hồ" },
  { value: "13", label: "Xã Hương Thọ" },
  { value: "14", label: "Xã Bình Điền" },
  { value: "15", label: "Xã Hồng Tiến" },
  { value: "16", label: "Xã Bình Thành" },
];
export const thuathienhue_huyenaluoi = [
  { value: "1", label: "Thị trấn A Lưới" },
  { value: "2", label: "Xã Hồng Vân" },
  { value: "3", label: "Xã Hồng Hạ" },
  { value: "4", label: "Xã Hồng Kim" },
  { value: "5", label: "Xã Hồng Trung" },
  { value: "6", label: "Xã Hương Nguyên" },
  { value: "7", label: "Xã Bắc Sơn" },
  { value: "8", label: "Xã Hồng Bắc" },
  { value: "9", label: "Xã A Ngo" },
  { value: "10", label: "Xã Sơn Thủy" },
  { value: "11", label: "Xã Phú Vinh" },
  { value: "12", label: "Xã Hồng Quảng" },
  { value: "13", label: "Xã Hương Phong" },
  { value: "14", label: "Xã Nhâm" },
  { value: "15", label: "Xã Hồng Thượng" },
  { value: "16", label: "Xã Hồng Thái" },
  { value: "17", label: "Xã Hương Lâm" },
  { value: "18", label: "Xã A Roằng" },
  { value: "19", label: "Xã Đông Sơn" },
  { value: "20", label: "Xã A Đớt" },
  { value: "21", label: "Xã Hồng Thủy" },
];
export const thuathienhue_huyenphuloc = [
  { value: "1", label: "Thị trấn Phú Lộc" },
  { value: "2", label: "Thị trấn Lăng Cô" },
  { value: "3", label: "Xã Vinh Mỹ" },
  { value: "4", label: "Xã Vinh Hưng" },
  { value: "5", label: "Xã Vinh Hải" },
  { value: "6", label: "Xã Vinh Giang" },
  { value: "7", label: "Xã Vinh Hiền" },
  { value: "8", label: "Xã Lộc Bổn" },
  { value: "9", label: "Xã Lộc Sơn" },
  { value: "10", label: "Xã Lộc Bình" },
  { value: "11", label: "Xã Lộc Vĩnh" },
  { value: "12", label: "Xã Lộc An" },
  { value: "13", label: "Xã Lộc Điền" },
  { value: "14", label: "Xã Lộc Thủy" },
  { value: "15", label: "Xã Lộc Trì" },
  { value: "16", label: "Xã Lộc Tiến" },
  { value: "17", label: "Xã Lộc Hòa" },
  { value: "18", label: "Xã Xuân Lộc" },
];
export const thuathienhue_huyennamdong = [
  { value: "1", label: "Thị trấn Khe Tre" },
  { value: "2", label: "Xã Hương Phú" },
  { value: "3", label: "Xã Hương Sơn" },
  { value: "4", label: "Xã Hương Lộc" },
  { value: "5", label: "Xã Thượng Quảng" },
  { value: "6", label: "Xã Hương Hòa" },
  { value: "7", label: "Xã Hương Giang" },
  { value: "8", label: "Xã Hương Hữu" },
  { value: "9", label: "Xã Thượng Lộ" },
  { value: "10", label: "Xã Thượng Long" },
  { value: "11", label: "Xã Thượng Nhật" },
];

export const quangnam_thanhphotamky = [
  { value: "1", label: "Phường Tân Thạnh" },
  { value: "2", label: "Phường Phước Hòa" },
  { value: "3", label: "Phường An Mỹ" },
  { value: "4", label: "Phường Hòa Hương" },
  { value: "5", label: "Phường An Xuân" },
  { value: "6", label: "Phường An Sơn" },
  { value: "7", label: "Phường Trường Xuân" },
  { value: "8", label: "Phường An Phú" },
  { value: "9", label: "Xã Tam Thanh" },
  { value: "10", label: "Xã Tam Thăng" },
  { value: "11", label: "Xã Tam Phú" },
  { value: "12", label: "Phường Hòa Thuận" },
  { value: "13", label: "Xã Tam Ngọc" },
];
export const quangnam_thanhphohoian = [
  { value: "1", label: "Phường Minh An" },
  { value: "2", label: "Phường Tân An" },
  { value: "3", label: "Phường Cẩm Phô" },
  { value: "4", label: "Phường Thanh Hà" },
  { value: "5", label: "Phường Sơn Phong" },
  { value: "6", label: "Phường Cẩm Châu" },
  { value: "7", label: "Phường Cửa Đại" },
  { value: "8", label: "Phường Cẩm An" },
  { value: "9", label: "Xã Cẩm Hà" },
  { value: "10", label: "Xã Cẩm Kim" },
  { value: "11", label: "Phường Cẩm Nam" },
  { value: "12", label: "Xã Cẩm Thanh" },
  { value: "13", label: "Xã Tân Hiệp" },
];
export const quangnam_huyentaygiang = [
  { value: "1", label: "Xã Ch'ơm" },
  { value: "2", label: "Xã Ga Ri" },
  { value: "3", label: "Xã A Xan" },
  { value: "4", label: "Xã Tr'Hy" },
  { value: "5", label: "Xã Lăng" },
  { value: "6", label: "Xã A Nông" },
  { value: "7", label: "Xã A Tiêng" },
  { value: "8", label: "Xã Bha Lê" },
  { value: "9", label: "Xã A Vương" },
  { value: "10", label: "Xã Dang" },
];
export const quangnam_huyendonggiang = [
  { value: "1", label: "Thị trấn P Rao" },
  { value: "2", label: "Xã Tà Lu" },
  { value: "3", label: "Xã Sông Kôn" },
  { value: "4", label: "Xã Jơ Ngây" },
  { value: "5", label: "Xã A Ting" },
  { value: "6", label: "Xã Tư" },
  { value: "7", label: "Xã Ba" },
  { value: "8", label: "Xã A Rooi" },
  { value: "9", label: "Xã Za Hung" },
  { value: "10", label: "Xã Mà Cooi" },
  { value: "11", label: "Xã Ka Dăng" },
];
export const quangnam_huyendailoc = [
  { value: "1", label: "Thị Trấn Ái Nghĩa" },
  { value: "2", label: "Xã Đại Sơn" },
  { value: "3", label: "Xã Đại Lãnh" },
  { value: "4", label: "Xã Đại Hưng" },
  { value: "5", label: "Xã Đại Hồng" },
  { value: "6", label: "Xã Đại Đồng" },
  { value: "7", label: "Xã Đại Quang" },
  { value: "8", label: "Xã Đại Nghĩa" },
  { value: "9", label: "Xã Đại Hiệp" },
  { value: "10", label: "Xã Đại Thạnh" },
  { value: "11", label: "Xã Đại Chánh" },
  { value: "12", label: "Xã Đại Tân" },
  { value: "13", label: "Xã Đại Phong" },
  { value: "14", label: "Xã Đại Minh" },
  { value: "15", label: "Xã Đại Thắng" },
  { value: "16", label: "Xã Đại Cường" },
  { value: "17", label: "Xã Đại An" },
  { value: "18", label: "Xã Đại Hòa" },
];
export const quangnam_xadienban = [
  { value: "1", label: "Phường Vĩnh Điện" },
  { value: "2", label: "Xã Điện Tiến" },
  { value: "3", label: "Xã Điện Hòa" },
  { value: "4", label: "Xã Điện Thắng Bắc" },
  { value: "5", label: "Xã Điện Thắng Trung" },
  { value: "6", label: "Xã Điện Thắng Nam" },
  { value: "7", label: "Phường Điện Ngọc" },
  { value: "8", label: "Xã Điện Hồng" },
  { value: "9", label: "Xã Điện Thọ" },
  { value: "10", label: "Xã Điện Phước" },
  { value: "11", label: "Phường Điện An" },
  { value: "12", label: "Phường Điện Nam Bắc" },
  { value: "13", label: "Phường Điện Nam Trung" },
  { value: "14", label: "Phường Điện Nam Đông" },
  { value: "15", label: "Phường Điện Dương" },
  { value: "16", label: "Xã Điện Quang" },
  { value: "17", label: "Xã Điện Trung" },
  { value: "18", label: "Xã Điện Phong" },
  { value: "19", label: "Xã Điện Minh" },
  { value: "20", label: "Xã Điện Phương" },
];
export const quangnam_huyenduyxuyen = [
  { value: "1", label: "Thị trấn Nam Phước" },
  { value: "2", label: "Xã Duy Thu" },
  { value: "3", label: "Xã Duy Phú" },
  { value: "4", label: "Xã Duy Tân" },
  { value: "5", label: "Xã Duy Hòa" },
  { value: "6", label: "Xã Duy Châu" },
  { value: "7", label: "Xã Duy Trinh" },
  { value: "8", label: "Xã Duy Sơn" },
  { value: "9", label: "Xã Duy Trung" },
  { value: "10", label: "Xã Duy Phước" },
  { value: "11", label: "Xã Duy Thành" },
  { value: "12", label: "Xã Duy Vinh" },
  { value: "13", label: "Xã Duy Nghĩa" },
  { value: "14", label: "Xã Duy Hải" },
];
export const quangnam_huyenqueson = [
  { value: "1", label: "Thị trấn Đông Phú" },
  { value: "2", label: "Xã Quế Xuân 1" },
  { value: "3", label: "Xã Quế Xuân 2" },
  { value: "4", label: "Xã Quế Phú" },
  { value: "5", label: "Xã Hương An" },
  { value: "6", label: "Xã Quế Cường" },
  { value: "7", label: "Xã Quế Hiệp" },
  { value: "8", label: "Xã Quế Thuận" },
  { value: "9", label: "Xã Phú Thọ" },
  { value: "10", label: "Xã Quế Long" },
  { value: "11", label: "Xã Quế Châu" },
  { value: "12", label: "Xã Quế Phong" },
  { value: "13", label: "Xã Quế An" },
  { value: "14", label: "Xã Quế Minh" },
];
export const quangnam_huyennamgiang = [
  { value: "1", label: "Thị trấn Thạnh Mỹ" },
  { value: "2", label: "Xã Laêê" },
  { value: "3", label: "Xã Chơ Chun" },
  { value: "4", label: "Xã Zuôich" },
  { value: "5", label: "Xã Tà Pơơ" },
  { value: "6", label: "Xã La Dêê" },
  { value: "7", label: "Xã Đắc Tôi" },
  { value: "8", label: "Xã Chà Vàl" },
  { value: "9", label: "Xã Tà Bhinh" },
  { value: "10", label: "Xã Cà Dy" },
  { value: "11", label: "Xã Đắc Pre" },
  { value: "12", label: "Xã Đắc Pring" },
];
export const quangnam_huyenphuocson = [
  { value: "1", label: "Thị trấn Khâm Đức" },
  { value: "2", label: "Xã Phước Xuân" },
  { value: "3", label: "Xã Phước Hiệp" },
  { value: "4", label: "Xã Phước Hòa" },
  { value: "5", label: "Xã Phước Đức" },
  { value: "6", label: "Xã Phước Năng" },
  { value: "7", label: "Xã Phước Mỹ" },
  { value: "8", label: "Xã Phước Chánh" },
  { value: "9", label: "Xã Phước Công" },
  { value: "10", label: "Xã Phước Kim" },
  { value: "11", label: "Xã Phước Lộc" },
  { value: "12", label: "Xã Phước Thành" },
];
export const quangnam_huyenhiepduc = [
  { value: "1", label: "Thị trấn Tân An" },
  { value: "2", label: "Xã Hiệp Hòa" },
  { value: "3", label: "Xã Hiệp Thuận" },
  { value: "4", label: "Xã Quế Thọ" },
  { value: "5", label: "Xã Bình Lâm" },
  { value: "6", label: "Xã Sông Trà" },
  { value: "7", label: "Xã Phước Trà" },
  { value: "8", label: "Xã Phước Gia" },
  { value: "9", label: "Xã Quế Bình" },
  { value: "10", label: "Xã Quế Lưu" },
  { value: "11", label: "Xã Thăng Phước" },
  { value: "12", label: "Xã Bình Sơn" },
];
export const quangnam_huyenthangbinh = [
  { value: "1", label: "Thị trấn Hà Lam" },
  { value: "2", label: "Xã Bình Dương" },
  { value: "3", label: "Xã Bình Giang" },
  { value: "4", label: "Xã Bình Nguyên" },
  { value: "5", label: "Xã Bình Phục" },
  { value: "6", label: "Xã Bình Triều" },
  { value: "7", label: "Xã Bình Đào" },
  { value: "8", label: "Xã Bình Minh" },
  { value: "9", label: "Xã Bình Lãnh" },
  { value: "10", label: "Xã Bình Trị" },
  { value: "11", label: "Xã Bình Định Bắc" },
  { value: "12", label: "Xã Bình Định Nam" },
  { value: "13", label: "Xã Bình Quý" },
  { value: "14", label: "Xã Bình Phú" },
  { value: "15", label: "Xã Bình Chánh" },
  { value: "16", label: "Xã Bình Tú" },
  { value: "17", label: "Xã Bình Sa" },
  { value: "18", label: "Xã Bình Hải" },
  { value: "19", label: "Xã Bình Quế" },
  { value: "20", label: "Xã Bình An" },
  { value: "21", label: "Xã Bình Trung" },
  { value: "22", label: "Xã Bình Nam" },
];
export const quangnam_huyentienphuoc = [
  { value: "1", label: "Thị trấn Tiên Kỳ" },
  { value: "2", label: "Xã Tiên Sơn" },
  { value: "3", label: "Xã Tiên Hà" },
  { value: "4", label: "Xã Tiên Cẩm" },
  { value: "5", label: "Xã Tiên Châu" },
  { value: "6", label: "Xã Tiên Lãnh" },
  { value: "7", label: "Xã Tiên Ngọc" },
  { value: "8", label: "Xã Tiên Hiệp" },
  { value: "9", label: "Xã Tiên Cảnh" },
  { value: "10", label: "Xã Tiên Mỹ" },
  { value: "11", label: "Xã Tiên Phong" },
  { value: "12", label: "Xã Tiên Thọ" },
  { value: "13", label: "Xã Tiên An" },
  { value: "14", label: "Xã Tiên Lộc" },
  { value: "15", label: "Xã Tiên Lập" },
];
export const quangnam_huyenbactramy = [
  { value: "1", label: "Thị trấn Trà My" },
  { value: "2", label: "Xã Trà Sơn" },
  { value: "3", label: "Xã Trà Kót" },
  { value: "4", label: "Xã Trà Nú" },
  { value: "5", label: "Xã Trà Đông" },
  { value: "6", label: "Xã Trà Dương" },
  { value: "7", label: "Xã Trà Giang" },
  { value: "8", label: "Xã Trà Bui" },
  { value: "9", label: "Xã Trà Đốc" },
  { value: "10", label: "Xã Trà Tân" },
  { value: "11", label: "Xã Trà Giác" },
  { value: "12", label: "Xã Trà Giáp" },
  { value: "13", label: "Xã Trà Ka" },
];
export const quangnam_huyennamtramy = [
  { value: "1", label: "Xã Trà Leng" },
  { value: "2", label: "Xã Trà Dơn" },
  { value: "3", label: "Xã Trà Tập" },
  { value: "4", label: "Xã Trà Mai" },
  { value: "5", label: "Xã Trà Cang" },
  { value: "6", label: "Xã Trà Linh" },
  { value: "7", label: "Xã Trà Nam" },
  { value: "8", label: "Xã Trà Don" },
  { value: "9", label: "Xã Trà Vân" },
  { value: "10", label: "Xã Trà Vinh" },
];
export const quangnam_huyennuithanh = [
  { value: "1", label: "Thị trấn Núi Thành" },
  { value: "2", label: "Xã Tam Xuân I" },
  { value: "3", label: "Xã Tam Xuân II" },
  { value: "4", label: "Xã Tam Tiến" },
  { value: "5", label: "Xã Tam Sơn" },
  { value: "6", label: "Xã Tam Thạnh" },
  { value: "7", label: "Xã Tam Anh Bắc" },
  { value: "8", label: "Xã Tam Anh Nam" },
  { value: "9", label: "Xã Tam Hòa" },
  { value: "10", label: "Xã Tam Hiệp" },
  { value: "11", label: "Xã Tam Hải" },
  { value: "12", label: "Xã Tam Giang" },
  { value: "13", label: "Xã Tam Quang" },
  { value: "14", label: "Xã Tam Nghĩa" },
  { value: "15", label: "Xã Tam Mỹ Tây" },
  { value: "16", label: "Xã Tam Mỹ Đông" },
  { value: "17", label: "Xã Tam Trà" },
];
export const quangnam_huyenphuninh = [
  { value: "1", label: "Thị trấn Phú Thịnh" },
  { value: "2", label: "Xã Tam Thành" },
  { value: "3", label: "Xã Tam An" },
  { value: "4", label: "Xã Tam Đàn" },
  { value: "5", label: "Xã Tam Lộc" },
  { value: "6", label: "Xã Tam Phước" },
  { value: "7", label: "Xã Tam Vinh" },
  { value: "8", label: "Xã Tam Thái" },
  { value: "9", label: "Xã Tam Đại" },
  { value: "10", label: "Xã Tam Dân" },
  { value: "11", label: "Xã Tam Lãnh" },
];
export const quangnam_huyennongson = [
  { value: "1", label: "Xã Quế Trung" },
  { value: "2", label: "Xã Quế Ninh" },
  { value: "3", label: "Xã Phước Ninh" },
  { value: "4", label: "Xã Quế Lộc" },
  { value: "5", label: "Xã Sơn Viên" },
  { value: "6", label: "Xã Quế Phước" },
  { value: "7", label: "Xã Quế Lâm" },
];
export const quangngai_thanhphoquangngai = [
  { value: "1", label: "Phường Lê Hồng Phong" },
  { value: "2", label: "Phường Trần Phú" },
  { value: "3", label: "Phường Quảng Phú" },
  { value: "4", label: "Phường Nghĩa Chánh" },
  { value: "5", label: "Phường Trần Hưng Đạo" },
  { value: "6", label: "Phường Nguyễn Nghiêm" },
  { value: "7", label: "Phường Nghĩa Lộ" },
  { value: "8", label: "Phường Chánh Lộ" },
  { value: "9", label: "Xã Nghĩa Dũng" },
  { value: "10", label: "Xã Nghĩa Dõng" },
  { value: "11", label: "Phường Trương Quang Trọng" },
  { value: "12", label: "Xã Tịnh Hòa" },
  { value: "13", label: "Xã Tịnh Kỳ" },
  { value: "14", label: "Xã Tịnh Thiện" },
  { value: "15", label: "Xã Tịnh Ấn Đông" },
  { value: "16", label: "Xã Tịnh Châu" },
  { value: "17", label: "Xã Tịnh Khê" },
  { value: "18", label: "Xã Tịnh Long" },
  { value: "19", label: "Xã Tịnh Ấn Tây" },
  { value: "20", label: "Xã Tịnh An" },
  { value: "21", label: "Xã Nghĩa Phú" },
  { value: "22", label: "Xã Nghĩa Hà" },
  { value: "23", label: "Xã Nghĩa An" },
];
export const quangngai_huyenbinhson = [
  { value: "1", label: "Thị Trấn Châu Ổ" },
  { value: "2", label: "Xã Bình Thuận" },
  { value: "3", label: "Xã Bình Thạnh" },
  { value: "4", label: "Xã Bình Đông" },
  { value: "5", label: "Xã Bình Chánh" },
  { value: "6", label: "Xã Bình Nguyên" },
  { value: "7", label: "Xã Bình Khương" },
  { value: "8", label: "Xã Bình Trị" },
  { value: "9", label: "Xã Bình An" },
  { value: "10", label: "Xã Bình Hải" },
  { value: "11", label: "Xã Bình Dương" },
  { value: "12", label: "Xã Bình Phước" },
  { value: "13", label: "Xã Bình Thới" },
  { value: "14", label: "Xã Bình Hòa" },
  { value: "15", label: "Xã Bình Trung" },
  { value: "16", label: "Xã Bình Minh" },
  { value: "17", label: "Xã Bình Long" },
  { value: "18", label: "Xã Bình Thanh Tây" },
  { value: "19", label: "Xã Bình Phú" },
  { value: "20", label: "Xã Bình Thanh Đông" },
  { value: "21", label: "Xã Bình Chương" },
  { value: "22", label: "Xã Bình Hiệp" },
  { value: "23", label: "Xã Bình Mỹ" },
  { value: "24", label: "Xã Bình Tân" },
  { value: "25", label: "Xã Bình Châu" },
];
export const quangngai_huyentrabong = [
  { value: "1", label: "Thị trấn Trà Xuân" },
  { value: "2", label: "Xã Trà Giang" },
  { value: "3", label: "Xã Trà Thủy" },
  { value: "4", label: "Xã Trà Hiệp" },
  { value: "5", label: "Xã Trà Bình" },
  { value: "6", label: "Xã Trà Phú" },
  { value: "7", label: "Xã Trà Lâm" },
  { value: "8", label: "Xã Trà Tân" },
  { value: "9", label: "Xã Trà Sơn" },
  { value: "10", label: "Xã Trà Bùi" },
];
export const quangngai_huyentaytra = [
  { value: "1", label: "Xã Trà Thanh" },
  { value: "2", label: "Xã Trà Khê" },
  { value: "3", label: "Xã Trà Quân" },
  { value: "4", label: "Xã Trà Phong" },
  { value: "5", label: "Xã Trà Lãnh" },
  { value: "6", label: "Xã Trà Nham" },
  { value: "7", label: "Xã Trà Xinh" },
  { value: "8", label: "Xã Trà Thọ" },
  { value: "9", label: "Xã Trà Trung" },
];
export const quangngai_huyensontinh = [
  { value: "1", label: "Xã Tịnh Thọ" },
  { value: "2", label: "Xã Tịnh Trà" },
  { value: "3", label: "Xã Tịnh Phong" },
  { value: "4", label: "Xã Tịnh Hiệp" },
  { value: "5", label: "Xã Tịnh Bình" },
  { value: "6", label: "Xã Tịnh Đông" },
  { value: "7", label: "Xã Tịnh Bắc" },
  { value: "8", label: "Xã Tịnh Sơn" },
  { value: "9", label: "Xã Tịnh Hà" },
  { value: "10", label: "Xã Tịnh Giang" },
  { value: "11", label: "Xã Tịnh Minh" },
];
export const quangngai_huyentunghia = [
  { value: "1", label: "Thị trấn La Hà" },
  { value: "2", label: "Thị trấn Sông Vệ" },
  { value: "3", label: "Xã Nghĩa Lâm" },
  { value: "4", label: "Xã Nghĩa Thắng" },
  { value: "5", label: "Xã Nghĩa Thuận" },
  { value: "6", label: "Xã Nghĩa Kỳ" },
  { value: "7", label: "Xã Nghĩa Sơn" },
  { value: "8", label: "Xã Nghĩa Thọ" },
  { value: "9", label: "Xã Nghĩa Hòa" },
  { value: "10", label: "Xã Nghĩa Điền" },
  { value: "11", label: "Xã Nghĩa Thương" },
  { value: "12", label: "Xã Nghĩa Trung" },
  { value: "13", label: "Xã Nghĩa Hiệp" },
  { value: "14", label: "Xã Nghĩa Phương" },
  { value: "15", label: "Xã Nghĩa Mỹ" },
];
export const quangngai_huyensonha = [
  { value: "1", label: "Thị trấn Di Lăng" },
  { value: "2", label: "Xã Sơn Hạ" },
  { value: "3", label: "Xã Sơn Thành" },
  { value: "4", label: "Xã Sơn Nham" },
  { value: "5", label: "Xã Sơn Bao" },
  { value: "6", label: "Xã Sơn Linh" },
  { value: "7", label: "Xã Sơn Giang" },
  { value: "8", label: "Xã Sơn Trung" },
  { value: "9", label: "Xã Sơn Thượng" },
  { value: "10", label: "Xã Sơn Cao" },
  { value: "11", label: "Xã Sơn Hải" },
  { value: "12", label: "Xã Sơn Thủy" },
  { value: "13", label: "Xã Sơn Kỳ" },
  { value: "14", label: "Xã Sơn Ba" },
];
export const quangngai_huyensontay = [
  { value: "1", label: "Xã Sơn Bua" },
  { value: "2", label: "Xã Sơn Mùa" },
  { value: "3", label: "Xã Sơn Liên" },
  { value: "4", label: "Xã Sơn Tân" },
  { value: "5", label: "Xã Sơn Màu" },
  { value: "6", label: "Xã Sơn Dung" },
  { value: "7", label: "Xã Sơn Long" },
  { value: "8", label: "Xã Sơn Tinh" },
  { value: "9", label: "Xã Sơn Lập" },
];
export const quangngai_huyenminhlong = [
  { value: "1", label: "Xã Long Sơn" },
  { value: "2", label: "Xã Long Mai" },
  { value: "3", label: "Xã Thanh An" },
  { value: "4", label: "Xã Long Môn" },
  { value: "5", label: "Xã Long Hiệp" },
];
export const quangngai_huyennghiahanh = [
  { value: "1", label: "Thị trấn Chợ Chùa" },
  { value: "2", label: "Xã Hành Thuận" },
  { value: "3", label: "Xã Hành Dũng" },
  { value: "4", label: "Xã Hành Trung" },
  { value: "5", label: "Xã Hành Nhân" },
  { value: "6", label: "Xã Hành Đức" },
  { value: "7", label: "Xã Hành Minh" },
  { value: "8", label: "Xã Hành Phước" },
  { value: "9", label: "Xã Hành Thiện" },
  { value: "10", label: "Xã Hành Thịnh" },
  { value: "11", label: "Xã Hành Tín Tây" },
  { value: "12", label: "Xã Hành Tín Đông" },
];
export const quangngai_huyenmoduc = [
  { value: "1", label: "Thị trấn Mộ Đức" },
  { value: "2", label: "Xã Đức Lợi" },
  { value: "3", label: "Xã Đức Thắng" },
  { value: "4", label: "Xã Đức Nhuận" },
  { value: "5", label: "Xã Đức Chánh" },
  { value: "6", label: "Xã Đức Hiệp" },
  { value: "7", label: "Xã Đức Minh" },
  { value: "8", label: "Xã Đức Thạnh" },
  { value: "9", label: "Xã Đức Hòa" },
  { value: "10", label: "Xã Đức Tân" },
  { value: "11", label: "Xã Đức Phú" },
  { value: "12", label: "Xã Đức Phong" },
  { value: "13", label: "Xã Đức Lân" },
];
export const quangngai_huyenducpho = [
  { value: "1", label: "Thị trấn Đức Phổ" },
  { value: "2", label: "Xã Phổ An" },
  { value: "3", label: "Xã Phổ Phong" },
  { value: "4", label: "Xã Phổ Thuận" },
  { value: "5", label: "Xã Phổ Văn" },
  { value: "6", label: "Xã Phổ Quang" },
  { value: "7", label: "Xã Phổ Nhơn" },
  { value: "8", label: "Xã Phổ Ninh" },
  { value: "9", label: "Xã Phổ Minh" },
  { value: "10", label: "Xã Phổ Vinh" },
  { value: "11", label: "Xã Phổ Hòa" },
  { value: "12", label: "Xã Phổ Cường" },
  { value: "13", label: "Xã Phổ Khánh" },
  { value: "14", label: "Xã Phổ Thạnh" },
  { value: "15", label: "Xã Phổ Châu" },
];
export const quangngai_huyenbato = [
  { value: "1", label: "Thị trấn Ba Tơ" },
  { value: "2", label: "Xã Ba Điền" },
  { value: "3", label: "Xã Ba Vinh" },
  { value: "4", label: "Xã Ba Thành" },
  { value: "5", label: "Xã Ba Động" },
  { value: "6", label: "Xã Ba Dinh" },
  { value: "7", label: "Xã Ba Giang" },
  { value: "8", label: "Xã Ba Liên" },
  { value: "9", label: "Xã Ba Ngạc" },
  { value: "10", label: "Xã Ba Khâm" },
  { value: "11", label: "Xã Ba Cung" },
  { value: "12", label: "Xã Ba Chùa" },
  { value: "13", label: "Xã Ba Tiêu" },
  { value: "14", label: "Xã Ba Trang" },
  { value: "15", label: "Xã Ba Tô" },
  { value: "16", label: "Xã Ba Bích" },
  { value: "17", label: "Xã Ba Vì" },
  { value: "18", label: "Xã Ba Lế" },
  { value: "19", label: "Xã Ba Nam" },
  { value: "20", label: "Xã Ba Xa" },
];
export const quangngai_huyenlyson = [
  { value: "1", label: "Xã An Vĩnh" },
  { value: "2", label: "Xã An Hải" },
  { value: "3", label: "Xã An Bình" },
];
export const binhdinh_thanhphoquinhon = [
  { value: "1", label: "Phường Nhơn Bình" },
  { value: "2", label: "Phường Nhơn Phú" },
  { value: "3", label: "Phường Đống Đa" },
  { value: "4", label: "Phường Trần Quang Diệu" },
  { value: "5", label: "Phường Hải Cảng" },
  { value: "6", label: "Phường Quang Trung" },
  { value: "7", label: "Phường Thị Nại" },
  { value: "8", label: "Phường Lê Hồng Phong" },
  { value: "9", label: "Phường Trần Hưng Đạo" },
  { value: "10", label: "Phường Ngô Mây" },
  { value: "11", label: "Phường Lý Thường Kiệt" },
  { value: "12", label: "Phường Lê Lợi" },
  { value: "13", label: "Phường Trần Phú" },
  { value: "14", label: "Phường Bùi Thị Xuân" },
  { value: "15", label: "Phường Nguyễn Văn Cừ" },
  { value: "16", label: "Phường Ghềnh Ráng" },
  { value: "17", label: "Xã Nhơn Lý" },
  { value: "18", label: "Xã Nhơn Hội" },
  { value: "19", label: "Xã Nhơn Hải" },
  { value: "20", label: "Xã Nhơn Châu" },
  { value: "21", label: "Xã Phước Mỹ" },
];
export const binhdinh_huyenanlao = [
  { value: "1", label: "Thị trấn An Lão" },
  { value: "2", label: "Xã An Hưng" },
  { value: "3", label: "Xã An Trung" },
  { value: "4", label: "Xã An Dũng" },
  { value: "5", label: "Xã An Vinh" },
  { value: "6", label: "Xã An Toàn" },
  { value: "7", label: "Xã An Tân" },
  { value: "8", label: "Xã An Hòa" },
  { value: "9", label: "Xã An Quang" },
  { value: "10", label: "Xã An Nghĩa" },
];
export const binhdinh_huyenhoainhon = [
  { value: "1", label: "Thị trấn Tam Quan" },
  { value: "2", label: "Thị trấn Bồng Sơn" },
  { value: "3", label: "Xã Hoài Sơn" },
  { value: "4", label: "Xã Hoài Châu Bắc" },
  { value: "5", label: "Xã Hoài Châu" },
  { value: "6", label: "Xã Hoài Phú" },
  { value: "7", label: "Xã Tam Quan Bắc" },
  { value: "8", label: "Xã Tam Quan Nam" },
  { value: "9", label: "Xã Hoài Hảo" },
  { value: "10", label: "Xã Hoài Thanh Tây" },
  { value: "11", label: "Xã Hoài Thanh" },
  { value: "12", label: "Xã Hoài Hương" },
  { value: "13", label: "Xã Hoài Tân" },
  { value: "14", label: "Xã Hoài Hải" },
  { value: "15", label: "Xã Hoài Xuân" },
  { value: "16", label: "Xã Hoài Mỹ" },
  { value: "17", label: "Xã Hoài Đức" },
];
export const binhdinh_huyenhoaian = [
  { value: "1", label: "Thị trấn Tăng Bạt Hổ" },
  { value: "2", label: "Xã Ân Hảo Tây" },
  { value: "3", label: "Xã Ân Hảo Đông" },
  { value: "4", label: "Xã Ân Sơn" },
  { value: "5", label: "Xã Ân Mỹ" },
  { value: "6", label: "Xã Dak Mang" },
  { value: "7", label: "Xã Ân Tín" },
  { value: "8", label: "Xã Ân Thạnh" },
  { value: "9", label: "Xã Ân Phong" },
  { value: "10", label: "Xã Ân Đức" },
  { value: "11", label: "Xã Ân Hữu" },
  { value: "12", label: "Xã Bok Tới" },
  { value: "13", label: "Xã Ân Tường Tây" },
  { value: "14", label: "Xã Ân Tường Đông" },
  { value: "15", label: "Xã Ân Nghĩa" },
];
export const binhdinh_huyenphumy = [
  { value: "1", label: "Thị trấn Phù Mỹ" },
  { value: "2", label: "Thị trấn Bình Dương" },
  { value: "3", label: "Xã Mỹ Đức" },
  { value: "4", label: "Xã Mỹ Châu" },
  { value: "5", label: "Xã Mỹ Thắng" },
  { value: "6", label: "Xã Mỹ Lộc" },
  { value: "7", label: "Xã Mỹ Lợi" },
  { value: "8", label: "Xã Mỹ An" },
  { value: "9", label: "Xã Mỹ Phong" },
  { value: "10", label: "Xã Mỹ Trinh" },
  { value: "11", label: "Xã Mỹ Thọ" },
  { value: "12", label: "Xã Mỹ Hòa" },
  { value: "13", label: "Xã Mỹ Thành" },
  { value: "14", label: "Xã Mỹ Chánh" },
  { value: "15", label: "Xã Mỹ Quang" },
  { value: "16", label: "Xã Mỹ Hiệp" },
  { value: "17", label: "Xã Mỹ Tài" },
  { value: "18", label: "Xã Mỹ Cát" },
  { value: "19", label: "Xã Mỹ Chánh Tây" },
];
export const binhdinh_huyenvinhthanh = [
  { value: "1", label: "Thị trấn Vĩnh Thạnh" },
  { value: "2", label: "Xã Vĩnh Sơn" },
  { value: "3", label: "Xã Vĩnh Kim" },
  { value: "4", label: "Xã Vĩnh Hiệp" },
  { value: "5", label: "Xã Vĩnh Hảo" },
  { value: "6", label: "Xã Vĩnh Hòa" },
  { value: "7", label: "Xã Vĩnh Thịnh" },
  { value: "8", label: "Xã Vĩnh Thuận" },
  { value: "9", label: "Xã Vĩnh Quang" },
];
export const binhdinh_huyentayson = [
  { value: "1", label: "Thị trấn Phú Phong" },
  { value: "2", label: "Xã Bình Tân" },
  { value: "3", label: "Xã Tây Thuận" },
  { value: "4", label: "Xã Bình Thuận" },
  { value: "5", label: "Xã Tây Giang" },
  { value: "6", label: "Xã Bình Thành" },
  { value: "7", label: "Xã Tây An" },
  { value: "8", label: "Xã Bình Hòa" },
  { value: "9", label: "Xã Tây Bình" },
  { value: "10", label: "Xã Bình Tường" },
  { value: "11", label: "Xã Tây Vinh" },
  { value: "12", label: "Xã Vĩnh An" },
  { value: "13", label: "Xã Tây Xuân" },
  { value: "14", label: "Xã Bình Nghi" },
  { value: "15", label: "Xã Tây Phú" },
];
export const binhdinh_huyenphucat = [
  { value: "1", label: "Thị trấn Ngô Mây" },
  { value: "2", label: "Xã Cát Sơn" },
  { value: "3", label: "Xã Cát Minh" },
  { value: "4", label: "Xã Cát Khánh" },
  { value: "5", label: "Xã Cát Tài" },
  { value: "6", label: "Xã Cát Lâm" },
  { value: "7", label: "Xã Cát Hanh" },
  { value: "8", label: "Xã Cát Thành" },
  { value: "9", label: "Xã Cát Trinh" },
  { value: "10", label: "Xã Cát Hải" },
  { value: "11", label: "Xã Cát Hiệp" },
  { value: "12", label: "Xã Cát Nhơn" },
  { value: "13", label: "Xã Cát Hưng" },
  { value: "14", label: "Xã Cát Tường" },
  { value: "15", label: "Xã Cát Tân" },
  { value: "16", label: "Xã Cát Tiến" },
  { value: "17", label: "Xã Cát Thắng" },
  { value: "18", label: "Xã Cát Chánh" },
];
export const binhdinh_xaannhon = [
  { value: "1", label: "Phường Bình Định" },
  { value: "2", label: "Phường Đập Đá" },
  { value: "3", label: "Xã Nhơn Mỹ" },
  { value: "4", label: "Phường Nhơn Thành" },
  { value: "5", label: "Xã Nhơn Hạnh" },
  { value: "6", label: "Xã Nhơn Hậu" },
  { value: "7", label: "Xã Nhơn Phong" },
  { value: "8", label: "Xã Nhơn An" },
  { value: "9", label: "Xã Nhơn Phúc" },
  { value: "10", label: "Phường Nhơn Hưng" },
  { value: "11", label: "Xã Nhơn Khánh" },
  { value: "12", label: "Xã Nhơn Lộc" },
  { value: "13", label: "Phường Nhơn Hòa" },
  { value: "14", label: "Xã Nhơn Tân" },
  { value: "15", label: "Xã Nhơn Thọ" },
];
export const binhdinh_huyentuyphuoc = [
  { value: "1", label: "Thị trấn Tuy Phước" },
  { value: "2", label: "Thị trấn Diêu Trì" },
  { value: "3", label: "Xã Phước Thắng" },
  { value: "4", label: "Xã Phước Hưng" },
  { value: "5", label: "Xã Phước Quang" },
  { value: "6", label: "Xã Phước Hòa" },
  { value: "7", label: "Xã Phước Sơn" },
  { value: "8", label: "Xã Phước Hiệp" },
  { value: "9", label: "Xã Phước Lộc" },
  { value: "10", label: "Xã Phước Nghĩa" },
  { value: "11", label: "Xã Phước Thuận" },
  { value: "12", label: "Xã Phước An" },
  { value: "13", label: "Xã Phước Thành" },
];
export const binhdinh_huyenvancanh = [
  { value: "1", label: "Thị trấn Vân Canh" },
  { value: "2", label: "Xã Canh Liên" },
  { value: "3", label: "Xã Canh Hiệp" },
  { value: "4", label: "Xã Canh Vinh" },
  { value: "5", label: "Xã Canh Hiển" },
  { value: "6", label: "Xã Canh Thuận" },
  { value: "7", label: "Xã Canh Hòa" },
];
export const phuyen_thanhphotuyhoa = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường 8" },
  { value: "3", label: "Phường 2" },
  { value: "4", label: "Phường 9" },
  { value: "5", label: "Phường 3" },
  { value: "6", label: "Phường 4" },
  { value: "7", label: "Phường 5" },
  { value: "8", label: "Phường 7" },
  { value: "9", label: "Phường 6" },
  { value: "10", label: "Phường Phú Thạnh" },
  { value: "11", label: "Phường Phú Đông" },
  { value: "12", label: "Xã Hòa Kiến" },
  { value: "13", label: "Xã Bình Kiến" },
  { value: "14", label: "Xã Bình Ngọc" },
  { value: "15", label: "Xã An Phú" },
  { value: "16", label: "Phường Phú Lâm" },
];
export const phuyen_xasongcau = [
  { value: "1", label: "Phường Xuân Phú" },
  { value: "2", label: "Xã Xuân Lâm" },
  { value: "3", label: "Phường Xuân Thành" },
  { value: "4", label: "Xã Xuân Hải" },
  { value: "5", label: "Xã Xuân Lộc" },
  { value: "6", label: "Xã Xuân Bình" },
  { value: "7", label: "Xã Xuân Hòa" },
  { value: "8", label: "Xã Xuân Cảnh" },
  { value: "9", label: "Xã Xuân Thịnh" },
  { value: "10", label: "Xã Xuân Phương" },
  { value: "11", label: "Phường Xuân Yên" },
  { value: "12", label: "Xã Xuân Thọ 1" },
  { value: "13", label: "Phường Xuân Đài" },
  { value: "14", label: "Xã Xuân Thọ 2" },
];
export const phuyen_huyendongxuan = [
  { value: "1", label: "Thị trấn La Hai" },
  { value: "2", label: "Xã Đa Lộc" },
  { value: "3", label: "Xã Phú Mỡ" },
  { value: "4", label: "Xã Xuân Lãnh" },
  { value: "5", label: "Xã Xuân Long" },
  { value: "6", label: "Xã Xuân Quang 1" },
  { value: "7", label: "Xã Xuân Sơn Bắc" },
  { value: "8", label: "Xã Xuân Quang 2" },
  { value: "9", label: "Xã Xuân Sơn Nam" },
  { value: "10", label: "Xã Xuân Quang 3" },
  { value: "11", label: "Xã Xuân Phước" },
];
export const phuyen_huyentuyan = [
  { value: "1", label: "Thị trấn Chí Thạnh" },
  { value: "2", label: "Xã An Dân" },
  { value: "3", label: "Xã An Ninh Tây" },
  { value: "4", label: "Xã An Ninh Đông" },
  { value: "5", label: "Xã An Thạch" },
  { value: "6", label: "Xã An Định" },
  { value: "7", label: "Xã An Nghiệp" },
  { value: "8", label: "Xã An Hải" },
  { value: "9", label: "Xã An Cư" },
  { value: "10", label: "Xã An Xuân" },
  { value: "11", label: "Xã An Lĩnh" },
  { value: "12", label: "Xã An Hòa" },
  { value: "13", label: "Xã An Hiệp" },
  { value: "14", label: "Xã An Mỹ" },
  { value: "15", label: "Xã An Chấn" },
  { value: "16", label: "Xã An Thọ" },
];
export const phuyen_huyensonhoa = [
  { value: "1", label: "Thị trấn Củng Sơn" },
  { value: "2", label: "Xã Phước Tân" },
  { value: "3", label: "Xã Sơn Hội" },
  { value: "4", label: "Xã Sơn Định" },
  { value: "5", label: "Xã Sơn Long" },
  { value: "6", label: "Xã Cà Lúi" },
  { value: "7", label: "Xã Sơn Phước" },
  { value: "8", label: "Xã Sơn Xuân" },
  { value: "9", label: "Xã Sơn Nguyên" },
  { value: "10", label: "Xã Eachà Rang" },
  { value: "11", label: "Xã Krông Pa" },
  { value: "12", label: "Xã Suối Bạc" },
  { value: "13", label: "Xã Sơn Hà" },
  { value: "14", label: "Xã Suối Trai" },
];
export const phuyen_huyensonghinh = [
  { value: "1", label: "Thị trấn Hai Riêng" },
  { value: "2", label: "Xã Ea Lâm" },
  { value: "3", label: "Xã Đức Bình Tây" },
  { value: "4", label: "Xã Ea Bá" },
  { value: "5", label: "Xã Sơn Giang" },
  { value: "6", label: "Xã Đức Bình Đông" },
  { value: "7", label: "Xã EaBar" },
  { value: "8", label: "Xã EaBia" },
  { value: "9", label: "Xã EaTrol" },
  { value: "10", label: "Xã Sông Hinh" },
  { value: "11", label: "Xã Ealy" },
];
export const phuyen_huyentayhoa = [
  { value: "1", label: "Xã Sơn Thành Tây" },
  { value: "2", label: "Xã Sơn Thành Đông" },
  { value: "3", label: "Xã Hòa Bình 1" },
  { value: "4", label: "Thị trấn Phú Thứ" },
  { value: "5", label: "Xã Hòa Phong" },
  { value: "6", label: "Xã Hòa Phú" },
  { value: "7", label: "Xã Hòa Tân Tây" },
  { value: "8", label: "Xã Hòa Đồng" },
  { value: "9", label: "Xã Hòa Mỹ Đông" },
  { value: "10", label: "Xã Hòa Mỹ Tây" },
  { value: "11", label: "Xã Hòa Thịnh" },
];
export const phuyen_huyenphuhoa = [
  { value: "1", label: "Xã Hòa Quang Bắc" },
  { value: "2", label: "Xã Hòa Quang Nam" },
  { value: "3", label: "Xã Hòa Hội" },
  { value: "4", label: "Xã Hòa Trị" },
  { value: "5", label: "Xã Hòa An" },
  { value: "6", label: "Xã Hòa Định Đông" },
  { value: "7", label: "Thị Trấn Phú Hòa" },
  { value: "8", label: "Xã Hòa Định Tây" },
  { value: "9", label: "Xã Hòa Thắng" },
];
export const phuyen_huyendonghoa = [
  { value: "1", label: "Xã Hòa Thành" },
  { value: "2", label: "Xã Hòa Hiệp Bắc" },
  { value: "3", label: "Thị trấn Hòa Vinh" },
  { value: "4", label: "Thị trấn Hòa Hiệp Trung" },
  { value: "5", label: "Xã Hòa Tân Đông" },
  { value: "6", label: "Xã Hòa Xuân Tây" },
  { value: "7", label: "Xã Hòa Hiệp Nam" },
  { value: "8", label: "Xã Hòa Xuân Đông" },
  { value: "9", label: "Xã Hòa Tâm" },
  { value: "10", label: "Xã Hòa Xuân Nam" },
];
export const khanhhoa_thanhphonhatrang = [
  { value: "1", label: "Phường Vĩnh Hòa" },
  { value: "2", label: "Phường Vĩnh Hải" },
  { value: "3", label: "Phường Vĩnh Phước" },
  { value: "4", label: "Phường Ngọc Hiệp" },
  { value: "5", label: "Phường Vĩnh Thọ" },
  { value: "6", label: "Phường Xương Huân" },
  { value: "7", label: "Phường Vạn Thắng" },
  { value: "8", label: "Phường Vạn Thạnh" },
  { value: "9", label: "Phường Phương Sài" },
  { value: "10", label: "Phường Phương Sơn" },
  { value: "11", label: "Phường Phước Hải" },
  { value: "12", label: "Phường Phước Tân" },
  { value: "13", label: "Phường Lộc Thọ" },
  { value: "14", label: "Phường Phước Tiến" },
  { value: "15", label: "Phường Tân Lập" },
  { value: "16", label: "Phường Phước Hòa" },
  { value: "17", label: "Phường Vĩnh Nguyên" },
  { value: "18", label: "Phường Phước Long" },
  { value: "19", label: "Phường Vĩnh Trường" },
  { value: "20", label: "Xã Vĩnh Lương" },
  { value: "21", label: "Xã Vĩnh Phương" },
  { value: "22", label: "Xã Vĩnh Ngọc" },
  { value: "23", label: "Xã Vĩnh Thạnh" },
  { value: "24", label: "Xã Vĩnh Trung" },
  { value: "25", label: "Xã Vĩnh Hiệp" },
  { value: "26", label: "Xã Vĩnh Thái" },
  { value: "27", label: "Xã Phước Đồng" },
];
export const khanhhoa_thanhphocamranh = [
  { value: "1", label: "Phường Cam Nghĩa" },
  { value: "2", label: "Phường Cam Phúc Bắc" },
  { value: "3", label: "Phường Cam Phúc Nam" },
  { value: "4", label: "Phường Cam Lộc" },
  { value: "5", label: "Phường Cam Phú" },
  { value: "6", label: "Phường Ba Ngòi" },
  { value: "7", label: "Phường Cam Thuận" },
  { value: "8", label: "Phường Cam Lợi" },
  { value: "9", label: "Phường Cam Linh" },
  { value: "10", label: "Xã Cam Thành Nam" },
  { value: "11", label: "Xã Cam Phước Đông" },
  { value: "12", label: "Xã Cam Thịnh Tây" },
  { value: "13", label: "Xã Cam Thịnh Đông" },
  { value: "14", label: "Xã Cam Lập" },
  { value: "15", label: "Xã Cam Bình" },
];
export const khanhhoa_huyencanlam = [
  { value: "1", label: "Xã Cam Tân" },
  { value: "2", label: "Xã Cam Hòa" },
  { value: "3", label: "Xã Cam Hải Đông" },
  { value: "4", label: "Xã Cam Hải Tây" },
  { value: "5", label: "Xã Sơn Tân" },
  { value: "6", label: "Xã Cam Hiệp Bắc" },
  { value: "7", label: "Thị trấn Cam Đức" },
  { value: "8", label: "Xã Cam Hiệp Nam" },
  { value: "9", label: "Xã Cam Phước Tây" },
  { value: "10", label: "Xã Cam Thành Bắc" },
  { value: "11", label: "Xã Cam An Bắc" },
  { value: "12", label: "Xã Cam An Nam" },
  { value: "13", label: "Xã Suối Cát" },
  { value: "14", label: "Xã Suối Tân" },
];
export const khanhhoa_huyenvanninh = [
  { value: "1", label: "Thị trấn Vạn Giã" },
  { value: "2", label: "Xã Đại Lãnh" },
  { value: "3", label: "Xã Vạn Phước" },
  { value: "4", label: "Xã Vạn Long" },
  { value: "5", label: "Xã Vạn Bình" },
  { value: "6", label: "Xã Vạn Thọ" },
  { value: "7", label: "Xã Vạn Khánh" },
  { value: "8", label: "Xã Vạn Phú" },
  { value: "9", label: "Xã Vạn Lương" },
  { value: "10", label: "Xã Vạn Thắng" },
  { value: "11", label: "Xã Vạn Thạnh" },
  { value: "12", label: "Xã Xuân Sơn" },
  { value: "13", label: "Xã Vạn Hưng" },
];
export const khanhhoa_xaninhhoa = [
  { value: "1", label: "Phường Ninh Hiệp" },
  { value: "2", label: "Xã Ninh Sơn" },
  { value: "3", label: "Xã Ninh Tây" },
  { value: "4", label: "Xã Ninh Thượng" },
  { value: "5", label: "Xã Ninh An" },
  { value: "6", label: "Phường Ninh Hải" },
  { value: "7", label: "Xã Ninh Thọ" },
  { value: "8", label: "Xã Ninh Trung" },
  { value: "9", label: "Xã Ninh Sim" },
  { value: "10", label: "Xã Ninh Xuân" },
  { value: "11", label: "Xã Ninh Thân" },
  { value: "12", label: "Phường Ninh Diêm" },
  { value: "13", label: "Xã Ninh Đông" },
  { value: "14", label: "Phường Ninh Thủy" },
  { value: "15", label: "Phường Ninh Đa" },
  { value: "16", label: "Xã Ninh Phụng" },
  { value: "17", label: "Xã Ninh Bình" },
  { value: "18", label: "Xã Ninh Phước" },
  { value: "19", label: "Xã Ninh Phú" },
  { value: "20", label: "Xã Ninh Tân" },
  { value: "21", label: "Xã Ninh Quang" },
  { value: "22", label: "Phường Ninh Giang" },
  { value: "23", label: "Phường Ninh Hà" },
  { value: "24", label: "Xã Ninh Hưng" },
  { value: "25", label: "Xã Ninh Lộc" },
  { value: "26", label: "Xã Ninh Ích" },
  { value: "27", label: "Xã Ninh Vân" },
];
export const khanhhoa_huyenkhanhvinh = [
  { value: "1", label: "Thị trấn Khánh Vĩnh" },
  { value: "2", label: "Xã Khánh Hiệp" },
  { value: "3", label: "Xã Khánh Bình" },
  { value: "4", label: "Xã Khánh Trung" },
  { value: "5", label: "Xã Khánh Đông" },
  { value: "6", label: "Xã Khánh Thượng" },
  { value: "7", label: "Xã Khánh Nam" },
  { value: "8", label: "Xã Sông Cầu" },
  { value: "9", label: "Xã Giang Ly" },
  { value: "10", label: "Xã Cầu Bà" },
  { value: "11", label: "Xã Liên Sang" },
  { value: "12", label: "Xã Khánh Thành" },
  { value: "13", label: "Xã Khánh Phú" },
  { value: "14", label: "Xã Sơn Thái" },
];
export const khanhhoa_huyendienkhanh = [
  { value: "1", label: "Thị trấn Diên Khánh" },
  { value: "2", label: "Xã Diên Lâm" },
  { value: "3", label: "Xã Diên Điền" },
  { value: "4", label: "Xã Diên Xuân" },
  { value: "5", label: "Xã Diên Sơn" },
  { value: "6", label: "Xã Diên Đồng" },
  { value: "7", label: "Xã Diên Phú" },
  { value: "8", label: "Xã Diên Thọ" },
  { value: "9", label: "Xã Diên Phước" },
  { value: "10", label: "Xã Diên Lạc" },
  { value: "11", label: "Xã Diên Tân" },
  { value: "12", label: "Xã Diên Hòa" },
  { value: "13", label: "Xã Diên Thạnh" },
  { value: "14", label: "Xã Diên Toàn" },
  { value: "15", label: "Xã Diên An" },
  { value: "16", label: "Xã Diên Bình" },
  { value: "17", label: "Xã Diên Lộc" },
  { value: "18", label: "Xã Suối Hiệp" },
  { value: "19", label: "Xã Suối Tiên" },
];
export const khanhhoa_huyenkhanhson = [
  { value: "1", label: "Thị trấn Tô Hạp" },
  { value: "2", label: "Xã Thành Sơn" },
  { value: "3", label: "Xã Sơn Lâm" },
  { value: "4", label: "Xã Sơn Hiệp" },
  { value: "5", label: "Xã Sơn Bình" },
  { value: "6", label: "Xã Sơn Trung" },
  { value: "7", label: "Xã Ba Cụm Bắc" },
  { value: "8", label: "Xã Ba Cụm Nam" },
];
export const khanhhoa_huyentruongsa = [
  { value: "1", label: "Thị trấn Trường Sa" },
  { value: "2", label: "Xã Song Tử Tây" },
  { value: "3", label: "Xã Sinh Tồn" },
];
export const ninhthuan_thanhphophanrang = [
  { value: "1", label: "Phường Đô Vinh" },
  { value: "2", label: "Phường Phước Mỹ" },
  { value: "3", label: "Phường Bảo An" },
  { value: "4", label: "Phường Phủ Hà" },
  { value: "5", label: "Phường Thanh Sơn" },
  { value: "6", label: "Phường Mỹ Hương" },
  { value: "7", label: "Phường Tấn Tài" },
  { value: "8", label: "Phường Kinh Dinh" },
  { value: "9", label: "Phường Đạo Long" },
  { value: "10", label: "Phường Đài Sơn" },
  { value: "11", label: "Phường Đông Hải" },
  { value: "12", label: "Phường Mỹ Đông" },
  { value: "13", label: "Xã Thành Hải" },
  { value: "14", label: "Phường Văn Hải" },
  { value: "15", label: "Phường Mỹ Bình" },
  { value: "16", label: "Phường Mỹ Hải" },
];
export const ninhthuan_huyenbacai = [
  { value: "1", label: "Xã Phước Bình" },
  { value: "2", label: "Xã Phước Hòa" },
  { value: "3", label: "Xã Phước Tân" },
  { value: "4", label: "Xã Phước Tiến" },
  { value: "5", label: "Xã Phước Thắng" },
  { value: "6", label: "Xã Phước Thành" },
  { value: "7", label: "Xã Phước Đại" },
  { value: "8", label: "Xã Phước Chính" },
  { value: "9", label: "Xã Phước Trung" },
];
export const ninhthuan_huyenninhson = [
  { value: "1", label: "Thị trấn Tân Sơn" },
  { value: "2", label: "Xã Lâm Sơn" },
  { value: "3", label: "Xã Lương Sơn" },
  { value: "4", label: "Xã Quảng Sơn" },
  { value: "5", label: "Xã Mỹ Sơn" },
  { value: "6", label: "Xã Hòa Sơn" },
  { value: "7", label: "Xã Ma Nới" },
  { value: "8", label: "Xã Nhơn Sơn" },
];
export const ninhthuan_huyenninhhai = [
  { value: "1", label: "Thị trấn Khánh Hải" },
  { value: "2", label: "Xã Vĩnh Hải" },
  { value: "3", label: "Xã Phương Hải" },
  { value: "4", label: "Xã Tân Hải" },
  { value: "5", label: "Xã Xuân Hải" },
  { value: "6", label: "Xã Hộ Hải" },
  { value: "7", label: "Xã Tri Hải" },
  { value: "8", label: "Xã Nhơn Hải" },
  { value: "9", label: "Xã Thanh Hải" },
];
export const ninhthuan_huyenninhphuoc = [
  { value: "1", label: "Thị trấn Phước Dân" },
  { value: "2", label: "Xã Phước Sơn" },
  { value: "3", label: "Xã Phước Thái" },
  { value: "4", label: "Xã Phước Hậu" },
  { value: "5", label: "Xã Phước Thuận" },
  { value: "6", label: "Xã An Hải" },
  { value: "7", label: "Xã Phước Hữu" },
  { value: "8", label: "Xã Phước Hải" },
  { value: "9", label: "Xã Phước Vinh" },
];
export const ninhthuan_huyenthuanbac = [
  { value: "1", label: "Xã Phước Chiến" },
  { value: "2", label: "Xã Công Hải" },
  { value: "3", label: "Xã Phước Kháng" },
  { value: "4", label: "Xã Lợi Hải" },
  { value: "5", label: "Xã Bắc Sơn" },
  { value: "6", label: "Xã Bắc Phong" },
];
export const ninhthuan_huyenthuannam = [
  { value: "1", label: "Xã Phước Hà" },
  { value: "2", label: "Xã Phước Nam" },
  { value: "3", label: "Xã Phước Ninh" },
  { value: "4", label: "Xã Nhị Hà" },
  { value: "5", label: "Xã Phước Dinh" },
  { value: "6", label: "Xã Phước Minh" },
  { value: "7", label: "Xã Phước Diêm" },
  { value: "8", label: "Xã Cà Ná" },
];
export const binhthuan_thanhphophanthiet = [
  { value: "1", label: "Phường Mũi Né" },
  { value: "2", label: "Phường Hàm Tiến" },
  { value: "3", label: "Phường Phú Hài" },
  { value: "4", label: "Phường Phú Thủy" },
  { value: "5", label: "Phường Phú Tài" },
  { value: "6", label: "Phường Phú Trinh" },
  { value: "7", label: "Phường Xuân An" },
  { value: "8", label: "Phường Thanh Hải" },
  { value: "9", label: "Phường Bình Hưng" },
  { value: "10", label: "Phường Đức Nghĩa" },
  { value: "11", label: "Phường Lạc Đạo" },
  { value: "12", label: "Phường Đức Thắng" },
  { value: "13", label: "Phường Hưng Long" },
  { value: "14", label: "Phường Đức Long" },
  { value: "15", label: "Xã Thiện Nghiệp" },
  { value: "16", label: "Xã Phong Nẫm" },
  { value: "17", label: "Xã Tiến Lợi" },
  { value: "18", label: "Xã Tiến Thành" },
];
export const binhthuan_xalagi = [
  { value: "1", label: "Phường Phước Hội" },
  { value: "2", label: "Phường Phước Lộc" },
  { value: "3", label: "Phường Tân Thiện" },
  { value: "4", label: "Phường Tân An" },
  { value: "5", label: "Phường Bình Tân" },
  { value: "6", label: "Xã Tân Hải" },
  { value: "7", label: "Xã Tân Tiến" },
  { value: "8", label: "Xã Tân Bình" },
  { value: "9", label: "Xã Tân Phước" },
];
export const binhthuan_huyentuyphong = [
  { value: "1", label: "Thị trấn Liên Hương" },
  { value: "2", label: "Thị trấn Phan Rí Cửa" },
  { value: "3", label: "Xã Phan Dũng" },
  { value: "4", label: "Xã Phong Phú" },
  { value: "5", label: "Xã Vĩnh Hảo" },
  { value: "6", label: "Xã Vĩnh Tân" },
  { value: "7", label: "Xã Phú Lạc" },
  { value: "8", label: "Xã Phước Thể" },
  { value: "9", label: "Xã Hòa Minh" },
  { value: "10", label: "Xã Chí Công" },
  { value: "11", label: "Xã Bình Thạnh" },
  { value: "12", label: "Xã Hòa Phú" },
];
export const binhthuan_huyenbacbinh = [
  { value: "1", label: "Thị trấn Chợ Lầu" },
  { value: "2", label: "Xã Phan Sơn" },
  { value: "3", label: "Xã Phan Lâm" },
  { value: "4", label: "Xã Bình An" },
  { value: "5", label: "Xã Phan Điền" },
  { value: "6", label: "Xã Hải Ninh" },
  { value: "7", label: "Xã Sông Lũy" },
  { value: "8", label: "Xã Phan Tiến" },
  { value: "9", label: "Xã Sông Bình" },
  { value: "10", label: "Thị trấn Lương Sơn" },
  { value: "11", label: "Xã Phan Hòa" },
  { value: "12", label: "Xã Phan Thanh" },
  { value: "13", label: "Xã Hồng Thái" },
  { value: "14", label: "Xã Phan Hiệp" },
  { value: "15", label: "Xã Bình Tân" },
  { value: "16", label: "Xã Phan Rí Thành" },
  { value: "17", label: "Xã Hòa Thắng" },
  { value: "18", label: "Xã Hồng Phong" },
];
export const binhthuan_huyenhamthuanbac = [
  { value: "1", label: "Thị trấn Ma Lâm" },
  { value: "2", label: "Thị trấn Phú Long" },
  { value: "3", label: "Xã La Dạ" },
  { value: "4", label: "Xã Đông Tiến" },
  { value: "5", label: "Xã Thuận Hòa" },
  { value: "6", label: "Xã Đông Giang" },
  { value: "7", label: "Xã Hàm Phú" },
  { value: "8", label: "Xã Hồng Liêm" },
  { value: "9", label: "Xã Thuận Minh" },
  { value: "10", label: "Xã Hồng Sơn" },
  { value: "11", label: "Xã Hàm Trí" },
  { value: "12", label: "Xã Hàm Đức" },
  { value: "13", label: "Xã Hàm Liêm" },
  { value: "14", label: "Xã Hàm Chính" },
  { value: "15", label: "Xã Hàm Hiệp" },
  { value: "16", label: "Xã Hàm Thắng" },
  { value: "17", label: "Xã Đa Mi" },
];
export const binhthuan_huyenhamthuannam = [
  { value: "1", label: "Thị trấn Thuận Nam" },
  { value: "2", label: "Xã Mỹ Thạnh" },
  { value: "3", label: "Xã Hàm Cần" },
  { value: "4", label: "Xã Mương Mán" },
  { value: "5", label: "Xã Hàm Thạnh" },
  { value: "6", label: "Xã Hàm Kiệm" },
  { value: "7", label: "Xã Hàm Cường" },
  { value: "8", label: "Xã Hàm Mỹ" },
  { value: "9", label: "Xã Tân Lập" },
  { value: "10", label: "Xã Hàm Minh" },
  { value: "11", label: "Xã Thuận Quí" },
  { value: "12", label: "Xã Tân Thuận" },
  { value: "13", label: "Xã Tân Thành" },
];
export const binhthuan_huyentanhlinh = [
  { value: "1", label: "Thị trấn Lạc Tánh" },
  { value: "2", label: "Xã Bắc Ruộng" },
  { value: "3", label: "Xã Măng Tố" },
  { value: "4", label: "Xã Nghị Đức" },
  { value: "5", label: "Xã La Ngâu" },
  { value: "6", label: "Xã Huy Khiêm" },
  { value: "7", label: "Xã Đức Tân" },
  { value: "8", label: "Xã Đức Phú" },
  { value: "9", label: "Xã Đồng Kho" },
  { value: "10", label: "Xã Gia An" },
  { value: "11", label: "Xã Đức Bình" },
  { value: "12", label: "Xã Gia Huynh" },
  { value: "13", label: "Xã Đức Thuận" },
  { value: "14", label: "Xã Suối Kiết" },
];
export const binhthuan_huyenduclinh = [
  { value: "1", label: "Thị trấn Võ Xu" },
  { value: "2", label: "Thị trấn Đức Tài" },
  { value: "3", label: "Xã Đa Kai" },
  { value: "4", label: "Xã Sùng Nhơn" },
  { value: "5", label: "Xã Mê Pu" },
  { value: "6", label: "Xã Nam Chính" },
  { value: "7", label: "Xã Đức Chính" },
  { value: "8", label: "Xã Đức Hạnh" },
  { value: "9", label: "Xã Đức Tín" },
  { value: "10", label: "Xã Vũ Hòa" },
  { value: "11", label: "Xã Tân Hà" },
  { value: "12", label: "Xã Đông Hà" },
  { value: "13", label: "Xã Trà Tân" },
];
export const binhthuan_huyenhamtan = [
  { value: "1", label: "Thị trấn Tân Minh" },
  { value: "2", label: "Thị trấn Tân Nghĩa" },
  { value: "3", label: "Xã Sông Phan" },
  { value: "4", label: "Xã Tân Phúc" },
  { value: "5", label: "Xã Tân Đức" },
  { value: "6", label: "Xã Tân Thắng" },
  { value: "7", label: "Xã Thắng Hải" },
  { value: "8", label: "Xã Tân Hà" },
  { value: "9", label: "Xã Tân Xuân" },
  { value: "10", label: "Xã Sơn Mỹ" },
];
export const binhthuan_huyenphuqui = [
  { value: "1", label: "Xã Ngũ Phụng" },
  { value: "2", label: "Xã Long Hải" },
  { value: "3", label: "Xã Tam Thanh" },
];
export const binhphuoc_xaphuoclong = [
  { value: "1", label: "Phường Thác Mơ" },
  { value: "2", label: "Phường Long Thủy" },
  { value: "3", label: "Phường Phước Bình" },
  { value: "4", label: "Phường Long Phước" },
  { value: "5", label: "Phường Sơn Giang" },
  { value: "6", label: "Xã Long Giang" },
  { value: "7", label: "Xã Phước Tín" },
];
export const binhphuoc_xadongxoai = [
  { value: "1", label: "Phường Tân Phú" },
  { value: "2", label: "Phường Tân Đồng" },
  { value: "3", label: "Phường Tân Bình" },
  { value: "4", label: "Phường Tân Xuân" },
  { value: "5", label: "Phường Tân Thiện" },
  { value: "6", label: "Xã Tân Thành" },
  { value: "7", label: "Xã Tiến Thành" },
  { value: "8", label: "Xã Tiến Hưng" },
];
export const binhphuoc_xabinhlong = [
  { value: "1", label: "Phường Hưng Chiến" },
  { value: "2", label: "Phường An Lộc" },
  { value: "3", label: "Phường Phú Thịnh" },
  { value: "4", label: "Phường Phú Đức" },
  { value: "5", label: "Xã Thanh Lương" },
  { value: "6", label: "Xã Thanh Phú" },
];
export const binhphuoc_huyenbugiamap = [
  { value: "1", label: "Xã Bù Gia Mập" },
  { value: "2", label: "Xã Đak O" },
  { value: "3", label: "Xã Đức Hạnh" },
  { value: "4", label: "Xã Phú Văn" },
  { value: "5", label: "Xã Đa Kia" },
  { value: "6", label: "Xã Phước Minh" },
  { value: "7", label: "Xã Bình Thắng" },
  { value: "8", label: "Xã Phú Nghĩa" },
];
export const binhphuoc_huyenlocninh = [
  { value: "1", label: "Thị trấn Lộc Ninh" },
  { value: "2", label: "Xã Lộc Hòa" },
  { value: "3", label: "Xã Lộc An" },
  { value: "4", label: "Xã Lộc Tấn" },
  { value: "5", label: "Xã Lộc Thạnh" },
  { value: "6", label: "Xã Lộc Hiệp" },
  { value: "7", label: "Xã Lộc Thiện" },
  { value: "8", label: "Xã Lộc Thuận" },
  { value: "9", label: "Xã Lộc Quang" },
  { value: "10", label: "Xã Lộc Phú" },
  { value: "11", label: "Xã Lộc Thành" },
  { value: "12", label: "Xã Lộc Thái" },
  { value: "13", label: "Xã Lộc Điền" },
  { value: "14", label: "Xã Lộc Hưng" },
  { value: "15", label: "Xã Lộc Thịnh" },
  { value: "16", label: "Xã Lộc Khánh" },
];
export const binhphuoc_huyenbudop = [
  { value: "1", label: "Thị trấn Thanh Bình" },
  { value: "2", label: "Xã Hưng Phước" },
  { value: "3", label: "Xã Phước Thiện" },
  { value: "4", label: "Xã Thiện Hưng" },
  { value: "5", label: "Xã Thanh Hòa" },
  { value: "6", label: "Xã Tân Thành" },
  { value: "7", label: "Xã Tân Tiến" },
];
export const binhphuoc_huyenhonquan = [
  { value: "1", label: "Xã Thanh An" },
  { value: "2", label: "Xã An Khương" },
  { value: "3", label: "Xã An Phú" },
  { value: "4", label: "Xã Tân Lợi" },
  { value: "5", label: "Xã Tân Hưng" },
  { value: "6", label: "Xã Minh Đức" },
  { value: "7", label: "Xã Minh Tâm" },
  { value: "8", label: "Xã Phước An" },
  { value: "9", label: "Xã Thanh Bình" },
  { value: "10", label: "Xã Tân Khai" },
  { value: "11", label: "Xã Đồng Nơ" },
  { value: "12", label: "Xã Tân Hiệp" },
  { value: "13", label: "Xã Tân Quan" },
];
export const binhphuoc_huyendongphu = [
  { value: "1", label: "Thị trấn Tân Phú" },
  { value: "2", label: "Xã Thuận Lợi" },
  { value: "3", label: "Xã Đồng Tâm" },
  { value: "4", label: "Xã Tân Phước" },
  { value: "5", label: "Xã Tân Hưng" },
  { value: "6", label: "Xã Tân Lợi" },
  { value: "7", label: "Xã Tân Lập" },
  { value: "8", label: "Xã Tân Hòa" },
  { value: "9", label: "Xã Thuận Phú" },
  { value: "10", label: "Xã Đồng Tiến" },
  { value: "11", label: "Xã Tân Tiến" },
];
export const binhphuoc_huyenbudang = [
  { value: "1", label: "Thị trấn Đức Phong" },
  { value: "2", label: "Xã Đường 10" },
  { value: "3", label: "Xã Đak Nhau" },
  { value: "4", label: "Xã Phú Sơn" },
  { value: "5", label: "Xã Thọ Sơn" },
  { value: "6", label: "Xã Bình Minh" },
  { value: "7", label: "Xã Bom Bo" },
  { value: "8", label: "Xã Minh Hưng" },
  { value: "9", label: "Xã Đoàn Kết" },
  { value: "10", label: "Xã Đồng Nai" },
  { value: "11", label: "Xã Đức Liễu" },
  { value: "12", label: "Xã Thống Nhất" },
  { value: "13", label: "Xã Nghĩa Trung" },
  { value: "14", label: "Xã Nghĩa Bình" },
  { value: "15", label: "Xã Đăng Hà" },
  { value: "16", label: "Xã Phước Sơn" },
];
export const binhphuoc_huyenchonthanh = [
  { value: "1", label: "Thị trấn Chơn Thành" },
  { value: "2", label: "Xã Thành Tâm" },
  { value: "3", label: "Xã Minh Lập" },
  { value: "4", label: "Xã Quang Minh" },
  { value: "5", label: "Xã Minh Hưng" },
  { value: "6", label: "Xã Minh Long" },
  { value: "7", label: "Xã Minh Thành" },
  { value: "8", label: "Xã Nha Bích" },
  { value: "9", label: "Xã Minh Thắng" },
];
export const binhphuoc_huyenphurieng = [
  { value: "1", label: "Xã Long Bình" },
  { value: "2", label: "Xã Bình Tân" },
  { value: "3", label: "Xã Bình Sơn" },
  { value: "4", label: "Xã Long Hưng" },
  { value: "5", label: "Xã Phước Tân" },
  { value: "6", label: "Xã Bù Nho" },
  { value: "7", label: "Xã Long Hà" },
  { value: "8", label: "Xã Long Tân" },
  { value: "9", label: "Xã Phú Trung" },
  { value: "10", label: "Xã Phú Riềng" },
];

export const tayninh_thanhphotayninh = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường 3" },
  { value: "3", label: "Phường 4" },
  { value: "4", label: "Phường Hiệp Ninh" },
  { value: "5", label: "Phường 2" },
  { value: "6", label: "Xã Thạnh Tân" },
  { value: "7", label: "Xã Tân Bình" },
  { value: "8", label: "Xã Bình Minh" },
  { value: "9", label: "Phường Ninh Sơn" },
  { value: "10", label: "Phường Ninh Thạnh" },
];
export const tayninh_huyentanbien = [
  { value: "1", label: "Thị trấn Tân Biên" },
  { value: "2", label: "Xã Tân Lập" },
  { value: "3", label: "Xã Thạnh Bắc" },
  { value: "4", label: "Xã Tân Bình" },
  { value: "5", label: "Xã Thạnh Bình" },
  { value: "6", label: "Xã Thạnh Tây" },
  { value: "7", label: "Xã Hòa Hiệp" },
  { value: "8", label: "Xã Tân Phong" },
  { value: "9", label: "Xã Mỏ Công" },
  { value: "10", label: "Xã Trà Vong" },
];
export const tayninh_huyentanchau = [
  { value: "1", label: "Thị trấn Tân Châu" },
  { value: "2", label: "Xã Tân Hà" },
  { value: "3", label: "Xã Tân Đông" },
  { value: "4", label: "Xã Tân Hội" },
  { value: "5", label: "Xã Tân Hòa" },
  { value: "6", label: "Xã Suối Ngô" },
  { value: "7", label: "Xã Suối Dây" },
  { value: "8", label: "Xã Tân Hiệp" },
  { value: "9", label: "Xã Thạnh Đông" },
  { value: "10", label: "Xã Tân Thành" },
  { value: "11", label: "Xã Tân Phú" },
  { value: "12", label: "Xã Tân Hưng" },
];
export const tayninh_huyenduongminhchau = [
  { value: "1", label: "Thị trấn Dương Minh Châu" },
  { value: "2", label: "Xã Suối Đá" },
  { value: "3", label: "Xã Phan" },
  { value: "4", label: "Xã Phước Ninh" },
  { value: "5", label: "Xã Phước Minh" },
  { value: "6", label: "Xã Bàu Năng" },
  { value: "7", label: "Xã Chà Là" },
  { value: "8", label: "Xã Cầu Khởi" },
  { value: "9", label: "Xã Bến Củi" },
  { value: "10", label: "Xã Lộc Ninh" },
  { value: "11", label: "Xã Truông Mít" },
];
export const tayninh_huyenchauthanh = [
  { value: "1", label: "Thị trấn Châu Thành" },
  { value: "2", label: "Xã Hảo Đước" },
  { value: "3", label: "Xã Phước Vinh" },
  { value: "4", label: "Xã Đồng Khởi" },
  { value: "5", label: "Xã Thái Bình" },
  { value: "6", label: "Xã An Cơ" },
  { value: "7", label: "Xã Biên Giới" },
  { value: "8", label: "Xã Hòa Thạnh" },
  { value: "9", label: "Xã Trí Bình" },
  { value: "10", label: "Xã Hòa Hội" },
  { value: "11", label: "Xã An Bình" },
  { value: "12", label: "Xã Thanh Điền" },
  { value: "13", label: "Xã Thành Long" },
  { value: "14", label: "Xã Ninh Điền" },
  { value: "15", label: "Xã Long Vĩnh" },
];
export const tayninh_huyenhoathanh = [
  { value: "1", label: "Thị trấn Hòa Thành" },
  { value: "2", label: "Xã Hiệp Tân" },
  { value: "3", label: "Xã Long Thành Bắc" },
  { value: "4", label: "Xã Trường Hòa" },
  { value: "5", label: "Xã Trường Đông" },
  { value: "6", label: "Xã Long Thành Trung" },
  { value: "7", label: "Xã Trường Tây" },
  { value: "8", label: "Xã Long Thành Nam" },
];
export const tayninh_huyengodau = [
  { value: "1", label: "Thị trấn Gò Dầu" },
  { value: "2", label: "Xã Thạnh Đức" },
  { value: "3", label: "Xã Cẩm Giang" },
  { value: "4", label: "Xã Hiệp Thạnh" },
  { value: "5", label: "Xã Bàu Đồn" },
  { value: "6", label: "Xã Phước Thạnh" },
  { value: "7", label: "Xã Phước Đông" },
  { value: "8", label: "Xã Phước Trạch" },
  { value: "9", label: "Xã Thanh Phước" },
];
export const tayninh_huyenbencau = [
  { value: "1", label: "Thị trấn Bến Cầu" },
  { value: "2", label: "Xã Long Chữ" },
  { value: "3", label: "Xã Long Phước" },
  { value: "4", label: "Xã Long Giang" },
  { value: "5", label: "Xã Tiên Thuận" },
  { value: "6", label: "Xã Long Khánh" },
  { value: "7", label: "Xã Lợi Thuận" },
  { value: "8", label: "Xã Long Thuận" },
  { value: "9", label: "Xã An Thạnh" },
];
export const tayninh_huyentrangbang = [
  { value: "1", label: "Thị trấn Trảng Bàng" },
  { value: "2", label: "Xã Đôn Thuận" },
  { value: "3", label: "Xã Hưng Thuận" },
  { value: "4", label: "Xã Lộc Hưng" },
  { value: "5", label: "Xã Gia Lộc" },
  { value: "6", label: "Xã Gia Bình" },
  { value: "7", label: "Xã Phước Lưu" },
  { value: "8", label: "Xã Bình Thạnh" },
  { value: "9", label: "Xã An Tịnh" },
  { value: "10", label: "Xã An Hòa" },
  { value: "11", label: "Xã Phước Chỉ" },
];
export const binhduong_thanhphothudaumot = [
  { value: "1", label: "Phường Hiệp Thành" },
  { value: "2", label: "Phường Phú Lợi" },
  { value: "3", label: "Phường Phú Cường" },
  { value: "4", label: "Phường Phú Hòa" },
  { value: "5", label: "Phường Phú Thọ" },
  { value: "6", label: "Phường Chánh Nghĩa" },
  { value: "7", label: "Phường Định Hòa" },
  { value: "8", label: "Phường Hòa Phú" },
  { value: "9", label: "Phường Phú Mỹ" },
  { value: "10", label: "Phường Phú Tân" },
  { value: "11", label: "Phường Tân An" },
  { value: "12", label: "Phường Hiệp An" },
  { value: "13", label: "Phường Tương Bình Hiệp" },
  { value: "14", label: "Phường Chánh Mỹ" },
];
export const binhduong_huyenbaubang = [
  { value: "1", label: "Xã Trừ Văn Thố" },
  { value: "2", label: "Xã Cây Trường II" },
  { value: "3", label: "Xã Lai Uyên" },
  { value: "4", label: "Xã Tân Hưng" },
  { value: "5", label: "Xã Long Nguyên" },
  { value: "6", label: "Xã Hưng Hòa" },
  { value: "7", label: "Xã Lai Hưng" },
];
export const binhduong_huyendaitieng = [
  { value: "1", label: "Thị trấn Dầu Tiếng" },
  { value: "2", label: "Xã Minh Hòa" },
  { value: "3", label: "Xã Minh Thạnh" },
  { value: "4", label: "Xã Minh Tân" },
  { value: "5", label: "Xã Định An" },
  { value: "6", label: "Xã Long Hòa" },
  { value: "7", label: "Xã Định Thành" },
  { value: "8", label: "Xã Định Hiệp" },
  { value: "9", label: "Xã An Lập" },
  { value: "10", label: "Xã Long Tân" },
  { value: "11", label: "Xã Thanh An" },
  { value: "12", label: "Xã Thanh Tuyền" },
];
export const binhduong_xabencat = [
  { value: "1", label: "Phường Mỹ Phước" },
  { value: "2", label: "Phường Chánh Phú Hòa" },
  { value: "3", label: "Xã An Điền" },
  { value: "4", label: "Xã An Tây" },
  { value: "5", label: "Phường Thới Hòa" },
  { value: "6", label: "Phường Hòa Lợi" },
  { value: "7", label: "Phường Tân Định" },
  { value: "8", label: "Xã Phú An" },
];
export const binhduong_huyenphugiao = [
  { value: "1", label: "Thị trấn Phước Vĩnh" },
  { value: "2", label: "Xã An Linh" },
  { value: "3", label: "Xã Phước Sang" },
  { value: "4", label: "Xã An Thái" },
  { value: "5", label: "Xã An Long" },
  { value: "6", label: "Xã An Bình" },
  { value: "7", label: "Xã Tân Hiệp" },
  { value: "8", label: "Xã Tam Lập" },
  { value: "9", label: "Xã Tân Long" },
  { value: "10", label: "Xã Vĩnh Hòa" },
  { value: "11", label: "Xã Phước Hòa" },
];
export const binhduong_xatanuyen = [
  { value: "1", label: "Phường Uyên Hưng" },
  { value: "2", label: "Phường Tân Phước Khánh" },
  { value: "3", label: "Xã Vĩnh Tân" },
  { value: "4", label: "Xã Hội Nghĩa" },
  { value: "5", label: "Phường Tân Hiệp" },
  { value: "6", label: "Phường Khánh Bình" },
  { value: "7", label: "Xã Phú Chánh" },
  { value: "8", label: "Xã Bạch Đằng" },
  { value: "9", label: "Xã Tân Vĩnh Hiệp" },
  { value: "10", label: "Phường Thạnh Phước" },
  { value: "11", label: "Xã Thạnh Hội" },
  { value: "12", label: "Phường Thái Hòa" },
];
export const binhduong_xadian = [
  { value: "1", label: "Phường Dĩ An" },
  { value: "2", label: "Phường Tân Bình" },
  { value: "3", label: "Phường Tân Đông Hiệp" },
  { value: "4", label: "Phường Bình An" },
  { value: "5", label: "Phường Bình Thắng" },
  { value: "6", label: "Phường Đông Hòa" },
  { value: "7", label: "Phường An Bình" },
];
export const binhduong_xathuanan = [
  { value: "1", label: "Phường An Thạnh" },
  { value: "2", label: "Phường Lái Thiêu" },
  { value: "3", label: "Phường Bình Chuẩn" },
  { value: "4", label: "Phường Thuận Giao" },
  { value: "5", label: "Phường An Phú" },
  { value: "6", label: "Phường Hưng Định" },
  { value: "7", label: "Xã An Sơn" },
  { value: "8", label: "Phường Bình Nhâm" },
  { value: "9", label: "Phường Bình Hòa" },
  { value: "10", label: "Phường Vĩnh Phú" },
];
export const binhduong_huyenbactanuyen = [
  { value: "1", label: "Xã Tân Định" },
  { value: "2", label: "Xã Bình Mỹ" },
  { value: "3", label: "Xã Tân Bình" },
  { value: "4", label: "Xã Tân Lập" },
  { value: "5", label: "Xã Tân Thành" },
  { value: "6", label: "Xã Đất Cuốc" },
  { value: "7", label: "Xã Hiếu Liêm" },
  { value: "8", label: "Xã Lạc An" },
  { value: "9", label: "Xã Tân Mỹ" },
  { value: "10", label: "Xã Thường Tân" },
];
export const dongnai_thanhphobienhoa = [
  { value: "1", label: "Phường Trảng Dài" },
  { value: "2", label: "Phường Tân Phong" },
  { value: "3", label: "Phường Tân Biên" },
  { value: "4", label: "Phường Hố Nai" },
  { value: "5", label: "Phường Tân Hòa" },
  { value: "6", label: "Phường Tân Hiệp" },
  { value: "7", label: "Phường Bửu Long" },
  { value: "8", label: "Phường Tân Tiến" },
  { value: "9", label: "Phường Tam Hiệp" },
  { value: "10", label: "Phường Long Bình" },
  { value: "11", label: "Phường Quang Vinh" },
  { value: "12", label: "Phường Tân Mai" },
  { value: "13", label: "Phường Thống Nhất" },
  { value: "14", label: "Phường Trung Dũng" },
  { value: "15", label: "Phường Tam Hòa" },
  { value: "16", label: "Phường Hòa Bình" },
  { value: "17", label: "Phường Quyết Thắng" },
  { value: "18", label: "Phường Thanh Bình" },
  { value: "19", label: "Phường Bình Đa" },
  { value: "20", label: "Phường An Bình" },
  { value: "21", label: "Phường Bửu Hòa" },
  { value: "22", label: "Phường Long Bình Tân" },
  { value: "23", label: "Phường Tân Vạn" },
  { value: "24", label: "Xã Tân Hạnh" },
  { value: "25", label: "Xã Hiệp Hòa" },
  { value: "26", label: "Xã Hóa An" },
  { value: "27", label: "Xã An Hòa" },
  { value: "28", label: "Xã Tam Phước" },
  { value: "29", label: "Xã Phước Tân" },
  { value: "30", label: "Xã Long Hưng" },
];
export const dongnai_xalongkhanh = [
  { value: "1", label: "Phường Xuân Trung" },
  { value: "2", label: "Phường Xuân Thanh" },
  { value: "3", label: "Phường Xuân Bình" },
  { value: "4", label: "Phường Xuân An" },
  { value: "5", label: "Phường Xuân Hòa" },
  { value: "6", label: "Phường Phú Bình" },
  { value: "7", label: "Xã Bình Lộc" },
  { value: "8", label: "Xã Bảo Quang" },
  { value: "9", label: "Xã Suối Tre" },
  { value: "10", label: "Xã Bảo Vinh" },
  { value: "11", label: "Xã Xuân Lập" },
  { value: "12", label: "Xã Bàu Sen" },
  { value: "13", label: "Xã Bàu Trâm" },
  { value: "14", label: "Xã Xuân Tân" },
  { value: "15", label: "Xã Hàng Gòn" },
];
export const dongnai_huyentanphu = [
  { value: "1", label: "Thị trấn Tân Phú" },
  { value: "2", label: "Xã Dak Lua" },
  { value: "3", label: "Xã Nam Cát Tiên" },
  { value: "4", label: "Xã Phú An" },
  { value: "5", label: "Xã Núi Tượng" },
  { value: "6", label: "Xã Tà Lài" },
  { value: "7", label: "Xã Phú Lập" },
  { value: "8", label: "Xã Phú Sơn" },
  { value: "9", label: "Xã Phú Thịnh" },
  { value: "10", label: "Xã Thanh Sơn" },
  { value: "11", label: "Xã Phú Trung" },
  { value: "12", label: "Xã Phú Xuân" },
  { value: "13", label: "Xã Phú Lộc" },
  { value: "14", label: "Xã Phú Lâm" },
  { value: "15", label: "Xã Phú Bình" },
  { value: "16", label: "Xã Phú Thanh" },
  { value: "17", label: "Xã Trà Cổ" },
  { value: "18", label: "Xã Phú Điền" },
];
export const dongnai_huyenvinhcuu = [
  { value: "1", label: "Thị trấn Vĩnh An" },
  { value: "2", label: "Xã Phú Lý" },
  { value: "3", label: "Xã Trị An" },
  { value: "4", label: "Xã Tân An" },
  { value: "5", label: "Xã Vĩnh Tân" },
  { value: "6", label: "Xã Bình Lợi" },
  { value: "7", label: "Xã Thạnh Phú" },
  { value: "8", label: "Xã Thiện Tân" },
  { value: "9", label: "Xã Tân Bình" },
  { value: "10", label: "Xã Bình Hòa" },
  { value: "11", label: "Xã Mã Đà" },
  { value: "12", label: "Xã Hiếu Liêm" },
];
export const dongnai_huyendinhquan = [
  { value: "1", label: "Thị trấn Định Quán" },
  { value: "2", label: "Xã Thanh Sơn" },
  { value: "3", label: "Xã Phú Tân" },
  { value: "4", label: "Xã Phú Vinh" },
  { value: "5", label: "Xã Phú Lợi" },
  { value: "6", label: "Xã Phú Hòa" },
  { value: "7", label: "Xã Ngọc Định" },
  { value: "8", label: "Xã La Ngà" },
  { value: "9", label: "Xã Gia Canh" },
  { value: "10", label: "Xã Phú Ngọc" },
  { value: "11", label: "Xã Phú Cường" },
  { value: "12", label: "Xã Túc Trưng" },
  { value: "13", label: "Xã Phú Túc" },
  { value: "14", label: "Xã Suối Nho" },
];
export const dongnai_huyentrangbom = [
  { value: "1", label: "Thị trấn Trảng Bom" },
  { value: "2", label: "Xã Thanh Bình" },
  { value: "3", label: "Xã Cây Gáo" },
  { value: "4", label: "Xã Bàu Hàm" },
  { value: "5", label: "Xã Sông Thao" },
  { value: "6", label: "Xã Sông Trầu" },
  { value: "7", label: "Xã Đông Hòa" },
  { value: "8", label: "Xã Bắc Sơn" },
  { value: "9", label: "Xã Hố Nai 3" },
  { value: "10", label: "Xã Tây Hòa" },
  { value: "11", label: "Xã Bình Minh" },
  { value: "12", label: "Xã Trung Hòa" },
  { value: "13", label: "Xã Đồi 61" },
  { value: "14", label: "Xã Hưng Thịnh" },
  { value: "15", label: "Xã Quảng Tiến" },
  { value: "16", label: "Xã Giang Điền" },
  { value: "17", label: "Xã An Viễn" },
];
export const dongnai_huyenthongnhat = [
  { value: "1", label: "Xã Gia Tân 1" },
  { value: "2", label: "Xã Gia Tân 2" },
  { value: "3", label: "Xã Gia Tân 3" },
  { value: "4", label: "Xã Gia Kiệm" },
  { value: "5", label: "Xã Quang Trung" },
  { value: "6", label: "Xã Bàu Hàm 2" },
  { value: "7", label: "Xã Hưng Lộc" },
  { value: "8", label: "Xã Lộ 25" },
  { value: "9", label: "Xã Xuân Thiện" },
  { value: "10", label: "Xã Xuân Thạnh" },
];
export const dongnai_huyencammy = [
  { value: "1", label: "Xã Sông Nhạn" },
  { value: "2", label: "Xã Xuân Quế" },
  { value: "3", label: "Xã Nhân Nghĩa" },
  { value: "4", label: "Xã Xuân Đường" },
  { value: "5", label: "Xã Long Giao" },
  { value: "6", label: "Xã Xuân Mỹ" },
  { value: "7", label: "Xã Thừa Đức" },
  { value: "8", label: "Xã Bảo Bình" },
  { value: "9", label: "Xã Xuân Bảo" },
  { value: "10", label: "Xã Xuân Tây" },
  { value: "11", label: "Xã Xuân Đông" },
  { value: "12", label: "Xã Sông Ray" },
  { value: "13", label: "Xã Lâm San" },
];
export const dongnai_huyenlongthanh = [
  { value: "1", label: "Thị trấn Long Thành" },
  { value: "2", label: "Xã An Phước" },
  { value: "3", label: "Xã Bình An" },
  { value: "4", label: "Xã Long Đức" },
  { value: "5", label: "Xã Lộc An" },
  { value: "6", label: "Xã Bình Sơn" },
  { value: "7", label: "Xã Tam An" },
  { value: "8", label: "Xã Cẩm Đường" },
  { value: "9", label: "Xã Long An" },
  { value: "10", label: "Xã Suối Trầu" },
  { value: "11", label: "Xã Bàu Cạn" },
  { value: "12", label: "Xã Long Phước" },
  { value: "13", label: "Xã Phước Bình" },
  { value: "14", label: "Xã Tân Hiệp" },
  { value: "15", label: "Xã Phước Thái" },
];
export const dongnai_huyenxuanloc = [
  { value: "1", label: "Thị trấn Gia Ray" },
  { value: "2", label: "Xã Xuân Bắc" },
  { value: "3", label: "Xã Suối Cao" },
  { value: "4", label: "Xã Xuân Thành" },
  { value: "5", label: "Xã Xuân Thọ" },
  { value: "6", label: "Xã Xuân Trường" },
  { value: "7", label: "Xã Xuân Hòa" },
  { value: "8", label: "Xã Xuân Hưng" },
  { value: "9", label: "Xã Xuân Tâm" },
  { value: "10", label: "Xã Suối Cát" },
  { value: "11", label: "Xã Xuân Hiệp" },
  { value: "12", label: "Xã Xuân Phú" },
  { value: "13", label: "Xã Xuân Định" },
  { value: "14", label: "Xã Bảo Hòa" },
  { value: "15", label: "Xã Lang Minh" },
];
export const dongnai_huyennhontrach = [
  { value: "1", label: "Xã Phước Thiền" },
  { value: "2", label: "Xã Long Tân" },
  { value: "3", label: "Xã Đại Phước" },
  { value: "4", label: "Xã Hiệp Phước" },
  { value: "5", label: "Xã Phú Hữu" },
  { value: "6", label: "Xã Phú Hội" },
  { value: "7", label: "Xã Phú Thạnh" },
  { value: "8", label: "Xã Phú Đông" },
  { value: "9", label: "Xã Long Thọ" },
  { value: "10", label: "Xã Vĩnh Thanh" },
  { value: "11", label: "Xã Phước Khánh" },
  { value: "12", label: "Xã Phước An" },
];
export const bariavungtau_thanhphovungtau = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường Thắng Tam" },
  { value: "3", label: "Phường 2" },
  { value: "4", label: "Phường 3" },
  { value: "5", label: "Phường 4" },
  { value: "6", label: "Phường 5" },
  { value: "7", label: "Phường Thắng Nhì" },
  { value: "8", label: "Phường 7" },
  { value: "9", label: "Phường Nguyễn An Ninh" },
  { value: "10", label: "Phường 8" },
  { value: "11", label: "Phường 9" },
  { value: "12", label: "Phường Thắng Nhất" },
  { value: "13", label: "Phường Rạch Dừa" },
  { value: "14", label: "Phường 10" },
  { value: "15", label: "Phường 11" },
  { value: "16", label: "Phường 12" },
  { value: "17", label: "Xã Long Sơn" },
];
export const bariavungtau_thanhphobaria = [
  { value: "1", label: "Phường Phước Hưng" },
  { value: "2", label: "Phường Phước Hiệp" },
  { value: "3", label: "Phường Phước Nguyên" },
  { value: "4", label: "Phường Long Toàn" },
  { value: "5", label: "Phường Long Tâm" },
  { value: "6", label: "Phường Phước Trung" },
  { value: "7", label: "Phường Long Hương" },
  { value: "8", label: "Phường Kim Dinh" },
  { value: "9", label: "Xã Tân Hưng" },
  { value: "10", label: "Xã Long Phước" },
  { value: "11", label: "Xã Hòa Long" },
];
export const bariavungtau_huyenchauduc = [
  { value: "1", label: "Xã Bàu Chinh" },
  { value: "2", label: "Thị trấn Ngãi Giao" },
  { value: "3", label: "Xã Bình Ba" },
  { value: "4", label: "Xã Suối Nghệ" },
  { value: "5", label: "Xã Xuân Sơn" },
  { value: "6", label: "Xã Sơn Bình" },
  { value: "7", label: "Xã Bình Giã" },
  { value: "8", label: "Xã Bình Trung" },
  { value: "9", label: "Xã Xà Bang" },
  { value: "10", label: "Xã Cù Bị" },
  { value: "11", label: "Xã Láng Lớn" },
  { value: "12", label: "Xã Quảng Thành" },
  { value: "13", label: "Xã Kim Long" },
  { value: "14", label: "Xã Suối Rao" },
  { value: "15", label: "Xã Đá Bạc" },
  { value: "16", label: "Xã Nghĩa Thành" },
];
export const bariavungtau_huyenxuyenmoc = [
  { value: "1", label: "Thị trấn Phước Bửu" },
  { value: "2", label: "Xã Phước Thuận" },
  { value: "3", label: "Xã Phước Tân" },
  { value: "4", label: "Xã Xuyên Mộc" },
  { value: "5", label: "Xã Bông Trang" },
  { value: "6", label: "Xã Tân Lâm" },
  { value: "7", label: "Xã Bàu Lâm" },
  { value: "8", label: "Xã Hòa Bình" },
  { value: "9", label: "Xã Hòa Hưng" },
  { value: "10", label: "Xã Hòa Hiệp" },
  { value: "11", label: "Xã Hòa Hội" },
  { value: "12", label: "Xã Bưng Riềng" },
  { value: "13", label: "Xã Bình Châu" },
];
export const bariavungtau_huyenlongdien = [
  { value: "1", label: "Thị trấn Long Điền" },
  { value: "2", label: "Thị trấn Long Hải" },
  { value: "3", label: "Xã An Ngãi" },
  { value: "4", label: "Xã Tam Phước" },
  { value: "5", label: "Xã An Nhứt" },
  { value: "6", label: "Xã Phước Tỉnh" },
  { value: "7", label: "Xã Phước Hưng" },
];
export const bariavungtau_huyendatdo = [
  { value: "1", label: "Thị trấn Đất Đỏ" },
  { value: "2", label: "Xã Phước Long Thọ" },
  { value: "3", label: "Xã Phước Hội" },
  { value: "4", label: "Xã Long Mỹ" },
  { value: "5", label: "Thị trấn Phước Hải" },
  { value: "6", label: "Xã Long Tân" },
  { value: "7", label: "Xã Láng Dài" },
  { value: "8", label: "Xã Lộc An" },
];
export const bariavungtau_huyentanthanh = [
  { value: "1", label: "Thị trấn Phú Mỹ" },
  { value: "2", label: "Xã Tân Hòa" },
  { value: "3", label: "Xã Tân Hải" },
  { value: "4", label: "Xã Phước Hòa" },
  { value: "5", label: "Xã Tân Phước" },
  { value: "6", label: "Xã Mỹ Xuân" },
  { value: "7", label: "Xã Sông Xoài" },
  { value: "8", label: "Xã Hắc Dịch" },
  { value: "9", label: "Xã Châu Pha" },
  { value: "10", label: "Xã Tóc Tiên" },
];
export const longan_thanhphotanan = [
  { value: "1", label: "Phường 5" },
  { value: "2", label: "Phường 2" },
  { value: "3", label: "Phường 4" },
  { value: "4", label: "Phường Tân Khánh" },
  { value: "5", label: "Phường 1" },
  { value: "6", label: "Phường 3" },
  { value: "7", label: "Phường 7" },
  { value: "8", label: "Phường 6" },
  { value: "9", label: "Xã Hướng Thọ Phú" },
  { value: "10", label: "Xã Nhơn Thạnh Trung" },
  { value: "11", label: "Xã Lợi Bình Nhơn" },
  { value: "12", label: "Xã Bình Tâm" },
  { value: "13", label: "Phường Khánh Hậu" },
  { value: "14", label: "Xã An Vĩnh Ngãi" },
];
export const longan_xakientuong = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường 2" },
  { value: "3", label: "Xã Thạnh Trị" },
  { value: "4", label: "Xã Bình Hiệp" },
  { value: "5", label: "Xã Bình Tân" },
  { value: "6", label: "Xã Tuyên Thạnh" },
  { value: "7", label: "Phường 3" },
  { value: "8", label: "Xã Thạnh Hưng" },
];
export const longan_huyentanhung = [
  { value: "1", label: "Thị trấn Tân Hưng" },
  { value: "2", label: "Xã Hưng Hà" },
  { value: "3", label: "Xã Hưng Điền B" },
  { value: "4", label: "Xã Hưng Điền" },
  { value: "5", label: "Xã Thạnh Hưng" },
  { value: "6", label: "Xã Hưng Thạnh" },
  { value: "7", label: "Xã Vĩnh Thạnh" },
  { value: "8", label: "Xã Vĩnh Châu B" },
  { value: "9", label: "Xã Vĩnh Lợi" },
  { value: "10", label: "Xã Vĩnh Đại" },
  { value: "11", label: "Xã Vĩnh Châu A" },
  { value: "12", label: "Xã Vĩnh Bửu" },
];
export const longan_huyenvinhhung = [
  { value: "1", label: "Thị trấn Vĩnh Hưng" },
  { value: "2", label: "Xã Hưng Điền A" },
  { value: "3", label: "Xã Khánh Hưng" },
  { value: "4", label: "Xã Thái Trị" },
  { value: "5", label: "Xã Vĩnh Trị" },
  { value: "6", label: "Xã Thái Bình Trung" },
  { value: "7", label: "Xã Vĩnh Bình" },
  { value: "8", label: "Xã Vĩnh Thuận" },
  { value: "9", label: "Xã Tuyên Bình" },
  { value: "10", label: "Xã Tuyên Bình Tây" },
];
export const longan_huyenmochoa = [
  { value: "1", label: "Xã Bình Hòa Tây" },
  { value: "2", label: "Xã Bình Thạnh" },
  { value: "3", label: "Xã Bình Hòa Trung" },
  { value: "4", label: "Xã Bình Hòa Đông" },
  { value: "5", label: "Xã Bình Phong Thạnh" },
  { value: "6", label: "Xã Tân Lập" },
  { value: "7", label: "Xã Tân Thành" },
];
export const longan_huyentanthanh = [
  { value: "1", label: "Thị trấn Tân Thạnh" },
  { value: "2", label: "Xã Bắc Hòa" },
  { value: "3", label: "Xã Hậu Thạnh Tây" },
  { value: "4", label: "Xã Nhơn Hòa Lập" },
  { value: "5", label: "Xã Tân Lập" },
  { value: "6", label: "Xã Hậu Thạnh Đông" },
  { value: "7", label: "Xã Nhơn Hòa" },
  { value: "8", label: "Xã Kiến Bình" },
  { value: "9", label: "Xã Tân Thành" },
  { value: "10", label: "Xã Tân Bình" },
  { value: "11", label: "Xã Tân Ninh" },
  { value: "12", label: "Xã Nhơn Ninh" },
  { value: "13", label: "Xã Tân Hòa" },
];
export const longan_huyenthanhhoa = [
  { value: "1", label: "Thị trấn Thạnh Hóa" },
  { value: "2", label: "Xã Tân Hiệp" },
  { value: "3", label: "Xã Thuận Bình" },
  { value: "4", label: "Xã Thạnh Phước" },
  { value: "5", label: "Xã Thạnh Phú" },
  { value: "6", label: "Xã Thuận Nghĩa Hòa" },
  { value: "7", label: "Xã Thủy Đông" },
  { value: "8", label: "Xã Thủy Tây" },
  { value: "9", label: "Xã Tân Tây" },
  { value: "10", label: "Xã Tân Đông" },
  { value: "11", label: "Xã Thạnh An" },
];
export const longan_huyenduchue = [
  { value: "1", label: "Thị trấn Đông Thành" },
  { value: "2", label: "Xã Mỹ Quý Đông" },
  { value: "3", label: "Xã Mỹ Thạnh Bắc" },
  { value: "4", label: "Xã Mỹ Quý Tây" },
  { value: "5", label: "Xã Mỹ Thạnh Tây" },
  { value: "6", label: "Xã Mỹ Thạnh Đông" },
  { value: "7", label: "Xã Bình Thành" },
  { value: "8", label: "Xã Bình Hòa Bắc" },
  { value: "9", label: "Xã Bình Hòa Hưng" },
  { value: "10", label: "Xã Bình Hòa Nam" },
  { value: "11", label: "Xã Mỹ Bình" },
];
export const longan_huyenduchoa = [
  { value: "1", label: "Thị trấn Hậu Nghĩa" },
  { value: "2", label: "Thị trấn Hiệp Hòa" },
  { value: "3", label: "Thị trấn Đức Hòa" },
  { value: "4", label: "Xã Lộc Giang" },
  { value: "5", label: "Xã An Ninh Đông" },
  { value: "6", label: "Xã An Ninh Tây" },
  { value: "7", label: "Xã Tân Mỹ" },
  { value: "8", label: "Xã Hiệp Hòa" },
  { value: "9", label: "Xã Đức Lập Thượng" },
  { value: "10", label: "Xã Đức Lập Hạ" },
  { value: "11", label: "Xã Tân Phú" },
  { value: "12", label: "Xã Mỹ Hạnh Bắc" },
  { value: "13", label: "Xã Đức Hòa Thượng" },
  { value: "14", label: "Xã Hòa Khánh Tây" },
  { value: "15", label: "Xã Hòa Khánh Đông" },
  { value: "16", label: "Xã Mỹ Hạnh Nam" },
  { value: "17", label: "Xã Hòa Khánh Nam" },
  { value: "18", label: "Xã Đức Hòa Đông" },
  { value: "19", label: "Xã Đức Hòa Hạ" },
  { value: "20", label: "Xã Hựu Thạnh" },
];
export const longan_huyenbenluc = [
  { value: "1", label: "Thị trấn Bến Lức" },
  { value: "2", label: "Xã Thạnh Lợi" },
  { value: "3", label: "Xã Lương Bình" },
  { value: "4", label: "Xã Thạnh Hòa" },
  { value: "5", label: "Xã Lương Hòa" },
  { value: "6", label: "Xã Tân Hòa" },
  { value: "7", label: "Xã Tân Bửu" },
  { value: "8", label: "Xã An Thạnh" },
  { value: "9", label: "Xã Bình Đức" },
  { value: "10", label: "Xã Mỹ Yên" },
  { value: "11", label: "Xã Thanh Phú" },
  { value: "12", label: "Xã Long Hiệp" },
  { value: "13", label: "Xã Thạnh Đức" },
  { value: "14", label: "Xã Phước Lợi" },
  { value: "15", label: "Xã Nhựt Chánh" },
];
export const longan_huyenthuthua = [
  { value: "1", label: "Thị trấn Thủ Thừa" },
  { value: "2", label: "Xã Long Thạnh" },
  { value: "3", label: "Xã Tân Thành" },
  { value: "4", label: "Xã Long Thuận" },
  { value: "5", label: "Xã Mỹ Lạc" },
  { value: "6", label: "Xã Mỹ Thạnh" },
  { value: "7", label: "Xã Bình An" },
  { value: "8", label: "Xã Nhị Thành" },
  { value: "9", label: "Xã Mỹ An" },
  { value: "10", label: "Xã Bình Thạnh" },
  { value: "11", label: "Xã Mỹ Phú" },
  { value: "12", label: "Xã Long Thành" },
  { value: "13", label: "Xã Tân Lập" },
];
export const longan_huyentantru = [
  { value: "1", label: "Thị trấn Tân Trụ" },
  { value: "2", label: "Xã Mỹ Bình" },
  { value: "3", label: "Xã An Nhựt Tân" },
  { value: "4", label: "Xã Quê Mỹ Thạnh" },
  { value: "5", label: "Xã Lạc Tấn" },
  { value: "6", label: "Xã Bình Trinh Đông" },
  { value: "7", label: "Xã Tân Phước Tây" },
  { value: "8", label: "Xã Bình Lãng" },
  { value: "9", label: "Xã Bình Tịnh" },
  { value: "10", label: "Xã Đức Tân" },
  { value: "11", label: "Xã Nhựt Ninh" },
];
export const longan_huyencanduoc = [
  { value: "1", label: "Thị trấn Cần Đước" },
  { value: "2", label: "Xã Long Trạch" },
  { value: "3", label: "Xã Long Khê" },
  { value: "4", label: "Xã Long Định" },
  { value: "5", label: "Xã Phước Vân" },
  { value: "6", label: "Xã Long Hòa" },
  { value: "7", label: "Xã Long Cang" },
  { value: "8", label: "Xã Long Sơn" },
  { value: "9", label: "Xã Tân Trạch" },
  { value: "10", label: "Xã Mỹ Lệ" },
  { value: "11", label: "Xã Tân Lân" },
  { value: "12", label: "Xã Phước Tuy" },
  { value: "13", label: "Xã Long Hựu Đông" },
  { value: "14", label: "Xã Tân Ân" },
  { value: "15", label: "Xã Phước Đông" },
  { value: "16", label: "Xã Long Hựu Tây" },
  { value: "17", label: "Xã Tân Chánh" },
];
export const longan_huyencangiuoc = [
  { value: "1", label: "Thị trấn Cần Giuộc" },
  { value: "2", label: "Xã Phước Lý" },
  { value: "3", label: "Xã Long Thượng" },
  { value: "4", label: "Xã Long Hậu" },
  { value: "5", label: "Xã Tân Kim" },
  { value: "6", label: "Xã Phước Hậu" },
  { value: "7", label: "Xã Mỹ Lộc" },
  { value: "8", label: "Xã Phước Lại" },
  { value: "9", label: "Xã Phước Lâm" },
  { value: "10", label: "Xã Trường Bình" },
  { value: "11", label: "Xã Thuận Thành" },
  { value: "12", label: "Xã Phước Vĩnh Tây" },
  { value: "13", label: "Xã Phước Vĩnh Đông" },
  { value: "14", label: "Xã Long An" },
  { value: "15", label: "Xã Long Phụng" },
  { value: "16", label: "Xã Đông Thạnh" },
  { value: "17", label: "Xã Tân Tập" },
];
export const longan_huyenchauthanh = [
  { value: "1", label: "Thị trấn Tầm Vu" },
  { value: "2", label: "Xã Bình Quới" },
  { value: "3", label: "Xã Hòa Phú" },
  { value: "4", label: "Xã Phú Ngãi Trị" },
  { value: "5", label: "Xã Vĩnh Công" },
  { value: "6", label: "Xã Thuận Mỹ" },
  { value: "7", label: "Xã Hiệp Thạnh" },
  { value: "8", label: "Xã Phước Tân Hưng" },
  { value: "9", label: "Xã Thanh Phú Long" },
  { value: "10", label: "Xã Dương Xuân Hội" },
  { value: "11", label: "Xã An Lục Long" },
  { value: "12", label: "Xã Long Trì" },
  { value: "13", label: "Xã Thanh Vĩnh Đông" },
];
export const tiengiang_thanhphomytho = [
  { value: "1", label: "Phường 5" },
  { value: "2", label: "Phường 4" },
  { value: "3", label: "Phường 7" },
  { value: "4", label: "Phường 3" },
  { value: "5", label: "Phường 1" },
  { value: "6", label: "Phường 2" },
  { value: "7", label: "Phường 8" },
  { value: "8", label: "Phường 6" },
  { value: "9", label: "Phường 9" },
  { value: "10", label: "Phường 10" },
  { value: "11", label: "Phường Tân Long" },
  { value: "12", label: "Xã Đạo Thạnh" },
  { value: "13", label: "Xã Trung An" },
  { value: "14", label: "Xã Mỹ Phong" },
  { value: "15", label: "Xã Tân Mỹ Chánh" },
  { value: "16", label: "Xã Phước Thạnh" },
  { value: "17", label: "Xã Thới Sơn" },
];
export const tiengiang_xagocong = [
  { value: "1", label: "Phường 3" },
  { value: "2", label: "Phường 2" },
  { value: "3", label: "Phường 4" },
  { value: "4", label: "Phường 1" },
  { value: "5", label: "Phường 5" },
  { value: "6", label: "Xã Long Hưng" },
  { value: "7", label: "Xã Long Thuận" },
  { value: "8", label: "Xã Long Chánh" },
  { value: "9", label: "Xã Long Hòa" },
  { value: "10", label: "Xã Bình Đông" },
  { value: "11", label: "Xã Bình Xuân" },
  { value: "12", label: "Xã Tân Trung" },
];
export const tiengiang_xacailay = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường 2" },
  { value: "3", label: "Phường 3" },
  { value: "4", label: "Phường 4" },
  { value: "5", label: "Phường 5" },
  { value: "6", label: "Xã Mỹ Phước Tây" },
  { value: "7", label: "Xã Mỹ Hạnh Đông" },
  { value: "8", label: "Xã Mỹ Hạnh Trung" },
  { value: "9", label: "Xã Tân Phú" },
  { value: "10", label: "Xã Tân Bình" },
  { value: "11", label: "Xã Tân Hội" },
  { value: "12", label: "Phường Nhị Mỹ" },
  { value: "13", label: "Xã Nhị Quý" },
  { value: "14", label: "Xã Thanh Hòa" },
  { value: "15", label: "Xã Phú Quý" },
  { value: "16", label: "Xã Long Khánh" },
];
export const tiengiang_huyentanphuoc = [
  { value: "1", label: "Thị trấn Mỹ Phước" },
  { value: "2", label: "Xã Tân Hòa Đông" },
  { value: "3", label: "Xã Thạnh Tân" },
  { value: "4", label: "Xã Thạnh Mỹ" },
  { value: "5", label: "Xã Thạnh Hòa" },
  { value: "6", label: "Xã Phú Mỹ" },
  { value: "7", label: "Xã Tân Hòa Thành" },
  { value: "8", label: "Xã Hưng Thạnh" },
  { value: "9", label: "Xã Tân Lập 1" },
  { value: "10", label: "Xã Tân Hòa Tây" },
  { value: "11", label: "Xã Mỹ Phước" },
  { value: "12", label: "Xã Tân Lập 2" },
  { value: "13", label: "Xã Phước Lập" },
];
export const tiengiang_huyencaibe = [
  { value: "1", label: "Thị trấn Cái Bè" },
  { value: "2", label: "Xã Hậu Mỹ Bắc B" },
  { value: "3", label: "Xã Hậu Mỹ Bắc A" },
  { value: "4", label: "Xã Mỹ Trung" },
  { value: "5", label: "Xã Hậu Mỹ Trinh" },
  { value: "6", label: "Xã Hậu Mỹ Phú" },
  { value: "7", label: "Xã Mỹ Tân" },
  { value: "8", label: "Xã Mỹ Lợi B" },
  { value: "9", label: "Xã Thiện Trung" },
  { value: "10", label: "Xã Mỹ Hội" },
  { value: "11", label: "Xã An Cư" },
  { value: "12", label: "Xã Hậu Thành" },
  { value: "13", label: "Xã Mỹ Lợi A" },
  { value: "14", label: "Xã Hòa Khánh" },
  { value: "15", label: "Xã Thiện Trí" },
  { value: "16", label: "Xã Mỹ Đức Đông" },
  { value: "17", label: "Xã Mỹ Đức Tây" },
  { value: "18", label: "Xã Đông Hòa Hiệp" },
  { value: "19", label: "Xã An Thái Đông" },
  { value: "20", label: "Xã Tân Hưng" },
  { value: "21", label: "Xã Mỹ Lương" },
  { value: "22", label: "Xã Tân Thanh" },
  { value: "23", label: "Xã An Thái Trung" },
  { value: "24", label: "Xã An Hữu" },
  { value: "25", label: "Xã Hòa Hưng" },
];
export const tiengiang_huyencailay = [
  { value: "1", label: "Xã Thạnh Lộc" },
  { value: "2", label: "Xã Mỹ Thành Bắc" },
  { value: "3", label: "Xã Phú Cường" },
  { value: "4", label: "Xã Mỹ Thành Nam" },
  { value: "5", label: "Xã Phú Nhuận" },
  { value: "6", label: "Xã Bình Phú" },
  { value: "7", label: "Xã Cẩm Sơn" },
  { value: "8", label: "Xã Phú An" },
  { value: "9", label: "Xã Mỹ Long" },
  { value: "10", label: "Xã Long Tiên" },
  { value: "11", label: "Xã Hiệp Đức" },
  { value: "12", label: "Xã Long Trung" },
  { value: "13", label: "Xã Hội Xuân" },
  { value: "14", label: "Xã Tân Phong" },
  { value: "15", label: "Xã Tam Bình" },
  { value: "16", label: "Xã Ngũ Hiệp" },
];
export const tiengiang_huyenchauthanh = [
  { value: "1", label: "Thị trấn Tân Hiệp" },
  { value: "2", label: "Xã Tân Hội Đông" },
  { value: "3", label: "Xã Tân Hương" },
  { value: "4", label: "Xã Tân Lý Đông" },
  { value: "5", label: "Xã Tân Lý Tây" },
  { value: "6", label: "Xã Thân Cửu Nghĩa" },
  { value: "7", label: "Xã Tam Hiệp" },
  { value: "8", label: "Xã Điềm Hy" },
  { value: "9", label: "Xã Nhị Bình" },
  { value: "10", label: "Xã Dưỡng Điềm" },
  { value: "11", label: "Xã Đông Hòa" },
  { value: "12", label: "Xã Long Định" },
  { value: "13", label: "Xã Hữu Đạo" },
  { value: "14", label: "Xã Long An" },
  { value: "15", label: "Xã Long Hưng" },
  { value: "16", label: "Xã Bình Trưng" },
  { value: "17", label: "Xã Thạnh Phú" },
  { value: "18", label: "Xã Bàn Long" },
  { value: "19", label: "Xã Vĩnh Kim" },
  { value: "20", label: "Xã Bình Đức" },
  { value: "21", label: "Xã Song Thuận" },
  { value: "22", label: "Xã Kim Sơn" },
  { value: "23", label: "Xã Phú Phong" },
];
export const tiengiang_huyenchogao = [
  { value: "1", label: "Thị trấn Chợ Gạo" },
  { value: "2", label: "Xã Trung Hòa" },
  { value: "3", label: "Xã Hòa Tịnh" },
  { value: "4", label: "Xã Mỹ Tịnh An" },
  { value: "5", label: "Xã Tân Bình Thạnh" },
  { value: "6", label: "Xã Phú Kiết" },
  { value: "7", label: "Xã Lương Hòa Lạc" },
  { value: "8", label: "Xã Thanh Bình" },
  { value: "9", label: "Xã Quơn Long" },
  { value: "10", label: "Xã Bình Phục Nhứt" },
  { value: "11", label: "Xã Đăng Hưng Phước" },
  { value: "12", label: "Xã Tân Thuận Bình" },
  { value: "13", label: "Xã Song Bình" },
  { value: "14", label: "Xã Bình Phan" },
  { value: "15", label: "Xã Long Bình Điền" },
  { value: "16", label: "Xã An Thạnh Thủy" },
  { value: "17", label: "Xã Xuân Đông" },
  { value: "18", label: "Xã Hòa Định" },
  { value: "19", label: "Xã Bình Ninh" },
];
export const tiengiang_huyengocongtay = [
  { value: "1", label: "Thị trấn Vĩnh Bình" },
  { value: "2", label: "Xã Đồng Sơn" },
  { value: "3", label: "Xã Bình Phú" },
  { value: "4", label: "Xã Đồng Thạnh" },
  { value: "5", label: "Xã Thành Công" },
  { value: "6", label: "Xã Bình Nhì" },
  { value: "7", label: "Xã Yên Luông" },
  { value: "8", label: "Xã Thạnh Trị" },
  { value: "9", label: "Xã Thạnh Nhựt" },
  { value: "10", label: "Xã Long Vĩnh" },
  { value: "11", label: "Xã Bình Tân" },
  { value: "12", label: "Xã Vĩnh Hựu" },
  { value: "13", label: "Xã Long Bình" },
];
export const tiengiang_huyengocongdong = [
  { value: "1", label: "Thị trấn Tân Hòa" },
  { value: "2", label: "Xã Tăng Hòa" },
  { value: "3", label: "Xã Tân Phước" },
  { value: "4", label: "Xã Gia Thuận" },
  { value: "5", label: "Thị trấn Vàm Láng" },
  { value: "6", label: "Xã Tân Tây" },
  { value: "7", label: "Xã Kiểng Phước" },
  { value: "8", label: "Xã Tân Đông" },
  { value: "9", label: "Xã Bình Ân" },
  { value: "10", label: "Xã Tân Điền" },
  { value: "11", label: "Xã Bình Nghị" },
  { value: "12", label: "Xã Phước Trung" },
  { value: "13", label: "Xã Tân Thành" },
];
export const tiengiang_huyentanphudong = [
  { value: "1", label: "Xã Tân Thới" },
  { value: "2", label: "Xã Tân Phú" },
  { value: "3", label: "Xã Phú Thạnh" },
  { value: "4", label: "Xã Tân Thạnh" },
  { value: "5", label: "Xã Phú Đông" },
  { value: "6", label: "Xã Phú Tân" },
];
export const bentre_thanhphobentre = [
  { value: "1", label: "Phường Phú Khương" },
  { value: "2", label: "Phường Phú Tân" },
  { value: "3", label: "Phường 8" },
  { value: "4", label: "Phường 6" },
  { value: "5", label: "Phường 4" },
  { value: "6", label: "Phường 5" },
  { value: "7", label: "Phường 1" },
  { value: "8", label: "Phường 3" },
  { value: "9", label: "Phường 2" },
  { value: "10", label: "Phường 7" },
  { value: "11", label: "Xã Sơn Đông" },
  { value: "12", label: "Xã Phú Hưng" },
  { value: "13", label: "Xã Bình Phú" },
  { value: "14", label: "Xã Mỹ Thạnh An" },
  { value: "15", label: "Xã Nhơn Thạnh" },
  { value: "16", label: "Xã Phú Nhuận" },
  { value: "17", label: "Xã Mỹ Thành" },
];
export const bentre_huyenchauthanh = [
  { value: "1", label: "Thị trấn Châu Thành" },
  { value: "2", label: "Xã Tân Thạch" },
  { value: "3", label: "Xã Qưới Sơn" },
  { value: "4", label: "Xã An Khánh" },
  { value: "5", label: "Xã Giao Long" },
  { value: "6", label: "Xã Giao Hòa" },
  { value: "7", label: "Xã Phú Túc" },
  { value: "8", label: "Xã Phú Đức" },
  { value: "9", label: "Xã Phú An Hòa" },
  { value: "10", label: "Xã An Phước" },
  { value: "11", label: "Xã Tam Phước" },
  { value: "12", label: "Xã Thành Triệu" },
  { value: "13", label: "Xã Tường Đa" },
  { value: "14", label: "Xã Tân Phú" },
  { value: "15", label: "Xã Quới Thành" },
  { value: "16", label: "Xã Phước Thạnh" },
  { value: "17", label: "Xã An Hóa" },
  { value: "18", label: "Xã Tiên Long" },
  { value: "19", label: "Xã An Hiệp" },
  { value: "20", label: "Xã Hữu Định" },
  { value: "21", label: "Xã Tiên Thủy" },
  { value: "22", label: "Xã Sơn Hòa" },
];
export const bentre_huyencholach = [
  { value: "1", label: "Thị trấn Chợ Lách" },
  { value: "2", label: "Xã Phú Phụng" },
  { value: "3", label: "Xã Sơn Định" },
  { value: "4", label: "Xã Vĩnh Bình" },
  { value: "5", label: "Xã Hòa Nghĩa" },
  { value: "6", label: "Xã Long Thới" },
  { value: "7", label: "Xã Phú Sơn" },
  { value: "8", label: "Xã Tân Thiềng" },
  { value: "9", label: "Xã Vĩnh Thành" },
  { value: "10", label: "Xã Vĩnh Hòa" },
  { value: "11", label: "Xã Hưng Khánh Trung B" },
];
export const bentre_huyenmocaynam = [
  { value: "1", label: "Thị trấn Mỏ Cày" },
  { value: "2", label: "Xã Định Thủy" },
  { value: "3", label: "Xã Đa Phước Hội" },
  { value: "4", label: "Xã Tân Hội" },
  { value: "5", label: "Xã Phước Hiệp" },
  { value: "6", label: "Xã Bình Khánh Đông" },
  { value: "7", label: "Xã An Thạnh" },
  { value: "8", label: "Xã Bình Khánh Tây" },
  { value: "9", label: "Xã An Định" },
  { value: "10", label: "Xã Thành Thới B" },
  { value: "11", label: "Xã Tân Trung" },
  { value: "12", label: "Xã An Thới" },
  { value: "13", label: "Xã Thành Thới A" },
  { value: "14", label: "Xã Minh Đức" },
  { value: "15", label: "Xã Ngãi Đăng" },
  { value: "16", label: "Xã Cẩm Sơn" },
  { value: "17", label: "Xã Hương Mỹ" },
];
export const bentre_huyengiongtrom = [
  { value: "1", label: "Thị trấn Giồng Trôm" },
  { value: "2", label: "Xã Phong Nẫm" },
  { value: "3", label: "Xã Phong Mỹ" },
  { value: "4", label: "Xã Mỹ Thạnh" },
  { value: "5", label: "Xã Châu Hòa" },
  { value: "6", label: "Xã Lương Hòa" },
  { value: "7", label: "Xã Lương Quới" },
  { value: "8", label: "Xã Lương Phú" },
  { value: "9", label: "Xã Châu Bình" },
  { value: "10", label: "Xã Thuận Điền" },
  { value: "11", label: "Xã Sơn Phú" },
  { value: "12", label: "Xã Bình Hòa" },
  { value: "13", label: "Xã Phước Long" },
  { value: "14", label: "Xã Hưng Phong" },
  { value: "15", label: "Xã Long Mỹ" },
  { value: "16", label: "Xã Tân Hào" },
  { value: "17", label: "Xã Bình Thành" },
  { value: "18", label: "Xã Tân Thanh" },
  { value: "19", label: "Xã Tân Lợi Thạnh" },
  { value: "20", label: "Xã Thạnh Phú Đông" },
  { value: "21", label: "Xã Hưng Nhượng" },
  { value: "22", label: "Xã Hưng Lễ" },
];
export const bentre_huyenbinhdai = [
  { value: "1", label: "Thị trấn Bình Đại" },
  { value: "2", label: "Xã Tam Hiệp" },
  { value: "3", label: "Xã Long Định" },
  { value: "4", label: "Xã Long Hòa" },
  { value: "5", label: "Xã Phú Thuận" },
  { value: "6", label: "Xã Vang Quới Tây" },
  { value: "7", label: "Xã Vang Quới Đông" },
  { value: "8", label: "Xã Châu Hưng" },
  { value: "9", label: "Xã Phú Vang" },
  { value: "10", label: "Xã Lộc Thuận" },
  { value: "11", label: "Xã Định Trung" },
  { value: "12", label: "Xã Thới Lai" },
  { value: "13", label: "Xã Bình Thới" },
  { value: "14", label: "Xã Phú Long" },
  { value: "15", label: "Xã Bình Thắng" },
  { value: "16", label: "Xã Thạnh Trị" },
  { value: "17", label: "Xã Đại Hòa Lộc" },
  { value: "18", label: "Xã Thừa Đức" },
  { value: "19", label: "Xã Thạnh Phước" },
  { value: "20", label: "Xã Thới Thuận" },
];
export const bentre_huyenbatri = [
  { value: "1", label: "Thị trấn Ba Tri" },
  { value: "2", label: "Xã Tân Mỹ" },
  { value: "3", label: "Xã Mỹ Hòa" },
  { value: "4", label: "Xã Tân Xuân" },
  { value: "5", label: "Xã Mỹ Chánh" },
  { value: "6", label: "Xã Bảo Thạnh" },
  { value: "7", label: "Xã An Phú Trung" },
  { value: "8", label: "Xã Mỹ Thạnh" },
  { value: "9", label: "Xã Mỹ Nhơn" },
  { value: "10", label: "Xã Phước Tuy" },
  { value: "11", label: "Xã Phú Ngãi" },
  { value: "12", label: "Xã An Ngãi Trung" },
  { value: "13", label: "Xã Phú Lễ" },
  { value: "14", label: "Xã An Bình Tây" },
  { value: "15", label: "Xã Bảo Thuận" },
  { value: "16", label: "Xã Tân Hưng" },
  { value: "17", label: "Xã An Ngãi Tây" },
  { value: "18", label: "Xã An Hiệp" },
  { value: "19", label: "Xã Vĩnh Hòa" },
  { value: "20", label: "Xã Tân Thủy" },
  { value: "21", label: "Xã Vĩnh An" },
  { value: "22", label: "Xã An Đức" },
  { value: "23", label: "Xã An Hòa Tây" },
  { value: "24", label: "Xã An Thủy" },
];
export const bentre_huyenthanhphu = [
  { value: "1", label: "Thị trấn Thạnh Phú" },
  { value: "2", label: "Xã Phú Khánh" },
  { value: "3", label: "Xã Đại Điền" },
  { value: "4", label: "Xã Quới Điền" },
  { value: "5", label: "Xã Tân Phong" },
  { value: "6", label: "Xã Mỹ Hưng" },
  { value: "7", label: "Xã An Thạnh" },
  { value: "8", label: "Xã Thới Thạnh" },
  { value: "9", label: "Xã Hòa Lợi" },
  { value: "10", label: "Xã An Điền" },
  { value: "11", label: "Xã Bình Thạnh" },
  { value: "12", label: "Xã An Thuận" },
  { value: "13", label: "Xã An Quy" },
  { value: "14", label: "Xã Thạnh Hải" },
  { value: "15", label: "Xã An Nhơn" },
  { value: "16", label: "Xã Giao Thạnh" },
  { value: "17", label: "Xã Thạnh Phong" },
  { value: "18", label: "Xã Mỹ An" },
];
export const bentre_huyenmocaybac = [
  { value: "1", label: "Xã Phú Mỹ" },
  { value: "2", label: "Xã Hưng Khánh Trung A" },
  { value: "3", label: "Xã Thanh Tân" },
  { value: "4", label: "Xã Thạnh Ngãi" },
  { value: "5", label: "Xã Tân Phú Tây" },
  { value: "6", label: "Xã Phước Mỹ Trung" },
  { value: "7", label: "Xã Tân Thành Bình" },
  { value: "8", label: "Xã Thành An" },
  { value: "9", label: "Xã Hòa Lộc" },
  { value: "10", label: "Xã Tân Thanh Tây" },
  { value: "11", label: "Xã Tân Bình" },
  { value: "12", label: "Xã Nhuận Phú Tân" },
  { value: "13", label: "Xã Khánh Thạnh Tân" },
];
export const travinh_thanhphotravinh = [
  { value: "1", label: "Phường 4" },
  { value: "2", label: "Phường 1" },
  { value: "3", label: "Phường 3" },
  { value: "4", label: "Phường 2" },
  { value: "5", label: "Phường 5" },
  { value: "6", label: "Phường 6" },
  { value: "7", label: "Phường 7" },
  { value: "8", label: "Phường 8" },
  { value: "9", label: "Phường 9" },
  { value: "10", label: "Xã Long Đức" },
];
export const travinh_huyencanglong = [
  { value: "1", label: "Thị trấn Càng Long" },
  { value: "2", label: "Xã Mỹ Cẩm" },
  { value: "3", label: "Xã An Trường A" },
  { value: "4", label: "Xã An Trường" },
  { value: "5", label: "Xã Huyền Hội" },
  { value: "6", label: "Xã Tân An" },
  { value: "7", label: "Xã Tân Bình" },
  { value: "8", label: "Xã Bình Phú" },
  { value: "9", label: "Xã Phương Thạnh" },
  { value: "10", label: "Xã Đại Phúc" },
  { value: "11", label: "Xã Đại Phước" },
  { value: "12", label: "Xã Nhị Long Phú" },
  { value: "13", label: "Xã Nhị Long" },
  { value: "14", label: "Xã Đức Mỹ" },
];
export const travinh_huyencauke = [
  { value: "1", label: "Thị trấn Cầu Kè" },
  { value: "2", label: "Xã Hòa Ân" },
  { value: "3", label: "Xã Châu Điền" },
  { value: "4", label: "Xã An Phú Tân" },
  { value: "5", label: "Xã Hòa Tân" },
  { value: "6", label: "Xã Ninh Thới" },
  { value: "7", label: "Xã Phong Phú" },
  { value: "8", label: "Xã Phong Thạnh" },
  { value: "9", label: "Xã Tam Ngãi" },
  { value: "10", label: "Xã Thông Hòa" },
  { value: "11", label: "Xã Thạnh Phú" },
];
export const travinh_huyentieucan = [
  { value: "1", label: "Thị trấn Tiểu Cần" },
  { value: "2", label: "Thị trấn Cầu Quan" },
  { value: "3", label: "Xã Phú Cần" },
  { value: "4", label: "Xã Hiếu Tử" },
  { value: "5", label: "Xã Hiếu Trung" },
  { value: "6", label: "Xã Long Thới" },
  { value: "7", label: "Xã Hùng Hòa" },
  { value: "8", label: "Xã Tân Hùng" },
  { value: "9", label: "Xã Tập Ngãi" },
  { value: "10", label: "Xã Ngãi Hùng" },
  { value: "11", label: "Xã Tân Hòa" },
];
export const travinh_huyenchauthanh = [
  { value: "1", label: "Thị trấn Châu Thành" },
  { value: "2", label: "Xã Đa Lộc" },
  { value: "3", label: "Xã Mỹ Chánh" },
  { value: "4", label: "Xã Thanh Mỹ" },
  { value: "5", label: "Xã Lương Hòa A" },
  { value: "6", label: "Xã Lương Hòa" },
  { value: "7", label: "Xã Song Lộc" },
  { value: "8", label: "Xã Nguyệt Hóa" },
  { value: "9", label: "Xã Hòa Thuận" },
  { value: "10", label: "Xã Hòa Lợi" },
  { value: "11", label: "Xã Phước Hảo" },
  { value: "12", label: "Xã Hưng Mỹ" },
  { value: "13", label: "Xã Hòa Minh" },
  { value: "14", label: "Xã Long Hòa" },
];
export const travinh_huyenaungang = [
  { value: "1", label: "Thị trấn Cầu Ngang" },
  { value: "2", label: "Thị trấn Mỹ Long" },
  { value: "3", label: "Xã Mỹ Long Bắc" },
  { value: "4", label: "Xã Mỹ Long Nam" },
  { value: "5", label: "Xã Mỹ Hòa" },
  { value: "6", label: "Xã Vĩnh Kim" },
  { value: "7", label: "Xã Kim Hòa" },
  { value: "8", label: "Xã Hiệp Hòa" },
  { value: "9", label: "Xã Thuận Hòa" },
  { value: "10", label: "Xã Long Sơn" },
  { value: "11", label: "Xã Nhị Trường" },
  { value: "12", label: "Xã Trường Thọ" },
  { value: "13", label: "Xã Hiệp Mỹ Đông" },
  { value: "14", label: "Xã Hiệp Mỹ Tây" },
  { value: "15", label: "Xã Thạnh Hòa Sơn" },
];
export const travinh_huyentracu = [
  { value: "1", label: "Thị trấn Trà Cú" },
  { value: "2", label: "Thị trấn Định An" },
  { value: "3", label: "Xã Phước Hưng" },
  { value: "4", label: "Xã Tập Sơn" },
  { value: "5", label: "Xã Tân Sơn" },
  { value: "6", label: "Xã An Quảng Hữu" },
  { value: "7", label: "Xã Lưu Nghiệp Anh" },
  { value: "8", label: "Xã Ngãi Xuyên" },
  { value: "9", label: "Xã Kim Sơn" },
  { value: "10", label: "Xã Thanh Sơn" },
  { value: "11", label: "Xã Hàm Giang" },
  { value: "12", label: "Xã Hàm Tân" },
  { value: "13", label: "Xã Đại An" },
  { value: "14", label: "Xã Định An" },
  { value: "15", label: "Xã Ngọc Biên" },
  { value: "16", label: "Xã Long Hiệp" },
  { value: "17", label: "Xã Tân Hiệp" },
];
export const travinh_huyenduyenhai = [
  { value: "1", label: "Xã Đôn Xuân" },
  { value: "2", label: "Xã Đôn Châu" },
  { value: "3", label: "Thị trấn Long Thành" },
  { value: "4", label: "Xã Long Khánh" },
  { value: "5", label: "Xã Ngũ Lạc" },
  { value: "6", label: "Xã Long Vĩnh" },
  { value: "7", label: "Xã Đông Hải" },
];
export const travinh_xaduyenhai = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Xã Long Toàn" },
  { value: "3", label: "Phường 2" },
  { value: "4", label: "Xã Long Hữu" },
  { value: "5", label: "Xã Dân Thành" },
  { value: "6", label: "Xã Trường Long Hòa" },
  { value: "7", label: "Xã Hiệp Thạnh" },
];
export const vinhlong_thanhphovinhlong = [
  { value: "1", label: "Phường 9" },
  { value: "2", label: "Phường 5" },
  { value: "3", label: "Phường 1" },
  { value: "4", label: "Phường 2" },
  { value: "5", label: "Phường 4" },
  { value: "6", label: "Phường 3" },
  { value: "7", label: "Phường 8" },
  { value: "8", label: "Xã Tân Ngãi" },
  { value: "9", label: "Xã Tân Hòa" },
  { value: "10", label: "Xã Tân Hội" },
  { value: "11", label: "Xã Trường An" },
];
export const vinhlong_huyenlongho = [
  { value: "1", label: "Thị trấn Long Hồ" },
  { value: "2", label: "Xã Đồng Phú" },
  { value: "3", label: "Xã Bình Hòa Phước" },
  { value: "4", label: "Xã Hòa Ninh" },
  { value: "5", label: "Xã An Bình" },
  { value: "6", label: "Xã Thanh Đức" },
  { value: "7", label: "Xã Tân Hạnh" },
  { value: "8", label: "Xã Phước Hậu" },
  { value: "9", label: "Xã Long Phước" },
  { value: "10", label: "Xã Phú Đức" },
  { value: "11", label: "Xã Lộc Hòa" },
  { value: "12", label: "Xã Long An" },
  { value: "13", label: "Xã Phú Quới" },
  { value: "14", label: "Xã Thạnh Quới" },
  { value: "15", label: "Xã Hòa Phú" },
];
export const vinhlong_huyenmangthit = [
  { value: "1", label: "Thị trấn Cái Nhum" },
  { value: "2", label: "Xã Mỹ An" },
  { value: "3", label: "Xã Mỹ Phước" },
  { value: "4", label: "Xã An Phước" },
  { value: "5", label: "Xã Nhơn Phú" },
  { value: "6", label: "Xã Long Mỹ" },
  { value: "7", label: "Xã Hòa Tịnh" },
  { value: "8", label: "Xã Chánh Hội" },
  { value: "9", label: "Xã Bình Phước" },
  { value: "10", label: "Xã Chánh An" },
  { value: "11", label: "Xã Tân An Hội" },
  { value: "12", label: "Xã Tân Long" },
  { value: "13", label: "Xã Tân Long Hội" },
];
export const vinhlong_huyenvungliem = [
  { value: "1", label: "Thị trấn Vũng Liêm" },
  { value: "2", label: "Xã Tân Quới Trung" },
  { value: "3", label: "Xã Quới Thiện" },
  { value: "4", label: "Xã Quới An" },
  { value: "5", label: "Xã Trung Chánh" },
  { value: "6", label: "Xã Tân An Luông" },
  { value: "7", label: "Xã Thanh Bình" },
  { value: "8", label: "Xã Trung Thành Tây" },
  { value: "9", label: "Xã Trung Hiệp" },
  { value: "10", label: "Xã Hiếu Phụng" },
  { value: "11", label: "Xã Trung Thành Đông" },
  { value: "12", label: "Xã Trung Thành" },
  { value: "13", label: "Xã Trung Hiếu" },
  { value: "14", label: "Xã Trung Ngãi" },
  { value: "15", label: "Xã Hiếu Thuận" },
  { value: "16", label: "Xã Trung Nghĩa" },
  { value: "17", label: "Xã Trung An" },
  { value: "18", label: "Xã Hiếu Nhơn" },
  { value: "19", label: "Xã Hiếu Thành" },
  { value: "20", label: "Xã Hiếu Nghĩa" },
];
export const vinhlong_huyentambinh = [
  { value: "1", label: "Thị trấn Tam Bình" },
  { value: "2", label: "Xã Tân Lộc" },
  { value: "3", label: "Xã Phú Thịnh" },
  { value: "4", label: "Xã Hậu Lộc" },
  { value: "5", label: "Xã Hòa Thạnh" },
  { value: "6", label: "Xã Hòa Lộc" },
  { value: "7", label: "Xã Phú Lộc" },
  { value: "8", label: "Xã Song Phú" },
  { value: "9", label: "Xã Hòa Hiệp" },
  { value: "10", label: "Xã Mỹ Lộc" },
  { value: "11", label: "Xã Tân Phú" },
  { value: "12", label: "Xã Long Phú" },
  { value: "13", label: "Xã Mỹ Thạnh Trung" },
  { value: "14", label: "Xã Tường Lộc" },
  { value: "15", label: "Xã Loan Mỹ" },
  { value: "16", label: "Xã Ngãi Tứ" },
  { value: "17", label: "Xã Bình Ninh" },
];
export const vinhlong_xabinhminh = [
  { value: "1", label: "Phường Cái Vồn" },
  { value: "2", label: "Phường Thành Phước" },
  { value: "3", label: "Xã Thuận An" },
  { value: "4", label: "Xã Đông Thạnh" },
  { value: "5", label: "Xã Đông Bình" },
  { value: "6", label: "Phường Đông Thuận" },
  { value: "7", label: "Xã Mỹ Hòa" },
  { value: "8", label: "Xã Đông Thành" },
];
export const vinhlong_huyentraon = [
  { value: "1", label: "Thị trấn Trà Ôn" },
  { value: "2", label: "Xã Xuân Hiệp" },
  { value: "3", label: "Xã Nhơn Bình" },
  { value: "4", label: "Xã Hòa Bình" },
  { value: "5", label: "Xã Thới Hòa" },
  { value: "6", label: "Xã Trà Côn" },
  { value: "7", label: "Xã Tân Mỹ" },
  { value: "8", label: "Xã Hựu Thành" },
  { value: "9", label: "Xã Vĩnh Xuân" },
  { value: "10", label: "Xã Thuận Thới" },
  { value: "11", label: "Xã Phú Thành" },
  { value: "12", label: "Xã Thiện Mỹ" },
  { value: "13", label: "Xã Lục Sỹ Thành" },
  { value: "14", label: "Xã Tích Thiện" },
];
export const vinhlong_huyenbinhtan = [
  { value: "1", label: "Xã Tân Hưng" },
  { value: "2", label: "Xã Tân Thành" },
  { value: "3", label: "Xã Thành Trung" },
  { value: "4", label: "Xã Tân An Thạnh" },
  { value: "5", label: "Xã Tân Lược" },
  { value: "6", label: "Xã Nguyễn Văn Thảnh" },
  { value: "7", label: "Xã Thành Đông" },
  { value: "8", label: "Xã Mỹ Thuận" },
  { value: "9", label: "Xã Tân Bình" },
  { value: "10", label: "Xã Thành Lợi" },
  { value: "11", label: "Xã Tân Quới" },
];

export const dongthap_thanhphocaolanh = [
  { value: "1", label: "Phường 11" },
  { value: "2", label: "Phường 1" },
  { value: "3", label: "Phường 2" },
  { value: "4", label: "Phường 4" },
  { value: "5", label: "Phường 3" },
  { value: "6", label: "Phường 6" },
  { value: "7", label: "Xã Mỹ Ngãi" },
  { value: "8", label: "Xã Mỹ Tân" },
  { value: "9", label: "Xã Mỹ Trà" },
  { value: "10", label: "Phường Mỹ Phú" },
  { value: "11", label: "Xã Tân Thuận Tây" },
  { value: "12", label: "Phường Hòa Thuận" },
  { value: "13", label: "Xã Hòa An" },
  { value: "14", label: "Xã Tân Thuận Đông" },
  { value: "15", label: "Xã Tịnh Thới" },
];
export const dongthap_thanhphosadec = [
  { value: "1", label: "Phường 3" },
  { value: "2", label: "Phường 1" },
  { value: "3", label: "Phường 4" },
  { value: "4", label: "Phường 2" },
  { value: "5", label: "Xã Tân Khánh Đông" },
  { value: "6", label: "Phường Tân Quy Đông" },
  { value: "7", label: "Phường An Hòa" },
  { value: "8", label: "Xã Tân Quy Tây" },
  { value: "9", label: "Xã Tân Phú Đông" },
];
export const dongthap_xahongngu = [
  { value: "1", label: "Phường An Lộc" },
  { value: "2", label: "Phường An Thạnh" },
  { value: "3", label: "Xã Bình Thạnh" },
  { value: "4", label: "Xã Tân Hội" },
  { value: "5", label: "Phường An Lạc" },
  { value: "6", label: "Xã An Bình B" },
  { value: "7", label: "Xã An Bình A" },
];
export const dongthap_huyentanhong = [
  { value: "1", label: "Thị trấn Sa Rài" },
  { value: "2", label: "Xã Tân Hộ Cơ" },
  { value: "3", label: "Xã Thông Bình" },
  { value: "4", label: "Xã Bình Phú" },
  { value: "5", label: "Xã Tân Thành A" },
  { value: "6", label: "Xã Tân Thành B" },
  { value: "7", label: "Xã Tân Phước" },
  { value: "8", label: "Xã Tân Công Chí" },
  { value: "9", label: "Xã An Phước" },
];
export const dongthap_huyenhongngu = [
  { value: "1", label: "Xã Thường Phước 1" },
  { value: "2", label: "Xã Thường Thới Hậu A" },
  { value: "3", label: "Xã Thường Thới Hậu B" },
  { value: "4", label: "Xã Thường Thới Tiền" },
  { value: "5", label: "Xã Thường Phước 2" },
  { value: "6", label: "Xã Thường Lạc" },
  { value: "7", label: "Xã Long Khánh A" },
  { value: "8", label: "Xã Long Khánh B" },
  { value: "9", label: "Xã Long Thuận" },
  { value: "10", label: "Xã Phú Thuận B" },
  { value: "11", label: "Xã Phú Thuận A" },
];
export const dongthap_huyentamnong = [
  { value: "1", label: "Thị trấn Tràm Chim" },
  { value: "2", label: "Xã Hòa Bình" },
  { value: "3", label: "Xã Tân Công Sính" },
  { value: "4", label: "Xã Phú Hiệp" },
  { value: "5", label: "Xã Phú Đức" },
  { value: "6", label: "Xã Phú Thành B" },
  { value: "7", label: "Xã An Hòa" },
  { value: "8", label: "Xã An Long" },
  { value: "9", label: "Xã Phú Cường" },
  { value: "10", label: "Xã Phú Ninh" },
  { value: "11", label: "Xã Phú Thọ" },
  { value: "12", label: "Xã Phú Thành A" },
];
export const dongthap_huyenthapmuoi = [
  { value: "1", label: "Thị trấn Mỹ An" },
  { value: "2", label: "Xã Thạnh Lợi" },
  { value: "3", label: "Xã Hưng Thạnh" },
  { value: "4", label: "Xã Trường Xuân" },
  { value: "5", label: "Xã Tân Kiều" },
  { value: "6", label: "Xã Mỹ Hòa" },
  { value: "7", label: "Xã Mỹ Quý" },
  { value: "8", label: "Xã Mỹ Đông" },
  { value: "9", label: "Xã Đốc Binh Kiều" },
  { value: "10", label: "Xã Mỹ An" },
  { value: "11", label: "Xã Phú Điền" },
  { value: "12", label: "Xã Láng Biển" },
  { value: "13", label: "Xã Thanh Mỹ" },
];
export const dongthap_huyencaolanh = [
  { value: "1", label: "Thị trấn Mỹ Thọ" },
  { value: "2", label: "Xã Gáo Giồng" },
  { value: "3", label: "Xã Phương Thịnh" },
  { value: "4", label: "Xã Ba Sao" },
  { value: "5", label: "Xã Phong Mỹ" },
  { value: "6", label: "Xã Tân Nghĩa" },
  { value: "7", label: "Xã Phương Trà" },
  { value: "8", label: "Xã Nhị Mỹ" },
  { value: "9", label: "Xã Mỹ Thọ" },
  { value: "10", label: "Xã Tân Hội Trung" },
  { value: "11", label: "Xã An Bình" },
  { value: "12", label: "Xã Mỹ Hội" },
  { value: "13", label: "Xã Mỹ Hiệp" },
  { value: "14", label: "Xã Mỹ Long" },
  { value: "15", label: "Xã Bình Hàng Trung" },
  { value: "16", label: "Xã Mỹ Xương" },
  { value: "17", label: "Xã Bình Hàng Tây" },
  { value: "18", label: "Xã Bình Thạnh" },
];
export const dongthap_huyenthanhbinh = [
  { value: "1", label: "Thị trấn Thanh Bình" },
  { value: "2", label: "Xã Tân Quới" },
  { value: "3", label: "Xã Tân Hòa" },
  { value: "4", label: "Xã An Phong" },
  { value: "5", label: "Xã Phú Lợi" },
  { value: "6", label: "Xã Tân Mỹ" },
  { value: "7", label: "Xã Bình Tấn" },
  { value: "8", label: "Xã Tân Huề" },
  { value: "9", label: "Xã Tân Bình" },
  { value: "10", label: "Xã Tân Thạnh" },
  { value: "11", label: "Xã Tân Phú" },
  { value: "12", label: "Xã Bình Thành" },
  { value: "13", label: "Xã Tân Long" },
];
export const dongthap_huyenlapvo = [
  { value: "1", label: "Thị trấn Lấp Vò" },
  { value: "2", label: "Xã Mỹ An Hưng A" },
  { value: "3", label: "Xã Tân Mỹ" },
  { value: "4", label: "Xã Mỹ An Hưng B" },
  { value: "5", label: "Xã Tân Khánh Trung" },
  { value: "6", label: "Xã Long Hưng A" },
  { value: "7", label: "Xã Vĩnh Thạnh" },
  { value: "8", label: "Xã Long Hưng B" },
  { value: "9", label: "Xã Bình Thành" },
  { value: "10", label: "Xã Định An" },
  { value: "11", label: "Xã Định Yên" },
  { value: "12", label: "Xã Hội An Đông" },
  { value: "13", label: "Xã Bình Thạnh Trung" },
];
export const dongthap_huyenlaivung = [
  { value: "1", label: "Thị trấn Lai Vung" },
  { value: "2", label: "Xã Tân Dương" },
  { value: "3", label: "Xã Hòa Thành" },
  { value: "4", label: "Xã Long Hậu" },
  { value: "5", label: "Xã Tân Phước" },
  { value: "6", label: "Xã Hòa Long" },
  { value: "7", label: "Xã Tân Thành" },
  { value: "8", label: "Xã Long Thắng" },
  { value: "9", label: "Xã Vĩnh Thới" },
  { value: "10", label: "Xã Tân Hòa" },
  { value: "11", label: "Xã Định Hòa" },
  { value: "12", label: "Xã Phong Hòa" },
];
export const dongthap_huyenchauthanh = [
  { value: "1", label: "Thị trấn Cái Tàu Hạ" },
  { value: "2", label: "Xã An Hiệp" },
  { value: "3", label: "Xã An Nhơn" },
  { value: "4", label: "Xã Tân Nhuận Đông" },
  { value: "5", label: "Xã Tân Bình" },
  { value: "6", label: "Xã Tân Phú Trung" },
  { value: "7", label: "Xã Phú Long" },
  { value: "8", label: "Xã An Phú Thuận" },
  { value: "9", label: "Xã Phú Hựu" },
  { value: "10", label: "Xã An Khánh" },
  { value: "11", label: "Xã Tân Phú" },
  { value: "12", label: "Xã Hòa Tân" },
];
export const angiang_thanhpholongxuyen = [
  { value: "1", label: "Phường Mỹ Bình" },
  { value: "2", label: "Phường Mỹ Long" },
  { value: "3", label: "Phường Đông Xuyên" },
  { value: "4", label: "Phường Mỹ Xuyên" },
  { value: "5", label: "Phường Bình Đức" },
  { value: "6", label: "Phường Bình Khánh" },
  { value: "7", label: "Phường Mỹ Phước" },
  { value: "8", label: "Phường Mỹ Quý" },
  { value: "9", label: "Phường Mỹ Thới" },
  { value: "10", label: "Phường Mỹ Thạnh" },
  { value: "11", label: "Phường Mỹ Hòa" },
  { value: "12", label: "Xã Mỹ Khánh" },
  { value: "13", label: "Xã Mỹ Hòa Hưng" },
];
export const angiang_thanhphochaudoc = [
  { value: "1", label: "Phường Châu Phú B" },
  { value: "2", label: "Phường Châu Phú A" },
  { value: "3", label: "Phường Vĩnh Mỹ" },
  { value: "4", label: "Phường Núi Sam" },
  { value: "5", label: "Phường Vĩnh Ngươn" },
  { value: "6", label: "Xã Vĩnh Tế" },
  { value: "7", label: "Xã Vĩnh Châu" },
];
export const angiang_huyenanphu = [
  { value: "1", label: "Thị trấn An Phú" },
  { value: "2", label: "Xã Khánh An" },
  { value: "3", label: "Thị Trấn Long Bình" },
  { value: "4", label: "Xã Khánh Bình" },
  { value: "5", label: "Xã Quốc Thái" },
  { value: "6", label: "Xã Nhơn Hội" },
  { value: "7", label: "Xã Phú Hữu" },
  { value: "8", label: "Xã Phú Hội" },
  { value: "9", label: "Xã Phước Hưng" },
  { value: "10", label: "Xã Vĩnh Lộc" },
  { value: "11", label: "Xã Vĩnh Hậu" },
  { value: "12", label: "Xã Vĩnh Trường" },
  { value: "13", label: "Xã Vĩnh Hội Đông" },
  { value: "14", label: "Xã Đa Phước" },
];
export const angiang_xatanchau = [
  { value: "1", label: "Phường Long Thạnh" },
  { value: "2", label: "Phường Long Hưng" },
  { value: "3", label: "Phường Long Châu" },
  { value: "4", label: "Xã Phú Lộc" },
  { value: "5", label: "Xã Vĩnh Xương" },
  { value: "6", label: "Xã Vĩnh Hòa" },
  { value: "7", label: "Xã Tân Thạnh" },
  { value: "8", label: "Xã Tân An" },
  { value: "9", label: "Xã Long An" },
  { value: "10", label: "Phường Long Phú" },
  { value: "11", label: "Xã Châu Phong" },
  { value: "12", label: "Xã Phú Vĩnh" },
  { value: "13", label: "Xã Lê Chánh" },
  { value: "14", label: "Phường Long Sơn" },
];
export const angiang_huyenphutan = [
  { value: "1", label: "Thị trấn Phú Mỹ" },
  { value: "2", label: "Thị trấn Chợ Vàm" },
  { value: "3", label: "Xã Long Hòa" },
  { value: "4", label: "Xã Phú Long" },
  { value: "5", label: "Xã Phú Lâm" },
  { value: "6", label: "Xã Phú Hiệp" },
  { value: "7", label: "Xã Phú Thạnh" },
  { value: "8", label: "Xã Hòa Lạc" },
  { value: "9", label: "Xã Phú Thành" },
  { value: "10", label: "Xã Phú An" },
  { value: "11", label: "Xã Phú Xuân" },
  { value: "12", label: "Xã Hiệp Xương" },
  { value: "13", label: "Xã Phú Bình" },
  { value: "14", label: "Xã Phú Thọ" },
  { value: "15", label: "Xã Phú Hưng" },
  { value: "16", label: "Xã Bình Thạnh Đông" },
  { value: "17", label: "Xã Tân Hòa" },
  { value: "18", label: "Xã Tân Trung" },
];
export const angiang_huyenchauphu = [
  { value: "1", label: "Thị trấn Cái Dầu" },
  { value: "2", label: "Xã Khánh Hòa" },
  { value: "3", label: "Xã Mỹ Đức" },
  { value: "4", label: "Xã Mỹ Phú" },
  { value: "5", label: "Xã Ô Long Vỹ" },
  { value: "6", label: "Xã Vĩnh Thạnh Trung" },
  { value: "7", label: "Xã Thạnh Mỹ Tây" },
  { value: "8", label: "Xã Bình Long" },
  { value: "9", label: "Xã Bình Mỹ" },
  { value: "10", label: "Xã Bình Thủy" },
  { value: "11", label: "Xã Đào Hữu Cảnh" },
  { value: "12", label: "Xã Bình Phú" },
  { value: "13", label: "Xã Bình Chánh" },
];
export const angiang_huyentinhbien = [
  { value: "1", label: "Thị trấn Nhà Bàng" },
  { value: "2", label: "Thị trấn Chi Lăng" },
  { value: "3", label: "Xã Núi Voi" },
  { value: "4", label: "Xã Nhơn Hưng" },
  { value: "5", label: "Xã An Phú" },
  { value: "6", label: "Xã Thới Sơn" },
  { value: "7", label: "Thị trấn Tịnh Biên" },
  { value: "8", label: "Xã Văn Giáo" },
  { value: "9", label: "Xã An Cư" },
  { value: "10", label: "Xã An Nông" },
  { value: "11", label: "Xã Vĩnh Trung" },
  { value: "12", label: "Xã Tân Lợi" },
  { value: "13", label: "Xã An Hảo" },
  { value: "14", label: "Xã Tân Lập" },
];
export const angiang_huyentriton = [
  { value: "1", label: "Thị trấn Tri Tôn" },
  { value: "2", label: "Thị trấn Ba Chúc" },
  { value: "3", label: "Xã Lạc Quới" },
  { value: "4", label: "Xã Lê Trì" },
  { value: "5", label: "Xã Vĩnh Gia" },
  { value: "6", label: "Xã Vĩnh Phước" },
  { value: "7", label: "Xã Châu Lăng" },
  { value: "8", label: "Xã Lương Phi" },
  { value: "9", label: "Xã Lương An Trà" },
  { value: "10", label: "Xã Tà Đảnh" },
  { value: "11", label: "Xã Núi Tô" },
  { value: "12", label: "Xã An Tức" },
  { value: "13", label: "Xã Cô Tô" },
  { value: "14", label: "Xã Tân Tuyến" },
  { value: "15", label: "Xã Ô Lâm" },
];
export const angiang_huyenchauthanh = [
  { value: "1", label: "Thị trấn An Châu" },
  { value: "2", label: "Xã An Hòa" },
  { value: "3", label: "Xã Cần Đăng" },
  { value: "4", label: "Xã Vĩnh Hanh" },
  { value: "5", label: "Xã Bình Thạnh" },
  { value: "6", label: "Xã Vĩnh Bình" },
  { value: "7", label: "Xã Bình Hòa" },
  { value: "8", label: "Xã Vĩnh An" },
  { value: "9", label: "Xã Hòa Bình Thạnh" },
  { value: "10", label: "Xã Vĩnh Lợi" },
  { value: "11", label: "Xã Vĩnh Nhuận" },
  { value: "12", label: "Xã Tân Phú" },
  { value: "13", label: "Xã Vĩnh Thành" },
];
export const angiang_huyenchomoi = [
  { value: "1", label: "Thị trấn Chợ Mới" },
  { value: "2", label: "Thị trấn Mỹ Luông" },
  { value: "3", label: "Xã Kiến An" },
  { value: "4", label: "Xã Mỹ Hội Đông" },
  { value: "5", label: "Xã Long Điền A" },
  { value: "6", label: "Xã Tấn Mỹ" },
  { value: "7", label: "Xã Long Điền B" },
  { value: "8", label: "Xã Kiến Thành" },
  { value: "9", label: "Xã Mỹ Hiệp" },
  { value: "10", label: "Xã Mỹ An" },
  { value: "11", label: "Xã Nhơn Mỹ" },
  { value: "12", label: "Xã Long Giang" },
  { value: "13", label: "Xã Long Kiến" },
  { value: "14", label: "Xã Bình Phước Xuân" },
  { value: "15", label: "Xã An Thạnh Trung" },
  { value: "16", label: "Xã Hội An" },
  { value: "17", label: "Xã Hòa Bình" },
  { value: "18", label: "Xã Hòa An" },
];
export const angiang_huyenthoaison = [
  { value: "1", label: "Thị trấn Núi Sập" },
  { value: "2", label: "Thị trấn Phú Hòa" },
  { value: "3", label: "Thị Trấn Óc Eo" },
  { value: "4", label: "Xã Tây Phú" },
  { value: "5", label: "Xã An Bình" },
  { value: "6", label: "Xã Vĩnh Phú" },
  { value: "7", label: "Xã Vĩnh Trạch" },
  { value: "8", label: "Xã Phú Thuận" },
  { value: "9", label: "Xã Vĩnh Chánh" },
  { value: "10", label: "Xã Định Mỹ" },
  { value: "11", label: "Xã Định Thành" },
  { value: "12", label: "Xã Mỹ Phú Đông" },
  { value: "13", label: "Xã Vọng Đông" },
  { value: "14", label: "Xã Vĩnh Khánh" },
  { value: "15", label: "Xã Thoại Giang" },
  { value: "16", label: "Xã Bình Thành" },
  { value: "17", label: "Xã Vọng Thê" },
];
export const kiengiang_thanhphorachgia = [
  { value: "1", label: "Phường Vĩnh Thanh Vân" },
  { value: "2", label: "Phường Vĩnh Thanh" },
  { value: "3", label: "Phường Vĩnh Quang" },
  { value: "4", label: "Phường Vĩnh Hiệp" },
  { value: "5", label: "Phường Vĩnh Bảo" },
  { value: "6", label: "Phường Vĩnh Lạc" },
  { value: "7", label: "Phường An Hòa" },
  { value: "8", label: "Phường An Bình" },
  { value: "9", label: "Phường Rạch Sỏi" },
  { value: "10", label: "Phường Vĩnh Lợi" },
  { value: "11", label: "Phường Vĩnh Thông" },
  { value: "12", label: "Xã Phi Thông" },
];
export const kiengiang_xahatien = [
  { value: "1", label: "Phường Tô Châu" },
  { value: "2", label: "Phường Đông Hồ" },
  { value: "3", label: "Phường Bình San" },
  { value: "4", label: "Phường Pháo Đài" },
  { value: "5", label: "Xã Mỹ Đức" },
  { value: "6", label: "Xã Tiên Hải" },
  { value: "7", label: "Xã Thuận Yên" },
];
export const kiengiang_huyenkienluong = [
  { value: "1", label: "Thị trấn Kiên Lương" },
  { value: "2", label: "Xã Kiên Bình" },
  { value: "3", label: "Xã Hòa Điền" },
  { value: "4", label: "Xã Dương Hòa" },
  { value: "5", label: "Xã Bình An" },
  { value: "6", label: "Xã Bình Trị" },
  { value: "7", label: "Xã Sơn Hải" },
  { value: "8", label: "Xã Hòn Nghệ" },
];
export const kiengiang_huyenhondat = [
  { value: "1", label: "Thị trấn Hòn Đất" },
  { value: "2", label: "Thị trấn Sóc Sơn" },
  { value: "3", label: "Xã Bình Sơn" },
  { value: "4", label: "Xã Bình Giang" },
  { value: "5", label: "Xã Mỹ Thái" },
  { value: "6", label: "Xã Nam Thái Sơn" },
  { value: "7", label: "Xã Mỹ Hiệp Sơn" },
  { value: "8", label: "Xã Sơn Kiên" },
  { value: "9", label: "Xã Sơn Bình" },
  { value: "10", label: "Xã Mỹ Thuận" },
  { value: "11", label: "Xã Lình Huỳnh" },
  { value: "12", label: "Xã Thổ Sơn" },
  { value: "13", label: "Xã Mỹ Lâm" },
  { value: "14", label: "Xã Mỹ Phước" },
];
export const kiengiang_huyentanhiep = [
  { value: "1", label: "Thị trấn Tân Hiệp" },
  { value: "2", label: "Xã Tân Hội" },
  { value: "3", label: "Xã Tân Thành" },
  { value: "4", label: "Xã Tân Hiệp B" },
  { value: "5", label: "Xã Tân Hòa" },
  { value: "6", label: "Xã Thạnh Đông B" },
  { value: "7", label: "Xã Thạnh Đông" },
  { value: "8", label: "Xã Tân Hiệp A" },
  { value: "9", label: "Xã Tân An" },
  { value: "10", label: "Xã Thạnh Đông A" },
  { value: "11", label: "Xã Thạnh Trị" },
];
export const kiengiang_huyenchauthanh = [
  { value: "1", label: "Thị trấn Minh Lương" },
  { value: "2", label: "Xã Mong Thọ A" },
  { value: "3", label: "Xã Mong Thọ B" },
  { value: "4", label: "Xã Mong Thọ" },
  { value: "5", label: "Xã Giục Tượng" },
  { value: "6", label: "Xã Vĩnh Hòa Hiệp" },
  { value: "7", label: "Xã Vĩnh Hòa Phú" },
  { value: "8", label: "Xã Minh Hòa" },
  { value: "9", label: "Xã Bình An" },
  { value: "10", label: "Xã Thạnh Lộc" },
];
export const kiengiang_huyengiongrieng = [
  { value: "1", label: "Thị Trấn Giồng Riềng" },
  { value: "2", label: "Xã Thạnh Hưng" },
  { value: "3", label: "Xã Thạnh Phước" },
  { value: "4", label: "Xã Thạnh Lộc" },
  { value: "5", label: "Xã Thạnh Hòa" },
  { value: "6", label: "Xã Thạnh Bình" },
  { value: "7", label: "Xã Bàn Thạch" },
  { value: "8", label: "Xã Bàn Tân Định" },
  { value: "9", label: "Xã Ngọc Thành" },
  { value: "10", label: "Xã Ngọc Chúc" },
  { value: "11", label: "Xã Ngọc Thuận" },
  { value: "12", label: "Xã Hòa Hưng" },
  { value: "13", label: "Xã Hòa Lợi" },
  { value: "14", label: "Xã Hòa An" },
  { value: "15", label: "Xã Long Thạnh" },
  { value: "16", label: "Xã Vĩnh Thạnh" },
  { value: "17", label: "Xã Vĩnh Phú" },
  { value: "18", label: "Xã Hòa Thuận" },
  { value: "19", label: "Xã Ngọc Hòa" },
];
export const kiengiang_huyengoquao = [
  { value: "1", label: "Thị trấn Gò Quao" },
  { value: "2", label: "Xã Vĩnh Hòa Hưng Bắc" },
  { value: "3", label: "Xã Định Hòa" },
  { value: "4", label: "Xã Thới Quản" },
  { value: "5", label: "Xã Định An" },
  { value: "6", label: "Xã Thủy Liễu" },
  { value: "7", label: "Xã Vĩnh Hòa Hưng Nam" },
  { value: "8", label: "Xã Vĩnh Phước A" },
  { value: "9", label: "Xã Vĩnh Phước B" },
  { value: "10", label: "Xã Vĩnh Tuy" },
  { value: "11", label: "Xã Vĩnh Thắng" },
];
export const kiengiang_huyenanbien = [
  { value: "1", label: "Thị trấn Thứ Ba" },
  { value: "2", label: "Xã Tây Yên" },
  { value: "3", label: "Xã Tây Yên A" },
  { value: "4", label: "Xã Nam Yên" },
  { value: "5", label: "Xã Hưng Yên" },
  { value: "6", label: "Xã Nam Thái" },
  { value: "7", label: "Xã Nam Thái A" },
  { value: "8", label: "Xã Đông Thái" },
  { value: "9", label: "Xã Đông Yên" },
];
export const kiengiang_huyenanminh = [
  { value: "1", label: "Thị trấn Thứ Mười Một" },
  { value: "2", label: "Xã Thuận Hòa" },
  { value: "3", label: "Xã Đông Hòa" },
  { value: "4", label: "Xã Đông Thạnh" },
  { value: "5", label: "Xã Tân Thạnh" },
  { value: "6", label: "Xã Đông Hưng" },
  { value: "7", label: "Xã Đông Hưng A" },
  { value: "8", label: "Xã Đông Hưng B" },
  { value: "9", label: "Xã Vân Khánh" },
  { value: "10", label: "Xã Vân Khánh Đông" },
  { value: "11", label: "Xã Vân Khánh Tây" },
];
export const kiengiang_huyenvinhthuan = [
  { value: "1", label: "Thị trấn Vĩnh Thuận" },
  { value: "2", label: "Xã Vĩnh Bình Bắc" },
  { value: "3", label: "Xã Vĩnh Bình Nam" },
  { value: "4", label: "Xã Bình Minh" },
  { value: "5", label: "Xã Vĩnh Thuận" },
  { value: "6", label: "Xã Tân Thuận" },
  { value: "7", label: "Xã Phong Đông" },
  { value: "8", label: "Xã Vĩnh Phong" },
];
export const kiengiang_huyenphuquoc = [
  { value: "1", label: "Thị trấn Dương Đông" },
  { value: "2", label: "Thị trấn An Thới" },
  { value: "3", label: "Xã Cửa Cạn" },
  { value: "4", label: "Xã Gành Dầu" },
  { value: "5", label: "Xã Cửa Dương" },
  { value: "6", label: "Xã Hàm Ninh" },
  { value: "7", label: "Xã Dương Tơ" },
  { value: "8", label: "Xã Hòn Thơm" },
  { value: "9", label: "Xã Bãi Thơm" },
  { value: "10", label: "Xã Thổ Châu" },
];
export const kiengiang_huyenkienhai = [
  { value: "1", label: "Xã Hòn Tre" },
  { value: "2", label: "Xã Lại Sơn" },
  { value: "3", label: "Xã An Sơn" },
  { value: "4", label: "Xã Nam Du" },
];
export const kiengiang_huyenuminhthuong = [
  { value: "1", label: "Xã Thạnh Yên" },
  { value: "2", label: "Xã Thạnh Yên A" },
  { value: "3", label: "Xã An Minh Bắc" },
  { value: "4", label: "Xã Vĩnh Hòa" },
  { value: "5", label: "Xã Hòa Chánh" },
  { value: "6", label: "Xã Minh Thuận" },
];
export const kiengiang_quangiangthanh = [
  { value: "1", label: "Xã Vĩnh Phú" },
  { value: "2", label: "Xã Vĩnh Điều" },
  { value: "3", label: "Xã Tân Khánh Hòa" },
  { value: "4", label: "Xã Phú Lợi" },
  { value: "5", label: "Xã Phú Mỹ" },
];
export const cantho_quaninhkieu = [
  { value: "1", label: "Phường Cái Khế" },
  { value: "2", label: "Phường An Hòa" },
  { value: "3", label: "Phường Thới Bình" },
  { value: "4", label: "Phường An Nghiệp" },
  { value: "5", label: "Phường An Cư" },
  { value: "6", label: "Phường An Hội" },
  { value: "7", label: "Phường Tân An" },
  { value: "8", label: "Phường An Lạc" },
  { value: "9", label: "Phường An Phú" },
  { value: "10", label: "Phường Xuân Khánh" },
  { value: "11", label: "Phường Hưng Lợi" },
  { value: "12", label: "Phường An Khánh" },
  { value: "13", label: "Phường An Bình" },
];
export const cantho_quanomon = [
  { value: "1", label: "Phường Châu Văn Liêm" },
  { value: "2", label: "Phường Thới Hòa" },
  { value: "3", label: "Phường Thới Long" },
  { value: "4", label: "Phường Long Hưng" },
  { value: "5", label: "Phường Thới An" },
  { value: "6", label: "Phường Phước Thới" },
  { value: "7", label: "Phường Trường Lạc" },
];
export const cantho_quanbinhthuy = [
  { value: "1", label: "Phường Bình Thủy" },
  { value: "2", label: "Phường Trà An" },
  { value: "3", label: "Phường Trà Nóc" },
  { value: "4", label: "Phường Thới An Đông" },
  { value: "5", label: "Phường An Thới" },
  { value: "6", label: "Phường Bùi Hữu Nghĩa" },
  { value: "7", label: "Phường Long Hòa" },
  { value: "8", label: "Phường Long Tuyền" },
];
export const cantho_quancairang = [
  { value: "1", label: "Phường Lê Bình" },
  { value: "2", label: "Phường Hưng Phú" },
  { value: "3", label: "Phường Hưng Thạnh" },
  { value: "4", label: "Phường Ba Láng" },
  { value: "5", label: "Phường Thường Thạnh" },
  { value: "6", label: "Phường Phú Thứ" },
  { value: "7", label: "Phường Tân Phú" },
];
export const cantho_quanthotnot = [
  { value: "1", label: "Phường Thốt Nốt" },
  { value: "2", label: "Phường Thới Thuận" },
  { value: "3", label: "Phường Thuận An" },
  { value: "4", label: "Phường Tân Lộc" },
  { value: "5", label: "Phường Trung Nhứt" },
  { value: "6", label: "Phường Thạnh Hòa" },
  { value: "7", label: "Phường Trung Kiên" },
  { value: "8", label: "Phường Tân Hưng" },
  { value: "9", label: "Phường Thuận Hưng" },
];
export const cantho_huyenvinhthanh = [
  { value: "1", label: "Xã Vĩnh Bình" },
  { value: "2", label: "Thị trấn Thanh An" },
  { value: "3", label: "Thị trấn Vĩnh Thạnh" },
  { value: "4", label: "Xã Thạnh Mỹ" },
  { value: "5", label: "Xã Vĩnh Trinh" },
  { value: "6", label: "Xã Thạnh An" },
  { value: "7", label: "Xã Thạnh Tiến" },
  { value: "8", label: "Xã Thạnh Thắng" },
  { value: "9", label: "Xã Thạnh Lợi" },
  { value: "10", label: "Xã Thạnh Qưới" },
  { value: "11", label: "Xã Thạnh Lộc" },
];
export const cantho_huyencodo = [
  { value: "1", label: "Xã Trung An" },
  { value: "2", label: "Xã Trung Thạnh" },
  { value: "3", label: "Xã Thạnh Phú" },
  { value: "4", label: "Xã Trung Hưng" },
  { value: "5", label: "Thị trấn Cờ Đỏ" },
  { value: "6", label: "Xã Thới Hưng" },
  { value: "7", label: "Xã Đông Hiệp" },
  { value: "8", label: "Xã Đông Thắng" },
  { value: "9", label: "Xã Thới Đông" },
  { value: "10", label: "Xã Thới Xuân" },
];
export const cantho_huyenphongdien = [
  { value: "1", label: "Thị trấn Phong Điền" },
  { value: "2", label: "Xã Nhơn Ái" },
  { value: "3", label: "Xã Giai Xuân" },
  { value: "4", label: "Xã Tân Thới" },
  { value: "5", label: "Xã Trường Long" },
  { value: "6", label: "Xã Mỹ Khánh" },
  { value: "7", label: "Xã Nhơn Nghĩa" },
];
export const cantho_xathoilai = [
  { value: "1", label: "Thị trấn Thới Lai" },
  { value: "2", label: "Xã Thới Thạnh" },
  { value: "3", label: "Xã Tân Thạnh" },
  { value: "4", label: "Xã Xuân Thắng" },
  { value: "5", label: "Xã Đông Bình" },
  { value: "6", label: "Xã Đông Thuận" },
  { value: "7", label: "Xã Thới Tân" },
  { value: "8", label: "Xã Trường Thắng" },
  { value: "9", label: "Xã Định Môn" },
  { value: "10", label: "Xã Trường Thành" },
  { value: "11", label: "Xã Trường Xuân" },
  { value: "12", label: "Xã Trường Xuân A" },
  { value: "13", label: "Xã Trường Xuân B" },
];
export const haugiang_thanhphovithanh = [
  { value: "1", label: "Phường I" },
  { value: "2", label: "Phường III" },
  { value: "3", label: "Phường IV" },
  { value: "4", label: "Phường V" },
  { value: "5", label: "Phường VII" },
  { value: "6", label: "Xã Vị Tân" },
  { value: "7", label: "Xã Hoả Lựu" },
  { value: "8", label: "Xã Tân Tiến" },
  { value: "9", label: "Xã Hoả Tiến" },
];
export const haugiang_xangabay = [
  { value: "1", label: "Phường Ngã Bảy" },
  { value: "2", label: "Phường Lái Hiếu" },
  { value: "3", label: "Phường Hiệp Thành" },
  { value: "4", label: "Xã Hiệp Lợi" },
  { value: "5", label: "Xã Đại Thành" },
  { value: "6", label: "Xã Tân Thành" },
];
export const haugiang_huyenchauthanhA = [
  { value: "1", label: "Thị trấn Một Ngàn" },
  { value: "2", label: "Xã Tân Hòa" },
  { value: "3", label: "Thị trấn Bảy Ngàn" },
  { value: "4", label: "Xã Trường Long Tây" },
  { value: "5", label: "Xã Trường Long A" },
  { value: "6", label: "Xã Nhơn Nghĩa A" },
  { value: "7", label: "Thị trấn Rạch Gòi" },
  { value: "8", label: "Xã Thạnh Xuân" },
  { value: "9", label: "Thị trấn Cái Tắc" },
  { value: "10", label: "Xã Tân Phú Thạnh" },
];
export const haugiang_huyenchauthanh = [
  { value: "1", label: "Thị Trấn Ngã Sáu" },
  { value: "2", label: "Xã Đông Thạnh" },
  { value: "3", label: "Xã Phú An" },
  { value: "4", label: "Xã Đông Phú" },
  { value: "5", label: "Xã Phú Hữu" },
  { value: "6", label: "Xã Phú Tân" },
  { value: "7", label: "Thị trấn Mái Dầm" },
  { value: "8", label: "Xã Đông Phước" },
  { value: "9", label: "Xã Đông Phước A" },
];
export const haugiang_huyenphunghiep = [
  { value: "1", label: "Thị trấn Kinh Cùng" },
  { value: "2", label: "Thị trấn Cây Dương" },
  { value: "3", label: "Xã Tân Bình" },
  { value: "4", label: "Xã Bình Thành" },
  { value: "5", label: "Xã Thạnh Hòa" },
  { value: "6", label: "Xã Long Thạnh" },
  { value: "7", label: "Xã Phụng Hiệp" },
  { value: "8", label: "Xã Hòa Mỹ" },
  { value: "9", label: "Xã Hòa An" },
  { value: "10", label: "Xã Phương Bình" },
  { value: "11", label: "Xã Hiệp Hưng" },
  { value: "12", label: "Xã Tân Phước Hưng" },
  { value: "13", label: "Thị trấn Búng Tàu" },
  { value: "14", label: "Xã Phương Phú" },
  { value: "15", label: "Xã Tân Long" },
];
export const haugiang_huyenvithuy = [
  { value: "1", label: "Thị trấn Nàng Mau" },
  { value: "2", label: "Xã Vị Trung" },
  { value: "3", label: "Xã Vị Thuỷ" },
  { value: "4", label: "Xã Vị Thắng" },
  { value: "5", label: "Xã Vĩnh Thuận Tây" },
  { value: "6", label: "Xã Vĩnh Trung" },
  { value: "7", label: "Xã Vĩnh Tường" },
  { value: "8", label: "Xã Vị Đông" },
  { value: "9", label: "Xã Vị Thanh" },
  { value: "10", label: "Xã Vị Bình" },
];
export const haugiang_huyenlongmy = [
  { value: "1", label: "Xã Thuận Hưng" },
  { value: "2", label: "Xã Thuận Hòa" },
  { value: "3", label: "Xã Vĩnh Thuận Đông" },
  { value: "4", label: "Xã Vĩnh Viễn" },
  { value: "5", label: "Xã Vĩnh Viễn A" },
  { value: "6", label: "Xã Lương Tâm" },
  { value: "7", label: "Xã Lương Nghĩa" },
  { value: "8", label: "Xã Xà Phiên" },
];
export const haugiang_xalongmy = [
  { value: "1", label: "Phường Thuận An" },
  { value: "2", label: "Phường Trà Lồng" },
  { value: "3", label: "Phường Bình Thạnh" },
  { value: "4", label: "Xã Long Bình" },
  { value: "5", label: "Phường Vĩnh Tường" },
  { value: "6", label: "Xã Long Trị" },
  { value: "7", label: "Xã Long Trị A" },
  { value: "8", label: "Xã Long Phú" },
  { value: "9", label: "Xã Tân Phú" },
];
export const soctrang_thanhphosoctrang = [
  { value: "1", label: "Phường 5" },
  { value: "2", label: "Phường 7" },
  { value: "3", label: "Phường 8" },
  { value: "4", label: "Phường 6" },
  { value: "5", label: "Phường 2" },
  { value: "6", label: "Phường 1" },
  { value: "7", label: "Phường 4" },
  { value: "8", label: "Phường 3" },
  { value: "9", label: "Phường 9" },
  { value: "10", label: "Phường 10" },
];
export const soctrang_huyenchauthanh = [
  { value: "1", label: "Thị trấn Châu Thành" },
  { value: "2", label: "Xã Hồ Đắc Kiện" },
  { value: "3", label: "Xã Phú Tâm" },
  { value: "4", label: "Xã Thuận Hòa" },
  { value: "5", label: "Xã Phú Tân" },
  { value: "6", label: "Xã Thiện Mỹ" },
  { value: "7", label: "Xã An Hiệp" },
  { value: "8", label: "Xã An Ninh" },
];
export const soctrang_huyenkesach = [
  { value: "1", label: "Thị trấn Kế Sách" },
  { value: "2", label: "Thị trấn An Lạc Thôn" },
  { value: "3", label: "Xã Xuân Hòa" },
  { value: "4", label: "Xã Phong Nẫm" },
  { value: "5", label: "Xã An Lạc Tây" },
  { value: "6", label: "Xã Trinh Phú" },
  { value: "7", label: "Xã Ba Trinh" },
  { value: "8", label: "Xã Thới An Hội" },
  { value: "9", label: "Xã Nhơn Mỹ" },
  { value: "10", label: "Xã Kế Thành" },
  { value: "11", label: "Xã Kế An" },
  { value: "12", label: "Xã Đại Hải" },
  { value: "13", label: "Xã An Mỹ" },
];
export const soctrang_huyenmytu = [
  { value: "1", label: "Thị trấn Huỳnh Hữu Nghĩa" },
  { value: "2", label: "Xã Long Hưng" },
  { value: "3", label: "Xã Hưng Phú" },
  { value: "4", label: "Xã Mỹ Hương" },
  { value: "5", label: "Xã Mỹ Tú" },
  { value: "6", label: "Xã Mỹ Phước" },
  { value: "7", label: "Xã Thuận Hưng" },
  { value: "8", label: "Xã Mỹ Thuận" },
  { value: "9", label: "Xã Phú Mỹ" },
];
export const soctrang_huyenculaodung = [
  { value: "1", label: "Thị trấn Cù Lao Dung" },
  { value: "2", label: "Xã An Thạnh 1" },
  { value: "3", label: "Xã An Thạnh Tây" },
  { value: "4", label: "Xã An Thạnh Đông" },
  { value: "5", label: "Xã Đại Ân 1" },
  { value: "6", label: "Xã An Thạnh 2" },
  { value: "7", label: "Xã An Thạnh 3" },
  { value: "8", label: "Xã An Thạnh Nam" },
];
export const soctrang_huyenlongphu = [
  { value: "1", label: "Thị trấn Long Phú" },
  { value: "2", label: "Xã Song Phụng" },
  { value: "3", label: "Thị trấn Đại Ngãi" },
  { value: "4", label: "Xã Hậu Thạnh" },
  { value: "5", label: "Xã Long Đức" },
  { value: "6", label: "Xã Trường Khánh" },
  { value: "7", label: "Xã Phú Hữu" },
  { value: "8", label: "Xã Tân Hưng" },
  { value: "9", label: "Xã Châu Khánh" },
  { value: "10", label: "Xã Tân Thạnh" },
  { value: "11", label: "Xã Long Phú" },
];
export const soctrang_huyenmyxuyen = [
  { value: "1", label: "Thị trấn Mỹ Xuyên" },
  { value: "2", label: "Xã Đại Tâm" },
  { value: "3", label: "Xã Tham Đôn" },
  { value: "4", label: "Xã Thạnh Phú" },
  { value: "5", label: "Xã Ngọc Đông" },
  { value: "6", label: "Xã Thạnh Quới" },
  { value: "7", label: "Xã Hòa Tú 1" },
  { value: "8", label: "Xã Gia Hòa 1" },
  { value: "9", label: "Xã Ngọc Tố" },
  { value: "10", label: "Xã Gia Hòa 2" },
  { value: "11", label: "Xã Hòa Tú II" },
];
export const soctrang_xanganam = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường 2" },
  { value: "3", label: "Xã Vĩnh Quới" },
  { value: "4", label: "Xã Tân Long" },
  { value: "5", label: "Xã Long Bình" },
  { value: "6", label: "Phường 3" },
  { value: "7", label: "Xã Mỹ Bình" },
  { value: "8", label: "Xã Mỹ Quới" },
];
export const soctrang_huyenthanhtri = [
  { value: "1", label: "Thị trấn Phú Lộc" },
  { value: "2", label: "Thị trấn Hưng Lợi" },
  { value: "3", label: "Xã Lâm Tân" },
  { value: "4", label: "Xã Thạnh Tân" },
  { value: "5", label: "Xã Lâm Kiết" },
  { value: "6", label: "Xã Tuân Tức" },
  { value: "7", label: "Xã Vĩnh Thành" },
  { value: "8", label: "Xã Thạnh Trị" },
  { value: "9", label: "Xã Vĩnh Lợi" },
  { value: "10", label: "Xã Châu Hưng" },
];
export const soctrang_xavinhchau = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Xã Hòa Đông" },
  { value: "3", label: "Phường Khánh Hòa" },
  { value: "4", label: "Xã Vĩnh Hiệp" },
  { value: "5", label: "Xã Vĩnh Hải" },
  { value: "6", label: "Xã Lạc Hòa" },
  { value: "7", label: "Phường 2" },
  { value: "8", label: "Phường Vĩnh Phước" },
  { value: "9", label: "Xã Vĩnh Tân" },
  { value: "10", label: "Xã Lai Hòa" },
];
export const soctrang_huyentrande = [
  { value: "1", label: "Xã Đại Ân 2" },
  { value: "2", label: "Thị trấn Trần Đề" },
  { value: "3", label: "Xã Liêu Tú" },
  { value: "4", label: "Xã Lịch Hội Thượng" },
  { value: "5", label: "Thị trấn Lịch Hội Thượng" },
  { value: "6", label: "Xã Trung Bình" },
  { value: "7", label: "Xã Tài Văn" },
  { value: "8", label: "Xã Viên An" },
  { value: "9", label: "Xã Thạnh Thới An" },
  { value: "10", label: "Xã Thạnh Thới Thuận" },
  { value: "11", label: "Xã Viên Bình" },
];
export const baclieu_thanhphobaclieu = [
  { value: "1", label: "Phường 2" },
  { value: "2", label: "Phường 3" },
  { value: "3", label: "Phường 5" },
  { value: "4", label: "Phường 7" },
  { value: "5", label: "Phường 1" },
  { value: "6", label: "Phường 8" },
  { value: "7", label: "Phường Nhà Mát" },
  { value: "8", label: "Xã Vĩnh Trạch" },
  { value: "9", label: "Xã Vĩnh Trạch Đông" },
  { value: "10", label: "Xã Hiệp Thành" },
];
export const baclieu_huyenhongdan = [
  { value: "1", label: "Thị trấn Ngan Dừa" },
  { value: "2", label: "Xã Ninh Quới" },
  { value: "3", label: "Xã Ninh Quới A" },
  { value: "4", label: "Xã Ninh Hòa" },
  { value: "5", label: "Xã Lộc Ninh" },
  { value: "6", label: "Xã Vĩnh Lộc" },
  { value: "7", label: "Xã Vĩnh Lộc A" },
  { value: "8", label: "Xã Ninh Thạnh Lợi A" },
  { value: "9", label: "Xã Ninh Thạnh Lợi" },
];
export const baclieu_huyenphuoclong = [
  { value: "1", label: "Thị trấn Phước Long" },
  { value: "2", label: "Xã Vĩnh Phú Đông" },
  { value: "3", label: "Xã Vĩnh Phú Tây" },
  { value: "4", label: "Xã Phước Long" },
  { value: "5", label: "Xã Hưng Phú" },
  { value: "6", label: "Xã Vĩnh Thanh" },
  { value: "7", label: "Xã Phong Thạnh Tây A" },
  { value: "8", label: "Xã Phong Thạnh Tây B" },
];
export const baclieu_huyenvinhloi = [
  { value: "1", label: "Xã Vĩnh Hưng" },
  { value: "2", label: "Xã Vĩnh Hưng A" },
  { value: "3", label: "Thị trấn Châu Hưng" },
  { value: "4", label: "Xã Châu Hưng A" },
  { value: "5", label: "Xã Hưng Thành" },
  { value: "6", label: "Xã Hưng Hội" },
  { value: "7", label: "Xã Châu Thới" },
  { value: "8", label: "Xã Long Thạnh" },
];
export const baclieu_xagiarai = [
  { value: "1", label: "Phường 1" },
  { value: "2", label: "Phường Hộ Phòng" },
  { value: "3", label: "Xã Phong Thạnh Đông" },
  { value: "4", label: "Phường Láng Tròn" },
  { value: "5", label: "Xã Phong Tân" },
  { value: "6", label: "Xã Tân Phong" },
  { value: "7", label: "Xã Phong Thạnh" },
  { value: "8", label: "Xã Phong Thạnh A" },
  { value: "9", label: "Xã Phong Thạnh Tây" },
  { value: "10", label: "Xã Tân Thạnh" },
];
export const baclieu_huyendonghai = [
  { value: "1", label: "Thị trấn Gành Hào" },
  { value: "2", label: "Xã Long Điền Đông" },
  { value: "3", label: "Xã Long Điền Đông A" },
  { value: "4", label: "Xã Long Điền" },
  { value: "5", label: "Xã Long Điền Tây" },
  { value: "6", label: "Xã Điền Hải" },
  { value: "7", label: "Xã An Trạch" },
  { value: "8", label: "Xã An Trạch A" },
  { value: "9", label: "Xã An Phúc" },
  { value: "10", label: "Xã Định Thành" },
  { value: "11", label: "Xã Định Thành A" },
];
export const baclieu_huyenhoabinh = [
  { value: "1", label: "Thị trấn Hòa Bình" },
  { value: "2", label: "Xã Minh Diệu" },
  { value: "3", label: "Xã Vĩnh Bình" },
  { value: "4", label: "Xã Vĩnh Mỹ B" },
  { value: "5", label: "Xã Vĩnh Hậu" },
  { value: "6", label: "Xã Vĩnh Hậu A" },
  { value: "7", label: "Xã Vĩnh Mỹ A" },
  { value: "8", label: "Xã Vĩnh Thịnh" },
];
export const camau_thanhphocamau = [
  { value: "1", label: "Phường 9" },
  { value: "2", label: "Phường 4" },
  { value: "3", label: "Phường 1" },
  { value: "4", label: "Phường 5" },
  { value: "5", label: "Phường 2" },
  { value: "6", label: "Phường 8" },
  { value: "7", label: "Phường 6" },
  { value: "8", label: "Phường 7" },
  { value: "9", label: "Phường Tân Xuyên" },
  { value: "10", label: "Xã An Xuyên" },
  { value: "11", label: "Phường Tân Thành" },
  { value: "12", label: "Xã Tân Thành" },
  { value: "13", label: "Xã Tắc Vân" },
  { value: "14", label: "Xã Lý Văn Lâm" },
  { value: "15", label: "Xã Định Bình" },
  { value: "16", label: "Xã Hòa Thành" },
  { value: "17", label: "Xã Hòa Tân" },
];
export const camau_huyenuminh = [
  { value: "1", label: "Thị trấn U Minh" },
  { value: "2", label: "Xã Khánh Hòa" },
  { value: "3", label: "Xã Khánh Thuận" },
  { value: "4", label: "Xã Khánh Tiến" },
  { value: "5", label: "Xã Nguyễn Phích" },
  { value: "6", label: "Xã Khánh Lâm" },
  { value: "7", label: "Xã Khánh An" },
  { value: "8", label: "Xã Khánh Hội" },
];
export const camau_huyenthoibinh = [
  { value: "1", label: "Thị trấn Thới Bình" },
  { value: "2", label: "Xã Biển Bạch" },
  { value: "3", label: "Xã Tân Bằng" },
  { value: "4", label: "Xã Trí Phải" },
  { value: "5", label: "Xã Trí Lực" },
  { value: "6", label: "Xã Biển Bạch Đông" },
  { value: "7", label: "Xã Thới Bình" },
  { value: "8", label: "Xã Tân Phú" },
  { value: "9", label: "Xã Tân Lộc Bắc" },
  { value: "10", label: "Xã Tân Lộc" },
  { value: "11", label: "Xã Tân Lộc Đông" },
  { value: "12", label: "Xã Hồ Thị Kỷ" },
];
export const camau_huyentranvanthoi = [
  { value: "1", label: "Thị trấn Trần Văn Thời" },
  { value: "2", label: "Thị trấn Sông Đốc" },
  { value: "3", label: "Xã Khánh Bình Tây Bắc" },
  { value: "4", label: "Xã Khánh Bình Tây" },
  { value: "5", label: "Xã Trần Hợi" },
  { value: "6", label: "Xã Khánh Lộc" },
  { value: "7", label: "Xã Khánh Bình" },
  { value: "8", label: "Xã Khánh Hưng" },
  { value: "9", label: "Xã Khánh Bình Đông" },
  { value: "10", label: "Xã Khánh Hải" },
  { value: "11", label: "Xã Lợi An" },
  { value: "12", label: "Xã Phong Điền" },
  { value: "13", label: "Xã Phong Lạc" },
];
export const camau_huyencainuoc = [
  { value: "1", label: "Thị trấn Cái Nước" },
  { value: "2", label: "Xã Thạnh Phú" },
  { value: "3", label: "Xã Lương Thế Trân" },
  { value: "4", label: "Xã Phú Hưng" },
  { value: "5", label: "Xã Tân Hưng" },
  { value: "6", label: "Xã Hưng Mỹ" },
  { value: "7", label: "Xã Hòa Mỹ" },
  { value: "8", label: "Xã Đông Hưng" },
  { value: "9", label: "Xã Đông Thới" },
  { value: "10", label: "Xã Tân Hưng Đông" },
  { value: "11", label: "Xã Trần Thới" },
];
export const camau_huyendamdoi = [
  { value: "1", label: "Thị trấn Đầm Dơi" },
  { value: "2", label: "Xã Tạ An Khương" },
  { value: "3", label: "Xã Tạ An Khương Đông" },
  { value: "4", label: "Xã Trần Phán" },
  { value: "5", label: "Xã Tân Trung" },
  { value: "6", label: "Xã Tân Đức" },
  { value: "7", label: "Xã Tân Thuận" },
  { value: "8", label: "Xã Tạ An Khương Nam" },
  { value: "9", label: "Xã Tân Duyệt" },
  { value: "10", label: "Xã Tân Dân" },
  { value: "11", label: "Xã Tân Tiến" },
  { value: "12", label: "Xã Quách Phẩm Bắc" },
  { value: "13", label: "Xã Quách Phẩm" },
  { value: "14", label: "Xã Thanh Tùng" },
  { value: "15", label: "Xã Ngọc Chánh" },
  { value: "16", label: "Xã Nguyễn Huân" },
];
export const camau_huyennamcan = [
  { value: "1", label: "Thị Trấn Năm Căn" },
  { value: "2", label: "Xã Hàm Rồng" },
  { value: "3", label: "Xã Hiệp Tùng" },
  { value: "4", label: "Xã Đất Mới" },
  { value: "5", label: "Xã Lâm Hải" },
  { value: "6", label: "Xã Hàng Vịnh" },
  { value: "7", label: "Xã Tam Giang" },
  { value: "8", label: "Xã Tam Giang Đông" },
];
export const camau_huyenphutan = [
  { value: "1", label: "Thị trấn Cái Đôi Vàm" },
  { value: "2", label: "Xã Phú Thuận" },
  { value: "3", label: "Xã Phú Mỹ" },
  { value: "4", label: "Xã Phú Tân" },
  { value: "5", label: "Xã Tân Hải" },
  { value: "6", label: "Xã Việt Thắng" },
  { value: "7", label: "Xã Tân Hưng Tây" },
  { value: "8", label: "Xã Rạch Chèo" },
  { value: "9", label: "Xã Việt Khái" },
];
export const camau_huyenngochuyen = [
  { value: "1", label: "Xã Tam Giang Tây" },
  { value: "2", label: "Xã Tân Ân Tây" },
  { value: "3", label: "Xã Viên An Đông" },
  { value: "4", label: "Xã Viên An" },
  { value: "5", label: "Thị trấn Rạch Gốc" },
  { value: "6", label: "Xã Tân Ân" },
  { value: "7", label: "Xã Đất Mũi" },
];



export const daklak_buonMaThuot = [
  { value: "1", label: "Phường Tân Lập" },
  { value: "2", label: "Phường Tân Hòa" },
  { value: "3", label: "Phường Tân An" },
  { value: "4", label: "Phường Thống Nhất" },
  { value: "5", label: "Phường Thành Nhất" },
  { value: "6", label: "Phường Thắng Lợi" },
  { value: "7", label: "Phường Tân Lợi" },
  { value: "8", label: "Phường Thành Công" },
  { value: "9", label: "Phường Tân Thành" },
  { value: "10", label: "Phường Tân Tiến" },
  { value: "11", label: "Phường Tự An" },
  { value: "12", label: "Phường Ea Tam" },
  { value: "13", label: "Phường Khánh Xuân" },
  { value: "14", label: "Xã Hòa Thuận" },
  { value: "15", label: "Xã Cư ÊBur" },
  { value: "16", label: "Xã Ea Tu" },
  { value: "17", label: "Xã Hòa Thắng" },
  { value: "18", label: "Xã Ea Kao" },
  { value: "19", label: "Xã Hòa Phú" },
  { value: "20", label: "Xã Hòa Khánh" },
  { value: "21", label: "Xã Hòa Xuân" },
];

export const daklak_buonHo = [
  { value: "1", label: "Phường An Bình" },
  { value: "2", label: "Phường Đạt Hiếu" },
  { value: "3", label: "Phường Bình Tân" },
  { value: "4", label: "Phường An Lạc" },
  { value: "5", label: "Phường Đoàn Kết" },
  { value: "6", label: "Phường Thiện An" },
  { value: "7", label: "Phường Thống Nhất" },
  { value: "8", label: "Xã Ea Siên" },
  { value: "9", label: "Xã Ea Drông" },
  { value: "10", label: "Xã Ea Blang" },
  { value: "11", label: "Xã Bình Thuận" },
  { value: "12", label: "Xã Cư Bao" },
];

export const daklak_buonDon = [
  { value: "1", label: "Cuôr Knia" },
  { value: "2", label: "Ea Bar, Buôn Đôn" },
  { value: "3", label: "Ea Huar" },
  { value: "4", label: "Ea Nuôl" },
  { value: "5", label: "Ea Wer" },
  { value: "6", label: "Krông Na" },
  { value: "7", label: "Tân Hòa, Buôn Đôn" },
];

export const daklak_cuKuin = [
  { value: "1", label: "Cư Êwi" },
  { value: "2", label: "Dray Bhăng" },
  { value: "3", label: "Ea Bhôk" },
  { value: "4", label: "Ea Hu" },
  { value: "5", label: "Ea Ktur" },
  { value: "6", label: "Ea Ning" },
  { value: "7", label: "Ea Tiêu" },
  { value: "8", label: "Hòa Hiệp, Cư Kuin" },
];

export const daklak_cuMgar = [
  { value: "1", label: "Cuôr Đăng" },
  { value: "2", label: "Cư Dliê M'nông" },
  { value: "3", label: "Cư M'gar (xã)" },
  { value: "4", label: "Cư Suê" },
  { value: "5", label: "Ea Drơng" },
  { value: "6", label: "Ea H'đing" },
  { value: "7", label: "Ea Kiết" },
  { value: "8", label: "Ea Kpam" },
  { value: "9", label: "Ea Kuếh" },
  { value: "10", label: "Ea M'Droh" },
  { value: "11", label: "Ea M'nang" },
  { value: "12", label: "Ea Pôk" },
  { value: "13", label: "Ea Tar" },
  { value: "14", label: "Ea Tul" },
  { value: "15", label: "Quảng Hiệp" },
  { value: "16", label: "Quảng Phú (thị trấn)" },
  { value: "17", label: "Quảng Tiến, Cư M'gar" },
];

export const daklak_eaHleo = [
  { value: "1", label: "Cư A Mung" },
  { value: "2", label: "Cư Mốt" },
  { value: "3", label: "Dliê Yang" },
  { value: "4", label: "Ea Drăng" },
  { value: "5", label: "Ea H'leo (xã)" },
  { value: "6", label: "Ea Hiao" },
  { value: "7", label: "Ea Khal" },
  { value: "8", label: "Ea Nam" },
  { value: "9", label: "Ea Ral" },
  { value: "10", label: "Ea Sol" },
  { value: "11", label: "Ea Tir" },
  { value: "12", label: "Ea Wy" },
];

export const daklak_eaKar = [
  { value: "1", label: "Cư Bông" },
  { value: "2", label: "Cư Elang" },
  { value: "3", label: "Cư Huê" },
  { value: "4", label: "Cư Ni" },
  { value: "5", label: "Cư Prông" },
  { value: "6", label: "Cư Yang" },
  { value: "7", label: "Ea Đar" },
  { value: "8", label: "Ea Kar (thị trấn)" },
  { value: "9", label: "Ea Kmút" },
  { value: "10", label: "Ea Knốp" },
  { value: "11", label: "Ea Ô" },
  { value: "12", label: "Ea Păl" },
  { value: "13", label: "Ea Sar" },
  { value: "14", label: "Ea Sô" },
  { value: "15", label: "Ea Tih" },
  { value: "16", label: "Xuân Phú, Ea Kar" },
];

export const daklak_eaSub = [
  { value: "1", label: "Cư Kbang" },
  { value: "2", label: "Cư M'Lan" },
  { value: "3", label: "Ea Bung" },
  { value: "4", label: "Ea Lê" },
  { value: "5", label: "Ea Rốk" },
  { value: "6", label: "Ea Súp (thị trấn)" },
  { value: "7", label: "Ia Jlơi" },
  { value: "8", label: "Ia Lốp" },
  { value: "9", label: "Ia Rvê" },
  { value: "10", label: "Ya Tờ Mốt" },
];

export const daklak_krongAna = [
  { value: "1", label: "Băng Adrênh" },
  { value: "2", label: "Bình Hòa, Krông Ana" },
  { value: "3", label: "Buôn Trấp" },
  { value: "4", label: "Dray Sáp" },
  { value: "5", label: "Dur Kmăl" },
  { value: "6", label: "Ea Bông" },
  { value: "7", label: "Ea Na" },
  { value: "8", label: "Quảng Điền (xã)" },
];

export const daklak_krongBong = [
  { value: "1", label: "Cư Drăm" },
  { value: "2", label: "Cư Kty" },
  { value: "3", label: "Cư Pui" },
  { value: "4", label: "Dang Kang" },
  { value: "5", label: "Êa Trul" },
  { value: "6", label: "Hòa Lễ" },
  { value: "7", label: "Hòa Phong, Krông Bông" },
  { value: "8", label: "Hòa Sơn, Krông Bông" },
  { value: "9", label: "Hòa Tân, Krông Bông" },
  { value: "10", label: "Hòa Thành, Krông Bông" },
  { value: "11", label: "Khuê Ngọc Điền" },
  { value: "12", label: "Krông Kmar" },
  { value: "13", label: "Yang Mao" },
  { value: "14", label: "Yang Reh" },
];

export const daklak_krongBuk = [
  { value: "1", label: "Chư Kbô" },
  { value: "2", label: "Cư Né" },
  { value: "3", label: "Cư Pơng" },
  { value: "4", label: "Ea Ngai" },
  { value: "5", label: "Ea Sin" },
  { value: "6", label: "Pơng Drang" },
  { value: "7", label: "Tân Lập, Krông Búk" },
];

export const daklak_krongNang = [
  { value: "1", label: "Cư Klông" },
  { value: "2", label: "Dliê Ya" },
  { value: "3", label: "Ea Dắh" },
  { value: "4", label: "Ea Hồ" },
  { value: "5", label: "Ea Púk" },
  { value: "6", label: "Ea Tam (xã)" },
  { value: "7", label: "Ea Tân" },
  { value: "8", label: "Ea Tóh" },
  { value: "9", label: "Krông Năng (thị trấn)" },
  { value: "10", label: "Phú Lộc, Krông Năng" },
  { value: "11", label: "Phú Xuân, Krông Năng" },
  { value: "12", label: "Tam Giang, Krông Năng" },
];

export const daklak_krongPack = [
  { value: "1", label: "Ea Hiu" },
  { value: "2", label: "Ea Kênh" },
  { value: "3", label: "Ea Kly" },
  { value: "4", label: "Ea Knuếc" },
  { value: "5", label: "Ea Kuăng" },
  { value: "6", label: "Ea Phê" },
  { value: "7", label: "Ea Uy" },
  { value: "8", label: "Ea Yiêng" },
  { value: "9", label: "Ea Yông" },
  { value: "10", label: "Hòa An, Krông Pắc" },
  { value: "11", label: "Hòa Đông, Krông Pắc" },
  { value: "12", label: "Hòa Tiến, Krông Pắc" },
  { value: "13", label: "Krông Búk (xã)" },
  { value: "14", label: "Phước An (thị trấn)" },
  { value: "15", label: "Tân Tiến, Krông Pắc" },
  { value: "16", label: "Vụ Bổn" },
];

export const daklak_lak = [
  { value: "1", label: "Bông Krang" },
  { value: "2", label: "Buôn Tría" },
  { value: "3", label: "Buôn Triết" },
  { value: "4", label: "Đắk Liêng" },
  { value: "5", label: "Đắk Nuê" },
  { value: "6", label: "Đắk Phơi" },
  { value: "7", label: "Ea Rbin" },
  { value: "8", label: "Krông Nô (xã)" },
  { value: "9", label: "Liên Sơn (thị trấn)" },
  { value: "10", label: "Nam Ka" },
  { value: "11", label: "Yang Tao" },
];

export const daklak_madrak = [
  { value: "1", label: "Thị trấn M'Đrắk" },
  { value: "2", label: "Xã Cư Prao" },
  { value: "3", label: "Xã Ea Pil" },
  { value: "4", label: "Xã Ea Lai" },
  { value: "5", label: "Xã Ea H'MLay" },
  { value: "6", label: "Xã Krông Jing" },
  { value: "7", label: "Xã Ea M' Doal" },
  { value: "8", label: "Xã Ea Riêng" },
  { value: "9", label: "Xã Cư M'ta" },
  { value: "10", label: "Xã Cư K Róa" },
  { value: "11", label: "Xã Krông Á" },
  { value: "12", label: "Xã Cư San" },
  { value: "13", label: "Xã Ea Trang" },

];


export const daknong_giaNghia = [
  { value: "1", label: "Phường Nghĩa Đức" },
  { value: "2", label: "Phường Nghĩa Thành" },
  { value: "3", label: "Phường Nghĩa Phú" },
  { value: "4", label: "Phường Nghĩa Tân" },
  { value: "5", label: "Phường Nghĩa Trung" },
  { value: "6", label: "Xã Đăk R'Moan" },
  { value: "7", label: "Xã Quảng Thành" },
  { value: "8", label: "Xã Đắk Nia" },
];

export const daknong_cuJut = [
  { value: "1", label: "Cư Knia" },
  { value: "2", label: "Đắk D'rông" },
  { value: "3", label: "Đắk Wil" },
  { value: "4", label: "Ea Pô" },
  { value: "5", label: "Ea T'ling" },
  { value: "6", label: "Nam Dong" },
  { value: "7", label: "Tâm Thắng" },
  { value: "8", label: "Trúc Sơn" },
];

export const daknong_dakGlong = [
  { value: "1", label: "Đắk Ha" },
  { value: "2", label: "Đắk Plao" },
  { value: "3", label: "Đắk R'măng" },
  { value: "4", label: "Đắk Som" },
  { value: "5", label: "Quảng Hòa, Đắk Glong" },
  { value: "6", label: "Quảng Khê, Đắk Glong" },
  { value: "7", label: "Quảng Sơn, Đắk Glong" },
];

export const daknong_dakMil = [
  { value: "1", label: "Đắk Gằn" },
  { value: "2", label: "Đắk Lao" },
  { value: "3", label: "Đắk Mil (thị trấn)" },
  { value: "4", label: "Đắk N'Drót" },
  { value: "5", label: "Đắk R'La" },
  { value: "6", label: "Đắk Sắk" },
  { value: "7", label: "Đức Mạnh" },
  { value: "8", label: "Đức Minh, Đắk Mil" },
  { value: "9", label: "Long Sơn, Đắk Mil" },
  { value: "10", label: "Thuận An, Đắk Mil" },
];

export const daknong_dakRlap = [
  { value: "1", label: "Đạo Nghĩa" },
  { value: "2", label: "Đắk Ru" },
  { value: "3", label: "Đắk Sin" },
  { value: "4", label: "Đắk Wer" },
  { value: "5", label: "Hưng Bình (xã)" },
  { value: "6", label: "Kiến Đức (thị trấn)" },
  { value: "7", label: "Kiến Thành, Đắk R'lấp" },
  { value: "8", label: "Nghĩa Thắng, Đắk R'lấp" },
  { value: "9", label: "Nhân Cơ" },
  { value: "10", label: "Nhân Đạo, Đắk R'lấp" },
];

export const daknong_dakSong = [
  { value: "1", label: "Đắk Hòa" },
  { value: "2", label: "Đắk Môl" },
  { value: "3", label: "Đắk N'Drung" },
  { value: "4", label: "Đức An (thị trấn)" },
  { value: "5", label: "Nam Bình, Đắk Song" },
  { value: "6", label: "Nâm N'Jang" },
  { value: "7", label: "Thuận Hà" },
  { value: "8", label: "Thuận Hạnh" },
  { value: "9", label: "Trường Xuân, Đắk Song" },
];

export const daknong_krongNo = [
  { value: "1", label: "Buôn Choáh" },
  { value: "2", label: "Đắk Drô" },
  { value: "3", label: "Đắk Mâm" },
  { value: "4", label: "Đắk Nang" },
  { value: "5", label: "Đắk Sôr" },
  { value: "6", label: "Đức Xuyên" },
  { value: "7", label: "Nam Đà" },
  { value: "8", label: "Nam Xuân, Krông Nô" },
  { value: "9", label: "Nâm N'Đir" },
  { value: "10", label: "Nâm Nung" },
  { value: "11", label: "Quảng Phú, Krông Nô" },
  { value: "12", label: "Tân Thành, Krông Nô" },
];

export const daknong_tuyDuc = [
  { value: "1", label: "Đắk Buk So" },
  { value: "2", label: "Đắk Ngo" },
  { value: "3", label: "Đắk R'Tih" },
  { value: "4", label: "Quảng Tâm (xã)" },
  { value: "5", label: "Quảng Tân, Tuy Đức" },
  { value: "6", label: "Quảng Trực" },
];

export const lamdong_baoLoc = [
  { value: "1", label: "B'Lao" },
  { value: "2", label: "Đại Lào" },
  { value: "3", label: "Đamb'ri" },
  { value: "4", label: "Lộc Châu" },
  { value: "5", label: "Lộc Nga" },
  { value: "6", label: "Lộc Phát" },
  { value: "7", label: "Lộc Sơn (phường)" },
  { value: "8", label: "Lộc Thanh" },
  { value: "9", label: "Lộc Tiến (phường)" },
  { value: "10", label: "Phường 1, Bảo Lộc" },
  { value: "11", label: "Phường 2, Bảo Lộc" },
];

export const lamdong_daLat = [
  { value: "1", label: "Phường 1, Đà Lạt" },
  { value: "2", label: "Phường 2, Đà Lạt" },
  { value: "3", label: "Phường 3, Đà Lạt" },
  { value: "4", label: "Phường 4, Đà Lạt" },
  { value: "5", label: "Phường 5, Đà Lạt" },
  { value: "6", label: "Phường 6, Đà Lạt" },
  { value: "7", label: "Phường 7, Đà Lạt" },
  { value: "8", label: "Phường 8, Đà Lạt" },
  { value: "9", label: "Phường 9, Đà Lạt" },
  { value: "10", label: "Phường 10, Đà Lạt" },
  { value: "11", label: "Phường 11, Đà Lạt" },
  { value: "12", label: "Phường 12, Đà Lạt" },
  { value: "13", label: "Tà Nung" },
  { value: "14", label: "Trạm Hành" },
  { value: "15", label: "Xuân Thọ, Đà Lạt" },
  { value: "16", label: "Xuân Trường, Đà Lạt" },
];

export const lamdong_baoLam = [
  { value: "1", label: "B'Lá" },
  { value: "2", label: "Lộc An, Bảo Lâm (Lâm Đồng)" },
  { value: "3", label: "Lộc Bảo" },
  { value: "4", label: "Lộc Bắc" },
  { value: "5", label: "Lộc Đức" },
  { value: "6", label: "Lộc Lâm" },
  { value: "7", label: "Lộc Nam" },
  { value: "8", label: "Lộc Ngãi" },
  { value: "9", label: "Lộc Phú, Bảo Lâm (Lâm Đồng)" },
  { value: "10", label: "Lộc Quảng" },
  { value: "11", label: "Lộc Tân" },
  { value: "12", label: "Lộc Thành, Bảo Lâm (Lâm Đồng)" },
  { value: "13", label: "Lộc Thắng" },
  { value: "14", label: "Tân Lạc (xã)" },
];

export const lamdong_catTien = [
  { value: "1", label: "Cát Tiên (thị trấn)" },
  { value: "2", label: "Đồng Nai Thượng" },
  { value: "3", label: "Đức Phổ (xã)" },
  { value: "4", label: "Gia Viễn (xã)" },
  { value: "5", label: "Nam Ninh (xã)" },
  { value: "6", label: "Phước Cát" },
  { value: "7", label: "Phước Cát 2" },
  { value: "8", label: "Quảng Ngãi (xã)" },
  { value: "9", label: "Tiên Hoàng" },
];

export const lamdong_diLinh = [
  { value: "1", label: "Bảo Thuận, Di Linh" },
  { value: "2", label: "Di Linh (thị trấn)" },
  { value: "3", label: "Đinh Lạc" },
  { value: "4", label: "Đinh Trang Hòa" },
  { value: "5", label: "Đinh Trang Thượng" },
  { value: "6", label: "Gia Bắc" },
  { value: "7", label: "Gia Hiệp" },
  { value: "8", label: "Gung Ré" },
  { value: "9", label: "Hòa Bắc, Di Linh" },
  { value: "10", label: "Hòa Nam, Di Linh" },
  { value: "11", label: "Hòa Ninh, Di Linh" },
  { value: "12", label: "Hòa Trung" },
  { value: "13", label: "Liên Đầm" },
  { value: "14", label: "Sơn Điền" },
  { value: "15", label: "Tam Bố" },
  { value: "16", label: "Tân Châu, Di Linh" },
  { value: "17", label: "Tân Lâm, Di Linh" },
  { value: "18", label: "Tân Nghĩa, Di Linh" },
  { value: "19", label: "Tân Thượng, Di Linh" },
];

export const lamdong_daHuoai = [
  { value: "1", label: "Đạ M'ri" },
  { value: "2", label: "Đạ Oai" },
  { value: "3", label: "Đạ Ploa" },
  { value: "4", label: "Đạ Tồn" },
  { value: "5", label: "Đoàn Kết, Đạ Huoai" },
  { value: "6", label: "Hà Lâm" },
  { value: "7", label: "Ma Đa Guôi (thị trấn)" },
  { value: "8", label: "Ma Đa Guôi (xã)" },
  { value: "9", label: "Phước Lộc, Đạ Huoai" },
];

export const lamdong_daTeh = [
  { value: "1", label: "An Nhơn, Đạ Tẻh" },
  { value: "2", label: "Đạ Kho" },
  { value: "3", label: "Đạ Lây" },
  { value: "4", label: "Đạ Pal" },
  { value: "5", label: "Đạ Tẻh (thị trấn)" },
  { value: "6", label: "Mỹ Đức, Đạ Tẻh" },
  { value: "7", label: "Quảng Trị (xã)" },
  { value: "8", label: "Quốc Oai (xã)" },
  { value: "9", label: "Triệu Hải" },
];

export const lamdong_damRong = [
  { value: "1", label: "Đạ K'Nàng" },
  { value: "2", label: "Đạ Long" },
  { value: "3", label: "Đạ M'Rông" },
  { value: "4", label: "Đạ Rsal" },
  { value: "5", label: "Đạ Tông" },
  { value: "6", label: "Liêng S'Rônh" },
  { value: "7", label: "Phi Liêng" },
  { value: "8", label: "Rô Men" },
];

export const lamdong_donDuong = [
  { value: "1", label: "D'Ran" },
  { value: "2", label: "Đạ Ròn" },
  { value: "3", label: "Ka Đô" },
  { value: "4", label: "Ka Đơn" },
  { value: "5", label: "Lạc Lâm" },
  { value: "6", label: "Lạc Xuân" },
  { value: "7", label: "Pró" },
  { value: "8", label: "Quảng Lập" },
  { value: "9", label: "Thạnh Mỹ, Đơn Dương" },
  { value: "10", label: "Tu Tra" },
];

export const lamdong_ducTrong = [
  { value: "1", label: "Bình Thạnh, Đức Trọng" },
  { value: "2", label: "Đà Loan" },
  { value: "3", label: "Đa Quyn" },
  { value: "4", label: "Hiệp An (xã)" },
  { value: "5", label: "Hiệp Thạnh, Đức Trọng" },
  { value: "6", label: "Liên Hiệp, Đức Trọng" },
  { value: "7", label: "Liên Nghĩa (thị trấn)" },
  { value: "8", label: "N'Thol Hạ" },
  { value: "9", label: "Ninh Gia" },
  { value: "10", label: "Ninh Loan" },
  { value: "11", label: "Phú Hội, Đức Trọng" },
  { value: "12", label: "Tà Hine" },
  { value: "13", label: "Tà Năng" },
  { value: "14", label: "Tân Hội, Đức Trọng" },
  { value: "15", label: "Tân Thành, Đức Trọng" },
];

export const lamdong_lacDuong = [
  { value: "1", label: "Đạ Chais" },
  { value: "2", label: "Đạ Nhim" },
  { value: "3", label: "Đạ Sar" },
  { value: "4", label: "Đưng KNớ" },
  { value: "5", label: "Lạc Dương (thị trấn)" },
  { value: "6", label: "Lát (xã)" },
];

export const lamdong_lamHa = [
  { value: "1", label: "Đạ Đờn" },
  { value: "2", label: "Đan Phượng, Lâm Hà" },
  { value: "3", label: "Đinh Văn" },
  { value: "4", label: "Đông Thanh, Lâm Hà" },
  { value: "5", label: "Gia Lâm, Lâm Hà" },
  { value: "6", label: "Hoài Đức (xã)" },
  { value: "7", label: "Liên Hà, Lâm Hà" },
  { value: "8", label: "Mê Linh, Lâm Hà" },
  { value: "9", label: "Nam Ban" },
  { value: "10", label: "Nam Hà, Lâm Hà" },
  { value: "11", label: "Phi Tô" },
  { value: "12", label: "Phú Sơn, Lâm Hà" },
  { value: "13", label: "Phúc Thọ, Lâm Hà" },
  { value: "14", label: "Tân Hà, Lâm Hà" },
  { value: "15", label: "Tân Thanh, Lâm Hà" },
  { value: "16", label: "Tân Văn, Lâm Hà" },
];

export const gialai_pleiKu = [
  { value: "1", label: "An Phú, Pleiku" },
  { value: "2", label: "Biển Hồ (xã)" },
  { value: "3", label: "Chi Lăng, Pleiku" },
  { value: "4", label: "Chư Ă" },
  { value: "5", label: "Diên Hồng (phường)" },
  { value: "6", label: "Diên Phú, Pleiku" },
  { value: "7", label: "Đống Đa, Pleiku" },
  { value: "8", label: "Gào (xã)" },
  { value: "9", label: "Hoa Lư (phường)" },
  { value: "10", label: "Hội Phú" },
  { value: "11", label: "Hội Thương" },
  { value: "12", label: "Ia Kênh" },
  { value: "13", label: "Ia Kring" },
  { value: "14", label: "Phù Đổng (phường)" },
  { value: "15", label: "Tân Sơn, Pleiku" },
  { value: "16", label: "Tây Sơn, Pleiku" },
  { value: "17", label: "Thắng Lợi, Pleiku" },
  { value: "18", label: "Thống Nhất, Pleiku" },
  { value: "19", label: "Trà Bá" },
  { value: "20", label: "Trà Đa" },
  { value: "21", label: "Yên Đỗ" },
  { value: "22", label: "Yên Thế (phường)" },
];

export const gialai_anKhe = [
  { value: "1", label: "An Bình, An Khê" },
  { value: "2", label: "An Phú, An Khê" },
  { value: "3", label: "An Phước (phường)" },
  { value: "4", label: "An Tân (phường)" },
  { value: "5", label: "Cửu An" },
  { value: "6", label: "Ngô Mây, An Khê" },
  { value: "7", label: "Song An, An Khê" },
  { value: "8", label: "Tây Sơn, An Khê" },
  { value: "9", label: "Thành An, An Khê" },
  { value: "10", label: "Tú An" },
  { value: "11", label: "Xuân An, An Khê" },
];

export const gialai_ayunPa = [
  { value: "1", label: "Cheo Reo" },
  { value: "2", label: "Chư Băh" },
  { value: "3", label: "Đoàn Kết, Ayun Pa" },
  { value: "4", label: "Hòa Bình, Ayun Pa" },
  { value: "5", label: "Ia R'tô" },
  { value: "6", label: "Ia RBol" },
  { value: "7", label: "Ia Sao, Ayun Pa" },
  { value: "8", label: "Sông Bờ (phường)" },
];

export const gialai_chuPah = [
  { value: "1", label: "Chư Đang Ya" },
  { value: "2", label: "Đăk Tơ Ver" },
  { value: "3", label: "Hà Tây (xã)" },
  { value: "4", label: "Hòa Phú, Chư Păh" },
  { value: "5", label: "Ia Ka" },
  { value: "6", label: "Ia Khươl" },
  { value: "7", label: "Ia Kreng" },
  { value: "8", label: "Ia Ly" },
  { value: "9", label: "Ia Mơ Nông" },
  { value: "10", label: "Ia Nhin" },
  { value: "11", label: "Ia Phí" },
  { value: "12", label: "Nghĩa Hòa, Chư Păh" },
  { value: "13", label: "Nghĩa Hưng, Chư Păh" },
  { value: "14", label: "Phú Hòa, Chư Păh" },
];

export const gialai_chuProng = [
  { value: "1", label: "Bàu Cạn, Chư Prông" },
  { value: "2", label: "Bình Giáo" },
  { value: "3", label: "Chư Prông (thị trấn)" },
  { value: "4", label: "Ia Bang" },
  { value: "5", label: "Ia Băng, Chư Prông" },
  { value: "6", label: "Ia Boòng" },
  { value: "7", label: "Ia Drăng" },
  { value: "8", label: "Ia Ga" },
  { value: "9", label: "Ia Kly" },
  { value: "10", label: "Ia Lâu" },
  { value: "11", label: "Ia Me" },
  { value: "12", label: "Ia Mơ" },
  { value: "13", label: "Ia O, Chư Prông" },
  { value: "14", label: "Ia Phìn" },
  { value: "15", label: "Ia Pia" },
  { value: "16", label: "Ia Piơr" },
  { value: "17", label: "Ia Púch" },
  { value: "18", label: "Ia Tôr" },
  { value: "19", label: "Ia Vê" },
  { value: "20", label: "Thăng Hưng" },
];

export const gialai_chuPuh = [
  { value: "1", label: "Chư Don" },
  { value: "2", label: "Ia Blứ" },
  { value: "3", label: "Ia Dreng" },
  { value: "4", label: "Ia Hla" },
  { value: "5", label: "Ia Hrú" },
  { value: "6", label: "Ia Le" },
  { value: "7", label: "Ia Phang" },
  { value: "8", label: "Ia Rong" },
  { value: "9", label: "Nhơn Hòa (thị trấn)" },
];

export const gialai_chuSe = [
  { value: "1", label: "Al Bá" },
  { value: "2", label: "Ayun, Chư Sê" },
  { value: "3", label: "Bar Măih" },
  { value: "4", label: "Bờ Ngoong" },
  { value: "5", label: "Chư Pơng" },
  { value: "6", label: "Chư Sê (thị trấn)" },
  { value: "7", label: "Dun (xã)" },
  { value: "8", label: "HBông" },
  { value: "9", label: "Ia Blang" },
  { value: "10", label: "Ia Glai" },
  { value: "11", label: "Ia HLốp" },
  { value: "12", label: "Ia Ko" },
  { value: "13", label: "Ia Pal" },
  { value: "14", label: "Ia Tiêm" },
  { value: "15", label: "Kông HTok" },
];

export const gialai_dakDoa = [
  { value: "1", label: "A Dơk" },
  { value: "2", label: "Đak Đoa (thị trấn)" },
  { value: "3", label: "Đak Krong" },
  { value: "4", label: "Đak Sơ Mei" },
  { value: "5", label: "Glar" },
  { value: "6", label: "Hà Bầu" },
  { value: "7", label: "Hà Đông, Đak Đoa" },
  { value: "8", label: "Hải Yang" },
  { value: "9", label: "Hneng" },
  { value: "10", label: "Hnol" },
  { value: "11", label: "Ia Băng, Đak Đoa" },
  { value: "12", label: "Ia Pết" },
  { value: "13", label: "Kdang" },
  { value: "14", label: "Kon Gang" },
  { value: "15", label: "Nam Yang" },
  { value: "16", label: "Tân Bình, Đak Đoa" },
  { value: "17", label: " (xã)" },
];

export const gialai_dakPo = [
  { value: "1", label: "An Thành" },
  { value: "2", label: "Cư An" },
  { value: "3", label: "Đak Pơ (thị trấn)" },
  { value: "4", label: "Hà Tam" },
  { value: "5", label: "Phú An, Đak Pơ" },
  { value: "6", label: "Tân An, Đak Pơ" },
  { value: "7", label: "Ya Hội" },
  { value: "8", label: "Yang Bắc" },
];

export const gialai_ducCo = [
  { value: "1", label: "Chư Ty" },
  { value: "2", label: "Ia Din" },
  { value: "3", label: "Ia Dom, Đức Cơ" },
  { value: "4", label: "Ia Dơk" },
  { value: "5", label: "Ia Kla" },
  { value: "6", label: "Ia Krêl" },
  { value: "7", label: "Ia Kriêng" },
  { value: "8", label: "Ia Lang" },
  { value: "9", label: "Ia Nan" },
  { value: "10", label: "Ia Pnôn" },
];

export const gialai_iaGrai = [
  { value: "1", label: "Ia Bă" },
  { value: "2", label: "Ia Chia" },
  { value: "3", label: "Ia Dêr" },
  { value: "4", label: "Ia Grăng" },
  { value: "5", label: "Ia Hrung" },
  { value: "6", label: "Ia Kha" },
  { value: "7", label: "Ia Khai" },
  { value: "8", label: "Ia Krai" },
  { value: "9", label: "Ia O, Ia Grai" },
  { value: "10", label: "Ia Pếch" },
  { value: "11", label: "Ia Sao, Ia Grai" },
  { value: "12", label: "Ia Tô" },
  { value: "13", label: "Ia Yok" },
];

export const gialai_iaPa = [
  { value: "1", label: "Chư Mố" },
  { value: "2", label: "Chư Răng" },
  { value: "3", label: "Ia Broăi" },
  { value: "4", label: "Ia Kdăm" },
  { value: "5", label: "Ia Mrơn" },
  { value: "6", label: "Ia Trok" },
  { value: "7", label: "Ia Tul" },
  { value: "8", label: "Kim Tân, Ia Pa" },
  { value: "9", label: "Pờ Tó" },
];

export const gialai_kBang = [
  { value: "1", label: "Đăk Rong" },
  { value: "2", label: "Sơn Lang" },
  { value: "3", label: "Kon Pne" },
  { value: "4", label: "Krong" },
  { value: "5", label: "Sơ Pai" },
  { value: "6", label: "Lơ Ku" },
  { value: "7", label: "Nghĩa An" },
  { value: "8", label: "Tơ Tung" },
  { value: "9", label: "Kon Lơng Khơn" },
  { value: "10", label: "Đăk Hlơ" },
  { value: "11", label: "Kon Bla" },
  { value: "12", label: "Đăk Smar" },
  { value: "13", label: "Đăk Kpia" },
  { value: "14", label: "xã Đông" },
];

export const gialai_kongChro = [
  { value: "1", label: "An Trung, Kông Chro" },
  { value: "2", label: "Chơ Long" },
  { value: "3", label: "Chư Krey" },
  { value: "4", label: "Đăk Kơ Ning" },
  { value: "5", label: "Đăk Pling" },
  { value: "6", label: "Đăk Pơ Pho" },
  { value: "7", label: "Đăk Song (xã)" },
  { value: "8", label: "Đăk Tơ Pang" },
  { value: "9", label: "Kông Chro (thị trấn)" },
  { value: "10", label: "Kông Yang" },
  { value: "11", label: "Sró" },
  { value: "12", label: "Ya Ma" },
  { value: "13", label: "Yang Nam" },
  { value: "14", label: "Yang Trung" },
];

export const gialai_krongPa = [
  { value: "1", label: "Chư Drăng" },
  { value: "2", label: "Chư Gu" },
  { value: "3", label: "Chư Ngọc" },
  { value: "4", label: "Chư Rcăm" },
  { value: "5", label: "Đất Bằng" },
  { value: "6", label: "Ia HDreh" },
  { value: "7", label: "Ia Mlăh" },
  { value: "8", label: "Ia RMok" },
  { value: "9", label: "Ia RSai" },
  { value: "10", label: "Ia RSươm" },
  { value: "11", label: "Krông Năng (xã)" },
  { value: "12", label: "Phú Cần, Krông Pa" },
  { value: "13", label: "Phú Túc (thị trấn)" },
  { value: "14", label: "Uar" },
];

export const gialai_mangYang = [
  { value: "1", label: "Ayun, Mang Yang" },
  { value: "2", label: "Đak DJrăng" },
  { value: "3", label: "Đăk Jơ Ta" },
  { value: "4", label: "Đăk Ta Ley" },
  { value: "5", label: "Đăk Trôi" },
  { value: "6", label: "Đăk Yă" },
  { value: "7", label: "Đê Ar" },
  { value: "8", label: "Hra" },
  { value: "9", label: "Kon Chiêng" },
  { value: "10", label: "Kon Dơng" },
  { value: "11", label: "Kon Thụp" },
  { value: "12", label: "Lơ Pang" },
];

export const gialai_phuThien = [
  { value: "1", label: "Ayun Hạ" },
  { value: "2", label: "Chrôh Pơnan" },
  { value: "3", label: "Chư A Thai" },
  { value: "4", label: "Ia Ake" },
  { value: "5", label: "Ia Hiao" },
  { value: "6", label: "Ia Peng" },
  { value: "7", label: "Ia Piar" },
  { value: "8", label: "Ia Sol" },
  { value: "9", label: "Ia Yeng" },
  { value: "10", label: "Phú Thiện (thị trấn)" },
];

export const kontum_konTum = [
  { value: "1", label: "Chư Hreng" },
  { value: "2", label: "Duy Tân, thành phố Kon Tum" },
  { value: "3", label: "Đak Năng" },
  { value: "4", label: "Đăk Blà" },
  { value: "5", label: "Đăk Cấm" },
  { value: "6", label: "Đăk Rơ Wa" },
  { value: "7", label: "Đoàn Kết, thành phố Kon Tum" },
  { value: "8", label: "Hòa Bình, thành phố Kon Tum" },
  { value: "9", label: "Ia Chim" },
  { value: "10", label: "Kroong" },
  { value: "11", label: "Lê Lợi, thành phố Kon Tum" },
  { value: "12", label: "Ngọk Bay" },
  { value: "13", label: "Ngô Mây, thành phố Kon Tum" },
  { value: "14", label: "Nguyễn Trãi, thành phố Kon Tum" },
  { value: "15", label: "Quang Trung, thành phố Kon Tum" },
  { value: "16", label: "Quyết Thắng, thành phố Kon Tum" },
  { value: "17", label: "Thắng Lợi, thành phố Kon Tum" },
  { value: "18", label: "Thống Nhất, thành phố Kon Tum" },
  { value: "19", label: "Trần Hưng Đạo, thành phố Kon Tum" },
  { value: "20", label: "Trường Chinh (phường)" },
  { value: "21", label: "Vinh Quang, thành phố Kon Tum" },
  { value: "22", label: "Vinh Quang, thành phố Kon Tum" },
];

export const kontum_dakGlei = [
  { value: "1", label: "Đăk Blô" },
  { value: "2", label: "Đăk Choong" },
  { value: "3", label: "Đăk Glei (thị trấn)" },
  { value: "4", label: "Đăk Kroong" },
  { value: "5", label: "Đăk Long, Đăk Glei" },
  { value: "6", label: "Đăk Man" },
  { value: "7", label: "Đăk Môn" },
  { value: "8", label: "Đăk Nhoong" },
  { value: "9", label: "Đăk Pek" },
  { value: "10", label: "Mường Hoong" },
  { value: "11", label: "Ngọc Linh, Đăk Glei" },
  { value: "12", label: "Xốp (xã)" },
];

export const kontum_dakHa = [
  { value: "1", label: "Đăk Hà (thị trấn)" },
  { value: "2", label: "Đăk Hring" },
  { value: "3", label: "Đăk La" },
  { value: "4", label: "Đăk Long, Đăk Hà" },
  { value: "5", label: "Đăk Mar" },
  { value: "6", label: "Đăk Ngọk" },
  { value: "7", label: "Đăk Pxi" },
  { value: "8", label: "Đăk Ui" },
  { value: "9", label: "Hà Mòn" },
  { value: "10", label: "Ngọk Réo" },
  { value: "11", label: "Ngọk Wang" },
];

export const kontum_dakTo = [
  { value: "1", label: "Diên Bình" },
  { value: "2", label: "Đăk Rơ Nga" },
  { value: "3", label: "Đăk Tô (thị trấn)" },
  { value: "4", label: "Đăk Trăm" },
  { value: "5", label: "Kon Đào" },
  { value: "6", label: "Ngọc Tụ" },
  { value: "7", label: "Pô Kô" },
  { value: "8", label: "Tân Cảnh" },
  { value: "9", label: "Văn Lem" },
];

export const kontum_iaHDrai = [
  { value: "1", label: "Ia Dom, Ia H'Drai" },
  { value: "2", label: "Ia Đal" },
  { value: "3", label: "Ia Tơi" },
];

export const kontum_konPlông = [
  { value: "1", label: "Đăk Nên" },
  { value: "2", label: "Đăk Ring" },
  { value: "3", label: "Đăk Tăng" },
  { value: "4", label: "Hiếu (xã)" },
  { value: "5", label: "Măng Bút" },
  { value: "6", label: "Măng Cành" },
  { value: "7", label: "Măng Đen" },
  { value: "8", label: "Ngọc Tem" },
  { value: "9", label: "Pờ Ê" },
];

export const kontum_konRay = [
  { value: "1", label: "Đăk Kôi" },
  { value: "2", label: "Đăk Pne" },
  { value: "3", label: "Đăk Ruồng" },
  { value: "4", label: "Đăk Rve" },
  { value: "5", label: "Đăk Tơ Lung" },
  { value: "6", label: "Đăk Tờ Re" },
  { value: "7", label: "Tân Lập, Kon Rẫy" },
];

export const kontum_ngocHoi = [
  { value: "1", label: "Đăk Ang" },
  { value: "2", label: "Đăk Dục" },
  { value: "3", label: "Đăk Kan" },
  { value: "4", label: "Đăk Nông (xã)" },
  { value: "5", label: "Đăk Xú" },
  { value: "6", label: "Plei Kần" },
  { value: "7", label: "Pờ Y" },
  { value: "8", label: "Sa Loong" },
];

export const kontum_saThay = [
  { value: "1", label: "Hơ Moong" },
  { value: "2", label: "Mô Rai" },
  { value: "3", label: "Rờ Kơi" },
  { value: "4", label: "Sa Bình" },
  { value: "5", label: "Sa Nghĩa" },
  { value: "6", label: "Sa Nhơn" },
  { value: "7", label: "Sa Sơn" },
  { value: "8", label: "Sa Thầy (thị trấn)" },
  { value: "9", label: "Ya Ly" },
  { value: "10", label: "Ya Tăng" },
  { value: "11", label: "Ya Xiêr" },
];

export const kontum_tuMoRong = [
  { value: "1", label: "Đăk Hà (xã)" },
  { value: "2", label: "Đăk Na" },
  { value: "3", label: "Đăk Rơ Ông" },
  { value: "4", label: "Đăk Sao" },
  { value: "5", label: "Đăk Tờ Kan" },
  { value: "6", label: "Măng Ri" },
  { value: "7", label: "Ngọk Lây" },
  { value: "8", label: "Ngọk Yêu" },
  { value: "9", label: "Tê Xăng" },
  { value: "10", label: "Tu Mơ Rông (xã)" },
  { value: "11", label: "Văn Xuôi" },
];