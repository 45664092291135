// Loại bất động sản
export const estateCateArr = [
  { value: "1", label: "Căn hộ chung cư" },
  { value: "2", label: "Nhà vườn farm" },
  { value: "3", label: "Nhà riêng" },
  { value: "4", label: "Nhà biệt thự, liền kề" },
  { value: "5", label: "Các loại nhà bán" },
  { value: "6", label: "Đất nền" },
  { value: "7", label: "Đất rẫy" },
  { value: "8", label: "Đất nông nghiệp" },
  { value: "9", label: "Kho, xưởng" },
];

export const costArr = [
  { value: "1", label: "dưới 1 tỷ" },
  { value: "2", label: "dưới 2 tỷ" },
  { value: "3", label: "dưới 3 tỷ" },
  { value: "4", label: "dưới 4 tỷ" },
  { value: "5", label: "dưới 5 tỷ" },
  { value: "6", label: "dưới 6 tỷ" },
  { value: "7", label: "dưới 7 tỷ" },
  { value: "8", label: "dưới 8 tỷ" },
  { value: "9", label: "dưới 9 tỷ" },
  { value: "10", label: "dưới 10 tỷ" },
];

export const areaArr = [
  { value: "1", label: "0-50 m²" },
  { value: "2", label: "50-100 m²" },
  { value: "3", label: "100-150 m²" },
  { value: "4", label: "150-200 m²" },
  { value: "5", label: "200-250 m²" },
  { value: "6", label: "250-300 m²" },
  { value: "7", label: "300-350 m²" },
  { value: "8", label: "350-400 m²" },
  { value: "9", label: "400-450 m²" },
  { value: "10", label: "450-500 m²" },
  { value: "11", label: "500-10000 m²" },
];

// Giấy tờ pháp lý
export const legalDocument = [
  { value: "1", label: "Sổ đỏ/ Sổ hồng" },
  { value: "2", label: "Hợp đồng mua bán" },
];

// Đơn vị mua bán
export const unitVND = [
  { value: "1", label: "VND" },
  { value: "2", label: "Giá / m²" },
];

// Hướng nhà
export const direction = [
  { value: "1", label: "Đông" },
  { value: "2", label: "Tây" },
  { value: "3", label: "Nam" },
  { value: "4", label: "Bắc" },
  { value: "5", label: "Đông-Bắc" },
  { value: "6", label: "Tây-Bắc" },
  { value: "7", label: "Đông-Nam" },
  { value: "8", label: "Tây-Nam" },
];

// Số phòng ngủ
export const bedRooms = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

// Số phòng khách
export const livingRooms = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

// Số phòng tắm, vệ sinh
export const toiletRooms = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

// Số hình thức giao dichj
export const transactionType = [
  { value: "1", label: "Chính chủ" },
  { value: "2", label: "Người môi giới" },
  { value: "3", label: "Nhà đầu tư" },
];

// Khởi tạo option
export const defaultOptions = [
];