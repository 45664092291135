import React, { Component } from 'react';
import '../../css/quill.snow.css';
import DOMPurify from 'dompurify';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../atribute/EditorToobar";


class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { text: '' };
  }

  handleChange = (value) => {
    
    if (value.length <= 1450) {
      this.setState({ text: value });
      this.props.onEditorChange(DOMPurify.sanitize(value));
    } else {
      alert(`Số lượng từ gần vượt 1500 từ, nếu vượt quá 1500 từ sẽ bị reset.`);
      this.setState({ text: '' });
      this.props.onEditorChange('');
    }
  }

  render() {
    
    
    return (
      <div>
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={this.state.text}
          onChange={this.handleChange}
          placeholder={"Mô tả chi tiết về bất động sản..."}
          modules={modules}
          formats={formats}
        />
        
      </div>
    );
  }
}

export default TextEditor;
