import React, { Component } from "react";
import ReactMapboxGl, { GeoJSONLayer } from "react-mapbox-gl";
import '../../css/mapbox.css';

require('dotenv').config();

const Map = ReactMapboxGl({
  accessToken:process.env.REACT_APP_MAPBOX_TOKEN,
});

class Mapbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      isSatelliteView: false,
      geoCodingAddress: {
        long: '',
        latt: '',
      },
    };
  }

  toggleSatelliteView = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isSatelliteView: !prevState.isSatelliteView,
    }));
  };

  // Gọi API chuyển địa chỉ thành tọa độ ở đây khi component được tạo
  componentDidMount() {
    this.getCoordinatesFromAddress(this.props.address);
  }

  // Sử dụng fetch để gọi API chuyển địa chỉ thành tọa độ (thay thế URL và tham số tương ứng)
  getCoordinatesFromAddress = (address) => {
    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${this.state.accessToken}`)
      .then(response => response.json())
      .then(data => {
        if (data.features && data.features.length > 0) {
          const coordinates = data.features[0].geometry.coordinates;
          this.setState({
            geoCodingAddress: {
              long: coordinates[0],
              latt: coordinates[1]
            }
          });
        } else {
          console.error("Không tìm thấy tọa độ cho địa chỉ này.");
        }
      })
      .catch(error => {
        console.error("Lỗi khi gọi API chuyển địa chỉ thành tọa độ:", error);
      });
  };

  render() {
    const { isSatelliteView } = this.state;
    const centerCoordinate = [this.state.geoCodingAddress.long, this.state.geoCodingAddress.latt];
    const mapStyle = isSatelliteView
      ? "mapbox://styles/mapbox/satellite-v9"
      : "mapbox://styles/mapbox/streets-v9";

    return (
      <div className="container-mapbox">
        <Map
          style={mapStyle}
          containerStyle={{
            height: "50vh",
            width: "69vw",
          }}
          zoom={[14]}
          center={centerCoordinate}
        >
          {/* Hiển thị vùng hình tròn */}
          <GeoJSONLayer
            data={{
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: centerCoordinate,
                  },
                },
              ],
            }}
            circleLayout={{
              visibility: "visible",
            }}
            circlePaint={{
              "circle-color": "red",
              "circle-radius": 70,
              "circle-opacity": 0.4,
            }}
          />
          <div className="container-mapbox-inner">
            <div>
              <button className={`streets-map ${isSatelliteView ? 'satellite-map' : ''}`}
                onClick={this.toggleSatelliteView}>
                {isSatelliteView ? "" : ""}
              </button>
            </div>
          </div>
        </Map>
      </div>
    );
  }
}

export default Mapbox;
