import React from 'react';

class TextWithEllipsis extends React.Component {
  truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + ' ...';
    } else {
      return text;
    }
  }

  render() {
    const { text, maxLength } = this.props;
    const truncatedText = this.truncateText(text, maxLength);

    return (
      <span>{truncatedText}</span>
    );
  }
}

export default TextWithEllipsis;
