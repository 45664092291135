import React from "react";
import * as disLabel from "../constants/label";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import { FaSignOutAlt, FaEyeSlash, FaEye } from 'react-icons/fa';
import GetCookies from '../hooks/GetCookie';
import RemoveCookies from '../hooks/RemoveCookie';
import http from "../axios/http-common";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import * as disErrMess from "../constants/errorMessage";
require('dotenv').config();


const recaptcha_key = process.env.REACT_APP_SITE_KEY;


class MyPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeDiv: 1,
      username: localStorage.getItem("username"),
      isAuth: !!GetCookies('access_token'),
      fields: {
        currentpassword: '',
        newpassword: '',
        renewpassword: ''
      },
      curentPasswordVisible: false,
      newPasswordVisible: false,
      reNewPasswordVisible: false,
      errors: {},
      sucessinform: false,
      isRecaptchaVerified: false,
      recaptchaError: "",
    };
  }

  togglePasswordVisibility = (e, field) => {
    e.preventDefault();
    if (field === "curentPasswordVisible") {
      this.setState({ curentPasswordVisible: !this.state.curentPasswordVisible });
    };
    if (field === "newPasswordVisible") {
      this.setState({ newPasswordVisible: !this.state.newPasswordVisible });
    };
    if (field === "reNewPasswordVisible") {
      this.setState({ reNewPasswordVisible: !this.state.reNewPasswordVisible });
    };
  }
  
  handleDivClick = (number) => {
    this.setState({ activeDiv: number })
  }

  createLogoutModal = () => {
    let logoutDataReq = { "refresh_token": GetCookies('refresh_token') };
    logoutDataReq.refresh_token = logoutDataReq.refresh_token.replace(/^"(.*)"$/, '$1');

    (async () => {
      try {
        const response = await http.post(`/logout/`, logoutDataReq);
        if (response.status === 200) {
          RemoveCookies('access_token')
          RemoveCookies('refresh_token')

          this.setState({ isAuth: false });
          window.location.reload();
        } else {
          console.log("Xuất hiện lỗi system trong quá trình logout.");
        }
      } catch (error) {
        console.log('Lỗi xuất hiện trong quả trình logout.', error);
      }
    })();
  };

  handleReset = () => {
    this.setState({
      fields: { currentpassword: '', newpassword: '', renewpassword: '' },
      errors: {},
      recaptchaError: '',
    });
  };

  handleValidate(e) {
    e.preventDefault();

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // Check currentpassword
    if (!fields["currentpassword"]) {
      formIsValid = false;
      errors["currentpassword"] = disErrMess.ERROR_INPUT_ENTER_CURENT_PASSWORD;
    } else if (typeof fields["currentpassword"] !== "undefined") {
      if (!fields["currentpassword"].match(/^\S*$/)) {
        formIsValid = false;
        errors["currentpassword"] = disErrMess.ERROR_INPUT_ENTER_CURENT_PASSWORD;
      }
    }

    // Check newPassword
    if (!fields["newpassword"] || !fields["renewpassword"]) {
      formIsValid = false;
      errors["renewpassword"] = disErrMess.ERROR_INPUT_PASSWORD;
    } else if (fields["newpassword"].length < 8 || fields["renewpassword"].length < 8) {
      formIsValid = false;
      errors["renewpassword"] = disErrMess.ERROR_INPUT_PASSWORD_LESSTHAN_8_CHARACTER;
    } else if (fields["newpassword"] !== fields["renewpassword"]) {
      formIsValid = false;
      errors["renewpassword"] = disErrMess.ERROR_INPUT_PASSWORD_NOT_THE_SAME;
    } else if (typeof fields["newpassword"] !== "undefined" || typeof fields["renewpassword"] !== "undefined") {
      if (!fields["newpassword"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}/)) {
        formIsValid = false;
        errors["renewpassword"] = disErrMess.ERROR_INPUT_PASSWORD_AND_REPASSWORD;
      }
    }


    this.setState({ errors: errors });

    // submit form
    let changeDataForm =
    {
      "username": this.state.username,
      "currentpassword": this.state.fields.currentpassword,
      "newpassword": this.state.fields.newpassword,
      "renewpassword": this.state.fields.renewpassword,
    };

    if (formIsValid !== false) {

      if (!this.state.isRecaptchaVerified) {
        this.setState({
          recaptchaError: "Chọn tôi không phải robot để tiếp tục."
        });
        return;
      }

      http.post(`/changepassword/`, changeDataForm)
        .then(res => {
          if (res.status === 200) {
            this.setState({ sucessinform: true });
            this.handleReset();
          }
        })
        .catch(error => {
          try {
            // Catch lỗi sử dụng mail đã đăng ký từ trước.
            if (typeof error.response.data.email !== 'undefined') {
              errors["email"] = disErrMess.ERROR_INPUT_EMAIL_HAS_USED;
            }
            // Catch lỗi sử dụng username đã đăng ký từ trước.
            if (typeof error.response.data.username !== 'undefined') {
              errors["name"] = disErrMess.ERROR_INPUT_NAME_INVALID;

            }
            this.setState({ errors: errors })
          } catch (error) {
            console.log('Error:', error);
          }
        });
    }

    return formIsValid;
  };

  // get value from element then set to state
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleRecaptchaChange = (value) => {
    if (value) {
      this.setState({
        isRecaptchaVerified: true,
        recaptchaError: ""
      });
    } else {
      this.setState({
        isRecaptchaVerified: false,
        recaptchaError: "Chọn tôi không phải robot để tiếp tục."
      });
    }
  };

  render() {

    const lang = {
      hl: "en"
    };

    return (
      <div className="container-mypage">
        {this.state.activeDiv === 1 ?
          <div className="mypage-div-profile">
            {disLabel.PROFILE_USER}
          </div> : null
        }

        {this.state.activeDiv === 2 ? (
          <div className="mypage-div-content">
            {this.state.sucessinform === true ? (
              <div className="message-susses-area"><span>{disLabel.CHANGE_PASSWORD_SUCCESS}</span></div>
            ) : (
              null
            )}
            <Form>
              <FormGroup className="modal-form">
                <Label className="modal-text" for="name">
                  {disLabel.CURRENT_PASSWORD}
                </Label>
                <Input
                  type={this.state.curentPasswordVisible ? 'text' : 'password'}
                  id="currentpassword"
                  name="currentpassword"
                  value={this.state.fields.currentpassword}
                  maxLength="32"
                  placeholder="Nhập mật khẩu hiện tại"
                  onChange={(e) => this.handleChange("currentpassword" , e)}
                />
                <a className="button-visible-password" onClick={(e) => this.togglePasswordVisibility(e, "curentPasswordVisible")}>
                  {this.state.curentPasswordVisible ?
                    <FaEye className="show-password-entered" size={25} fill="rgb(147 144 144)" /> :
                    <FaEyeSlash className="show-password-entered" size={25} fill="rgb(147 144 144)" />
                  }
                </a>
                <span className="text-danger">{this.state.errors["currentpassword"]}</span>
              </FormGroup>
              <FormGroup className="modal-form">
                <Label className="modal-text" for="password">
                  {disLabel.NEW_PASSWORD}
                </Label>
                <Input
                  type={this.state.newPasswordVisible ? 'text' : 'password'}
                  id="newpassword"
                  name="newpassword"
                  value={this.state.fields.newpassword}
                  maxLength="32"
                  placeholder="Nhập mật khẩu mới"
                  onChange={(e) => this.handleChange("newpassword", e )}
                />
                <a className="button-visible-password" onClick={(e) => this.togglePasswordVisibility(e, "newPasswordVisible")}>
                  {this.state.newPasswordVisible ?
                    <FaEye className="show-password-entered" size={25} fill="rgb(147 144 144)" /> :
                    <FaEyeSlash className="show-password-entered" size={25} fill="rgb(147 144 144)" />
                  }
                </a>
                <Input
                  type={this.state.reNewPasswordVisible ? 'text' : 'password'}
                  id="renewpassword"
                  name="renewpassword"
                  value={this.state.fields.renewpassword}
                  maxLength="32"
                  placeholder="Xác nhận lại mật khẩu mới"
                  onChange={(e) => this.handleChange("renewpassword", e)}
                />
                <a className="button-visible-password" onClick={(e) => this.togglePasswordVisibility(e, "reNewPasswordVisible")}>
                  {this.state.reNewPasswordVisible ?
                    <FaEye className="show-password-entered" size={25} fill="rgb(147 144 144)" /> :
                    <FaEyeSlash className="show-password-entered" size={25} fill="rgb(147 144 144)" />
                  }
                </a>
                <span className="text-danger">{this.state.errors["renewpassword"]}</span>
              </FormGroup>
              <div className="recaptcha-container">
                <ReCAPTCHA
                  className="recaptcha-small"
                  ref={(ref) => (this.recaptcha = ref)}
                  sitekey={recaptcha_key}
                  onChange={this.handleRecaptchaChange}
                  lang={lang}
                />
              </div>
              <div>{this.state.recaptchaError && (
                <div className="im-not-robot">
                  {this.state.recaptchaError}
                </div>
              )}</div>
              <Button color="btn btn-info" onClick={this.handleValidate.bind(this)}>{disLabel.CHANGE_PASSWORD_BTN}</Button>
              <Button color="btn btn-secondary" onClick={this.handleReset}>{disLabel.DELETE_BTN}</Button>
            </Form>
          </div>
        ) : null}


        {this.state.activeDiv === 3 ?
          <div className="mypage-div-content">
            パスワード変更
          </div> : ''
        }

        <div className="mypage-div-right">
          <div className={`mypage-div-right-item ${this.state.activeDiv === 1 ? "active" : "mypage-div-right-item_none"
            }`} onClick={() => this.handleDivClick(1)}>
            <a href="/" onClick={(e) => { e.preventDefault(); }}><div className="mypage-div-right-item_1">プロファイル</div></a>
          </div>
          <div className={`mypage-div-right-item ${this.state.activeDiv === 2 ? "active" : "mypage-div-right-item_none"
            }`} onClick={() => this.handleDivClick(2)}>
            <a href="/" onClick={(e) => { e.preventDefault(); }}><div className="mypage-div-right-item_1">パスワード変更</div></a>
          </div>
          <div className={`mypage-div-right-item ${this.state.activeDiv === 3 ? "active" : "mypage-div-right-item_none"
            }`} onClick={() => this.handleDivClick(2)}>

            <div className="mypage-div-right-item_1">
              <FaSignOutAlt size={25} fill="#FF6800" />
              <LinkContainer to="/">
                <Nav.Link onClick={this.createLogoutModal}>
                  <div className="logout-link">{disLabel.LOGOUT_FORM}</div>
                </Nav.Link>
              </LinkContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyPage;
