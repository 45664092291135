import React from "react";
import * as disLabel from "../constants/label";
import { FaRegCheckCircle, FaRegBell } from 'react-icons/fa';

class CampaignPage extends React.Component {


  render() {

    return (
      <div className="container-instruction-parent">
        <div>
        <span className="infos-inform">{disLabel.CAMPAIGN_INFOS}</span>
          <div className="container-instruction-child">
            <FaRegCheckCircle size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.CAMPAIGN_INFOS_1}</span>
          </div>
          <div className="container-instruction-child">
            <FaRegCheckCircle size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.CAMPAIGN_INFOS_2}</span>
          </div>
          <div className="container-instruction-child">
            <FaRegCheckCircle size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.CAMPAIGN_INFOS_3}</span>
          </div>
          <div className="container-instruction-child">
            <FaRegCheckCircle size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.CAMPAIGN_INFOS_4}</span>
          </div>
          <div className="container-instruction-child">
            <FaRegCheckCircle size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.CAMPAIGN_INFOS_5}</span>
          </div>
          <div className="container-instruction-child">
            <FaRegCheckCircle size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.CAMPAIGN_INFOS_6}</span>
          </div>
        </div>
        <div className="div-infos-inform">
          <span className="infos-inform">{disLabel.ADVERTIES_INFOS}</span>
          <div className="container-instruction-child">
            <FaRegBell size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.ADVERTIES_INFOS_1}</span>
          </div>
          <div className="container-instruction-child">
            <FaRegBell size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.ADVERTIES_INFOS_2}</span>
          </div>
          <div className="container-instruction-child">
            <FaRegBell size={25} className="fa-heard-title" style={{ fill: '#FF6800' }} /><span className="">{disLabel.ADVERTIES_INFOS_3}</span>
          </div>
        </div>
      </div>
    );
  }
}
export default CampaignPage;
