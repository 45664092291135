import React, { Component } from "react";
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from "@material-ui/core/styles";
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF6800"
    }
  }
});

const styles = theme => ({
  drawerPaper: {
    width: 180
  }
});

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div >
        <div className="navbar-customize">
          <ThemeProvider theme={theme}>
            <AppBar >
              <Toolbar>
                <IconButton
                  onClick={() => {
                    this.setState({ drawerOpen: true });
                  }}
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>

            <Drawer
              anchor="left"
              variant="persistent"
              open={this.state.drawerOpen}
              onClose={() => {
                this.setState({ drawerOpen: false });
              }}
              classes={{ paper: classes.drawerPaper }}
            >
              <IconButton
                style={{ justifyContent: "flex-end" }}
                color="primary"
                onClick={() => {
                  this.setState({ drawerOpen: false });
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
              <Divider />
              <LinkContainer to="/">
                <Nav.Link className="mobile-menu-link" onClick={() => { this.setState({ drawerOpen: false }); }}>Trang chủ</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/nha-cho-thue">
                <Nav.Link className="mobile-menu-link" onClick={() => { this.setState({ drawerOpen: false }); }}>Nhà cho thuê</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/du-an-dau-tu">
                <Nav.Link className="mobile-menu-link" onClick={() => { this.setState({ drawerOpen: false }); }}>Dự án đầu tư</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/huong-dan">
                <Nav.Link className="mobile-menu-link" onClick={() => { this.setState({ drawerOpen: false }); }}>Hướng dẫn</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer to="/quang-cao-va-uu-dai">
                <Nav.Link className="mobile-menu-link" onClick={() => { this.setState({ drawerOpen: false }); }}>Quảng cáo & ưu đãi</Nav.Link>
              </LinkContainer> */}
              <LinkContainer to="/lien-he">
                <Nav.Link className="mobile-menu-link" onClick={() => { this.setState({ drawerOpen: false }); }}>Liên hệ</Nav.Link>
              </LinkContainer>
            </Drawer>
          </ThemeProvider>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Navbar);
