import React from "react";


class RuleUsePage extends React.Component {


  render() {

    return (
      <div className="container-rule-parent">
        <span className="rule-use-title-lv1">Quy chế hoạt động</span>
        <div className="rule-use-title-lv2">
          <span >Đối tượng đăng tin:</span>
          <div className="rule-use-content-lv2">
            <span >Cá nhân có quyền hợp pháp tham gia các thỏa thuận/hợp đồng mua bán ở Việt Nam, và bán hàng không vì mục đích kinh doanh. Người sử dụng phải trên 18 tuổi, nếu dưới thì phải có sự giám sát của cha mẹ hoặc người giám hộ hợp pháp.</span> <br />
            <span>Cá nhân/tổ chức bao gồm cửa hàng, doanh nghiệp, môi giới, hộ gia đình có quyền hợp pháp tham gia các thỏa thuận/hợp đồng mua bán ở Việt Nam.</span>
          </div>
          <span >Qui định cơ bản:</span>
          <div className="rule-use-content-lv2">
            <span>Đăng tin đúng chuyên mục với sản phẩm muốn bán và phải chịu trách nhiệm về nội dung và thông tin đăng trên Raozat.com.</span><br />
            <span>Khách hàng không được đăng tin bán các mặt hàng cấm kinh doanh theo quy định của nhà nước Việt Nam.</span><br />
            <span>Nội dung tin đăng cần có đầy đủ thông tin để người mua có thể quyết định mua hàng.</span><br />
            <span>Người mua và người bán tự chịu trách nhiệm về giao dịch.</span><br />
          </div>
          <span >Thông tin liên hệ:</span>
          <div className="rule-use-content-lv2">
            <span>Người đăng tin phải bắt buộc cung cấp đầy đủ, chính xác thông tin về tên thật của người bán, số điện thoại, địa chỉ email, địa chỉ. Người bán phải có chịu mọi trách nhiệm nếu như đưa thông tin liên hệ sai.</span><br />
          </div>
          <span >Tiêu đề tin đăng:</span>
          <div className="rule-use-content-lv2">
            <span>Tiêu đề bao gồm tối đa 70 ký tự miêu tả tổng quan về bất động sản, tiêu đề sẽ tự động format chữ cái đầu viết hoa, và viết thường các chữ còn lại.</span><br />
          </div>
          <span >Hình ảnh:</span>
          <div className="rule-use-content-lv2">
            <span>Mỗi tin đăng được tối đa 5 hình ảnh thể hiện chân thực về sản phẩm hoặc dịch vụ, không chấp nhận hình ảnh sai khác hoặc không đúng với bất động sản đang được đề cập tới.</span><br />
          </div>
          <span >Nội dung:</span>
          <div className="rule-use-content-lv2">
            <span>Người dùng phải mô tả thông tin chi tiết về bất động sản cần bán như loại nhà đất,địa chỉ, diện tích, số phòng ngủ, hướng nhà, thông tin người sở hữu nếu là chính chủ, hoặc người môi giới hoặc nhà đầu tư nếu không phải là chính chủ...</span><br />
          </div>
          <span >Giá:</span>
          <div className="rule-use-content-lv2">
            <span>Giá trên trang Raozat.com được niêm yết bằng Việt Nam Đồng (VNĐ). Mọi sản phẩm/dịch vụ đều phải cung cấp giá. Theo pháp lệnh của nhà nước, mọi tin đăng có giá là ngoại tệ đều bị từ chối và xóa bỏ.</span><br />
          </div>
          <span >Ngôn ngữ:</span>
          <div className="rule-use-content-lv2">
            <span>Raozat.com chỉ chấp nhận sử dụng tiếng Việt có dấu.</span><br />
            <span>Theo điều 8 mục 11 của Luật Quảng cáo, không được sử dụng các từ ngữ "nhất", "duy nhất", "tốt nhất", "số một" hoặc từ ngữ có ý nghĩa tương tự trong nội dung tin đăng (nếu sử dụng phải có tài liệu chứng minh).</span><br />
          </div>
          <span >Hành vi nghiêm cấm:</span>
          <div className="rule-use-content-lv2">
            <span>▪ Chống phá và xuyên tạc:</span><br />
            <span>Tuyệt đối cấm tuyên truyền, chống phá và xuyên tạc chính quyền, thể chế, và chính sách của nhà nước.</span><br />
            <span>▪ Bạo lực và gây hận thù:</span><br />
            <span>Cấm kích động bạo lực, tuyên truyền chiến tranh, và gây hận thù giữa các dân tộc và nhân dân các quốc gia.</span><br />
            <span>▪ Nội dung đồi trụy và tội ác:</span><br />
            <span>Tuyệt đối không chấp nhận nội dung đồi trụy, tội ác, hay gây hại đến đạo đức xã hội.</span><br />
            <span>▪ Mê tín dị đoan và phá hoại thuần phong mỹ tục:</span><br />
            <span>Cấm mê tín dị đoan và hành vi phá hoại thuần phong mỹ tục của dân tộc.</span><br />
            <span>▪ Tiết lộ bí Mật nhà nước và quân Sự:</span><br />
            <span>Nghiêm cấm tiết lộ bí mật nhà nước, quân sự, an ninh, kinh tế, và các bí mật khác được quy định bởi pháp luật.</span><br />
            <span>▪ Hành vi phá hoại và làm ảnh hưởng đến giá trị cộng đồng:</span><br />
            <span>Cấm mọi hành vi phá hoại, làm ảnh hưởng đến giá trị và chất lượng của cộng đồng.</span><br />
            <span>▪ Cờ bạc và cá cược:</span><br />
            <span>Tuyệt đối nghiêm cấm tổ chức các hình thức cá cược, cờ bạc, hoặc các thỏa thuận liên quan đến tiền, hiện kim, hiện vật.</span><br />
            <span>▪ Xúc phạm và nhạo báng:</span><br />
            <span>Cấm xúc phạm, nhạo báng người khác dưới mọi hình thức (nhạo báng, chê bai, kỳ thị tôn giáo, giới tính, sắc tộc…).</span><br />
            <span>▪ Cấm đăng tải từ nguồn không đáng tin cậy:</span><br />
            <span>Cấm đăng lại từ các trang blog cá nhân, diễn đàn, hay các nguồn tin không đáng tin cậy.</span><br />
            <span>▪ Bảo đảm tuân thủ thuần phong mỹ tục:</span><br />
            <span>Nội dung không được phép đi ngược lại thuần phong mỹ tục của Việt Nam và pháp luật nước Cộng hòa Xã hội Chủ nghĩa Việt Nam.</span><br />
            <span>Chúng tôi sẽ xử lý mọi vi phạm một cách nghiêm túc và có thể chuyển thông tin của người vi phạm cho cơ quan chức năng để xử lý theo quy định của pháp luật. Xin cảm ơn sự hợp tác của Quý thành viên.</span>
          </div>
          <span >Khác:</span>
          <div className="rule-use-content-lv2">
            <span>Raozat.com tôn trọng và nghiêm túc thực hiện các quy định của pháp luật về bảo vệ quyền lợi của Khách hàng (người tiêu dùng). Vì vậy, đề nghị các Nhà Bán Hàng trên sàn TMĐT Raozat.com cung cấp đầy đủ, chính xác, trung thực và chi tiết các thông tin liên quan đến bất động sản cần bán. Mọi hành vi lừa đảo, gian lận trong kinh doanh đều bị lên án và phải chịu hoàn toàn trách nhiệm trước pháp luật.</span>
          </div>
        </div>
      </div>
    );
  }
}
export default RuleUsePage;
