import React, { useState } from 'react';
import Footer from './footerItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { LinkContainer } from "react-router-bootstrap";
import * as disLabel from "../../constants/label";
import LoginModal from "../../modal/LoginModal";
import SignUpModal from "../../modal/SignUpModal";
import GetCookies from '../../hooks/GetCookie';

class FooterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuth: !!GetCookies('access_token'),
            loginModal: false,
            signUpModal: false,
        };
    };

    // function đăng ký
    createSignUpModal = () => {
        this.setState({ signUpModal: !this.state.signUpModal });
    };

    isClosedLoginForm = () => {
        this.toggleLogin();
        this.createSignUpModal();
    }

    openSignUpForm = () => {
        this.setState({ signUpModal: true })
    };

    openLoginForm = () => {
        this.setState({ loginModal: true })
    };

    toggleSignUp = () => {
        this.setState({ signUpModal: !this.state.signUpModal });
    };

    toggleLogin = () => {
        this.setState({ loginModal: !this.state.loginModal });
    };

    createPublishEstate = () => {
        if (!this.state.isAuth) {
            this.openLoginForm();
        }
    };

    render() {
        return (
            <div>
                <div>
                    <Footer>
                        <Footer.Wrapper>
                            <Footer.Row>
                                <Footer.Column>
                                    <Footer.Title>VỀ RAOZAT.COM</Footer.Title>
                                    <LinkContainer to="/gioi-thieu">
                                        <Footer.Link>Giới thiệu</Footer.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/quy-che-hoat-dong">
                                        <Footer.Link>Quy chế hoạt động</Footer.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/bao-mat-thong-tin">
                                        <Footer.Link>Bảo mật thông tin</Footer.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/lien-he">
                                        <Footer.Link>Liên hệ</Footer.Link>
                                    </LinkContainer>
                                </Footer.Column>
                                <Footer.Column>
                                    <Footer.Title>ACCOUNT</Footer.Title>
                                    <LinkContainer to="/">
                                        <Footer.Link onClick={this.openSignUpForm}>Đăng ký</Footer.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/">
                                        <Footer.Link onClick={this.openLoginForm}>Đăng nhập</Footer.Link>
                                    </LinkContainer>
                                    {this.state.isAuth === true ? (
                                        <LinkContainer to="/dang-tin" onClick={this.createPublishEstate}>
                                            <Footer.Link>{disLabel.PUBLIST_BTN}</Footer.Link>
                                        </LinkContainer>

                                    ) : (
                                        <LinkContainer to="/" onClick={this.createPublishEstate}>
                                            <Footer.Link>{disLabel.PUBLIST_BTN}</Footer.Link>
                                        </LinkContainer>
                                    )}
                                    <Footer.Link href="#">Tin tức</Footer.Link>
                                </Footer.Column>
                                <Footer.Column>
                                    <Footer.Title>LIÊN KẾT</Footer.Title>
                                    <Footer.Link href="#">Sitemap</Footer.Link>
                                    <Footer.Link href="#">Email: lienhe@raozat.com</Footer.Link>
                                    <Footer.Link href="#">SkypeId: thanh.bq</Footer.Link>
                                </Footer.Column>
                                <Footer.Column>
                                    <Footer.Title>KẾT NỐI SNS</Footer.Title>
                                    <Footer.Link href="#" className="sns-link"><FontAwesomeIcon className='icon-footer' icon={faFacebookF} style={{ color: '#1877F2' }} />Facebook</Footer.Link>
                                    <Footer.Link href="#" className="sns-link"><FontAwesomeIcon className='icon-footer' icon={faInstagram} style={{ color: '#FDB34F' }} />Instagram</Footer.Link>
                                    <Footer.Link href="#" className="sns-link"><FontAwesomeIcon className='icon-footer' icon={faYoutube} style={{ color: '#FF0000' }} />Youtube</Footer.Link>
                                    <Footer.Link href="#" className="sns-link"><FontAwesomeIcon className='icon-footer' icon={faTwitter} style={{ color: '#1DA1F2' }} />Twitter</Footer.Link>
                                </Footer.Column>
                            </Footer.Row>
                            {this.state.signUpModal ? (
                                <SignUpModal toggle={this.toggleSignUp} />
                            ) : null}
                            {this.state.loginModal ? (
                                <LoginModal isOpenSignupForm={this.isClosedLoginForm} toggle={this.toggleLogin} />
                            ) : null}
                        </Footer.Wrapper>
                    </Footer>
                </div>
                <div className='copyright-container'>© 2023 Raozat.com - Nền tảng mua bán bất động sản miễn phí và đáng tin cậy, được phát triển bởi đội ngũ Kỹ sư IT tại Nhật Bản. Chúng tôi cam kết tạo ra trải nghiệm mua bán an toàn và thuận lợi. Hãy lựa chọn Raozat.com để trải nghiệm sự đổi mới và chất lượng từ chuyên gia hàng đầu trong lĩnh vực giao dịch bất động sản. Người chiệu trách nhiệm: ông Bùi Quang Thạnh.</div>
            </div>
        )
    }
}
export default FooterPage;