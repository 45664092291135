import React, { useCallback, useState, useEffect  } from 'react';
import { useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';
import '../../css/Upload.css';
import { FaTimes, FaCloudUploadAlt  } from 'react-icons/fa';
import * as disLabel from '../constants/label';

const Upload = ({ onFilesChange }) => {
     // Lưu trữ danh sách tệp đã tải lên
    const [uploadedFiles, setUploadedFiles] = useState([]);
    // Đếm số lượng tệp đã tải lên
    const [fileCount, setFileCount] = useState(0); 
    const MAX_FILE_COUNT = 5;

    const onDrop = useCallback((acceptedFiles) => {

        if (fileCount + acceptedFiles.length > MAX_FILE_COUNT) {
            alert(`Bạn chỉ được tải lên maximum ${MAX_FILE_COUNT} ảnh.`);
            return;
        }

        const compressAndAddFiles = async () => {
            const compressedFiles = [];

            for (const file of acceptedFiles) {
                if (fileCount < MAX_FILE_COUNT) {
                    const compressedFile = await new Promise((resolve) => {
                        new Compressor(file, {
                            quality: 0.3,
                            success(result) {
                                resolve(result);
                            },
                            error(err) {
                                console.error(err.message);
                                resolve(null);
                            },
                        });
                    });

                    if (compressedFile) {
                        compressedFiles.push(compressedFile);
                        setFileCount((prevCount) => prevCount + 1);
                    }
                }
            }

            setUploadedFiles((prevFiles) => [...prevFiles, ...compressedFiles]);
        };

        compressAndAddFiles();

    }, [fileCount]);

    useEffect(() => {
        if (uploadedFiles.length > 0) {
            onFilesChange(uploadedFiles);
        }
    }, [uploadedFiles, onFilesChange]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const deleteImage = (index) =>{
        // sử dụng ([...]) để Sao chép danh sách tệp đã tải lên và loại bỏ tệp tại vị trí index
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
        setFileCount((prevCount) => prevCount - 1);
    }
    return (
        <div className="dropzone">
            <div
                {...getRootProps()}
                className="dropzone-content"
                style={{
                    border: '2px dashed #FF6800', 
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                }}
            >
                <input {...getInputProps()} />
                <FaCloudUploadAlt size={100} color="#FF6800" />
                <p>{disLabel.DRAG_AND_DROP_IMAGE_UPLOAD}</p>
                {/* <p>Kéo và thả ảnh vào đây hoặc click để tải lên</p> */}
            </div>
            <div className="uploaded-files">
                {uploadedFiles.map((file, index) => (
                    <div key={index} className="uploaded-file">
                        <FaTimes className="delete_image_uploaded" size={25} color="red" onClick={() => deleteImage(index)}/>
                        <img className='upload-image-publish' src={URL.createObjectURL(file)} alt={file.name} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Upload;