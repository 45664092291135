import React from "react";
import * as disLabel from "../constants/label";
import { Button, Form, FormGroup, Input } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import * as disErrMess from "../constants/errorMessage";
import http from "../axios/http-common";
require('dotenv').config();


const recaptcha_key = process.env.REACT_APP_SITE_KEY;


class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sucessinform: false,
      fields: {
        name: '',
        email: '',
        subject: '',
        content: ''
      },
      errors: {},
      isRecaptchaVerified: false,
      recaptchaError: "",
    };
  }

  // get value from element then set to state
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  handleReset = () => {
    this.setState({
      fields: { name: '', email: '', subject: '', content: '' },
      errors: {},
      recaptchaError: '',
    });
  };

  handleRecaptchaChange = (value) => {
    if (value) {
      this.setState({
        isRecaptchaVerified: true,
        recaptchaError: ""
      });
    } else {
      this.setState({
        isRecaptchaVerified: false,
        recaptchaError: "Chọn tôi không phải robot để tiếp tục."
      });
    }
  };

  handleValidate(e) {
    e.preventDefault();

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // Check name
    if (fields["name"] === "undefined" || fields["name"] === "" ) {
      formIsValid = false;
      errors["name"] = disErrMess.ERROR_NOT_INPUT_NAME;
    }

    // Check email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = disErrMess.ERROR_NOT_INPUT_EMAIL;
    } else if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = disErrMess.ERROR_INPUT_EMAIL_NOT_VALID;
      }
    }

    // Check subject
    if (fields["subject"] === "undefined" || fields["subject"] === "" ) {
      formIsValid = false;
      errors["subject"] = disErrMess.ERROR_NOT_INPUT_NAME;
    }

    // Check content
    if (fields["content"] === "undefined" || fields["content"] === "" ) {
      formIsValid = false;
      errors["content"] = disErrMess.ERROR_NOT_INPUT_NAME;
    }

    this.setState({ errors: errors });

    // submit form
    let sentDataForm =
    {
      "name": this.state.name,
      "email": this.state.fields.email,
      "subject": this.state.fields.subject,
      "content": this.state.fields.content,
    };

    if (formIsValid !== false) {

      if (!this.state.isRecaptchaVerified) {
        this.setState({
          recaptchaError: "Chọn tôi không phải robot để tiếp tục."
        });
        return;
      }

      http.post(`/contact/`, sentDataForm)
        .then(res => {
          if (res.status === 201) {
            this.setState({ sucessinform: true });
            this.handleReset();
          } else {
            errors["content"] = res.detail;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }

    return formIsValid;
  }
  render() {

    const lang = {
      hl: "en"
    };

    return (
      <div className="container-contact-parent">
        <div className="section_h2-header_block1"><h5 className="title_display_default">{disLabel.CONTACT_INFO}</h5></div>
        {this.state.sucessinform === true ? (
          <div className="message-susses-area"><span>{disLabel.SENT_CONTACT_SUCCESS}</span></div>
        ) : (
          null
        )}
        <Form>
          <FormGroup className="modal-form">
            <Input
              className="contact-input"
              type='text'
              id="name"
              name="name"
              value={this.state.fields.name}
              maxLength="32"
              placeholder="Nhập tên"
              onChange={(e) => this.handleChange("name", e)}
            />
            <span className="text-danger">{this.state.errors["name"]}</span>
            <Input
              className="contact-input"
              type='email'
              id="email"
              name="email"
              value={this.state.fields.email}
              maxLength="32"
              placeholder="Nhập địa chỉ email"
              onChange={(e) => this.handleChange("email", e)}
            />
            <span className="text-danger">{this.state.errors["email"]}</span>
            <Input
              className="contact-input"
              type='text'
              id="subject"
              name="subject"
              value={this.state.fields.subject}
              maxLength="50"
              placeholder="Nhập chủ đề"
              onChange={(e) => this.handleChange("subject", e)}
            />
            <span className="text-danger">{this.state.errors["subject"]}</span>
            <Input
              className="contact-input"
              type='textarea'
              id="content"
              name="content"
              value={this.state.fields.content}
              maxLength="300"
              rows={4}
              placeholder="Nhập nội dung chi tiết"
              onChange={(e) => this.handleChange("content", e)}
            />
            <span className="text-danger">{this.state.errors["content"]}</span>
          </FormGroup>
          <div className="recaptcha-container">
            <ReCAPTCHA
              className="recaptcha-small"
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={recaptcha_key}
              onChange={this.handleRecaptchaChange}
              lang={lang}
            />
          </div>
          <div>{this.state.recaptchaError && (
            <div className="im-not-robot">
              {this.state.recaptchaError}
            </div>
          )}</div>
          <Button className="sent-contact-btn" color="btn btn-info" onClick={this.handleValidate.bind(this)}>{disLabel.SENT_BTN}</Button>
        </Form>
      </div>
    );
  }
}
export default ContactPage;
