const convertToTextPrice = (priceNum) => {
    const priceStr = priceNum.toString();
    if (priceStr.length === 7) {
        return priceStr.slice(0, 1) + " Triệu";
    } else if (priceStr.length === 8) {
        return priceStr.slice(0, 2) + " Triệu";
    } else if (priceStr.length === 9) {
        return priceStr.slice(0, 3) + " Triệu";
    } else if (priceStr.length === 10) {
        return priceStr.slice(0, 1) + "," + priceStr.slice(1, 2) + " Tỷ";
    } else if (priceStr.length === 11) {
        return priceStr.slice(0, 2) + "," + priceStr.slice(2, 3) + " Tỷ";
    } else if (priceStr.length === 12) {
        return priceStr.slice(0, 3) + "," + priceStr.slice(3, 4) + " Tỷ";
    } else {
        return "Thỏa thuận";
    }
};

export default convertToTextPrice