import React from "react";
import { Form } from "react-bootstrap";
import * as disLabel from "../../constants/label";

class PublishGuideline extends React.Component {

    render() {
        return (
            <div className="div-guideline">
                <div><h5 className="title_display_default">{disLabel.PUBLISH_GUIDLINE}</h5></div><br/>
                <span>● Để đăng bài được thì bạn phải login vào hệ thống, và click vào button đăng tin để thực hiện đăng tin bất động sản.</span>
                <img src="/images/dangtin_1.png" alt="Active Account" style={{ width: '100%' }} /><br/><br/>
                <span>● Bạn chọn loại bất động sản cần giao dịch, ví dụ nhà riêng, chung cư...</span><br/>
                <span>● Chọn tỉnh thành, quận huyện, phường xã và nhập đường để xác định được tọa độ của bất động sản trên Map.</span><br/>
                <img src="/images/dangtin_2.png" alt="Active Account" style={{ width: '100%' }} /><br/><br/>
                <span>● Nhập tiêu đề ngắn gọn dễ hiểu, cuốn người đọc là một điểm + cho bài đăng của bạn.</span><br/>
                <span>● Nhập thông tin bds như diện tích, giấy tờ pháp lý, mặt đường, mức giá, đơn vị, và hướng nhà.</span>
                <img src="/images/dangtin_3.png" alt="Active Account" style={{ width: '100%' }} /><br/><br/>
                <span>● Nhập thông tin về số phòng ngủ, phòng khách, phòng vệ sinh và thông tin liên hệ và kéo thả hình ảnh vào ô upload.</span>
                <img src="/images/dangtin_4.png" alt="Active Account" style={{ width: '100%' }} /><br/><br/>
                <span>● Cuối cùng là xác nhận tôi không phải là robot như dưới và bấm vào đăng tin để hoàn thành bài đăng.</span>
                <img src="/images/dangtin_5.png" alt="Active Account" style={{ width: '100%' }} />
            </div>
        );
    }
}

export default PublishGuideline;